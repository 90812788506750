import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';
import { v4 } from 'uuid';

import { getOrderDeleteRequest } from '../../../../store/reducers/order/deleteOrder';
import { getOrderById } from '../../../../store/reducers/order/order';

import { requestDeleteOrder } from '../../../../store/actions';

import useTxt from '../../../../hooks/useTxt';

import Accordion from '../../../../components/Accordion';
import {
  CenteredButtonGroup,
  IconTextButton,
} from '../../../../components/Buttons';
import { Spinner } from '../../../../components/Loading';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Tooltip from '../../../../components/Tooltip';
import Txt from '../../../../components/Txt';

import { IconInfo } from '../../../../assets';
import { IconDelete } from '../../../../assets/svg';

import { generateUrl, routes } from '../../../../routes';
import EditOrderForm from './EditOrderForm';

type Props = {
  orderId: string;
  onClose: () => void;
};

const SidebarHeaderInfoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TooltipInfoContent = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-left: 12px;

  border-radius: 50%;

  width: ${({ theme: { margin } }) => margin[18]};
  height: ${({ theme: { margin } }) => margin[18]};

  background-color: ${({ theme: { color } }) => color.black};
`;

const StyledSpan = styled.span`
  font-weight: bold;
`;

const EditOrderSidebar = ({ orderId, onClose }: Props) => {
  const [isSubmitting, submitState] = React.useState(false);
  const dispatch = useDispatch();
  const deleteRequestId = v4();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const order = useSelector(getOrderById(orderId));

  const deleteRequestState = useSelector(getOrderDeleteRequest(deleteRequestId))
    .kind;

  const isOrderDeletable = order?.isDeletable ?? false;

  const procurementReferenceNumberHeader = useTxt(
    'order.options.secondHeader.edit'
  );

  const procurementReferenceNumberTooltip = useTxt(
    'order.options.secondHeader.tooltip'
  );

  const onDeleteOrder = () => {
    dispatch(requestDeleteOrder({ requestId: deleteRequestId, orderId }));
    history.push(generateUrl({ route: routes.PROJECT, projectId }));
  };

  const handleSubmitting = (formikSubmitState: boolean) => {
    submitState(formikSubmitState);
  };

  return (
    <Sidebar ariaLabelledBy="create-new-order-sidebar-title" onClose={onClose}>
      <SidebarContent>
        <Accordion titleTextID="order.options.header.edit" isOpen>
          {order?.procurementReferenceNumber ? (
            <SidebarHeaderInfoContent>
              <StyledSpan>{procurementReferenceNumberHeader}</StyledSpan>
              <TooltipInfoContent>
                <div>{order?.procurementReferenceNumber}</div>
                <Tooltip
                  className="hoverable-tooltip"
                  tip={procurementReferenceNumberTooltip}
                >
                  <Icon src={IconInfo} alt="altText" />
                </Tooltip>
              </TooltipInfoContent>
            </SidebarHeaderInfoContent>
          ) : null}
          <EditOrderForm
            orderId={orderId}
            onClose={onClose}
            isSubmitting={handleSubmitting}
          />
        </Accordion>
        <Accordion titleTextID="order.options.header.delete" isOpen={false}>
          <>
            {isOrderDeletable ? (
              <CenteredButtonGroup>
                <StyledIconTextButton
                  type="button"
                  disabled={isSubmitting}
                  onClick={onDeleteOrder}
                  icon={IconDelete}
                >
                  {deleteRequestState === 'Loading' ? (
                    <Spinner size="1rem" light />
                  ) : (
                    <Txt id="order.options.deleteButton" />
                  )}
                </StyledIconTextButton>
              </CenteredButtonGroup>
            ) : (
              <Txt
                id="order.options.cannotBeDeleted"
                component={StyledDisabledDiv}
              />
            )}
          </>
        </Accordion>
      </SidebarContent>
    </Sidebar>
  );
};

export default EditOrderSidebar;

const SidebarContent = styled.div`
  padding: ${(props) =>
    `0 ${props.theme.margin[32]} ${props.theme.margin[24]} ${props.theme.margin[32]}`};
  background: ${(props) => props.theme.color.white};
`;

const StyledIconTextButton = styled(IconTextButton)`
  border-radius: ${(props) => props.theme.margin[24]};
  border: 1px solid rgba(0, 0, 0, 0);

  padding: ${(props) => props.theme.margin[16]};

  height: 2.5rem;
  min-width: 6.5rem;

  justify-content: center;

  background: ${(props) => props.theme.color.graphiteB96A};

  font-size: 1rem;
`;

const StyledDisabledDiv = styled.div`
  padding: ${(props) => props.theme.margin[8]} 0 0
    ${(props) => props.theme.margin[8]};
`;
