import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getNumberOfUnreadNotifications } from '../../store/reducers/notification';

import { IconFeedOpen, IconFeedClosed } from '../../assets';

import {
  TransparentCircleButtonContainer,
  NumberIndicator,
} from './Components';
import FeedSidebar from './FeedsideBar/FeedSidebar';

const NotificationIcon = () => {
  const numberOfUnreadNotifications = useSelector(
    getNumberOfUnreadNotifications
  );
  const [feedSidebarOpen, setFeedSidebarOpen] = useState(false);
  const toggleFeedSidebar = () => setFeedSidebarOpen((open) => !open);

  const shouldShowNumberIndicator =
    !feedSidebarOpen && numberOfUnreadNotifications > 0;

  return (
    <>
      <TransparentCircleButtonContainer
        onClick={toggleFeedSidebar}
        value="Avaa / Sulje"
      >
        <img
          src={feedSidebarOpen ? IconFeedOpen : IconFeedClosed}
          alt="notification-indicator"
        />
        {shouldShowNumberIndicator ? (
          <NumberIndicator>{numberOfUnreadNotifications}</NumberIndicator>
        ) : null}
      </TransparentCircleButtonContainer>
      {feedSidebarOpen ? (
        <FeedSidebar closeSidebar={toggleFeedSidebar} />
      ) : null}
    </>
  );
};

export default NotificationIcon;
