import { Dispatch } from 'react';

import {
  APIPurchaseInvoiceAttachmentFile,
  APIUpdatedEntities,
  RawAPIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import { ExtractActionTypes, makeApiActions } from '../../utils/actionCreators';
import { GET, apiErrorHandlingWithDecode, POST } from '../../utils/api';
import { Thunk } from '../../utils/thunk';

export type InvoiceAttachmentFilesAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeApiActions('get', 'invoiceAttachmentFiles')<
    APIPurchaseInvoiceAttachmentFile[]
  >(),
  ...makeApiActions('post', 'invoiceAttachmentFiles')<APIUpdatedEntities>(),
};
export const {
  getInvoiceAttachmentFilesStarted,
  getInvoiceAttachmentFilesSuccess,
  getInvoiceAttachmentFilesFailure,
  postInvoiceAttachmentFilesSuccess,
  postInvoiceAttachmentFilesFailure,
} = actionCreators;

const fetchInvoiceAttachmentFilesForOrder = (orderId: ID) => {
  return GET<APIPurchaseInvoiceAttachmentFile[]>(
    `v1/orders/${orderId}/purchase-invoice/attachment-files`
  );
};

export const requestInvoiceAttachmentFilesForOrder = (orderId: ID): Thunk => (
  dispatch
) => {
  dispatch(getInvoiceAttachmentFilesStarted());
  fetchInvoiceAttachmentFilesForOrder(orderId).then(
    (response) => {
      dispatch(getInvoiceAttachmentFilesSuccess(response));
    },
    (error) => {
      dispatch(
        getInvoiceAttachmentFilesFailure(apiErrorHandlingWithDecode(error))
      );
    }
  );
};

export const postInvoiceAttachmentsUpload = async (
  invoiceHeaderId: string,
  files: File[],
  dispatch: Dispatch<any>
): Promise<void> => {
  const data = new FormData();
  files.forEach((file) => data.append('file', file));

  return POST<RawAPIUpdatedEntities>(
    `v1/attachments/purchase-invoice-headers/${invoiceHeaderId}/files`,
    data
  ).then(
    async (response) =>
      dispatch(
        postInvoiceAttachmentFilesSuccess(await mapRawUpdatedEntities(response))
      ),
    async (error) => {
      dispatch(
        postInvoiceAttachmentFilesFailure(apiErrorHandlingWithDecode(error))
      );

      throw error;
    }
  );
};
