import { useHistory } from 'react-router-dom';

import { OrderViewMode } from '../../../types/general';

import useRouteParams from '../../../hooks/useRouteParams';

import { generateUrl, routes } from '../../../routes';

export default function useChangeViewMode() {
  const history = useHistory();

  const {
    orderId,
    projectId,
    showTargetRows,
    viewMode,
    subViewMode,
    showInfo,
  } = useRouteParams();

  return (
    nextMode:
      | OrderViewMode['type']
      | 'showTarget'
      | 'hideTarget'
      | 'showInfo'
      | 'hideInfo'
  ) => {
    // common parameters for all urls, only the route path changes
    const viewModeWithoutNormal = viewMode === 'normal' ? undefined : viewMode;

    const baseUrlParams = {
      projectId,
      orderId,
      showTargetRows,
      subViewMode,
      viewMode: viewModeWithoutNormal,
      showInfo,
    };

    const url = {
      normal: generateUrl({
        ...baseUrlParams,
        viewMode: undefined,
        route: routes.ORDER,
        subViewMode: undefined,
      }),
      edit: generateUrl({
        ...baseUrlParams,
        route: routes.ORDER,
        viewMode: 'edit',
        subViewMode: undefined,
      }),
      receive: generateUrl({
        ...baseUrlParams,
        route: routes.ORDER,
        viewMode: 'receive',
      }),
      comments: generateUrl({
        ...baseUrlParams,
        route: routes.ORDER,
        viewMode: 'comments',
      }),
      showTarget: generateUrl({
        ...baseUrlParams,
        route: routes.ORDER,
        showTargetRows: 'showTarget',
      }),
      hideTarget: generateUrl({
        ...baseUrlParams,
        route: routes.ORDER,
        showTargetRows: undefined,
      }),
      showInfo: generateUrl({
        ...baseUrlParams,
        route: routes.ORDER,
        showInfo: 'info',
      }),
      hideInfo: generateUrl({
        ...baseUrlParams,
        route: routes.ORDER,
        showInfo: undefined,
      }),
    }[nextMode];

    history.push(url);
  };
}
