import React from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import Sidebar from '../../../components/Sidebar/Sidebar';
import Txt from '../../../components/Txt';

import CreateGroupForm from './CreateGroupForm';

type Props = {
  onClose: () => void;
};

const CreateGroupSidebar = ({ onClose }: Props) => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <Sidebar
      ariaLabelledBy="create-payment-program-row-group-title"
      onClose={onClose}
    >
      <SidebarContent>
        <StyledHeader id="create-payment-program-row-group-title">
          <Txt id="revenue.addGroup.form.header" />
        </StyledHeader>
        <CreateGroupForm projectId={projectId} onClose={onClose} />
      </SidebarContent>
    </Sidebar>
  );
};

export default CreateGroupSidebar;

const SidebarContent = styled.div`
  padding: ${(props) =>
    `0 ${props.theme.margin[32]} ${props.theme.margin[24]} ${props.theme.margin[32]}`};
  background: ${(props) => props.theme.color.white};
`;

const StyledHeader = styled.h3`
  padding: ${(props) => props.theme.margin[8]} 0;
  font-size: 18px;
`;
