import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { ExtractActionTypes, makeAction } from '../../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
} from '../../../utils/api';
import { bigString, dateString } from '../../../utils/decoders';
import { flow } from '../../../utils/function';
import { isDefined } from '../../../utils/general';
import invertObject from '../../../utils/invertObject';
import { createAsyncThunk } from '../../../utils/thunk';

import {
  getOrderChangeLogEntriesFetchRequest,
  OrderChangeLogEntry,
} from '../../reducers/order/changeLogEntries';

export type OrderChangeLogEntryAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('getOrderChangeLogEntriesStarted')<{ orderId: string }>(),
  ...makeAction('getOrderChangeLogEntriesFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getOrderChangeLogEntriesSuccess')<{
    orderId: string;
    orderChangeLogEntries: OrderChangeLogEntry[];
  }>(),
};

export const {
  getOrderChangeLogEntriesStarted,
  getOrderChangeLogEntriesFailure,
  getOrderChangeLogEntriesSuccess,
} = actionCreators;

export const statusToId = {
  changeOfCosts: '1',
  deletion: '2',
  orderChange: '3',
  topicChange: '4',
  statusChange: '5',
  newRow: '6',
} as const;

export type LogEntryAction = keyof typeof statusToId;

const idToStatus = invertObject(statusToId);

const requiredProperties = t.exact(
  t.type({
    id: t.string,
    userId: t.string,
    userName: t.string,
    snapshotId: t.union([t.string, t.null]),
    timestamp: dateString,
    orderId: t.string,
    orderRowId: t.string,
    orderRowNo: t.number,
    orderRowDescription: t.string,
    orderRowChangeTypeId: t.keyof(idToStatus),
    differenceForOrderTotal: bigString,
  })
);

const optionalProperties = t.partial({
  moveChangeInfo: t.string,
  quantityChangeInfo: t.string,
  unitPriceChangeInfo: t.string,
});

const apiOrderChangeLogEntryType = t.intersection([
  requiredProperties,
  optionalProperties,
]);

export type APIOrderChangeLogEntry = t.TypeOf<
  typeof apiOrderChangeLogEntryType
>;

export async function toOrderChangeLogEntry(
  u: unknown
): Promise<OrderChangeLogEntry[]> {
  const apiChangeLogEntries = await tPromise.decode(
    t.array(apiOrderChangeLogEntryType),
    u
  );

  return apiChangeLogEntries
    .map(({ orderRowChangeTypeId, ...rest }) => {
      const changeType = idToStatus[orderRowChangeTypeId];

      return {
        changeType,
        ...rest,
      };
    })
    .filter(isDefined);
}

const getOrderChangeLogEntries = async (orderId: string) => {
  const response = await GET(`v1/orders/${orderId}/change-log`);

  return toOrderChangeLogEntry(response);
};

export const fetchOrderChangeLogEntries = (orderId: string) =>
  createAsyncThunk(getOrderChangeLogEntries, {
    args: [orderId],
    isPending: flow(
      getOrderChangeLogEntriesFetchRequest(orderId),
      (requestState) => requestState === 'Loading'
    ),
    initialAction: getOrderChangeLogEntriesStarted({ orderId }),
    successActionCreator: (response) =>
      getOrderChangeLogEntriesSuccess({
        orderId,
        orderChangeLogEntries: response,
      }),
    failureActionCreator: (error) =>
      getOrderChangeLogEntriesFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
