import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { AppState } from '../store/reducers';
import { NotificationState } from '../store/reducers/notification';

import { closeNotification } from '../store/actions/notification';

import { IconInfo, IconStop } from '../assets';
import { IconCloseWhite } from '../assets/svg';

import { IconButton } from './Buttons';
import Txt from './Txt';

type NotificationProps = {
  children?: React.ReactNode;
  onClose: () => void;
  isError?: boolean;
  timeout?: number;
};

const Notification: React.FC<NotificationProps> = ({
  children,
  onClose,
  isError,
  timeout,
}) => {
  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(onClose, timeout);

      return () => clearTimeout(timer);
    }
  });

  const [icon, altText] = isError
    ? [IconStop, 'Virheilmoitus']
    : [IconInfo, 'Ilmoitus'];

  return (
    <Container isError={isError}>
      <Icon src={icon} alt={altText} />
      {children}
      <IconButton onClick={onClose} icon={IconCloseWhite} />
    </Container>
  );
};

const NotificationGroup = styled.div`
  position: absolute;
  top: 4rem;
  right: 1.25rem;

  width: 25rem; /* 400px */

  display: flex;
  flex-flow: column nowrap;
`;

type ContainerProps = {
  isError?: boolean;
};

const Container = styled.div<ContainerProps>`
  padding: ${({ theme }) => theme.margin[8]};

  width: 100%;
  min-height: ${({ theme }) => theme.margin[40]};

  display: flex;
  align-items: center;

  background: ${({ isError, theme }) =>
    isError ? theme.color.errorNotification : theme.color.infoNotification};

  color: ${({ theme }) => theme.color.white};

  z-index: 200;

  & ~ & {
    margin-top: 1.25rem; /* 20px */
  }
`;

const Icon = styled.img`
  width: ${({ theme }) => theme.margin[32]};
  height: ${({ theme }) => theme.margin[24]};
`;

const Text = styled.div`
  padding-left: ${({ theme }) => theme.margin[8]};
  flex-grow: 1;
  line-height: 1.2;
`;

type Props = {
  notifications: NotificationState;
};

const GlobalNotificationHandler = ({ notifications }: Props) => {
  const dispatch = useDispatch();

  const onCloseNotification = (id: string) => () => {
    dispatch(closeNotification(id));
  };

  return (
    <NotificationGroup>
      {notifications.ui.map((notification) => (
        <Notification
          key={`notification-${notification.id}`}
          isError={notification.type === 'error'}
          onClose={onCloseNotification(notification.id)}
          timeout={notification.timeout}
        >
          <Text>
            {notification.textId ? (
              <Txt id={notification.textId} />
            ) : (
              <>{notification.text}</>
            )}
          </Text>
        </Notification>
      ))}
    </NotificationGroup>
  );
};

const mapStateToProps = (state: AppState): Props => ({
  notifications: state.notification,
});

export default connect(mapStateToProps)(GlobalNotificationHandler);
