import styled from 'styled-components';

export const ContractorText = styled.span`
  padding-left: 0.25rem;
  color: ${(props) => props.theme.color.graphiteB57};
  font-size: ${(props) => props.theme.fontSize.small};
`;

export const StyledDescription = styled.span`
  padding: 0.25rem 0;
`;
