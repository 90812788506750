import { isNotNull, isDefined } from './general';
import { RecordKey } from './record';

export function searchFilter<
  K extends RecordKey,
  T extends Partial<Record<K, any>>
>(items: T[], searchText?: string, searchFields?: Array<keyof T>): T[] {
  if (!searchFields || searchFields.length === 0) {
    return searchText && searchText.length > 0
      ? items.filter((entry) =>
          Object.values(entry)
            .filter(isNotNull)
            .filter(isDefined)
            .map((value: any) => value.toString())
            .some((string) =>
              string.toLowerCase().includes(searchText.toLowerCase())
            )
        )
      : items;
  }

  return searchText && searchText.length > 0
    ? items.filter((entry) => {
        const subset = Object.fromEntries(
          searchFields.map((key) => [key, entry[key]])
        );

        return Object.values(subset)
          .filter(isNotNull)
          .filter(isDefined)
          .map((value) => value.toString())
          .some((string) =>
            string.toLowerCase().includes(searchText.toLowerCase())
          );
      })
    : items;
}
