import React, { useEffect, useMemo, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import {
  getUnhandledInvoiceHeadersByOrderId,
  getHandledInvoiceHeadersByOrderId,
} from '../../../../../store/reducers/invoiceHeader';
import { getTasks } from '../../../../../store/reducers/tasks';
import { getOuterBarState } from '../../../../../store/reducers/ui';

import { ID } from '../../../../../types/general';

import useTxt from '../../../../../hooks/useTxt';

import { PaginationButton } from '../../../../../components/Buttons';
import { Table } from '../../../../../components/Table';

import {
  useDebouncedValue,
  useInitialStateChange,
} from '../../../../../utils/hooks';
import { searchFilter } from '../../../../../utils/search';

import { IconInvoiceHeaders } from '../../../../../assets';

import ArrivalRowTableHeader from './ArrivalRowTableHeader';
import { SectionSpacer, SectionTitleBar } from './Components';
import Invoice from './Invoice';

type Props = {
  orderId: string;
  showHandledRows: boolean;
  selectedId?: ID;
  setSelectedId: (id: ID, onlyOuterBar?: boolean) => void;
  toggleExpandInvoice: (id: string) => void;
  expandedInvoiceIds?: string[];
  searchText: {
    text: string;
    idFilter?: string;
  };
  setSearchText?: (searchText: { text: string; idFilter?: string }) => void;
};

const InvoicesTable = ({
  orderId,
  showHandledRows,
  selectedId,
  setSelectedId,
  expandedInvoiceIds,
  toggleExpandInvoice,
  searchText,
  setSearchText,
}: Props) => {
  const history = useHistory();
  const [isAllRendered, setAllRendered] = React.useState(false);

  const { hash } = history.location;

  const hashInvoiceId = hash.replace('#invoiceHeaderId-', '');
  const [hashOpened, setHashOpened] = useState<string>('');
  const outerBarOpen = useSelector(getOuterBarState());

  const ref = React.useRef<number>();

  useEffect(() => {
    ref.current = requestAnimationFrame(() => {
      setAllRendered(true);
    });

    return () => cancelAnimationFrame(ref.current ?? 0);
  }, []);

  const unhandledInvoiceHeaders = useSelector(
    getUnhandledInvoiceHeadersByOrderId(orderId)
  );

  const handledInvoiceHeaders = useSelector(
    getHandledInvoiceHeadersByOrderId(orderId)
  );

  const sectionTitleText = useTxt(
    'order.receiveMode.invoiceTable.sectionTitle'
  );

  const selectedInvoice = unhandledInvoiceHeaders
    .concat(handledInvoiceHeaders)
    .find((invoice) => selectedId === invoice.id);

  const debouncedValue = useDebouncedValue(searchText.text, 1000);

  const unhandledInvoices = searchFilter(
    unhandledInvoiceHeaders,
    searchText.idFilter ?? debouncedValue,
    searchText.idFilter ? ['id'] : undefined
  );

  const handledInvoices = searchFilter(
    handledInvoiceHeaders,
    searchText.idFilter ?? debouncedValue,
    searchText.idFilter ? ['id'] : undefined
  );

  const showSectionTitleBar =
    handledInvoices.length !== 0 || unhandledInvoices.length !== 0;

  const showArrivalRowTableHeaderUnhandled = unhandledInvoices.length !== 0;

  const showArrivalRowTableHeaderHandled =
    showHandledRows && handledInvoices.length !== 0;

  const apiTasks = useSelector(getTasks);

  const initialInvoiceId = unhandledInvoices.find(
    (row) => row.id === hashInvoiceId
  )?.id;

  const memoizedHashInvoiceId = useMemo(() => initialInvoiceId, [
    initialInvoiceId,
  ]);

  const memoizedInvoiceHeaderVendorId = useMemo(() => {
    return unhandledInvoiceHeaders
      .concat(handledInvoiceHeaders)
      .find((row) => row.id === hashInvoiceId)?.vendorInvoiceNo;
  }, [hashInvoiceId, handledInvoiceHeaders, unhandledInvoiceHeaders]);

  // when history changes(hashInvoiceId), set the search text to the invoice vendor id

  useEffect(() => {
    if (memoizedInvoiceHeaderVendorId && setSearchText) {
      setSearchText({
        text: memoizedInvoiceHeaderVendorId,
        idFilter: hashInvoiceId,
      });
    }
  }, [memoizedInvoiceHeaderVendorId, hashInvoiceId, setSearchText]);

  useEffect(() => {
    if (
      hashOpened !== memoizedHashInvoiceId &&
      memoizedHashInvoiceId &&
      isAllRendered
    ) {
      toggleExpandInvoice(memoizedHashInvoiceId);
      setHashOpened(memoizedHashInvoiceId);
      setSelectedId(memoizedHashInvoiceId);
    }
  }, [
    memoizedHashInvoiceId,
    toggleExpandInvoice,
    setHashOpened,
    hashOpened,
    setSelectedId,
    isAllRendered,
  ]);

  const conditionalToggleExpand = (id: string | undefined) => {
    if (!id) {
      return;
    }

    toggleExpandInvoice(id);
  };

  // opens the first invoice on initial mount
  useInitialStateChange(
    conditionalToggleExpand,
    initialInvoiceId ? undefined : unhandledInvoices[0]?.id
  );

  type PaginationState = {
    handledPageNumber: number;
    unhandledPageNumber: number;
  };

  type PaginationAction =
    | { type: 'updateUnhandled'; pageNumber: number }
    | { type: 'updateHandled'; pageNumber: number };

  const paginationReducer = (
    state: PaginationState,
    action: PaginationAction
  ): PaginationState => {
    switch (action.type) {
      case 'updateUnhandled':
        return { ...state, unhandledPageNumber: action.pageNumber };
      case 'updateHandled':
        return { ...state, handledPageNumber: action.pageNumber };
      default:
        return state;
    }
  };

  const [currentPage, setCurrentPage] = useReducer(paginationReducer, {
    handledPageNumber: 1,
    unhandledPageNumber: 1,
  });

  // 100 invoice rows per page
  const invoicePerPage = 100;

  const unhandledStart = (currentPage.unhandledPageNumber - 1) * invoicePerPage;

  const unhandledEnd = unhandledStart + invoicePerPage;

  const handledStart = (currentPage.handledPageNumber - 1) * invoicePerPage;

  const handledEnd = handledStart + invoicePerPage;

  const getTotalPages = (length: number) => {
    const totalPages = Math.ceil(length / invoicePerPage);

    return totalPages;
  };

  const goToPrevious = useTxt('order.receiveMode.invoiceTable.previous');
  const goToNextPage = useTxt('order.receiveMode.invoiceTable.next');

  return (
    <SectionSpacer>
      {showSectionTitleBar ? (
        <SectionTitleBar icon={IconInvoiceHeaders}>
          <div>{sectionTitleText}</div>
        </SectionTitleBar>
      ) : null}
      <TableContainer>
        <StyledTable>
          {showArrivalRowTableHeaderUnhandled ? (
            <ArrivalRowTableHeader
              listType="invoiceHeader"
              invoiceType="unhandled"
            />
          ) : null}
          {unhandledInvoices
            .slice(unhandledStart, unhandledEnd)
            .map((invoice) => (
              <Invoice
                key={`invoice-header-${invoice.id}`}
                isOpen={expandedInvoiceIds?.includes(invoice.id)}
                toggleExpandInvoice={toggleExpandInvoice}
                invoiceHeader={invoice}
                isSelected={selectedInvoice?.id === invoice.id}
                onSelect={() => setSelectedId(invoice.id)}
                isAllRendered={isAllRendered}
                apiTasks={apiTasks}
              />
            ))}
          {unhandledInvoices.length >= invoicePerPage ? (
            <tbody>
              <tr>
                <td colSpan={outerBarOpen ? 11 : 16}>
                  <PaginatorActions>
                    <PaginationButton
                      disabled={currentPage.unhandledPageNumber === 1}
                      onClick={() =>
                        setCurrentPage({
                          type: 'updateUnhandled',
                          pageNumber: currentPage.unhandledPageNumber - 1,
                        })
                      }
                    >
                      {goToPrevious}
                    </PaginationButton>
                    <PageNumber data-testid="unhandled_pageNumber">
                      {`${currentPage.unhandledPageNumber}/ ${getTotalPages(
                        unhandledInvoices.length
                      )}`}
                    </PageNumber>
                    <PaginationButton
                      disabled={unhandledEnd >= unhandledInvoices.length}
                      onClick={() =>
                        setCurrentPage({
                          type: 'updateUnhandled',
                          pageNumber: currentPage.unhandledPageNumber + 1,
                        })
                      }
                    >
                      {goToNextPage}
                    </PaginationButton>
                  </PaginatorActions>
                </td>
              </tr>
            </tbody>
          ) : null}

          {showHandledRows ? (
            <>
              {showArrivalRowTableHeaderHandled ? (
                <ArrivalRowTableHeader
                  listType="invoiceHeader"
                  invoiceType="handled"
                />
              ) : null}
              {handledInvoices
                .slice(handledStart, handledEnd)
                .map((invoice) => (
                  <Invoice
                    key={`invoice-header-${invoice.id}`}
                    isOpen={expandedInvoiceIds?.includes(invoice.id)}
                    toggleExpandInvoice={toggleExpandInvoice}
                    invoiceHeader={invoice}
                    isSelected={selectedInvoice?.id === invoice.id}
                    onSelect={() => setSelectedId(invoice.id, true)}
                    isAllRendered={isAllRendered}
                    isHandled
                  />
                ))}

              {handledInvoices.length >= invoicePerPage ? (
                <tbody>
                  <tr>
                    <td colSpan={outerBarOpen ? 11 : 16}>
                      <PaginatorActions>
                        <PaginationButton
                          disabled={currentPage.handledPageNumber === 1}
                          onClick={() =>
                            setCurrentPage({
                              type: 'updateHandled',
                              pageNumber: currentPage.handledPageNumber - 1,
                            })
                          }
                        >
                          {goToPrevious}
                        </PaginationButton>
                        <PageNumber data-testid="unhandled_pageNumber">
                          {`${currentPage.handledPageNumber}/ ${getTotalPages(
                            handledInvoices.length
                          )}`}
                        </PageNumber>
                        <PaginationButton
                          disabled={handledEnd >= handledInvoices.length}
                          onClick={() =>
                            setCurrentPage({
                              type: 'updateHandled',
                              pageNumber: currentPage.handledPageNumber + 1,
                            })
                          }
                        >
                          {goToNextPage}
                        </PaginationButton>
                      </PaginatorActions>
                    </td>
                  </tr>
                </tbody>
              ) : null}
            </>
          ) : null}
        </StyledTable>
      </TableContainer>
    </SectionSpacer>
  );
};

const PaginatorActions = styled.div`
  border-radius: 0 0 0.5rem 0.5rem;

  padding: ${({ theme }) => theme.margin[24]} 0;

  display: flex;
  align-items: center;
  justify-content: end;

  gap: 1rem;
`;

const PageNumber = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
`;

const StyledTable = styled(Table)`
  border: none;
`;

const TableContainer = styled.div``;

export default InvoicesTable;
