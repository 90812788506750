import { flow } from '../../../utils/function';

import { ActionTypes as Action } from '../../actionTypes';
import { getSortOrderForKey, SortState } from '../helpers/sort';
import { AppState } from '../index';
import { InvoiceHeader } from '../invoiceHeader';

// can be extended if needed,
// for now, users are able to sort invoices by dueDate
export type SortableKey = Extract<keyof InvoiceHeader, 'dueDate'>;

export type InvoiceSortState = {
  handled: SortState<SortableKey>;
  unhandled: SortState<SortableKey>;
};

const initialSortState: InvoiceSortState = {
  handled: [
    {
      key: 'dueDate',
      sortOrder: 'Descending',
    },
  ],
  unhandled: [
    {
      key: 'dueDate',
      sortOrder: 'Ascending',
    },
  ],
};

function sortInvoiceReducer(
  state: InvoiceSortState = initialSortState,
  action: Action
): InvoiceSortState {
  switch (action.type) {
    case 'TOGGLE_INVOICE_HEADER_SORT': {
      const { sortableKey, invoiceType } = action.payload;
      const oldOrder = getSortOrderForKey(state[invoiceType], sortableKey);
      const newOrder = oldOrder === 'Ascending' ? 'Descending' : 'Ascending';

      const otherOrderings = state[invoiceType].filter(
        ({ key }) => key !== sortableKey
      );

      return {
        ...state,
        [invoiceType]: [
          { key: sortableKey, sortOrder: newOrder },
          ...otherOrderings,
        ],
      };
    }
    default: {
      return state;
    }
  }
}

export function getSortOrders({
  invoiceHeaders: { sortOrders },
}: AppState): InvoiceSortState {
  return sortOrders;
}

export const getInvoiceSortOrderFor = (
  sortableKey: string,
  invoiceType: 'handled' | 'unhandled'
) =>
  flow(getSortOrders, (sortOrders) =>
    getSortOrderForKey(sortOrders[invoiceType], sortableKey)
  );

export default sortInvoiceReducer;
