import { Reducer } from 'redux';

import { APITopic } from '../../types/api';

import { flow } from '../../utils/function';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import { pickBy } from '../../utils/record';
import * as remoteData from '../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';

import { ActionTypes } from '../actionTypes';
import { getArrivalRowById } from './arrivalRow';
import { getRenderableOrderRowById } from './orderRow';

import { AppState } from '.';

export type TopicState = {
  requestedByOrderId: Record<string, remoteData.RemoteData>;
  requestedByWorkPackageId: Record<string, remoteData.RemoteData>;
  requestedByProjectId: Record<string, remoteData.RemoteData>;
  deleteRequests: Record<string, remoteData.RemoteData>;
  data: Partial<Record<string, APITopic>>;
};

const initialState: TopicState = {
  requestedByOrderId: {},
  requestedByWorkPackageId: {},
  requestedByProjectId: {},
  deleteRequests: {},
  data: {},
};

const topicReducer: Reducer<TopicState, ActionTypes> = (
  state = initialState,
  action
): TopicState => {
  switch (action.type) {
    case 'GET_TOPICS_BY_ORDER_ID_STARTED': {
      const { orderId } = action.payload;

      return {
        ...state,
        requestedByOrderId: {
          ...state.requestedByOrderId,
          [orderId]: remoteData.loading,
        },
      };
    }
    case 'GET_TOPICS_BY_ORDER_ID_FAILURE': {
      const { orderId, error } = action.payload;

      return {
        ...state,
        requestedByOrderId: {
          ...state.requestedByOrderId,
          [orderId]: remoteData.fail(error),
        },
      };
    }
    case 'GET_TOPICS_BY_ORDER_ID_SUCCESS': {
      const { orderId, topics } = action.payload;

      return {
        ...state,
        requestedByOrderId: {
          ...state.requestedByOrderId,
          [orderId]: remoteData.succeed(undefined),
        },
        data: {
          ...state.data,
          ...normalizeBy('id', topics),
        },
      };
    }

    case 'GET_TOPICS_BY_WORK_PACKAGE_ID_STARTED': {
      const { workPackageId } = action.payload;

      return {
        ...state,
        requestedByWorkPackageId: {
          ...state.requestedByWorkPackageId,
          [workPackageId]: remoteData.loading,
        },
      };
    }
    case 'GET_TOPICS_BY_WORK_PACKAGE_ID_FAILURE': {
      const { workPackageId, error } = action.payload;

      return {
        ...state,
        requestedByWorkPackageId: {
          ...state.requestedByWorkPackageId,
          [workPackageId]: remoteData.fail(error),
        },
      };
    }
    case 'GET_TOPICS_BY_WORK_PACKAGE_ID_SUCCESS': {
      const { workPackageId, topics } = action.payload;

      return {
        ...state,
        requestedByWorkPackageId: {
          ...state.requestedByWorkPackageId,
          [workPackageId]: remoteData.succeed(undefined),
        },
        data: {
          ...state.data,
          ...normalizeBy('id', topics),
        },
      };
    }

    case 'GET_TOPICS_BY_PROJECT_ID_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        requestedByProjectId: {
          ...state.requestedByProjectId,
          [projectId]: remoteData.loading,
        },
      };
    }
    case 'GET_TOPICS_BY_PROJECT_ID_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        requestedByProjectId: {
          ...state.requestedByProjectId,
          [projectId]: remoteData.fail(error),
        },
      };
    }
    case 'GET_TOPICS_BY_PROJECT_ID_SUCCESS': {
      const { projectId, topics } = action.payload;

      return {
        ...state,
        requestedByProjectId: {
          ...state.requestedByProjectId,
          [projectId]: remoteData.succeed(undefined),
        },
        data: {
          ...state.data,
          ...normalizeBy('id', topics),
        },
      };
    }
    case 'DELETE_TOPIC_STARTED': {
      const { topicId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [topicId]: remoteData.loading,
        },
      };
    }
    case 'DELETE_TOPIC_FAILURE': {
      const { topicId, error } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [topicId]: remoteData.fail(error),
        },
      };
    }
    case 'DELETE_TOPIC_SUCCESS': {
      const { topicId, topics: updatedTopics } = action.payload;

      if (!updatedTopics) {
        return {
          ...state,
          deleteRequests: {
            ...state.deleteRequests,
            [topicId]: remoteData.succeed(undefined),
          },
        };
      }

      const data = { ...state.data };
      updatedTopics.forEach((topic) => {
        const { isDeleted, id } = topic;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = topic;
        }
      });

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [topicId]: remoteData.succeed(undefined),
        },
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { topics: updatedTopics } = action.payload;

    if (!updatedTopics) {
      return state;
    }

    const data = { ...state.data };
    updatedTopics.forEach((topic) => {
      const { isDeleted, id } = topic;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = topic;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const getTopic = (id: string) => ({
  topics: {
    data: { [id]: topic },
  },
}: AppState): APITopic | undefined => topic;

const getRequestStateByOrderId = (orderId: string) => ({
  topics: {
    requestedByOrderId: { [orderId]: requestState = remoteData.notAsked },
  },
}: AppState): remoteData.RemoteData => requestState;

export const isFetchingByOrderId = (
  orderId: string
): ((appState: AppState) => boolean) =>
  flow(getRequestStateByOrderId(orderId), remoteData.isLoading);

const getRequestStateByWorkPackageId = (workPackageId: string) => ({
  topics: {
    requestedByWorkPackageId: {
      [workPackageId]: requestState = remoteData.notAsked,
    },
  },
}: AppState): remoteData.RemoteData => requestState;

export const getRequestStateByProjectId = (projectId: string) => ({
  topics: {
    requestedByProjectId: { [projectId]: requestState = remoteData.notAsked },
  },
}: AppState): remoteData.RemoteData => requestState;

export const getDeleteRequestState = (topicId: string) => ({
  topics: {
    deleteRequests: { [topicId]: requestState = remoteData.notAsked },
  },
}: AppState): remoteData.RemoteData => requestState;

export const isFetchingByWorkPackageId = (
  workPackageId: string
): ((appState: AppState) => boolean) =>
  flow(getRequestStateByWorkPackageId(workPackageId), remoteData.isLoading);

export const isFetchingByProjectId = (
  projectId: string
): ((appState: AppState) => boolean) =>
  flow(getRequestStateByProjectId(projectId), remoteData.isLoading);

export const isDeletingTopic = (
  topicId: string
): ((appState: AppState) => boolean) =>
  flow(getDeleteRequestState(topicId), remoteData.isLoading);

export const getTopics = ({ topics: { data } }: AppState) =>
  Object.values(data).filter(isDefined);

export const getTopicsByOrderId = (orderId: string) => ({
  topics: { data },
}: AppState): Partial<Record<string, APITopic>> =>
  pickBy(data, (topic) => topic?.orderId === orderId);

export const getOrderTopics = (orderId: string) => (
  appState: AppState
): remoteData.RemoteData<APITopic[]> => {
  const topics = getTopics(appState).filter(
    (topic) => topic.orderId === orderId
  );

  return remoteData.map(
    getRequestStateByOrderId(orderId)(appState),
    (_) => topics
  );
};

export const getDefaultTopicForOrder = (orderId: string) => (
  appState: AppState
): remoteData.RemoteData<APITopic | undefined> => {
  const topics = getOrderTopics(orderId)(appState);

  return remoteData.map(topics, (data) => {
    const defaultTopic = data.find((entry) => entry.defaultTopic === true);

    return defaultTopic;
  });
};

export const getTopicsByWorkPackageId = (workPackageId: string) => (
  appState: AppState
) => {
  const topics = getTopics(appState).filter(
    (topic) => topic.workPackageId === workPackageId
  );
  const requestState = getRequestStateByWorkPackageId(workPackageId)(appState);

  return remoteData.map(requestState, (_) => topics);
};

export const getTopicsByIds = (topicIds: string[]) => (appState: AppState) => {
  const topics = getTopics(appState).filter((topic) =>
    topicIds.includes(topic.id)
  );

  return topics;
};

export const getTopicByArrivalRowId = (arrivalRowId: string) => (
  appState: AppState
): APITopic | undefined => {
  const arrivalRow = getArrivalRowById(arrivalRowId)(appState);

  if (!arrivalRow) {
    return undefined;
  }

  const orderRow = getRenderableOrderRowById(arrivalRow.orderRowId ?? '')(
    appState
  );

  if (!orderRow) {
    return undefined;
  }

  const topic = getTopic(orderRow.topicId)(appState);

  return topic;
};

export default topicReducer;
