import styled from 'styled-components';

import Cell from '../../../components/Cell';
import { Content, Header } from '../../../components/Modal/Modal';

import { fontSizeCss } from '../../../styles/additionals';

export const Container = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  max-width: 60vw;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};

  height: 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  align-self: stretch;
`;

export const StyledContent = styled(Content)`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => `${theme.margin[32]}`};
  min-height: 16rem;
  max-height: none;
`;

export const SumRow = styled.div`
  padding: ${({ theme }) => `${theme.margin[32]} ${theme.margin[24]}`};
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const StyledDivTableContainer = styled.div`
  max-height: 70vh;

  display: flex;
  flex-direction: column;

  flex: 1;

  overflow-y: scroll;
`;

type PrimaryRowAndCellProps = {
  depth?: number;
};

export const StyledCell = styled(Cell)<PrimaryRowAndCellProps>`
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};
  ${(props) => fontSizeCss(props.depth)}
`;

export const RightPaddedCell = styled(StyledCell)`
  padding-right: 1.5rem;
`;
