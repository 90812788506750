import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { AppState } from '..';
import { WorkPackageTimelineEntry } from '../../actions/schedule/workPackageTimeline';
import { ActionTypes as Action } from '../../actionTypes';

type Err = BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<string, WorkPackageTimelineEntry[]>;
};

const initialState: State = {
  requests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WORK_PACKAGE_TIMELINES_STARTED': {
      const { projectId } = action.payload;
      const requests = { ...state.requests, [projectId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_WORK_PACKAGE_TIMELINES_FAILURE': {
      const { projectId, error } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }

    case 'GET_WORK_PACKAGE_TIMELINES_SUCCESS': {
      const { projectId, workPackageTimelineEntries } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.succeed(undefined),
      };

      return {
        requests,
        data: { ...state.data, [projectId]: workPackageTimelineEntries },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  projectId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    schedule: {
      workPackageTimelines: {
        requests: { [projectId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
}

export function isLoading(projectId: string): Selector<boolean> {
  return flow(
    getRequestState(projectId),
    (requestState) => requestState === 'Loading'
  );
}

export const getWorkPackageTimelineEntriesForProject: (
  projectId: string
) => Selector<remoteData.RemoteData<WorkPackageTimelineEntry[]>> = (
  projectId
) => ({
  schedule: {
    workPackageTimelines: {
      requests: { [projectId]: requestState = remoteData.notAsked },
      data,
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    const timelineData = data[projectId];

    if (!timelineData) {
      return [];
    }

    return timelineData;
  });

export const getWorkPackageCurrentPeriodActualPoC: ({
  projectId,
  workPackageId,
  showHidden,
}: {
  projectId: string;
  workPackageId: string;
  showHidden?: boolean;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageTimelineEntry | undefined> = ({
  projectId,
  workPackageId,
  showHidden = true,
}) =>
  flow(getWorkPackageTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntry = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.snapshotTypeId === '1' &&
          entry.snapshotId === null &&
          entry.currentPeriod &&
          (entry.hide === false || entry.hide === showHidden)
      )[0];

      return filteredEntry;
    })
  );

export const getWorkPackageCurrentPeriodLatestSnapshotPlannedPoC: ({
  projectId,
  workPackageId,
  showHidden,
}: {
  projectId: string;
  workPackageId: string;
  showHidden?: boolean;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageTimelineEntry | undefined> = ({
  projectId,
  workPackageId,
  showHidden = true,
}) =>
  flow(getWorkPackageTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntry = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.snapshotTypeId === '2' &&
          entry.latestSnapshot &&
          entry.currentPeriod &&
          entry.pastPeriod === false &&
          (entry.hide === false || entry.hide === showHidden)
      )[0];

      return filteredEntry;
    })
  );

export const getWorkPackagePlannedPoCEntries: ({
  projectId,
  workPackageId,
  showHidden,
}: {
  projectId: string;
  workPackageId: string;
  showHidden?: boolean;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageTimelineEntry[]> = ({
  projectId,
  workPackageId,
  showHidden = true,
}) =>
  flow(getWorkPackageTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.snapshotId === null &&
          (entry.hide === false || entry.hide === showHidden)
      );

      return filteredEntries;
    })
  );

export const getWorkPackageActualAndPlannedPoCEntries: ({
  projectId,
  workPackageId,
  showHidden,
}: {
  projectId: string;
  workPackageId: string;
  showHidden?: boolean;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageTimelineEntry[]> = ({
  projectId,
  workPackageId,
  showHidden = true,
}) =>
  flow(getWorkPackageTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredActualEntries = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.projectId === projectId &&
          entry.snapshotTypeId === '1' &&
          entry.snapshotId === null &&
          (entry.hide === false || entry.hide === showHidden)
      );

      const filteredPlannedEntries = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.snapshotId === null &&
          entry.currentPeriod === false &&
          (entry.hide === false || entry.hide === showHidden)
      );

      return filteredActualEntries.concat(filteredPlannedEntries);
    })
  );

export const getWorkPackagePoCBasedOnPreviouslyPlannedEntries: ({
  projectId,
  workPackageId,
  showHidden,
}: {
  projectId: string;
  workPackageId: string;
  showHidden?: boolean;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageTimelineEntry[]> = ({
  projectId,
  workPackageId,
  showHidden = true,
}) =>
  flow(getWorkPackageTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.projectId === projectId &&
          entry.snapshotTypeId === '3' &&
          entry.snapshotId === null &&
          (entry.hide === false || entry.hide === showHidden)
      );

      return filteredEntries;
    })
  );

export const getWorkPackagePreviouslyPlannedPoCEntries: ({
  projectId,
  workPackageId,
  showHidden,
}: {
  projectId: string;
  workPackageId: string;
  showHidden?: boolean;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageTimelineEntry[]> = ({
  projectId,
  workPackageId,
  showHidden = true,
}) =>
  flow(getWorkPackageTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.latestSnapshot &&
          entry.pastPeriod === false &&
          (entry.hide === false || entry.hide === showHidden)
      );

      return filteredEntries;
    })
  );

export const getWorkPackageSnapshotPoCEntries: ({
  projectId,
  workPackageId,
  showHidden,
}: {
  projectId: string;
  workPackageId: string;
  showHidden?: boolean;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageTimelineEntry[]> = ({
  projectId,
  workPackageId,
  showHidden = true,
}) =>
  flow(getWorkPackageTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.workPackageId === workPackageId &&
          entry.projectId === projectId &&
          entry.snapshotId !== null &&
          entry.pastPeriod &&
          (entry.hide === false || entry.hide === showHidden)
      );

      return filteredEntries;
    })
  );
