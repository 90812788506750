import { useIntl } from 'react-intl';

import { TextId } from '../localization';

export default function useTxt(
  id?: TextId,
  values?: Record<string, string | number>
): string {
  const intl = useIntl();

  if (!id) {
    return '';
  }

  return intl.formatMessage({ id }, values);
}
