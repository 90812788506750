import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import * as uuid from 'uuid';

import { getRequestState } from '../../../../store/reducers/target/moveTargetRows';
import { getSelectedTargetRowsForOrder } from '../../../../store/reducers/target/selection';
import {
  getTargetRowsByIds,
  getTotalPricesSumForTargetRowsWithIds,
} from '../../../../store/reducers/target/targetRows';
import { getTopicsByIds } from '../../../../store/reducers/topic';

import * as action from '../../../../store/actions';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { PrimaryButton, SecondaryButton } from '../../../../components/Buttons';
import Modal, {
  Container,
  Content,
  Header,
  Footer,
} from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import * as big from '../../../../utils/big';
import { isNotNull } from '../../../../utils/general';

import OrderList from '../OrderList';
import TopicDropDown from '../TopicDropDown';

type MoveModalProps = {
  onClose: () => void | undefined;
};

const MoveModal = ({ onClose }: MoveModalProps) => {
  const { orderId } = useRouteParams();

  const [requestId] = React.useState(uuid.v4());

  const [selectedOrder, setSelectedOrder] = React.useState(orderId);

  const targetRowIds = useSelector(getSelectedTargetRowsForOrder(orderId));

  const targetRows = useSelector(getTargetRowsByIds(targetRowIds, orderId));

  const targetRowTopicIds = targetRows
    .map((row) => row.topicId)
    .filter(isNotNull);

  const uniqueTopicIds = [...new Set(targetRowTopicIds)];

  const topics = useSelector(getTopicsByIds(uniqueTopicIds));

  const [selectedTopic, setSelectedTopic] = React.useState<
    { id?: string; isPrimary?: boolean } | undefined
  >({ id: topics.find(({ defaultTopic }) => defaultTopic)?.id });

  const totalPricesSum = useSelector(
    getTotalPricesSumForTargetRowsWithIds(targetRowIds, orderId)
  );
  const dispatch = useDispatch();

  const sendRequest = () => {
    if (!selectedTopic?.id) {
      return;
    }

    if (selectedTopic?.id === 'newTopic') {
      dispatch(
        action.moveTargetRowsToOrder({
          requestId,
          orderId: selectedOrder,
          targetRowIds,
        })
      );
    }

    dispatch(
      action.moveTargetRowsToTopic({
        requestId,
        topicId: selectedTopic.id,
        targetRowIds,
      })
    );
  };

  const [filter, setFilterText] = React.useState('');
  const placeHolder = useTxt('order.targetMode.moveModal.filterPlaceHolder');

  const requestState = useSelector(getRequestState(requestId));

  React.useEffect(() => {
    if (requestState === 'Success') {
      onClose();
    }
  }, [requestState, onClose]);

  const isActionButtonDisabled =
    selectedTopic === undefined || requestState === 'Loading';

  const titleText = useTxt('order.targetMode.moveModal.title', {
    amount: targetRowIds.length,
    totalPrice: big.priceFormatRounded(totalPricesSum),
  });

  const selectionInfo = useTxt(
    'order.editMode.moveModal.dropdown.selectionInfo'
  );

  const handleChange = useCallback((newTopic) => {
    setSelectedTopic(newTopic);
  }, []);

  return (
    <Modal onClose={onClose}>
      <Container>
        <Header>{titleText}</Header>
        <Content noMaxHeight>
          <StyledLabel htmlFor="filter">
            <Txt id="order.targetMode.moveModal.filterLabel" />
            <FilterTextInput
              name="filter"
              id="filter"
              placeholder={placeHolder}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </StyledLabel>
          <Txt
            id="order.targetMode.moveModal.scrollListTitle"
            component={ListTitle}
          />
          <OrderList
            filter={filter}
            selected={selectedOrder}
            onSelect={setSelectedOrder}
            allNotRemovable={false}
          />
          <Txt
            id="order.targetMode.moveModal.dropdownLabel"
            component={ListTitle}
          />
          <TopicDropDown
            orderId={selectedOrder}
            onChange={handleChange}
            currentTopics={topics}
            selectedTopic={selectedTopic}
          />
          <Txt
            id="order.targetMode.moveModal.bottomInfo"
            component={BottomTitle}
          />
        </Content>

        <StyledFooter>
          {!selectedTopic?.isPrimary ? (
            <SelectionIndicator>{selectionInfo}</SelectionIndicator>
          ) : null}
          <ActionButtons>
            <CancelButton onClick={onClose}>
              <Txt id="common.cancel" />
            </CancelButton>
            <ActionButton
              onClick={sendRequest}
              disabled={isActionButtonDisabled}
            >
              <Txt id="order.targetMode.moveModal.button.action" />
            </ActionButton>
          </ActionButtons>
        </StyledFooter>
      </Container>
    </Modal>
  );
};

export default MoveModal;

const SelectionIndicator = styled.label`
  color: ${(props) => props.theme.color.red};
`;

const StyledLabel = styled.label`
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;

  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
`;

const StyledFooter = styled(Footer)`
  display: grid;
  grid-template-columns: 1fr auto auto;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ListTitle = styled.h2`
  margin-bottom: 1rem;
  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
`;

const BottomTitle = styled.h2`
  margin: 1rem;

  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
`;

const FilterTextInput = styled.input`
  margin-top: 1rem;

  border-radius: 4px;
  border: 1px solid ${({ theme: { color } }) => `${color.graphiteB91}`};

  padding: 0 1rem;

  min-height: 3rem;

  background: ${({ theme: { color } }) => `${color.graphiteB96A}`};

  color: ${({ theme: { color } }) => `${color.pitch}`};
  font-family: 'Graphik', sans-serif;
  font-size: 0.875;

  ::placeholder {
    opacity: 0.5;
  }
`;

const CancelButton = styled(SecondaryButton)`
  margin-right: 4px;
`;

const ActionButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

export const ButtonGroup = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
`;
