import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import { BackendError } from '../../utils/api';
import * as big from '../../utils/big';
import { isPresent, isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';

import { APIWorkPackageGroup } from '../actions/workPackageGroup';
import { ActionTypes } from '../actionTypes';
import { getWorkPackageById } from './workPackage';

import { AppState } from '.';

export type WorkPackageGroup = {
  id: ID;
  name: string;
  code: string;
  projectId: string;
  workPackageIds: ID[];
};

export type WorkPackageGroupState = Partial<
  Record<
    string,
    remoteData.RemoteData<
      Partial<Record<string, APIWorkPackageGroup>>,
      BackendError | undefined
    >
  >
>;

const initialState: WorkPackageGroupState = {};

const workPackageGroupReducer: Reducer<WorkPackageGroupState, ActionTypes> = (
  state = initialState,
  action
): WorkPackageGroupState => {
  switch (action.type) {
    case 'GET_WORK_PACKAGE_GROUPS_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.loading,
      };
    }
    case 'GET_WORK_PACKAGE_GROUPS_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.fail(error),
      };
    }
    case 'GET_WORK_PACKAGE_GROUPS_SUCCESS': {
      const { projectId, workPackageGroups } = action.payload;
      const normalizedworkPackageGroups = normalizeBy('id', workPackageGroups);

      return {
        ...state,
        [projectId]: remoteData.succeed(normalizedworkPackageGroups),
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { workPackageGroups: updatedWorkPackageGroups = [] } = action.payload;

    return updatedWorkPackageGroups.reduce((nextState, workPackageGroup) => {
      const { id, projectId } = workPackageGroup;

      const {
        [projectId]: remoteWorkPackageGroups = remoteData.notAsked,
      } = nextState;

      return {
        ...nextState,
        [projectId]: remoteData.map(
          remoteWorkPackageGroups,
          ({ [id]: _, ...workPackageGroups }) => {
            return { [id]: workPackageGroup, ...workPackageGroups };
          }
        ),
      };
    }, state);
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default workPackageGroupReducer;

export const selectProjectWorkPackageGroups = (projectId: string) => ({
  workPackageGroups: { [projectId]: workPackageGroups = remoteData.notAsked },
}: AppState) => workPackageGroups;

export const getWorkPackageGroupById = (workPackageGroupId: string) => ({
  workPackageGroups,
}: AppState): APIWorkPackageGroup | undefined =>
  Object.values(workPackageGroups)
    .map((remoteWorkPackageGroups) =>
      remoteData.unwrap(remoteWorkPackageGroups ?? remoteData.notAsked, {
        unwrapper: ({ [workPackageGroupId]: workPackageGroup }) =>
          workPackageGroup,
        defaultValue: undefined,
      })
    )
    .find(isPresent);

export const getProjectWorkPackageGroups = (projectId: string) => ({
  workPackageGroups: {
    [projectId]: projectWorkPackageGroups = remoteData.notAsked,
  },
}: AppState): remoteData.RemoteData<APIWorkPackageGroup[]> =>
  remoteData.map(projectWorkPackageGroups, (workPackageGroups) =>
    Object.values(workPackageGroups)
      .filter(isDefined)
      .sort((a, b) => (a.code < b.code ? -1 : 1))
  );

export const getWorkPackageGroupsByProjectId = (projectId: string) => (
  appState: AppState
): APIWorkPackageGroup[] => {
  const remoteWorkPackageGroups = getProjectWorkPackageGroups(projectId)(
    appState
  );

  const workPackageGroups = remoteData.withDefault(remoteWorkPackageGroups, []);

  return workPackageGroups;
};

type WorkPackageGroupTotals = {
  targetTotal: Big;
  additionalTargetTotal: Big;
  predictionTotal: Big;
  predictionChangeTotal: Big;
  changeOrderTotal: Big;
  contractTotal: Big;
  receivedTotal: Big;
  reservesTotal: Big;
};

export const getWorkPackageGroupTotals = (workPackageGroupId: string) => (
  appState: AppState
): WorkPackageGroupTotals => {
  const workPackageGroup = getWorkPackageGroupById(workPackageGroupId)(
    appState
  );
  const workPackageIds = workPackageGroup?.workPackageIds ?? [];

  const workPackages = workPackageIds
    .map((workPackageId) => getWorkPackageById(workPackageId)(appState))
    .filter(isDefined);

  return {
    targetTotal: big.sum(...workPackages.map((o) => o.targetTotal)),
    additionalTargetTotal: big.sum(
      ...workPackages.map((o) => o.additionalTargetTotal)
    ),
    predictionTotal: big.sum(...workPackages.map((o) => o.predictionTotal)),
    predictionChangeTotal: big.sum(
      ...workPackages.map((o) => o.predictionChangeFromLatest)
    ),
    changeOrderTotal: big.sum(...workPackages.map((o) => o.changeOrdersTotal)),
    contractTotal: big.sum(...workPackages.map((o) => o.contractTotal)),
    receivedTotal: big.sum(...workPackages.map((o) => o.receivedTotal)),
    reservesTotal: big.sum(...workPackages.map((o) => o.reservesTotal)),
  };
};
