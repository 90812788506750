import React, { useRef } from 'react';

import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

import { StyledTooltip } from './Tooltip';

export interface HTMLTooltipProps {
  htmlTip: React.ReactElement<any>;
  disable?: boolean;
  place?: 'top' | 'bottom' | 'left' | 'right';
  children?: any;
  tipId?: string;
  className?: string;
  delayHide?: number;
  delayShow?: number;
  noWrap?: boolean;
}

const HTMLToolTip = (props: HTMLTooltipProps) => {
  const {
    children,
    htmlTip,
    place,
    disable,
    tipId,
    className,
    delayHide,
    delayShow,
    noWrap,
  } = props;

  const hiddenSpan = useRef<HTMLSpanElement>(null);
  const realTipId = tipId ?? uuid();

  return (
    <>
      <HiddenSpan ref={hiddenSpan}>{htmlTip}</HiddenSpan>
      <AnchorAreaSpan data-tip="" data-for={realTipId} noWrap={noWrap}>
        {children}
      </AnchorAreaSpan>
      <StyledTooltip
        disable={disable}
        effect="solid"
        type="dark"
        place={place || 'top'}
        id={realTipId}
        className={className}
        delayHide={delayHide}
        delayShow={delayShow}
        role="tooltip"
        html
      >
        {hiddenSpan.current?.innerHTML}
      </StyledTooltip>
    </>
  );
};

const HiddenSpan = styled.span`
  display: none;
`;

const AnchorAreaSpan = styled.span<{ noWrap?: boolean }>`
  ${(props) =>
    props.noWrap
      ? `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      `
      : null}
`;

export default HTMLToolTip;
