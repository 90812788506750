import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getDeleteRequestState } from '../../../../store/reducers/target/targetRows';
import { getIsOuterBarOpen } from '../../../../store/reducers/ui';

import {
  requestDeleteTargetRow,
  TargetRow,
} from '../../../../store/actions/target/targetRow';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { TagsContainer } from '../../../../components/Analysis/AnalysisTags';
import { IconButton } from '../../../../components/Buttons';
import Cell, { MoneyCell } from '../../../../components/Cell';
import Tooltip from '../../../../components/Tooltip';

import * as big from '../../../../utils/big';
import CAN, {
  CaslPaymentProgramRowRequestParams,
} from '../../../../utils/caslUserPermissions';

import { IconSplitRow, IconSuffix } from '../../../../assets';
import { IconDelete } from '../../../../assets/svg';

import AnalysisTags from '../AnalysisTags';
import Checkbox from './Checkbox';
import { StyledSplitIcon } from './Target';

type TargetedRowProps = {
  row: TargetRow;
  disableCheckbox?: boolean;
};

const TargetedRow = ({
  row: {
    id: targetRowId,
    description,
    quantity,
    unit,
    unitPrice,
    totalPrice,
    isDeletable,
    isDisabled,
    isSplitFrom,
    analysisListItemIds,
  },
  disableCheckbox,
}: TargetedRowProps) => {
  const suffixAlt = useTxt('target.table.alt.suffix');
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const requestState = useSelector(getDeleteRequestState(targetRowId));

  const isButtonDisabled = requestState !== 'NotAsked';

  const dispatch = useDispatch();

  const { projectId, viewMode } = useRouteParams();

  const ability = new CaslPaymentProgramRowRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const onDeleteTargetRow = () => {
    dispatch(requestDeleteTargetRow({ targetRowId }));
  };

  const colSpanLength = () => {
    if (viewMode === 'edit' && !outerBarOpen) {
      return 4;
    }

    if (viewMode === 'edit' && outerBarOpen) {
      return 3;
    }

    if (viewMode === 'normal' && outerBarOpen) {
      return 3;
    }

    if (viewMode === 'receive') {
      return 5;
    }

    if (viewMode === 'normal' && !outerBarOpen) {
      return 4;
    }

    return 4;
  };

  const removeText = useTxt('order.inputs.Remove');

  const splitTargetTip = useTxt('order.targetMode.splitIcon.tooltip.splitFrom');

  const disabledSplitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.disabled'
  );

  if (isSplitFrom && isDisabled) {
    return null;
  }

  return (
    <Tr data-testid={`targeted-row-${targetRowId}`} isDisabled={isDisabled}>
      {viewMode === 'edit' ? <SuffixCell /> : null}
      <SuffixCell align="center">
        <SuffixCircle>
          <img src={IconSuffix} alt={suffixAlt} />
        </SuffixCircle>
      </SuffixCell>
      <StyledCellWithLeftBorder
        contentContainer={StyledContainer}
        isDisabled={isDisabled}
      >
        {viewMode === 'edit' ? (
          <Checkbox
            targetRowId={targetRowId}
            disabled={!allowedUser || disableCheckbox || isDisabled}
          />
        ) : null}
        <DescriptionSpan>{description}</DescriptionSpan>
        {isSplitFrom || isDisabled ? (
          <Tooltip tip={isSplitFrom ? splitTargetTip : disabledSplitTargetTip}>
            <StyledSplitIcon src={IconSplitRow} alt="split_icon" />
          </Tooltip>
        ) : null}
      </StyledCellWithLeftBorder>
      {outerBarOpen ? (
        <StyledCell align="right">
          {`${big.amountFormat(quantity || new Big(0), 0)} ${unit}`}
        </StyledCell>
      ) : (
        <>
          <StyledCell align="right">
            {big.amountFormat(quantity || new Big(0))}
          </StyledCell>
          <StyledCell align="center">{unit}</StyledCell>
        </>
      )}
      <StyledMoneyCell
        value={unitPrice || new Big(0)}
        decimals={outerBarOpen ? 0 : undefined}
      />
      <StyledMoneyCell value={totalPrice} decimals={outerBarOpen ? 0 : 2} />
      <StyledCell colSpan={colSpanLength()} />
      {outerBarOpen || viewMode === 'receive' ? null : (
        <>
          <StyledCell align="center" contentContainer={TagsContainer}>
            <AnalysisTags
              targetRowId={targetRowId}
              analysisListItemIds={analysisListItemIds}
            />
          </StyledCell>
        </>
      )}
      <StyledCell colSpan={2} />
      {viewMode === 'edit' ? (
        <StyledCell align="center">
          {isDeletable ? (
            <IconButton
              icon={IconDelete}
              onClick={onDeleteTargetRow}
              aria-label={removeText}
              disabled={isButtonDisabled}
            />
          ) : null}
        </StyledCell>
      ) : null}
    </Tr>
  );
};

type TrProps = {
  isDisabled?: boolean;
};

const Tr = styled.tr<TrProps>`
  position: relative;
  background: ${(props) =>
    props.isDisabled
      ? props.theme.color.graphiteB76
      : props.theme.color.whisper};
  color: ${(props) =>
    props.isDisabled ? props.theme.color.graphiteB48 : props.theme.color.pitch};
`;

export const TargetCell = styled(Cell)`
  color: ${({
    theme: {
      color: { purple },
    },
  }) => purple};
`;

const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

const StyledCellWithLeftBorder = styled(Cell)<TrProps>`
  border-bottom: 1px solid transparent;
  padding-left: ${({ theme: { margin } }) => margin[16]};
  background: linear-gradient(white, white) left/8px 100% no-repeat
    ${(props) =>
      props.isDisabled
        ? props.theme.color.graphiteB76
        : props.theme.color.whisper};
  border-image: linear-gradient(
      to right,
      white 9px,
      ${({ theme: { color } }) => color.graySuit} 9px 100%
    )
    1;
`;

const StyledContainer = styled.div`
  padding: ${({ theme: { margin } }) => margin[8]} 0;
  display: flex;
  align-items: center;
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

const DescriptionSpan = styled.span`
  margin-left: ${({ theme: { margin } }) => margin[24]};
`;

export const SuffixCell = styled(Cell)`
  padding: 0;
  background-color: white;
`;

export const SuffixCircle = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme: { margin, color } }) => `
    height: ${margin[32]};
    width: ${margin[32]};
    background-color: ${color.graphiteB96A};
  `}
`;

export default TargetedRow;
