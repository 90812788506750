export type RecordKey = string | symbol | number;

export function keys<K extends RecordKey>(
  r: Record<K, unknown> | Partial<Record<K, unknown>>
): K[] {
  const unknownKeys: unknown[] = Object.keys(r);
  const castedKeys: K[] = unknownKeys as K[];

  return castedKeys;
}

export function mapValues<Key extends RecordKey, A, B>(
  record: Record<Key, A>,
  fn: (a: A) => B
): Record<Key, B> {
  return keys(record).reduce(
    (acc: Partial<Record<Key, B>>, key) => ({
      ...acc,
      [key]: fn(record[key]),
    }),
    {}
  );
}

export function mergeWith<Key extends RecordKey, Value, MergedValue>(
  left: Record<Key, Value>,
  right: Record<Key, Value>,
  merge: (leftValue: Value, rightValue: Value) => MergedValue
): Record<Key, MergedValue> {
  return keys(left).reduce(
    (acc: Partial<Record<Key, MergedValue>>, key) => ({
      ...acc,
      [key]: merge(right[key], left[key]),
    }),
    {}
  );
}

export function pickBy<Key extends RecordKey, Value>(
  record: Record<Key, Value>,
  predicate: (value: Value, key: Key) => boolean
): Partial<Record<Key, Value>> {
  return keys(record).reduce((acc: Partial<Record<Key, Value>>, key) => {
    const { [key]: value } = record;

    return predicate(value, key) ? { ...acc, [key]: value } : acc;
  }, {});
}
