import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { dateString } from '../../../utils/decoders';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/schedule/scheduledTasks';

const actionCreators = {
  ...makeAction('getWorkPackageScheduledTasksStarted')<{
    workPackageId: string;
  }>(),
  ...makeAction('getWorkPackageScheduledTasksSuccess')<{
    workPackageId: string;
    projectScheduledTasksEntries: ScheduledTask[];
  }>(),
  ...makeAction('getWorkPackageScheduledTasksFailure')<{
    workPackageId: string;
    error: BackendError | undefined;
  }>(),
};

export type ScheduledTasksAction = ExtractActionTypes<typeof actionCreators>;

const apiScheduledTaskEntryType = t.exact(
  t.type({
    id: t.string,
    projectId: t.string,
    parentId: t.union([t.string, t.null]),
    name: t.union([t.string, t.null]),
    level: t.number,
    plannedStartTime: t.union([t.string, t.null]),
    plannedEndTime: t.union([t.string, t.null]),
    startedAt: t.union([t.string, t.null]),
    finishedAt: t.union([t.string, t.null]),
    workAmount: t.union([t.string, t.null]),
    taskCompletionPercentage: t.union([t.string, t.null]),
    isDeleted: t.boolean,
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export type ScheduledTask = t.TypeOf<typeof apiScheduledTaskEntryType>;

const {
  getWorkPackageScheduledTasksStarted,
  getWorkPackageScheduledTasksSuccess,
  getWorkPackageScheduledTasksFailure,
} = actionCreators;

export async function toWorkSectionScheduledTasksEntries(
  u: unknown
): Promise<ScheduledTask[]> {
  const scheduleTaskRows = await tPromise.decode(
    t.array(apiScheduledTaskEntryType),
    u
  );

  return scheduleTaskRows;
}

const requestWorkSectionScheduledTasks = async ({
  workPackageId,
}: {
  workPackageId: string;
}): Promise<ScheduledTask[]> => {
  const response = await GET<ScheduledTask[]>(
    `v1/work-packages/${workPackageId}/schedule-tasks`
  );

  return toWorkSectionScheduledTasksEntries(response);
};

export const getScheduledTasksForWorkpackage = ({
  workPackageId,
}: {
  workPackageId: string;
}) =>
  createAsyncThunk(requestWorkSectionScheduledTasks, {
    args: [{ workPackageId }],
    initialAction: getWorkPackageScheduledTasksStarted({ workPackageId }),
    isPending: isLoading(workPackageId),
    failureActionCreator: (error) =>
      getWorkPackageScheduledTasksFailure({
        workPackageId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (projectScheduledTasksEntries) =>
      getWorkPackageScheduledTasksSuccess({
        workPackageId,
        projectScheduledTasksEntries,
      }),
  });
