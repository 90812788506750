import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes as Action } from '../../actionTypes';

type OrderDeletes = Partial<Record<string, remoteData.RemoteAction>>;

function orderDeleteReducer(state: OrderDeletes = {}, action: Action) {
  switch (action.type) {
    case 'DELETE_ORDER_STARTED': {
      return { ...state, [action.payload.requestId]: remoteData.loading };
    }
    case 'DELETE_ORDER_FAILURE': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.fail(action.payload.error),
      };
    }
    case 'DELETE_ORDER_SUCCESS': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.succeed(undefined),
      };
    }
    default:
      return state;
  }
}

export function getOrderDeleteRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    orders: {
      deleteRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export default orderDeleteReducer;
