import { useRef, useEffect, useState } from 'react';

import { debounce } from 'lodash';

export const useDebounce = <T extends any[]>(
  fn: (...args: T) => void,
  delay: number
) => {
  // Save debounced update function using useRef so that it persists between renders
  const debouncedUpdate = useRef(debounce(fn, delay));

  // Execute debounced function call on component unmount
  useEffect(() => {
    // React advised to do this. In our case it's not necessary, but to get rid
    // of the warning, I guess doing it like this does not hurt either. Calling
    // debouncedUpdate.current.flush() straight will warn about possibly changed
    // value, which might be the case for DOM nodes.
    const debounceFn = debouncedUpdate.current;

    return () => {
      debounceFn.flush();
    };
  }, []);

  return debouncedUpdate.current;
};

// Does not execute function call on component unmount
// does not save update function so that it persists between renders
export function useDebouncedValue<Value>(value: Value, milliseconds: number) {
  const [debouncedValue, setDebouncedValue] = useState<Value>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), milliseconds);

    return () => {
      clearTimeout(timer);
    };
  }, [value, milliseconds]);

  return debouncedValue;
}

// perform initial stage change with side eiffect
// e.g openning the first invoice on initial mount
export const useInitialStateChange = <T extends any>(
  fn: (args: T) => void,
  args: any
) => {
  const [mounted, setMounted] = useState(false);

  const resetInit = () => setMounted(false);

  useEffect(() => {
    if (!mounted && fn) {
      setMounted(true);
      fn(args);
    }
  }, [mounted, fn, args]);

  return [resetInit];
};
