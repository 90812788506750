import styled from 'styled-components';

type Props = {
  laariMode?: 'normal' | 'minimized'; // undefined = no laari present
};

const Toolbar = styled.div<Props>`
  bottom: ${(props) =>
    `calc(${props.theme.margin[16]} ${
      props.laariMode ? `+ ${props.theme.margin[56]}` : ''
    } ${props.laariMode === 'normal' ? '+ 17rem' : ''} )`};
  left: ${(props) => props.theme.margin[16]};

  border-radius: 0.5rem 0.5rem 0 0;

  padding: 0 ${(props) => props.theme.margin[24]};

  width: 100%;
  height: ${(props) => props.theme.margin[56]};

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => props.theme.color.toolbarHighlight};

  z-index: 20;
`;

export default Toolbar;
