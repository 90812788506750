import React from 'react';

import styled, { css } from 'styled-components';

import useTxt from '../../hooks/useTxt';

import { IconKebabMenu, IconPlus } from '../../assets/svg';

import AnalysisLinker from '../AnalysisLinker';

const analysisTagHeight = '0.875rem';
const analysisTagWidth = '3.25rem';
const plusTagSize = '2rem';
const moreTagWidth = '1.25rem';
const moreTagHeight = '2rem';

export const EDIT_TAG_AMOUNT = 2;
export const VIEW_TAG_AMOUNT = 4;

export const StyledAnalysisLinker = styled(AnalysisLinker)`
  position: absolute;
  top: 0;
  right: 2.5rem;
  z-index: 10;
`;

export const Tag = styled.button<{ compressed?: boolean }>`
  margin: 0 ${({ theme }) => theme.margin[2]};

  border-radius: ${({ theme }) => theme.radius.tiny};
  border: 1px solid ${({ theme }) => theme.color.mercury};

  padding: 0 ${({ theme }) => theme.margin[10]};

  height: ${analysisTagHeight};
  ${({ compressed }) =>
    compressed
      ? ''
      : css`
          min-width: ${analysisTagWidth};
        `}

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;

  font-size: ${({ theme }) => theme.fontSize.miniscule};
  line-height: ${({ theme }) => theme.margin[10]};
`;

type ShowMoreProps = { compressed?: boolean };

export const ShowMoreTag = ({ compressed }: ShowMoreProps) => {
  const showMoreText = useTxt('order.analysisTags.showMore');

  return (
    <ShowMoreButton aria-label={showMoreText} compressed={compressed}>
      <IconKebabMenu />
    </ShowMoreButton>
  );
};

const ShowMoreButton = styled.button<ShowMoreProps>`
  margin: 0 ${({ theme, compressed }) => (compressed ? '0' : theme.margin[2])};

  border: none;

  ${({ compressed }) =>
    compressed
      ? ''
      : css`
          min-width: ${moreTagWidth};
        `}
  height: ${moreTagHeight};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
`;

export const PlusTag = ({
  compressed,
  ...props
}: React.HTMLAttributes<any> & {
  isPressed: boolean;
  compressed?: boolean;
}) => {
  const addText = useTxt('order.analysisTags.add');

  return (
    <PlusButton aria-label={addText} compressed={compressed} {...props}>
      <IconPlus />
    </PlusButton>
  );
};

const PlusButton = styled.button<{ isPressed: boolean; compressed?: boolean }>`
  position: relative;

  margin: 0 ${({ theme }) => theme.margin[2]};

  border-radius: ${({ theme }) => theme.radius.tiny};
  border: 1px solid ${({ theme }) => theme.color.mercury};

  ${({ compressed }) =>
    compressed
      ? css`
          padding-inline-end: 0;
          padding-inline-start: 0;
        `
      : css`
          width: ${plusTagSize};
          height: ${plusTagSize};
        `}

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ isPressed, theme: { color } }) =>
    isPressed ? color.dropdownBorder : 'white'};
`;

export const TagsContainer = styled.div`
  padding: ${({ theme }) => theme.margin[4]};

  height: 2.5rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-end;
`;
