import React from 'react';

import styled from 'styled-components';

import {
  NotificationDifferenceByOrder,
  NotificationFeedItem,
} from '../../../types/general';

import * as big from '../../../utils/big';

import { IconCheckmarkFaded } from '../../../assets/svg';

import { TextId } from '../../../localization';
import { generateUrl, routes } from '../../../routes';
import { IconTextButton } from '../../Buttons';
import Txt from '../../Txt';
import {
  NotificationContainer,
  StyledNotificationContent,
  StyledHeader,
  StyledLinkArea,
  NotificationTimeStamp,
  StyledRouterLink,
} from './Components';

type ExternalChangeProps = {
  notification: NotificationFeedItem;
  onCloseSidebar: () => void;
  markAsRead: (item: NotificationFeedItem) => void;
};

const ExternalChangeNotification = ({
  notification,
  onCloseSidebar,
  markAsRead,
}: ExternalChangeProps) => {
  const { newOrders, editedOrders, removedOrders, project } = notification;

  if (!project || !(newOrders || editedOrders || removedOrders)) {
    return null;
  }

  const handleNotificationClick = (): void => {
    if (!notification.isRead) {
      markAsRead(notification);
    }

    if (!project.id) {
      return;
    }

    onCloseSidebar();
  };

  return (
    <NotificationContainer>
      <StyledNotificationContent>
        <StyledHeader>
          <Txt id="navigation.notifications.externalChangeNotifications.targetRows" />
        </StyledHeader>
        {/* new indicator */}
        <NotificationTimeStamp>
          {notification.dismissedAt?.toLocaleDateString()}
        </NotificationTimeStamp>

        <ExternalChangeLinkArea>
          {/* project info */}
          {notification.project ? notification.project.label : null}

          {/* notifications */}
          <ExternalOrderDifferences
            project={project}
            title="navigation.notifications.externalChangeNotifications.newOrders"
            key="new-order-link"
            onCloseSidebar={onCloseSidebar}
            diffs={notification.newOrders}
          />
          <ExternalOrderDifferences
            project={project}
            title="navigation.notifications.externalChangeNotifications.removedOrders"
            key="removed-order-link"
            onCloseSidebar={onCloseSidebar}
            diffs={notification.removedOrders}
          />
          <ExternalOrderDifferences
            project={project}
            title="navigation.notifications.externalChangeNotifications.editedOrders"
            key="edited-order-link"
            onCloseSidebar={onCloseSidebar}
            diffs={notification.editedOrders}
          />
        </ExternalChangeLinkArea>

        <ExternalChangeFooter>
          <ExternalChangeRouterLink
            to={generateUrl({
              route: routes.REPORTING,
              projectId: project.id,
            })}
            onClick={onCloseSidebar}
          >
            <Txt id="navigation.notifications.externalChangeNotifications.checkOlderChanges" />
          </ExternalChangeRouterLink>

          <ExternalChangeIconButton
            icon={IconCheckmarkFaded}
            onClick={handleNotificationClick}
          >
            <Txt id="common.dismiss" />
          </ExternalChangeIconButton>
        </ExternalChangeFooter>
      </StyledNotificationContent>
    </NotificationContainer>
  );
};

type ExternalOrderDifferencesProps = {
  title: TextId;
  project: {
    id: string;
    label: string;
  };
  key: string;
  onCloseSidebar: () => void;
  diffs?: NotificationDifferenceByOrder[];
};

const ExternalOrderDifferences = ({
  title,
  project,
  key,
  onCloseSidebar,
  diffs,
}: ExternalOrderDifferencesProps) =>
  project && diffs && diffs.length > 0 ? (
    <>
      <ExternalNotificationsListHeader>
        <Txt id={title} />
      </ExternalNotificationsListHeader>
      {diffs.map((diff) => {
        let diffStr = big.priceFormat(diff.difference, 0);

        if (!(diffStr.startsWith('0') || diffStr.startsWith('-'))) {
          diffStr = `+${diffStr}`;
        }

        return (
          <ExternalChangeRouterLink
            key={`${key}-${diff.order.id}`}
            to={generateUrl({
              route: routes.ORDER,
              projectId: project.id,
              orderId: diff.order.id,
              viewMode: 'receive',
              subViewMode: 'invoices',
            })}
            onClick={onCloseSidebar}
          >
            {diff.order.label} | {diffStr}
          </ExternalChangeRouterLink>
        );
      })}
    </>
  ) : null;

const ExternalChangeLinkArea = styled(StyledLinkArea)`
  color: ${({ theme }) => theme.color.sidebarFadedText};
`;

const ExternalNotificationsListHeader = styled.div`
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  font-weight: bold;
`;

const ExternalChangeRouterLink = styled(StyledRouterLink)`
  margin-top: 0;
  color: ${({ theme }) => theme.color.sidebarFadedText};
`;

const ExternalChangeFooter = styled.div`
  padding-top: 1rem;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.sidebarFadedText};
`;

const ExternalChangeIconButton = styled(IconTextButton)`
  padding: ${(props) => props.theme.margin[4]}
    ${(props) => props.theme.margin[10]};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.sidebarFadedText};
  float: right;
`;

export default ExternalChangeNotification;
