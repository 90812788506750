import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isTargetRowSelected } from '../../../../store/reducers/target/selection';

import * as Actions from '../../../../store/actions';

import useRouteParams from '../../../../hooks/useRouteParams';

import CheckboxComponent from '../../../../components/Input/Checkbox';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  targetRowId: string;
};

const Checkbox = ({ targetRowId, ...rest }: CheckboxProps) => {
  const { orderId } = useRouteParams();

  const defaultChecked = useSelector(
    isTargetRowSelected({ orderId, targetRowId })
  );

  const dispatch = useDispatch();

  const onChange = () =>
    dispatch(Actions.toggleTargetRowSelection({ orderId, targetRowId }));

  return (
    <CheckboxComponent checked={defaultChecked} onChange={onChange} {...rest} />
  );
};

export default Checkbox;
