import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { getOrderRowsByTopicId } from '../../../../store/reducers/orderRow';
import {
  getAllTargetRowsForTopic,
  getTopicTargetRows,
} from '../../../../store/reducers/target/targetRows';
import { getTopicsByOrderId } from '../../../../store/reducers/topic';
import {
  isOrderRowInSelected,
  multipleOrderRowsInState,
  getUIState,
  isTopicOpen,
} from '../../../../store/reducers/ui';

import { toggleMultipleTargetRowSelection } from '../../../../store/actions/target/selection';
import { requestTargetRows } from '../../../../store/actions/target/targetRow';
import {
  orderRowSelectionToggled,
  allOrderRowsSelectionToggled,
  uiTopicToggled,
} from '../../../../store/actions/ui';

import { APITopic } from '../../../../types/api';
import { ViewModeOptions } from '../../../../types/general';

import useRemoteData from '../../../../hooks/useRemoteData';

import { isDefined } from '../../../../utils/general';

import EditableOrderRow from '../../ViewModeWrappers/Edit/components/EditableOrderRow/EditableOrderRow';
import TopicControlRow from '../../ViewModeWrappers/Edit/components/TopicControlRow';
import ReceiveOrderRow from '../../ViewModeWrappers/Receive/ReceiveOrderRow';
import OrderRow from '../OrderRow';
import TargetRow from '../Target/Target';
import TopicRow from './TopicRow';

type TopicProps = {
  projectId: string;
  topic: APITopic;
  viewModeOptions: ViewModeOptions;
};

const Topic = ({ projectId, topic, viewModeOptions }: TopicProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isOpen = useSelector(isTopicOpen(topic.id));
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isScrolled, setScrolled] = useState(false);
  const [topicWasOpened, setTopicWasOpened] = useState(false);

  const topicTargetRows =
    useRemoteData(
      getTopicTargetRows({ orderId: topic.orderId, topicId: topic.id }),
      requestTargetRows({ orderId: topic.orderId })
    ) ?? [];

  const allTargetRowsForTopic =
    useRemoteData(
      getAllTargetRowsForTopic({ orderId: topic.orderId, topicId: topic.id }),
      requestTargetRows({ orderId: topic.orderId })
    ) ?? [];

  const { hash } = history.location;

  const toggleIsOpen = useCallback(() => dispatch(uiTopicToggled(topic.id)), [
    dispatch,
    topic.id,
  ]);

  useEffect(() => {
    if (isScrolled) {
      return;
    }

    // TODO: find a way to define this in routes.ts
    if (hash !== `#topic-${topic.id}`) {
      return;
    }

    if (!isOpen) {
      toggleIsOpen();
    }

    if (rowRef.current) {
      rowRef.current.scrollIntoView();
      setScrolled(true);
    }
  }, [isScrolled, isOpen, toggleIsOpen, hash, topic.id]);

  const orderRows = useSelector(getOrderRowsByTopicId(topic.id));

  useEffect(() => {
    // If any order rows under this topic should be focused, open this topic.
    // Only do this if topic hasn't been already once forced open (otherwise would alway reopen).
    if (orderRows.some((row) => hash === `#orderRowId-${row.id}`)) {
      if (!isOpen && !topicWasOpened) {
        toggleIsOpen();
        setTopicWasOpened(true);
      }
    }
  }, [hash, isOpen, orderRows, toggleIsOpen, topicWasOpened]);

  /* As the selected orderRows are used only in EditMode
   * and by TopicRow and EditableOrderRow components
   * this state was decided to implement as a parent component
   * state, using useReducer
   */
  const selectedState = useSelector(getUIState);

  const onOneRowSelect = (orderRowId: string) => {
    dispatch(orderRowSelectionToggled({ topicId: topic.id, orderRowId }));
  };

  const areAllSelected = multipleOrderRowsInState(
    selectedState.selectedOrderRows,
    topic.id,
    topic.orderRowIds
  );

  const onAllRowsSelect = (topicId: string, orderRowIds: string[]) => {
    const targetRowIds = allTargetRowsForTopic.map((row) => row.id);
    dispatch(allOrderRowsSelectionToggled({ topicId, orderRowIds }));
    dispatch(
      toggleMultipleTargetRowSelection({
        orderId: topic.orderId,
        targetRowIds,
        chooseAll: !areAllSelected,
      })
    );
  };

  return (
    <tbody>
      <TopicRow
        projectId={projectId}
        ref={rowRef}
        topic={topic}
        key={`order-topic-${topic.id}`}
        viewModeOptions={viewModeOptions}
        isOpen={isOpen}
        onToggle={toggleIsOpen}
        onChoose={onAllRowsSelect}
        isSelected={areAllSelected}
      />
      {isOpen ? (
        <>
          {viewModeOptions.showTargetRows ? (
            <>
              {topicTargetRows.map((targetRow) => (
                <TargetRow key={targetRow.id} target={targetRow} />
              ))}
            </>
          ) : null}
          {orderRows.map((orderRow) => {
            if (viewModeOptions.type === 'edit') {
              const isOrderRowSelected = isOrderRowInSelected(
                selectedState.selectedOrderRows,
                topic.id,
                orderRow.id
              );

              const rowHighlighted = hash === `#orderRowId-${orderRow.id}`;

              return (
                <EditableOrderRowWithHighlight
                  orderRow={orderRow}
                  key={`order-row-editable-${orderRow.id}`}
                  isSelected={isOrderRowSelected}
                  onChoose={onOneRowSelect}
                  rowHighlighted={rowHighlighted}
                  viewModeOptions={viewModeOptions}
                />
              );
            }

            if (viewModeOptions.type === 'receive') {
              return (
                <ReceiveOrderRow
                  orderRow={orderRow}
                  key={`order-row-receive-${orderRow.id}`}
                  viewModeOptions={viewModeOptions}
                />
              );
            }

            return (
              <OrderRow
                orderRowId={orderRow.id}
                key={`order-row-${orderRow.id}`}
              />
            );
          })}
          {viewModeOptions.type === 'edit' ? (
            <TopicControlRow topic={topic} />
          ) : null}
        </>
      ) : null}
    </tbody>
  );
};

type TopicsProps = {
  projectId: string;
  orderId: string;
  viewModeOptions: ViewModeOptions;
};

const Topics = ({ projectId, orderId, viewModeOptions }: TopicsProps) => {
  const topics = Object.values(useSelector(getTopicsByOrderId(orderId))).filter(
    isDefined
  );

  return (
    <>
      {topics.map((topic) => (
        <Topic
          projectId={projectId}
          topic={topic}
          viewModeOptions={viewModeOptions}
          key={`topic-container-${topic.id}`}
        />
      ))}
    </>
  );
};
export default Topics;

type EditableOrderRowWithHighlightProps = {
  rowHighlighted: boolean;
};

const EditableOrderRowWithHighlight = styled(
  EditableOrderRow
)<EditableOrderRowWithHighlightProps>`
  @keyframes flash-animation {
    from {
      background: ${(props) => props.theme.color.sidebarBackground};
      opacity: 0.3;
    }
    to {
      background: default;
    }
  }

  ${({ rowHighlighted }) =>
    rowHighlighted
      ? css`
          animation: flash-animation linear 1.5s 1;
        `
      : css``}
`;
