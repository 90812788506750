export type Nullable<A> = A | undefined | null;

/*
 *  Map - apply a function to nullable value. If value is nullish result is
 *  also nullish.
 */
export function map<A, B>(nullable: Nullable<A>, fn: (a: A) => B): Nullable<B> {
  switch (nullable) {
    case undefined:
    case null:
      return nullable;
    default:
      return fn(nullable);
  }
}

/*
 *  Lift - lift a function into the nullable realm.
 */
export function lift<A, B>(fn: (a: A) => B): (fa: Nullable<A>) => Nullable<B> {
  return (fa: Nullable<A>) => map(fa, fn);
}

/*
 *  Append - join two Nullable values together as though they were one.
 *  If either value is nullish result is nullish.
 */
export function append<A, B>(a: Nullable<A>, b: Nullable<B>): Nullable<[A, B]> {
  switch (a) {
    case undefined:
    case null:
      return a;
    default:
      switch (b) {
        case undefined:
        case null:
          return b;
        default:
          return [a, b];
      }
  }
}
