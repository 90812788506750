import { combineReducers } from 'redux';

import projectTimelines from './projectTimeline';
import scheduledTasks from './scheduledTasks';
import workPackageGroupTimelines from './workPackageGroupTimeline';
import workPackageReceivedTimelines from './workPackageReceivedTimeline';
import workPackageTimelines from './workPackageTimeline';

export default combineReducers({
  workPackageTimelines,
  workPackageGroupTimelines,
  projectTimelines,
  scheduledTasks,
  workPackageReceivedTimelines,
});
