import React, { PropsWithChildren } from 'react';

import styled from 'styled-components';

type SDContextProps = {
  outerBarContainer: HTMLDivElement | null;
};

export const AppContext = React.createContext<SDContextProps>({
  outerBarContainer: null,
});

const OuterBarContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: 1;

  flex: 1 1;
`;

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const outerBarContainer = React.useRef<HTMLDivElement | null>(null);
  const theBar = (outerBarContainer && outerBarContainer.current) || null;

  return (
    <AppContext.Provider value={{ outerBarContainer: theBar }}>
      {children}
      <OuterBarContainer ref={outerBarContainer} />
    </AppContext.Provider>
  );
};
