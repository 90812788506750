import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { createMultipleOrderRows } from '../../../../../../store/actions';

import { APITopic } from '../../../../../../types/api';

import useTxt from '../../../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../../../components/Buttons';
import TextArea from '../../../../../../components/Input/TextArea';
import { Spinner } from '../../../../../../components/Loading';
import Modal from '../../../../../../components/Modal/Modal';

import {
  parseOrderRows,
  ParsedResultRow,
  everythingValid,
  constructCreateOrderRowsRequest,
} from './utils';

import {
  Container,
  TitleContainer,
  InputContainer,
  TableContainer,
  BottomControlsContainer,
  InfoContainer,
  ErrorText,
} from './Components';
import PreviewTable from './PreviewTable';

type Props = {
  topic: APITopic;
  onClose: () => void;
};

const ExcelImporter = ({ topic, onClose }: Props) => {
  const dispatch = useDispatch();

  // Raw content of the textarea
  const [content, setContent] = useState('');
  // Parsed rows from the raw content
  const [parsedContent, setParsedContent] = useState<ParsedResultRow[]>([]);
  // Is everything valid in the input?
  const [valid, setValid] = useState(true);
  // Is submit request in progress?
  const [submitting, setSubmitting] = useState(false);

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  useEffect(() => {
    const newParsedContent = parseOrderRows(content);
    setParsedContent(newParsedContent);
    setValid(everythingValid(newParsedContent));
  }, [content]);

  const onSubmit = () => {
    setSubmitting(true);

    dispatch(
      createMultipleOrderRows(
        constructCreateOrderRowsRequest(parsedContent, topic.id, topic.orderId),
        () => {
          onClose();
        }
      )
    );
  };

  const importRowsText = useTxt('order.excelImport.importRows');
  const instructionsText = useTxt('order.excelImport.instructions');
  const errorText = useTxt('order.excelImport.error');
  const cancelText = useTxt('common.cancel');
  const createText = useTxt('order.excelImport.createRows');

  return (
    <Modal onClose={onClose}>
      <Container>
        <TitleContainer>
          <h2>{importRowsText}</h2>
        </TitleContainer>
        <InfoContainer>{instructionsText}</InfoContainer>
        <InputContainer>
          <TextArea value={content} onChange={onTextAreaChange} />
        </InputContainer>
        <TableContainer>
          <PreviewTable rows={parsedContent} />
        </TableContainer>
        <BottomControlsContainer>
          <ErrorText>{!valid ? errorText : null}</ErrorText>
          <ButtonGroup>
            <SecondaryButton onClick={onClose}>{cancelText}</SecondaryButton>
            <PrimaryButton
              disabled={parsedContent.length === 0 || !valid || submitting}
              onClick={onSubmit}
            >
              {submitting ? <Spinner size="1rem" light /> : createText}
            </PrimaryButton>
          </ButtonGroup>
        </BottomControlsContainer>
      </Container>
    </Modal>
  );
};

export default ExcelImporter;
