import React from 'react';
import { Link } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { ActualCost } from '../../../../store/reducers/actualCost';
import { InvoiceHeader } from '../../../../store/reducers/invoiceHeader';

import { APIArrival } from '../../../../types/api';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import Cell, { MoneyCell } from '../../../../components/Cell';
import { BaseRow } from '../../../../components/Table';

import { dateTimeFormat, dateFormat } from '../../../../utils/format';
import { isInvoiceHeader } from '../../../../utils/general';

import { IconToLinkedEntity } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';

type InvoiceRowProps = {
  cost: InvoiceHeader | ActualCost | undefined;
  arrival: APIArrival | undefined;
  arrivalsTotal: Big;
  userName: string;
  onClose: () => void;
};

const InvoiceRow = ({
  cost,
  arrival,
  arrivalsTotal,
  userName,
  onClose,
}: InvoiceRowProps) => {
  const { projectId, orderId } = useRouteParams();

  const iconAltText = useTxt(
    'order.invoicesPerOrderRowModal.table.linkIcon.alt'
  );

  const linkPathName = generateUrl({
    route: routes.ORDER,
    projectId,
    orderId,
    viewMode: 'receive',
    subViewMode: 'invoices',
  });

  const search = '?showAll=true';
  let description: string = '';
  let hash: string = '';

  let testId: string = '';

  if (cost) {
    if (isInvoiceHeader(cost)) {
      description = `${cost.vendorInvoiceNo} ${cost.supplierName}`;
      hash = `#invoiceHeaderId-${cost.id}`;
      testId = `navigate-to-invoice-header-${cost.id}`;
    } else {
      description = `${dateFormat.format(cost.date)} ${cost.documentNumber} ${
        cost.supplierName ?? ''
      }`;
      hash = `#actualCostId-${cost.id}`;
      testId = `navigate-to-actual-cost-${cost.id}`;
    }
  }

  return (
    <Tr>
      <StyledCell align="left">
        {arrival?.createdAt
          ? dateTimeFormat.format(new Date(arrival.createdAt))
          : ''}
      </StyledCell>
      <StyledCell align="left">{userName}</StyledCell>
      <StyledMoneyCell value={arrivalsTotal ?? new Big(0)} />
      <StyledCell>{description}</StyledCell>
      <StyledCell>
        <Link
          data-testid={testId}
          to={{ pathname: linkPathName, search, hash }}
          onClick={onClose}
        >
          <StyledImg src={IconToLinkedEntity} alt={iconAltText} />
        </Link>
      </StyledCell>
    </Tr>
  );
};

const StyledImg = styled.img`
  padding-left: ${({ theme }) => theme.margin[8]};
  vertical-align: middle;
`;

export const Tr = styled(BaseRow)`
  background: ${({
    theme: {
      color: { white },
    },
  }) => white};
`;

export const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${(props) => props.theme.color.mercury};
`;

export const StyledCellWithBackground = styled(StyledCell)`
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${(props) => props.theme.color.mercury};
  font-weight: bold;
`;

export default InvoiceRow;
