import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getProjects, APIProject } from '../../store/reducers/project';

import { ID } from '../../types/general';

import { isDefined } from '../../utils/general';
import * as remoteData from '../../utils/remoteData';

import { IconProject } from '../../assets';

import { generateUrl, routes } from '../../routes';
import ProjectMenu from '../ProjectMenu/ProjectMenu';
import Sidebar from '../Sidebar/Sidebar';
import { TransparentCircleButtonContainer } from './Components';

const ProjectsIcon = () => {
  const projects = remoteData.withDefault(useSelector(getProjects), {});

  const definedProjects: APIProject[] = Object.values(projects).filter(
    isDefined
  );

  const [projectMenuOpen, setProjectMenuOpen] = useState(false);
  const toggleProjectMenu = () => setProjectMenuOpen((open) => !open);
  const history = useHistory();

  const onClickProject = (projectId: ID) => {
    history.push(generateUrl({ route: routes.PROJECT, projectId }));
    setProjectMenuOpen(false);
  };

  return (
    <>
      <TransparentCircleButtonContainer
        onClick={toggleProjectMenu}
        value="Avaa / Sulje"
      >
        <img src={IconProject} alt="projekti ikoni" />
      </TransparentCircleButtonContainer>
      {projectMenuOpen ? (
        <Sidebar align="left" onClose={toggleProjectMenu}>
          <ProjectMenu
            projects={definedProjects}
            onClickProject={onClickProject}
          />
        </Sidebar>
      ) : null}
    </>
  );
};

export default ProjectsIcon;
