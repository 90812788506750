import React from 'react';

import styled from 'styled-components';

import { Selector } from '../../store/reducers/utils';

import { RevenueLinkRequest } from '../../store/actions/analysis/revenueLinkage';
import { AnalysisRow } from '../../store/actions/analysis/row';

import useRemoteData from '../../hooks/useRemoteData';

import * as remoteData from '../../utils/remoteData';
import { Thunk } from '../../utils/thunk';

import Row from './Row';

const filterRows = (filter: string) => ({ code, value }: AnalysisRow) => {
  if (filter === '') {
    return true;
  }

  const lcFilter = filter.toLowerCase();
  const lcCode = code.toLowerCase();
  const lcValue = value.toLowerCase();

  return `${lcCode} ${lcValue}`.includes(lcFilter);
};

type AnalysisListProps = {
  filter: string;
  linkedEntityId: string;
  existingLinks: string[];
  // close: () => void | undefined;
  selector: Selector<remoteData.RemoteData<AnalysisRow[]>>;
  fetchData: Thunk;
  linker: (params: RevenueLinkRequest) => Thunk;
  unlinker: (params: RevenueLinkRequest) => Thunk;
};

const AnalysisRowList = ({
  filter,
  linkedEntityId,
  existingLinks,
  // close,
  selector,
  fetchData,
  linker,
  unlinker,
}: AnalysisListProps) => {
  const rows = useRemoteData(selector, fetchData) ?? [];

  const selectableRows = rows.filter(filterRows(filter));

  return (
    <Container data-testid="analysis-linker-row-list">
      {selectableRows.map((row) => (
        <Row
          key={row.id}
          analysisRow={row}
          linkedEntityId={linkedEntityId}
          existingLinks={existingLinks}
          // close={close}
          linker={linker}
          unlinker={unlinker}
        />
      ))}
    </Container>
  );
};

export default AnalysisRowList;

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.graphiteB76};

  padding-bottom: 1.5rem;

  max-height: 22rem;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
`;
