import Big from 'big.js';
import { sortBy } from 'lodash';
import { Reducer } from 'redux';

import { APIProcurementArea } from '../../types/api';
import { GlobalState } from '../../types/general';

import * as big from '../../utils/big';
import {
  initialGlobalState,
  isDefined,
  updateGlobalStateDerivative,
} from '../../utils/general';

import { ActionTypes } from '../actionTypes';
import { getGlobalStateById } from './helpers/globalStateSelector';
import { getOrderById } from './order/order';
import { getProjectById } from './project';

import { AppState } from '.';

export type ProcurementAreaState = GlobalState<APIProcurementArea>;

const initialState: ProcurementAreaState = initialGlobalState;

const procurementAreaReducer: Reducer<ProcurementAreaState, ActionTypes> = (
  state = initialState,
  action
): ProcurementAreaState => {
  switch (action.type) {
    case 'GET_PROCUREMENT_AREAS_SUCCESS':
      return updateGlobalStateDerivative(state, action.payload);
    case 'POST_ORDER_SUCCESS':
    case 'DELETE_ORDER_SUCCESS':
      return updateGlobalStateDerivative(
        state,
        action.payload.procurementAreas
      );
    default:
      return state;
  }
};

export default procurementAreaReducer;

export const getProcumentAreaById = getGlobalStateById('procurementAreas');

export const getProcurementAreasByProjectId = (projectId: string) => (
  appState: AppState
): APIProcurementArea[] => {
  const project = getProjectById(projectId)(appState);
  const projectProcurementAreaIds = project?.procurementAreaIds ?? [];

  const procurementAreas = projectProcurementAreaIds
    .map((paId) => getProcumentAreaById(paId)(appState))
    .filter(isDefined);
  const sortedPAs = sortBy(procurementAreas, ({ code }) => code);

  return sortedPAs;
};

type ProcurementAreaTotals = {
  targetTotal: Big;
  additionalTargetTotal: Big;
  predictionTotal: Big;
  predictionChangeTotal: Big;
  changeOrderTotal: Big;
  contractTotal: Big;
  receivedTotal: Big;
  reservesTotal: Big;
};

export const getProcurementAreaTotals = (
  procurementAreaId: string,
  statusFilters: string[]
) => (appState: AppState): ProcurementAreaTotals => {
  const procurementArea = getProcumentAreaById(procurementAreaId)(appState);
  const orderIds = procurementArea?.orderIds ?? [];

  const orders = orderIds
    .map((orderId) => getOrderById(orderId)(appState))
    .filter(isDefined);

  const filteredOrders = orders.filter((order) =>
    statusFilters.length === 0 ? true : statusFilters.includes(order.statusId)
  );

  return {
    targetTotal: big.sum(...filteredOrders.map((o) => o.targetTotal)),
    additionalTargetTotal: big.sum(
      ...filteredOrders.map((o) => o.additionalTargetTotal)
    ),
    predictionTotal: big.sum(...filteredOrders.map((o) => o.predictionTotal)),
    predictionChangeTotal: big.sum(
      ...filteredOrders.map((o) => o.predictionChangeFromLatest)
    ),
    changeOrderTotal: big.sum(
      ...filteredOrders.map((o) => o.changeOrdersTotal)
    ),
    contractTotal: big.sum(...filteredOrders.map((o) => o.contractTotal)),
    receivedTotal: big.sum(...filteredOrders.map((o) => o.receivedTotal)),
    reservesTotal: big.sum(...filteredOrders.map((o) => o.reservesTotal)),
  };
};
