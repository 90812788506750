import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  RawAPIUpdatedEntities,
  APIUpdatedEntities,
  APIMultiplePaymentProgramRowBody,
} from '../../types/api';
import { mapRawUpdatedEntities } from '../../types/mappers';

import {
  makeApiActions,
  makeAction,
  ExtractActionTypes,
} from '../../utils/actionCreators';
import {
  GET,
  POST,
  PUT,
  BackendError,
  DELETE,
  apiErrorHandlingWithDecode,
} from '../../utils/api';
import { dateString, bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import { isDefined } from '../../utils/general';
import invertObject from '../../utils/invertObject';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { getRevenueDeleteRequest } from '../reducers/revenue/deleteRevenue';
import {
  getRevenue,
  getProjectRevenue,
  Revenue,
} from '../reducers/revenue/revenue';
import { SortableKey } from '../reducers/revenue/sortRevenue';
import { getRevenueUpdateRequest } from '../reducers/revenue/updateRevenue';

export type RevenueAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getRevenuesRequested')<{ projectId: string }>(),
  ...makeAction('getRevenuesSucceeded')<{
    projectId: string;
    revenues: Revenue[];
  }>(),
  ...makeAction('getRevenuesFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),

  ...makeAction('putRevenueRequested')<{ requestId: string }>(),
  ...makeAction('putRevenueFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putRevenueSucceeded')<
    APIUpdatedEntities & { requestId: string }
  >(),

  ...makeAction('postRevenueRequested')<{ requestId: string }>(),
  ...makeAction('postRevenueFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postRevenueSuccess')<
    APIUpdatedEntities & { requestId: string }
  >(),

  ...makeAction('revenuesSortOrderToggled')<{
    sortableKey: SortableKey;
  }>(),
  ...makeAction('deleteRevenueStarted')<{
    requestId: string;
    projectId: string;
  }>(),
  ...makeAction('deleteRevenueFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deleteRevenueSuccess')<
    APIUpdatedEntities & { requestId: string; projectId: string }
  >(),
  ...makeApiActions('post', 'paymentProgram')<APIUpdatedEntities>(),
};

export const {
  getRevenuesRequested,
  getRevenuesFailure,
  getRevenuesSucceeded,

  putRevenueRequested,
  putRevenueFailure,
  putRevenueSucceeded,

  revenuesSortOrderToggled,

  deleteRevenueStarted,
  deleteRevenueFailure,
  deleteRevenueSuccess,

  postPaymentProgramStarted,
  postPaymentProgramSuccess,
  postPaymentProgramFailure,
} = actionCreators;

// TODO backend should return more descriptive names?
const statusToId = {
  Preliminary: '1',
  Planned: '2',
  Accepted: '3',
  Invoiced: '4',
  Paid: '5',
} as const;

const idToStatus = invertObject(statusToId);

const apiRevenueType = t.exact(
  t.type({
    id: t.string,
    visiblePaymentBatchCode: t.string,
    projectId: t.string,
    description: t.union([t.string, t.null]),
    paymentBatchGroupId: t.union([t.string, t.null]),
    paymentProgramRowGroupId: t.string,
    quantity: bigString,

    grossPrice: bigString,
    netPrice: bigString,
    isBilled: t.boolean,
    isDeletable: t.boolean,

    unit: t.union([t.string, t.null]),
    unitPriceWithoutVat: bigString,
    vatPrc: bigString,
    statusId: t.keyof(idToStatus),
    billingDate: t.union([t.string, dateString, t.null]),
    salesInvoiceLinesTotal: bigString,

    analysisListItemIds: t.array(t.string),

    isDeleted: t.boolean,
    updatedAt: dateString,
  })
);

export async function toRevenues(u: unknown): Promise<Revenue[]> {
  const apiRevenues = await tPromise.decode(t.array(apiRevenueType), u);

  return apiRevenues
    .map(
      ({
        statusId,
        description,
        unit,
        unitPriceWithoutVat: unitPrice,
        vatPrc: vat,
        visiblePaymentBatchCode: batchCode,
        salesInvoiceLinesTotal: actualizedBilling,
        analysisListItemIds,
        ...rest
      }) => {
        const status = idToStatus[statusId];

        return {
          batchCode,
          unit: unit ?? '',
          description: description ?? '',

          unitPrice,

          vat,
          actualizedBilling,

          status,

          analysisRowIds: analysisListItemIds,

          ...rest,
        };
      }
    )
    .filter(isDefined);
}

type ApiRevenue = t.OutputOf<typeof apiRevenueType>;
export async function toApiRevenue({
  quantity,
  unitPrice,
  netPrice,
  grossPrice,
  unit,
  vat,
  batchCode,
  actualizedBilling,
  billingDate,
  status,
  analysisRowIds,
  ...revenue
}: Revenue): Promise<Partial<ApiRevenue>> {
  const apiRevenue: Partial<ApiRevenue> = apiRevenueType.encode({
    ...revenue,
    analysisListItemIds: analysisRowIds,
    visiblePaymentBatchCode: batchCode,
    statusId: statusToId[status],
    quantity,
    unitPriceWithoutVat: unitPrice,
    netPrice,
    grossPrice,
    unit: unit || null,
    vatPrc: vat,
    salesInvoiceLinesTotal: actualizedBilling,
    billingDate,
    updatedAt: revenue.updatedAt,
  });

  delete apiRevenue.id;
  delete apiRevenue.projectId;
  delete apiRevenue.isDeleted;
  delete apiRevenue.salesInvoiceLinesTotal;
  delete apiRevenue.isBilled;
  delete apiRevenue.netPrice;
  delete apiRevenue.grossPrice;
  delete apiRevenue.isDeletable;

  return apiRevenue;
}

async function fetchRevenues(projectId: string): Promise<Revenue[]> {
  const response = await GET(`v1/projects/${projectId}/payment-program-rows`);

  return toRevenues(response);
}

export const requestRevenues = (projectId: string) =>
  createAsyncThunk(fetchRevenues, {
    args: [projectId],
    isPending: flow(getProjectRevenue(projectId), remoteData.isLoading),
    initialAction: getRevenuesRequested({ projectId }),
    successActionCreator: (revenues) =>
      getRevenuesSucceeded({ projectId, revenues }),
    failureActionCreator: (error) =>
      getRevenuesFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

async function updateRevenue(revenue: Revenue): Promise<APIUpdatedEntities> {
  const revenueId = revenue.id;
  const apiRevenue = await toApiRevenue(revenue);

  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/payment-program-rows/${revenueId}`,
    apiRevenue
  );

  return mapRawUpdatedEntities(response);
}

const postMultiplePaymentProgramRows = (
  body: APIMultiplePaymentProgramRowBody
) =>
  POST<RawAPIUpdatedEntities>('v1/payment-program-rows/multiple', body).then(
    mapRawUpdatedEntities
  );

export const createMultiplePaymentPrograms = (
  body: APIMultiplePaymentProgramRowBody,
  successCallback?: (updated: APIUpdatedEntities) => void
): Thunk => (dispatch, _) => {
  dispatch(postPaymentProgramStarted());

  postMultiplePaymentProgramRows(body).then(
    (updatedEntities) => {
      dispatch(postPaymentProgramSuccess(updatedEntities));

      if (successCallback) {
        successCallback(updatedEntities);
      }
    },
    (error) => {
      dispatch(postPaymentProgramFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};

type RevenueUpdateOptions = {
  requestId: string;
  revenueId: string;
  projectId: string;
};

// TODO: forcedRequest boolean could be refactored if there is a better solution found
export const requestRevenueUpdate = (
  updatedFields: Partial<Revenue>,
  { requestId, revenueId, projectId }: RevenueUpdateOptions,
  forcedRequest?: boolean
): Thunk => (dispatch, getState) => {
  const revenue = getRevenue({ revenueId, projectId })(getState());

  if (revenue === undefined) {
    return;
  }

  dispatch(
    createAsyncThunk(updateRevenue, {
      args: [{ ...revenue, ...updatedFields }],
      isPending: forcedRequest
        ? undefined
        : flow(getRevenueUpdateRequest(requestId), remoteData.isLoading),
      initialAction: putRevenueRequested({ requestId }),
      successActionCreator: (updatedEntities) =>
        putRevenueSucceeded({ ...updatedEntities, requestId }),
      failureActionCreator: (error) =>
        putRevenueFailure({
          requestId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

const postNewRevenue = async ({
  projectId,
  paymentProgramRowGroupId,
}: {
  projectId: string;
  paymentProgramRowGroupId: string;
}): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>(
    'v1/payment-program-rows',
    {
      projectId,
      paymentProgramRowGroupId,
      description: null,
      quantity: null,
      unit: null,
      unitPriceWithoutVat: null,
      billingDate: null,
      statusId: null,
      visiblePaymentBatchCode: null,
      vatPrc: null,
    }
  );

  return mapRawUpdatedEntities(response);
};

type NewRevenueRequest = {
  requestId: string;
  projectId: string;
  paymentProgramRowGroupId: string;
};

export const requestNewRevenue = ({
  requestId,
  projectId,
  paymentProgramRowGroupId,
}: NewRevenueRequest): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(postNewRevenue, {
      args: [{ projectId, paymentProgramRowGroupId }],
      isPending: flow(getRevenueUpdateRequest(requestId), remoteData.isLoading),
      initialAction: putRevenueRequested({ requestId }),
      successActionCreator: (updatedEntities) =>
        putRevenueSucceeded({ ...updatedEntities, requestId }),
      failureActionCreator: (error) =>
        putRevenueFailure({
          requestId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

type DeleteRevenueRequest = {
  requestId: string;
  projectId: string;
  revenueId: string;
};

const deleteRevenue = async (
  revenueId: string
): Promise<APIUpdatedEntities> => {
  const response = await DELETE<RawAPIUpdatedEntities>(
    `v1/payment-program-rows/${revenueId}`
  );

  return mapRawUpdatedEntities(response);
};

export const requestDeleteRevenue = ({
  requestId,
  projectId,
  revenueId,
}: DeleteRevenueRequest): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(deleteRevenue, {
      args: [revenueId],
      isPending: flow(getRevenueDeleteRequest(requestId), remoteData.isLoading),
      initialAction: deleteRevenueStarted({ requestId, projectId }),
      successActionCreator: (updatedEntities) =>
        deleteRevenueSuccess({ ...updatedEntities, requestId, projectId }),
      failureActionCreator: (error) =>
        deleteRevenueFailure({
          requestId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};
