import styled from 'styled-components';

import { IconDown } from '../assets';

// eslint-disable-next-line import/prefer-default-export
export const Select = styled.select`
  border-radius: 0.125rem;
  border: 1px solid black;

  padding: ${({ theme }) => `${theme.margin[4]} ${theme.margin[16]}`};

  height: 1.5rem;
  min-width: 12rem;

  background: ${(props) => props.theme.color.white} url(${IconDown}) no-repeat
    calc(100% - ${(props) => props.theme.margin[4]}) center;

  line-height: inherit;
  text-align-last: center;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 400;

  appearance: none;

  :disabled {
    border-color: ${(props) => props.theme.color.buttonPrimaryDisabled};
    background: ${(props) => props.theme.color.buttonPrimaryDisabled};
  }
`;
