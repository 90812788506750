import React from 'react';

import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';

import {
  StickyHeaderTh,
  TableHeader as BaseHeader,
} from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import getWidths from '../../../../utils/headerWidth';

import { IconReceive, IconShoppingCart } from '../../../../assets';

const Tr = styled.tr`
  line-height: ${({ theme }) => theme.margin[32]};
`;

export const tableColumns = {
  'orderRow.rowNo': { align: 'left', relativeWidth: 1 },
  'orderRow.description': { align: 'left', relativeWidth: 6 },
  'orderRow.quantity': { align: 'right', relativeWidth: 2 },
  'orderRow.amount': { align: 'right', relativeWidth: 2 },
  arrow: { align: 'left', relativeWidth: 1 },
  'arrivalRow.quantity': { align: 'right', relativeWidth: 2 },
  'arrivalRow.amount': { align: 'right', relativeWidth: 2 },
  'orderRow.change': { align: 'right', relativeWidth: 2 },
  'orderRow.amountLeft': { align: 'right', relativeWidth: 2 },
};

const getColumnPercent = getWidths(tableColumns);

const textIdHead = 'order.invoiceLines.convertModal.table.header.';

type ThProps = {
  name: keyof typeof tableColumns;
  background?: boolean;
};

const Th = styled(StickyHeaderTh)<ThProps>`
  top: ${({ theme: { margin } }) => `${margin[48]}`};
  padding: ${({ theme: { margin } }) => `${margin[8]}`}
    ${({ theme: { margin } }) => `${margin[8]}`}
    ${({ theme: { margin } }) => `${margin[8]}`} 0;
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  ${(props) =>
    props.background
      ? `background: ${props.theme.color.primaryRowBackground};`
      : null}
  text-align: ${({ name }) => tableColumns[name].align};
`;

const TxtTh = ({ name, background }: ThProps) => (
  <Th name={name} background={background}>
    <Txt id={`${textIdHead}${name}` as const} />
  </Th>
);

const UpperTierTh = styled(StickyHeaderTh)`
  padding: ${({ theme: { margin } }) => `${margin[8]}`};
  text-align: left;
`;

const UpperTierThWithBackground = styled(UpperTierTh)`
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
`;

const StyledImg = styled.img`
  margin-right: ${({ theme: { margin } }) => margin[8]};
`;

export const TableHeader = () => {
  const altOrderRows = useTxt(
    'order.invoiceLines.convertModal.table.uppertierheader.orderRows'
  );

  const altArrivalRows = useTxt(
    'order.invoiceLines.convertModal.table.uppertierheader.arrivalRows'
  );

  return (
    <>
      <colgroup>
        <col width={getColumnPercent('orderRow.rowNo')} />
        <col width={getColumnPercent('orderRow.description')} />
        <col width={getColumnPercent('orderRow.quantity')} />
        <col width={getColumnPercent('orderRow.amount')} />
        <col width={getColumnPercent('arrow')} />
      </colgroup>
      <colgroup>
        <col width={getColumnPercent('arrivalRow.quantity')} />
        <col width={getColumnPercent('arrivalRow.amount')} />
      </colgroup>
      <colgroup>
        <col width={getColumnPercent('orderRow.change')} />
        <col width={getColumnPercent('orderRow.amountLeft')} />
      </colgroup>
      <BaseHeader>
        <Tr>
          <UpperTierTh scope="colgroup" colSpan={5}>
            <StyledImg src={IconShoppingCart} alt={altOrderRows} />
            <Txt id="order.actualCostLines.convertModal.table.uppertierheader.orderRows" />
          </UpperTierTh>
          <UpperTierThWithBackground scope="colgroup" colSpan={2}>
            <StyledImg src={IconReceive} alt={altArrivalRows} />
            <Txt id="order.actualCostLines.convertModal.table.uppertierheader.arrivalRows" />
          </UpperTierThWithBackground>
          <UpperTierTh scope="colgroup" colSpan={2} />
        </Tr>
        <Tr>
          <TxtTh name="orderRow.rowNo" />
          <TxtTh name="orderRow.description" />
          <TxtTh name="orderRow.quantity" />
          <TxtTh name="orderRow.amount" />
          <TxtTh name="arrow" />
          <TxtTh name="arrivalRow.quantity" background />
          <TxtTh name="arrivalRow.amount" background />
          <TxtTh name="orderRow.change" />
          <TxtTh name="orderRow.amountLeft" />
        </Tr>
      </BaseHeader>
    </>
  );
};
