import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDefaultTopicForOrder,
  isDeletingTopic,
} from '../../../../store/reducers/topic';
import { getActiveProjectId } from '../../../../store/reducers/ui';

import * as Actions from '../../../../store/actions';
import { fetchTopicsForOrder } from '../../../../store/actions';

import { APITopic } from '../../../../types/api';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import { IconButton } from '../../../../components/Buttons';
import Cell from '../../../../components/Cell';

import { IconDelete } from '../../../../assets/svg';

type TopicRowEditControlsProps = {
  topic: APITopic;
};

const TopicRowEditControls = ({ topic }: TopicRowEditControlsProps) => {
  const dispatch = useDispatch();
  const removeText = useTxt('order.topicRow.Remove');
  const projectId = useSelector(getActiveProjectId) ?? '';

  const isDeleting = useSelector(isDeletingTopic(topic.id));

  const defaultTopic = useRemoteData(
    getDefaultTopicForOrder(topic.orderId),
    fetchTopicsForOrder(topic.orderId)
  );

  const onClickDeleteTopic = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!isDeleting) {
      dispatch(
        Actions.deleteTopic(
          topic.id,
          topic.name,
          topic.orderId,
          projectId,
          defaultTopic
        )
      );
    }
  };

  return (
    <Cell align="center">
      {!topic.defaultTopic ? (
        <IconButton
          icon={IconDelete}
          onClick={onClickDeleteTopic}
          aria-label={removeText}
          disabled={isDeleting}
        />
      ) : null}
    </Cell>
  );
};

export default TopicRowEditControls;
