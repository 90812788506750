import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import {
  getWorkPackagePlannedPoCEntries,
  getWorkPackagePreviouslyPlannedPoCEntries,
  getWorkPackageSnapshotPoCEntries,
  getWorkPackageActualAndPlannedPoCEntries,
  getWorkPackagePoCBasedOnPreviouslyPlannedEntries,
} from '../../../../../store/reducers/schedule/workPackageTimeline';
import { getWorkPackageById } from '../../../../../store/reducers/workPackage';

import { getWorkPackageTimelineForProject } from '../../../../../store/actions/schedule/workPackageTimeline';

import useRemoteData from '../../../../../hooks/useRemoteData';
import useTxt from '../../../../../hooks/useTxt';

import { BaseRow } from '../../../../../components/Table';
import {
  DescriptionCell,
  FuturePoCCell,
  SnapshotPoCCell,
  DescriptionCellDiv,
} from './components/Cell';

import { IconToLinkedEntity } from '../../../../../assets';

import { generateUrl, routes } from '../../../../../routes';

type WorkPackageRowProps = {
  projectId: string;
  workPackageId: string;
  snapshotIds: string[];
  months: Date[];
  currentPeriod: Date;
  selectedSnapshotTypeId: '1' | '2' | '3';
};

const WorkPackageRow = ({
  projectId,
  workPackageId,
  months,
  snapshotIds,
  currentPeriod,
  selectedSnapshotTypeId,
}: WorkPackageRowProps) => {
  const plannedEntries = useRemoteData(
    getWorkPackagePlannedPoCEntries({
      projectId,
      workPackageId,
      showHidden: false,
    }),
    getWorkPackageTimelineForProject({ projectId })
  );

  const actualAndPlannedEntries =
    useRemoteData(
      getWorkPackageActualAndPlannedPoCEntries({
        projectId,
        workPackageId,
        showHidden: false,
      }),
      getWorkPackageTimelineForProject({ projectId })
    ) ?? [];

  const PoCBasedOnPreviouslyPlanned =
    useRemoteData(
      getWorkPackagePoCBasedOnPreviouslyPlannedEntries({
        projectId,
        workPackageId,
        showHidden: false,
      }),
      getWorkPackageTimelineForProject({ projectId })
    ) ?? [];

  const previouslyPlannedEntries =
    useRemoteData(
      getWorkPackagePreviouslyPlannedPoCEntries({
        projectId,
        workPackageId,
        showHidden: false,
      }),
      getWorkPackageTimelineForProject({ projectId })
    ) ?? [];

  const snapshotEntries =
    useRemoteData(
      getWorkPackageSnapshotPoCEntries({
        projectId,
        workPackageId,
        showHidden: false,
      }),
      getWorkPackageTimelineForProject({ projectId })
    ) ?? [];

  const workPackage = useSelector(getWorkPackageById(workPackageId));

  const iconAltText = useTxt('common.workSection.icon.alt');

  if (!plannedEntries || !workPackage) {
    return null;
  }

  const mappedEntries = () => {
    switch (selectedSnapshotTypeId) {
      case '1':
        return actualAndPlannedEntries.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
      case '2':
        return plannedEntries.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
      case '3':
        return PoCBasedOnPreviouslyPlanned.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
    }
  };

  const mappedPreviousEntries = previouslyPlannedEntries.map((entry) => {
    return { date: entry.date, percentage: entry.percentageOfCompletion };
  });

  const mappedSnapshotEntries = snapshotEntries.map((entry) => {
    return {
      snapshotId: entry.snapshotId,
      percentage: entry.percentageOfCompletion,
    };
  });

  return (
    <BaseRow>
      <DescriptionCell
        fontWeight="normal"
        indent
        condensed
        borderBottom="light"
        contentContainer={DescriptionCellDiv}
      >
        <StyledSpan>
          <b>{workPackage.code}</b>
          <NameSpan>{workPackage.name}</NameSpan>
        </StyledSpan>
        <Link
          to={generateUrl({
            route: routes.WORKSECTION_EXPANDED,
            projectId,
            workPackageId: workPackage.id,
          })}
        >
          <StyledImg src={IconToLinkedEntity} alt={iconAltText} />
        </Link>
      </DescriptionCell>
      {snapshotIds.map((id) => {
        return (
          <SnapshotPoCCell
            snapshotId={id}
            timelineEntries={mappedSnapshotEntries}
            key={`workpackage-${workPackageId}-snapshot-${id}`}
            borderBottom="light"
            condensed
          />
        );
      })}
      {months.map((month) => {
        return (
          <FuturePoCCell
            month={month}
            currentPeriod={currentPeriod}
            timelineEntries={mappedEntries()}
            previouslyPlannedEntries={mappedPreviousEntries}
            key={`project-${month.toString()}`}
            borderBottom="light"
            condensed
          />
        );
      })}
    </BaseRow>
  );
};

const StyledImg = styled.img`
  padding: 0 ${({ theme }) => theme.margin[8]};
  vertical-align: middle;
`;

const StyledSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const NameSpan = styled.span`
  margin-left: ${(props) => props.theme.margin[8]};
`;

export default WorkPackageRow;
