import React from 'react';

import styled from 'styled-components';

import { TableHeader as BaseHeader } from '../../../components/Table';
import Txt from '../../../components/Txt';

import getWidths from '../../../utils/headerWidth';

const Tr = styled.tr`
  line-height: ${({ theme }) => theme.margin[32]};
`;

export const tableColumns = {
  empty: { align: 'left', relativeWidth: 3 },
  value: { align: 'left', relativeWidth: 20 },
  paymentProgramRowsAmount: { align: 'right', relativeWidth: 7 },
  orderRowsAmount: { align: 'right', relativeWidth: 7 },
  targetRowsAmount: { align: 'right', relativeWidth: 7 },
  arrivalsAmount: { align: 'right', relativeWidth: 7 },
  none: { align: 'left', relativeWidth: 50 },
  delete: { align: 'left', relativeWidth: 3 },
};

const getColumnPercent = getWidths(tableColumns);

const textIdHead = 'analysis.table.header.';

type ThProps = {
  name: keyof typeof tableColumns;
};

const Th = styled.th<ThProps>`
  padding: ${({ theme: { margin } }) =>
    `${margin[8]} ${margin[16]} ${margin[8]} ${margin[8]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  text-align: ${({ name }) => tableColumns[name].align};
`;

const TxtTh = ({ name }: ThProps) => (
  <Th name={name}>
    <Txt id={`${textIdHead}${name}` as const} />
  </Th>
);

export const TableHeader = () => {
  return (
    <BaseHeader>
      <Tr>
        <TxtTh name="empty" />
        <TxtTh name="empty" />
        <TxtTh name="value" />
        <TxtTh name="paymentProgramRowsAmount" />
        <TxtTh name="orderRowsAmount" />
        <TxtTh name="targetRowsAmount" />
        <TxtTh name="arrivalsAmount" />
        <TxtTh name="none" />
        <TxtTh name="delete" />
      </Tr>
    </BaseHeader>
  );
};
