import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes as Action } from '../../actionTypes';

type RevenueUpdates = Partial<Record<string, remoteData.RemoteAction>>;

function revenueUpdateReducer(state: RevenueUpdates = {}, action: Action) {
  switch (action.type) {
    case 'PUT_REVENUE_REQUESTED': {
      return { ...state, [action.payload.requestId]: remoteData.loading };
    }
    case 'PUT_REVENUE_FAILURE': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.fail(action.payload.error),
      };
    }
    case 'PUT_REVENUE_SUCCEEDED': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.succeed(undefined),
      };
    }
    default:
      return state;
  }
}

export function getRevenueUpdateRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    revenues: {
      updateRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export default revenueUpdateReducer;
