import React from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getProjectById } from '../store/reducers/project';

import useTxt from '../hooks/useTxt';

import { DeltaBigProfitValue, BigPriceValue } from './BigValue';

type ProjectInfoProps = {
  projectId: string;
};

const ProjectInfo = ({ projectId }: ProjectInfoProps) => {
  const project = useSelector(getProjectById(projectId));

  const costs = project?.costPredictionTotal ?? new Big(0);
  const revenue = project?.revenueTotal ?? new Big(0);

  const profit = revenue.sub(costs);

  const revenuePredictionChangeFromLatest =
    project?.revenuePredictionChangeFromLatest ?? new Big(0);

  const costPredictionChangeFromLatest =
    project?.costPredictionChangeFromLatest ?? new Big(0);

  const profitChange = revenuePredictionChangeFromLatest.sub(
    costPredictionChangeFromLatest
  );

  const profitText = useTxt('project.info.profit');
  const profitChangeText = useTxt('project.info.profitChange');

  const name = project?.name ?? '';
  const code = project?.code ?? '';

  return (
    <Container>
      <Text>{`${code} ${name}`}</Text>
      <Text data-testid="project-profit">
        <b>{profitText}</b> <BigPriceValue value={profit} decimals={0} />
        <ProfitChangeSpan data-testid="project-profit-change">
          <b>{profitChangeText}</b>{' '}
          <DeltaBigProfitValue value={profitChange} decimals={0} />
        </ProfitChangeSpan>
      </Text>
    </Container>
  );
};

const Text = styled.div`
  font-size: ${({
    theme: {
      fontSize: { small },
    },
  }) => small};
  line-height: 0.875rem;
  font-weight: 400;
  font-family: 'Graphik', sans-serif;
`;

const ProfitChangeSpan = styled.span`
  margin-left: ${(props) => props.theme.margin[8]};
  border-left: 1px solid ${(props) => props.theme.color.graphiteB76};
  padding-left: ${(props) => props.theme.margin[8]};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ProjectInfo;
