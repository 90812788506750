import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { selectCurrentUser } from '../../store/reducers/user';

import { updateUserData } from '../../store/actions';

import { LanguageId, LanguageType } from '../../types/general';

import { Context } from '../../context/locale';
import { additionalStyle } from '../../styles/additionals';
import { PrimaryButton } from '../Buttons';
import DropDownSelect from '../DropDownSelect';
import ModalWindow from '../ModalWindow';
import Txt from '../Txt';
import { CircleIconButtonContainer } from './Components';

export const getUserInitials = (userName: string) =>
  userName
    // Replace things we're not interested in with a single space.
    .replace(/[.,\-\s]+/, ' ')
    .split(' ')
    .map((str) => str[0])
    // Filter to first and last item in the list.
    .filter((_s, index, a) => index === 0 || index === a.length - 1)
    .join('');

const languageOptions = [
  {
    key: 'en',
    value: '2',
    label: 'English',
  },
  {
    key: 'fi',
    value: '1',
    label: 'Suomi',
  },
];

const UserIcon = () => {
  const localContext = useContext(Context);
  const user = useSelector(selectCurrentUser);

  const [langId, setLangId] = useState<LanguageId>(user?.languageId ?? '1');
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  const initials = getUserInitials(user.name);

  const handleDropDown = (languageId: LanguageId) => {
    setLangId(languageId);
  };

  const handleChangeLanguage = () => {
    const language: LanguageType = langId === '1' ? 'fi' : 'en';

    localContext.setLang(language);

    dispatch(updateUserData(user.id, { languageId: langId }));

    setShowSettingsModal(false);
  };

  return (
    <>
      <CircleIconButtonContainer
        onClick={() => setShowSettingsModal(!showSettingsModal)}
      >
        {initials.toUpperCase()}
      </CircleIconButtonContainer>
      {showSettingsModal ? (
        <ModalWindow
          onClose={() => setShowSettingsModal(false)}
          titleId="users.editMode.editUserSettings"
          data-testid="testId"
        >
          <Txt id="users.editMode.language" />
          <DropDownSelect
            additionalContainerStyles={additionalStyle}
            defaultValue={langId}
            onChange={(value) => handleDropDown(value as typeof langId)}
            id="language-select"
            options={languageOptions}
          />
          <ButtonWrapper>
            <PrimaryButton type="button" onClick={handleChangeLanguage}>
              <Txt id="common.save" />
            </PrimaryButton>
          </ButtonWrapper>
        </ModalWindow>
      ) : null}
    </>
  );
};

const ButtonWrapper = styled.div`
  padding-top: ${(props) => props.theme.margin[16]};
`;

export default UserIcon;
