import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getDeleteRequestState } from '../../../../store/reducers/target/targetRows';
import {
  getIsOuterBarOpen,
  getSelectedOrderRowsForOrder,
} from '../../../../store/reducers/ui';

import {
  requestDeleteTargetRow,
  TargetRow,
} from '../../../../store/actions/target/targetRow';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { TagsContainer } from '../../../../components/Analysis/AnalysisTags';
import { IconButton } from '../../../../components/Buttons';
import Cell, { MoneyCell } from '../../../../components/Cell';
import { BaseRow } from '../../../../components/Table';
import Tooltip from '../../../../components/Tooltip';

import * as big from '../../../../utils/big';
import CAN, {
  CaslPaymentProgramRowRequestParams,
} from '../../../../utils/caslUserPermissions';

import { IconSplitRow } from '../../../../assets';
import { IconDelete } from '../../../../assets/svg';

import AnalysisTags from '../AnalysisTags';
import Checkbox from './Checkbox';

type TargetProps = {
  target: TargetRow;
};

const Target = ({
  target: {
    id: targetRowId,
    description,
    orderId,
    quantity,
    unit,
    unitPrice,
    totalPrice,
    isDeletable,
    isDisabled,
    isSplitFrom,
    analysisListItemIds,
  },
}: TargetProps) => {
  const { projectId, viewMode } = useRouteParams();
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const requestState = useSelector(getDeleteRequestState(targetRowId));

  const isButtonDisabled = requestState !== 'NotAsked';

  const dispatch = useDispatch();

  const isTargetRowSelectionDisabled =
    useSelector(getSelectedOrderRowsForOrder(orderId)).length > 0;

  const ability = new CaslPaymentProgramRowRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const onDeleteTargetRow = () => {
    dispatch(requestDeleteTargetRow({ targetRowId }));
  };

  const colSpanLength = () => {
    if (viewMode === 'edit' && !outerBarOpen) {
      return 4;
    }

    if (viewMode === 'edit' && outerBarOpen) {
      return 3;
    }

    if (viewMode === 'normal' && outerBarOpen) {
      return 2;
    }

    if (viewMode === 'normal' && !outerBarOpen) {
      return 3;
    }

    if (viewMode === 'receive' && !outerBarOpen) {
      return 4;
    }

    return 4;
  };

  const removeText = useTxt('order.inputs.Remove');

  const splitTargetTip = useTxt('order.targetMode.splitIcon.tooltip.splitFrom');

  const disabledSplitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.disabled'
  );

  if (isSplitFrom && isDisabled) {
    return null;
  }

  return (
    <Tr data-testid={`target-row-${targetRowId}`} isDisabled={isDisabled}>
      <StyledCell align="center">
        {viewMode === 'edit' ? (
          <Checkbox
            targetRowId={targetRowId}
            disabled={
              !allowedUser || isTargetRowSelectionDisabled || isDisabled
            }
          />
        ) : null}
      </StyledCell>
      {viewMode !== 'edit' ? null : <StyledCell />}
      <StyledCell>
        {description}
        {isSplitFrom || isDisabled ? (
          <Tooltip tip={isSplitFrom ? splitTargetTip : disabledSplitTargetTip}>
            <StyledSplitIcon src={IconSplitRow} alt="split_icon" />
          </Tooltip>
        ) : null}
      </StyledCell>
      {outerBarOpen ? (
        <StyledCell align="right">
          {`${big.amountFormat(quantity || new Big(0), 0)} ${unit}`}
        </StyledCell>
      ) : (
        <>
          <StyledCell align="right">
            {big.amountFormat(quantity || new Big(0))}
          </StyledCell>
          <StyledCell align="center">{unit}</StyledCell>
        </>
      )}
      <StyledMoneyCell
        value={unitPrice || new Big(0)}
        decimals={outerBarOpen ? 0 : undefined}
      />
      <StyledMoneyCell
        value={totalPrice}
        decimals={outerBarOpen ? 0 : undefined}
      />
      <StyledCell colSpan={colSpanLength()} />
      {outerBarOpen || viewMode === 'receive' ? null : (
        <>
          <StyledCell align="center" contentContainer={TagsContainer}>
            <AnalysisTags
              targetRowId={targetRowId}
              analysisListItemIds={analysisListItemIds}
            />
          </StyledCell>
        </>
      )}
      <StyledCell colSpan={2} />
      <StyledCell align="center">
        {isDeletable && viewMode === 'edit' ? (
          <IconButton
            icon={IconDelete}
            onClick={onDeleteTargetRow}
            aria-label={removeText}
            disabled={isButtonDisabled}
          />
        ) : null}
      </StyledCell>
    </Tr>
  );
};

type TrProps = {
  isDisabled?: boolean;
};

const Tr = styled(BaseRow)<TrProps>`
  background: ${(props) =>
    props.isDisabled
      ? props.theme.color.graphiteB76
      : props.theme.color.whisper};
  color: ${(props) =>
    props.isDisabled ? props.theme.color.graphiteB48 : props.theme.color.pitch};
`;

const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

export const StyledSplitIcon = styled.img`
  margin: 0 ${({ theme }) => theme.margin[8]};
  width: ${({ theme }) => theme.margin[20]};
  height: ${({ theme }) => theme.margin[20]};
`;

export default Target;
