import React from 'react';

import Big from 'big.js';
import { OptionGroupData } from 'rc-select/lib/interface';
import styled, { css } from 'styled-components';

import { APIOrderRow, APITopic, APIWorkPackage } from '../../../types/api';
import { InvoiceLineSelectionPayload } from '../../../types/general';

import useTxt from '../../../hooks/useTxt';

import DropDownSelect from '../../../components/DropDownSelect';
import Tooltip from '../../../components/Tooltip';

import * as big from '../../../utils/big';

import { IconReceive } from '../../../assets';

type Props = {
  orderRows: APIOrderRow[];
  selectionState: InvoiceLineSelectionPayload | undefined;
  allowedUser: boolean;
  onChange: (selection: string, primary?: boolean) => void | undefined;
  widthForDropdown: number;
  defaultValue: string;
  alreadyAssigned: boolean;
  options: OptionGroupData[];
};

function convertStateToSelectionValue(
  selectionState: InvoiceLineSelectionPayload | undefined
): string | undefined {
  if (!selectionState) {
    return undefined;
  }

  if (selectionState.linkedOrderRowId) {
    return `orderRow-${selectionState.linkedOrderRowId}`;
  }

  if (selectionState.linkedTopicId) {
    return `newRowForTopic-${selectionState.linkedTopicId}`;
  }

  if (selectionState.workPackageId) {
    return `newRowForWorkPackage-${selectionState.workPackageId}`;
  }

  return undefined;
}

const OrderRowDropdown = ({
  orderRows,
  selectionState,
  allowedUser,
  onChange,
  widthForDropdown,
  defaultValue,
  alreadyAssigned,
  options,
}: Props) => {
  const selectedValue = convertStateToSelectionValue(selectionState);

  const placeholderDefault = orderRows.find(
    (row) => row.id === defaultValue.replace('orderRow-', '')
  );

  const placeholderText = placeholderDefault
    ? placeholderDefault.rowNumber
    : '';

  const altArrivalRows = useTxt(
    'order.invoiceLines.convertModal.table.uppertierheader.arrivalRows'
  );

  const tooltipText = useTxt('order.invoiceLines.row.tooltip.received', {
    orderRowDescription: `${
      placeholderDefault
        ? `${placeholderDefault.rowNumber} ${placeholderDefault.description}`
        : ''
    }`,
  });

  if (orderRows && allowedUser && alreadyAssigned) {
    return (
      <StyledDiv>
        <Tooltip
          tip={tooltipText}
          className="hoverable-tooltip"
          place="top"
          delayShow={500}
        >
          <img src={IconReceive} alt={altArrivalRows} />
        </Tooltip>
      </StyledDiv>
    );
  }

  if (orderRows && allowedUser) {
    return (
      <StyledDropdown
        alternatePlaceholderStyle={!selectedValue && defaultValue !== 'NONE'}
        hideBorder
        dropdownMatchSelectWidth={widthForDropdown}
        optionFilterProp="title"
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholderText}
        optionLabelProp="shortlabel"
        options={options}
        value={selectedValue}
        disabled={alreadyAssigned}
      />
    );
  }

  return null;
};

export default OrderRowDropdown;

type OrderRowDivProps = {
  width: number;
  index?: number;
};

export const OrderRowDiv = styled.div<OrderRowDivProps>`
  width: ${(props) => props.width}px;

  display: grid;

  overflow: hidden;

  justify-items: start;

  grid-template-columns: 1fr 6fr 1fr;
  grid-template-rows: 1.5rem;
`;

export const PriceText = styled.div`
  margin-left: ${({ theme: { margin } }) => margin[4]};
  padding: 0 ${(props) => props.theme.margin[4]};
  display: flex;
  color: ${(props) => props.theme.color.graphiteB57};
`;

export const OrderRowDescDiv = styled.div`
  height: 1.5rem;
  min-width: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  overflow: hidden;
`;

export const OrderRowText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Paragraph = styled.p<OrderRowDivProps>`
  width: ${(props) => props.width}px;
  margin-block-start: ${(props) =>
    props.index === 0 ? props.theme.margin[24] : '0'};
  margin-block-end: ${(props) =>
    props.index === 0 ? props.theme.margin[24] : '0'};
`;

export const WorkPackageText = styled.span`
  margin-left: ${({ theme: { margin } }) => margin[4]};
  border-left: 1px solid ${(props) => props.theme.color.graphiteB76};
  padding: 0 ${(props) => props.theme.margin[4]};
  color: ${(props) => props.theme.color.graphiteB57};
`;

export const TopicDescriptionText = styled.span`
  padding: 0 ${(props) => props.theme.margin[4]} 0 0;
`;

type StyledDropdownProps = {
  alternatePlaceholderStyle: boolean;
};

const StyledDropdown = styled(DropDownSelect)<StyledDropdownProps>`
  ${(props) => {
    return props.alternatePlaceholderStyle
      ? css`
          /* stylelint-disable selector-max-class -- Third party libraries need to be styled either by type or by classnames */
          .rc-select-selection-item,
          .rc-select-selection-placeholder {
            color: ${({ theme }) => theme.color.graphiteB86};
          }
        `
      : '';
  }}
`;

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const StyledNewTopicDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export type Topic = APITopic & {
  allChecked: boolean;
  allDisabled: boolean;
  createNewTopic?: boolean;
};

export const newTopicId = 'newTopic-for-workPackage-';

export const orderRowStyle = {
  paddingLeft: '1rem',
};

export const createOptionsForTopics = (
  topics: Topic[],
  orderRows: APIOrderRow[],
  workPackages: APIWorkPackage[],
  widthForDropdown: number,
  newTopicText: string,
  moreWorkSectionsText: string
): OptionGroupData[] => {
  const existingTopics = topics.map((topic) => {
    const rows = orderRows?.filter((row) => row.topicId === topic.id);

    const workPackage = workPackages?.filter(
      (row) => row.id === topic.workPackageId
    )[0];

    const options = rows.map((option) => ({
      value: `orderRow-${option.id}`,
      title: `${option.rowNumber.toString()} ${
        option.description
      } ${big.priceFormat(option.unitPrice ?? new Big('0'))}/${option.unit} ${
        topic.name
      } ${workPackage ? `${workPackage.code} ${workPackage.name}` : null} `,
      label: (
        <OrderRowDiv width={widthForDropdown - 40}>
          <span>{option.rowNumber.toString()}</span>
          <OrderRowDescDiv>
            <OrderRowText>{option.description}</OrderRowText>
          </OrderRowDescDiv>
          <PriceText>{`${big.priceFormat(option.unitPrice ?? new Big('0'))}/${
            option.unit
          }`}</PriceText>
        </OrderRowDiv>
      ),
      shortlabel: option.rowNumber.toString(),
      style: orderRowStyle,
    }));

    const group = {
      label: (
        <Paragraph width={widthForDropdown - 40}>
          <TopicDescriptionText>{topic.name}</TopicDescriptionText>
          <WorkPackageText>
            {workPackage ? `${workPackage.code} ${workPackage.name}` : null}
          </WorkPackageText>
        </Paragraph>
      ),
      title: topic.name,
      shortlabel: `aihe ${topic.id}`,
      options,
      topicId: topic.id,
      workPackage: workPackage
        ? {
            code: workPackage.code,
            name: workPackage.name,
          }
        : undefined,
    };

    return group;
  });

  const newTopics = workPackages.map((workPackage, index) => {
    const group = {
      label: (
        <>
          {index === 0 ? (
            <StyledNewTopicDiv>
              <Paragraph
                width={widthForDropdown - 40}
                index={0}
              >{`---- ${moreWorkSectionsText} ----`}</Paragraph>
              <Paragraph width={widthForDropdown - 40}>
                <TopicDescriptionText>{`+ ${workPackage.name} (${newTopicText})`}</TopicDescriptionText>
                <WorkPackageText>
                  {workPackage
                    ? `${workPackage.code} ${workPackage.name}`
                    : null}
                </WorkPackageText>
              </Paragraph>
            </StyledNewTopicDiv>
          ) : (
            <Paragraph width={widthForDropdown - 40}>
              <TopicDescriptionText>{`+ ${workPackage.name} (${newTopicText})`}</TopicDescriptionText>
              <WorkPackageText>
                {workPackage ? `${workPackage.code} ${workPackage.name}` : null}
              </WorkPackageText>
            </Paragraph>
          )}
        </>
      ),
      title: `+ ${workPackage.name} (${newTopicText})`,
      shortlabel: `workpackage ${workPackage.id}`,
      options: [],
      topicId: null,
      workPackage: workPackage
        ? {
            code: workPackage.code,
            name: workPackage.name,
            id: workPackage.id,
          }
        : undefined,
    };

    return group;
  });

  return [...existingTopics, ...newTopics];
};

export const addOptionsForNoneAndNew = (
  topicOptions: OptionGroupData[],
  notSelectedLabelText: string,
  newOrderRowLabelText: string,
  newOrderRowLabelTextShort: string,
  widthForDropdown: number
): OptionGroupData[] => [
  {
    options: [
      {
        value: 'NONE',
        title: notSelectedLabelText,
        label: notSelectedLabelText,
        shortlabel: '-',
      },
    ],
  },
  ...topicOptions.map((group) => {
    const newRowOption = {
      value:
        group.topicId !== null
          ? `newRowForTopic-${group.topicId}`
          : `newRowForWorkPackage-${group?.workPackage?.id}`,
      title: `${newOrderRowLabelText} ${group.title} ${
        group.workPackage
          ? `${group.workPackage.code} ${group.workPackage.name}`
          : null
      }`,
      label: (
        <OrderRowDiv width={widthForDropdown - 40}>
          <span>+</span>
          <OrderRowDescDiv>
            <OrderRowText>{newOrderRowLabelText}</OrderRowText>
          </OrderRowDescDiv>
        </OrderRowDiv>
      ),
      shortlabel: newOrderRowLabelTextShort,
      style: orderRowStyle,
    };

    return { ...group, options: [...group.options, newRowOption] };
  }),
];
