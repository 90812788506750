import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import { Form, Formik, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';
import * as yup from 'yup';

import { getOrderRowsByOrderId } from '../../../../../store/reducers/orderRow';
import { getSelectedTargetRowsForOrder } from '../../../../../store/reducers/target/selection';
import {
  getRequestState,
  getTargetRowsByIds,
} from '../../../../../store/reducers/target/targetRows';
import { getOrderTopics } from '../../../../../store/reducers/topic';
import { SplitDraftTargetRow } from '../../../../../store/reducers/ui';

import {
  fetchTopicsForOrder,
  requestSplitTargetRows,
  TargetRow as APITargetRow,
} from '../../../../../store/actions';

import { APIOrderRow } from '../../../../../types/api';

import useRemoteData from '../../../../../hooks/useRemoteData';
import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import {
  SecondaryButton,
  PrimaryButton,
} from '../../../../../components/Buttons';
import Modal, {
  Content,
  Header,
  Footer,
} from '../../../../../components/Modal/Modal';
import Txt from '../../../../../components/Txt';

import * as big from '../../../../../utils/big';
import { isDefined, isNotNull } from '../../../../../utils/general';

import { SplitOrderAndTargetRowsTable } from './SplitOrderAndTargetRowsTable';

export type FormValueShape = {
  rows: Partial<Record<string, SplitDraftTargetRow[]>>;
};

type SplitTargetRowsModalProps = {
  onClose: (success?: boolean) => void | undefined;
};

export const SplitTargetRowsModal = ({
  onClose,
}: SplitTargetRowsModalProps) => {
  const { orderId } = useRouteParams();
  const dispatch = useDispatch();
  const [requestId] = useState(v4());

  const [formOrderRowsState, setFormOrderRowInvalid] = useState<
    Partial<Record<string, boolean>>
  >();

  const formInvalid = Object.values(formOrderRowsState ?? {}).some(
    (value) => value === true
  );

  const callBackFunction = useCallback(
    (orderRowId: string, invalid: boolean) => {
      setFormOrderRowInvalid((prevState) => ({
        ...prevState,
        [orderRowId]: invalid,
      }));
    },
    []
  );

  const targetRowIds = useSelector(getSelectedTargetRowsForOrder(orderId));

  const targetRowsToBeSplit = useSelector(
    getTargetRowsByIds(targetRowIds, orderId)
  );

  const orderRows = useSelector(getOrderRowsByOrderId(orderId));

  const requestState = useSelector(getRequestState(requestId));

  const targetRowsWithoutOrderRowId = targetRowsToBeSplit.filter(
    ({ orderRowId }) => orderRowId === null
  );

  const targetRowsWithOrderRowId = targetRowsToBeSplit.filter(
    ({ orderRowId }) => orderRowId !== null
  );

  const selectedOrderRowIds = targetRowsToBeSplit
    .map(({ orderRowId }) => orderRowId)
    .filter(isNotNull);

  const filteredOrderRows = Object.values(orderRows)
    .filter(isDefined)
    .filter(({ id }) => selectedOrderRowIds.includes(id));

  const allTargetRowIdsRelatedToOrderRows = [
    ...new Set(filteredOrderRows.map((row) => row.targetRowIds).flat()),
  ];

  const allRelatedTargetRows = useSelector(
    getTargetRowsByIds(allTargetRowIdsRelatedToOrderRows, orderId)
  );

  // fetch topics so default topic can be pre-selected in draft row
  const topics =
    useRemoteData(getOrderTopics(orderId), fetchTopicsForOrder(orderId)) ?? [];

  const defaultTopicId =
    topics.find(({ defaultTopic }) => defaultTopic)?.id ?? '';

  const sendRequest = (values: FormValueShape) => {
    const splitFromTargetRowIds = Object.keys(values.rows);

    const data = splitFromTargetRowIds.reduce((acc, id) => {
      const splitRows = values.rows[id]?.map((row) => {
        return {
          ...row,
          quantity:
            row.quantity !== ''
              ? big.fromInputString(row.quantity, '').toString()
              : '',
          unitPrice:
            row.unitPrice !== ''
              ? big.fromInputString(row.unitPrice, '').toString()
              : '',
        };
      });

      return splitRows ? [...acc, ...splitRows] : acc;
    }, [] as SplitDraftTargetRow[]);

    dispatch(requestSplitTargetRows({ requestId, data }));
  };

  // observe request state: close modal when POST succeeds
  useEffect(() => {
    if (requestState === 'Success') {
      onClose(true);
    }

    if (targetRowIds.length === 0) {
      onClose();
    }
  }, [requestState, onClose, targetRowIds.length]);

  const titleText = useTxt('order.targetSplitModal.title');
  const titleRemarkText = useTxt('order.targetSplitModal.title.remark');

  const withoutOrderRowsTableText = useTxt(
    'order.targetSplitModal.withoutOrderRowsTable.title'
  );

  const withOrderRowsTableText = useTxt(
    'order.targetSplitModal.withOrderRowsTable.title'
  );
  const requiredFieldText = useTxt('form.inputError.isRequired');

  /* eslint-disable react/no-this-in-sfc */
  yup.addMethod(
    yup.string,
    'formatBigInt',
    function formatBigInt(message: string = 'not a valid number') {
      return this.test('formatBigInt', message, (value: string | undefined) => {
        if (value === undefined) {
          return false;
        }

        try {
          big.fromInputString(value);

          return true;
        } catch {
          return false;
        }
      });
    }
  );
  /* eslint-enable react/no-this-in-sfc */

  const arrayValidationSchema = yup
    .object()
    .shape({
      rows: yup.lazy((value) => {
        if (value !== undefined) {
          const validationObject = yup
            .object()
            .shape({
              description: yup.string(),
              quantity: yup.string().formatBigInt(),
              unit: yup.string(),
              unitPrice: yup.string().formatBigInt(),
              orderId: yup.string().required(requiredFieldText),
              topicId: yup.string().required(requiredFieldText),
              analysisId: yup.string(),
            })
            .required();

          const newEntries = Object.keys(value).reduce(
            (acc, val) => ({
              ...acc,
              [val]: yup.array().of(validationObject),
            }),
            {}
          );

          return yup.object().shape(newEntries);
        }

        return yup.mixed().notRequired();
      }),
    })
    .required();

  const blankRow = {
    rowId: v4(),
    description: '',
    quantity: '',
    unit: '',
    unitPrice: '',
    orderId,
    topicId: defaultTopicId,
    analysisId: '',
    splitFromTargetRowId: '',
    orderRowId: '',
  };

  const calculateSplitTargetSum = (values: FormValueShape): Big => {
    try {
      const splitRows = Object.values(values.rows).filter(isDefined).flat();

      const result = splitRows.reduce(
        (acc, val) =>
          acc.add(
            big
              .fromInputString(val?.quantity, 0)
              .mul(big.fromInputString(val?.unitPrice, 0))
          ),
        new Big(0)
      );

      return result;
    } catch (e) {
      return new Big(0);
    }
  };

  // NB: The purpose of this component is to hook into Formik events
  // If POST request fails, manually set isSubmitting to false; this re-enables Save button
  const FormikChangeListener = () => {
    const { setSubmitting } = useFormikContext();

    useEffect(() => {
      if (requestState === 'Failure') {
        setSubmitting(false);
      }
    }, [setSubmitting]);

    return null;
  };

  const initialValues = targetRowsToBeSplit.reduce(
    (acc, row) => {
      return {
        rows: {
          ...acc.rows,
          [row.id]: [
            {
              ...blankRow,
              splitFromTargetRowId: row.id,
              orderRowId: row.orderRowId,
            },
          ],
        },
      };
    },
    { rows: {} } as FormValueShape
  );

  return (
    <Modal onClose={onClose}>
      <FormContainer>
        <StyledHeader>
          {titleText}
          <StyledRemark>{titleRemarkText}</StyledRemark>
        </StyledHeader>
        <Formik
          initialValues={initialValues}
          onSubmit={sendRequest}
          validationSchema={arrayValidationSchema}
        >
          {({
            errors,
            values,
            setValues,
            setFieldValue,
            handleChange,
            isValid,
            isSubmitting,
          }) => (
            <StyledForm>
              <StyledContent>
                <FormikChangeListener />
                {targetRowsWithoutOrderRowId.length > 0 ? (
                  <>
                    <TableDescription>
                      {withoutOrderRowsTableText}
                    </TableDescription>

                    <SplitOrderAndTargetRowsTable
                      errors={errors}
                      values={values}
                      setValues={setValues}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      selectedTargetRows={targetRowsWithoutOrderRowId}
                    />
                  </>
                ) : null}
                {targetRowsWithOrderRowId.length > 0 ? (
                  <>
                    <TableDescription>
                      {withOrderRowsTableText}
                    </TableDescription>
                    <SplitOrderAndTargetRowsTable
                      errors={errors}
                      values={values}
                      setValues={setValues}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      selectedTargetRows={targetRowsWithOrderRowId}
                      orderRows={filteredOrderRows}
                      allRelatedTargetRows={allRelatedTargetRows}
                      setFormOrderRowInvalid={callBackFunction}
                    />
                  </>
                ) : null}
                <ToolBarAndStatusGroup alignRight>
                  <SumField>
                    <Txt id="order.targetSplitModal.targetRowsSum" />{' '}
                    <b>
                      {big.priceFormat(
                        calculateToBeSplitTargetSum(targetRowsToBeSplit)
                      )}
                    </b>
                  </SumField>
                  <SumField>
                    <Txt id="order.targetSplitModal.orderRowsSum" />{' '}
                    <b>
                      {big.priceFormat(
                        calculateOrderSum(
                          targetRowsToBeSplit,
                          allRelatedTargetRows,
                          filteredOrderRows
                        )
                      )}
                    </b>
                  </SumField>
                </ToolBarAndStatusGroup>
              </StyledContent>
              <Footer>
                <RedSpan>
                  {calculateToBeSplitTargetSum(targetRowsToBeSplit)
                    .minus(calculateSplitTargetSum(values))
                    .abs()
                    .gt(0.01) && !formInvalid ? (
                    <Txt
                      id="order.targetSplitModal.remainingAmount"
                      values={{
                        remainingAmount: big.priceFormat(
                          calculateToBeSplitTargetSum(
                            targetRowsToBeSplit
                          ).minus(calculateSplitTargetSum(values))
                        ),
                      }}
                    />
                  ) : null}
                  {formInvalid ? (
                    <Txt id="order.targetSplitModal.tooMuchArrivals" />
                  ) : null}
                </RedSpan>
                <CancelButton type="button" onClick={() => onClose()}>
                  <Txt id="common.cancel" />
                </CancelButton>
                <ActionButton
                  type="submit"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    formInvalid ||
                    calculateToBeSplitTargetSum(targetRowsToBeSplit)
                      .minus(calculateSplitTargetSum(values))
                      .abs()
                      .gt(0.01)
                  }
                >
                  <Txt id="order.targetSplitModal.button.submit" />
                </ActionButton>
              </Footer>
            </StyledForm>
          )}
        </Formik>
      </FormContainer>
    </Modal>
  );
};

export const calculateToBeSplitTargetSum = (
  targetRowsToBeSplit: APITargetRow[],
  orderRowIds?: (string | null)[]
): Big => {
  try {
    const result = targetRowsToBeSplit
      .filter((row) =>
        orderRowIds ? orderRowIds.includes(row.orderRowId) : true
      )
      .reduce(
        (acc, val) =>
          acc.add(
            (val.quantity ?? new Big(0)).mul(val.unitPrice ?? new Big(0))
          ),
        new Big(0)
      );

    return result;
  } catch (e) {
    return new Big(0);
  }
};

export const calculateAllRelatedTargetRowsSum = (
  allRelatedTargetRows: APITargetRow[],
  orderRowIds?: (string | null)[]
): Big => {
  try {
    const result = allRelatedTargetRows
      .filter((row) =>
        orderRowIds ? orderRowIds.includes(row.orderRowId) : true
      )
      .reduce(
        (acc, val) =>
          acc.add(
            (val.quantity ?? new Big(0)).mul(val.unitPrice ?? new Big(0))
          ),
        new Big(0)
      );

    return result;
  } catch (e) {
    return new Big(0);
  }
};

export const calculateOrderSum = (
  targetRowsToBeSplit: APITargetRow[],
  allRelatedTargetRows: APITargetRow[],
  filteredOrderRows: APIOrderRow[]
): Big => {
  try {
    const result = filteredOrderRows.reduce((acc, val) => {
      const allRelatedTargetSum = calculateAllRelatedTargetRowsSum(
        allRelatedTargetRows,
        [val.id]
      );

      const targetToBeSplit = calculateToBeSplitTargetSum(targetRowsToBeSplit, [
        val.id,
      ]);

      const orderRowSum = acc.add(
        (val.quantity ?? new Big(0)).mul(val.unitPrice ?? new Big(0))
      );

      const portionOfForecast = targetToBeSplit
        .div(allRelatedTargetSum)
        .mul(orderRowSum);

      return portionOfForecast;
    }, new Big(0));

    return result;
  } catch (e) {
    return new Big(0);
  }
};

const CancelButton = styled(SecondaryButton)`
  margin-right: 4px;
`;

const ActionButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const TableDescription = styled.span`
  padding-top: ${({ theme }) => `${theme.margin[8]}`};
  padding-bottom: ${({ theme }) => `${theme.margin[8]}`};
  font-weight: bold;
`;

export const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  width: 80vw;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};

  height: ${(props) => props.theme.margin[48]};

  display: flex;
  align-items: center;

  font-weight: bold;
`;

const StyledRemark = styled.p`
  padding: ${({ theme }) => `${theme.margin[8]}`};
  font-weight: 400;
`;

const StyledContent = styled(Content)`
  padding: ${(props) =>
    `${props.theme.margin[8]} ${props.theme.margin[16]} ${props.theme.margin[16]}`};
  max-height: 80vh;
  overflow-y: scroll;
`;

type StatusGroupProps = {
  alignRight?: boolean;
};

const ToolBarAndStatusGroup = styled.div<StatusGroupProps>`
  margin-top: ${(props) => `${props.theme.margin[24]}`};

  width: 100%;

  display: flex;
  align-items: left;
  justify-content: left;

  ${(props) =>
    props.alignRight &&
    css`
      flex-direction: column;
      align-items: end;
      justify-content: flex-end;
    `}
`;

const SumField = styled.div`
  padding-bottom: ${(props) => props.theme.margin[10]};
  padding-right: ${(props) => props.theme.margin[176]};
`;

const RedSpan = styled.span`
  margin-right: ${(props) => props.theme.margin[16]};
  color: ${({ theme: { color } }) => color.red};
`;
