import React from 'react';

import { Big } from 'big.js';
import styled, { css } from 'styled-components';

import {
  BigPercentageValue,
  BigPriceValue,
} from '../../../../components/BigValue';
import Tooltip from '../../../../components/Tooltip';

type TrProps = {
  lineHeight?: 'normal' | 'condensed';
};

const Tr = styled.tr<TrProps>`
  line-height: ${({ theme, lineHeight }) =>
    lineHeight === 'condensed' ? theme.margin[20] : theme.margin[32]};
`;

type TableCellProps = {
  indentForSectionName?: number;
  fontStyleForSectionName?: 'normal' | 'italic';
  fontWeight: 'normal' | 'bold';
  textAlign?: 'left' | 'right';
  fontColor?: string;
};

const Td = styled.td<TableCellProps>`
  ${(props) =>
    props.indentForSectionName
      ? css`
          padding-left: ${props.indentForSectionName}px;
        `
      : ''}
  ${(props) =>
    props.fontStyleForSectionName
      ? css`
          font-style: ${props.fontStyleForSectionName};
        `
      : ''}

  ${(props) =>
    props.textAlign
      ? css`
          text-align: ${props.textAlign};
        `
      : ''}

  ${(props) =>
    props.fontColor
      ? css`
          color: ${props.fontColor};
        `
      : ''}

  font-weight: ${(props) => props.fontWeight};
`;

type RowType =
  | 'PriceNoIndent'
  | 'PriceSmallIndent'
  | 'PriceLargeIndent'
  | 'PercentageNoIndent';

type TableRowProps = {
  rowType: RowType;
  sectionName: string;
  currentValue?: Big;
  previousValue?: Big;
  changeValue?: Big;
  changeIcon?: React.ReactElement;
  rowTooltip?: string;
};

const IconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const indentForRowType = (rowType: RowType) => {
  switch (rowType) {
    case 'PriceSmallIndent':
      return 20;
    case 'PriceLargeIndent':
      return 40;
    default:
      return 0;
  }
};

const TableRow = ({
  rowType,
  sectionName,
  currentValue,
  previousValue,
  changeValue,
  changeIcon: ChangeIcon,
  rowTooltip,
}: TableRowProps) => {
  const lineHeight = rowType === 'PriceLargeIndent' ? 'condensed' : 'normal';

  const indentForSectionName = indentForRowType(rowType);

  const fontStyleForSectionName =
    rowType === 'PriceLargeIndent' ? 'italic' : 'normal';

  const fontWeightForSectionName =
    rowType === 'PriceNoIndent' || rowType === 'PercentageNoIndent'
      ? 'bold'
      : 'normal';

  const valueFormat =
    rowType === 'PercentageNoIndent' ? 'BigPercentage' : 'BigPrice';

  if (rowTooltip) {
    return (
      <Tr lineHeight={lineHeight}>
        <Td
          indentForSectionName={indentForSectionName}
          fontStyleForSectionName={fontStyleForSectionName}
          fontWeight={fontWeightForSectionName}
        >
          <Tooltip
            tip={rowTooltip}
            className="compressed-tooltip"
            place="right"
          >
            {sectionName}
          </Tooltip>
        </Td>
        <Td fontWeight="bold" textAlign="right">
          <Tooltip
            tip={rowTooltip}
            className="compressed-tooltip"
            place="right"
          >
            <Value value={currentValue} priceFormat={valueFormat} />
          </Tooltip>
        </Td>
        <Td fontWeight="normal" textAlign="right" fontColor="#92918E">
          <Tooltip
            tip={rowTooltip}
            className="compressed-tooltip"
            place="right"
          >
            <Value value={previousValue} priceFormat={valueFormat} />
          </Tooltip>
        </Td>
        <Td fontWeight="normal" textAlign="right" fontColor="#7A7977">
          <Tooltip
            tip={rowTooltip}
            className="compressed-tooltip"
            place="right"
          >
            <Value value={changeValue} priceFormat={valueFormat} />
          </Tooltip>
        </Td>
        <Td fontWeight="normal">
          <Tooltip
            tip={rowTooltip}
            className="compressed-tooltip"
            place="right"
          >
            <IconDiv>{ChangeIcon}</IconDiv>
          </Tooltip>
        </Td>
      </Tr>
    );
  }

  return (
    <Tr lineHeight={lineHeight}>
      <Td
        indentForSectionName={indentForSectionName}
        fontStyleForSectionName={fontStyleForSectionName}
        fontWeight={fontWeightForSectionName}
      >
        {sectionName}
      </Td>
      <Td fontWeight="bold" textAlign="right">
        <Value value={currentValue} priceFormat={valueFormat} />
      </Td>
      <Td fontWeight="normal" textAlign="right" fontColor="#92918E">
        <Value value={previousValue} priceFormat={valueFormat} />
      </Td>
      <Td fontWeight="normal" textAlign="right" fontColor="#7A7977">
        <Value value={changeValue} priceFormat={valueFormat} />
      </Td>
      <Td fontWeight="normal">
        <IconDiv>{ChangeIcon}</IconDiv>
      </Td>
    </Tr>
  );
};

type ValueProps = {
  value?: Big;
  priceFormat: 'BigPrice' | 'BigPercentage';
};

export const Value = ({ value, priceFormat }: ValueProps) => {
  if (!value) {
    return null;
  }

  switch (priceFormat) {
    case 'BigPrice': {
      return <BigPriceValue value={value} decimals={0} />;
    }
    default: {
      return <BigPercentageValue value={value} decimals={0} />;
    }
  }
};

export default TableRow;
