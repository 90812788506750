import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getProjectOrders } from '../../../../store/reducers/order/order';
import {
  getTopLevelHierarchyEntriesByProjectId,
  getTargetRowHierarchyEntriesForProject,
  getHierarchyMaxDepth,
} from '../../../../store/reducers/target/hierarchy';
import {
  getTopLevelTargetRows,
  getTargetRowsForProject,
} from '../../../../store/reducers/target/targetRows';
import {
  getRequestStateByProjectId,
  getTopics,
} from '../../../../store/reducers/topic';
import { getTargetViewOpenDepth } from '../../../../store/reducers/ui';
import { getProjectWorkPackages } from '../../../../store/reducers/workPackage';

import * as Actions from '../../../../store/actions';
import {
  fetchOrdersForProject,
  fetchWorkPackagesForProject,
} from '../../../../store/actions';
import {
  requestTargetRowsForProject,
  getTargetRowHierarchyForProject,
} from '../../../../store/actions/target';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import Cell from '../../../../components/Cell';
import { GlobalLoadingSpinner } from '../../../../components/Loading';
import { Table } from '../../../../components/Table';

import * as big from '../../../../utils/big';

import TableHeader from './TableHeader';
import TargetRow from './TargetRow';
import TargetRowHierarchyEntry from './TargetRowHierarchyEntry';

type TargetTableProps = {
  projectId: string;
};

const TargetTable = ({ projectId }: TargetTableProps) => {
  const sectionTitleText = useTxt('target.noHierarchies.sectionTitle');

  const topics =
    useRemoteData(
      getRequestStateByProjectId(projectId),
      Actions.fetchTopicsForProject(projectId)
    ) ?? [];

  const targetRows =
    useRemoteData(
      getTopLevelTargetRows({ projectId }),
      requestTargetRowsForProject({ projectId })
    ) ?? [];

  const targetRowHierarchyEntries =
    useRemoteData(
      getTopLevelHierarchyEntriesByProjectId(projectId),
      getTargetRowHierarchyForProject({ projectId })
    ) ?? [];

  const allTargetRowHierarchyEntries =
    useRemoteData(
      getTargetRowHierarchyEntriesForProject(projectId),
      Actions.getTargetRowHierarchyForProject({ projectId })
    ) ?? [];

  const allTargetRows =
    useRemoteData(
      getTargetRowsForProject({ projectId }),
      Actions.requestTargetRowsForProject({ projectId })
    ) ?? [];

  const allTopics = useSelector(getTopics, shallowEqual);

  const allOrders =
    useRemoteData(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const allWorkPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const openDepth = useSelector(getTargetViewOpenDepth(), shallowEqual);

  const maxDepth =
    useRemoteData(
      getHierarchyMaxDepth(projectId),
      getTargetRowHierarchyForProject({ projectId })
    ) ?? -1;

  const targetRowTotal = targetRows.reduce((acc, val) => {
    const unitPrice = val.unitPrice ?? new Big(0);
    const quantity = val.quantity ?? new Big(0);

    return acc.add(unitPrice.mul(quantity));
  }, new Big(0));

  const isFetchingMaxDepth = useSelector(getHierarchyMaxDepth(projectId));

  // don't show anything until topics are loaded
  if (!topics || isFetchingMaxDepth.kind !== 'Success') {
    return <GlobalLoadingSpinner />;
  }

  return (
    <Table>
      <TableHeader
        targetRowHierarchyEntries={allTargetRowHierarchyEntries}
        targetRows={allTargetRows}
        workPackages={allWorkPackages}
        orders={allOrders}
        topics={allTopics}
        openDepth={openDepth}
        maxDepth={maxDepth}
      />
      <tbody>
        {targetRowHierarchyEntries.map(({ id }) => (
          <TargetRowHierarchyEntry key={`hierarchyEntry-${id}`} entryId={id} />
        ))}
        {targetRows.length > 0 ? (
          <>
            <tr>
              <StyledCell
                colSpan={5}
                contentContainer={SectionTitleContentContainer}
              >
                <SectionTitleBar>{sectionTitleText}</SectionTitleBar>
              </StyledCell>
              <StyledCell
                contentContainer={SectionTitleContentContainer}
                align="right"
              >
                <SectionTitleBar justifyContentEnd>
                  {big.priceFormat(targetRowTotal)}
                </SectionTitleBar>
              </StyledCell>
              <StyledCell
                colSpan={2}
                contentContainer={SectionTitleContentContainer}
              >
                <SectionTitleBar> </SectionTitleBar>
              </StyledCell>
            </tr>
            {targetRows.map(({ id }) => (
              <TargetRow key={`targetRow-${id}`} rowId={id} />
            ))}
          </>
        ) : null}
      </tbody>
    </Table>
  );
};

type SectionTitleBarProps = {
  justifyContentEnd?: boolean;
};

const SectionTitleBar = styled.div<SectionTitleBarProps>`
  padding: ${(props) => props.theme.margin[20]}
    ${(props) => props.theme.margin[8]};

  height: ${(props) => props.theme.margin[20]};

  display: flex;
  justify-content: ${(props) =>
    props.justifyContentEnd ? 'flex-end' : 'space-between'};
  align-items: center;

  background: ${(props) => props.theme.color.primaryRowBackground};

  font-weight: bold;
  font-size: 1.125rem;
`;

const SectionTitleContentContainer = styled.div`
  margin-top: ${(props) => props.theme.margin[24]};
`;

const StyledCell = styled(Cell)`
  padding: 0;
`;

export default TargetTable;
