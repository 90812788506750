import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { getProjectById } from '../../../../store/reducers/project';

import useRouteParams from '../../../../hooks/useRouteParams';
import useChangeViewMode from '../../hooks/useChangeViewMode';

import { IconTextButton } from '../../../../components/Buttons';

import { IconArrowLeft } from '../../../../assets/svg';

import { generateUrl, routes } from '../../../../routes';
import { CommentModal } from '../../CommentModal';
import EditOrderSidebar from './EditOrderSidebar';
import MainButtons from './MainButtons';
import OrderInfoTitle from './OrderInfoTitle';

const OrderPageTop = () => {
  const toggleShowComments = () => setShowCommentModal(!showCommentModal);

  const { orderId, projectId, showInfo } = useRouteParams();

  const changeViewMode = useChangeViewMode();

  const toggleEditOrderSideBar = (visible?: boolean) => {
    const nextViewMode = showInfo === 'info' ? 'hideInfo' : 'showInfo';
    changeViewMode(visible === false ? 'hideInfo' : nextViewMode);
  };

  const project = useSelector(getProjectById(projectId));
  const projectName = project?.name ?? '';

  const history = useHistory();

  const [showCommentModal, setShowCommentModal] = useState(false);

  const onClose = () => {
    setShowCommentModal(false);
  };

  const navigateBackToProject = () => {
    history.push(generateUrl({ route: routes.PROJECT, projectId }));
  };

  return (
    <>
      <IconTextButton icon={IconArrowLeft} onClick={navigateBackToProject}>
        {projectName}
      </IconTextButton>
      <Row>
        <OrderInfoTitle orderId={orderId} />
        <MainButtons
          isInfoShown={!!showInfo}
          isCommentsShown={showCommentModal}
          toggleShowInfo={toggleEditOrderSideBar}
          toggleShowComments={toggleShowComments}
        />
      </Row>
      {showCommentModal ? (
        <CommentModal orderId={orderId} onClose={onClose} />
      ) : null}
      {showInfo ? (
        <EditOrderSidebar
          onClose={() => {
            toggleEditOrderSideBar(false);
          }}
          orderId={orderId}
        />
      ) : null}
    </>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default OrderPageTop;
