import React from 'react';

import styled from 'styled-components';

import useTxt from '../../../../../../hooks/useTxt';

import { IconButton } from '../../../../../../components/Buttons';
import { StickyTableHeader } from '../../../../../../components/Table';
import Txt from '../../../../../../components/Txt';

import getWidths from '../../../../../../utils/headerWidth';

import { IconSortDown, IconSortUp } from '../../../../../../assets/svg';

import { MoveCostModalReducerProps } from './MoveCostModal';

const OrderListWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
`;

const OrderListTable = styled.table`
  margin: 0;

  padding: 0 28px 0 0;

  width: 100%;

  flex: 1;

  font-family: 'Graphik', sans-serif;
  font-size: 0.75rem;
  font-weight: normal;
`;

const textIdHead = 'order.receiveMode.moveCostModalTH.';

const tableColumns = {
  visibleCode: { align: 'left', relativeWidth: 4 },
  name: { align: 'left', relativeWidth: 12 },
  contractor: { align: 'right', relativeWidth: 6 },
} as const;

type ThProps = {
  name: keyof typeof tableColumns;
  children?: React.ReactNode;
};

const getColumnPercent = getWidths(tableColumns);

const Th = styled.th<ThProps>`
  padding: ${({ theme: { margin } }) => `${margin[8]} ${margin[8]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  align-items: center;
  text-align: ${({ name }) => tableColumns[name].align};
`;

const ThDiv = styled.div<ThProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ name }) =>
    tableColumns[name].align === 'left' ? 'start' : 'end'};
`;

const StyledTxt = styled.b`
  align-self: flex-end;
  font-weight: bold;
`;

const TxtTh = ({ name, children }: ThProps) => (
  <Th name={name}>
    <ThDiv name={name}>
      {children}
      <Txt id={`${textIdHead}${name}` as const} component={StyledTxt} />
    </ThDiv>
  </Th>
);

const TableHeader = ({ sortOrder, toggleSort }: MoveCostModalReducerProps) => {
  const sortAscText = useTxt('project.procurementTable.areas.open');
  const sortDescText = useTxt('project.procurementTable.areas.closed');

  return (
    <StickyTableHeader>
      <tr>
        <TxtTh name="visibleCode">
          <IconButton
            icon={sortOrder === 'desc' ? IconSortDown : IconSortUp}
            aria-label={sortOrder === 'desc' ? sortAscText : sortDescText}
            onClick={toggleSort}
          />
        </TxtTh>
        <TxtTh name="name" />
        <TxtTh name="contractor" />
      </tr>
    </StickyTableHeader>
  );
};

export const OrderList = ({
  children,
  sortOrder,
  toggleSort,
}: React.PropsWithChildren<MoveCostModalReducerProps>) => (
  <OrderListWrapper>
    <OrderListTable>
      <TableHeader sortOrder={sortOrder} toggleSort={toggleSort} />
      <tbody>{children}</tbody>
    </OrderListTable>
  </OrderListWrapper>
);
