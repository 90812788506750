import axios, { Method } from 'axios';
import * as t from 'io-ts';
import { DecodeError } from 'io-ts-promise';
import reporter from 'io-ts-reporters';

import { setCsrfToken, getCsrfToken } from './csrfToken';

export enum ApiErrorStatus {
  ValidationError = 422,
  Conflict = 409,
  Unauthorized = 401,
}

export type BackendError = {
  status: ApiErrorStatus;
  message: string;
};

export class ApiError extends Error {
  private error: BackendError;

  public getBackendError() {
    return this.error;
  }

  constructor(error: BackendError) {
    super('ApiError');
    this.error = error;
    this.name = 'ApiError';
  }
}

export const apiErrorHandlingWithDecode = (error: unknown) => {
  if (error instanceof ApiError) {
    return error.getBackendError();
  }

  if (error instanceof DecodeError) {
    const report = reporter.report(t.failures(error.errors))[0];

    return {
      status: 422,
      message: report,
    };
  }

  return undefined;
};

type RequestProps = {
  method: Method;
  url: string;
  body?: Record<string, unknown> | FormData;
};

const request = <T>(props: RequestProps) =>
  axios
    .request<T>({
      method: props.method,
      url: `${process.env.REACT_APP_API_BASE_URL}${props.url}`,
      headers: {
        'Content-Type':
          props.body && props.body instanceof FormData
            ? 'multipart/form-data'
            : 'application/json',
        ...(props.method !== 'GET' ? { 'X-CSRF-Token': getCsrfToken() } : {}),
      },
      withCredentials: true,
      data: props.body,
    })
    .then((response) => {
      if (response.headers['x-set-csrf-token']) {
        setCsrfToken(response.headers['x-set-csrf-token']);
      }

      return response.data;
    })
    .catch((error) => {
      // Backend responded with non-200 status code that has valid content
      if (error.response) {
        throw new ApiError(error.response.data);
      }

      // No response received
      throw new Error('Unknown error when doing request');
    });

export const GET = <T = unknown>(url: string) =>
  request<T>({ method: 'GET', url });

export const PUT = <T>(url: string, body: Record<string, unknown>) =>
  request<T>({ method: 'PUT', url, body });

export const POST = <T>(
  url: string,
  body: Record<string, unknown> | FormData
) => request<T>({ method: 'POST', url, body });

export const DELETE = <T>(url: string) => request<T>({ method: 'DELETE', url });

export const getBaseApiUrl = () => {
  return `${process.env.REACT_APP_API_BASE_URL}`;
};

export const downloadFile = (url: string) => {
  return axios
    .request({
      url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
      method: 'GET',
      responseType: 'blob', // important
      withCredentials: true,
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      let fileName = 'unknown';
      const searchKey = 'Content-Disposition';
      const asLowercase = searchKey.toLowerCase();

      const objectKey =
        Object.keys(response.headers).find(
          (key) => key.toLowerCase() === asLowercase
        ) ?? 'content-disposition';

      const contentDisposition = response.headers[objectKey] as string;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

        if (fileNameMatch?.length === 2) {
          [, fileName] = fileNameMatch;
        }
      }

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((error) => {
      // Backend responded with non-200 status code that has valid content
      if (error.response) {
        throw new ApiError(error.response.data);
      }

      // No response received
      throw new Error('Unknown error when doing request');
    });
};
