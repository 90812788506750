import { Reducer } from 'redux';

import { APIUser } from '../../types/api';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

export type UserState = {
  loggedIn?: boolean;
  current: APIUser | undefined;
};

const initialState: UserState = {
  loggedIn: undefined,
  current: undefined,
};

const userReducer: Reducer<UserState, ActionTypes> = (
  state = initialState,
  action
): UserState => {
  switch (action.type) {
    case 'GET_USER_FAILURE':
      return { ...state, loggedIn: false, current: undefined };
    case 'GET_USER_SUCCESS':
      return { ...state, loggedIn: true, current: action.payload };
    case 'PUT_USER_SUCCESS': {
      return { ...state, current: action.payload };
    }
    default:
      return state;
  }
};

export const selectCurrentUser = (state: AppState) => state.user.current;

export const getUserName = (state: AppState) => {
  const name = state.user.current?.name ?? '';

  return name;
};

export default userReducer;
