import moment from 'moment';

import { ActualCost } from '../store/reducers/actualCost';
import { InvoiceHeader } from '../store/reducers/invoiceHeader';

import { APICommonProps, APIOrderRowPostBody } from '../types/api';
import { ID, GlobalState, MonthNumber } from '../types/general';

export const ORDER_ROW_STATUS_CONTRACT = '2';
export const ORDER_ROW_STATUS_CHANGEORDER = '3';
export const ORDER_ROW_STATUS_RESERVES = '1';

export const INVOICE_HEADER_STATUS_NOT_HANDLED = ['1'];
export const INVOICE_HEADER_STATUS_ACCEPTED = ['2', '3'];
export const INVOICE_HEADER_STATUS_COMPLAINT_FILED = ['4'];
export const INVOICE_HEADER_STATUS_DECLINED = ['5', '6'];
export const INVOICE_HEADER_STATUS_BEING_CORRECTED = ['7', '8', '11', '12'];
export const INVOICE_HEADER_STATUS_CORRECTED = ['9', '10'];

export const ALL_UNPROCESSED_INVOICE_STATUSES = INVOICE_HEADER_STATUS_NOT_HANDLED.concat(
  INVOICE_HEADER_STATUS_COMPLAINT_FILED
).concat(INVOICE_HEADER_STATUS_BEING_CORRECTED);

export const ACTUAL_COST_STATUS_NOT_HANDLED = '1';
export const ACTUAL_COST_STATUS_HANDLED = '2';
export const ACTUAL_COST_STATUS_CANCELED = '3';

export const REVENUE_ROW_STATUS_CONTRACT = '2';

export const calculateTotals = (numbers?: number[]) =>
  numbers ? numbers.reduce((a, b) => a + b, 0) : 0;

// TODO: Make unit, quantity and unitPrice nullable instead of being 0 or empty
// string. This needs backend support.
export const emptyOrderRow = (
  orderId: ID,
  topicId: ID
): APIOrderRowPostBody => ({
  description: '',
  quantity: null,
  unit: '',
  unitPrice: null,
  orderId,
  topicId,
  statusId: ORDER_ROW_STATUS_CONTRACT,
});

export const emptyTopic = (orderId: ID) => ({
  orderId,
  name: '',
  workPackageId: null,
});

export const emptyPaymentProgramRow = (paymentProgramRowGroupId: ID) => ({
  description: '',
  quantity: null,
  unit: '',
  unitPriceWithoutVat: null,
  vatPrc: null,
  billingDate: Date.now(),
  paymentProgramRowGroupId,
  statusId: REVENUE_ROW_STATUS_CONTRACT,
});

export const allInitialized = <T>(state: GlobalState<T>, ids: ID[]) =>
  ids.every((id) => state.meta.isInitialized[id]);

export const initialGlobalState: GlobalState<any> = {
  meta: {
    isInitialized: {},
  },
  data: {},
};

// Helpers for filtering with type guards. Usage: e.g. array.filter(isDefined)
// Issue: https://github.com/microsoft/TypeScript/issues/16069
export const isDefined = <T>(t: T | undefined): t is T => t !== undefined;

export const isNotNull = <T>(t: T | null): t is T => t !== null;

export const isMonthNumber = (t: MonthNumber | string): t is MonthNumber =>
  Number(t) > -1 && Number(t) < 12 && Number.isInteger(Number(t));

export const isPresent = <T>(t: T | undefined | null): t is T =>
  isDefined(t) && isNotNull(t);

export function isInvoiceHeader(
  row: InvoiceHeader | ActualCost
): row is InvoiceHeader {
  return (row as InvoiceHeader).vendorInvoiceNo !== undefined;
}

// checks if a given string includes a percentage
export const containsPercentage = (str: string) => {
  const percentageRegex = /%/;

  return percentageRegex.test(str);
};

export function updateRecord<T extends APICommonProps>(
  whatevers: Record<string, T>,
  updates: T[]
): Record<string, T> {
  return updates.reduce(
    (result, update) => {
      if (update.isDeleted) {
        const { [update.id]: _, ...remaining } = result;

        return remaining;
      }

      return { ...result, [update.id]: update };
    },
    { ...whatevers }
  );
}

export const updateGlobalStateDerivative = <T extends APICommonProps>(
  state: GlobalState<T>,
  updated: T[] | undefined
): GlobalState<T> => {
  if (!updated) {
    return state;
  }

  const data = updateRecord(state.data, updated);

  // Bake the new state:
  return {
    meta: {
      isInitialized: Object.keys(data).reduce(
        (result, id) => ({ ...result, [id]: true }),
        {}
      ),
    },
    data,
  };
};

export const concatQueryParamsWithString = (
  keyName: string,
  keyValues: string[]
): string => {
  if (keyValues.length === 0) {
    return '';
  }

  return '?'.concat(
    keyName.concat('='),
    keyValues.join('&'.concat(keyName, '='))
  );
};

export const onlyLettersAndNumbersAndDashes = (str: string) => {
  return Boolean(str.match(/^[A-Za-z0-9-]*$/));
};

export const finnishStrictLocalizations = [
  'DD.MM.YYYY',
  'DD.M.YYYY',
  'D.MM.YYYY',
  'D.M.YYYY',
];

export const otherStrictLocalizations = [
  'MM/DD/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'M/D/YYYY',
  'MM.DD.YYYY',
  'MM.D.YYYY',
  'M.DD.YYYY',
  'M.D.YYYY',
];

export const momentValueFromInput = (inputValue: Date, lang: string = 'fi') =>
  moment(
    inputValue,
    lang === 'fi' ? finnishStrictLocalizations : otherStrictLocalizations,
    true
  );
