import { useSelector } from 'react-redux';

import { Reducer } from 'redux';

import { ID } from '@customtypes/general';

import { APIActualCostAttachment } from '../../types/api';

import * as api from '../../utils/api';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import { Selector } from './utils';

import { ActionTypes } from '../actionTypes';
import { getActualCostById } from './actualCost';

import { AppState } from '.';

type Err = api.BackendError | undefined;

export type ActualCostAttachmentState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<ID, APIActualCostAttachment>;
};

const initialState: ActualCostAttachmentState = {
  requests: {},
  data: {},
};

export function getActualCostAttachmentRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    actualCostAttachments: {
      requests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

const actualCostReducer: Reducer<ActualCostAttachmentState, ActionTypes> = (
  state = initialState,
  action
): ActualCostAttachmentState => {
  switch (action.type) {
    case 'GET_ACTUAL_COST_ATTACHMENTS_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_ACTUAL_COST_ATTACHMENTS_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_ACTUAL_COST_ATTACHMENTS_SUCCESS': {
      const { orderId, actualCostAttachments } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', actualCostAttachments),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    default:
      return state;
  }
};

export const useActualCostAttachmentUrls = (actualCostId: ID) => {
  return useSelector((state: AppState) => {
    const actualCost = getActualCostById(actualCostId)(state);

    if (!actualCost) {
      return [];
    }

    return [
      ...(actualCost.invoiceLink ? [actualCost.invoiceLink] : []),
      ...Object.values(state.actualCostAttachments.data)
        .filter((row) => actualCost.actualCostsAttachmentIds.includes(row.id))
        .map((row) => row.attachmentLink),
      ...Object.values(state.actualCostAttachmentFiles.data)
        .filter((row) => row.actualCostId === actualCost.id)
        .map(
          (row) =>
            `${api.getBaseApiUrl()}v1/attachments/actual-costs/${
              row.actualCostId
            }/files/${row.id}`
        ),
    ];
  });
};

export default actualCostReducer;
