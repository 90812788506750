import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../../types/general';

import { BackendError } from '../../../utils/api';
import { isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes } from '../../actionTypes';

export type OrderSnapshot = {
  snapshotId: ID;
  projectId: ID;
  snapshotDescription: string | null;
  companyReportingPeriodDescription: string | null;
  orderId: ID;
  predictionChangeBeforeLocking: Big;
  targetChangeBeforeLocking: Big;
  arrivalsChangeBeforeLocking: Big;
  targetTotal: Big;
  additionalTargetTotal: Big;
  costPredictionTotal: Big;
  contractTotal: Big;
  changeOrdersTotal: Big;
  reservesTotal: Big;
  orderIsDeleted: boolean;
  orderName: string | null;
  orderVisibleCode: string | null;
  current: boolean;
  createdAt: Date;
  updatedAt: Date;
};

type Err = BackendError | undefined;

type State = {
  getRequests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  getCurrentPeriodRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, Record<string, OrderSnapshot>>>;
};

const initialState: State = {
  getRequests: {},
  getCurrentPeriodRequests: {},
  data: {},
};

const orderSnapshotReducer: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'GET_ORDER_SNAPSHOTS_STARTED': {
      const { orderId } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.loading,
      };

      return { ...state, getRequests };
    }
    case 'GET_ORDER_SNAPSHOTS_FAILURE': {
      const { orderId, error } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.fail(error),
      };

      return { ...state, getRequests };
    }
    case 'GET_ORDER_SNAPSHOTS_SUCCESS': {
      const { orderId, orderSnapshots } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        [orderId]: normalizeBy('snapshotId', orderSnapshots),
      };

      return {
        ...state,
        getRequests,
        data,
      };
    }
    case 'GET_ALL_ORDER_SNAPSHOTS_FOR_SNAPSHOT_SUCCESS': {
      const { orderSnapshots } = action.payload;
      const normalizedSnapshots = normalizeBy('orderId', orderSnapshots);

      const orderIds = Object.keys(normalizedSnapshots);

      const newData = orderIds.reduce((previous, key) => {
        const orderSnapshot = normalizedSnapshots[key];

        const normalizedOrderSnapshot = normalizeBy('snapshotId', [
          orderSnapshot,
        ]);

        const previousStateData = state.data[key] ?? {};

        return {
          ...previous,
          [key]: { ...previousStateData, ...normalizedOrderSnapshot },
        };
      }, state.data);

      return { ...state, data: newData };
    }
    case 'GET_CURRENT_PERIOD_ORDER_SNAPSHOTS_STARTED': {
      const { projectId } = action.payload;

      const getCurrentPeriodRequests = {
        ...state.getCurrentPeriodRequests,
        [projectId]: remoteData.loading,
      };

      return { ...state, getCurrentPeriodRequests };
    }
    case 'GET_CURRENT_PERIOD_ORDER_SNAPSHOTS_FAILURE': {
      const { projectId, error } = action.payload;

      const getCurrentPeriodRequests = {
        ...state.getCurrentPeriodRequests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, getCurrentPeriodRequests };
    }
    case 'GET_CURRENT_PERIOD_ORDER_SNAPSHOTS_SUCCESS': {
      const { orderSnapshots, projectId } = action.payload;
      const normalizedSnapshots = normalizeBy('orderId', orderSnapshots);

      const orderIds = Object.keys(normalizedSnapshots);

      const getCurrentPeriodRequests = {
        ...state.getCurrentPeriodRequests,
        [projectId]: remoteData.succeed(undefined),
      };

      const newData = orderIds.reduce((previous, key) => {
        const orderSnapshot = normalizedSnapshots[key];

        const normalizedOrderSnapshot = normalizeBy('snapshotId', [
          orderSnapshot,
        ]);

        const previousStateData = state.data[key] ?? {};

        return {
          ...previous,
          [key]: { ...previousStateData, ...normalizedOrderSnapshot },
        };
      }, state.data);

      return { ...state, data: newData, getCurrentPeriodRequests };
    }
    case 'POST_SNAPSHOT_SUCCESS': {
      // force fetching all data again from backend
      return { data: {}, getCurrentPeriodRequests: {}, getRequests: {} };
    }
    default:
      return state;
  }
};

export default orderSnapshotReducer;

export const getOrderSnapshotsFetchRequest = (
  orderId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    orders: {
      snapshots: {
        getRequests: { [orderId]: request },
      },
    },
  }) => request ?? remoteData.notAsked;
};

export const getCurrentOrderSnapshotsFetchRequest = (
  projectId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    orders: {
      snapshots: {
        getCurrentPeriodRequests: { [projectId]: request },
      },
    },
  }) => request ?? remoteData.notAsked;
};

export const getOrderSnapshots: (
  orderId: string
) => Selector<remoteData.RemoteData<OrderSnapshot[]>> = (orderId) => ({
  orders: {
    snapshots: {
      getRequests: { [orderId]: requestState = remoteData.notAsked },
      data: { [orderId]: snapshots = undefined },
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    if (!snapshots) {
      return [];
    }

    const values = Object.values(snapshots).filter(isDefined);

    return values;
  });

export const getOrderSnapshotsForSnapshotId: (
  snapshotId: string
) => Selector<remoteData.RemoteData<OrderSnapshot[]>> = (snapshotId) => ({
  orders: {
    snapshots: { data },
  },
  snapshots: {
    getOrderSnapshotsRequests: {
      [snapshotId]: requestState = remoteData.notAsked,
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    const snapshotsForOrder = Object.values(data).filter(isDefined);

    if (snapshotsForOrder.length === 0) {
      return [];
    }

    const allSnapshotData = snapshotsForOrder
      .map((row) => Object.values(row).filter(isDefined))
      .flat();

    const values = allSnapshotData.filter(
      (entry) => entry.snapshotId === snapshotId
    );

    return values;
  });

export const getCurrentPeriodOrderSnapshots: (
  projectId: string
) => Selector<remoteData.RemoteData<OrderSnapshot[]>> = (projectId) => ({
  orders: {
    snapshots: {
      data,
      getCurrentPeriodRequests: {
        [projectId]: requestState = remoteData.notAsked,
      },
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    const snapshotsForOrder = Object.values(data).filter(isDefined);

    if (snapshotsForOrder.length === 0) {
      return [];
    }

    const allSnapshotData = snapshotsForOrder
      .map((row) => Object.values(row).filter(isDefined))
      .flat();

    const values = allSnapshotData.filter(
      (entry) => entry.projectId === projectId && entry.snapshotId === 'NA'
    );

    return values;
  });
