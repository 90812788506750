import styled from 'styled-components';

type TableErrorNotificationProps = {
  position?: 'right' | 'left';
};

export const TableErrorNotification = styled.div<TableErrorNotificationProps>`
  position: absolute;
  ${({ position }) => (position === 'right' ? 'left: 0;' : 'right: 0;')}
  top: ${(props) => props.theme.margin[24]};

  border: 1px solid ${({ theme }) => theme.color.graphiteB76};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

  padding: ${(props) => props.theme.margin[8]};

  width: 15rem; /* 240px */

  display: flex;
  flex-flow: column nowrap;

  background: ${(props) => props.theme.color.white};

  text-align: left;
  color: ${(props) => props.theme.color.red};

  z-index: 10;
`;
