import { orderBy } from 'lodash';

// STEPS
// 1. A user toggles sorting/filtering from tables.
// 2. This Dispatches an action with the new SortOrder.
// 3. The changes by the action directly goes to the reducers.
// 4. Update the store with the new SortOrder.

export type SortOrder = 'Ascending' | 'Descending';

// T: sort by string or sortableKey e.g 'dueDate'
export type SortState<T> = Array<{
  key: T;
  sortOrder: SortOrder;
}>;

// gets current sort order(ASC or DESC) of a given key from sortOrders array
export const getSortOrderForKey = <T extends SortState<K>, K>(
  sortOrders: T,
  sortableKey: K
): SortOrder =>
  sortOrders.find(({ key }) => key === sortableKey)?.sortOrder ?? 'Ascending';

// sorts give items array using sortable value
export const sortItems = <T extends object, K extends keyof T>(
  sortableItems: Array<T>,
  sortOrders: SortState<K>
) => {
  const sorters = sortOrders.map(({ key }) => ({ [key]: sortableValue }: T) =>
    sortableValue
  );

  const orders = sortOrders.map(
    ({ sortOrder }) =>
      (({
        Ascending: 'asc',
        Descending: 'desc',
      } as const)[sortOrder])
  );

  return orderBy(sortableItems, sorters, orders);
};
