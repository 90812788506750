import Big from 'big.js';

import { APIOrderRow, APIOrderRowPutBody } from '../../../../../../types/api';
import { ValidationErrorTextId } from '../../../../../../types/general';

import * as big from '../../../../../../utils/big';

export type EditableOrderRowState = {
  status: string;
  description: string;
  quantity: string;
  unit: string;
  unitPrice: string;
  rowNumber: string;
};

export type ValidationErrorState = {
  [key in keyof EditableOrderRowState]: ValidationErrorTextId | null;
};

export const initialValidationErrorState: ValidationErrorState = {
  status: null,
  description: null,
  unit: null,
  quantity: null,
  unitPrice: null,
  rowNumber: null,
};

export const constructPutRequest = (
  state: EditableOrderRowState,
  updatedAt: string
): APIOrderRowPutBody => ({
  description: state.description,
  quantity:
    state.quantity === ''
      ? null
      : big.fromInputString(state.quantity).toString(),
  unit: state.unit,
  unitPrice:
    state.unitPrice === ''
      ? null
      : big.fromInputString(state.unitPrice).toString(),
  updatedAt,
  statusId: state.status,
  rowNumber: big.fromInputString(state.rowNumber).toNumber(),
});

export const MAX_DESCRIPTION_LENGTH = 1000;
export const MAX_UNIT_LENGTH = 7;

export const validateField = (
  field: keyof EditableOrderRowState,
  value: string,
  row: APIOrderRow | undefined
): ValidationErrorTextId | null => {
  switch (field) {
    case 'unitPrice':
      try {
        // Fields are allowed to be empty
        if (!row && value === '') {
          return null;
        }

        const quantity = big.fromInputString(value);

        if (!big.hasMaxNWholeNumbers(quantity, 12) && value.startsWith('-')) {
          return 'validation.hasMaxNWholeNumbersNegative';
        }

        if (!big.hasMaxNWholeNumbers(quantity, 12)) {
          return 'validation.hasMaxNWholeNumbersPositive';
        }

        if (!big.hasMaxNDecimals(quantity, 4)) {
          return 'validation.hasMaxNDecimals';
        }
      } catch (err) {
        return 'validation.number.error';
      }

      return null;

    case 'quantity': {
      try {
        // Fields are allowed to be empty
        if (!row && value === '') {
          return null;
        }

        const quantity = big.fromInputString(value);

        // Satisfy:
        // if arrival=0, allow quantity to be anything
        // if arrival>0, allow quantity to be >=arrival
        // if arrival<0, allow quantity to be <=arrival
        if (row && !row.arrivalQuantity.eq(0)) {
          if (row.arrivalQuantity.gt(0) && quantity.lt(row.arrivalQuantity)) {
            return 'validation.quantity.orderedLessThanReceived';
          }

          if (row.arrivalQuantity.lt(0) && quantity.gt(row.arrivalQuantity)) {
            return 'validation.quantity.orderedMoreThanReceived';
          }
        }

        if (!big.hasMaxNWholeNumbers(quantity, 12) && value.startsWith('-')) {
          return 'validation.hasMaxNWholeNumbersNegative';
        }

        if (!big.hasMaxNWholeNumbers(quantity, 12)) {
          return 'validation.hasMaxNWholeNumbersPositive';
        }

        if (!big.hasMaxNDecimals(quantity, 4)) {
          return 'validation.hasMaxNDecimals';
        }
      } catch (err) {
        return 'validation.number.error';
      }

      return null;
    }

    case 'description':
      if (value.length > MAX_DESCRIPTION_LENGTH) {
        return 'common.maxCharacters';
      }

      return null;
    case 'unit':
      if (value.length > MAX_UNIT_LENGTH) {
        return 'common.maxCharacters';
      }

      return null;
    case 'status':
      return null;

    case 'rowNumber':
      try {
        // Fields are allowed to be empty
        if (!row && value === '') {
          return null;
        }

        const rowNumber = new Big(value);

        if (!big.hasMaxNWholeNumbers(rowNumber, 9) && value.startsWith('-')) {
          return 'validation.hasMaxNWholeNumbersNegative';
        }

        if (!big.hasMaxNWholeNumbers(rowNumber, 9)) {
          return 'validation.hasMaxNWholeNumbersPositive';
        }

        if (!Number.isInteger(rowNumber.toNumber())) {
          return 'validation.integer.error';
        }
      } catch (err) {
        return 'validation.integer.error';
      }

      return null;

    default:
      return 'validation.unknownField';
  }
};
