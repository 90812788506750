import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../store/reducers';

import * as remoteData from '../utils/remoteData';
import { Thunk } from '../utils/thunk';

/**
 * @param selector Selector to use
 * @param fetchData Thunk to call if data has not been asked
 * @param callCondition To skip calling data fetch, set to false. Can be used to conditionally call hooks. Defaults to true
 */
export default function useRemoteData<A, E>(
  selector: (appState: AppState) => remoteData.RemoteData<A, E>,
  fetchData: Thunk,
  callCondition: boolean = true
) {
  const dispatch = useDispatch();
  const data = useSelector(selector);

  useEffect(() => {
    if (callCondition && data.kind === 'NotAsked') {
      dispatch(fetchData);
    }
  }, [fetchData, data.kind, dispatch, callCondition]);

  return data.kind === 'Success' ? data.value : undefined;
}
