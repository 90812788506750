import React from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import {
  getOrderRowReceivedTotalByTopicId,
  getOrderRowTotalByTopicId,
} from '../../../store/reducers/orderRow';

type ProgressBarProps = {
  topicId: string;
};

// TODO: Doesn't handle negative values

// exported for testing purposes
export const calculateProgressForTopic = (
  receivedTotal: Big,
  total: Big
): string => {
  try {
    if (total.toNumber() === 0) {
      return '0';
    }

    const percentage = receivedTotal.div(total).mul(100).round(0);

    return percentage.gt(100) ? '100' : percentage.toString();
  } catch (e) {
    return '0';
  }
};

const ProgressBackground = styled.div`
  border-radius: ${({ theme }) => theme.margin[16]};
  height: 0.5rem;
  width: 100%;
  background: ${({ theme }) => theme.color.graphiteB76};
`;

type ProgressBarForegroundProps = {
  width: string;
};

const ProgressForeground = styled.span<ProgressBarForegroundProps>`
  border-radius: ${({ theme }) => theme.margin[16]};

  width: ${({ width }) => width}%;
  height: 100%;

  display: block;

  content: '';
  background: ${({ theme }) => theme.color.darkpurple};
`;

export const ProgressBar = ({ topicId }: ProgressBarProps) => {
  const receivedTotal = useSelector(getOrderRowReceivedTotalByTopicId(topicId));
  const total = useSelector(getOrderRowTotalByTopicId(topicId));
  const percentage = calculateProgressForTopic(receivedTotal, total);

  return (
    <ProgressBackground>
      <ProgressForeground width={percentage} title="progress" />
    </ProgressBackground>
  );
};
