import { isEmpty } from 'lodash';
import { Reducer } from 'redux';

import * as api from '../../utils/api';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes as Action } from '../actionTypes';

import { AppState } from '.';

export type Dimension = {
  id: string;
  name: string | null;
  mandatory: boolean;
  validItems?: DimensionValue[];
};

export type DimensionValue = {
  id: string;
  code: string;
  name: string;
};

type Err = api.BackendError | undefined;
type RemoteData<A> = remoteData.RemoteData<A, Err>;

export type ManualEntriesState = {
  dimensions: RemoteData<Dimension[]>;
};

const initialManualEntriesState = {
  dimensions: remoteData.notAsked,
};

const reducer: Reducer<ManualEntriesState, Action> = (
  state: ManualEntriesState = initialManualEntriesState,
  action: Action
): ManualEntriesState => {
  switch (action.type) {
    case 'GET_MANUAL_ENTRIES_DIMENSIONS_STARTED': {
      /* We are not adding the remoteData.loading here, because this action/dispatch
      is run any time the user changes any dimension select value.
      Setting remoteData.loading would remove the value property of remoteData, and we would
      lose all the data that has been fetched so far */
      return { ...state };
    }

    case 'GET_MANUAL_ENTRIES_DIMENSIONS_FAILURE': {
      const { error } = action.payload;

      return { ...state, dimensions: remoteData.fail(error) };
    }

    case 'GET_MANUAL_ENTRIES_DIMENSIONS_SUCCESS': {
      const dimensions = action.payload;

      return { ...state, dimensions: remoteData.succeed(dimensions) };
    }
    case 'GET_MANUAL_ENTRIES_DIMENSIONS_INITIAL_STARTED': {
      return { ...state, dimensions: remoteData.loading };
    }

    case 'GET_MANUAL_ENTRIES_DIMENSIONS_INITIAL_FAILURE': {
      const { error } = action.payload;

      return { ...state, dimensions: remoteData.fail(error) };
    }

    case 'GET_MANUAL_ENTRIES_DIMENSIONS_INITIAL_SUCCESS': {
      const dimensions = action.payload;

      return { ...state, dimensions: remoteData.succeed(dimensions) };
    }
    case 'GET_MANUAL_ENTRIES_VALID_ITEMS_STARTED': {
      return {
        ...state,
      };
    }

    case 'GET_MANUAL_ENTRIES_VALID_ITEMS_FAILURE': {
      return {
        ...state,
      };
    }

    case 'GET_MANUAL_ENTRIES_VALID_ITEMS_SUCCESS': {
      const { dimensionId, validItems } = action.payload;

      const newDimensions = [...remoteData.withDefault(state.dimensions, [])];

      const index = newDimensions.findIndex((dim) => dim.id === dimensionId);
      const dimensionWhoseValuesUpdated = newDimensions[index];
      dimensionWhoseValuesUpdated.validItems = validItems;

      return {
        ...state,
        dimensions: remoteData.succeed(newDimensions),
      };
    }
    default:
      return state;
  }
};

export const getDimensionIds = () => (state: AppState): string[] => {
  const remoteDataDimensions = getDimensions()(state);
  const dimensions = remoteData.withDefault(remoteDataDimensions, []);

  return dimensions.map((dimension) => {
    return dimension.id;
  });
};

export const getDimensions = (): ((
  state: AppState
) => RemoteData<Dimension[]>) => {
  return (state) => state.manualEntries.dimensions;
};

export const getValidItemsForDimension = (dimensionId: string) => (
  state: AppState
): RemoteData<DimensionValue[]> => {
  const dimensions = remoteData.withDefault(getDimensions()(state), []);

  const validItems: DimensionValue[] =
    dimensions.find((dim) => dim.id === dimensionId)?.validItems ?? [];

  if (!isEmpty(validItems)) {
    return remoteData.succeed(validItems);
  }

  // returning notAsked would cause an infinite loop, so returning loading instead
  return remoteData.loading;
};

export default reducer;
