import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes as Action } from '../../actionTypes';

type Err = BackendError | undefined;

type State = Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;

const reducer: Reducer<State, Action> = (state = {}, action) => {
  switch (action.type) {
    case 'MOVE_TARGET_ROWS_TO_TOPIC_STARTED': {
      const { requestId } = action.payload;

      return { ...state, [requestId]: remoteData.loading };
    }
    case 'MOVE_TARGET_ROWS_TO_TOPIC_FAILURE': {
      const { requestId, error } = action.payload;

      return { ...state, [requestId]: remoteData.fail(error) };
    }

    case 'MOVE_TARGET_ROWS_TO_TOPIC_SUCCESS': {
      const { requestId } = action.payload;

      return { ...state, [requestId]: remoteData.succeed(undefined) };
    }
    case 'MOVE_TARGET_ROWS_TO_ORDER_STARTED': {
      const { requestId } = action.payload;

      return { ...state, [requestId]: remoteData.loading };
    }
    case 'MOVE_TARGET_ROWS_TO_ORDER_FAILURE': {
      const { requestId, error } = action.payload;

      return { ...state, [requestId]: remoteData.fail(error) };
    }

    case 'MOVE_TARGET_ROWS_TO_ORDER_SUCCESS': {
      const { requestId } = action.payload;

      return { ...state, [requestId]: remoteData.succeed(undefined) };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  requestId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    target: {
      moveTargetRows: { [requestId]: requestState = remoteData.notAsked },
    },
  }) => requestState.kind;
}

export function isLoading(requestId: string): Selector<boolean> {
  return flow(
    getRequestState(requestId),
    (requestState) => requestState === 'Loading'
  );
}
