import React from 'react';
import { useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { getProjectById } from '../../../store/reducers/project';

import { ScheduledTask } from '../../../store/actions';

import useTxt from '../../../hooks/useTxt';

import { IconTextButton } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import { Table, BoldedPrimaryRow } from '../../../components/Table';

import { IconLinkExternal } from '../../../assets/svg';

import { useParams, routes } from '../../../routes';
import ScheduleTreeRow from './ScheduleTreeRow';

export type ExtendedScheduleTask = ScheduledTask & {
  childNodes: ExtendedScheduleTask[];
};

type ScheduleTreeProps = {
  scheduleTasks: ScheduledTask[];
};

const ScheduleTree = ({ scheduleTasks }: ScheduleTreeProps) => {
  const { projectId } = useParams(routes.ORDER);

  const project = useSelector(getProjectById(projectId));

  const connectedTasksTitle = useTxt(
    'worksection.workpackage.connectedtasks.title'
  );

  const linkToSchedule = useTxt(
    'worksection.workpackage.connectedtasks.linkToSchedule'
  );

  const noConnctedTaskInfo = useTxt(
    'worksection.workpackage.connectedtasks.noConnectedTaskInfo'
  );

  const startDate = useTxt('worksection.workpackage.startDate');
  const endDate = useTxt('worksection.workpackage.endDate');
  const work = useTxt('scheduledTasks.workLoad');
  const tasks = useTxt('common.name');

  const scheduleTasksTree = createTreeFromScheduleTasks(scheduleTasks);

  const renderTree = (node: ExtendedScheduleTask, depth: number) => {
    const children = node?.childNodes.map((child) => (
      <React.Fragment key={child.id}>
        {renderTree(child, depth + 1)}
      </React.Fragment>
    ));

    return (
      <>
        <ScheduleTreeRow
          scheduleTask={node}
          hasChildren={children.length > 0}
        />
        {children}
      </>
    );
  };

  return (
    <ConnectedTasksContainer>
      <TopHeader>
        <StyledTitle>{connectedTasksTitle}</StyledTitle>
        {project?.externalUrl ? (
          <>
            {linkToSchedule}
            <a
              href={project?.externalUrl ?? ''}
              target="_blank"
              rel="noreferrer"
            >
              <IconTextButton icon={IconLinkExternal} />
            </a>
          </>
        ) : null}
      </TopHeader>
      <StyledTable>
        <TableHeader>
          <BoldedPrimaryRow>
            <StyledTh isFirstChild>{tasks}</StyledTh>
            <StyledTh>{work}</StyledTh>
            <StyledTh>{startDate}</StyledTh>
            <StyledTh>{endDate}</StyledTh>
            <StyledTh>%</StyledTh>
          </BoldedPrimaryRow>
        </TableHeader>
        <StyledTbody>
          {scheduleTasks.length ? (
            renderTree(scheduleTasksTree, 0)
          ) : (
            <NoConnectedTaskInfo data-testid="no-connected-tasks">
              <Cell colSpan={3}>{noConnctedTaskInfo}</Cell>
            </NoConnectedTaskInfo>
          )}
        </StyledTbody>
      </StyledTable>
    </ConnectedTasksContainer>
  );
};

const createTreeFromScheduleTasks = (dataset: ScheduledTask[]) => {
  const hashTable = Object.create(null);
  dataset.forEach((data) => {
    hashTable[data.id] = { ...data, childNodes: [] };
  });

  const dataTree: ExtendedScheduleTask[] = [];

  dataset.forEach((d) => {
    if (d.parentId) {
      hashTable[d.parentId].childNodes.push(hashTable[d.id]);
    } else {
      dataTree.push(hashTable[d.id]);
    }
  });

  return dataTree[0];
};

interface TopHeaderProps {
  hasExternalLink?: boolean;
}

const TopHeader = styled.div<TopHeaderProps>`
  position: sticky;
  top: 0;

  display: grid;
  align-items: center;

  background-color: white;

  white-space: nowrap;
  font-size: ${(props) => props.theme.fontSize.base};

  z-index: 1;

  ${(props) =>
    !props.hasExternalLink
      ? css`
          grid-template-columns: 1fr 8rem 1.5rem;
        `
      : '  grid-template-columns: 1fr '}
`;

const ConnectedTasksContainer = styled.div`
  position: relative;

  margin-bottom: ${(props) => props.theme.margin[72]};

  height: 25rem;

  font-size: 0.8rem;

  overflow-y: auto;
`;

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const StyledTbody = styled.tbody`
  margin-top: 5rem;
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 2rem;

  margin-bottom: ${(props) => props.theme.margin[64]};

  overflow-y: auto;

  z-index: 1;
  /* stylelint-disable selector-max-type  -- disable temporary until good solution is found */

  th {
    border-top: 1px solid ${(props) => props.theme.color.inputBorder};
  }
`;

const NoConnectedTaskInfo = styled.tr`
  height: 7rem;
  width: 25rem;
  display: table;
  line-height: 1.25rem;
`;

type RowProps = {
  depth?: number;
  isFirstChild?: boolean;
};

const StyledTh = styled.th<RowProps>`
  border-left: 0.1rem solid rgba(0, 0, 0, 0.01);

  padding: ${(props) => props.theme.margin[8]};

  width: ${(props) => (props.isFirstChild ? '18rem' : 'auto')};

  font-size: 0.9rem;
  font-weight: 600;
  text-align: ${(props) => (props.isFirstChild ? 'left' : 'center')};
`;

const StyledTitle = styled.h3`
  position: sticky;
  top: 0;

  padding: ${(props) => props.theme.margin[10]};

  background-color: white;

  line-height: 1.25rem;

  z-index: 1;
`;

export default ScheduleTree;
