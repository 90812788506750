type Column = {
  relativeWidth: number;
};

type TableColumns = {
  [name: string]: Column;
};

const getTotalWidth = (columns: TableColumns) => {
  return Object.values(columns).reduce(
    (acc, { relativeWidth }) => acc + relativeWidth,
    0
  );
};

const getWidths = <Columns extends TableColumns>(columns: Columns) => <
  ColumnName extends keyof Columns
>(
  name: ColumnName
) => {
  const totalWidth = getTotalWidth(columns);

  const {
    [name]: { relativeWidth },
  } = columns;

  return Math.round(100 * (relativeWidth / totalWidth));
};

export default getWidths;
