import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { getInvoiceAssignedArrivalRowsByInvoiceId } from '../../../../../store/reducers/arrivalRow';
import { useInvoiceHeaderAttachmentUrls } from '../../../../../store/reducers/invoiceAttachments';
import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';
import { selectProjectOrders } from '../../../../../store/reducers/order/order';
import { selectProjectUsers } from '../../../../../store/reducers/projectUsers';
import {
  getActiveProjectId,
  getIsOuterBarOpen,
} from '../../../../../store/reducers/ui';
import { selectCurrentUser } from '../../../../../store/reducers/user';

import {
  cancelInvoiceComplaintHeader,
  convertInvoiceHeader,
  fetchProjectUsers,
} from '../../../../../store/actions';
import { setOuterBarState } from '../../../../../store/actions/ui';

import { APITask } from '../../../../../types/api';

import { useArrivalRowSelection } from '../../../../../hooks/ui';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import useRemoteData from '../../../../../hooks/useRemoteData';
import useScrollTo from '../../../../../hooks/useScrollTo';
import useTxt from '../../../../../hooks/useTxt';

import { IconButton } from '../../../../../components/Buttons';
import { ToolTip as SimpleTooltip } from '../../../../../components/Cell';
import { InvoiceRow } from '../../../../../components/Table';
import ToolTip from '../../../../../components/Tooltip';

import { getBaseApiUrl } from '../../../../../utils/api';
import * as big from '../../../../../utils/big';
import CAN, {
  CaslOrderRequestParams,
} from '../../../../../utils/caslUserPermissions';
import { dateFormat } from '../../../../../utils/format';
import {
  INVOICE_HEADER_STATUS_NOT_HANDLED,
  INVOICE_HEADER_STATUS_ACCEPTED,
  INVOICE_HEADER_STATUS_COMPLAINT_FILED,
  INVOICE_HEADER_STATUS_DECLINED,
  INVOICE_HEADER_STATUS_CORRECTED,
  INVOICE_HEADER_STATUS_BEING_CORRECTED,
  ALL_UNPROCESSED_INVOICE_STATUSES,
} from '../../../../../utils/general';
import { isClickOrKeyboardSelection } from '../../../../../utils/mouseOrKeyInteraction';
import * as remoteData from '../../../../../utils/remoteData';
import { getInvoiceHeaderStatus } from './utils';

import {
  IconDown,
  IconRight,
  IconKebabMenu,
  IconAttachmentBlack,
  IconPaperAdd,
  IconPaperEdit,
  IconPapertrace,
  IconDecline,
  IconDelegate,
  IconChange,
  IconSplitScreen,
  IconPaperFix,
  IconNewTab,
} from '../../../../../assets/svg';

import { NoOverflowSpan } from '..';
import ArrivalRow, { StyledArrivalRow } from './ArrivalRow';
import {
  AttachmentCount,
  IndicatorImg,
  DropDownList,
  DropDownItem,
  TableBodyGap,
  InvoiceTableBody,
  ProcessingCell,
} from './Components';
import DeclineInvoiceModal from './DeclineInvoiceModal';
import { FileInvoiceComplaintModal } from './FileInvoiceComplaintModal';
import InvoiceDelegationRow from './InvoiceDelegationRow';
import InvoiceModal from './InvoiceModals';
import {
  CompactCell,
  LeftPaddedCompactCell,
  UnitPriceCompactCell,
  RightPaddedCompactCell,
  SelectableInvoiceCell,
} from './LaariCells';
import ManualEntryModal from './ManualEntryModal/ManualEntryModal';
import MoveCostModal from './MoveCostModal/MoveCostModal';
import ProcessingTool from './ProcessingTool';
import StartInvoiceCorrectionModal from './StartInvoiceCorrectionModal';

type Props = {
  invoiceHeader: InvoiceHeader;
  isSelected: boolean;
  isHandled?: boolean;
  onSelect?: () => void;
  isAllRendered: boolean;
  apiTasks?: APITask[];
  isOpen?: boolean;
  toggleExpandInvoice?: (id: string) => void;
};

const Invoice = ({
  invoiceHeader,
  isSelected,
  isHandled,
  onSelect,
  isAllRendered,
  apiTasks,
  isOpen,
  toggleExpandInvoice,
}: Props) => {
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const apiBaseUrl = getBaseApiUrl();

  const { hash } = history.location;
  const projectId = useSelector(getActiveProjectId) ?? '';

  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const orders = remoteData.withDefault(
    useSelector(selectProjectOrders(projectId)),
    {}
  );

  const projectUsers =
    useRemoteData(
      selectProjectUsers(invoiceHeader.projectId ?? ''),
      fetchProjectUsers(invoiceHeader.projectId ?? '')
    ) ?? [];

  const sortedArrivalRows = useSelector(
    getInvoiceAssignedArrivalRowsByInvoiceId({
      invoiceId: invoiceHeader.id,
      orderId: invoiceHeader.orderId ?? '',
    })
  );

  const dispatch = useDispatch();

  const [dropDownVisible, setDropDownVisibility] = useState(false);
  const [currentModal, setCurrentModal] = useState<string | undefined>();
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showManualEntryModal, setShowManualEntryModal] = useState(false);
  const [showFileComplaintModal, setShowFileComplaintModal] = useState(false);

  const [showInvoiceCorrectionModal, setInvoiceCorrectionModal] = useState(
    false
  );
  const attachmentUrls = useInvoiceHeaderAttachmentUrls(invoiceHeader.id);

  const [
    scrollToRef,
    setShouldScrollTo,
    hasScrolled,
  ] = useScrollTo<HTMLTableRowElement>();

  const ability = new CaslOrderRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  useEffect(() => {
    if (!isAllRendered) {
      return;
    }

    if (hash !== `#invoiceHeaderId-${invoiceHeader.id}`) {
      return;
    }

    // run only once when scrolling, outerBarOpen state change caused unintentional dispatch
    if (outerBarOpen && !hasScrolled) {
      dispatch(
        setOuterBarState({
          type: 'invoice_header',
          contentId: invoiceHeader.id,
        })
      );
    }

    setShouldScrollTo(true);
  }, [
    setShouldScrollTo,
    dispatch,
    hash,
    invoiceHeader.id,
    outerBarOpen,
    isAllRendered,
    hasScrolled,
  ]);

  const [
    selectedArrivalRows,
    setSelectedArrivalRows,
  ] = useArrivalRowSelection();

  const ref = useOnClickOutside(() => {
    if (dropDownVisible) {
      setDropDownVisibility(false);
    }
  });

  const toggleIsOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (toggleExpandInvoice) {
      toggleExpandInvoice(invoiceHeader.id);
    }
  };

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const onSelectRow = (rowId: string) => (selected: boolean) => {
    setSelectedArrivalRows([rowId], selected);
  };

  const assignedArrivalRowsTotal = big.sum(
    ...sortedArrivalRows.map((row) => row.quantity.mul(row.unitPrice))
  );

  const toggleDropDown = (e: React.SyntheticEvent) => {
    setDropDownVisibility(!dropDownVisible);

    if (isOpen) {
      stopPropagation(e);
    }

    if (onSelect) {
      onSelect();
    }
  };

  const showOuterBar = (e: React.SyntheticEvent) => {
    if (isOpen) {
      stopPropagation(e);
    }

    dispatch(
      setOuterBarState({
        type: 'invoice_header',
        contentId: invoiceHeader.id,
      })
    );

    if (onSelect) {
      onSelect();
    }
  };

  const onChangeConvertInvoice = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (isMovable) {
        toggleDropDown(e);
        dispatch(convertInvoiceHeader(invoiceHeader.id));

        if (onSelect) {
          onSelect();
        }
      }
    }
  };

  const onDelegateInvoice = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (INVOICE_HEADER_STATUS_NOT_HANDLED.includes(invoiceHeader.statusId)) {
        toggleDropDown(e);
        setCurrentModal('DELEGATE_INVOICE_MODAL');
      }
    }
  };

  const onUploadAttachment = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      toggleDropDown(e);
      setCurrentModal('UPLOAD_INVOICE_ATTACHMENT_MODAL');
    }
  };

  const onOpenInNewTab = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      toggleDropDown(e);

      const imageUrl = `${apiBaseUrl}v1/attachments/purchase-invoice-headers/${invoiceHeader.id}`;
      window.open(imageUrl, '_blank');
    }
  };

  const onChangeAddManualEntry = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (INVOICE_HEADER_STATUS_NOT_HANDLED.includes(invoiceHeader.statusId)) {
        toggleDropDown(e);

        setShowManualEntryModal(true);
      }
    }
  };

  const onChangeMoveInvoice = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (isMovable) {
        toggleDropDown(e);
        setShowMoveModal(true);
      }
    }
  };

  const onChangeDeclineInvoice = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (unhandledOrComplaintPendingWithoutArrivalRows) {
        toggleDropDown(e);
        setShowDeclineModal(true);
      }
    }
  };

  const onStartInvoiceCorrection = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (
        INVOICE_HEADER_STATUS_CORRECTED.concat(
          INVOICE_HEADER_STATUS_ACCEPTED
        ).includes(invoiceHeader.statusId)
      ) {
        toggleDropDown(e);
        setInvoiceCorrectionModal(true);
      }
    }
  };

  const onChangeFileInvoiceComplaint = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (INVOICE_HEADER_STATUS_NOT_HANDLED.includes(invoiceHeader.statusId)) {
        toggleDropDown(e);
        setShowFileComplaintModal(true);
      }
    }
  };

  const onChangeCancelInvoiceComplaint = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (
        INVOICE_HEADER_STATUS_COMPLAINT_FILED.includes(invoiceHeader.statusId)
      ) {
        dispatch(cancelInvoiceComplaintHeader(invoiceHeader.id));
        toggleDropDown(e);
      }
    }
  };

  const status = getInvoiceHeaderStatus(invoiceHeader);
  const rowHighlighted = hash === `#invoiceHeaderId-${invoiceHeader.id}`;

  const hasSelectableArrivalRows = ALL_UNPROCESSED_INVOICE_STATUSES.includes(
    invoiceHeader.statusId
  );

  const openLinks = (e: React.SyntheticEvent) => {
    stopPropagation(e);

    attachmentUrls.forEach((url) => window.open(url, '_blank'));
  };

  const showArrivalRow = isOpen && sortedArrivalRows.length > 0;
  const showEmptyArrivalRow = isOpen && sortedArrivalRows.length <= 0;

  const addText = useTxt('order.receiveMode.invoice.add');
  const moveText = useTxt('order.receiveMode.cost.move');
  const delegateTxt = useTxt('order.receiveMode.invoice.delegate');
  const declineText = useTxt('order.receiveMode.invoice.decline');
  const fileReclaimText = useTxt('order.receiveMode.invoice.fileComplaint');
  const cancelReclaimText = useTxt('order.receiveMode.invoice.cancelComplaint');

  const startCorrectionText = useTxt(
    'order.receiveMode.invoice.startCorrection'
  );
  const manualEntryText = useTxt('order.receiveMode.invoice.manualEntry');
  const openText = useTxt('order.receiveMode.invoice.isOpen');
  const closedText = useTxt('order.receiveMode.invoice.isClosed');
  const amountInclVATText = useTxt('order.receiveMode.invoice.amountInclVAT');
  const noRowsAssignedText = useTxt('order.receiveMode.invoice.noArrivalRows');

  const uploadAttachmentTxt = useTxt(
    'order.receiveMode.invoice.attachment.upload'
  );

  const openImageInNewTabTxt = useTxt(
    'order.receiveMode.invoice.image.inNewTab'
  );

  const statusText = useTxt(status.text, {
    identifier: status.identifier,
    handleVatAmountPending: status.handleVatAmountPending
      ? big.priceFormat(status.handleVatAmountPending)
      : '0',
    handleNetAmountPending: status.handleNetAmountPending
      ? big.priceFormat(status.handleNetAmountPending)
      : '0',
  });

  const showStatusComment =
    INVOICE_HEADER_STATUS_COMPLAINT_FILED.includes(invoiceHeader.statusId) ||
    INVOICE_HEADER_STATUS_DECLINED.includes(invoiceHeader.statusId) ||
    INVOICE_HEADER_STATUS_CORRECTED.includes(invoiceHeader.statusId) ||
    INVOICE_HEADER_STATUS_BEING_CORRECTED.includes(invoiceHeader.statusId);

  const shortStatusText = useTxt(status.shortText, {
    dateAndUser: status.dateAndUser ?? '',
  });

  const getClaimDropDownListItem = ({ statusId }: InvoiceHeader) =>
    INVOICE_HEADER_STATUS_COMPLAINT_FILED.includes(statusId) ? (
      <DropDownItem
        tabIndex={0}
        role="menuitem"
        onClick={onChangeCancelInvoiceComplaint}
        onKeyDown={onChangeCancelInvoiceComplaint}
      >
        <IconPapertrace />
        {cancelReclaimText}
      </DropDownItem>
    ) : (
      <DropDownItem
        disabled={!INVOICE_HEADER_STATUS_NOT_HANDLED.includes(statusId)}
        tabIndex={0}
        role="menuitem"
        onClick={onChangeFileInvoiceComplaint}
        onKeyDown={onChangeFileInvoiceComplaint}
      >
        <IconPapertrace />
        {fileReclaimText}
      </DropDownItem>
    );

  const unhandledOrComplaintPendingWithoutArrivalRows =
    sortedArrivalRows.length === 0 &&
    INVOICE_HEADER_STATUS_COMPLAINT_FILED.concat(
      INVOICE_HEADER_STATUS_NOT_HANDLED
    ).includes(invoiceHeader.statusId);

  const { isMovable } = invoiceHeader;

  return (
    <InvoiceTableBody isActive={isSelected}>
      <InvoiceModal
        projectUsers={projectUsers?.filter(
          (user) => user.id !== currentUser?.id
        )} // modal should not show current user name
        modalType={currentModal}
        handleClose={() => setCurrentModal(undefined)}
        invoiceHeader={invoiceHeader}
        tasks={apiTasks}
      />

      {showMoveModal ? (
        <MoveCostModal
          orders={orders}
          onClose={() => setShowMoveModal(false)}
          cost={invoiceHeader}
        />
      ) : null}
      {showDeclineModal ? (
        <DeclineInvoiceModal
          invoiceHeader={invoiceHeader}
          onClose={() => setShowDeclineModal(false)}
        />
      ) : null}
      {showFileComplaintModal ? (
        <FileInvoiceComplaintModal
          invoiceHeader={invoiceHeader}
          onClose={() => setShowFileComplaintModal(false)}
        />
      ) : null}
      {showManualEntryModal ? (
        <ManualEntryModal
          invoiceHeader={invoiceHeader}
          onClose={() => setShowManualEntryModal(false)}
          arrivalRowsForInvoice={sortedArrivalRows}
        />
      ) : null}
      {showInvoiceCorrectionModal ? (
        <StartInvoiceCorrectionModal
          invoiceHeader={invoiceHeader}
          onClose={() => setInvoiceCorrectionModal(false)}
        />
      ) : null}
      <InvoiceRow
        selected={isSelected}
        isHandled={isHandled}
        rowHighlighted={rowHighlighted}
        ref={scrollToRef}
        clickable
        data-testid={`invoice-header-selector-${invoiceHeader.id}`}
        onClick={(e) => {
          e.stopPropagation();
          toggleIsOpen(e);

          if (onSelect) {
            onSelect();
          }
        }}
      >
        <SelectableInvoiceCell selected={isSelected}>
          <IconButton
            icon={isOpen ? IconDown : IconRight}
            aria-label={isOpen ? openText : closedText}
          />
        </SelectableInvoiceCell>
        <CompactCell colSpan={2}>
          {invoiceHeader.vendorInvoiceNo}
          <SimpleTextToolTip>{invoiceHeader.invoiceNumber}</SimpleTextToolTip>
        </CompactCell>
        <CompactCell>
          {outerBarOpen ? null : (
            <ToolTip
              tip={invoiceHeader.comment ?? ''}
              delayHide={200}
              disable={!showStatusComment}
              className="hoverable-tooltip"
              tipId={`invoiceHeader-comment-tooltip-${invoiceHeader.id}`}
              noWrap
            >
              <IndicatorImg alt={statusText} src={status.icon} />
              {shortStatusText}
            </ToolTip>
          )}
          {apiTasks
            ?.map((t) => t.purchaseInvoiceHeaderId)
            .includes(invoiceHeader.id) ? (
            <IconContainer>
              <IconDelegate />
            </IconContainer>
          ) : null}
        </CompactCell>
        <CompactCell>
          <NoOverflowSpan>{invoiceHeader.supplierName}</NoOverflowSpan>
        </CompactCell>
        <CompactCell align="right">
          <InvoiceSumWrapper>
            {big.priceFormat(invoiceHeader.amount)}
          </InvoiceSumWrapper>
          <SimpleTextToolTip>
            {amountInclVATText}{' '}
            {big.priceFormat(invoiceHeader.amountIncludingVat)}
          </SimpleTextToolTip>
        </CompactCell>
        <CompactCell colSpan={outerBarOpen ? 1 : 5}>
          {dateFormat.format(new Date(invoiceHeader.dueDate))}
        </CompactCell>
        {!outerBarOpen ? (
          <UnitPriceCompactCell align="right">
            {big.priceFormat(assignedArrivalRowsTotal)}
          </UnitPriceCompactCell>
        ) : (
          <CompactCell align="right">
            {big.priceFormat(assignedArrivalRowsTotal)}
          </CompactCell>
        )}
        <CompactCell colSpan={outerBarOpen ? 2 : 3} />
        <RightPaddedCompactCell>
          <StyledIconButton
            icon={IconKebabMenu}
            onClick={toggleDropDown}
            disabled={!allowedUser}
            data-testid={`kebab-menu-${invoiceHeader.id}`}
          />
          <StyledIconButton
            icon={IconSplitScreen}
            onClick={showOuterBar}
            data-testid={`split-screen-${invoiceHeader.id}`}
          />
          {dropDownVisible ? (
            <DropDownList role="menu" ref={ref}>
              {!isHandled ? (
                <DropDownItem
                  disabled={
                    !INVOICE_HEADER_STATUS_NOT_HANDLED.includes(
                      invoiceHeader.statusId
                    )
                  }
                  tabIndex={0}
                  role="menuitem"
                  onClick={onDelegateInvoice}
                  onKeyDown={onDelegateInvoice}
                >
                  <IconDelegate />
                  {delegateTxt}
                </DropDownItem>
              ) : null}
              <DropDownItem
                disabled={!isMovable}
                tabIndex={0}
                role="menuitem"
                onClick={onChangeConvertInvoice}
                onKeyDown={onChangeConvertInvoice}
              >
                <IconPaperAdd />
                {addText}
              </DropDownItem>
              <DropDownItem
                disabled={!isMovable}
                tabIndex={0}
                role="menuitem"
                onClick={onChangeMoveInvoice}
                onKeyDown={onChangeMoveInvoice}
              >
                <IconChange />
                {moveText}
              </DropDownItem>
              <DropDownItem
                disabled={
                  !INVOICE_HEADER_STATUS_NOT_HANDLED.includes(
                    invoiceHeader.statusId
                  )
                }
                tabIndex={0}
                role="menuitem"
                onClick={onChangeAddManualEntry}
                onKeyDown={onChangeAddManualEntry}
              >
                <IconPaperEdit />

                {manualEntryText}
              </DropDownItem>
              <DropDownItem
                disabled={!unhandledOrComplaintPendingWithoutArrivalRows}
                tabIndex={0}
                role="menuitem"
                onClick={onChangeDeclineInvoice}
                onKeyDown={onChangeDeclineInvoice}
              >
                <IconDecline />
                {declineText}
              </DropDownItem>
              {getClaimDropDownListItem(invoiceHeader)}
              <DropDownItem
                tabIndex={0}
                role="menuitem"
                onClick={onUploadAttachment}
                onKeyDown={onUploadAttachment}
              >
                <IconAttachmentBlack />
                {uploadAttachmentTxt}
              </DropDownItem>
              <DropDownItem
                tabIndex={0}
                role="menuitem"
                onClick={onOpenInNewTab}
                onKeyDown={onOpenInNewTab}
              >
                <IconNewTab />
                {openImageInNewTabTxt}
              </DropDownItem>
              {isHandled &&
              INVOICE_HEADER_STATUS_CORRECTED.concat(
                INVOICE_HEADER_STATUS_ACCEPTED
              ).includes(invoiceHeader.statusId) ? (
                <DropDownItem
                  tabIndex={0}
                  role="menuitem"
                  onClick={onStartInvoiceCorrection}
                  onKeyDown={onStartInvoiceCorrection}
                >
                  <IconPaperFix />
                  {startCorrectionText}
                </DropDownItem>
              ) : null}
            </DropDownList>
          ) : null}
          &nbsp;
          {attachmentUrls.length > 0 ? (
            <StyledIconButton icon={IconAttachmentBlack} onClick={openLinks}>
              {attachmentUrls.length > 1 ? (
                <AttachmentCount>{attachmentUrls.length}</AttachmentCount>
              ) : null}
            </StyledIconButton>
          ) : null}
        </RightPaddedCompactCell>
      </InvoiceRow>
      {showArrivalRow
        ? sortedArrivalRows.map((row) => (
            <ArrivalRow
              projectId={projectId}
              key={`invoice-${invoiceHeader.id}-arrival-row-${row.id}`}
              arrivalRowId={row.id}
              selected={
                hasSelectableArrivalRows
                  ? selectedArrivalRows[row.id]
                  : undefined
              }
              onSelect={
                hasSelectableArrivalRows ? onSelectRow(row.id) : undefined
              }
              isHandled={
                INVOICE_HEADER_STATUS_ACCEPTED.includes(
                  invoiceHeader.statusId
                ) ||
                INVOICE_HEADER_STATUS_CORRECTED.includes(invoiceHeader.statusId)
              }
            />
          ))
        : null}
      {showEmptyArrivalRow ? (
        <StyledArrivalRow>
          <LeftPaddedCompactCell colSpan={outerBarOpen ? 9 : 13}>
            {noRowsAssignedText}
          </LeftPaddedCompactCell>
        </StyledArrivalRow>
      ) : null}
      {isOpen && apiTasks?.length ? (
        <InvoiceDelegationRow
          tasks={apiTasks.filter(
            (t) => t.purchaseInvoiceHeaderId === invoiceHeader.id
          )}
          projectUsers={projectUsers}
          currentUserId={currentUser?.id ?? ''}
          onShowOuterBar={showOuterBar}
          outerBarOpen={outerBarOpen}
        />
      ) : null}

      {isOpen && !isHandled ? (
        <tr>
          <ProcessingCell
            colSpan={outerBarOpen ? 11 : 16}
            isActive={isSelected}
          >
            <ProcessingTool
              invoice={invoiceHeader}
              arrivalRows={sortedArrivalRows}
            />
          </ProcessingCell>
        </tr>
      ) : null}
      {/* gives gap between two invoice rows */}
      <TableBodyGap isActive={isSelected}>
        <td colSpan={outerBarOpen ? 11 : 16} />
      </TableBodyGap>
    </InvoiceTableBody>
  );
};

const InvoiceSumWrapper = styled.span`
  padding-right: 1rem;
  cursor: text;
  overflow: hidden;
`;

const IconContainer = styled.span`
  padding-left: 0.25rem;
`;

const SimpleTextToolTip = styled(SimpleTooltip)`
  cursor: text;
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  margin-right: ${(props) => props.theme.margin[8]};
`;

export default Invoice;
