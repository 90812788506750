import React from 'react';

import styled, { css } from 'styled-components';

import Txt from '../../../../../components/Txt';

import getWidths from '../../../../../utils/headerWidth';

export const TableHeader = () => (
  <thead>
    <tr>
      <TxtTh name="description" fixedWidth="2rem" />
      <TxtTh name="description2" />
      <TxtTh name="quantity" textAlign="right" />
      <TxtTh name="unit" />
      <TxtTh name="unitPrice" textAlign="right" />
      <TxtTh name="selectOrder" />
      <TxtTh name="selectTopic" />
      <TxtTh name="selectAnalysis" />
      <TxtTh name="total" />
      <TxtTh name="none" />
    </tr>
  </thead>
);

const tableColumns = {
  description: { relativeWidth: 0 },
  description2: { relativeWidth: 3 },
  quantity: { relativeWidth: 1.5 },
  unit: { relativeWidth: 1 },
  unitPrice: { relativeWidth: 1.5 },
  selectOrder: { relativeWidth: 3 },
  selectTopic: { relativeWidth: 3 },
  selectAnalysis: { relativeWidth: 3 },
  total: { relativeWidth: 1.5 },
  none: { relativeWidth: 1 },
};

type ThProps = {
  name: keyof typeof tableColumns;
  fixedWidth?: string;
  textAlign?: 'left' | 'center' | 'right';
};

const Th = styled.th<ThProps>`
  padding: ${(props) => props.theme.margin[8]};
  width: ${({ name, fixedWidth }) =>
    fixedWidth || `${getColumnPercent(name)}%`};
  ${(props) =>
    props.fixedWidth &&
    css`
      box-sizing: content-box;
    `}
  align-items: center;
  text-align: ${(props) => props.textAlign ?? 'left'};
`;

const TxtTh = ({ name, fixedWidth, textAlign }: ThProps) => (
  <Th name={name} fixedWidth={fixedWidth} textAlign={textAlign}>
    <Txt id={`${textIdHead}${name}` as const} component="b" />
  </Th>
);

const textIdHead = 'order.targetSplitModal.tableHeader.';

const getColumnPercent = getWidths(tableColumns);
