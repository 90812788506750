import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/schedule/workPackageTimeline';

const actionCreators = {
  ...makeAction('getWorkPackageTimelinesStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getWorkPackageTimelinesSuccess')<{
    projectId: string;
    workPackageTimelineEntries: WorkPackageTimelineEntry[];
  }>(),
  ...makeAction('getWorkPackageTimelinesFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
};

export type WorkPackageTimelineAction = ExtractActionTypes<
  typeof actionCreators
>;

const apiWorkPackageTimelineEntryType = t.exact(
  t.type({
    workPackageId: t.string,
    projectId: t.string,
    date: dateString,
    percentageOfCompletion: bigString,
    snapshotTypeId: t.string,
    snapshotId: t.union([t.string, t.null]),
    currentPeriod: t.boolean,
    latestSnapshot: t.boolean,
    pastPeriod: t.boolean,
    hide: t.boolean,
  })
);

export type WorkPackageTimelineEntry = t.TypeOf<
  typeof apiWorkPackageTimelineEntryType
>;

const {
  getWorkPackageTimelinesStarted,
  getWorkPackageTimelinesSuccess,
  getWorkPackageTimelinesFailure,
} = actionCreators;

export async function toWorkPackageTimelineEntries(
  u: unknown
): Promise<WorkPackageTimelineEntry[]> {
  const targetRows = await tPromise.decode(
    t.array(apiWorkPackageTimelineEntryType),
    u
  );

  return targetRows;
}

const requestWorkPackageTimelineForProject = async ({
  projectId,
}: {
  projectId: string;
}): Promise<WorkPackageTimelineEntry[]> => {
  const response = await GET<WorkPackageTimelineEntry[]>(
    `v1/projects/${projectId}/work-package-timelines`
  );

  return toWorkPackageTimelineEntries(response);
};

export const getWorkPackageTimelineForProject = ({
  projectId,
}: {
  projectId: string;
}) =>
  createAsyncThunk(requestWorkPackageTimelineForProject, {
    args: [{ projectId }],
    initialAction: getWorkPackageTimelinesStarted({ projectId }),
    isPending: isLoading(projectId),
    failureActionCreator: (error) =>
      getWorkPackageTimelinesFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (workPackageTimelineEntries) =>
      getWorkPackageTimelinesSuccess({
        projectId,
        workPackageTimelineEntries,
      }),
  });
