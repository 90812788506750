import { APINotificationFeedItem } from '../../types/api';
import { ID, NotificationFeedItem } from '../../types/general';

import {
  makeApiActions,
  ExtractActionTypes,
  makeAction,
} from '../../utils/actionCreators';
import { GET, apiErrorHandlingWithDecode, PUT } from '../../utils/api';
import { Thunk } from '../../utils/thunk';

export type NotificationAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeApiActions('get', 'notifications')<APINotificationFeedItem[]>(),
  ...makeApiActions('put', 'markNotificationRead')<APINotificationFeedItem>(),
  ...makeAction('closeNotification')<string>(),
};
export const {
  closeNotification,
  getNotificationsFailure,
  getNotificationsSuccess,
  getNotificationsStarted,
  putMarkNotificationReadFailure,
  putMarkNotificationReadSuccess,
  putMarkNotificationReadStarted,
} = actionCreators;

async function getNotifications(): Promise<APINotificationFeedItem[]> {
  return GET<APINotificationFeedItem[]>(`v1/notifications`);
}

export const fetchNotifications = (): Thunk => (dispatch) => {
  dispatch(getNotificationsStarted());
  getNotifications().then(
    (response) => {
      dispatch(getNotificationsSuccess(response));
    },
    (error) => {
      dispatch(getNotificationsFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};

const markRead = (id: ID, dismissedAt?: Date) =>
  PUT<APINotificationFeedItem>(
    `v1/notifications/${id}/mark-read${
      dismissedAt ? `?at=${dismissedAt.toISOString()}` : ''
    }`,
    {}
  );

export const markNotificationRead = (
  notification: NotificationFeedItem
): Thunk => (dispatch) => {
  dispatch(putMarkNotificationReadStarted());
  markRead(
    notification.id,
    notification.notificationType === '8' ? notification.createdAt : undefined
  ).then(
    (response) => {
      dispatch(putMarkNotificationReadSuccess(response));
    },
    (error) => {
      dispatch(
        putMarkNotificationReadFailure(apiErrorHandlingWithDecode(error))
      );
    }
  );
};
