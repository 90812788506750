import React from 'react';

import useTxt from '../hooks/useTxt';

import { IconDown, IconRight } from '../assets';

import { TextId } from '../localization';

export type ArrowIconProps = {
  isOpen: boolean;
  openAltTextKey: TextId;
  closedAltTextKey: TextId;
};

const ArrowIcon = ({
  isOpen,
  openAltTextKey,
  closedAltTextKey,
}: ArrowIconProps) => {
  const openAltText = useTxt(openAltTextKey);
  const closedAltText = useTxt(closedAltTextKey);

  const imgSrc = isOpen ? IconDown : IconRight;
  const imgAlt = isOpen ? openAltText : closedAltText;

  return <img alt={imgAlt} src={imgSrc} />;
};

export default ArrowIcon;
