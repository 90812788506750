import React from 'react';
import { useSelector } from 'react-redux';

import { orderBy } from 'lodash';
import styled from 'styled-components';

import { getTopic } from '../../../../store/reducers/topic';
import { selectProjectWorkPackages } from '../../../../store/reducers/workPackage';

import { APITopicPutBody, APIWorkPackage } from '../../../../types/api';

import useTxt from '../../../../hooks/useTxt';

import Cell, { CellProps } from '../../../../components/Cell';
import DropDownSelect from '../../../../components/DropDownSelect';

import { isDefined } from '../../../../utils/general';
import * as remoteData from '../../../../utils/remoteData';

type WorkPackageSelectionCellProps = {
  topicId: string;
  projectId: string;
  setTopic: <K extends keyof APITopicPutBody>(
    key: K
  ) => (value: string | null) => void;
};

const WorkPackageSelection = ({
  topicId,
  projectId,
  setTopic,
  ...cellProps
}: WorkPackageSelectionCellProps & CellProps) => {
  const topic = useSelector(getTopic(topicId));

  const projectWorkPackages = remoteData.withDefault(
    useSelector(selectProjectWorkPackages(projectId)),
    {}
  );

  const filteredWorkPackages: APIWorkPackage[] = Object.values(
    projectWorkPackages
  ).filter(isDefined);

  const sortedWorkPackages = orderBy(filteredWorkPackages, ['code'], ['asc']);

  const noSelectionText = useTxt('common.noSelection');

  const defaultOption = {
    value: 'NULL',
    label: noSelectionText,
  };

  const options = [
    defaultOption,
    ...sortedWorkPackages.map((workPackage) => ({
      value: workPackage.id,
      label: `${workPackage.code} | ${workPackage.name}`,
    })),
  ];

  const labelText = useTxt('common.workPackage');
  const notFoundText = useTxt('common.workPackage.notFound');

  const onWorkPackageChange = () => (selection: string) => {
    const workPackageId = selection === defaultOption.value ? null : selection;
    setTopic('workPackageId')(workPackageId);
  };

  if (!topic) {
    return null;
  }

  const defaultValue = topic.workPackageId ?? defaultOption.value;

  return (
    <Cell {...cellProps}>
      <FullSizeDropDownSelect
        label={labelText}
        notFound={notFoundText}
        options={options}
        defaultValue={defaultValue}
        onChange={onWorkPackageChange()}
        id={`work-package-select-for-topic-${topicId}`}
      />
    </Cell>
  );
};

const FullSizeDropDownSelect = styled(DropDownSelect)`
  flex: 1;
`;

export default WorkPackageSelection;
