import { APIPurchaseInvoiceAttachment } from '../../types/api';
import { ID } from '../../types/general';

import { ExtractActionTypes, makeApiActions } from '../../utils/actionCreators';
import { GET, apiErrorHandlingWithDecode } from '../../utils/api';
import { Thunk } from '../../utils/thunk';

export type InvoiceAttachmentsAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = makeApiActions('get', 'invoiceAttachments')<
  APIPurchaseInvoiceAttachment[]
>();
export const {
  getInvoiceAttachmentsStarted,
  getInvoiceAttachmentsSuccess,
  getInvoiceAttachmentsFailure,
} = actionCreators;

const fetchInvoiceAttachmentsForOrder = (orderId: ID) => {
  return GET<APIPurchaseInvoiceAttachment[]>(
    `v1/orders/${orderId}/purchase-invoice-attachments`
  );
};

export const requestInvoiceAttachmentsForOrder = (orderId: ID): Thunk => (
  dispatch
) => {
  dispatch(getInvoiceAttachmentsStarted());
  fetchInvoiceAttachmentsForOrder(orderId).then(
    (response) => {
      dispatch(getInvoiceAttachmentsSuccess(response));
    },
    (error) => {
      dispatch(getInvoiceAttachmentsFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};
