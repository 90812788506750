import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getProjectById } from '../../store/reducers/project';

import { ViewContainer } from '../../components/Containers';
import Header from '../../components/Header';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';
import ProcurementAreasTable from './components/ProcurementAreasTable';

type RouteParams = {
  projectId: string;
};

const ProjectView = () => {
  const { projectId } = useParams<RouteParams>();

  const project = useSelector(getProjectById(projectId));

  if (!project) {
    return null;
  }

  return (
    <ViewContainer>
      <Header>
        <Txt id="procurement.header" />
      </Header>
      <ProjectInfo projectId={projectId} />
      <ProcurementAreasTable projectId={projectId} />
    </ViewContainer>
  );
};

export default ProjectView;
