import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeApiActions, ExtractActionTypes } from '../../utils/actionCreators';
import { GET } from '../../utils/api';
import { dateString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk } from '../../utils/thunk';

import {
  getCompanyReportingPeriods,
  CompanyReportingPeriod,
} from '../reducers/companyReportingPeriod';

export type CompanyReportingPeriodAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = makeApiActions('get', 'companyReportingPeriods')<
  CompanyReportingPeriod[]
>();

export const {
  getCompanyReportingPeriodsStarted,
  getCompanyReportingPeriodsFailure,
  getCompanyReportingPeriodsSuccess,
} = actionCreators;

const apiCompanyReportingPeriodType = t.exact(
  t.type({
    id: t.string,
    externalReportingPeriodCode: t.string,
    description: t.string,
    validFrom: dateString,
    validTo: dateString,
    isDeleted: t.boolean,
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export async function toCompanyReportingPeriods(
  u: unknown
): Promise<CompanyReportingPeriod[]> {
  const apiCompanyReportingPeriods = await tPromise.decode(
    t.array(apiCompanyReportingPeriodType),
    u
  );

  return apiCompanyReportingPeriods;
}

async function fetchCompanyReportingPeriods(): Promise<
  CompanyReportingPeriod[]
> {
  const response = await GET('v1/company-reporting-periods');

  return toCompanyReportingPeriods(response);
}

export const requestCompanyReportingPeriods = createAsyncThunk(
  fetchCompanyReportingPeriods,
  {
    args: [],
    isPending: flow(getCompanyReportingPeriods, remoteData.isLoading),
    initialAction: getCompanyReportingPeriodsStarted(),
    successActionCreator: getCompanyReportingPeriodsSuccess,
    failureActionCreator: () => getCompanyReportingPeriodsFailure(),
  }
);
