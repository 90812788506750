import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useFormik, FormikErrors } from 'formik';
import { sortBy } from 'lodash';
import styled from 'styled-components';

import {
  getSuppliers,
  getVatCodes,
  getCostTypes,
  getAccounts,
} from '../../../../store/reducers/order/options';
import {
  getOrdersByProcurementAreaId,
  getProjectOrders,
} from '../../../../store/reducers/order/order';
import { getProcurementReferenceNumber } from '../../../../store/reducers/order/procurementReferenceNumber';
import { getProcumentAreaById } from '../../../../store/reducers/procurementArea';

import {
  getDropDowns,
  Supplier,
} from '../../../../store/actions/order/options';
import {
  createOrder,
  fetchOrdersForProject,
} from '../../../../store/actions/order/order';
import {
  clearProcurementReferenceNumber,
  fetchProcurementReferenceNumberForProject,
} from '../../../../store/actions/order/procurementReferenceNumber';

import { APIOrderPostBody, APIUpdatedEntities } from '../../../../types/api';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../components/Buttons';
import { InputContainer } from '../../../../components/Containers';
import DropDownSelect from '../../../../components/DropDownSelect';
import { SelectLabel, Select } from '../../../../components/Input/Select';
import TextInput from '../../../../components/Input/TextInput';
import { Spinner } from '../../../../components/Loading';
import RemoteData from '../../../../components/RemoteData';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Tooltip from '../../../../components/Tooltip';
import Txt from '../../../../components/Txt';

import { uniqueCode } from '../../../../utils/decoders';
import { nullifyIfEmpty } from '../../../../utils/format';
import { onlyLettersAndNumbersAndDashes } from '../../../../utils/general';
import {
  FormValues,
  isNameErrorTextId,
  isVisibleCodeErrorTextId,
} from './utils';

import { IconInfo } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';
import theme from '../../../../styles/theme';

type RouteParams = {
  projectId: string;
};

type Props = {
  procurementAreaId: string;
  onClose: () => void;
};

const SidebarContent = styled.div`
  padding: ${(props) =>
    `0 ${props.theme.margin[32]} ${props.theme.margin[24]} ${props.theme.margin[32]}`};
  background: ${(props) => props.theme.color.white};
`;

const TooltipInfoContent = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSpan = styled.span`
  font-weight: bold;
`;

const StyledHeader = styled.h3`
  padding: ${(props) => props.theme.margin[8]} 0;
  font-size: 18px;
`;

const NewOrderSidebar = ({ procurementAreaId, onClose }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<RouteParams>();

  const remoteSupplierOptions = useSelector(getSuppliers);
  const remoteVatCodeOptions = useSelector(getVatCodes);
  const remoteCostTypeOptions = useSelector(getCostTypes);
  const remoteAccountOptions = useSelector(getAccounts);

  const vatCodeOptions = useRemoteData(getVatCodes, getDropDowns) ?? [];
  const costTypeOptions = useRemoteData(getCostTypes, getDropDowns) ?? [];
  const accountOptions = useRemoteData(getAccounts, getDropDowns) ?? [];

  const procurementReferenceNumberHeader = useTxt(
    'order.options.secondHeader.edit'
  );

  const allOrdersForProject =
    useRemoteData(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const procurementReferenceNumber =
    useRemoteData(
      getProcurementReferenceNumber(projectId),
      fetchProcurementReferenceNumberForProject(projectId)
    ) ?? '';

  const procurementReferenceNumberTooltip = useTxt(
    'order.options.header.tooltip'
  );

  const allVisibleCodes = allOrdersForProject.map((order) => order.visibleCode);

  const procurementAreaCode =
    useSelector(getProcumentAreaById(procurementAreaId))?.code ?? '';

  const otherVisibleCodesForProcurementArea = useSelector(
    getOrdersByProcurementAreaId(procurementAreaId)
  ).map((order) => order.visibleCode);

  const maxCode =
    sortBy(otherVisibleCodesForProcurementArea, (code) => code).pop() ??
    procurementAreaCode.concat('00000').slice(0, 5);

  const createNextCode = (currentCode: string) => {
    let str = currentCode;
    const match = str.match(/^(.*?)(\d*)$/);

    if (match && match[2] !== '') {
      // If the currentCode contains a number at the end, increment it
      const numStr = (parseInt(match[2], 10) + 1).toString();
      const zerosToAdd = match[2].length - numStr.length;
      str = match[1] + '0'.repeat(zerosToAdd) + numStr;
    } else {
      // If the currentCode does not contain a number at the end, append "1"
      str += '1';
    }

    return str;
  };

  const defaultVisibleCode = createNextCode(maxCode);

  const onCreateSuccess = (data: APIUpdatedEntities) => {
    const order = data.orders && data.orders[0];

    if (order) {
      history.push(
        generateUrl({ route: routes.ORDER, projectId, orderId: order.id })
      );
    } else {
      history.push(generateUrl({ route: routes.PROJECT, projectId }));
    }

    dispatch(clearProcurementReferenceNumber({ projectId }));
  };

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (values.name === '') {
      errors.name = 'order.options.formik.error.name.mandatory';
    }

    if (values.name.length > 200) {
      errors.name = 'order.options.formik.error.name.length';
    }

    if (values.visibleCode === '') {
      errors.name = 'order.options.formik.error.code.mandatory';
    }

    if (values.visibleCode.length > 10) {
      errors.visibleCode = 'order.options.formik.error.code.length';
    }

    if (!uniqueCode(allVisibleCodes).is(values.visibleCode)) {
      errors.visibleCode = 'order.options.formik.error.code.unique';
    }

    if (!onlyLettersAndNumbersAndDashes(values.visibleCode)) {
      errors.visibleCode =
        'order.options.formik.error.code.onlyLettersAndNumbersAndDashes';
    }

    if (
      values.visibleCode.slice(
        0,
        procurementAreaCode ? procurementAreaCode.length : 0
      ) !== procurementAreaCode
    ) {
      errors.visibleCode =
        'order.options.formik.error.code.procurementAreaMatch';
    }

    return errors;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      vatCodeId: '',
      supplierId: '',
      costTypeId: '',
      accountCodeId: '',
      visibleCode: defaultVisibleCode,
    },
    validate,
    onSubmit: (values) => {
      const body: APIOrderPostBody = {
        name: values.name,
        vatCodeId: nullifyIfEmpty(values.vatCodeId),
        supplierId: nullifyIfEmpty(values.supplierId),
        costTypeId: nullifyIfEmpty(values.costTypeId),
        accountCodeId: nullifyIfEmpty(values.accountCodeId),
        contractor: null,
        procurementAreaId,
        visibleCode: values.visibleCode,
        procurementReferenceNumber:
          procurementReferenceNumber !== ''
            ? procurementReferenceNumber
            : undefined,
      };

      dispatch(createOrder(projectId, body, onCreateSuccess, onClose));
    },
  });

  const nameErrorId = isNameErrorTextId(formik.errors.name)
    ? formik.errors.name
    : undefined;

  const visibleCodeErrorId = isVisibleCodeErrorTextId(formik.errors.visibleCode)
    ? formik.errors.visibleCode
    : undefined;

  const nameError = useTxt(nameErrorId);

  const visibleCodeError = useTxt(visibleCodeErrorId);

  const nameErrorText = nameError.length === 0 ? null : nameError;

  const visibleCodeErrorText =
    visibleCodeError.length === 0 ? null : visibleCodeError;

  const onSupplierChange = (value: string, suppliers: Supplier[]) => {
    const selectedSupplier = suppliers.find(
      (supplier) => supplier.id === value
    );

    if (!selectedSupplier) {
      return formik.setFieldValue('supplierId', value);
    }

    const {
      defaultVatCode,
      defaultAccountCode,
      defaultCostType,
    } = selectedSupplier;

    const costTypeId = costTypeOptions.find(
      (costType) => costType.externalCode === defaultCostType
    )?.id;

    const accountCodeId = accountOptions.find(
      (account) => account.externalCode === defaultAccountCode
    )?.id;

    const vatCodeId = vatCodeOptions.find(
      (vatCode) => vatCode.externalCode === defaultVatCode
    )?.id;

    if (costTypeId) {
      formik.setFieldValue('costTypeId', costTypeId);
    }

    if (accountCodeId) {
      formik.setFieldValue('accountCodeId', accountCodeId);
    }

    if (vatCodeId) {
      formik.setFieldValue('vatCodeId', vatCodeId);
    }

    return formik.setFieldValue('supplierId', value);
  };

  const chooseText = useTxt('common.choose');
  const nameText = useTxt('order.options.name');
  const codeText = useTxt('order.options.visibleCode');

  return (
    <Sidebar ariaLabelledBy="create-new-order-sidebar-title" onClose={onClose}>
      <SidebarContent>
        <StyledHeader id="create-new-order-sidebar-title">
          <Txt id="order.options.header.new" />
        </StyledHeader>
        {procurementReferenceNumber ? (
          <TooltipInfoContent>
            <StyledSpan>{procurementReferenceNumberHeader}</StyledSpan>
            <TooltipInfoContent>
              <div>{procurementReferenceNumber}</div>
              <Tooltip
                className="hoverable-tooltip"
                tip={procurementReferenceNumberTooltip}
              >
                <Icon src={IconInfo} alt="altText" />
              </Tooltip>
            </TooltipInfoContent>
          </TooltipInfoContent>
        ) : null}

        <form name="create-new-order-form" onSubmit={formik.handleSubmit}>
          <InputContainer>
            <TextInput
              label={codeText}
              name="visibleCode"
              value={formik.values.visibleCode}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              errorMessage={visibleCodeErrorText}
              paddingBottom="0.5rem"
            />
            <TextInput
              label={nameText}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              errorMessage={nameErrorText}
            />
            <SelectLabel htmlFor="supplier-select">
              <Txt id="order.options.supplier" component="b" />
              <RemoteData data={remoteSupplierOptions} fetchData={getDropDowns}>
                {(suppliers) => (
                  <>
                    <DropDownSelect
                      additionalStyles={additionalStyling}
                      additionalContainerStyles={additionalContainerStyling}
                      defaultValue={formik.values.supplierId}
                      onChange={(value) => {
                        const selection = value === 'NONE' ? '' : value;
                        onSupplierChange(selection, suppliers);
                      }}
                      disabled={formik.isSubmitting}
                      id="supplier-select"
                      options={[
                        {
                          key: 'NONE',
                          value: 'NONE',
                          label: chooseText,
                        },
                        ...suppliers.map((s) => ({
                          key: s.id,
                          value: s.id,
                          label: `${s.name} (${s.businessId})`,
                        })),
                      ]}
                    />
                    {formik.errors.supplierId ? (
                      <div className="error">{formik.errors.supplierId}</div>
                    ) : null}
                  </>
                )}
              </RemoteData>
            </SelectLabel>

            <SelectLabel htmlFor="cost-type-select">
              <Txt id="order.options.costType" component="b" />
              <RemoteData data={remoteCostTypeOptions} fetchData={getDropDowns}>
                {(costTypes) => (
                  <Select
                    name="costTypeId"
                    value={formik.values.costTypeId}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    id="cost-type-select"
                  >
                    <option value="">{chooseText}</option>
                    {costTypes.map((ct) => (
                      <option key={ct.id} value={ct.id}>
                        {ct.name}
                      </option>
                    ))}
                  </Select>
                )}
              </RemoteData>
            </SelectLabel>

            <SelectLabel htmlFor="vat-code-select">
              <Txt id="order.options.vatCode" component="b" />
              <RemoteData data={remoteVatCodeOptions} fetchData={getDropDowns}>
                {(vatCodes) => (
                  <Select
                    name="vatCodeId"
                    value={formik.values.vatCodeId}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    id="vat-code-select"
                  >
                    <option value="">{chooseText}</option>
                    {vatCodes.map((code) => (
                      <option key={code.id} value={code.id}>
                        {`${code.externalCode} - ${code.name}`}
                      </option>
                    ))}
                  </Select>
                )}
              </RemoteData>
            </SelectLabel>

            <SelectLabel htmlFor="account-select">
              <Txt id="order.options.account" component="b" />
              <RemoteData data={remoteAccountOptions} fetchData={getDropDowns}>
                {(accounts) => (
                  <Select
                    name="accountCodeId"
                    value={formik.values.accountCodeId}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    id="account-select"
                  >
                    <option value="">{chooseText}</option>
                    {accounts.map((acc) => (
                      <option key={acc.id} value={acc.id}>
                        {`${acc.externalCode} - ${acc.name}`}
                      </option>
                    ))}
                  </Select>
                )}
              </RemoteData>
            </SelectLabel>
          </InputContainer>

          <CenteredButtonGroup>
            <SecondaryButton type="button" onClick={onClose}>
              Peruuta
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? <Spinner size="1rem" light /> : 'Tallenna'}
            </PrimaryButton>
          </CenteredButtonGroup>
        </form>
      </SidebarContent>
    </Sidebar>
  );
};

export default NewOrderSidebar;

const additionalStyling: React.CSSProperties = {
  marginTop: theme.margin[8],
  marginBottom: theme.margin[20],
  borderRadius: theme.margin[4],
  background: theme.color.dropdownBg,
  fontSize: theme.fontSize.base,
  appearance: 'none',
};

const additionalContainerStyling: React.CSSProperties = {
  padding: `${theme.margin[4]} ${theme.margin[4]} ${theme.margin[4]} ${theme.margin[16]}`,
  height: theme.margin[36],
  backgroundColor: 'transparent',
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[16]}`,
};

const Icon = styled.img`
  margin-left: 12px;

  border-radius: 50%;

  width: ${({ theme: { margin } }) => margin[18]};
  height: ${({ theme: { margin } }) => margin[18]};

  background-color: ${({ theme: { color } }) => color.black};
`;
