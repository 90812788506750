import React from 'react';

import styled from 'styled-components';

import { SelectOption } from '../../types/ui';

import CheckboxComponent from '../Input/Checkbox';

/**
 * @param item item of type ../../types/ui ==> SelectOption
 * @param onChange sends back clicked| checked |unchecked item
 */

export const ListItem = (
  item: SelectOption,
  onChange: (option: SelectOption) => void
) => {
  return (
    <StyledDiv
      key={item.id}
      disable={item.disabled}
      onClick={() => onChange({ ...item, selected: !item.selected })}
    >
      <CheckboxComponent
        name={item.name}
        checked={item.selected}
        onChange={(e) => onChange({ ...item, selected: e.target.checked })}
        disabled={item.disabled}
      />
      <Label>{item.name}</Label>
    </StyledDiv>
  );
};

type StyleDisable = {
  disable?: boolean;
};

const StyledDiv = styled.div<StyleDisable>`
  padding: 0.5rem;
  display: flex;
  column-gap: 7px;

  &:hover {
    background: ${({ disable }) => (!disable ? '#f2f2f2' : false)};
    cursor: ${({ disable }) => (disable ? 'not-allowed;' : 'pointer')};
  }
`;

const Label = styled.h3`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize.base};
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
`;
