import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sortBy } from 'lodash';
import styled from 'styled-components';

import { getOrderSnapshotsForSnapshotId } from '../../../../store/reducers/order/snapshots';
import { getProjectSnapshotPoCEntries } from '../../../../store/reducers/schedule/projectTimeline';
import { Snapshot } from '../../../../store/reducers/snapshot';
import { isSnapshotOpen } from '../../../../store/reducers/ui';

import {
  getProjectTimelineForProject,
  ProjectTimelineEntry,
} from '../../../../store/actions';
import { fetchOrderSnapshotsForSnapshotId } from '../../../../store/actions/order/snapshots';
import { uiSnapshotToggled } from '../../../../store/actions/ui';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import {
  BigPercentageValue,
  DeltaBigCostValue,
  DeltaBigProfitValue,
} from '../../../../components/BigValue';
import Cell, { MoneyCell } from '../../../../components/Cell';
import { Spinner } from '../../../../components/Loading';
import RemoteData from '../../../../components/RemoteData';
import { PrimaryRow } from '../../../../components/Table';
import Tooltip from '../../../../components/Tooltip';

import { dateTimeFormat, dateFormat } from '../../../../utils/format';

import { IconDown, IconRight } from '../../../../assets';

import globalTheme from '../../../../styles/theme';
import OrderSnapshotRow from './OrderSnapshotRow';

type SnapshotRowWithOrdersProps = {
  snapshot: Snapshot;
  projectId: string;
};

const SnapshotRowWithOrders = ({
  snapshot,
  projectId,
}: SnapshotRowWithOrdersProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isSnapshotOpen(snapshot.id));

  const orderSnapshotData = useSelector(
    getOrderSnapshotsForSnapshotId(snapshot.id)
  );

  const snapshotEntries =
    useRemoteData(
      getProjectSnapshotPoCEntries({ projectId }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const projectSnapshotTimelineEntry = snapshotEntries.find(
    (entry) => entry.snapshotId === snapshot.id
  );

  const toggleIsOpen = useCallback(() => {
    dispatch(uiSnapshotToggled(snapshot.id));
  }, [dispatch, snapshot.id]);

  return (
    <>
      <SnapshotRow
        isOpen={isOpen}
        snapshot={snapshot}
        onToggle={toggleIsOpen}
        projectTimelineEntry={projectSnapshotTimelineEntry}
      />
      {isOpen ? (
        <RemoteData
          fetchData={fetchOrderSnapshotsForSnapshotId(snapshot.id)}
          data={orderSnapshotData}
          loadingElement={
            <tr>
              <Cell colSpan={15} align="center">
                <SpinnerDiv>
                  <Spinner size="2rem" />
                </SpinnerDiv>
              </Cell>
            </tr>
          }
        >
          {(orderSnapshots) => {
            const filteredOrderSnapshots = orderSnapshots.filter(
              (item) =>
                item.predictionChangeBeforeLocking.abs().gte(1) ||
                item.targetChangeBeforeLocking.abs().gte(1)
            );

            const sortedOrderSnapshots = sortBy(
              filteredOrderSnapshots,
              (orderSnapshot) =>
                orderSnapshot.predictionChangeBeforeLocking.toNumber()
            );

            return (
              <>
                {sortedOrderSnapshots.map((item) => (
                  <OrderSnapshotRow key={item.orderId} orderSnapshot={item} />
                ))}
              </>
            );
          }}
        </RemoteData>
      ) : null}
    </>
  );
};

const SpinnerDiv = styled.div`
  margin: ${(props) => props.theme.margin[8]};
`;

type SnapshotRowProps = {
  isOpen: boolean;
  snapshot: Snapshot;
  onToggle: () => void;
  projectTimelineEntry: ProjectTimelineEntry | undefined;
};

const SnapshotRow = ({
  isOpen,
  snapshot,
  onToggle,
  projectTimelineEntry,
}: SnapshotRowProps) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      e.stopPropagation();
      onToggle();
    }
  };

  const onClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    e.stopPropagation();
    onToggle();
  };

  const iconOpenAltText = useTxt('reporting.table.snapshot.open.alt');
  const iconClosedAltText = useTxt('reporting.table.snapshot.closed.alt');

  const plannedPoCText = useTxt('reporting.table.snapshot.tooltip.planned', {
    snapshotCreationDate: dateFormat.format(snapshot.createdAt),
    timelineDate: projectTimelineEntry
      ? dateFormat.format(projectTimelineEntry.date)
      : '',
  });

  const previouslyPlannedPoCText = useTxt(
    'reporting.table.snapshot.tooltip.previouslyPlanned',
    {
      snapshotCreationDate: dateFormat.format(snapshot.createdAt),
      timelineDate: projectTimelineEntry
        ? dateFormat.format(projectTimelineEntry.date)
        : '',
    }
  );

  const reasonText = useTxt('common.reason');

  const tooltipText = (snapshotTypeId: string) => {
    switch (snapshotTypeId) {
      case '2':
        return `${plannedPoCText}. ${reasonText}: ${
          snapshot.snapshotTypeComment ?? ''
        }`;
      case '3':
        return `${previouslyPlannedPoCText}. ${reasonText}: ${
          snapshot.snapshotTypeComment ?? ''
        }`;
      default:
        return '';
    }
  };

  return (
    <PrimaryRow tabIndex={0} clickable onClick={onClick} onKeyDown={onKeyDown}>
      <Cell align="center">
        <img
          src={isOpen ? IconDown : IconRight}
          alt={isOpen ? iconOpenAltText : iconClosedAltText}
        />
      </Cell>
      <Cell>
        {dateTimeFormat.format(snapshot.createdAt).replace('klo ', '')}
      </Cell>
      <Cell>{snapshot.companyReportingPeriodDescription}</Cell>
      <Cell>{snapshot.description}</Cell>
      <Cell>{snapshot.userName}</Cell>
      <StyledCell
        align="right"
        snapshotTypeId={projectTimelineEntry?.snapshotTypeId}
      >
        {projectTimelineEntry ? (
          <Tooltip
            tip={tooltipText(projectTimelineEntry.snapshotTypeId)}
            className="compressed-tooltip"
            place="top"
            delayShow={300}
            disable={projectTimelineEntry.snapshotTypeId === '1'}
          >
            {projectTimelineEntry.snapshotTypeId !== '1' ? '*' : null}
            <BigPercentageValue
              value={projectTimelineEntry.percentageOfCompletion}
              decimals={0}
            />
          </Tooltip>
        ) : null}
      </StyledCell>
      <MoneyCell value={snapshot.targetTotal} decimals={0} />
      <MoneyCell value={snapshot.additionalTargetTotal} decimals={0} />
      <Cell align="right">
        <DeltaBigCostValue
          value={snapshot.targetChangeBeforeLocking}
          decimals={0}
        />
      </Cell>
      <MoneyCell value={snapshot.costPredictionTotal} decimals={0} />
      <Cell align="right">
        <DeltaBigCostValue
          value={snapshot.predictionChangeBeforeLocking}
          decimals={0}
        />
      </Cell>
      <MoneyCell value={snapshot.contractTotal} decimals={0} />
      <MoneyCell value={snapshot.changeOrdersTotal} decimals={0} />
      <MoneyCell value={snapshot.reservesTotal} decimals={0} />
      <MoneyCell value={snapshot.revenueTotal} decimals={0} />
      <MoneyCell
        value={snapshot.revenueTotal.sub(snapshot.costPredictionTotal)}
        decimals={0}
      />
      <Cell align="right">
        <DeltaBigProfitValue
          value={snapshot.revenuePredictionChangeBeforeLocking.sub(
            snapshot.predictionChangeBeforeLocking
          )}
          decimals={0}
        />
      </Cell>
    </PrimaryRow>
  );
};

type StyledCellProps = {
  snapshotTypeId: string | undefined;
};

const StyledCell = styled(Cell)<StyledCellProps>`
  ${(props) =>
    snapshotPoCColor(props.snapshotTypeId)
      ? `color: ${snapshotPoCColor(props.snapshotTypeId)}`
      : ''}
`;

const snapshotPoCColor = (snapshotTypeId: string | undefined) => {
  switch (snapshotTypeId) {
    case '2':
      return globalTheme.color.cyan;
    case '3':
      return globalTheme.color.blue;
    default:
      return undefined;
  }
};

export default SnapshotRowWithOrders;
