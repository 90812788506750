import React from 'react';

import { sortBy } from 'lodash';
import styled from 'styled-components';

import { OrderChangeLogEntry } from '../../../store/reducers/order/changeLogEntries';
import { OrderSnapshot } from '../../../store/reducers/order/snapshots';
import { TargetChangeLogEntry } from '../../../store/reducers/target/changeLogEntries';

import useTxt from '../../../hooks/useTxt';

import { Table } from '../../../components/Table';

import ChangeLogEntry, { isOrderChangeLogEntry } from './ChangeLogEntry';
import { InvisibleTableHeader, TableHeader } from './ChangeLogTableHeaders';
import { VerticalAccordion } from './VerticalAccordion';

type ChangeLogTableProps = {
  changeLogEntries: (OrderChangeLogEntry | TargetChangeLogEntry)[];
  snapshots: OrderSnapshot[];
};

export const ChangeLogTable = ({
  changeLogEntries,
  snapshots,
}: ChangeLogTableProps) => {
  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);

  const emptyState = { order: [], target: [] };

  const [filterValue, setFilterValue] = React.useState<{
    order: string[];
    target: string[];
  }>(emptyState);

  const [accordionState, setAccordionState] = React.useState<
    Record<string, boolean>
  >({ NA: true });

  const setSelectedStatuses = ({
    key,
    value,
  }: {
    key: 'order' | 'target';
    value: string | null;
  }) => {
    if (value === null) {
      return setFilterValue(emptyState);
    }

    const state = filterValue;
    const index = state[key].indexOf(value);

    if (index > -1) {
      const newState = state[key].filter((item) => item !== value);

      return setFilterValue({ ...state, [key]: newState });
    }

    return setFilterValue({ ...state, [key]: state[key].concat(value) });
  };

  const toggleAccordion = (snapshotIdorCurrent: string) => {
    const state = accordionState;

    const newState = {
      ...state,
      [snapshotIdorCurrent]: !state[snapshotIdorCurrent],
    };

    return setAccordionState(newState);
  };

  const entriesFilteredByActions =
    filterValue.order.length + filterValue.target.length > 0
      ? changeLogEntries.filter(
          (entry) =>
            (filterValue.order.includes(entry.changeType) &&
              isOrderChangeLogEntry(entry)) ||
            (filterValue.target.includes(entry.changeType) &&
              !isOrderChangeLogEntry(entry))
        )
      : changeLogEntries;

  const currentPeriodDescription = useTxt(
    'comments.modal.changes.currentPeriod'
  );

  return (
    <>
      <StyledTable>
        <TableHeader
          filterOpen={filterOpen}
          filterValue={filterValue}
          toggleFilter={() => setFilterOpen(!filterOpen)}
          setFilterValue={setSelectedStatuses}
        />
      </StyledTable>
      {snapshots.map((snapshot) => {
        const isOpened = accordionState[snapshot.snapshotId] ?? false;

        const entriesBySnapshot = entriesFilteredByActions.filter(
          (entry) => entry.snapshotId === snapshot.snapshotId
        );

        const sortedEntries = sortBy(
          entriesBySnapshot,
          ['timestamp', 'id'],
          ['asc', 'asc']
        );

        const snapshotDescription =
          snapshot.companyReportingPeriodDescription &&
          snapshot.companyReportingPeriodDescription.length > 0
            ? snapshot.companyReportingPeriodDescription
            : snapshot.snapshotDescription ?? '';

        const description = snapshot.current
          ? currentPeriodDescription
          : snapshotDescription;

        return (
          <VerticalAccordion
            key={`snapshot-accordion-${snapshot.snapshotId}`}
            toggle={() => toggleAccordion(snapshot.snapshotId)}
            isOpen={isOpened}
            snapshotDescription={description}
            predictionChangeBeforeLocking={
              snapshot.predictionChangeBeforeLocking
            }
            targetChangeBeforeLocking={snapshot.targetChangeBeforeLocking}
            arrivalsChangeBeforeLocking={snapshot.arrivalsChangeBeforeLocking}
            changeLogEntryCount={sortedEntries.length}
          >
            <Table>
              <InvisibleTableHeader />
              <tbody>
                {sortedEntries.map((entry) => (
                  <ChangeLogEntry key={entry.id} {...entry} />
                ))}
              </tbody>
            </Table>
          </VerticalAccordion>
        );
      })}
    </>
  );
};

const StyledTable = styled(Table)`
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`;
