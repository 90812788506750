import { useParams } from 'react-router-dom';

import { OrderViewMode } from '../types/general';

type RouteParams = {
  projectId: string;
  orderId: string;
  viewMode?: Exclude<OrderViewMode['type'], 'normal'>;
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
};

export default function useRouteParams(): {
  projectId: string;
  orderId: string;
  viewMode: OrderViewMode['type'];
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
} {
  const {
    projectId,
    orderId,
    viewMode = 'normal',
    subViewMode,
    showTargetRows,
    showInfo,
  } = useParams<RouteParams>();

  return {
    projectId,
    orderId,
    viewMode,
    subViewMode,
    showTargetRows,
    showInfo,
  };
}
