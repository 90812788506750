import React from 'react';

import styled from 'styled-components';

import { TableHeader as BaseHeader } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import getWidths from '../../../../utils/headerWidth';

export const tableColumns = {
  timestamp: { align: 'left', relativeWidth: 2 },
  user: { align: 'left', relativeWidth: 2 },
  amount: { align: 'right', relativeWidth: 2 },
  invoice: { align: 'left', relativeWidth: 3 },
  link: { align: 'left', relativeWidth: 1 },
};

const getColumnPercent = getWidths(tableColumns);

const textIdHead = 'order.invoicesPerOrderRowModal.table.header.';

type ThProps = {
  name: keyof typeof tableColumns;
  background?: boolean;
};

const Th = styled.th<ThProps>`
  padding: ${({ theme: { margin } }) => `${margin[8]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  ${(props) =>
    props.background
      ? `background: ${props.theme.color.primaryRowBackground};`
      : null}
  text-align: ${({ name }) => tableColumns[name].align};
`;

const TxtTh = ({ name, background }: ThProps) => (
  <Th name={name} background={background}>
    <Txt id={`${textIdHead}${name}` as const} />
  </Th>
);

export const TableHeader = () => {
  return (
    <BaseHeader>
      <tr>
        <TxtTh name="timestamp" />
        <TxtTh name="user" />
        <TxtTh name="amount" />
        <TxtTh name="invoice" />
        <TxtTh name="link" />
      </tr>
    </BaseHeader>
  );
};
