import {
  APITopic,
  APITopicPostBody,
  APITopicPutBody,
  APIUpdatedEntities,
  RawAPIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import {
  makeAction,
  makeApiActions,
  ExtractActionTypes,
} from '../../utils/actionCreators';
import {
  apiErrorHandlingWithDecode,
  BackendError,
  DELETE,
  GET,
  POST,
  PUT,
} from '../../utils/api';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  isFetchingByOrderId,
  isFetchingByWorkPackageId,
  isFetchingByProjectId,
  isDeletingTopic,
} from '../reducers/topic';

export type TopicAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getTopicsByOrderIdStarted')<{ orderId: string }>(),
  ...makeAction('getTopicsByOrderIdFailure')<{
    error: BackendError | undefined;
    orderId: string;
  }>(),
  ...makeAction('getTopicsByOrderIdSuccess')<{
    orderId: string;
    topics: APITopic[];
  }>(),

  ...makeAction('getTopicsByWorkPackageIdStarted')<{ workPackageId: string }>(),
  ...makeAction('getTopicsByWorkPackageIdFailure')<{
    error: BackendError | undefined;
    workPackageId: string;
  }>(),
  ...makeAction('getTopicsByWorkPackageIdSuccess')<{
    workPackageId: string;
    topics: APITopic[];
  }>(),

  ...makeAction('getTopicsByProjectIdStarted')<{ projectId: string }>(),
  ...makeAction('getTopicsByProjectIdFailure')<{
    error: BackendError | undefined;
    projectId: string;
  }>(),
  ...makeAction('getTopicsByProjectIdSuccess')<{
    projectId: string;
    topics: APITopic[];
  }>(),

  ...makeApiActions('put', 'topic')<APIUpdatedEntities>(),
  ...makeApiActions('post', 'topic')<APIUpdatedEntities>(),
  ...makeAction('deleteTopicStarted')<{
    topicId: string;
  }>(),
  ...makeAction('deleteTopicFailure')<{
    error: BackendError | undefined;
    topicId: string;
  }>(),
  ...makeAction('deleteTopicSuccess')<
    APIUpdatedEntities & {
      topicId: string;
      orderId: string;
      topicName: string;
      projectId: string;
      defaultTopic: APITopic | undefined;
    }
  >(),
};
export const {
  getTopicsByOrderIdSuccess,
  getTopicsByOrderIdFailure,
  getTopicsByOrderIdStarted,

  getTopicsByWorkPackageIdSuccess,
  getTopicsByWorkPackageIdFailure,
  getTopicsByWorkPackageIdStarted,

  getTopicsByProjectIdSuccess,
  getTopicsByProjectIdFailure,
  getTopicsByProjectIdStarted,

  postTopicStarted,
  postTopicSuccess,
  postTopicFailure,
  putTopicStarted,
  putTopicSuccess,
  putTopicFailure,
  deleteTopicStarted,
  deleteTopicFailure,
  deleteTopicSuccess,
} = actionCreators;

const getTopicsByOrderId = (orderId: ID) =>
  GET<APITopic[]>(`v1/orders/${orderId}/topics`);

const getTopicsByWorkPackageId = (workPackageId: ID) =>
  GET<APITopic[]>(`v1/work-packages/${workPackageId}/topics`);

const getTopicsByProjectId = (projectId: ID) =>
  GET<APITopic[]>(`v1/projects/${projectId}/topics`);

export const fetchTopicsForOrder = (orderId: string) =>
  createAsyncThunk(getTopicsByOrderId, {
    args: [orderId],
    isPending: isFetchingByOrderId(orderId),
    initialAction: getTopicsByOrderIdStarted({ orderId }),
    failureActionCreator: (error) =>
      getTopicsByOrderIdFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (topics) =>
      getTopicsByOrderIdSuccess({ orderId, topics }),
  });

export const fetchTopicsForWorkPackage = (workPackageId: string) =>
  createAsyncThunk(getTopicsByWorkPackageId, {
    args: [workPackageId],
    isPending: isFetchingByWorkPackageId(workPackageId),
    initialAction: getTopicsByWorkPackageIdStarted({ workPackageId }),
    failureActionCreator: (error) =>
      getTopicsByWorkPackageIdFailure({
        workPackageId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (topics) =>
      getTopicsByWorkPackageIdSuccess({ workPackageId, topics }),
  });

export const fetchTopicsForProject = (projectId: string) =>
  createAsyncThunk(getTopicsByProjectId, {
    args: [projectId],
    isPending: isFetchingByProjectId(projectId),
    initialAction: getTopicsByProjectIdStarted({ projectId }),
    failureActionCreator: (error) =>
      getTopicsByProjectIdFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (topics) =>
      getTopicsByProjectIdSuccess({ projectId, topics }),
  });

const postTopic = (body: APITopicPostBody) =>
  POST<RawAPIUpdatedEntities>('v1/topics', body).then(mapRawUpdatedEntities);
export const createTopic = (
  body: APITopicPostBody,
  successCallback?: (updated: APIUpdatedEntities) => void
): Thunk => (dispatch, _) => {
  dispatch(postTopicStarted());

  postTopic(body).then(
    (updatedEntities) => {
      dispatch(postTopicSuccess(updatedEntities));

      if (successCallback) {
        successCallback(updatedEntities);
      }
    },
    (error) => {
      dispatch(postTopicFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};

const putTopic = (topicId: ID, body: APITopicPutBody) =>
  PUT<RawAPIUpdatedEntities>(`v1/topics/${topicId}`, body).then(
    mapRawUpdatedEntities
  );
export const updateTopic = (topicId: ID, body: APITopicPutBody): Thunk => (
  dispatch
) => {
  dispatch(putTopicStarted());
  putTopic(topicId, body).then(
    (updatedEntities) => {
      dispatch(putTopicSuccess(updatedEntities));
    },
    (error) => {
      dispatch(putTopicFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};

const makeDeleteTopicApiRequest = (topicId: ID) =>
  DELETE<RawAPIUpdatedEntities>(`v1/topics/${topicId}`).then(
    mapRawUpdatedEntities
  );

export const deleteTopic = (
  topicId: ID,
  topicName: string,
  orderId: ID,
  projectId: ID,
  defaultTopic: APITopic | undefined
) =>
  createAsyncThunk(makeDeleteTopicApiRequest, {
    args: [topicId],
    isPending: isDeletingTopic(topicId),
    initialAction: deleteTopicStarted({ topicId }),
    failureActionCreator: (error) =>
      deleteTopicFailure({
        topicId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      deleteTopicSuccess({
        topicId,
        topicName,
        orderId,
        projectId,
        defaultTopic,
        ...updatedEntities,
      }),
  });
