import React from 'react';
import { useSelector } from 'react-redux';

import {
  getUnhandledActualCostsByOrderId,
  ActualCost,
} from '../../../../../store/reducers/actualCost';
import {
  getArrivalRowsByOrderId,
  getUnassignedArrivalRowsByOrderId,
} from '../../../../../store/reducers/arrivalRow';
import {
  getUnhandledInvoiceHeadersByOrderId,
  InvoiceHeader,
} from '../../../../../store/reducers/invoiceHeader';

import { ID } from '../../../../../types/general';

import { useArrivalRowSelection } from '../../../../../hooks/ui';
import useTxt from '../../../../../hooks/useTxt';

import { Table } from '../../../../../components/Table';

import * as big from '../../../../../utils/big';
import CAN, {
  CaslOrderRequestParams,
} from '../../../../../utils/caslUserPermissions';

import ArrivalRow from './ArrivalRow';
import ArrivalRowTableHeader from './ArrivalRowTableHeader';
import AssignmentTool, { AssignmentSelection } from './AssignmentTool';
import { SectionTitleBar } from './Components';

type Props = {
  orderId: string;
  projectId: string;
  defaultSelection: AssignmentSelection;
  onAssignPurchaseInvoice: (id: ID) => void;
  onAssignActualCost: (id: ID) => void;
};

// Initial selection is either the first invoice, cost or no selection.
const getDefaultAssignmentSelection = (
  invoiceHeaders: InvoiceHeader[],
  actualCosts: ActualCost[],
  defaultSelection: AssignmentSelection
): AssignmentSelection => {
  const [invoice] = invoiceHeaders;
  const [cost] = actualCosts;

  const unhandledInvoiceIds = invoiceHeaders.map((row) => row.id);
  const unhandledActualCostIds = actualCosts.map((row) => row.id);

  if (
    defaultSelection.kind === 'invoice' &&
    unhandledInvoiceIds.includes(defaultSelection.id)
  ) {
    return defaultSelection;
  }

  if (
    defaultSelection.kind === 'cost' &&
    unhandledActualCostIds.includes(defaultSelection.id)
  ) {
    return defaultSelection;
  }

  return (
    (invoice && {
      kind: 'invoice',
      id: invoice.id,
    }) ||
    (cost && {
      kind: 'cost',
      id: cost.id,
    }) || { kind: 'unassigned' }
  );
};

const UnassignedArrivalRowsTable = ({
  orderId,
  onAssignPurchaseInvoice,
  onAssignActualCost,
  projectId,
  defaultSelection,
}: Props) => {
  const arrivalRows = useSelector(getArrivalRowsByOrderId(orderId));

  const invoiceHeaders = useSelector(
    getUnhandledInvoiceHeadersByOrderId(orderId)
  );

  const actualCosts = useSelector(getUnhandledActualCostsByOrderId(orderId));

  const newDefaultValue = getDefaultAssignmentSelection(
    invoiceHeaders,
    actualCosts,
    defaultSelection
  );

  const sortedArrivalRows = useSelector(
    getUnassignedArrivalRowsByOrderId(orderId)
  );

  const sectionTitleText = useTxt(
    'order.receiveMode.unassignedArrivalRows.sectionTitle'
  );

  const total = big.sum(
    ...sortedArrivalRows.map((row) => row.quantity.mul(row.unitPrice))
  );

  const [selectedArrivalRows, setSelected] = useArrivalRowSelection();

  const allRowsSelected = sortedArrivalRows.every(
    (row) => selectedArrivalRows[row.id]
  );

  const onSelectAll = () => {
    setSelected(
      sortedArrivalRows.map((row) => row.id),
      !allRowsSelected
    );
  };

  const onSelectRow = (rowId: string) => (selected: boolean) => {
    setSelected([rowId], selected);
  };

  const selectedArrivalRowsArray = arrivalRows.filter(
    (row) => selectedArrivalRows[row.id]
  );

  const ability = new CaslOrderRequestParams(projectId);
  const allowedUser = !CAN('write', ability);

  return (
    <>
      <SectionTitleBar>
        {sectionTitleText} {big.priceFormat(total)}
      </SectionTitleBar>
      <AssignmentTool
        arrivalRows={selectedArrivalRowsArray}
        invoiceHeaders={invoiceHeaders}
        actualCosts={actualCosts}
        defaultSelection={newDefaultValue}
        orderId={orderId}
        onAssignActualCost={onAssignActualCost}
        onAssignPurchaseInvoice={onAssignPurchaseInvoice}
        allowedUser={allowedUser}
      />
      {sortedArrivalRows.length > 0 && (
        <Table>
          <ArrivalRowTableHeader
            listType="unassigned"
            allSelected={
              sortedArrivalRows.length > 0 ? allRowsSelected : undefined
            }
            onSelectAll={onSelectAll}
            allowedUser={allowedUser}
          />
          <tbody>
            {sortedArrivalRows.map((arrivalRow) => (
              <ArrivalRow
                projectId={projectId}
                key={`unassigned-arrival-row-${arrivalRow.id}`}
                arrivalRowId={arrivalRow.id}
                selected={selectedArrivalRows[arrivalRow.id]}
                onSelect={onSelectRow(arrivalRow.id)}
                isHandled={false}
              />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default UnassignedArrivalRowsTable;
