import React from 'react';

function useOnClickOutside(onClickOutside: () => undefined | void) {
  const ref = React.useRef(null);

  const clickListener = React.useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current) {
        if (!(ref.current! as any).contains(e.target)) {
          onClickOutside();
        }
      }
    },
    [ref, onClickOutside]
  );

  React.useEffect(() => {
    document.addEventListener('click', clickListener, true);

    return () => {
      document.removeEventListener('click', clickListener, true);
    };
  }, [clickListener]);

  return ref;
}

export default useOnClickOutside;
