import React, { useEffect } from 'react';

import Big from 'big.js';
import { OptionGroupData } from 'rc-select/lib/interface';
import styled, { css } from 'styled-components';

import { InvoiceLine as APIInvoiceLine } from '../../../../store/reducers/invoiceLine';

import { APIOrderRow } from '../../../../types/api';
import { InvoiceLineSelectionPayload } from '../../../../types/general';

import useScrollTo from '../../../../hooks/useScrollTo';
import useTxt from '../../../../hooks/useTxt';

import Checkbox from '../../../../components/Input/Checkbox';
import { SecondaryRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';

import { CompactCell } from '../LineTablesCommon';
import OrderRowDropdown, {
  OrderRowDescDiv,
  OrderRowDiv,
  orderRowStyle,
  OrderRowText,
  PriceText,
} from '../OrderRowDropdown';

type Props = {
  invoiceLine: APIInvoiceLine;
  orderRows: APIOrderRow[];
  selectionState: InvoiceLineSelectionPayload | undefined;
  isAllRendered: boolean;
  isHighlighted: boolean;
  allowedUser: boolean;
  onChange: (
    invoiceLineId: string,
    selection?: string | null
  ) => void | undefined;
  widthForDropdown: number;
  checked: boolean;
  onToggle: (invoiceLineId: string) => void | undefined;
  options: OptionGroupData[];
};

const InvoiceLine = ({
  invoiceLine,
  orderRows,
  selectionState,
  isAllRendered,
  isHighlighted,
  onChange,
  allowedUser,
  widthForDropdown,
  checked,
  onToggle,
  options,
}: Props) => {
  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLTableRowElement>();

  useEffect(() => {
    if (isAllRendered && isHighlighted) {
      setShouldScrollTo(true);
    }
  }, [setShouldScrollTo, isAllRendered, isHighlighted]);

  const defaultValue = invoiceLine.orderRowId
    ? `orderRow-${invoiceLine.orderRowId}`
    : 'NONE';

  const notSelectedLabelText = useTxt(
    'order.invoiceLines.row.notSelectedLabel'
  );

  const newOrderRowLabelText = useTxt('order.invoiceLines.row.newOrderRow');

  const newOrderRowLabelTextShort = useTxt(
    'order.invoiceLines.row.newOrderRowShortLabel'
  );

  const optionsWithNewRow = [
    {
      options: [
        {
          value: 'NONE',
          title: notSelectedLabelText,
          label: notSelectedLabelText,
          shortlabel: '-',
        },
      ],
    },
    ...options.map((group) => {
      const newRowOption = {
        value:
          group.topicId !== null
            ? `newRowForTopic-${group.topicId}`
            : `newRowForWorkPackage-${group?.workPackage?.id}`,
        title: `${newOrderRowLabelText} ${group.title} ${
          group.workPackage
            ? `${group.workPackage.code} ${group.workPackage.name}`
            : null
        } ${big.priceFormat(invoiceLine.netPrice ?? new Big('0'))}/${
          invoiceLine.unit
        }`,
        label: (
          <OrderRowDiv width={widthForDropdown - 40}>
            <span>+</span>
            <OrderRowDescDiv>
              <OrderRowText>{newOrderRowLabelText}</OrderRowText>
            </OrderRowDescDiv>
            <PriceText>{`${big.priceFormat(
              invoiceLine.netPrice ?? new Big('0')
            )}/${invoiceLine.unit}`}</PriceText>
          </OrderRowDiv>
        ),
        shortlabel: newOrderRowLabelTextShort,
        style: orderRowStyle,
      };

      return { ...group, options: [...group.options, newRowOption] };
    }),
  ];

  return (
    <InvoiceLineWithHighlight
      data-testid={`invoice-line-selector-${invoiceLine.id}`}
      highlighted={isHighlighted}
      ref={scrollToRef}
    >
      <CompactCell>{invoiceLine.rowIndex}</CompactCell>
      <CompactCell align="center" paddingDisabled>
        {invoiceLine.arrivalRowId === null ? (
          <Checkbox
            checked={checked}
            onChange={() => onToggle(invoiceLine.id)}
            variant="secondary"
          />
        ) : null}
      </CompactCell>
      <CompactCell>{invoiceLine.productCode ?? ''}</CompactCell>
      <CompactCell>{invoiceLine.productName ?? ''}</CompactCell>
      <CompactCell align="right">
        {big.amountFormat(invoiceLine.quantity)}
      </CompactCell>
      <CompactCell>{invoiceLine.unit}</CompactCell>
      <CompactCell align="right">
        {big.priceFormat(invoiceLine.netPrice)}
      </CompactCell>
      <CompactCell align="right">
        {big.priceFormat(invoiceLine.netTotal)}
      </CompactCell>
      <CompactCell align="right">
        {big.amountFormat(invoiceLine.taxPercent)}%
      </CompactCell>
      <CompactCell overflowNotHidden>
        <OrderRowDropdown
          allowedUser={allowedUser}
          orderRows={orderRows}
          selectionState={selectionState}
          widthForDropdown={widthForDropdown}
          onChange={(selectedOrderRowOrTopicId) => {
            // Add invoiceLineId
            const selection =
              selectedOrderRowOrTopicId === 'NONE'
                ? null
                : selectedOrderRowOrTopicId;
            onChange(invoiceLine.id, selection);
          }}
          defaultValue={defaultValue}
          options={optionsWithNewRow}
          alreadyAssigned={invoiceLine.arrivalRowId !== null}
        />
      </CompactCell>
    </InvoiceLineWithHighlight>
  );
};

export default InvoiceLine;

type InvoiceLineWithHighlightProps = {
  highlighted: boolean;
};

const InvoiceLineWithHighlight = styled(
  SecondaryRow
)<InvoiceLineWithHighlightProps>`
  @keyframes flash-animation {
    from {
      background: ${(props) => props.theme.color.sidebarBackground};
      opacity: 0.3;
    }
    to {
      background: default;
    }
  }

  ${({ highlighted }) =>
    highlighted
      ? css`
          animation: flash-animation linear 1.5s 1;
        `
      : css``}
`;
