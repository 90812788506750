import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  APIOrderChatMessage,
  APIUpdatedEntities,
  RawAPIUpdatedEntities,
} from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
  POST,
} from '../../../utils/api';
import { dateString } from '../../../utils/decoders';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk } from '../../../utils/thunk';

import {
  getOrderChatCreateRequest,
  getOrderChatFetchRequest,
} from '../../reducers/order/chatMessages';

export type OrderChatMessagesAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getOrderChatMessagesStarted')<{ orderId: string }>(),
  ...makeAction('getOrderChatMessagesFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getOrderChatMessagesSuccess')<{
    orderId: string;
    orderChatMessages: APIOrderChatMessage[];
  }>(),
  ...makeAction('postOrderChatMessageStarted')<{
    orderId: string;
    requestId: string;
  }>(),
  ...makeAction('postOrderChatMessageFailure')<{
    orderId: string;
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postOrderChatMessageSuccess')<
    APIUpdatedEntities & { orderId: string; requestId: string }
  >(),
};

export const {
  getOrderChatMessagesStarted,
  getOrderChatMessagesFailure,
  getOrderChatMessagesSuccess,
  postOrderChatMessageStarted,
  postOrderChatMessageFailure,
  postOrderChatMessageSuccess,
} = actionCreators;

const orderChatMessageType = t.type({
  id: t.string,
  orderId: t.string,
  senderId: t.string,
  senderName: t.string,
  taggedUserIds: t.array(t.string),
  text: t.string,
  isDeleted: t.boolean,
  createdAt: dateString,
  updatedAt: dateString,
});

export type OrderChatMessage = t.TypeOf<typeof orderChatMessageType>;

export async function decodeOrderChatMessages(
  u: unknown
): Promise<OrderChatMessage[]> {
  const chatMessages = await tPromise.decode(t.array(orderChatMessageType), u);

  return chatMessages;
}

const getOrderChatMessages = async (orderId: string) => {
  const response = await GET(`v1/orders/${orderId}/chat-messages`);

  return decodeOrderChatMessages(response);
};

export const fetchOrderChatMessages = (orderId: string) =>
  createAsyncThunk(getOrderChatMessages, {
    args: [orderId],
    isPending: flow(getOrderChatFetchRequest(orderId), remoteData.isLoading),
    initialAction: getOrderChatMessagesStarted({ orderId }),
    successActionCreator: (response) =>
      getOrderChatMessagesSuccess({ orderId, orderChatMessages: response }),
    failureActionCreator: (error) =>
      getOrderChatMessagesFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

const postOrderChatMessages = async (
  orderId: string,
  message: string,
  taggedUserIds: string[]
): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>(
    `v1/orders/${orderId}/chat-message`,
    {
      message,
      taggedUserIds,
    }
  );

  return mapRawUpdatedEntities(response);
};

type PostOrderChatMessagePayload = {
  requestId: string;
  orderId: string;
  message: string;
  taggedUserIds: string[];
};

export const createOrderChatMessage = ({
  requestId,
  orderId,
  message,
  taggedUserIds,
}: PostOrderChatMessagePayload) =>
  createAsyncThunk(postOrderChatMessages, {
    args: [orderId, message, taggedUserIds],
    isPending: flow(
      getOrderChatCreateRequest(requestId),
      (requestState) => requestState === 'Loading'
    ),
    initialAction: postOrderChatMessageStarted({ requestId, orderId }),
    successActionCreator: (response) =>
      postOrderChatMessageSuccess({ ...response, requestId, orderId }),
    failureActionCreator: (error) =>
      postOrderChatMessageFailure({
        requestId,
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
