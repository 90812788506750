import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { OrderSnapshot } from '../../../../store/reducers/order/snapshots';

import useTxt from '../../../../hooks/useTxt';

import { DeltaBigCostValue } from '../../../../components/BigValue';
import Cell, { MoneyCell } from '../../../../components/Cell';
import { SecondaryRow } from '../../../../components/Table';

import { IconToLinkedEntity } from '../../../../assets';

import { generateUrl, routes, useParams } from '../../../../routes';

type SnapshotRowProps = {
  orderSnapshot: OrderSnapshot;
};

const OrderSnapshotRow = ({ orderSnapshot }: SnapshotRowProps) => {
  const { projectId } = useParams(routes.REPORTING);

  const iconAltText = useTxt('reporting.table.snapshot.order.link.alt');

  return (
    <SecondaryRow>
      <Cell align="center" />
      <Cell>{orderSnapshot.orderVisibleCode}</Cell>
      <Cell colSpan={3}>
        {orderSnapshot.orderName}
        {!orderSnapshot.orderIsDeleted ? (
          <>
            {' '}
            |{' '}
            <Link
              to={generateUrl({
                route: routes.ORDER,
                projectId,
                orderId: orderSnapshot.orderId,
                viewMode: 'comments',
              })}
            >
              <StyledImg src={IconToLinkedEntity} alt={iconAltText} />
            </Link>
          </>
        ) : null}
      </Cell>
      <Cell />
      <MoneyCell value={orderSnapshot.targetTotal} decimals={0} />
      <MoneyCell value={orderSnapshot.additionalTargetTotal} decimals={0} />
      <Cell align="right">
        <DeltaBigCostValue
          value={orderSnapshot.targetChangeBeforeLocking}
          decimals={0}
        />
      </Cell>
      <MoneyCell value={orderSnapshot.costPredictionTotal} decimals={0} />
      <Cell align="right">
        <DeltaBigCostValue
          value={orderSnapshot.predictionChangeBeforeLocking}
          decimals={0}
        />
      </Cell>
      <MoneyCell value={orderSnapshot.contractTotal} decimals={0} />
      <MoneyCell value={orderSnapshot.changeOrdersTotal} decimals={0} />
      <MoneyCell value={orderSnapshot.reservesTotal} decimals={0} />
      <Cell colSpan={3} />
    </SecondaryRow>
  );
};

const StyledImg = styled.img`
  padding-left: ${({ theme }) => theme.margin[8]};
  vertical-align: middle;
`;

export default OrderSnapshotRow;
