import { APIMultipleOrderRowsPostBody } from '../../../../../../types/api';
import { ID, ValidationErrorTextId } from '../../../../../../types/general';

import * as big from '../../../../../../utils/big';
import {
  isDefined,
  isNotNull,
  emptyOrderRow,
} from '../../../../../../utils/general';

import { validateField } from '../EditableOrderRow/utils';

export type ValueAndError = {
  value: string;
  validationError: ValidationErrorTextId | null;
};

// Undefined means that the row was not parseable
export type ParsedResultRow = {
  description: ValueAndError;
  quantity: ValueAndError;
  unit: ValueAndError;
  unitPrice: ValueAndError;
} | null;

// Removes white space and euro symbols
const tidyNumberField = (value: string): string => value.replace(/(\s|€)/g, '');

const isEmptyRow = (value: string): boolean =>
  value.replace(/\t/g, '').trim() === '';

export const parseOrderRows = (content: string): ParsedResultRow[] => {
  const rows = content.split(/\r?\n/);

  return (
    rows
      .map<ParsedResultRow | undefined>((row) => {
        if (isEmptyRow(row)) {
          return undefined;
        }

        const columns = row.split('\t');

        // If we have invalid amount of columns, let's indicate it explicitly with
        // null and render invalid row message to table.
        if (columns.length !== 4) {
          return null;
        }

        const description = columns[0].trim();
        const quantity = tidyNumberField(columns[1]);
        const unit = columns[2].trim();
        const unitPrice = tidyNumberField(columns[3]);

        return {
          description: {
            value: description,
            validationError: validateField(
              'description',
              description,
              undefined
            ),
          },
          quantity: {
            value: quantity,
            validationError: validateField('quantity', quantity, undefined),
          },
          unit: {
            value: unit,
            validationError: validateField('unit', unit, undefined),
          },
          unitPrice: {
            value: unitPrice,
            validationError: validateField('unitPrice', unitPrice, undefined),
          },
        };
      })
      // Let's filter out empty rows
      .filter(isDefined)
  );
};

export const everythingValid = (result: ParsedResultRow[]): boolean =>
  result.every(
    (row) =>
      isNotNull(row) &&
      row.description.validationError === null &&
      row.unit.validationError === null &&
      row.unitPrice.validationError === null &&
      row.quantity.validationError === null
  );

// Assumes that the rows are validated.
export const constructCreateOrderRowsRequest = (
  result: ParsedResultRow[],
  topicId: ID,
  orderId: ID
): APIMultipleOrderRowsPostBody => ({
  data: (result as NonNullable<ParsedResultRow>[]).map((row) => ({
    ...emptyOrderRow(orderId, topicId),
    description: row.description.value,
    quantity:
      row.quantity.value === ''
        ? null
        : big.fromInputString(row.quantity.value, 0).toString(),
    unit: row.unit.value,
    unitPrice:
      row.unitPrice.value === ''
        ? null
        : big.fromInputString(row.unitPrice.value, 0).toString(),
  })),
});
