import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk } from '../../../utils/thunk';

import { getPossibleAnalysisRowIdsforOrderRow } from '../../reducers/analysis/possibleOrderRowLinks';

const actionCreators = {
  ...makeAction('getPossibleAnalysisLinksForOrderRowStarted')<{
    orderRowId: string;
  }>(),
  ...makeAction('getPossibleAnalysisLinksForOrderRowFailure')<{
    orderRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getPossibleAnalysisLinksForOrderRowSuccess')<{
    orderRowId: string;
    analysisRowIds: string[];
  }>(),
};

export const {
  getPossibleAnalysisLinksForOrderRowStarted,
  getPossibleAnalysisLinksForOrderRowFailure,
  getPossibleAnalysisLinksForOrderRowSuccess,
} = actionCreators;

export type PossibleOrderRowLinkAction = ExtractActionTypes<
  typeof actionCreators
>;

const getPossibleAnalysisRowsForOrderRow = async (orderRowId: string) =>
  tPromise.decode(
    t.array(t.string),
    await GET(`v1/order-rows/${orderRowId}/valid-analysis-list-items`)
  );

export const requestPossibleAnalysisRowsForOrderRow = (orderRowId: string) =>
  createAsyncThunk(getPossibleAnalysisRowsForOrderRow, {
    args: [orderRowId],
    isPending: flow(
      getPossibleAnalysisRowIdsforOrderRow(orderRowId),
      remoteData.isLoading
    ),
    initialAction: getPossibleAnalysisLinksForOrderRowStarted({ orderRowId }),
    failureActionCreator: (error) =>
      getPossibleAnalysisLinksForOrderRowFailure({
        orderRowId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (analysisRowIds) =>
      getPossibleAnalysisLinksForOrderRowSuccess({
        orderRowId,
        analysisRowIds,
      }),
  });
