import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { getSelectableAnalysisRowsForOrderOrTargetRow } from '../../../store/reducers/analysis';
import { getAnalysisRow } from '../../../store/reducers/analysis/row';
import { getIsOuterBarOpen } from '../../../store/reducers/ui';

import { requestSelectableAnalysisRowsForOrderOrTargetRow } from '../../../store/actions';
import {
  createOrderRowAnalysisLinkage,
  removeOrderRowAnalysisLinkage,
} from '../../../store/actions/analysis/orderRowLinkage';
import { requestAnalysisRows } from '../../../store/actions/analysis/row';
import {
  createTargetRowAnalysisLinkage,
  removeTargetRowAnalysisLinkage,
} from '../../../store/actions/analysis/targetRowLinkage';

import useRemoteData from '../../../hooks/useRemoteData';

import {
  Tag,
  ShowMoreTag,
  PlusTag,
  StyledAnalysisLinker,
  EDIT_TAG_AMOUNT,
  VIEW_TAG_AMOUNT,
} from '../../../components/Analysis/AnalysisTags';

import { useParams, routes } from '../../../routes';

type AnalysisTagProps = {
  orderRowId?: string;
  targetRowId?: string;
  analysisListItemIds: string[];
};

const AnalysisTags = ({
  orderRowId,
  targetRowId,
  analysisListItemIds,
}: AnalysisTagProps) => {
  const { viewMode, projectId } = useParams(routes.ORDER);
  const [showAnalysisLinker, setShowAnalysisLinker] = React.useState(false);
  const showAmount = viewMode === 'edit' ? EDIT_TAG_AMOUNT : VIEW_TAG_AMOUNT;

  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const showMoreTag = analysisListItemIds.length > showAmount;
  const visibleTags = analysisListItemIds.slice(0, showAmount);

  const selector = getSelectableAnalysisRowsForOrderOrTargetRow({
    orderRowId,
    targetRowId,
    projectId,
  });

  const fetchData = requestSelectableAnalysisRowsForOrderOrTargetRow({
    orderRowId,
    targetRowId,
    projectId,
  });

  if (orderRowId === undefined && targetRowId === undefined) {
    return null;
  }

  return (
    <>
      {visibleTags.map((id) => (
        <AnalysisTag key={id} analysisRowId={id} compressed={outerBarOpen} />
      ))}
      {showMoreTag ? <ShowMoreTag compressed={outerBarOpen} /> : null}
      {viewMode !== 'edit' ? null : (
        <>
          <ButtonContainer>
            <PlusTag
              compressed={outerBarOpen}
              isPressed={false}
              onClick={() => setShowAnalysisLinker(!showAnalysisLinker)}
            />
            {showAnalysisLinker ? (
              <StyledAnalysisLinker
                linkedEntityId={orderRowId ?? (targetRowId as string)}
                existingLinks={analysisListItemIds}
                close={() => setShowAnalysisLinker(false)}
                selector={selector}
                fetchData={fetchData}
                linker={
                  orderRowId
                    ? createOrderRowAnalysisLinkage
                    : createTargetRowAnalysisLinkage
                }
                unlinker={
                  orderRowId
                    ? removeOrderRowAnalysisLinkage
                    : removeTargetRowAnalysisLinkage
                }
              />
            ) : null}
          </ButtonContainer>
        </>
      )}
    </>
  );
};

export default AnalysisTags;

type TagProps = {
  analysisRowId: string;
  compressed?: boolean;
};

export const AnalysisTag = ({ analysisRowId, compressed }: TagProps) => {
  const { projectId } = useParams(routes.ORDER);

  const code =
    useRemoteData(
      getAnalysisRow({ projectId, analysisRowId }),
      requestAnalysisRows({ projectId })
    )?.code ?? '';

  return <Tag compressed={compressed}>{code}</Tag>;
};

const ButtonContainer = styled.div`
  position: relative;
`;
