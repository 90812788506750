import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { getRevenueSummary } from '../../../store/reducers/revenue/revenue';

import RemoteData from '../../../components/RemoteData';
import Txt from '../../../components/Txt';

import * as RD from '../../../utils/remoteData';

type ContainerProps = {
  editMode: boolean;
};

const Container = styled.div<ContainerProps>`
  position: sticky;
  top: ${(props) => (props.editMode ? '73px' : '37px')};

  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: ${({
    theme: {
      color: { rowBorder },
    },
  }) => rowBorder};

  padding-right: ${({ theme: { margin } }) => margin[16]};
  padding-left: ${({ theme: { margin } }) => margin[16]};

  height: ${({ theme: { margin } }) => margin[32]};

  display: flex;
  align-items: center;

  background: white;

  z-index: 1;
`;

const Span = styled.span`
  margin-right: ${({ theme: { margin } }) => margin[32]};

  font-size: ${({
    theme: {
      fontSize: { small },
    },
  }) => small};
  line-height: 0.875rem;
  font-weight: 400;
  font-family: 'Graphik', sans-serif;
`;

interface SpanProps extends React.Attributes {
  'data-testid'?: string;
}

type EurosProps = {
  euros: RD.RemoteData<string>;
};

const Euros = ({ euros: data }: EurosProps) => (
  <RemoteData
    data={data}
    loadingElement={<Txt id="meta.loading" component="b" />}
    failureElement={<Txt id="meta.networkError" component="b" />}
  >
    {(euros) => <Txt id="meta.euros" values={{ euros }} component="b" />}
  </RemoteData>
);

type SummaryProps = {
  projectId: string;
  editMode: boolean;
};

const Summary = ({ projectId, editMode }: SummaryProps) => {
  const summary = useSelector(getRevenueSummary(projectId));
  const billedEuros = RD.map(summary, ({ billed }) => billed);
  const totalEuros = RD.map(summary, ({ total }) => total);
  const notBilledEuros = RD.map(summary, ({ notBilled }) => notBilled);

  return (
    <Container editMode={editMode}>
      <Txt
        id="revenue.summary.total"
        values={{ amount: <Euros euros={totalEuros} /> }}
        component={Span}
        props={{ 'data-testid': 'revenue-total' } as SpanProps}
      />
      <Txt
        id="revenue.summary.billed"
        values={{ amount: <Euros euros={billedEuros} /> }}
        component={Span}
        props={{ 'data-testid': 'revenue-billed' } as SpanProps}
      />
      <Txt
        id="revenue.summary.notBilled"
        values={{ amount: <Euros euros={notBilledEuros} /> }}
        component={Span}
        props={{ 'data-testid': 'revenue-not-billed' } as SpanProps}
      />
    </Container>
  );
};

export default Summary;
