import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import {
  getWorkPackageById,
  selectProjectWorkPackages,
} from '../../store/reducers/workPackage';

import * as Actions from '../../store/actions';

import { IconTextButton } from '../../components/Buttons';
import Header from '../../components/Header';
import TabIconTextButton from '../../components/Navigation/TabIconTextButton';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';

import CAN, {
  CaslWorkPackageRequestParams,
} from '../../utils/caslUserPermissions';
import { isSuccess } from '../../utils/remoteData';

import { IconArrowLeft, IconCogwheel } from '../../assets/svg';

import { generateUrl, routes, useParams } from '../../routes';
import EditWorkSectionModal from './EditWorkSection';
import SectionTable from './SectionTable';
import WorkSectionGraph from './WorkSectionGraph';

const WorkSectionExpanded = () => {
  const dispatch = useDispatch();
  const [editWorkPackage, setEditWorkPackage] = useState<boolean>(false);
  const [isRendered, setRendered] = useState<boolean>(false);
  const { workPackageId, projectId } = useParams(routes.WORKSECTION_EXPANDED);

  const workPackageRequestState = isSuccess(
    useSelector(selectProjectWorkPackages(projectId))
  );

  // Fetch project-related data here every time project or workPackage changes.
  useEffect(() => {
    dispatch(Actions.fetchWorkSectionRows({ projectId, workPackageId }));
    dispatch(Actions.fetchWorkPackagesForProject(projectId));
    dispatch(Actions.getWorkPackageTimelineForProject({ projectId }));
    dispatch(Actions.getWorkPackageGroupTimelineForProject({ projectId }));
    dispatch(Actions.getProjectTimelineForProject({ projectId }));
  }, [projectId, workPackageId, dispatch]);

  useLayoutEffect(() => {
    setRendered(true);
  }, []);

  const { code = '', name = '' } =
    useSelector(getWorkPackageById(workPackageId ?? '')) ?? {};

  const history = useHistory();

  const navigateBackToWorksectionList = () => {
    history.push(generateUrl({ route: routes.WORKSECTIONS, projectId }));
  };

  const ability = new CaslWorkPackageRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <Container>
      <BackButton
        icon={IconArrowLeft}
        onClick={() => navigateBackToWorksectionList()}
      >
        <Txt id="worksectionDetails.back" component="b" />
      </BackButton>
      <StyledHeader>
        <Txt id="worksectionDetails.header" values={{ code, name }} />
      </StyledHeader>
      <ProjectInfo projectId={projectId} />
      <ButtonContainer>
        <TabIconTextButton
          onClick={() => setEditWorkPackage(!editWorkPackage)}
          isActive={editWorkPackage}
          disabled={!allowedUser || !workPackageRequestState || !isRendered}
          icon={IconCogwheel}
          id="worksectionDetails.actionButton.info"
        />
      </ButtonContainer>
      {editWorkPackage ? (
        <EditWorkSectionModal
          onClose={() => setEditWorkPackage(!editWorkPackage)}
          workPackageId={workPackageId}
          headerInfo={{ code, name }}
        />
      ) : null}
      <WorkSectionGraph workPackageId={workPackageId} />
      <StyledTitle>
        <Txt id="navigation.project" />
      </StyledTitle>
      <SectionTable projectId={projectId} workPackageId={workPackageId} />
    </Container>
  );
};

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]};
`;

const StyledTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.margin[24]};
  margin-left: 0;
  border-top: 1px solid ${({ theme }) => theme.color.rowBorder};
  padding-top: ${({ theme }) => theme.margin[24]};
`;

const ButtonContainer = styled.div`
  height: ${({ theme: { margin } }) => margin[40]};

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const BackButton = styled(IconTextButton)`
  margin-bottom: ${({ theme: { margin } }) => margin[8]};
`;

const StyledHeader = styled(Header)`
  margin-bottom: ${({ theme: { margin } }) => margin[8]};
`;

export default WorkSectionExpanded;
