import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik, FormikErrors } from 'formik';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';

import { APIProject, getProject } from '../../../../store/reducers/project';
import { getProjectPoCBasedOnPreviouslyPlannedEntries } from '../../../../store/reducers/schedule/projectTimeline';
import { getRequestState } from '../../../../store/reducers/schedule/workPackageTimeline';
import { getSnapshotCreateRequest } from '../../../../store/reducers/snapshot';

import { fetchProjects } from '../../../../store/actions/project';
import { getProjectTimelineForProject } from '../../../../store/actions/schedule/projectTimeline';
import { getWorkPackageGroupTimelineForProject } from '../../../../store/actions/schedule/workPackageGroupTimeline';
import { getWorkPackageTimelineForProject } from '../../../../store/actions/schedule/workPackageTimeline';
import { requestNewSnapshot } from '../../../../store/actions/snapshot';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../components/Buttons';
import TextInput from '../../../../components/Input/TextInput';
import { Spinner } from '../../../../components/Loading';
import Modal, {
  Header,
  HeaderText,
  Container,
  Content,
} from '../../../../components/Modal/Modal';
import RemoteData from '../../../../components/RemoteData';
import Tooltip from '../../../../components/Tooltip';
import Txt from '../../../../components/Txt';

import { IconRight } from '../../../../assets/svg';

import Table from './Table';
import TimelineView from './TimelineView';

type AddSnapshotModalProps = {
  projectId: string;
  onClose: () => void;
};

type FormValues = {
  description: string;
  percentageOfCompletionMethod: '1' | '2' | '3';
  snapshotTypeComment: string;
};

type VisualState = 'table' | 'timelines' | 'graph';

export const AddSnapshotModal = ({
  projectId,
  onClose,
}: AddSnapshotModalProps) => {
  const [requestId] = useState(v4());
  const [visualState, setState] = useState<VisualState>('table');
  const [width, setWidth] = React.useState(0);
  const dispatch = useDispatch();
  const requestState = useSelector(getSnapshotCreateRequest(requestId)).kind;
  const totals = useSelector(getProject(projectId));

  const workPackageTimelinerequestState = useSelector(
    getRequestState(projectId)
  );

  const PoCBasedOnPreviouslyPlanned =
    useRemoteData(
      getProjectPoCBasedOnPreviouslyPlannedEntries({ projectId }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const previouslyPlannedDisabled = PoCBasedOnPreviouslyPlanned.length === 0;

  const ref = React.useRef<HTMLDivElement | null>(null);
  const animationRef = React.useRef<number>();

  React.useEffect(() => {
    if (workPackageTimelinerequestState === 'NotAsked') {
      dispatch(getWorkPackageTimelineForProject({ projectId }));
    }
  }, [projectId, dispatch, workPackageTimelinerequestState]);

  React.useEffect(() => {
    animationRef.current = requestAnimationFrame(() => {
      if (ref.current) {
        setWidth(ref.current.scrollWidth);
      }
    });

    return () => cancelAnimationFrame(animationRef.current ?? 0);
  }, [visualState]);

  const isRequired = useTxt('form.inputError.isRequired');
  const maxLength = useTxt('form.inputError.maxLength');

  React.useEffect(() => {
    if (requestState === 'Success') {
      dispatch(getWorkPackageTimelineForProject({ projectId }));
      dispatch(getWorkPackageGroupTimelineForProject({ projectId }));
      dispatch(getProjectTimelineForProject({ projectId }));
      onClose();
    }
  }, [requestState, onClose, dispatch, projectId]);

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (values.description.length === 0) {
      errors.description = isRequired;
    }

    if (values.description === '') {
      errors.description = isRequired;
    }

    if (values.description.length > 200) {
      errors.description = maxLength;
    }

    if (
      values.snapshotTypeComment === '' &&
      values.percentageOfCompletionMethod !== '1'
    ) {
      errors.snapshotTypeComment = isRequired;
    }

    if (values.snapshotTypeComment.length > 200) {
      errors.snapshotTypeComment = maxLength;
    }

    return errors;
  };

  const sendRequest = (
    description: string,
    snapshotTypeId: string,
    snapshotTypeComment: string
  ) => {
    if (
      requestState === 'Failure' ||
      requestState === 'Loading' ||
      description.length === 0
    ) {
      return;
    }

    dispatch(
      requestNewSnapshot({
        requestId,
        description,
        projectId,
        snapshotTypeId,
        snapshotTypeComment,
      })
    );
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      description: '',
      percentageOfCompletionMethod: '1',
      snapshotTypeComment: '',
    },
    validate,
    onSubmit: (values) => {
      sendRequest(
        values.description,
        values.percentageOfCompletionMethod,
        values.snapshotTypeComment
      );
    },
    validateOnMount: true,
  });

  const pocActual = useTxt('reporting.addNewSnapshot.modal.poc.actual');
  const pocPlanned = useTxt('reporting.addNewSnapshot.modal.poc.planned');

  const pocPreviouslyPlanned = useTxt(
    'reporting.addNewSnapshot.modal.poc.previouslyPlanned'
  );

  const reasonText = useTxt('common.reason');

  const linkToPoCWorkSectionGraph = useTxt(
    'reporting.addNewSnapshot.modal.linkToPoCWorkSectionGraph'
  );

  const linkToPoCTable = useTxt(
    'reporting.addNewSnapshot.modal.linkToPoCTable'
  );

  const tipActual = useTxt('reporting.addNewSnapshot.modal.poc.actualTooltip');

  const tipPlanned = useTxt(
    'reporting.addNewSnapshot.modal.poc.plannedTooltip'
  );

  const tipPreviouslyPlanned = useTxt(
    'reporting.addNewSnapshot.modal.poc.previouslyPlannedTooltip'
  );

  const exceptionNoteText = useTxt(
    'reporting.addNewSnapshot.modal.poc.exceptionNote'
  );

  const renderVisual = (project: APIProject | undefined) => {
    switch (visualState) {
      case 'table':
        return (
          <Table
            project={project}
            selectedSnapshotTypeId={formik.values.percentageOfCompletionMethod}
            descriptionValue={formik.values.description}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            errorMessage={formik.errors.description}
          />
        );
      case 'timelines':
        return (
          <TimelineView
            project={project}
            selectedSnapshotTypeId={formik.values.percentageOfCompletionMethod}
          />
        );
      case 'graph':
        return null;
    }
  };

  return (
    <Modal onClose={onClose}>
      <RemoteData
        fetchData={fetchProjects()}
        data={totals}
        loadingElement={<Container />}
      >
        {(project) => {
          const projectCodeName = `${project?.code} ${project?.name}`;

          return (
            <ConditionalWidthContainer
              wide={visualState !== 'table'}
              ref={ref}
              width={width}
            >
              <StyledHeader>
                <HeaderText>
                  <Txt
                    id="reporting.addNewSnapshot.modal.header"
                    values={{ projectCodeName }}
                  />
                </HeaderText>
              </StyledHeader>

              <Content noMaxHeight>
                <StyledForm onSubmit={formik.handleSubmit}>
                  <Row>
                    <Column widthPercentage={visualState === 'table' ? 70 : 80}>
                      {renderVisual(project)}
                      <FieldDiv>
                        <Label>
                          <Tooltip
                            tip={tipActual}
                            className="compressed-tooltip"
                            place="top"
                          >
                            <StyledRadioInput
                              role="radio"
                              type="radio"
                              name="percentageOfCompletionMethod"
                              checked={
                                formik.values.percentageOfCompletionMethod ===
                                '1'
                              }
                              onChange={formik.handleChange}
                              value="1"
                            />
                            <RadioLabelSpan>{pocActual}</RadioLabelSpan>
                          </Tooltip>
                        </Label>
                        <Label>
                          <Tooltip
                            tipId="planned-tooltip"
                            tip={`${tipPlanned}\n \n${exceptionNoteText}`}
                            className="line-break-compressed-tooltip"
                            place="top"
                          >
                            <StyledRadioInput
                              role="radio"
                              type="radio"
                              name="percentageOfCompletionMethod"
                              checked={
                                formik.values.percentageOfCompletionMethod ===
                                '2'
                              }
                              onChange={formik.handleChange}
                              value="2"
                            />
                            <RadioLabelSpan>{pocPlanned}</RadioLabelSpan>
                          </Tooltip>
                          {formik.values.percentageOfCompletionMethod ===
                          '2' ? (
                            <TextInput
                              label={reasonText}
                              name="snapshotTypeComment"
                              flexContainer
                              value={formik.values.snapshotTypeComment}
                              onChange={formik.handleChange}
                              errorMessage={formik.errors.snapshotTypeComment}
                              paddingBottom="0"
                            />
                          ) : null}
                        </Label>
                        <Label>
                          <Tooltip
                            tipId="previouslyPlanned-tooltip"
                            tip={`${tipPreviouslyPlanned}\n \n${exceptionNoteText}`}
                            className="line-break-compressed-tooltip"
                            place="bottom"
                          >
                            <StyledRadioInput
                              role="radio"
                              type="radio"
                              name="percentageOfCompletionMethod"
                              checked={
                                formik.values.percentageOfCompletionMethod ===
                                '3'
                              }
                              disabled={previouslyPlannedDisabled}
                              onChange={formik.handleChange}
                              value="3"
                            />
                            <RadioLabelSpan>
                              {pocPreviouslyPlanned}
                            </RadioLabelSpan>
                          </Tooltip>
                          {formik.values.percentageOfCompletionMethod ===
                          '3' ? (
                            <TextInput
                              label={reasonText}
                              name="snapshotTypeComment"
                              flexContainer
                              value={formik.values.snapshotTypeComment}
                              onChange={formik.handleChange}
                              errorMessage={formik.errors.snapshotTypeComment}
                              paddingBottom="0"
                            />
                          ) : null}
                        </Label>
                      </FieldDiv>
                    </Column>
                    <Column widthPercentage={visualState === 'table' ? 30 : 20}>
                      {visualState === 'timelines' ? null : (
                        <GraphButton onClick={() => setState('timelines')}>
                          <span>{linkToPoCWorkSectionGraph}</span>
                          <IconRight />
                        </GraphButton>
                      )}
                      {visualState === 'table' ? null : (
                        <GraphButton onClick={() => setState('table')}>
                          <span>{linkToPoCTable}</span>
                          <IconRight />
                        </GraphButton>
                      )}
                    </Column>
                  </Row>
                  <StyledButtonGroup>
                    <SecondaryButton type="button" onClick={onClose}>
                      <Txt id="common.cancel" />
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <Spinner size="1rem" />
                      ) : (
                        <Txt id="reporting.addNewSnapshot.modal.submitButton" />
                      )}
                    </PrimaryButton>
                  </StyledButtonGroup>
                </StyledForm>
              </Content>
            </ConditionalWidthContainer>
          );
        }}
      </RemoteData>
    </Modal>
  );
};

type ContainerProps = {
  wide: boolean;
  width?: number;
};

const ConditionalWidthContainer = styled(Container)<ContainerProps>`
  transition: width 1s ease-in-out;
  ${(props) =>
    props.wide
      ? css`
          width: 80vw;
        `
      : css`
          width: ${props.width};
        `}
`;

const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};

  height: 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  align-self: stretch;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const GraphButton = styled.button`
  margin: ${(props) => props.theme.margin[8]};

  border: none;

  width: 200px;

  display: flex;
  justify-content: space-between;

  align-self: flex-end;

  background: white;

  cursor: pointer;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  padding-bottom: ${(props) => props.theme.margin[32]};
  padding-right: ${(props) => props.theme.margin[32]};
  padding-top: ${(props) => props.theme.margin[8]};

  width: 100%;

  justify-content: flex-end;
`;

type ColumnProps = {
  widthPercentage?: number;
};

const Column = styled.div<ColumnProps>`
  padding: 0 ${(props) => props.theme.margin[8]};

  width: ${(props) => props.widthPercentage ?? 50}%;
  min-width: 208px;

  display: flex;
  flex-direction: column;
  align-content: space-between;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Label = styled.label`
  height: ${(props) => props.theme.margin[36]};
  display: flex;
  align-items: center;
`;

const StyledRadioInput = styled.input`
  margin: ${(props) => `${props.theme.margin[4]}`};
  transform: scale(1.5, 1.5);
  &:checked {
    accent-color: ${(props) => `${props.theme.color.graphiteB48}`};
  }
`;

const RadioLabelSpan = styled.span`
  padding: ${(props) => `${props.theme.margin[8]}`};
  font-weight: normal;
`;

const FieldDiv = styled.div`
  padding: ${(props) => props.theme.margin[24]} 20px
    ${(props) => props.theme.margin[10]};
  display: flex;
  flex-direction: column;
`;
