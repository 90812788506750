import { Reducer } from 'redux';

import { ID } from '@customtypes/general';

import { APIPurchaseInvoiceAttachmentFile } from '../../types/api';

import { updateRecord } from '../../utils/general';

import { ActionTypes } from '../actionTypes';

export type InvoiceAttachmentFilesState = Record<
  ID,
  APIPurchaseInvoiceAttachmentFile
>;

const initialState: InvoiceAttachmentFilesState = {};

const invoiceHeaderAttachmentFilesReducer: Reducer<
  InvoiceAttachmentFilesState,
  ActionTypes
> = (state = initialState, action): InvoiceAttachmentFilesState => {
  switch (action.type) {
    case 'GET_INVOICE_ATTACHMENT_FILES_SUCCESS':
      return updateRecord(state, action.payload);
    case 'POST_INVOICE_ATTACHMENT_FILES_SUCCESS':
      return action.payload.purchaseInvoiceAttachmentFiles
        ? updateRecord(state, action.payload.purchaseInvoiceAttachmentFiles)
        : state;
    default:
      return state;
  }
};

export default invoiceHeaderAttachmentFilesReducer;
