import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk } from '../../../utils/thunk';

import { getPossibleAnalysisRowIdsforTargetRow } from '../../reducers/analysis/possibleTargetRowLinks';

const actionCreators = {
  ...makeAction('getPossibleAnalysisLinksForTargetRowStarted')<{
    targetRowId: string;
  }>(),
  ...makeAction('getPossibleAnalysisLinksForTargetRowFailure')<{
    targetRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getPossibleAnalysisLinksForTargetRowSuccess')<{
    targetRowId: string;
    analysisRowIds: string[];
  }>(),
};

export const {
  getPossibleAnalysisLinksForTargetRowStarted,
  getPossibleAnalysisLinksForTargetRowFailure,
  getPossibleAnalysisLinksForTargetRowSuccess,
} = actionCreators;

export type PossibleTargetRowLinkAction = ExtractActionTypes<
  typeof actionCreators
>;

const getPossibleAnalysisRowsForTargetRow = async (targetRowId: string) =>
  tPromise.decode(
    t.array(t.string),
    await GET(`v1/target-rows/${targetRowId}/valid-analysis-list-items`)
  );

export const requestPossibleAnalysisRowsForTargetRow = (targetRowId: string) =>
  createAsyncThunk(getPossibleAnalysisRowsForTargetRow, {
    args: [targetRowId],
    isPending: flow(
      getPossibleAnalysisRowIdsforTargetRow(targetRowId),
      remoteData.isLoading
    ),
    initialAction: getPossibleAnalysisLinksForTargetRowStarted({ targetRowId }),
    failureActionCreator: (error) =>
      getPossibleAnalysisLinksForTargetRowFailure({
        targetRowId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (analysisRowIds) =>
      getPossibleAnalysisLinksForTargetRowSuccess({
        targetRowId,
        analysisRowIds,
      }),
  });
