import React from 'react';
import FocusLock from 'react-focus-lock';

import styled from 'styled-components';

import { Selector } from '../../store/reducers/utils';

import { AnalysisRow } from '../../store/actions';
import { RevenueLinkRequest } from '../../store/actions/analysis/revenueLinkage';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import useTxt from '../../hooks/useTxt';

import * as remoteData from '../../utils/remoteData';
import { Thunk } from '../../utils/thunk';

import { IconCloseBlack } from '../../assets/svg';

import { IconButton } from '../Buttons';
import AnalysisRowList from './AnalysisList';

type AnalysisLinkerProps = {
  close: () => void | undefined;
  linkedEntityId: string;
  existingLinks: string[];
  selector: Selector<remoteData.RemoteData<AnalysisRow[]>>;
  fetchData: Thunk;
  linker: (params: RevenueLinkRequest) => Thunk;
  unlinker: (params: RevenueLinkRequest) => Thunk;
};

const AnalysisLinker = ({
  close,
  linkedEntityId,
  existingLinks,
  selector,
  fetchData,
  linker,
  unlinker,
  ...focusLockProps
}: AnalysisLinkerProps & React.ComponentProps<typeof FocusLock>) => {
  const ref = useOnClickOutside(() => {
    close();
  });

  const [filterText, setFilterText] = React.useState('');

  const placeholder = useTxt('component.AnalysisLiker.filter.placeholder');

  return (
    <Container ref={ref} {...focusLockProps}>
      <CloseButton icon={IconCloseBlack} onClick={close} />
      <FilterTextInput
        name="filter"
        id="filter"
        placeholder={placeholder}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <AnalysisRowList
        filter={filterText}
        linkedEntityId={linkedEntityId}
        existingLinks={existingLinks}
        // close={close}
        selector={selector}
        fetchData={fetchData}
        linker={linker}
        unlinker={unlinker}
      />
    </Container>
  );
};

const Container = styled(FocusLock)`
  border: 1px solid ${(props) => props.theme.color.graphiteB76};
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  width: 22rem;

  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.color.white};

  color: ${(props) => props.theme.color.pitch};
`;

const CloseButton = styled(IconButton)`
  margin: ${(props) => props.theme.margin[10]};
  align-self: flex-end;
`;

const FilterTextInput = styled.input`
  margin-left: ${(props) => props.theme.margin[16]};
  margin-right: ${(props) => props.theme.margin[16]};
  margin-bottom: ${(props) => props.theme.margin[20]};

  border-radius: 4px;
  border: 1px solid ${({ theme: { color } }) => `${color.graphiteB91}`};

  padding: 0 1rem;

  min-height: 2.5rem;

  background: ${({ theme: { color } }) => `${color.graphiteB96A}`};

  color: ${({ theme: { color } }) => `${color.pitch}`};
  font-family: 'Graphik', sans-serif;
  font-size: 0.875;

  ::placeholder {
    opacity: 0.5;
  }
`;

export default AnalysisLinker;
