import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import {
  getWorkPackageTimelineForProject,
  getWorkPackageGroupTimelineForProject,
  getProjectTimelineForProject,
} from '../../store/actions';

import Header from '../../components/Header';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';
import WorksectionTable from './components/WorksectionTable';

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]};
`;

const Worksection = () => {
  type RouteParams = {
    projectId: string;
  };

  const dispatch = useDispatch();

  const { projectId } = useParams<RouteParams>();

  // Fetch project-related timeline data here every time project changes / user re-enters view.
  React.useEffect(() => {
    dispatch(getWorkPackageTimelineForProject({ projectId }));
    dispatch(getWorkPackageGroupTimelineForProject({ projectId }));
    dispatch(getProjectTimelineForProject({ projectId }));
  }, [projectId, dispatch]);

  return (
    <Container>
      <Header>
        <Txt id="worksection.header" />
      </Header>
      <ProjectInfo projectId={projectId} />
      <WorksectionTable projectId={projectId} />
    </Container>
  );
};

export default Worksection;
