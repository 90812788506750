import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import App from './App';
import LocaleWrapper from './context/locale';
import { store } from './store';
import GlobalStyle from './styles/global';
import theme from './styles/theme';

import './styles/fonts.css';
import './styles/normalize.css';

const app = (
  <Provider store={store}>
    <LocaleWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </ThemeProvider>
    </LocaleWrapper>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
