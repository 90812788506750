import { APIOrderRow } from '../../../../../types/api';
import { ValidationErrorTextId } from '../../../../../types/general';

import * as big from '../../../../../utils/big';

import {
  calculateRemainingQuantity,
  calculateExcessQuantity,
} from '../receiveModeUtils';

export enum ValidationAction {
  NONE,
  INCREASE_AMOUNT,
}

type ValidationResult = {
  action: ValidationAction;
  message: ValidationErrorTextId;
  values?: Record<string, string | number>;
} | null;

export const validate = (
  value: string,
  orderRow: APIOrderRow
): ValidationResult => {
  if (value === '' || orderRow.unitPrice === null) {
    return null;
  }

  try {
    const valueAsBig = big.fromInputString(value);

    if (!big.hasMaxNWholeNumbers(valueAsBig, 12) && value.startsWith('-')) {
      return {
        action: ValidationAction.NONE,
        message: 'validation.hasMaxNWholeNumbersNegative',
      };
    }

    if (!big.hasMaxNWholeNumbers(valueAsBig, 12)) {
      return {
        action: ValidationAction.NONE,
        message: 'validation.hasMaxNWholeNumbersPositive',
      };
    }

    if (!big.hasMaxNDecimals(valueAsBig, 4)) {
      return {
        action: ValidationAction.NONE,
        message: 'validation.hasMaxNDecimals',
      };
    }

    // Receiving 0 is not an error, even though it's quite dumb :D
    if (valueAsBig.eq(0)) {
      return null;
    }

    const unit = orderRow.unit === '' ? '' : ` ${orderRow.unit}`;

    const quantityLeft = calculateRemainingQuantity(orderRow);
    const excessQuantity = calculateExcessQuantity(orderRow, valueAsBig);

    if (!excessQuantity.eq(0)) {
      const changeAmountEur = big.priceFormat(
        excessQuantity.mul(orderRow.unitPrice)
      );

      const message = 'validation.excessQuantity';

      return {
        action: ValidationAction.INCREASE_AMOUNT,
        message,
        values: {
          quantityAndUnit: `${big.amountFormat(quantityLeft)}${unit}`,
          excessQuantityAndUnit: `${big.amountFormat(excessQuantity)}${unit}`,
          changeAmountEur,
        },
      };
    }

    // We shouldn't reach this point if quantity is null and there's a value. If
    // value has content and we reach here with null quantity, that should be an
    // error.
    if (orderRow.quantity === null) {
      return {
        action: ValidationAction.NONE,
        message: 'validation.emptyAmount',
      };
    }

    const negativeQuantity = orderRow.quantity.lt(0);

    // Do not allow the user to enter a negative (or in negative's case, positive)
    // arrival amount that would undo more than what has been previously marked
    // as arrived.
    if (
      (negativeQuantity && valueAsBig.gt(-orderRow.arrivalQuantity)) ||
      (!negativeQuantity && valueAsBig.lt(-orderRow.arrivalQuantity))
    ) {
      return {
        action: ValidationAction.NONE,
        message: 'validation.maximumChange',
        values: {
          arrivalQuantityAndUnit: `${-orderRow.arrivalQuantity}${unit}`,
        },
      };
    }
  } catch (err) {
    return {
      action: ValidationAction.NONE,
      message: 'validation.number.error',
    };
  }

  return null;
};
