import { SelectOption as Column } from '../types/ui';

export const loadProjectColumns = (): Column[] | undefined => {
  try {
    const defaultColumns = projColumns;
    const projectColumns = localStorage.getItem('projectColumns');

    if (projectColumns === null) {
      // can continue reading default column settings
      return undefined;
    }

    const parsedColumns = JSON.parse(projectColumns) as Column[] | undefined;

    if (parsedColumns && defaultColumns.length !== parsedColumns.length) {
      // if new columns are added, don't use old settings
      // add new columns to the end

      const newColumns = defaultColumns.reduce((acc, column) => {
        const previousSetting = parsedColumns.find((c) => c.id === column.id);

        if (previousSetting === undefined) {
          return [...acc, column];
        }

        return [...acc, previousSetting];
      }, [] as Column[]);

      return newColumns;
    }

    return parsedColumns;
  } catch (error) {
    // console.log(error);
    return undefined;
  }
};

export const saveProjectColumns = (columnSettings: Column[]): void => {
  try {
    const projectColumns = JSON.stringify(columnSettings);
    localStorage.setItem('projectColumns', projectColumns);
  } catch (error) {
    // console.log(error);
  }
};

export const loadWorkSectionViewColumns = (): Column[] | undefined => {
  try {
    const defaultColumns = workSectionColumns;

    const workSectionViewColumns = localStorage.getItem(
      'workSectionViewColumns'
    );

    if (workSectionViewColumns === null) {
      // can continue reading default column settings
      return undefined;
    }

    const parsedColumns = JSON.parse(workSectionViewColumns) as
      | Column[]
      | undefined;

    if (parsedColumns && defaultColumns.length !== parsedColumns.length) {
      // if new columns are added, don't use old settings
      // add new columns to the end

      const newColumns = defaultColumns.reduce((acc, column) => {
        const previousSetting = parsedColumns.find((c) => c.id === column.id);

        if (previousSetting === undefined) {
          return [...acc, column];
        }

        return [...acc, previousSetting];
      }, [] as Column[]);

      return newColumns;
    }

    return parsedColumns;
  } catch (error) {
    // console.log(error);
    return undefined;
  }
};

export const saveWorkSectionViewColumns = (columnSettings: Column[]): void => {
  try {
    const workSectionViewColumns = JSON.stringify(columnSettings);
    localStorage.setItem('workSectionViewColumns', workSectionViewColumns);
  } catch (error) {
    // console.log(error);
  }
};

export interface ColumnType extends Column {
  width?: string;
  relativeWidth?: number;
  align?: 'left' | 'right' | 'center';
  numberStyling?:
    | 'empty'
    | 'price'
    | 'priceDelta'
    | 'percentage'
    | 'percentageDelta'
    | 'profitDelta';
}

export const projColumns: ColumnType[] = [
  {
    id: '1',
    name: 'common.code',
    selected: true,
    disabled: true,
    width: '4rem',
  },
  {
    id: '2',
    name: 'common.description',
    selected: true,
    disabled: true,
    width: 'auto',
  },
  {
    id: '3',
    name: 'project.procurementTable.order.target',
    selected: true,
    tooltipInfo: 'project.tooltip.target',
    disabled: true,
  },
  {
    id: '15',
    name: 'project.procurementTable.order.additionalTarget',
    selected: false,
    tooltipInfo: 'project.tooltip.additionalTarget',
  },
  {
    id: '4',
    name: 'project.procurementTable.order.differenceToTarget',
    selected: false,
    tooltipInfo: 'project.tooltip.differenceToTarget',
  },
  {
    id: '5',
    name: 'project.procurementTable.order.prediction',
    selected: true,
    tooltipInfo: 'project.tooltip.costPlan',
    disabled: true,
    width: '7rem',
  },
  {
    id: '6',
    name: 'project.procurementTable.order.difference',
    selected: true,
    tooltipInfo: 'project.tooltip.differenceToReported',
  },
  {
    id: '7',
    name: 'project.procurementTable.order.contractTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.contract',
    width: '7rem',
  },
  {
    id: '8',
    name: 'project.procurementTable.order.changeOrdersTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.changeOrders',
    width: '8rem',
  },
  {
    id: '9',
    name: 'project.procurementTable.order.reserves',
    selected: true,
    tooltipInfo: 'project.tooltip.reserves',
  },
  {
    id: '11',
    name: 'project.procurementTable.order.receivedTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.accepted',
  },
  {
    id: '12',
    name: 'common.remainingAmount',
    selected: true,
    tooltipInfo: 'project.tooltip.remaining',
    width: '6.5rem',
  },
  {
    id: '13',
    name: 'common.unsettled',
    selected: false,
    tooltipInfo: 'project.tooltip.unsettled',
    width: '6rem',
  },
  {
    id: '14',
    name: 'common.status',
    selected: true,
    tooltipInfo: 'project.tooltip.status',
  },
];

export const workSectionColumns: ColumnType[] = [
  {
    id: '1',
    name: 'worksection.table.header.code',
    selected: true,
    disabled: true,
    relativeWidth: 1.5,
    width: '6rem',
    numberStyling: 'empty',
    align: 'left',
  },
  {
    id: '2',
    name: 'worksection.table.header.name',
    selected: true,
    disabled: true,
    align: 'left',
    relativeWidth: 4,
    width: 'auto',
    numberStyling: 'empty',
  },
  {
    id: '3',
    name: 'worksection.table.header.percentageOfCompletion',
    selected: true,
    align: 'right',
    relativeWidth: 1.5,
    width: '7rem',
    numberStyling: 'percentage',
    tooltipInfo: 'project.tooltip.PoC',
  },
  {
    id: '4',
    name: 'worksection.table.header.targetTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.target',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'price',
  },
  {
    id: '14',
    name: 'worksection.table.header.additionalTargetTotal',
    selected: false,
    tooltipInfo: 'project.tooltip.additionalTarget',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'price',
  },
  {
    id: '5',
    name: 'worksection.table.header.differenceToTarget',
    selected: false,
    tooltipInfo: 'project.tooltip.differenceToTarget',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'profitDelta',
  },
  {
    id: '6',
    name: 'worksection.table.header.predictionTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.costPlan',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'price',
  },
  {
    id: '7',
    name: 'worksection.table.header.predictionChangeFromLatest',
    selected: true,
    tooltipInfo: 'project.tooltip.differenceToReported',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'priceDelta',
  },
  {
    id: '8',
    name: 'worksection.table.header.contractTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.contract',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'price',
  },
  {
    id: '9',
    name: 'worksection.table.header.changeOrdersTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.changeOrders',
    align: 'right',
    relativeWidth: 2,
    width: '8rem',
    numberStyling: 'price',
  },
  {
    id: '10',
    name: 'worksection.table.header.reservesTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.reserves',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'price',
  },
  {
    id: '11',
    name: 'worksection.table.header.receivedTotal',
    selected: true,
    tooltipInfo: 'project.tooltip.accepted',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'price',
  },
  {
    id: '12',
    name: 'worksection.table.header.remaining',
    selected: true,
    tooltipInfo: 'project.tooltip.remaining',
    align: 'right',
    relativeWidth: 2,
    width: '7rem',
    numberStyling: 'price',
  },
  {
    id: '13',
    name: 'worksection.table.header.none',
    selected: true,
    disabled: true,
    align: 'left',
    relativeWidth: 1.5,
    width: '7rem',
    numberStyling: 'empty',
  },
];

const getTotalWidth = (columns: ColumnType[]) => {
  return columns.reduce(
    (acc, { relativeWidth }) => acc + (relativeWidth ?? 0),
    0
  );
};

export const getWidths = (columns: ColumnType[]) => (id: string) => {
  const totalWidth = getTotalWidth(columns);

  const relativeWidth = columns.find((column) => column.id === id)
    ?.relativeWidth;

  return Math.round(100 * (relativeWidth ?? 0 / totalWidth));
};
