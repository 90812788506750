type RecordKey = string | symbol | number;
type PartialRecord<K extends RecordKey, V> = Partial<Record<K, V>>;

export default function normalizeBy<
  K extends RecordKey,
  A extends Record<K, any>,
  Key extends K
>(k: A[Key] extends RecordKey ? Key : never, xs: A[]): Record<A[Key], A> {
  return xs.reduce(
    (acc: PartialRecord<A[Key], A>, x) => ({
      ...acc,
      [x[k]]: x,
    }),
    {}
  );
}
