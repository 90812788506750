import React from 'react';
// import { Link } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { ScheduledTask } from '../../../store/actions';

import { BigPercentageValue } from '../../../components/BigValue';
import Cell from '../../../components/Cell';

import { dateFormat } from '../../../utils/format';

// TODO: enable when link is ready

// import { IconToLinkedEntity } from '../../../assets';

interface TreeNodeProps {
  scheduleTask: ScheduledTask;
  hasChildren: boolean;
}

const ConnectedTaskRow = ({ scheduleTask, hasChildren }: TreeNodeProps) => {
  const {
    name,
    level,
    plannedEndTime,
    plannedStartTime,
    taskCompletionPercentage: poc,
    workAmount,
  } = scheduleTask;

  const amount = Math.round(Number(workAmount)) || '';

  return (
    <StyledTableRow>
      <StyledCell depth={level} hasChildren={hasChildren}>
        {name}
      </StyledCell>
      <Cell align="center">{amount}</Cell>
      <Cell align="center">
        {dateFormat.format(new Date(plannedStartTime ?? ''))}
      </Cell>
      <Cell align="center">
        {dateFormat.format(new Date(plannedEndTime ?? ''))}
      </Cell>
      <Cell align="center">
        <BigPercentageValue value={new Big(Number(poc))} decimals={0} />
      </Cell>
    </StyledTableRow>
  );
};

type RowProps = {
  depth?: number;
  isFirstChild?: boolean;
  hasChildren?: boolean;
};

const StyledTableRow = styled.tr`
  /* stylelint-disable selector-max-type  -- disable temporary until good solution is found */

  td {
    margin-top: 1rem;
  }
`;

const StyledCell = styled.td<RowProps>`
  padding-left: ${(props) => (props.depth === 0 ? 0.75 : props.depth)}rem;
  display: flex;
  font-weight: ${({ hasChildren }) => (hasChildren ? 600 : 'normal')};
  gap: 0.5rem;
`;

export default ConnectedTaskRow;
