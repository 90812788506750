import React from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';

import { getActualCostsByIds } from '../../../../store/reducers/actualCost';
import { getArrivalsByIds } from '../../../../store/reducers/arrival';
import { getArrivalRowsByOrderRowId } from '../../../../store/reducers/arrivalRow';
import { getInvoiceHeadersByIds } from '../../../../store/reducers/invoiceHeader';

import { Table } from '../../../../components/Table';

import { isDefined, isNotNull } from '../../../../utils/general';

import InvoiceRow from './InvoiceRow';
import TableFooter from './TableFooter';
import { TableHeader } from './TableHeader';

type TableProps = {
  orderRowId: string;
  onClose: () => void;
};

export const InvoiceTable = ({ orderRowId, onClose }: TableProps) => {
  const arrivalRows = useSelector(getArrivalRowsByOrderRowId(orderRowId));

  const invoiceHeaderIds = arrivalRows
    .map((row) => row.purchaseInvoiceHeaderId)
    .filter(isDefined)
    .filter(isNotNull);

  const actualCostIds = arrivalRows
    .map((row) => row.actualCostId)
    .filter(isDefined)
    .filter(isNotNull);

  const arrivalIds = arrivalRows
    .map((row) => row.arrivalId)
    .filter(isDefined)
    .filter(isNotNull);
  const invoiceHeaders = useSelector(getInvoiceHeadersByIds(invoiceHeaderIds));
  const actualCosts = useSelector(getActualCostsByIds(actualCostIds));
  const arrivals = useSelector(getArrivalsByIds(arrivalIds));

  const arrivalsTotal = arrivalRows.reduce((previous, row) => {
    const arrivalTotal = row.quantity.mul(row.unitPrice);

    return previous.add(arrivalTotal);
  }, new Big(0));

  return (
    <Table>
      <TableHeader />
      <tbody>
        {arrivalRows.map((arrivalRow) => {
          const total =
            arrivalRow.quantity.mul(arrivalRow.unitPrice) ?? new Big(0);

          const actualCost = actualCosts.find(
            (cost) => cost.id === arrivalRow.actualCostId
          );

          const invoiceHeader = invoiceHeaders.find(
            (header) => header.id === arrivalRow.purchaseInvoiceHeaderId
          );

          const arrival = arrivals.find(
            (item) => item.id === arrivalRow.arrivalId
          );
          const userName = arrival?.userName ?? '';

          return (
            <InvoiceRow
              key={`invoice-per-order-row-${arrivalRow.id}`}
              cost={invoiceHeader ?? actualCost}
              arrival={arrival}
              arrivalsTotal={total}
              userName={userName}
              onClose={onClose}
            />
          );
        })}
        <TableFooter arrivalsTotal={arrivalsTotal} />
      </tbody>
    </Table>
  );
};
