import React from 'react';

import styled from 'styled-components';

import { getScheduledTasksEntriesForWorkPackageId } from '../../../store/reducers/schedule/scheduledTasks';

import { getScheduledTasksForWorkpackage } from '../../../store/actions/schedule/scheduledTasks';

import useRemoteData from '../../../hooks/useRemoteData';

import Modal, {
  Content,
  Header,
  Container,
} from '../../../components/Modal/Modal';
import Txt from '../../../components/Txt';

import EditWorkPackageForm from './EditWorkPackageForm';
import ScheduleTree from './ScheduleTree';

interface EditWorkPackageProps {
  onClose: () => void;
  workPackageId: string;
  headerInfo: { code: string; name: string };
}

const EditWorkSectionModal = ({
  onClose,
  workPackageId,
  headerInfo,
}: EditWorkPackageProps) => {
  const scheduleTasks =
    useRemoteData(
      getScheduledTasksEntriesForWorkPackageId(workPackageId),
      getScheduledTasksForWorkpackage({ workPackageId })
    ) ?? [];

  return (
    <Modal onClose={onClose}>
      <StyledContainer>
        <Header>
          <Txt
            id="worksection.workpackage.editModal.header"
            values={headerInfo}
          />
        </Header>
        <StyledContent noMaxHeight>
          <EditWorkPackageForm
            workPackageId={workPackageId}
            onClose={onClose}
            hasScheduledTasks={scheduleTasks.length > 0}
          />
          <ScheduleTree scheduleTasks={scheduleTasks} />
        </StyledContent>
      </StyledContainer>
    </Modal>
  );
};

export default EditWorkSectionModal;

const StyledContainer = styled(Container)`
  min-width: 67rem;
`;

const StyledContent = styled(Content)`
  padding-right: ${(props) => `${props.theme.margin[32]}`};
`;
