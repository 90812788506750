import React from 'react';
import { useDispatch } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import {
  TargetRow,
  toggleTargetRowSelection,
} from '../../../../../store/actions';

import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import { IconButton } from '../../../../../components/Buttons';
import Cell, { MoneyCell } from '../../../../../components/Cell';
import { BaseRow } from '../../../../../components/Table';

import * as big from '../../../../../utils/big';

import { IconSuffix } from '../../../../../assets';
import { IconCloseBlack } from '../../../../../assets/svg';

import { SuffixCell, SuffixCircle } from '../TargetedRow';

type TargetRowToBeSplitProps = {
  row: TargetRow;
  removeAllRows: (id: string) => void;
};

export const TargetRowToBeSplit = ({
  row,
  removeAllRows,
}: TargetRowToBeSplitProps) => {
  const { orderId } = useRouteParams();
  const dispatch = useDispatch();

  const removeText = useTxt('order.inputs.Remove');
  const suffixAlt = useTxt('target.table.alt.suffix');

  const onDeleteTargetRow = (targetRowId: string) => {
    dispatch(toggleTargetRowSelection({ orderId, targetRowId }));
    removeAllRows(targetRowId);
  };

  return (
    <Tr>
      {row.orderRowId ? (
        <SuffixCell align="center">
          <SuffixCircle>
            <img src={IconSuffix} alt={suffixAlt} />
          </SuffixCircle>
        </SuffixCell>
      ) : null}
      <StyledCell colSpan={row.orderRowId ? 1 : 2}>
        {row.description}
      </StyledCell>
      <StyledCell align="right">
        {big.amountFormat(row.quantity || new Big(0), 4)}
      </StyledCell>
      <StyledCell align="left">{row.unit}</StyledCell>
      <StyledMoneyCell value={row.unitPrice || new Big(0)} decimals={4} />
      <StyledCell colSpan={3} />
      <StyledMoneyCell value={row.totalPrice} decimals={2} />
      <StyledCell align="center">
        <IconButton
          icon={IconCloseBlack}
          onClick={() => onDeleteTargetRow(row.id)}
          aria-label={removeText}
        />
      </StyledCell>
    </Tr>
  );
};

const Tr = styled(BaseRow)`
  background: ${({
    theme: {
      color: { whisper },
    },
  }) => whisper};
`;

const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;
