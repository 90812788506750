import React from 'react';

import { css } from 'styled-components';

import theme from './theme';

export const additionalStyle: React.CSSProperties = {
  height: theme.margin[36],
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[8]}`,
};

export const fontSizeCss = (depth: number | undefined) => {
  switch (depth) {
    case 1:
      return css`
        height: 2.5rem;
        font-size: 1.125rem;
      `;
    case 2:
      return css`
        height: 2.5rem;
        font-size: 1rem;
      `;
    case 3:
      return css`
        height: 2.5rem;
        font-size: 0.875rem;
      `;
    case 4:
      return css`
        height: 2rem;
        font-size: 0.75rem;
      `;
    default:
      return css`
        height: 1.5rem;
        font-size: 0.625rem;
      `;
  }
};

export const paddingLeftCss = (depth: number | undefined) => {
  switch (depth) {
    case 1:
      return css``;
    case 2:
      return css`
        margin-left: ${theme.margin[2]};
      `;
    case 3:
      return css`
        margin-left: ${theme.margin[4]};
      `;
    case 4:
      return css`
        margin-left: ${theme.margin[8]};
      `;
    default:
      return css`
        margin-left: ${theme.margin[10]};
      `;
  }
};
