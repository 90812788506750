import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import { Form, Formik, useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';
import * as yup from 'yup';

import { getRequestState } from '../../../../../store/reducers/target/targetRows';
import { getOrderTopics } from '../../../../../store/reducers/topic';
import { DraftTargetRow } from '../../../../../store/reducers/ui';

import {
  fetchTopicsForOrder,
  requestNewTargetRows,
} from '../../../../../store/actions';

import useRemoteData from '../../../../../hooks/useRemoteData';
import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import {
  SecondaryButton,
  PrimaryButton,
  IconTextButton,
} from '../../../../../components/Buttons';
import Modal, {
  Content,
  Header,
  Footer,
} from '../../../../../components/Modal/Modal';
import { Table } from '../../../../../components/Table';
import Txt from '../../../../../components/Txt';

import * as big from '../../../../../utils/big';

import { IconPlus } from '../../../../../assets/svg';

import { TableHeader } from './TableHeader';
import { TargetRow } from './TargetRow';

type FormValueShape = { rows: DraftTargetRow[] };

type AddTargetRowsModalProps = {
  onClose: () => void | undefined;
};

export const AddTargetRowsModal = ({ onClose }: AddTargetRowsModalProps) => {
  const { orderId } = useRouteParams();
  const dispatch = useDispatch();
  const [requestId] = useState(v4());
  const MAX_CREATABLE_ROWS = 10;

  const requestState = useSelector(getRequestState(requestId));

  // fetch topics so default topic can be pre-selected in draft row
  const topics =
    useRemoteData(getOrderTopics(orderId), fetchTopicsForOrder(orderId)) ?? [];

  const defaultTopicId =
    topics.find(({ defaultTopic }) => defaultTopic)?.id ?? '';

  const removeRow = (
    values: FormValueShape,
    setValues: (values: React.SetStateAction<FormValueShape>) => void,
    index: number
  ) => {
    const newValues = cloneDeep(values);
    newValues.rows.splice(index, 1);

    setValues({ rows: newValues.rows });
  };

  const addNewTargetRow = (
    values: FormValueShape,
    setValues: (values: React.SetStateAction<FormValueShape>) => void
  ) => {
    // get the last row's dropdown values and use those as a base
    const { orderId: rowOrderId, topicId, analysisId } = values.rows.slice(
      -1
    )[0];

    const newRow = {
      rowId: v4(),
      orderId: rowOrderId,
      topicId,
      description: '',
      quantity: '',
      unit: '',
      unitPrice: '',
      analysisId,
      createAlsoOrder: false,
    };

    setValues({ rows: [...values.rows, newRow] });
  };

  const sendRequest = (values: FormValueShape) => {
    // a bit of ugliness here to avoid calling Big constructor with an empty string
    const data = values.rows.map((row) => ({
      ...row,
      quantity:
        row.quantity !== ''
          ? big.fromInputString(row.quantity, '').toString()
          : '',
      unitPrice:
        row.unitPrice !== ''
          ? big.fromInputString(row.unitPrice, '').toString()
          : '',
    }));

    dispatch(requestNewTargetRows({ requestId, data }));
  };

  // observe request state: close modal when POST succeeds
  useEffect(() => {
    if (requestState === 'Success') {
      onClose();
    }
  }, [requestState, onClose]);

  const titleText = useTxt('order.addTargetRowsModal.title');
  const requiredFieldText = useTxt('order.addTargetRowsModal.requiredField');

  /* eslint-disable react/no-this-in-sfc */
  yup.addMethod(
    yup.string,
    'formatBigInt',
    function formatBigInt(message: string = 'not a valid number') {
      return this.test('formatBigInt', message, (value: string | undefined) => {
        if (value === undefined) {
          return true;
        }

        try {
          big.fromInputString(value);

          return true;
        } catch {
          return false;
        }
      });
    }
  );
  /* eslint-enable react/no-this-in-sfc */

  const arrayValidationSchema = yup
    .object()
    .shape({
      rows: yup.array().of(
        yup
          .object()
          .shape({
            description: yup.string(),
            quantity: yup.string().formatBigInt(),
            unit: yup.string(),
            unitPrice: yup.string().formatBigInt(),
            orderId: yup.string().required(requiredFieldText),
            topicId: yup.string().required(requiredFieldText),
            analysisId: yup.string(),
            createAlsoOrder: yup.boolean().required().default(false),
          })
          .required()
      ),
    })
    .required();

  const blankRow = {
    rowId: v4(),
    description: '',
    quantity: '',
    unit: '',
    unitPrice: '',
    orderId,
    topicId: defaultTopicId,
    analysisId: '',
    createAlsoOrder: false,
  };

  const calculateTargetSum = (values: FormValueShape): string => {
    try {
      const result = values.rows.reduce(
        (acc, val) =>
          acc.add(
            big
              .fromInputString(val?.quantity, 0)
              .times(big.fromInputString(val?.unitPrice, 0))
          ),
        new Big(0)
      );

      return big.amountFormat(result);
    } catch (e) {
      return '0';
    }
  };

  // TODO: Filter object by 'createAlsoOrder' and use the same calculation function as above
  const calculateOrderSum = (values: FormValueShape): string => {
    try {
      // same calculation as for target rows, but only include
      // if 'createAlsoOrder' is true
      const result = values.rows.reduce(
        (acc, val) =>
          acc.add(
            val.createAlsoOrder
              ? big
                  .fromInputString(val?.quantity, 0)
                  .times(big.fromInputString(val?.unitPrice, 0))
              : new Big(0)
          ),
        new Big(0)
      );

      return big.amountFormat(result);
    } catch (e) {
      return '0';
    }
  };

  const addingRowsDisabled = (values: FormValueShape) =>
    Object.keys(values).length >= MAX_CREATABLE_ROWS;

  const addNewRowButtonTitle = useTxt(
    'order.addTargetRowsModal.button.addNewRow'
  );

  const addNewRowButtonTitleDisabled = useTxt('common.maxRowsExceeded', {
    maxRows: MAX_CREATABLE_ROWS,
  });

  // NB: The purpose of this component is to hook into Formik events
  // If POST request fails, manually set isSubmitting to false; this re-enables Save button
  const FormikChangeListener = () => {
    const { setSubmitting } = useFormikContext();

    useEffect(() => {
      if (requestState === 'Failure') {
        setSubmitting(false);
      }
    }, [setSubmitting]);

    return null;
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer>
        <StyledHeader>{titleText}</StyledHeader>
        <Formik
          initialValues={{ rows: [blankRow] }}
          onSubmit={sendRequest}
          validationSchema={arrayValidationSchema}
        >
          {({
            errors,
            values,
            setValues,
            setFieldValue,
            handleChange,
            isValid,
            isSubmitting,
          }) => (
            <StyledForm>
              <StyledContent>
                <FormikChangeListener />
                <Table>
                  <TableHeader />
                  <tbody>
                    {values.rows.map((row, i) => (
                      <Fragment key={row.rowId}>
                        <TargetRow
                          key={row.rowId}
                          row={row}
                          index={i}
                          errors={errors}
                          isDeleteDisabled={values.rows.length === 1}
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          onRemove={() => {
                            const removeFnWithFormikValues = (index: number) =>
                              removeRow(values, setValues, index);

                            return removeFnWithFormikValues(i);
                          }}
                        />
                      </Fragment>
                    ))}
                  </tbody>
                </Table>

                <ToolBarAndStatusGroup>
                  <IconTextButton
                    icon={IconPlus}
                    id="targetRow.button.new"
                    type="button"
                    disabled={addingRowsDisabled(values)}
                    title={
                      addingRowsDisabled(values)
                        ? addNewRowButtonTitleDisabled
                        : addNewRowButtonTitle
                    }
                    onClick={() => addNewTargetRow(values, setValues)}
                  >
                    <Txt id="order.addTargetRowsModal.button.addNewRow" />
                  </IconTextButton>
                </ToolBarAndStatusGroup>

                <ToolBarAndStatusGroup alignRight>
                  <SumField>
                    <Txt id="order.addTargetRowsModal.targetRowsSum" />{' '}
                    <b>{calculateTargetSum(values)}€</b>
                  </SumField>
                  <SumField>
                    <Txt id="order.addTargetRowsModal.orderRowsSum" />{' '}
                    <b>{calculateOrderSum(values)}€</b>
                  </SumField>
                </ToolBarAndStatusGroup>
              </StyledContent>
              <Footer>
                <CancelButton type="button" onClick={onClose}>
                  <Txt id="common.cancel" />
                </CancelButton>
                <ActionButton type="submit" disabled={!isValid || isSubmitting}>
                  <Txt id="order.addTargetRowsModal.button.submit" />
                </ActionButton>
              </Footer>
            </StyledForm>
          )}
        </Formik>
      </FormContainer>
    </Modal>
  );
};

const CancelButton = styled(SecondaryButton)`
  margin-right: 4px;
`;

const ActionButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

export const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  width: 80vw;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};
  height: ${(props) => props.theme.margin[48]};
`;

const StyledContent = styled(Content)`
  padding: ${(props) =>
    `${props.theme.margin[48]} ${props.theme.margin[16]} ${props.theme.margin[16]}`};
  overflow-y: scroll;
`;

type StatusGroupProps = {
  alignRight?: boolean;
};

const ToolBarAndStatusGroup = styled.div<StatusGroupProps>`
  margin-top: ${(props) => `${props.theme.margin[24]}`};

  width: 100%;

  display: flex;
  align-items: left;
  justify-content: left;

  ${(props) =>
    props.alignRight &&
    css`
      flex-direction: column;
      align-items: end;
      justify-content: flex-end;
    `}
`;

const SumField = styled.div`
  padding-bottom: ${(props) => props.theme.margin[10]};
  padding-right: ${(props) => props.theme.margin[176]};
`;
