import React from 'react';
import { useParams } from 'react-router-dom';

import { getAnalysisGroupsForProject } from '../../../store/reducers/analysis/group';

import * as Actions from '../../../store/actions';

import useRemoteData from '../../../hooks/useRemoteData';

import { Table } from '../../../components/Table';

import { AnalysisGroup } from './AnalysisGroup';
import { TableHeader } from './TableHeader';

type RouteParams = {
  projectId: string;
};

export const AnalysisTable = () => {
  const { projectId } = useParams<RouteParams>();

  const analysisGroups =
    useRemoteData(
      getAnalysisGroupsForProject(projectId),
      Actions.requestAnalysisGroups({ projectId })
    ) ?? [];

  return (
    <Table>
      <TableHeader />
      <tbody>
        {analysisGroups.map(
          ({
            id,
            name,
            attributeNames,
            attributeIds,
            isOrderRowField,
            isPaymentProgramRowField,
            isTargetRowField,
          }) => (
            <AnalysisGroup
              key={id}
              id={id}
              name={name}
              dynamicColumnAttributeIds={attributeIds}
              dynamicColumnHeaderNames={attributeNames}
              isOrderRowField={isOrderRowField}
              isPaymentProgramRowField={isPaymentProgramRowField}
              isTargetRowField={isTargetRowField}
            />
          )
        )}
      </tbody>
    </Table>
  );
};
