import { combineThunks } from '../../../utils/thunk';

import { AnalysisGroupAction } from './group';
import { OrderRowLinkageAction } from './orderRowLinkage';
import {
  PossibleOrderRowLinkAction,
  requestPossibleAnalysisRowsForOrderRow,
} from './possibleOrderRowLinks';
import {
  PossibleRevenueLinkAction,
  requestPossibleAnalysisRowsForRevenue,
} from './possibleRevenueLinks';
import {
  PossibleTargetRowLinkAction,
  requestPossibleAnalysisRowsForTargetRow,
} from './possibleTargetRowLinks';
import { RevenueLinkageAction } from './revenueLinkage';
import { AnalysisRowAction, requestAnalysisRows } from './row';
import { AnalysisSelectListItemAction } from './selectListItem';
import { TargetRowLinkageAction } from './targetRowLinkage';

export * from './group';
export * from './row';
export * from './selectListItem';

export type AnalysisAction =
  | AnalysisGroupAction
  | AnalysisRowAction
  | AnalysisSelectListItemAction
  | PossibleOrderRowLinkAction
  | PossibleTargetRowLinkAction
  | PossibleRevenueLinkAction
  | OrderRowLinkageAction
  | TargetRowLinkageAction
  | RevenueLinkageAction;

export const requestSelectableAnalysisRowsForRevenue = (args: {
  revenueId: string;
  projectId: string;
}) => {
  const { revenueId, projectId } = args;

  return combineThunks(
    requestPossibleAnalysisRowsForRevenue(revenueId),
    requestAnalysisRows({ projectId })
  );
};

export const requestSelectableAnalysisRowsForOrderOrTargetRow = (args: {
  orderRowId?: string;
  projectId: string;
  targetRowId?: string;
}) => {
  const { orderRowId, projectId, targetRowId } = args;

  if (orderRowId) {
    return combineThunks(
      requestPossibleAnalysisRowsForOrderRow(orderRowId),
      requestAnalysisRows({ projectId })
    );
  }

  if (targetRowId) {
    return combineThunks(
      requestPossibleAnalysisRowsForTargetRow(targetRowId),
      requestAnalysisRows({ projectId })
    );
  }

  return requestAnalysisRows({ projectId });
};
