import React, { useContext } from 'react';
import { DayModifiers } from 'react-day-picker';

import { mapValues } from 'lodash';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import styled from 'styled-components';

import 'react-day-picker/lib/style.css';

import useLocalization from '../hooks/useLocalization';

import {
  finnishStrictLocalizations,
  otherStrictLocalizations,
  momentValueFromInput,
} from '../utils/general';

import { Context } from '../context/locale';
import { ErrorMessage, Container } from './Input/TextInput';

const monthsLocalization = [
  'datePicker.months.Jan',
  'datePicker.months.Feb',
  'datePicker.months.Mar',
  'datePicker.months.Apr',
  'datePicker.months.May',
  'datePicker.months.Jun',
  'datePicker.months.Jul',
  'datePicker.months.Aug',
  'datePicker.months.Sep',
  'datePicker.months.Oct',
  'datePicker.months.Nov',
  'datePicker.months.Dec',
] as const;

const labelsLocalization = {
  previousMonth: 'datePicker.labels.previousMonth',
  nextMonth: 'datePicker.labels.nextMonth',
} as const;
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const;

const weekdaysShortLocalization = weekDays.map(
  (day) => `datePicker.weekDaysShort.${day}` as const
);

const weekdaysLongLocalization = weekDays.map(
  (day) => `datePicker.weekDaysLong.${day}` as const
);

type DatePickerProps = {
  name?: string;
  date: Date | string;
  label?: string;
  onDayChange: (date: Date | string) => void;
  errorMessage?: string | null;
  disabled?: boolean;
  inputReadOnly?: boolean;
  contentContainer?: React.ComponentType<{ invalid: boolean }>;
};

export const DatePicker = ({
  name,
  date,
  label,
  disabled,
  onDayChange,
  errorMessage,
  inputReadOnly,
  contentContainer: ContentContainer = DayPickerContainer,
}: DatePickerProps) => {
  const [dateState, setDateState] = React.useState(date);

  const { lang } = useContext(Context);

  const localization = useLocalization();

  const months = monthsLocalization.map((id) =>
    localization.formatMessage({ id })
  );

  const weekdaysShort = weekdaysShortLocalization.map((id) =>
    localization.formatMessage({ id })
  );

  const weekdaysLong = weekdaysLongLocalization.map((id) =>
    localization.formatMessage({ id })
  );

  const labels = mapValues(labelsLocalization, (id) =>
    localization.formatMessage({ id })
  );

  const inputInvalid = typeof errorMessage === 'string';

  const currentMonth = new Date().getMonth() + 1;
  const fromMonth = new Date(new Date().getFullYear() - 10, currentMonth);
  const toMonth = new Date(new Date().getFullYear() + 10, currentMonth);

  const handleDayChange = (
    selectedDay: Date,
    modifiers: DayModifiers,
    dayPickerInput: DayPickerInput
  ) => {
    const input = dayPickerInput.getInput();

    const momentValue = momentValueFromInput(input.value);

    if (
      momentValue.isValid() &&
      momentValue.toDate() < toMonth &&
      momentValue.toDate() > fromMonth
    ) {
      setDateState(momentValue.toDate());
      onDayChange(momentValue.format(moment.HTML5_FMT.DATE));
    }
  };

  const parseDate = (str: string) => {
    const parsedDate = moment(
      str,
      lang === 'fi' ? finnishStrictLocalizations : otherStrictLocalizations,
      true
    );

    if (parsedDate.toDate() > toMonth || parsedDate.toDate() < fromMonth) {
      return undefined;
    }

    return parsedDate.isValid() ? parsedDate.toDate() : undefined;
  };

  const formatDate = (inputDate: Date) => {
    return moment(inputDate).format(lang === 'fi' ? 'D.M.YYYY' : 'M/D/YYYY');
  };

  return (
    <ContentContainer invalid={inputInvalid}>
      {label ? <Styledlabel>{label}</Styledlabel> : null}
      <DayPickerInput
        dayPickerProps={{
          months,
          weekdaysShort,
          weekdaysLong,
          labels,
          firstDayOfWeek: 1,
          fromMonth,
          toMonth,
          selectedDays: new Date(dateState),
        }}
        value={dateState}
        placeholder={lang === 'fi' ? 'D.M.YYYY' : 'M/D/YYYY'}
        onDayChange={handleDayChange}
        inputProps={{ name, disabled, readOnly: inputReadOnly }}
        parseDate={parseDate}
        formatDate={formatDate}
      />
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </ContentContainer>
  );
};

const DayPickerContainer = styled(Container)`
  z-index: 2;
`;

const Styledlabel = styled.label`
  padding: ${(props) => `0 0 ${props.theme.margin[8]} 0 `};
  display: block;
  font-weight: bold;
`;
