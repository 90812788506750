import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/target/hierarchy';

const actionCreators = {
  ...makeAction('getTargetRowHierarchiesStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getTargetRowHierarchiesSuccess')<{
    projectId: string;
    targetRowHierarchyEntries: TargetRowHierarchyEntry[];
  }>(),
  ...makeAction('getTargetRowHierarchiesFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
};

export type TargetRowHierarchyAction = ExtractActionTypes<
  typeof actionCreators
>;

const apiTargetRowHierarchyEntryType = t.exact(
  t.type({
    id: t.string,

    projectId: t.string,
    parentId: t.union([t.string, t.null]),

    referenceNumber: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),

    quantity: t.union([bigString, t.null]),
    unit: t.union([t.string, t.null]),

    isDeleted: t.boolean,
    createdAt: dateString,
    updatedAt: dateString,

    childIds: t.array(t.string),
    targetRowIds: t.array(t.string),

    depth: t.number,
    unitPrice: t.union([bigString, t.null]),
    totalAmount: t.union([bigString, t.null]),
  })
);

export type TargetRowHierarchyEntry = t.TypeOf<
  typeof apiTargetRowHierarchyEntryType
>;

const {
  getTargetRowHierarchiesStarted,
  getTargetRowHierarchiesSuccess,
  getTargetRowHierarchiesFailure,
} = actionCreators;

export async function toTargetRowHierarchyEntries(
  u: unknown
): Promise<TargetRowHierarchyEntry[]> {
  const targetRows = await tPromise.decode(
    t.array(apiTargetRowHierarchyEntryType),
    u
  );

  return targetRows;
}

const requestTargetRowHierarchyForProject = async ({
  projectId,
}: {
  projectId: string;
}): Promise<TargetRowHierarchyEntry[]> => {
  const response = await GET<TargetRowHierarchyEntry[]>(
    `v1/projects/${projectId}/target-row-hierarchy`
  );

  return toTargetRowHierarchyEntries(response);
};

export const getTargetRowHierarchyForProject = ({
  projectId,
}: {
  projectId: string;
}) =>
  createAsyncThunk(requestTargetRowHierarchyForProject, {
    args: [{ projectId }],
    initialAction: getTargetRowHierarchiesStarted({ projectId }),
    isPending: isLoading(projectId),
    failureActionCreator: (error) =>
      getTargetRowHierarchiesFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (targetRowHierarchyEntries) =>
      getTargetRowHierarchiesSuccess({
        projectId,
        targetRowHierarchyEntries,
      }),
  });
