import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { AppState } from '..';
import { WorkPackageGroupTimelineEntry } from '../../actions/schedule/workPackageGroupTimeline';
import { ActionTypes as Action } from '../../actionTypes';

type Err = BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<string, WorkPackageGroupTimelineEntry[]>;
};

const initialState: State = {
  requests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WORK_PACKAGE_GROUP_TIMELINES_STARTED': {
      const { projectId } = action.payload;
      const requests = { ...state.requests, [projectId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_WORK_PACKAGE_GROUP_TIMELINES_FAILURE': {
      const { projectId, error } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }

    case 'GET_WORK_PACKAGE_GROUP_TIMELINES_SUCCESS': {
      const { projectId, workPackageGroupTimelineEntries } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.succeed(undefined),
      };

      return {
        requests,
        data: { ...state.data, [projectId]: workPackageGroupTimelineEntries },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  projectId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    schedule: {
      workPackageGroupTimelines: {
        requests: { [projectId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
}

export function isLoading(projectId: string): Selector<boolean> {
  return flow(
    getRequestState(projectId),
    (requestState) => requestState === 'Loading'
  );
}

export const getWorkPackageGroupTimelineEntriesForProject: (
  projectId: string
) => Selector<remoteData.RemoteData<WorkPackageGroupTimelineEntry[]>> = (
  projectId
) => ({
  schedule: {
    workPackageGroupTimelines: {
      requests: { [projectId]: requestState = remoteData.notAsked },
      data,
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    const timelineData = data[projectId];

    if (!timelineData) {
      return [];
    }

    return timelineData;
  });

export const getWorkPackageGroupCurrentPeriodActualPoC: ({
  projectId,
  workPackageGroupId,
}: {
  projectId: string;
  workPackageGroupId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageGroupTimelineEntry | undefined> = ({
  projectId,
  workPackageGroupId,
}) =>
  flow(
    getWorkPackageGroupTimelineEntriesForProject(projectId),
    (remoteEntries) =>
      remoteData.map(remoteEntries, (entries) => {
        const filteredEntry = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.snapshotTypeId === '1' &&
            entry.snapshotId === null &&
            entry.currentPeriod
        )[0];

        return filteredEntry;
      })
  );

export const getWorkPackageGroupCurrentPeriodLatestSnapshotPlannedPoC: ({
  projectId,
  workPackageGroupId,
}: {
  projectId: string;
  workPackageGroupId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageGroupTimelineEntry | undefined> = ({
  projectId,
  workPackageGroupId,
}) =>
  flow(
    getWorkPackageGroupTimelineEntriesForProject(projectId),
    (remoteEntries) =>
      remoteData.map(remoteEntries, (entries) => {
        const filteredEntry = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.snapshotTypeId === '2' &&
            entry.latestSnapshot &&
            entry.currentPeriod &&
            entry.pastPeriod === false
        )[0];

        return filteredEntry;
      })
  );

export const getWorkPackageGroupPlannedPoCEntries: ({
  projectId,
  workPackageGroupId,
}: {
  projectId: string;
  workPackageGroupId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageGroupTimelineEntry[]> = ({
  projectId,
  workPackageGroupId,
}) =>
  flow(
    getWorkPackageGroupTimelineEntriesForProject(projectId),
    (remoteEntries) =>
      remoteData.map(remoteEntries, (entries) => {
        const filteredEntries = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.projectId === projectId &&
            entry.snapshotTypeId === '2' &&
            entry.snapshotId === null
        );

        return filteredEntries;
      })
  );

export const getWorkPackageGroupActualAndPlannedPoCEntries: ({
  projectId,
  workPackageGroupId,
}: {
  projectId: string;
  workPackageGroupId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageGroupTimelineEntry[]> = ({
  projectId,
  workPackageGroupId,
}) =>
  flow(
    getWorkPackageGroupTimelineEntriesForProject(projectId),
    (remoteEntries) =>
      remoteData.map(remoteEntries, (entries) => {
        const filteredActualEntries = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.projectId === projectId &&
            entry.snapshotTypeId === '1' &&
            entry.snapshotId === null
        );

        const filteredPlannedEntries = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.projectId === projectId &&
            entry.snapshotTypeId === '2' &&
            entry.snapshotId === null &&
            entry.currentPeriod === false
        );

        return filteredActualEntries.concat(filteredPlannedEntries);
      })
  );

export const getWorkPackageGroupPoCBasedOnPreviouslyPlannedEntries: ({
  projectId,
  workPackageGroupId,
}: {
  projectId: string;
  workPackageGroupId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageGroupTimelineEntry[]> = ({
  projectId,
  workPackageGroupId,
}) =>
  flow(
    getWorkPackageGroupTimelineEntriesForProject(projectId),
    (remoteEntries) =>
      remoteData.map(remoteEntries, (entries) => {
        const filteredEntries = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.projectId === projectId &&
            entry.snapshotTypeId === '3' &&
            entry.snapshotId === null
        );

        return filteredEntries;
      })
  );

export const getWorkPackageGroupPreviouslyPlannedPoCEntries: ({
  projectId,
  workPackageGroupId,
}: {
  projectId: string;
  workPackageGroupId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageGroupTimelineEntry[]> = ({
  projectId,
  workPackageGroupId,
}) =>
  flow(
    getWorkPackageGroupTimelineEntriesForProject(projectId),
    (remoteEntries) =>
      remoteData.map(remoteEntries, (entries) => {
        const filteredEntries = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.projectId === projectId &&
            entry.snapshotTypeId === '2' &&
            entry.latestSnapshot &&
            entry.pastPeriod === false
        );

        return filteredEntries;
      })
  );

export const getWorkPackageGroupSnapshotPoCEntries: ({
  projectId,
  workPackageGroupId,
}: {
  projectId: string;
  workPackageGroupId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<WorkPackageGroupTimelineEntry[]> = ({
  projectId,
  workPackageGroupId,
}) =>
  flow(
    getWorkPackageGroupTimelineEntriesForProject(projectId),
    (remoteEntries) =>
      remoteData.map(remoteEntries, (entries) => {
        const filteredEntries = entries.filter(
          (entry) =>
            entry.workPackageGroupId === workPackageGroupId &&
            entry.projectId === projectId &&
            entry.snapshotId !== null &&
            entry.pastPeriod
        );

        return filteredEntries;
      })
  );
