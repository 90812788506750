import * as React from 'react';

import styled from 'styled-components';

import { getOrderRowsForAnalysisRow } from '../../../../store/reducers/orderRow';

import { fetchOrderRowsForAnalysisRow } from '../../../../store/actions';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import {
  StickyHeaderTh,
  Table,
  TableHeader,
} from '../../../../components/Table';

import { TableRow } from './TableRow';

type AnalysisInspectionTableProps = {
  analysisRowId: string;
};

export const InspectionTable = ({
  analysisRowId,
}: AnalysisInspectionTableProps) => {
  const orderRows =
    useRemoteData(
      getOrderRowsForAnalysisRow(analysisRowId),
      fetchOrderRowsForAnalysisRow(analysisRowId)
    ) ?? [];

  const headerDescription = useTxt('analysis.modal.header.description');
  const headerOrder = useTxt('analysis.modal.header.order');

  return (
    <StyledDivTableContainer>
      <Table>
        <TableHeader>
          <tr>
            <StickyHeaderTh width="3rem" />
            <StickyHeaderTh width="auto">{headerDescription}</StickyHeaderTh>
            <StickyHeaderTh width="20rem">{headerOrder}</StickyHeaderTh>
            <StickyHeaderTh width="3rem" />
            <StickyHeaderTh width="3rem" />
            <StickyHeaderTh width="10rem" />
          </tr>
        </TableHeader>
        <tbody>
          {orderRows.map((orderRow) => (
            <TableRow
              key={orderRow.id}
              analysisRowId={analysisRowId}
              orderRow={orderRow}
            />
          ))}
        </tbody>
      </Table>
    </StyledDivTableContainer>
  );
};

const StyledDivTableContainer = styled.div`
  max-height: 70vh;

  display: flex;
  flex-direction: column;

  flex: 1;

  overflow-y: scroll;
`;
