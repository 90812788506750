import React from 'react';
import { useHistory } from 'react-router-dom';

import { orderBy } from 'lodash';
import styled from 'styled-components';

import { NotificationFeedItem } from '../../../types/general';

import { useElapsedTime } from '../../../hooks/ui';
import useTxt from '../../../hooks/useTxt';

import * as big from '../../../utils/big';
import { dateFormat } from '../../../utils/format';
import { checkDueDate } from './utils';

import {
  IconIndicatorGreen,
  IconIndicatorRed,
  IconInvoiceUnprocessed,
  IconArrowRight,
  IconArrowLeft,
  IconActualCostWhite,
  IconInvalid,
  IconInvalidLight,
} from '../../../assets/svg';

import { generateUrl, routes } from '../../../routes';
import {
  NotificationContainer,
  StyledNotificationContent,
  StyledHeader,
  StyledLinkArea,
  NotificationTimeStamp,
  StyledRouterLink,
  StyledLinkDiv,
  DueDateNotificationIcons,
} from './Components';

type NotificationProps = {
  notification: NotificationFeedItem;
  onCloseSidebar: () => void;
  markAsRead: (item: NotificationFeedItem) => void;
  isNew?: boolean;
};

const OrderLevelNotification = ({
  notification,
  onCloseSidebar,
  markAsRead,
  isNew,
}: NotificationProps) => {
  const history = useHistory();

  const delegationRequest = useTxt(
    'navigation.notifications.delegationRequest'
  );

  const delegationResponse = useTxt(
    'navigation.notifications.delegationResponse'
  );

  const invoiceDate = useTxt('order.receiveMode.arrivalRowTH.invoiceDate');
  const NewTxt = useTxt('navigation.notifications.new');
  const registrationDate = useTxt('navigation.notifications.registrationDate');

  const defaultDescription = useTxt(
    'navigation.notifications.defaultDescription'
  );

  const unHandledInvoiceDescription = useTxt(
    'navigation.notifications.description.unhandledInvoice'
  );

  const unhandledActualCostDescription = useTxt(
    'navigation.notifications.description.unhandledActualCost'
  );

  const declinedInvoiceDescription = useTxt(
    'navigation.notifications.description.declinedInvoice'
  );

  const canceledActualCostDescription = useTxt(
    'navigation.notifications.description.canceledActualCost'
  );

  const invoiceNeedingRehandlingDescription = useTxt(
    'navigation.notifications.description.invoiceNeedingRehandling'
  );

  const delegationRequestDescription = useTxt(
    'navigation.notifications.description.delegationRequest'
  );

  const delegationResponseDescription = useTxt(
    'navigation.notifications.description.delegationResponse'
  );

  const elapsedTimeText = useElapsedTime(notification.createdAt);

  const { notificationType } = notification;

  const handleNotificationClick = (): void => {
    if (!notification.isRead) {
      markAsRead(notification);
    }

    if (!(notification.project?.id && notification.order?.id)) {
      return;
    }

    history.push(
      generateUrl({
        route: routes.ORDER,
        projectId: notification.project.id,
        orderId: notification.order.id,
        viewMode: 'receive',
        subViewMode: 'invoices',
      })
    );

    onCloseSidebar();
  };

  const handleInvoiceClick = (invoiceId: string, costType?: string): void => {
    if (!(notification.project?.id && notification.order?.id)) {
      return;
    }

    const url = generateUrl({
      route: routes.ORDER,
      projectId: notification.project.id,
      orderId: notification.order.id,
      viewMode: 'receive',
      subViewMode: 'invoices',
    });

    if (!notification.isRead) {
      markAsRead(notification);
    }

    const search = '?showAll=true';

    const hash = !(costType === 'actual_cost')
      ? `#invoiceHeaderId-${invoiceId}`
      : `#actualCostId-${invoiceId}`;

    const location = {
      pathname: url,
      search: notification.notificationType === '5' ? search : undefined,
      hash,
    };

    history.push(location);

    onCloseSidebar();
  };

  const delegation =
    notificationType !== '6' ? delegationResponse : delegationRequest;

  const sortedInvoices = orderBy(
    notification.purchaseInvoiceHeaders,
    ['dueDate'],
    ['asc']
  );

  const selectDescription = () => {
    switch (notificationType) {
      case '1':
        return unHandledInvoiceDescription;
      case '2':
        return unhandledActualCostDescription;
      case '5':
        return declinedInvoiceDescription;
      case '6':
        return delegationRequestDescription;
      case '7':
        return delegationResponseDescription;
      case '10':
        return canceledActualCostDescription;
      case '11':
        return invoiceNeedingRehandlingDescription;
      default:
        return defaultDescription;
    }
  };

  return (
    <NotificationContainer onClick={handleNotificationClick}>
      <StyledNotificationContent>
        <StyledHeader>
          {`${isNew ? NewTxt : ''}${selectDescription()}`}
        </StyledHeader>
        {/* new indicator */}
        <NotificationTimeStamp>
          {isNew
            ? elapsedTimeText
            : notification.createdAt.toLocaleDateString()}
        </NotificationTimeStamp>

        <StyledLinkArea>
          {/* project info */}
          {notification.project ? notification.project.label : null}

          {/* notifications */}
          {notification.project && notification.order ? (
            <StyledRouterLink
              key={`order-link-${notification.order.id}`}
              to={generateUrl({
                route: routes.ORDER,
                projectId: notification.project.id,
                orderId: notification.order.id,
                viewMode: 'receive',
                subViewMode: 'invoices',
              })}
            >
              {notification.order.label}
            </StyledRouterLink>
          ) : null}
          <StyledLinkArea>
            {sortedInvoices.map((row) => {
              return (
                <StyledLinkDiv
                  key={`invoice-${row.id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleInvoiceClick(row.id);
                  }}
                >
                  <StyledTaskStatusIconDiv>
                    <InvoiceTaskStatusIcon taskStatus={row.taskStatus} />
                  </StyledTaskStatusIconDiv>
                  {notificationType === '6' || notificationType === '7'
                    ? delegation
                    : undefined}{' '}
                  {row.vendorInvoiceNumber} | {invoiceDate}{' '}
                  {dateFormat.format(row.dueDate)} |{' '}
                  {big.amountFormat(row.amount)} &euro;
                  <DueDateNotificationIcons>
                    {['1', '2', '6', '7', '11'].includes(notificationType) ? (
                      <>
                        {checkDueDate(row.dueDate) <= 0 ? (
                          <IconInvalid />
                        ) : null}
                        {[1, 2, 3, 4, 5, 6, 7].includes(
                          checkDueDate(row.dueDate)
                        ) ? (
                          <IconInvalidLight />
                        ) : null}
                      </>
                    ) : null}
                  </DueDateNotificationIcons>
                </StyledLinkDiv>
              );
            })}
            {notification.actualCosts.map((row) => (
              <StyledLinkDiv
                key={`cost-${row.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleInvoiceClick(row.id, 'actual_cost');
                }}
              >
                <StyledTaskStatusIconDiv>
                  <IconActualCostWhite />
                </StyledTaskStatusIconDiv>
                {row.documentNumber} | {registrationDate}{' '}
                {dateFormat.format(row.date)} | {big.amountFormat(row.amount)}{' '}
                &euro;
              </StyledLinkDiv>
            ))}
          </StyledLinkArea>
        </StyledLinkArea>
      </StyledNotificationContent>
    </NotificationContainer>
  );
};

type InvoiceTaskStatusIconProps = {
  taskStatus: string | null;
};

const InvoiceTaskStatusIcon = ({ taskStatus }: InvoiceTaskStatusIconProps) => {
  if (taskStatus === null) {
    return <IconInvoiceUnprocessed />;
  }

  switch (taskStatus) {
    case '1':
      return <IconArrowRight fill="white" stroke="white" />;
    case '2':
      return <StyledIndicatorGreen />;
    case '3':
      return <StyledIndicatorRed />;
    default:
      return <IconArrowLeft fill="white" stroke="white" />;
  }
};

const StyledTaskStatusIconDiv = styled.div`
  padding-right: ${(props) => props.theme.margin[4]};
`;

const StyledIndicatorGreen = styled(IconIndicatorGreen)`
  margin: 4px;
  display: block;
`;

const StyledIndicatorRed = styled(IconIndicatorRed)`
  margin: 4px;
  display: block;
`;

export default OrderLevelNotification;
