// Inspired by
// https://package.elm-lang.org/packages/krisajenkins/remotedata/latest/RemoteData
// " It makes it easier to represent the real state of a remote data fetch and
// handle it properly. "

export type NotAsked = {
  kind: 'NotAsked';
};
export type Loading = {
  kind: 'Loading';
};
export type Failure<E> = {
  kind: 'Failure';
  error: E;
};
export type Success<A> = {
  kind: 'Success';
  value: A;
};
export type RemoteData<A = undefined, E = unknown> =
  | NotAsked
  | Loading
  | Failure<E>
  | Success<A>;

export const notAsked: NotAsked = {
  kind: 'NotAsked',
};
export const loading: Loading = {
  kind: 'Loading',
};
export function fail<E>(error: E): Failure<E> {
  return {
    kind: 'Failure',
    error,
  };
}
/*
 * Lift an ordinary value into the realm of RemoteData.
 */
export function succeed<A>(value: A): Success<A> {
  return {
    kind: 'Success',
    value,
  };
}

/*
 * Map a function into the success value
 */
export function map<A, B, E>(
  remoteData: RemoteData<A, E>,
  fn: (a: A) => B
): RemoteData<B, E> {
  if (remoteData.kind === 'Success') {
    return succeed(fn(remoteData.value));
  }

  return remoteData;
}

/*
 *  Chain together RemoteData function calls.
 */
export function chain<A, B, E>(
  remoteData: RemoteData<A, E>,
  fn: (a: A) => RemoteData<B, E>
): RemoteData<B, E> {
  if (remoteData.kind === 'Success') {
    return fn(remoteData.value);
  }

  return remoteData;
}

export function fromNullable<A, E>(
  nullable: A | null | undefined,
  error: E
): RemoteData<A, E> {
  return nullable ? succeed(nullable) : fail(error);
}

/*
 * Return the success value or the default value
 */
export function withDefault<A, E>(
  remoteData: RemoteData<A, E>,
  defaultValue: A
): A {
  if (remoteData.kind === 'Success') {
    return remoteData.value;
  }

  return defaultValue;
}

/*
 * Take a default value, a function and a RemoteData. Return the default value
 * if the RemoteData is something other than Success a. If the RemoteData is
 *  Success a, apply the function on a and return the b.
 */
export function unwrap<Value, MappedValue, Err>(
  remoteData: RemoteData<Value, Err>,
  options: {
    unwrapper: (a: Value) => MappedValue;
    defaultValue: MappedValue;
  }
): MappedValue {
  return withDefault(map(remoteData, options.unwrapper), options.defaultValue);
}

/*
 * Append - join two RemoteData values together as though they were one.  If
 * either value is NotAsked, the result is NotAsked. If either value is
 * Loading, the result is Loading. If both values are Failure, the left one
 * wins.
 */
export function append<A, B, E>(
  left: RemoteData<A, E>,
  right: RemoteData<B, E>
): RemoteData<[A, B], E> {
  if (left.kind === 'NotAsked' || right.kind === 'NotAsked') {
    return notAsked;
  }

  if (left.kind === 'Loading' || right.kind === 'Loading') {
    return loading;
  }

  if (left.kind === 'Failure') {
    return left;
  }

  if (right.kind === 'Failure') {
    return right;
  }

  return succeed([left.value, right.value]);
}

export function isSuccess<A, E>(
  remoteData: RemoteData<A, E>
): remoteData is Success<A> {
  return remoteData.kind === 'Success';
}
export function isFailure<A = unknown, E = unknown>(
  remoteData: RemoteData<A, E>
): remoteData is Failure<E> {
  return remoteData.kind === 'Failure';
}
export function isLoading<A = unknown, E = unknown>(
  remoteData: RemoteData<A, E>
): remoteData is Loading {
  return remoteData.kind === 'Loading';
}

export type RemoteAction<E = unknown> = RemoteData<undefined, E>;
