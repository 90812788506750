// FIXME: Maybe think about using a store for this.
let csrfToken: string = '';

export function getCsrfToken(): string {
  return csrfToken;
}

export function setCsrfToken(nextToken: string) {
  csrfToken = nextToken;
}
