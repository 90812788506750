import React, { useState, ReactNode, ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '../store/reducers/user';

import { LanguageType } from '../types/general';

import * as localization from '../localization';

interface ILocalization {
  lang: LanguageType; // can be extended
  setLang: React.Dispatch<React.SetStateAction<LanguageType>>;
}

export const Context = React.createContext<ILocalization>({
  lang: 'fi',
  setLang: () => {},
});

const LocaleWrapper = ({ children }: { children: ReactNode }): ReactElement => {
  const user = useSelector(selectCurrentUser);

  const language: LanguageType = user?.languageId === '2' ? 'en' : 'fi';

  const [lang, setLang] = useState<LanguageType>(language);

  React.useEffect(() => {
    setLang(language);
  }, [language]);

  const locale = lang === 'fi' ? 'fi-FI' : 'en-GB';

  return (
    <Context.Provider value={{ lang, setLang }}>
      <IntlProvider
        locale={locale}
        messages={localization.texts[lang]}
        timeZone="Europe/Helsinki"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default LocaleWrapper;
