import { combineReducers } from 'redux';

import revenueDeleteReducer from './deleteRevenue';
import revenueReducer from './revenue';
import sortByReducer from './sortRevenue';
import revenueUpdateReducer from './updateRevenue';

export default combineReducers({
  paymentRows: revenueReducer,
  updateRequests: revenueUpdateReducer,
  deleteRequests: revenueDeleteReducer,
  sortOrder: sortByReducer,
});
