import React from 'react';

import styled from 'styled-components';

import Checkbox from '../../../../components/Input/Checkbox';
import { CompactTableHeader } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import getWidths from '../../../../utils/headerWidth';

export const tableColumns = {
  rowNo: { align: 'left', relativeWidth: 0.8 },
  chooseRow: { align: 'center', relativeWidth: 0.7 },
  productCode: { align: 'left', relativeWidth: 2.5 },
  description: { align: 'left', relativeWidth: 6 },
  quantity: { align: 'right', relativeWidth: 1.5 },
  unit: { align: 'left', relativeWidth: 1 },
  netPrice: { align: 'right', relativeWidth: 3 },
  netTotal: { align: 'right', relativeWidth: 3 },
  VAT: { align: 'right', relativeWidth: 1 },
  orderRow: { align: 'center', relativeWidth: 3 },
} as const;

const getColumnPercent = getWidths(tableColumns);
type ThProps = {
  name: keyof typeof tableColumns;
  paddingDisabled?: boolean;
};

const Th = styled.th<ThProps>`
  padding: ${(props) => (props.paddingDisabled ? 0 : props.theme.margin[8])};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  align-items: center;
  text-align: ${({ name }) => tableColumns[name].align};
`;

const textIdHead = 'order.invoiceLines.header.';

const TxtTh = ({ name }: ThProps) => (
  <Th name={name}>
    <Txt id={`${textIdHead}${name}` as const} />
  </Th>
);

type InvoiceLineTableHeaderProps = {
  boxChecked: boolean;
  boxDisabled: boolean;
  onToggle: () => void | undefined;
};

const InvoiceLineTableHeader = ({
  boxChecked,
  boxDisabled,
  onToggle,
}: InvoiceLineTableHeaderProps) => {
  return (
    <CompactTableHeader>
      <tr>
        <TxtTh name="rowNo" />
        <Th name="chooseRow" align="center" paddingDisabled>
          {!boxDisabled ? (
            <Checkbox
              checked={boxChecked}
              onChange={onToggle}
              variant="secondary"
            />
          ) : null}
        </Th>
        <TxtTh name="productCode" />
        <TxtTh name="description" />
        <TxtTh name="quantity" />
        <TxtTh name="unit" />
        <TxtTh name="netPrice" />
        <TxtTh name="netTotal" />
        <TxtTh name="VAT" />
        <TxtTh name="orderRow" />
      </tr>
    </CompactTableHeader>
  );
};

export default InvoiceLineTableHeader;
