import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { getStatuses } from '../../../store/reducers/order/options';
import { getOrderRowTotalsByWorkpackageId } from '../../../store/reducers/orderRow';
import { getTopicsByOrderId } from '../../../store/reducers/topic';
import { getAreAllWorkSectionsOpen } from '../../../store/reducers/ui';

import { getDropDowns } from '../../../store/actions/order/options';

import { APIOrder } from '../../../types/api';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import Cell from '../../../components/Cell';
import { SecondaryRow } from '../../../components/Table';

import * as big from '../../../utils/big';
import { isDefined } from '../../../utils/general';

import { IconDown, IconRight } from '../../../assets/svg';

import { ToggleButton } from '../../../context/toggleContext';
import TableHeaderRow from '../SectionTableHeader';
import { Topic } from './Topic';

type StyledSpanProps = {
  color: 'green' | 'red';
};

const StyledSpan = styled.span<StyledSpanProps>`
  color: ${({ color, theme }) =>
    color === 'green' ? theme.color.positiveGreen : theme.color.negativeRed};
`;

type PredictionChangeProps = {
  value: Big;
};

const PredictionChange = ({ value }: PredictionChangeProps) => {
  const [preText, color] = value.gt(0)
    ? (['+ ', 'red'] as const)
    : (['', 'green'] as const);

  return (
    <StyledSpan color={color}>
      {preText}
      {big.priceFormat(value, 0)}
    </StyledSpan>
  );
};

type OrderProps = {
  order: APIOrder;
  hasHeaderAfter?: boolean;
};

const Order = ({
  order: { id, visibleCode, name, contractor, statusId },
  hasHeaderAfter,
}: OrderProps) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { hash } = history.location;
  const { workPackageId } = useParams<{ workPackageId: string }>();

  const allSectionsOpen: boolean =
    useSelector(getAreAllWorkSectionsOpen()) ?? false;

  // TODO: find a way to define this in routes.ts
  const topicIdFromUrl = hash.split('#topic-').pop();

  const status = useRemoteData(getStatuses, getDropDowns)?.filter(
    (row) => row.id === statusId
  )[0].name;

  const orderContainsOpenTopic = Object.values(
    useSelector(getTopicsByOrderId(id))
  )
    .filter(isDefined)
    .some((topic) => topic.id === topicIdFromUrl);

  useEffect(() => {
    setIsOpen(allSectionsOpen);
  }, [allSectionsOpen]);

  useEffect(() => {
    if (orderContainsOpenTopic) {
      setIsOpen(true);
    }
  }, [orderContainsOpenTopic]);

  const toggleIsOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsOpen((open) => !open);
  };

  const openAltText = useTxt('worksection.table.mainRow.open');
  const closedAltText = useTxt('worksection.table.mainRow.closed');

  const {
    changeOrdersTotal,
    reservesTotal,
    targetTotal,
    additionalTargetTotal,
    contractTotal,
    predictionTotal,
    predictionChangeFromLatest,
    receivedTotal,
  } = useSelector(
    getOrderRowTotalsByWorkpackageId({ orderId: id, workPackageId })
  );

  const onKeyPress = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      toggleIsOpen(e);
    }
  };

  return (
    <>
      <SecondaryRow
        clickable
        onClick={toggleIsOpen}
        onKeyPress={onKeyPress}
        tabIndex={0}
        data-testid={`worksection-expanded-order-${id}`}
      >
        <ArrowCell align="center">
          <ToggleButton
            icon={isOpen ? IconDown : IconRight}
            label={isOpen ? openAltText : closedAltText}
            onClick={toggleIsOpen}
          />
        </ArrowCell>
        <BasicCell>{visibleCode}</BasicCell>
        <BoldedCell>
          {name}
          {contractor ? ` | ${contractor}` : null}
        </BoldedCell>
        <BasicCell align="right">{big.priceFormat(targetTotal, 0)}</BasicCell>
        <BasicCell align="right">
          {big.priceFormat(additionalTargetTotal, 0)}
        </BasicCell>
        <BasicCell align="right">
          {big.priceFormat(predictionTotal, 0)}
        </BasicCell>
        <BasicCell align="right">
          <PredictionChange value={predictionChangeFromLatest} />
        </BasicCell>
        <BasicCell align="right">{big.priceFormat(contractTotal, 0)}</BasicCell>
        <BasicCell align="right">
          {big.priceFormat(changeOrdersTotal, 0)}
        </BasicCell>
        <BasicCell align="right">{big.priceFormat(reservesTotal, 0)}</BasicCell>
        <BasicCell align="right">{big.priceFormat(receivedTotal, 0)}</BasicCell>
        <RightPaddedCell align="center">{status}</RightPaddedCell>
      </SecondaryRow>
      {isOpen ? (
        <>
          <Topic orderId={id} allSectionsOpen={allSectionsOpen} />
          {hasHeaderAfter ? <TableHeaderRow /> : null}
        </>
      ) : null}
    </>
  );
};

const ArrowCell = styled(Cell)`
  padding-right: ${({ theme }) => theme.margin[16]};
  padding-left: ${({ theme }) => theme.margin[16]};
`;

const BoldedCell = styled(Cell)`
  font-weight: bold;
`;

const BasicCell = styled(Cell)`
  padding-right: ${({ theme }) => theme.margin[24]};
`;

const RightPaddedCell = styled(Cell)`
  padding-right: ${({ theme }) => theme.margin[64]};
`;

export default Order;
