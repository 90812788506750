import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import { sortBy } from 'lodash';
import styled from 'styled-components';

import { getCurrentPeriodOrderSnapshots } from '../../../../store/reducers/order/snapshots';
import { getProject } from '../../../../store/reducers/project';
import { getProjectCurrentPeriodActualPoC } from '../../../../store/reducers/schedule/projectTimeline';
import {
  getSnapshotCreateRequests,
  getSnapshots,
} from '../../../../store/reducers/snapshot';

import {
  fetchProjects,
  getProjectTimelineForProject,
} from '../../../../store/actions';
import { fetchCurrentPeriodOrderSnapshots } from '../../../../store/actions/order/snapshots';
import { fetchSnapshots } from '../../../../store/actions/snapshot';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import {
  BigPercentageValue,
  BigPriceValue,
  DeltaBigCostValue,
  DeltaBigProfitValue,
} from '../../../../components/BigValue';
import Cell from '../../../../components/Cell';
import { Table } from '../../../../components/Table';

import OrderSnapshotRow from './OrderSnapshotRow';
import SnapshotRow from './SnapshotRow';
import TableHeader from './TableHeader';

type RevenueTableProps = {
  projectId: string;
};

const SnapshotTable = ({ projectId }: RevenueTableProps) => {
  const dispatch = useDispatch();
  const requestState = useSelector(getSnapshotCreateRequests());

  const memoedRequestState = useMemo(() => requestState, [requestState]);

  React.useEffect(() => {
    dispatch(fetchCurrentPeriodOrderSnapshots(projectId));
  }, [dispatch, projectId, memoedRequestState]);

  const sectionTitleText = useTxt(
    'reporting.currentPeriod.orderSnapshots.sectionTitle'
  );

  const snapshotRows =
    useRemoteData(getSnapshots(projectId), fetchSnapshots(projectId)) ?? [];

  const actualPoC = useRemoteData(
    getProjectCurrentPeriodActualPoC({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const percentageOfCompletion = actualPoC
    ? actualPoC.percentageOfCompletion
    : new Big(0);

  const project = useRemoteData(getProject(projectId), fetchProjects());

  const currentOrderSnapshots =
    useRemoteData(
      getCurrentPeriodOrderSnapshots(projectId),
      fetchCurrentPeriodOrderSnapshots(projectId)
    ) ?? [];

  const filteredOrderSnapshots = currentOrderSnapshots.filter(
    (item) =>
      item.predictionChangeBeforeLocking.abs().gte(1) ||
      item.targetChangeBeforeLocking.abs().gte(1)
  );

  const sortedOrderSnapshots = sortBy(filteredOrderSnapshots, (orderSnapshot) =>
    orderSnapshot.predictionChangeBeforeLocking.toNumber()
  );

  return (
    <Table>
      <TableHeader />
      <tbody>
        {snapshotRows.map((snapshot) => (
          <SnapshotRow
            key={snapshot.id}
            snapshot={snapshot}
            projectId={projectId}
          />
        ))}
        {project ? (
          <>
            <tr>
              <StyledCell
                colSpan={5}
                contentContainer={SectionTitleContentContainer}
              >
                <SectionTitleBar>{sectionTitleText}</SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPercentageValue
                    value={percentageOfCompletion}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue value={project.targetTotal} decimals={0} />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue
                    value={project.additionalTargetTotal}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <DeltaBigCostValue
                    value={project.targetChangeFromLatest}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue
                    value={project.costPredictionTotal}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <DeltaBigCostValue
                    value={project.costPredictionChangeFromLatest}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue value={project.contractTotal} decimals={0} />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue
                    value={project.changeOrdersTotal}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue value={project.reservesTotal} decimals={0} />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue value={project.revenueTotal} decimals={0} />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <BigPriceValue
                    value={project.revenueTotal.sub(
                      project.costPredictionTotal
                    )}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
              <StyledCell align="right" contentContainer={CellDiv}>
                <SectionTitleBar justifyContentEnd numberCell>
                  <DeltaBigProfitValue
                    value={project.revenuePredictionChangeFromLatest.sub(
                      project.costPredictionChangeFromLatest
                    )}
                    decimals={0}
                  />
                </SectionTitleBar>
              </StyledCell>
            </tr>
            {sortedOrderSnapshots.map((row) => (
              <OrderSnapshotRow
                key={`currentOrderSnapshot-${row.orderId}`}
                orderSnapshot={row}
              />
            ))}
          </>
        ) : null}
      </tbody>
    </Table>
  );
};

const StyledCell = styled(Cell)`
  padding: 0;
`;

const SectionTitleContentContainer = styled.div`
  margin-top: ${(props) => props.theme.margin[24]};
`;

type SectionTitleBarProps = {
  justifyContentEnd?: boolean;
  numberCell?: boolean;
  snapshotTypeId?: string;
};

const SectionTitleBar = styled.div<SectionTitleBarProps>`
  padding: ${(props) => props.theme.margin[20]}
    ${(props) => props.theme.margin[8]};

  width: 100%;
  height: ${(props) => props.theme.margin[20]};

  display: flex;
  justify-content: ${(props) =>
    props.justifyContentEnd ? 'flex-end' : 'space-between'};
  align-items: center;

  background: ${(props) => props.theme.color.primaryRowBackground};

  ${(props) => (props.numberCell ? '' : 'font-weight: bold;')}
  ${(props) => (props.numberCell ? '' : 'font-size: 1.125rem;')}
`;

const aligns = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

type CellDivProps = {
  align?: 'center' | 'right' | 'left';
};

const CellDiv = styled.div<CellDivProps>`
  position: relative;

  margin-top: ${(props) => props.theme.margin[24]};

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${({ align }) => (align ? `justify-content: ${aligns[align]};` : '')}
`;

export default SnapshotTable;
