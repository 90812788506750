import React from 'react';

import Big from 'big.js';
import styled, { css } from 'styled-components';

import {
  AlternateBigValue,
  AlternateDeltaBigValue,
} from '../../../../../../components/BigValue';
import Cell from '../../../../../../components/Cell';

type FuturePoCCellProps = {
  month: Date;
  currentPeriod: Date;
  timelineEntries: { date: Date; percentage: Big }[];
  previouslyPlannedEntries: { date: Date; percentage: Big }[];
  condensed?: boolean;
  borderBottom: 'dark' | 'light';
};

export const FuturePoCCell = ({
  month,
  currentPeriod,
  timelineEntries,
  previouslyPlannedEntries,
  condensed,
  borderBottom,
}: FuturePoCCellProps) => {
  const filteredEntry = timelineEntries.find(
    (entry) =>
      entry.date.getMonth() === month.getMonth() &&
      entry.date.getFullYear() === month.getFullYear()
  );

  const bolded = !condensed;

  const filteredPreviousEntry = previouslyPlannedEntries.find(
    (entry) =>
      entry.date.getMonth() === month.getMonth() &&
      entry.date.getFullYear() === month.getFullYear()
  );

  const current =
    month.getMonth() === currentPeriod.getMonth() &&
    month.getFullYear() === currentPeriod.getFullYear();

  if (!filteredEntry) {
    return (
      <StyledCell
        current={current}
        condensed={condensed}
        borderBottom={borderBottom}
      />
    );
  }

  const difference = filteredEntry.percentage.sub(
    filteredPreviousEntry?.percentage ?? new Big(0)
  );

  return (
    <StyledCell
      current={current}
      contentContainer={NumberContainer}
      condensed={condensed}
      borderBottom={borderBottom}
    >
      <AlternateBigValue
        formatValue={difference}
        value={filteredEntry.percentage}
        decimals={0}
        bolded={bolded}
      />
      <AlternateDeltaBigValue value={difference} decimals={0} />
    </StyledCell>
  );
};

type SnapshotPoCCellProps = {
  snapshotId: string;
  timelineEntries: { snapshotId: string | null; percentage: Big }[];
  condensed?: boolean;
  borderBottom: 'dark' | 'light';
};

export const SnapshotPoCCell = ({
  snapshotId,
  timelineEntries,
  condensed,
  borderBottom,
}: SnapshotPoCCellProps) => {
  const filteredEntry = timelineEntries.find(
    (entry) => entry.snapshotId === snapshotId
  );

  const bolded = !condensed;

  if (!filteredEntry || filteredEntry.percentage.eq(0)) {
    return (
      <StyledCell
        current={false}
        condensed={condensed}
        borderBottom={borderBottom}
      />
    );
  }

  return (
    <StyledCell
      current={false}
      contentContainer={NumberContainer}
      condensed={condensed}
      borderBottom={borderBottom}
    >
      <AlternateBigValue
        formatValue={new Big(0)}
        value={filteredEntry.percentage}
        decimals={0}
        bolded={bolded}
      />
    </StyledCell>
  );
};

type StyledCellProps = {
  current: boolean;
  borderBottom: 'dark' | 'light';
};

const StyledCell = styled(Cell)<StyledCellProps>`
  width: 4rem;
  ${({ current, theme }) =>
    current
      ? css`
          border-left: 2px solid ${theme.color.blue};
          background: ${theme.color.whisper};
        `
      : css`
          border-left: 1px solid ${theme.color.graphiteB86};
        `}
  ${({ borderBottom, theme }) =>
    borderBottom === 'dark'
      ? css`
          border-bottom: 2px solid ${theme.color.graphiteB57};
        `
      : css`
          border-bottom: 1px solid ${theme.color.graphiteB86};
        `}
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

type DescriptionCellProps = {
  fontWeight: 'normal' | 'bold' | '900';
  indent?: boolean;
  borderBottom: 'dark' | 'light';
};

export const DescriptionCell = styled(Cell)<DescriptionCellProps>`
  position: sticky;
  left: 0px;

  ${({ indent, theme }) =>
    indent
      ? css`
          padding: 0 0 0 ${theme.margin[16]};
        `
      : ''}
  border-right: 2px solid ${({ theme }) => theme.color.graphiteB86};
  ${({ borderBottom, theme }) =>
    borderBottom === 'dark'
      ? css`
          border-bottom: 2px solid ${theme.color.graphiteB86};
        `
      : css`
          border-bottom: 1px solid ${theme.color.graphiteB86};
        `}

  min-width: 300px;
  max-width: 300px;
  width: 300px;

  background: white;

  font-weight: ${({ fontWeight }) => fontWeight};

  overflow: hidden;
`;

type CellDivProps = {
  align?: 'center' | 'right' | 'left';
  className?: string;
  condensed?: boolean;
};

export const DescriptionCellDiv = styled.div<CellDivProps>`
  /* Needed for children and grandchildren, not this component itself */
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
`;
