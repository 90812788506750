import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { APIUpdatedEntities, RawAPIUpdatedEntities } from '../../types/api';
import { mapRawUpdatedEntities } from '../../types/mappers';

import {
  makeApiActions,
  makeAction,
  ExtractActionTypes,
} from '../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
  POST,
} from '../../utils/api';
import { isDefined } from '../../utils/general';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { Dimension, DimensionValue } from '../reducers/manualEntry';

export type ManualEntryAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeApiActions('get', 'manualEntriesDimensions')<Dimension[]>(),
  ...makeApiActions('get', 'manualEntriesDimensionsInitial')<Dimension[]>(),
  ...makeAction('getManualEntriesValidItemsStarted')<{
    dimensionId: string;
  }>(),
  ...makeAction('getManualEntriesValidItemsFailure')<{
    dimensionId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getManualEntriesValidItemsSuccess')<{
    dimensionId: string;
    validItems: DimensionValue[];
  }>(),
  ...makeApiActions('post', 'manualEntries')<APIUpdatedEntities>(),
};

export const {
  getManualEntriesDimensionsStarted,
  getManualEntriesDimensionsFailure,
  getManualEntriesDimensionsSuccess,
  getManualEntriesDimensionsInitialStarted,
  getManualEntriesDimensionsInitialFailure,
  getManualEntriesDimensionsInitialSuccess,
  getManualEntriesValidItemsStarted,
  getManualEntriesValidItemsFailure,
  getManualEntriesValidItemsSuccess,
  postManualEntriesStarted,
  postManualEntriesFailure,
  postManualEntriesSuccess,
} = actionCreators;

const apiDimensionValue = t.exact(
  t.type({
    id: t.string,
    code: t.string,
    name: t.string,
  })
);

const apiDimensionType = t.exact(
  t.type({
    id: t.string,
    name: t.union([t.string, t.null]),
    mandatory: t.boolean,
    validItems: t.array(apiDimensionValue),
  })
);

async function toDimensions(u: unknown): Promise<Dimension[]> {
  const decodedDimensions = await tPromise.decode(t.array(apiDimensionType), u);

  return decodedDimensions.filter(isDefined);
}

async function fetchManualEntryDimensions(
  queryParams: string = ''
): Promise<Dimension[]> {
  const endpointUrl = `v1/manual-entries/dimensions${queryParams}`;

  const response = await GET(endpointUrl);

  return toDimensions(response);
}

export const requestManualEntryDimensions = (queryParams?: string) =>
  createAsyncThunk(fetchManualEntryDimensions, {
    args: [queryParams],
    initialAction: getManualEntriesDimensionsStarted(),
    successActionCreator: (response) =>
      getManualEntriesDimensionsSuccess(response),
    failureActionCreator: (error) =>
      getManualEntriesDimensionsFailure(apiErrorHandlingWithDecode(error)),
  });

async function fetchManualEntryDimensionsInitial(
  queryParams: string = ''
): Promise<Dimension[]> {
  const endpointUrl = `v1/manual-entries/dimensions${queryParams}`;

  const response = await GET(endpointUrl);

  return toDimensions(response);
}

export const requestManualEntryDimensionsInitial = (queryParams?: string) =>
  createAsyncThunk(fetchManualEntryDimensionsInitial, {
    args: [queryParams],
    initialAction: getManualEntriesDimensionsInitialStarted(),
    successActionCreator: (response) =>
      getManualEntriesDimensionsInitialSuccess(response),
    failureActionCreator: (error) =>
      getManualEntriesDimensionsInitialFailure(
        apiErrorHandlingWithDecode(error)
      ),
  });

async function toDimensionValues(u: unknown): Promise<DimensionValue[]> {
  const decodedValues = await tPromise.decode(t.array(apiDimensionValue), u);

  return decodedValues.filter(isDefined);
}

async function fetchDimensionValues(
  dimensionId: string
): Promise<DimensionValue[]> {
  const response = await GET(
    `v1/manual-entries/dimensions/${dimensionId}/valid-items`
  );

  return toDimensionValues(response);
}

export const requestDimensionValues = (dimensionId: string) =>
  createAsyncThunk(fetchDimensionValues, {
    args: [dimensionId],
    initialAction: getManualEntriesValidItemsStarted({ dimensionId }),
    successActionCreator: (validItems) =>
      getManualEntriesValidItemsSuccess({ dimensionId, validItems }),
    failureActionCreator: (error) =>
      getManualEntriesValidItemsFailure({
        dimensionId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export type APIManualEntryBody = {
  purchaseInvoiceHeaderId: string;
  amount: string;
  description: string;
  vatCodeId: string | null;
  accountId: string | null;
  dimensionItemIds: string[];
};

const putNewManualEntry = (manualEntryBody: APIManualEntryBody) =>
  POST<RawAPIUpdatedEntities>(`v1/manual-entries`, manualEntryBody).then(
    mapRawUpdatedEntities
  );

export const createManualEntry = (
  manualEntryBody: APIManualEntryBody
): Thunk => (dispatch, _) => {
  dispatch(postManualEntriesStarted());
  putNewManualEntry(manualEntryBody).then(
    (updatedEntities) => {
      dispatch(postManualEntriesSuccess(updatedEntities));
    },
    (error) => {
      dispatch(postManualEntriesFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};
