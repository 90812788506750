import React from 'react';

import styled from 'styled-components';

import Txt from '../../../../../components/Txt';

import getWidths from '../../../../../utils/headerWidth';

export const TableHeader = () => (
  <thead>
    <tr>
      <TxtTh name="description" />
      <TxtTh name="quantity" />
      <TxtTh name="unit" />
      <TxtTh name="unitPrice" />
      <TxtTh name="selectOrder" />
      <TxtTh name="selectTopic" />
      <TxtTh name="selectAnalysis" />
      <th>
        <TopOverflowContainer>
          <TopOverFlowElement>
            <Txt id="order.addTargetRowsModal.tableHeader.alsoCreateOrderRows" />
          </TopOverFlowElement>
        </TopOverflowContainer>
      </th>
      <TxtTh name="none" />
    </tr>
  </thead>
);

const tableColumns = {
  description: { relativeWidth: 3 },
  quantity: { relativeWidth: 2 },
  unit: { relativeWidth: 2 },
  unitPrice: { relativeWidth: 2 },
  selectOrder: { relativeWidth: 3 },
  selectTopic: { relativeWidth: 3 },
  selectAnalysis: { relativeWidth: 3 },
  alsoCreateOrderRows: { relativeWidth: 1 },
  none: { relativeWidth: 1 },
};

type ThProps = {
  name: keyof typeof tableColumns;
};

const Th = styled.th<ThProps>`
  padding: ${(props) => props.theme.margin[8]};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  align-items: center;
  text-align: left;
`;

const TxtTh = ({ name }: ThProps) => (
  <Th name={name}>
    <Txt id={`${textIdHead}${name}` as const} component="b" />
  </Th>
);

const textIdHead = 'order.addTargetRowsModal.tableHeader.';

const getColumnPercent = getWidths(tableColumns);

const TopOverflowContainer = styled.div`
  position: relative;
  max-height: 20px;
`;

const TopOverFlowElement = styled.b``;
