import { APIActualCostAttachment } from '../../types/api';
import { ID } from '../../types/general';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import { GET, apiErrorHandlingWithDecode, BackendError } from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { getActualCostAttachmentRequest } from '../reducers/actualCostAttachments';

export type ActualCostAttachmentsAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('getActualCostAttachmentsStarted')<{ orderId: string }>(),
  ...makeAction('getActualCostAttachmentsFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostAttachmentsSuccess')<{
    orderId: string;
    actualCostAttachments: APIActualCostAttachment[];
  }>(),
};

export const {
  getActualCostAttachmentsStarted,
  getActualCostAttachmentsSuccess,
  getActualCostAttachmentsFailure,
} = actionCreators;

const fetchActualCostAttachmentsForOrder = (orderId: ID) => {
  return GET<APIActualCostAttachment[]>(
    `v1/orders/${orderId}/actual-costs-attachments`
  );
};

export const requestActualCostAttachmentsForOrder = (orderId: ID): Thunk => (
  dispatch
) => {
  dispatch(
    createAsyncThunk(fetchActualCostAttachmentsForOrder, {
      args: [orderId],
      isPending: flow(
        getActualCostAttachmentRequest(orderId),
        remoteData.isLoading
      ),
      initialAction: getActualCostAttachmentsStarted({ orderId }),
      successActionCreator: (actualCostAttachments) =>
        getActualCostAttachmentsSuccess({ orderId, actualCostAttachments }),
      failureActionCreator: (error) =>
        getActualCostAttachmentsFailure({
          orderId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};
