import React from 'react';

import { Big } from 'big.js';
import styled, { css } from 'styled-components';

import { IconButton } from '../../../components/Buttons';
import Txt from '../../../components/Txt';

import * as big from '../../../utils/big';

import { IconRight, IconDown } from '../../../assets/svg';

type VerticalAccordionProps = React.PropsWithChildren<{
  snapshotDescription: string;
  predictionChangeBeforeLocking: Big;
  targetChangeBeforeLocking: Big;
  arrivalsChangeBeforeLocking: Big;
  isOpen: boolean;
  toggle: () => void;
  changeLogEntryCount: number;
}>;

type VerticalAccordionContainerProps = {
  isOpen: boolean;
};

const VerticalAccordionContainer = styled.div`
  width: 100%;
  height: 2.313rem;

  display: grid;
  align-items: center;

  align-self: center;

  background: ${(props) => props.theme.color.primaryRowBackground};

  justify-items: start;

  grid-template-columns: 0.5fr 2fr 1fr 4fr 1fr 4fr 1fr 4fr 0.5fr;
`;

const VerticalAccordionContent = styled.div<VerticalAccordionContainerProps>`
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  ${(props) =>
    props.isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  animation: 300ms ease-in forwards growDown;
  transform-origin: top center;
`;

const InlineDiv = styled.div`
  padding: 0 ${(props) => props.theme.margin[8]};
  height: 2rem;
  display: flex;
  align-items: center;
`;

const LeftPaddedSpan = styled.span`
  padding-left: ${(props) => props.theme.margin[4]};
`;

const ContainerDiv = styled.div`
  margin-bottom: ${(props) => props.theme.margin[4]};
  border: 1px solid lightgrey;
  border-radius: 0 0 0.25rem 0.25rem;
`;

/**
 * Accordion component which takes default isOpen state and onToggle callback for
 * listening the accordions open/closed state.
 */
// eslint-disable-next-line import/prefer-default-export
export const VerticalAccordion = ({
  toggle,
  isOpen,
  children,
  snapshotDescription,
  predictionChangeBeforeLocking,
  targetChangeBeforeLocking,
  arrivalsChangeBeforeLocking,
  changeLogEntryCount,
}: VerticalAccordionProps) => (
  <ContainerDiv>
    <VerticalAccordionContainer>
      {changeLogEntryCount > 0 ? (
        <StyledIconButton
          type="button"
          icon={isOpen ? IconDown : IconRight}
          onClick={() => {
            toggle();
          }}
        />
      ) : (
        <div />
      )}
      <InlineDiv>
        <b>{`${snapshotDescription}:`}</b>
      </InlineDiv>
      <div />
      {predictionChangeBeforeLocking.abs().gt(1) ? (
        <InlineDiv>
          <b>
            <Txt id="comments.modal.changes.predictionChangeBeforeLocking" />
          </b>{' '}
          <LeftPaddedSpan>
            {big.priceFormat(predictionChangeBeforeLocking, 0)}
          </LeftPaddedSpan>
        </InlineDiv>
      ) : (
        <div />
      )}
      <div />
      {targetChangeBeforeLocking.abs().gt(1) ? (
        <InlineDiv>
          <b>
            <Txt id="comments.modal.changes.targetChangeBeforeLocking" />
          </b>{' '}
          <LeftPaddedSpan>
            {big.priceFormat(targetChangeBeforeLocking, 0)}
          </LeftPaddedSpan>
        </InlineDiv>
      ) : (
        <div />
      )}
      <div />
      {arrivalsChangeBeforeLocking.abs().gt(1) ? (
        <InlineDiv>
          <b>
            <Txt id="comments.modal.changes.arrivalsChangeBeforeLocking" />
          </b>{' '}
          <LeftPaddedSpan>
            {big.priceFormat(arrivalsChangeBeforeLocking, 0)}
          </LeftPaddedSpan>
        </InlineDiv>
      ) : (
        <div />
      )}
      <div />
    </VerticalAccordionContainer>
    <VerticalAccordionContent isOpen={isOpen}>
      {children}
    </VerticalAccordionContent>
  </ContainerDiv>
);

const StyledIconButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.margin[8]};
`;
