import { APIUser, APIProjectUsers } from '../../types/api';
import { ID } from '../../types/general';
import { toAPIProjectUsers } from '../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import { GET, BackendError, apiErrorHandlingWithDecode } from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk } from '../../utils/thunk';

import { selectProjectUsers } from '../reducers/projectUsers';

const actionCreators = {
  ...makeAction('getProjectUsersStarted')<{ projectId: string }>(),
  ...makeAction('getProjectUsersFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getProjectUsersSuccess')<{
    projectId: string;
    users: APIProjectUsers[];
  }>(),
};

export const {
  getProjectUsersStarted,
  getProjectUsersSuccess,
  getProjectUsersFailure,
} = actionCreators;

export type ProjectUsersAction = ExtractActionTypes<typeof actionCreators>;

const getProjectUsers = async (projectId: string) => {
  const apiUsers = await GET<APIUser[]>(`v1/projects/${projectId}/users`);

  return apiUsers.map(toAPIProjectUsers);
};

export const fetchProjectUsers = (projectId: ID) =>
  createAsyncThunk(getProjectUsers, {
    args: [projectId],
    isPending: flow(selectProjectUsers(projectId), remoteData.isLoading),
    initialAction: getProjectUsersStarted({ projectId }),
    successActionCreator: (users) =>
      getProjectUsersSuccess({ projectId, users }),
    failureActionCreator: (error) =>
      getProjectUsersFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
