import { APIOrder } from '../../../types/api';

import { flow } from '../../../utils/function';

import { ActionTypes as Action } from '../../actionTypes';
import { getSortOrderForKey, SortState } from '../helpers/sort';
import { AppState } from '../index';

export type SortableKey = Extract<keyof APIOrder, 'visibleCode'>;
export type SortByState = SortState<SortableKey>;

const initialSortByState: SortByState = [
  {
    key: 'visibleCode',
    sortOrder: 'Ascending',
  },
];

function sortByReducer(
  state: SortByState = initialSortByState,
  action: Action
): SortByState {
  switch (action.type) {
    case 'ORDER_SORT_TOGGLED': {
      const { sortableKey } = action.payload;
      const oldOrder = getSortOrderForKey(state, sortableKey);
      const newOrder = oldOrder === 'Ascending' ? 'Descending' : 'Ascending';

      const otherOrderings = state.filter(({ key }) => key !== sortableKey);

      return [{ key: sortableKey, sortOrder: newOrder }, ...otherOrderings];
    }
    default: {
      return state;
    }
  }
}

export function getSortOrders({
  orders: { sortOrders },
}: AppState): SortByState {
  return sortOrders;
}

export const getSortOrderFor = (sortableKey: 'visibleCode') =>
  flow(getSortOrders, (sortOrders) =>
    getSortOrderForKey(sortOrders, sortableKey)
  );

export default sortByReducer;
