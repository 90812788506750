import React, { useState } from 'react';

import { sortBy } from 'lodash';

import { APIProject } from '../../store/reducers/project';

import { ID } from '../../types/general';

import { searchFilter } from '../../utils/search';

import SearchInput from '../Input/SearchInput';
import {
  ProjectMenuContainer,
  ProjectButton,
  ProjectsList,
} from './Components';

type Props = {
  projects: APIProject[];
  onClickProject: (projectId: ID) => void;
};

// TODO: Show last visited projects on top! This needs them to be stored to
// localStorage, so I'll not implement it now.

const ProjectMenu = ({ projects, onClickProject }: Props) => {
  const [searchText, setSearchText] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const mappedProjects = projects.map((project) => {
    return {
      name: project.name,
      code: project.code,
    };
  });

  // don't include unnecessary properties (e.g. id)
  const filteredMappedProjects = searchFilter(mappedProjects, searchText);

  const filteredProjects = projects.filter((el) => {
    return filteredMappedProjects.some((entry) => {
      return entry.code === el.code && entry.name === el.name;
    });
  });

  const sortedAndFiltered = sortBy(filteredProjects, (entry) => entry.code);

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <ProjectMenuContainer>
      <SearchInput
        value={searchText}
        onChange={onSearchInputChange}
        handleClearButtonClick={() => setSearchText('')}
        ref={inputRef}
      />
      <ProjectsList>
        {sortedAndFiltered.map((project) => (
          <ProjectButton
            onClick={() => onClickProject(project.id)}
            key={`project-selection-${project.id}`}
            data-testid="project-selection-button"
          >
            {`${project.code} ${project.name}`}
          </ProjectButton>
        ))}
      </ProjectsList>
    </ProjectMenuContainer>
  );
};

export default ProjectMenu;
