import React from 'react';

import styled, { css } from 'styled-components';
import { v1 as uuid } from 'uuid';

import { IconInvalid } from '../../assets';

type InvalidProp = {
  invalid: boolean;
};

type ContainerProps = {
  paddingRight?: string;
  paddingBottom?: string;
  flex?: boolean;
};

export const Container = styled.div<InvalidProp & ContainerProps>`
  padding-right: ${({ paddingRight }) => paddingRight ?? '0'};

  ${(props) =>
    props.flex &&
    css`
      padding-bottom: ${props.paddingBottom ?? props.theme.margin[24]};
      display: flex;
      align-items: center;
      flex-grow: 1;
    `}

  ${(props) =>
    !props.invalid &&
    !props.flex &&
    css`
      padding-bottom: ${props.paddingBottom ?? props.theme.margin[24]};
    `}
`;

const Label = styled.label<InvalidProp & ContainerProps>`
  ${(props) =>
    props.flex
      ? css`
          padding: 0 0 ${props.theme.margin[8]} 0;
        `
      : css`
          padding: ${props.theme.margin[16]} ${props.theme.margin[16]}
            ${props.theme.margin[8]} 0;
        `}

  display: block;
  font-weight: bold;
  ${(props) =>
    props.invalid &&
    css`
      color: ${props.theme.color.red};
    `}
`;

const Input = styled.input<InvalidProp>`
  border-radius: ${(props) => props.theme.margin[4]};
  border: 1px solid ${(props) => props.theme.color.inputBorder};

  padding: ${(props) => `0 ${props.theme.margin[16]}`};

  height: ${(props) => props.theme.margin[40]};
  width: 100%;

  background: ${(props) => props.theme.color.white};

  color: ${(props) => props.theme.color.pitch};

  &:hover {
    border-color: ${(props) => props.theme.color.graphiteB76};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.color.indigo};
  }

  ${(props) =>
    props.invalid &&
    css`
      border-color: ${props.theme.color.red};
      padding-right: ${props.theme.margin[40]};
      background: ${props.theme.color.white} url(${IconInvalid}) no-repeat
        scroll right ${props.theme.margin[8]} center;
    `}
`;

export const ErrorMessage = styled.div`
  padding: ${(props) =>
    `${props.theme.margin[4]} 0 ${props.theme.margin[10]} ${props.theme.margin[16]}`};
  color: ${(props) => props.theme.color.red};
`;

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  optional?: boolean;
  errorMessage?: string | null;
  paddingRight?: string;
  paddingBottom?: string;
  flexContainer?: boolean;
}

export default (props: TextInputProps) => {
  const {
    label,
    optional,
    errorMessage,
    paddingRight,
    paddingBottom,
    flexContainer,
    ...inputFieldProps
  } = props;

  const inputId = `text-input-${uuid()}`;
  const inputInvalid = typeof errorMessage === 'string';

  return (
    <Container
      invalid={inputInvalid}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      flex={flexContainer}
    >
      <Label htmlFor={inputId} invalid={inputInvalid}>
        {label} {optional ? <StyledSpan>Vapaaehtoinen</StyledSpan> : null}
      </Label>
      <Input
        {...inputFieldProps}
        type="text"
        id={inputId}
        invalid={inputInvalid}
      />
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </Container>
  );
};

const StyledSpan = styled.span`
  font-weight: normal;
  color: ${(props) => props.theme.color.graphiteB57};
`;
