import styled, { css } from 'styled-components';

type TableTextInputProps = {
  invalid?: boolean;
  align?: 'right' | 'left' | 'center';
  background?: string;
};

export default styled.input<TableTextInputProps>`
  /* Normal input border color was way too light when there is no label. Thus
    I decided to just use the hover color instead and disable hover. */
  border: 1px solid ${(props) => props.theme.color.graphiteB76};

  padding: ${(props) => props.theme.margin[4]};

  width: 100%;
  height: ${(props) => props.theme.margin[24]};

  font-size: ${(props) => props.theme.fontSize.base};
  line-height: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.color.pitch};

  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  &:focus {
    border-color: ${(props) => props.theme.color.indigo};
  }

  &::placeholder {
    text-align: right;
  }

  ${(props) =>
    props.invalid &&
    css`
      /* stylelint-disable declaration-no-important -- TODO: Needs to be refactored away */
      border-color: ${props.theme.color.red} !important;
    `}

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
      background-position: 99% 50%;
    `}
`;
