import { combineReducers } from 'redux';

import changeLogEntriesReducer from './changeLogEntries';
import chatMessagesReducer from './chatMessages';
import deleteOrderReducer from './deleteOrder';
import moveOrderRowsReducer from './moveOrderRows';
import optionsReducer from './options';
import orderDataReducer from './order';
import procurementReferenceNumberReducer from './procurementReferenceNumber';
import orderSnapshotsReducer from './snapshots';
import sortReducer from './sortOrders';

export default combineReducers({
  changeLogEntries: changeLogEntriesReducer,
  chatMessages: chatMessagesReducer,
  moveOrderRows: moveOrderRowsReducer,
  options: optionsReducer,
  snapshots: orderSnapshotsReducer,
  orderData: orderDataReducer,
  deleteRequests: deleteOrderReducer,
  sortOrders: sortReducer,
  procurementReferenceNumbers: procurementReferenceNumberReducer,
});
