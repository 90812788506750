import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { postInvoiceAttachmentsUpload } from '../../../store/actions';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../components/Buttons';
import FileUpload from '../../../components/FileUpload/FileUpload';
import { Spinner } from '../../../components/Loading';
import Modal, {
  Content,
  Header,
  Footer,
} from '../../../components/Modal/Modal';
import Txt from '../../../components/Txt';

import { TextId } from '../../../localization';

export type FileUploadModalProps = {
  titleTextId: TextId;
  parentId: string;
  onClose: () => void;
};

export const FileUploadModal = ({
  titleTextId,
  parentId,
  onClose,
}: FileUploadModalProps) => {
  const [uploadData, setUploadData] = useState<{ files: File[] }>({
    files: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const updateData = (newFiles: File[]) =>
    setUploadData({ ...uploadData, files: newFiles });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmitting(true);

    postInvoiceAttachmentsUpload(parentId, uploadData.files, dispatch).then(
      () => {
        setUploadData({ files: [] });
        setIsSubmitting(false);
        onClose();
      },
      () => {
        setUploadData({ files: [] });
        setIsSubmitting(false);
      }
    );
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer onSubmit={onSubmit}>
        <Header>
          <Txt id={titleTextId} />
        </Header>
        <StyledContent>
          <FileUpload multiple updateFilesCb={updateData} />
        </StyledContent>
        <Footer>
          <ButtonGroup>
            <SecondaryButton
              type="button"
              onClick={() => onClose()}
              disabled={isSubmitting}
            >
              <Txt id="common.cancel" />
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              disabled={isSubmitting || uploadData.files.length === 0}
            >
              {isSubmitting ? (
                <Spinner size="1rem" />
              ) : (
                <Txt id="common.send" />
              )}
            </PrimaryButton>
          </ButtonGroup>
        </Footer>
      </FormContainer>
    </Modal>
  );
};

const StyledContent = styled(Content)`
  padding: ${(props) => `${props.theme.margin[32]}`};
  max-height: 100%;
  min-height: 200px;
`;

export const FormContainer = styled.form`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);

  width: 25%;
  min-width: 500px;

  display: flex;
  flex-direction: column;
`;
