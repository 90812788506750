import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { ExtractActionTypes, makeAction } from '../../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
} from '../../../utils/api';
import { bigString, dateString } from '../../../utils/decoders';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk } from '../../../utils/thunk';

import {
  getOrderSnapshotsFetchRequest,
  getCurrentOrderSnapshotsFetchRequest,
  OrderSnapshot,
} from '../../reducers/order/snapshots';
import { getOrderSnapshotsForSnapshotIdFetchRequest } from '../../reducers/snapshot';

export type OrderSnapshotsAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getOrderSnapshotsStarted')<{ orderId: string }>(),
  ...makeAction('getOrderSnapshotsFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getOrderSnapshotsSuccess')<{
    orderId: string;
    orderSnapshots: OrderSnapshot[];
  }>(),
  ...makeAction('getAllOrderSnapshotsForSnapshotStarted')<{
    snapshotId: string;
  }>(),
  ...makeAction('getAllOrderSnapshotsForSnapshotFailure')<{
    snapshotId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getAllOrderSnapshotsForSnapshotSuccess')<{
    snapshotId: string;
    orderSnapshots: OrderSnapshot[];
  }>(),
  ...makeAction('getCurrentPeriodOrderSnapshotsStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getCurrentPeriodOrderSnapshotsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getCurrentPeriodOrderSnapshotsSuccess')<{
    projectId: string;
    orderSnapshots: OrderSnapshot[];
  }>(),
};

export const {
  getOrderSnapshotsStarted,
  getOrderSnapshotsFailure,
  getOrderSnapshotsSuccess,
  getAllOrderSnapshotsForSnapshotStarted,
  getAllOrderSnapshotsForSnapshotFailure,
  getAllOrderSnapshotsForSnapshotSuccess,
  getCurrentPeriodOrderSnapshotsStarted,
  getCurrentPeriodOrderSnapshotsFailure,
  getCurrentPeriodOrderSnapshotsSuccess,
} = actionCreators;

const apiOrderSnapshotType = t.exact(
  t.type({
    snapshotId: t.string,
    snapshotDescription: t.union([t.string, t.null]),
    companyReportingPeriodDescription: t.union([t.string, t.null]),
    orderId: t.string,
    projectId: t.string,
    predictionChangeBeforeLocking: bigString,
    targetChangeBeforeLocking: bigString,
    arrivalsChangeBeforeLocking: bigString,
    targetTotal: bigString,
    additionalTargetTotal: bigString,
    costPredictionTotal: bigString,
    contractTotal: bigString,
    changeOrdersTotal: bigString,
    reservesTotal: bigString,
    orderIsDeleted: t.boolean,
    orderName: t.union([t.string, t.null]),
    orderVisibleCode: t.union([t.string, t.null]),
    current: t.boolean,
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export type APIOrderSnapshot = t.TypeOf<typeof apiOrderSnapshotType>;

export async function toOrderSnapshot(u: unknown): Promise<OrderSnapshot[]> {
  const apiOrderSnapshots = await tPromise.decode(
    t.array(apiOrderSnapshotType),
    u
  );

  return apiOrderSnapshots;
}

const getOrderSnapshots = async (orderId: string) => {
  const response = await GET(`v1/orders/${orderId}/snapshots`);

  return toOrderSnapshot(response);
};

const getCurrentPeriodOrderSnapshots = async (projectId: string) => {
  const response = await GET(
    `v1/projects/${projectId}/current-period-order-snapshots`
  );

  return toOrderSnapshot(response);
};

export const fetchOrderSnapshots = (orderId: string) =>
  createAsyncThunk(getOrderSnapshots, {
    args: [orderId],
    isPending: flow(
      getOrderSnapshotsFetchRequest(orderId),
      remoteData.isLoading
    ),
    initialAction: getOrderSnapshotsStarted({ orderId }),
    successActionCreator: (response) =>
      getOrderSnapshotsSuccess({
        orderId,
        orderSnapshots: response,
      }),
    failureActionCreator: (error) =>
      getOrderSnapshotsFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export const fetchCurrentPeriodOrderSnapshots = (projectId: string) =>
  createAsyncThunk(getCurrentPeriodOrderSnapshots, {
    args: [projectId],
    isPending: flow(
      getCurrentOrderSnapshotsFetchRequest(projectId),
      remoteData.isLoading
    ),
    initialAction: getCurrentPeriodOrderSnapshotsStarted({ projectId }),
    successActionCreator: (response) =>
      getCurrentPeriodOrderSnapshotsSuccess({
        projectId,
        orderSnapshots: response,
      }),
    failureActionCreator: (error) =>
      getCurrentPeriodOrderSnapshotsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

const getOrderSnapshotsForSnapshotId = async (snapshotId: string) => {
  const response = await GET(`v1/snapshots/${snapshotId}/orders`);

  return toOrderSnapshot(response);
};

export const fetchOrderSnapshotsForSnapshotId = (snapshotId: string) =>
  createAsyncThunk(getOrderSnapshotsForSnapshotId, {
    args: [snapshotId],
    isPending: flow(
      getOrderSnapshotsForSnapshotIdFetchRequest(snapshotId),
      remoteData.isLoading
    ),
    initialAction: getAllOrderSnapshotsForSnapshotStarted({ snapshotId }),
    successActionCreator: (response) =>
      getAllOrderSnapshotsForSnapshotSuccess({
        snapshotId,
        orderSnapshots: response,
      }),
    failureActionCreator: (error) =>
      getAllOrderSnapshotsForSnapshotFailure({
        snapshotId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
