import { Reducer } from 'redux';

import * as api from '../../../utils/api';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { OrderOptions } from '../../actions/order/options';
import { ActionTypes as Action } from '../../actionTypes';

/*
This reducer is very different from other reducers:
almost all other reducers use data that is handled by
ids: some Record<string, A> of type A data.

This reducer has data that is not handled by ids:
this data about suppliers, vat codes, accounts and
cost types are universal across all projects at SD.

Some of these data might change (SD might accept new suppliers),
but others might never change (it is unlikely that the
Finnish goverment would add another VAT code for example).
*/

type Err = api.BackendError | undefined;
type RemoteData<A> = remoteData.RemoteData<A, Err>;
export type OrderOptionsState = RemoteData<OrderOptions>;
const initialSupplierState = remoteData.notAsked;

const reducer: Reducer<OrderOptionsState, Action> = (
  state: OrderOptionsState = initialSupplierState,
  action: Action
): OrderOptionsState => {
  switch (action.type) {
    case 'GET_ORDER_DROPDOWN_OPTIONS_STARTED': {
      return remoteData.loading;
    }
    case 'GET_ORDER_DROPDOWN_OPTIONS_FAILURE': {
      const { error } = action.payload;

      return remoteData.fail(error);
    }
    case 'GET_ORDER_DROPDOWN_OPTIONS_SUCCESS': {
      const orderOptions = action.payload;

      return remoteData.succeed(orderOptions);
    }
    default:
      return state;
  }
};

function select<K extends keyof OrderOptions>(
  key: K
): Selector<RemoteData<OrderOptions[K]>> {
  return ({ orders: { options } }) =>
    remoteData.map(options, ({ [key]: value }) => value);
}

export const getSuppliers = select('suppliers');
export const getVatCodes = select('vatCodes');
export const getCostTypes = select('costTypes');
export const getAccounts = select('accounts');
export const getStatuses = select('statuses');

export default reducer;
