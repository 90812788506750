import React from 'react';
import { useSelector } from 'react-redux';

import { getOrderRowsByTopicId } from '../../../../store/reducers/orderRow';

import { ReceiveViewModeOptions, ID } from '../../../../types/general';

import useRouteParams from '../../../../hooks/useRouteParams';

import { ReceiveAllButton } from '../../../../components/Buttons';
import Cell from '../../../../components/Cell';

import CAN, {
  CaslOrderRequestParams,
} from '../../../../utils/caslUserPermissions';

import { orderRowFullyReceived } from '../../ViewModeWrappers/Receive/receiveModeUtils';

type Props = {
  viewModeOptions: ReceiveViewModeOptions;
  topicId: ID;
};

const TopicRowReceiveControls = ({ viewModeOptions, topicId }: Props) => {
  const { projectId } = useRouteParams();

  const orderRows = useSelector(getOrderRowsByTopicId(topicId));

  // Check if all eligible order rows in this topic have their "select all"
  // button ticked or not.
  const allSelectedTrueForAllRows = orderRows
    .filter(
      (row) =>
        row.quantity !== null &&
        row.unitPrice !== null &&
        !orderRowFullyReceived(row)
    )
    .every(
      (row) =>
        viewModeOptions.state[row.id] &&
        viewModeOptions.state[row.id].allSelected
    );

  // Check if every eligible row has already everything received
  const everythingReceived = orderRows
    .filter((row) => row.quantity !== null && row.unitPrice !== null)
    .every(orderRowFullyReceived);

  const onClickReceiveAll = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    viewModeOptions.setSelectAllForEveryRowInTopic(
      topicId,
      !allSelectedTrueForAllRows
    );
  };

  const ability = new CaslOrderRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <>
      <Cell colSpan={2} align="right">
        <ReceiveAllButton
          active={allSelectedTrueForAllRows || everythingReceived}
          disabled={everythingReceived || !allowedUser}
          onClick={onClickReceiveAll}
        >
          100%
        </ReceiveAllButton>
      </Cell>
    </>
  );
};

export default TopicRowReceiveControls;
