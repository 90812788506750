import React from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { IconToggle } from '../assets/index';

import { TextId } from '../localization';
import Txt from './Txt';

type Color = keyof DefaultTheme['color'];

export type StatusPillProps = {
  textId: TextId;
  colorName: Color;
  onClick?: () => void | undefined;
  isClickable?: boolean;
};

const StatusPill = ({
  textId,
  colorName,
  onClick,
  isClickable,
}: StatusPillProps) => {
  return (
    <PillButton onClick={onClick} disabled={!isClickable} colorName={colorName}>
      {isClickable ? <img src={IconToggle} alt="Toggle kuvake" /> : null}
      <Txt id={textId} component={Span} />
    </PillButton>
  );
};
export default StatusPill;

type PillButtonProps = {
  colorName: Color;
};

const PillButton = styled.button<PillButtonProps>`
  border-radius: ${({ theme }) => theme.margin[20]};
  border: none;

  height: ${({ theme }) => theme.margin[18]};

  display: flex;
  align-items: center;

  background: ${({ colorName, theme: { color } }) => color[colorName]};

  font-family: 'Graphik', sans-serif;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: 500;
  color: ${({ theme }) => theme.color.white};
`;

const Span = styled.span`
  width: ${({ theme }) => theme.margin[56]};
`;
