import React from 'react';

import styled from 'styled-components';

import Modal from '../Modal/Modal';

// TODO: Apply some design on this one.

export const ErrorContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);

  padding: 1rem;

  min-width: 484px;

  display: flex;
  flex-flow: column nowrap;

  background: white;
`;

type ErrorModalProps = {
  onClose: () => void;
};

const ErrorModal = ({
  children,
  onClose,
}: React.PropsWithChildren<ErrorModalProps>) => (
  <Modal onClose={onClose}>
    <ErrorContainer>{children}</ErrorContainer>
  </Modal>
);

export default ErrorModal;
