import React from 'react';
import { useSelector } from 'react-redux';

import {
  getRenderableOrderRowById,
  getOrderRowById,
} from '../../../store/reducers/orderRow';
import {
  getOrderRowTargetRows,
  toTargetOrderRow,
} from '../../../store/reducers/target/targetRows';
import { getIsOuterBarOpen } from '../../../store/reducers/ui';

import * as Actions from '../../../store/actions';

import useRemoteData from '../../../hooks/useRemoteData';
import useRouteParams from '../../../hooks/useRouteParams';

import { TagsContainer } from '../../../components/Analysis/AnalysisTags';
import { BaseButtonWithUnderline } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import {
  OrderRowLeftBorderContainer,
  OrderRowRightPaddingContainer,
  OrderRowSmallerRightPaddingContainer,
} from '../../../components/Containers';
import { SecondaryRow } from '../../../components/Table';

import * as big from '../../../utils/big';

import AnalysisTags from './AnalysisTags';
import { InvoicesPerOrderRowModal } from './InvoicesPerOrderRowModal';
import StatusPill from './StatusPill';
import TargetedRow from './Target/TargetedRow';

type Props = {
  orderRowId: string;
};

export const OrderRow = ({ orderRowId }: Props) => {
  const { orderId, showTargetRows } = useRouteParams();
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const [isInvoiceModalVisible, setInvoiceModalVisibility] = React.useState(
    false
  );

  const orderRow = useSelector(
    getRenderableOrderRowById(orderRowId, outerBarOpen ? 2 : undefined)
  );

  const apiOrderRow = useSelector(getOrderRowById(orderRowId));

  const targetRows =
    useRemoteData(
      getOrderRowTargetRows({
        orderId,
        orderRowId,
      }),
      Actions.requestTargetRows({ orderId })
    ) ?? [];

  const targetOrderRow = toTargetOrderRow(apiOrderRow, targetRows);

  if (orderRow === undefined) {
    return null;
  }

  const {
    rowNumber,
    description,
    quantity,
    unit,
    unitPrice,
    arrivalQuantity,
    arrivalTotal,
    totalPrice,
    analysisListItemIds,
    remainingAmount,
    arrivalRowIds,
  } = orderRow;
  const arrived = `${arrivalQuantity} ${unit}`;

  const showInvoiceModalButton = arrivalRowIds.length > 0;

  return (
    <>
      {isInvoiceModalVisible ? (
        <InvoicesPerOrderRowModal
          orderRow={orderRow}
          onClose={() => setInvoiceModalVisibility(false)}
        />
      ) : null}
      <SecondaryRow>
        <Cell align="center">{rowNumber}</Cell>
        <Cell>{description}</Cell>
        {outerBarOpen ? (
          <Cell align="right">{`${quantity} ${unit}` ?? '-'}</Cell>
        ) : (
          <>
            <Cell align="right">{quantity ?? '-'}</Cell>
            <Cell align="center">{unit}</Cell>
          </>
        )}

        <Cell align="right">{unitPrice ?? '- €'}</Cell>
        {showTargetRows ? (
          <Cell align="right">
            {targetOrderRow.target.eq(0)
              ? ''
              : big.priceFormatRounded(
                  targetOrderRow.target,
                  outerBarOpen ? 0 : undefined
                )}
          </Cell>
        ) : null}
        <Cell
          align="right"
          contentContainer={
            showTargetRows || outerBarOpen
              ? OrderRowSmallerRightPaddingContainer
              : OrderRowRightPaddingContainer
          }
        >
          {totalPrice ?? '- €'}
        </Cell>
        {showTargetRows ? (
          <Cell align="right">
            {targetOrderRow.difference.eq(0)
              ? ''
              : big.priceFormatRounded(targetOrderRow.difference)}
          </Cell>
        ) : null}
        <Cell align="right" contentContainer={OrderRowLeftBorderContainer}>
          {arrived}
        </Cell>
        <Cell align="right">
          {showInvoiceModalButton ? (
            <BaseButtonWithUnderline
              onClick={() => setInvoiceModalVisibility(true)}
            >
              {arrivalTotal}
            </BaseButtonWithUnderline>
          ) : (
            arrivalTotal
          )}
        </Cell>
        {outerBarOpen && showTargetRows ? null : (
          <>
            <Cell align="center" contentContainer={TagsContainer}>
              <AnalysisTags
                orderRowId={orderRowId}
                analysisListItemIds={analysisListItemIds}
              />
            </Cell>
            <Cell align="center">
              <StatusPill orderRowId={orderRowId} isEditable={false} />
            </Cell>
          </>
        )}
        <Cell align="right">{remainingAmount}</Cell>
      </SecondaryRow>
      {showTargetRows ? (
        <>
          {targetRows.map((row) => (
            <TargetedRow key={`targeted-row-${row.id}`} row={row} />
          ))}
        </>
      ) : null}
    </>
  );
};

export default OrderRow;
