import styled from 'styled-components';

import { BaseButton } from '../Buttons';

export const CircleIconButtonContainer = styled(BaseButton)`
  position: relative;

  margin: 0 0 2rem 0;

  border: 0;
  border-radius: 50%;

  width: 1.5rem;
  height: 1.5rem;

  background-color: ${(props) => props.theme.color.white};

  font-size: 0.625rem;
  text-align: center;
  line-height: 1.5rem;
  color: ${(props) => props.theme.color.darkpurple};
`;

export const TransparentCircleButtonContainer = styled(
  CircleIconButtonContainer
)`
  display: flex;
  justify-content: center;
  background: transparent;
`;

export const NumberIndicator = styled.span`
  position: absolute;
  right: 0;
  top: 0;

  border-radius: 50%;

  width: 0.8rem;
  height: 0.8rem;

  background-color: ${(props) => props.theme.color.red};

  text-align: center;
  font-size: 0.5rem;
  font-weight: bold;
  line-height: 0.8rem;
  color: white;
`;
