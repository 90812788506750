import styled from 'styled-components';

import { IconTextButton } from './Buttons';

export const ToolBeltButton = styled(IconTextButton)`
  padding-left: ${(props) => props.theme.margin[24]};
  padding-right: ${(props) => props.theme.margin[24]};
`;

export const ToolBeltContainer = styled.div`
  position: sticky;
  top: 4.3rem;

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: ${(props) => props.theme.color.white};

  z-index: 1;
`;
