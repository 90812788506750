import React from 'react';

import Big from 'big.js';
import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';

import Cell from '../../../../components/Cell';

import * as big from '../../../../utils/big';

import { Tr } from './InvoiceRow';

type TableFooterProps = {
  arrivalsTotal: Big;
};

const TableFooter = ({ arrivalsTotal }: TableFooterProps) => {
  const totalText = useTxt('common.total.short');

  return (
    <>
      <Tr>
        <Cell colSpan={2} />
        <StyledCellWithBackground align="right">
          <LineBreakDiv>
            <Span>{`${totalText}: ${big.priceFormat(arrivalsTotal)}`}</Span>
          </LineBreakDiv>
        </StyledCellWithBackground>
        <Cell colSpan={2} />
      </Tr>
    </>
  );
};

const Span = styled.span`
  display: block;
  font-weight: bold;
`;

const LineBreakDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCellWithBackground = styled(Cell)`
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
`;

export default TableFooter;
