export const defaultStyle = {
  control: {
    fontSize: 14,
    fontWeight: 'normal',
    width: '100%',
    maxHeight: '30vh',
    maxWidth: '40vw',
  },
  '&multiLine': {
    control: {},
    highlighter: {
      border: '1px solid transparent',
      padding: '1rem 2rem 1rem 1rem',
    },
    input: {
      padding: '1rem 2rem 1rem 1rem',
      border: '1px solid #386CF1',
      borderRadius: '5px',
      overflowY: 'auto',
    },
  },
  suggestions: {
    list: {
      background: '#EAEAEA',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 12,
      height: 150,
      overflow: 'scroll',
    },
    item: {
      padding: '5px 5px',
      borderBottom: '1px solid rgba(100,0,0,0.15)',
      '&focused': {
        backgroundColor: '#450866',
        backgroundClip: 'border-box',

        fontWeight: 900,
        color: 'white',
      },
    },
  },
};

export const mentionsStyle = {
  backgroundColor: '#cee4e5',
  padding: 0,
  margin: 0,
  boxSizing: 'border-box',
};
