import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { getOrderById } from '../../../../store/reducers/order/order';
import { getIsOuterBarOpen } from '../../../../store/reducers/ui';

type OrderInfoTitleProps = {
  orderId: string;
};

const OrderInfoTitle: React.FC<OrderInfoTitleProps> = ({ orderId }) => {
  const order = useSelector(getOrderById(orderId));
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  if (!order) {
    return null;
  }

  const { contractor, name } = order;

  const orderInfo = `${order?.visibleCode}, ${name}${
    contractor && !outerBarOpen ? ', ' : ''
  }`;

  return (
    <Container>
      <Header>
        {orderInfo}
        {contractor && !outerBarOpen ? (
          <ContractorText>{contractor}</ContractorText>
        ) : null}
      </Header>
    </Container>
  );
};

const Container = styled.div`
  height: 37px;
  display: flex;
  align-items: center;
`;

const Header = styled.h1`
  font-family: BuildingSans, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const ContractorText = styled.span`
  font-weight: 300;
`;

export default OrderInfoTitle;
