import Big from 'big.js';
import { either, fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

import * as big from './big';

export const dateString = new t.Type<Date, string, unknown>(
  'dateString',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    either.chain(t.string.validate(u, c), (s) => {
      const d = new Date(s);

      return Number.isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
    }),
  (a) => a.toISOString()
);

export const apiCommonProps = t.type({
  id: t.string,
  isDeleted: t.boolean,
  updatedAt: dateString,
  createdAt: dateString,
});

export const bigString = new t.Type<Big, string, unknown>(
  'bigString',
  (u): u is Big => u instanceof Big,
  (u, c) =>
    either.chain(t.string.validate(u, c), (str) => {
      try {
        return t.success(new Big(str));
      } catch (_) {
        return t.failure(u, c);
      }
    }),
  (a) => a.toString()
);

export const bigInputString = new t.Type<Big, string, unknown>(
  'bigInputString',
  (u): u is Big => u instanceof Big,
  (u, c) =>
    either.chain(t.string.validate(u, c), (str) => {
      try {
        const value = big.fromInputString(str);

        if (!big.hasMaxNWholeNumbers(value, 12)) {
          return t.failure(u, c);
        }

        if (!big.hasMaxNDecimals(value, 4)) {
          return t.failure(u, c);
        }

        return t.success(value);
      } catch (_) {
        return t.failure(u, c);
      }
    }),
  (a) => a.toString()
);

export const bigPercentage = new t.Type<Big, string, unknown>(
  'bigInputString',
  (u): u is Big => u instanceof Big,
  (u, c) =>
    either.chain(bigInputString.validate(u, c), (value) => {
      if (value.lt(new Big(0))) {
        return t.failure(u, c, 'Too small');
      }

      if (value.gt(new Big(100))) {
        return t.failure(u, c, 'Too Big');
      }

      return t.success(value);
    }),
  (a) => a.toString()
);

// https://github.com/gcanti/io-ts/issues/373#issuecomment-544233232
interface MaxLengthStringShape<N> {
  readonly MaxLengthString: unique symbol;
  readonly length: N;
}

interface UniqueCodeShape {
  readonly uniqueCode: unique symbol;
}

export const maxLengthString = <N extends number>(len: N) =>
  t.brand(
    t.string,
    (s): s is t.Branded<string, MaxLengthStringShape<N>> => s.length <= len,
    'MaxLengthString'
  );

export const uniqueCode = (existingValues: string[]) =>
  t.brand(
    t.string,
    (s): s is t.Branded<string, UniqueCodeShape> => !existingValues.includes(s),
    'uniqueCode'
  );

type DecodeResult<Type> =
  | { kind: 'Invalid' }
  | { kind: 'Decoded'; value: Type };

export function decode<Type, Output, Input>(
  codec: t.Type<Type, Output, Input>,
  input: Input
): DecodeResult<Type> {
  return pipe(
    codec.decode(input),
    fold<t.Errors, Type, DecodeResult<Type>>(
      (_) => ({ kind: 'Invalid' }),
      (value) => ({ kind: 'Decoded', value })
    )
  );
}
