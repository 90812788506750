import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { getTopicsByOrderId } from '../../../store/reducers/topic';
import {
  getAreAllTopicsOpen,
  getIsOuterBarOpen,
} from '../../../store/reducers/ui';

import { uiStateToggleOpenAllTopics } from '../../../store/actions/ui';

import { OrderViewMode } from '../../../types/general';

import useTxt from '../../../hooks/useTxt';

import { StickyTableHeader } from '../../../components/Table';
import Txt from '../../../components/Txt';

import { isDefined } from '../../../utils/general';

import { IconDoubleDown, IconDoubleRight } from '../../../assets/svg';

import { Toggle, ToggleButton } from '../../../context/toggleContext';

type OrderTableHeaderProps = {
  orderViewMode: OrderViewMode;
  orderId: string;
};

export const OrderTableHeader = ({
  orderViewMode,
  orderId,
}: OrderTableHeaderProps) => {
  const dispatch = useDispatch();
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const allIds = useSelector(getAreAllTopicsOpen());
  const allOpenText = useTxt('worksection.workPackage.groups.open');
  const allClosedText = useTxt('worksection.workPackage.groups.closed');

  const topics = Object.values(useSelector(getTopicsByOrderId(orderId))).filter(
    isDefined
  );

  const allTopicsOpen = allIds.length === topics.length;

  const toggleAllOpenStatus = () => {
    if (allTopicsOpen) {
      dispatch(uiStateToggleOpenAllTopics([]));
    } else {
      dispatch(uiStateToggleOpenAllTopics(topics.map((topic) => topic.id)));
    }
  };

  return (
    <StyledTableHeader isEditMode={orderViewMode.type === 'edit'}>
      <Tr>
        <SelectTh
          align="center"
          colSpan={orderViewMode.type === 'edit' ? 2 : 1}
          isEditMode={orderViewMode.type === 'edit'}
        >
          <Toggle>
            <StyledDiv>
              <ToggleButton
                label={allTopicsOpen ? allOpenText : allClosedText}
                icon={allTopicsOpen ? IconDoubleDown : IconDoubleRight}
                onClick={toggleAllOpenStatus}
              />
            </StyledDiv>
          </Toggle>
        </SelectTh>
        <DescriptionTh>
          <Txt id="common.description" />
        </DescriptionTh>
        <QuantityTh>
          <Txt id="common.quantity" />
        </QuantityTh>
        {outerBarOpen ? null : (
          <UnitTh aria-label="unit-short">
            <Txt id="common.unit.short" />
          </UnitTh>
        )}
        <UnitPriceTh>
          <Txt id="common.unitPrice" />
        </UnitPriceTh>
        {orderViewMode.showTargetRows ? (
          <TargetTh outerBarOpen={outerBarOpen}>
            <Txt id="common.target" />
          </TargetTh>
        ) : null}
        <TotalSumTh>
          <Txt
            id={
              orderViewMode.showTargetRows
                ? 'common.prediction'
                : 'common.amount'
            }
          />
        </TotalSumTh>
        {orderViewMode.showTargetRows ? (
          <TargetTh outerBarOpen={outerBarOpen}>
            <Txt id="common.target.difference" />
          </TargetTh>
        ) : null}
        <ReceivedTh colSpan={2}>
          <Txt id="common.received" />
        </ReceivedTh>
        {orderViewMode.type === 'normal' &&
        !(orderViewMode.showTargetRows && outerBarOpen) ? (
          <>
            <AnalysisTh>
              <Txt id="common.analysis" />
            </AnalysisTh>
            <StatusTh>
              <Txt id="common.status" />
            </StatusTh>
          </>
        ) : null}
        {orderViewMode.type === 'edit' &&
        !(orderViewMode.showTargetRows && outerBarOpen) ? (
          <>
            <AnalysisTh>
              <Txt id="common.analysis" />
            </AnalysisTh>
            <EditModeStatusTh>
              <Txt id="common.status" />
            </EditModeStatusTh>
          </>
        ) : null}
        {orderViewMode.type === 'receive' ? (
          <>
            <ToBeReceivedTh>
              <Txt id="common.toBeReceived" />
            </ToBeReceivedTh>
            <ReceiveRestTh aria-label="Receive the remaining" />
          </>
        ) : null}
        <RemainingTh>
          <Txt
            id={
              orderViewMode.showTargetRows
                ? 'common.remainingAmountLong'
                : 'common.remainingAmount'
            }
          />
        </RemainingTh>
        {orderViewMode.type === 'edit' ? <ActionTh /> : null}
      </Tr>
    </StyledTableHeader>
  );
};

const StyledTableHeader = styled(StickyTableHeader)<{ isEditMode: boolean }>`
  top: ${(props) => (props.isEditMode ? '8.8rem' : '6.55rem')};
`;

const Tr = styled.tr`
  width: 100%;
`;

const SelectTh = styled.th<{ isEditMode: boolean }>`
  width: ${(props) => (props.isEditMode ? '4' : '2')}%;
  min-width: 70px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DescriptionTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  width: 20%;
  align-items: left;
`;

const QuantityTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  width: 5%;
  text-align: right;
`;

const UnitTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  width: 5%;
  text-align: center;
`;

const UnitPriceTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  width: 7%;
  text-align: right;
`;

const TotalSumTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  padding-right: ${({ theme }) => theme.margin[40]};
  width: 7%;
  text-align: right;
`;

type TargetThProps = {
  outerBarOpen: boolean;
};

const TargetTh = styled.th<TargetThProps>`
  padding: ${({ theme }) => theme.margin[8]};
  padding-left: ${({ theme, outerBarOpen }) =>
    outerBarOpen ? theme.margin[10] : theme.margin[32]};

  width: 7%;

  text-align: ${({ outerBarOpen }) => (outerBarOpen ? 'left' : 'right')};
  color: ${({ theme }) => theme.color.purple};
`;

const ReceivedTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  padding-left: ${({ theme }) => theme.margin[32]};
  width: 12%;
  text-align: center;
`;

const AnalysisTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  width: 10%;
  text-align: center;
`;

const StatusTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  width: 4%;
  text-align: center;
`;

const EditModeStatusTh = styled.th`
  padding: ${({ theme }) => theme.margin[8]};
  width: 5%;
  text-align: center;
`;

const ToBeReceivedTh = styled.th`
  width: 6%;
  text-align: right;
`;

const RemainingTh = styled.th`
  padding-right: ${({ theme }) => theme.margin[8]};
  width: 6%;
  text-align: right;
`;

const ActionTh = styled.th`
  padding-right: ${({ theme }) => theme.margin[8]};
  width: 3%;
  text-align: right;
`;

const ReceiveRestTh = styled.th`
  width: 4%;
`;

export default OrderTableHeader;
