import React from 'react';

import styled from 'styled-components';

import useRouteParams from '../../../../hooks/useRouteParams';
import useChangeViewMode from '../../hooks/useChangeViewMode';

import TabIconTextButton from '../../../../components/Navigation/TabIconTextButton';

import CAN, {
  CaslProjectRequestParams,
} from '../../../../utils/caslUserPermissions';

import {
  IconHexEye,
  IconCogwheel,
  IconEdit,
  IconChat,
} from '../../../../assets/svg';

type MainButtonsProps = {
  toggleShowInfo: () => void | undefined;
  toggleShowComments: () => void | undefined;
  isInfoShown: boolean;
  isCommentsShown: boolean;
};

const MainButtons: React.FC<MainButtonsProps> = ({
  toggleShowInfo,
  toggleShowComments,
  isInfoShown,
  isCommentsShown,
}) => {
  const { viewMode, projectId, showTargetRows } = useRouteParams();
  const changeViewMode = useChangeViewMode();

  const toggleEditMode = () => {
    const nextViewMode = viewMode === 'edit' ? 'normal' : 'edit';
    changeViewMode(nextViewMode);
  };

  const toggleTargetMode = () => {
    const nextViewMode =
      showTargetRows === 'showTarget' ? 'hideTarget' : 'showTarget';
    changeViewMode(nextViewMode);
  };

  const ability = new CaslProjectRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <Container>
      <TabIconTextButton
        onClick={toggleShowComments}
        icon={IconChat}
        isActive={isCommentsShown}
        id="order.mainButtons.showComments"
      />
      <TabIconTextButton
        onClick={toggleTargetMode}
        icon={IconHexEye}
        isActive={!!showTargetRows}
        id="order.mainButtons.showTarget"
      />
      <TabIconTextButton
        onClick={toggleShowInfo}
        icon={IconCogwheel}
        isActive={isInfoShown}
        id="order.mainButtons.showInfo"
        disabled={!allowedUser}
      />
      <TabIconTextButton
        onClick={toggleEditMode}
        icon={IconEdit}
        isActive={viewMode === 'edit'}
        id="order.mainButtons.edit"
        disabled={!allowedUser}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default MainButtons;
