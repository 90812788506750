import styled from 'styled-components';

import Cell from '../../../components/Cell';

export type CompactCellProps = {
  overflowNotHidden?: boolean;
  paddingDisabled?: boolean;
};

export const CompactCell = styled(Cell)<CompactCellProps>`
  padding: ${(props) =>
    props.paddingDisabled ? 0 : `0 ${props.theme.margin[4]}`};
  height: ${(props) => props.theme.margin[32]};
  overflow: ${(props) => (props.overflowNotHidden ? `visible` : `hidden`)};
`;

export const SectionTitleContainer = styled.div`
  position: sticky;
  top: 0;

  padding: 0.5rem 0.5rem 0.5rem 1rem;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  background: ${(props) => props.theme.color.white};

  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;

  z-index: 20;
`;

export const SelectionContainer = styled.div`
  position: sticky;
  top: 0;

  padding: 0.5rem 0.5rem 0.5rem 1rem;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  background: ${(props) => props.theme.color.toolbarHighlight};

  z-index: 20;
`;

export const SelectionContainerText = styled.div`
  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
`;

export const Title = styled.h2`
  margin: 1rem;

  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
`;

export const SectionSpacer = styled.div`
  margin-bottom: ${(props) => props.theme.margin[40]};
  width: 100%;
`;
