import React, { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import * as uuid from 'uuid';

import { getRequestState } from '../../../../../store/reducers/order/moveOrderRows';
import {
  getOrderRowsByIds,
  getTargetRowIdsByOrderRowIds,
} from '../../../../../store/reducers/orderRow';
import { getTopicsByIds } from '../../../../../store/reducers/topic';
import {
  getTotalPricesSumForOrderRowsWithIds,
  getSelectedOrderRowsForOrder,
} from '../../../../../store/reducers/ui';

import * as action from '../../../../../store/actions';

import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../components/Buttons';
import Modal, {
  Container,
  FilterTextInput,
  Header,
  Content,
  Footer,
} from '../../../../../components/Modal/Modal';
import Txt from '../../../../../components/Txt';
import OrderList from '../../../components/OrderList';
import TopicDropDown from '../../../components/TopicDropDown';

import * as big from '../../../../../utils/big';
import { isNotNull } from '../../../../../utils/general';

type MoveModalProps = {
  onClose: () => void | undefined;
};

const MoveModal = ({ onClose }: MoveModalProps) => {
  const { orderId } = useRouteParams();
  const inputRef = useRef<HTMLInputElement>(null);

  const [requestId] = React.useState(uuid.v4());

  const [selectedOrder, setSelectedOrder] = React.useState(orderId);

  const orderRowIds = useSelector(getSelectedOrderRowsForOrder(orderId));
  const orderRows = useSelector(getOrderRowsByIds(orderRowIds, orderId));

  const orderRowTopicIds = orderRows
    .map((row) => row.topicId)
    .filter(isNotNull);

  const uniqueTopicIds = [...new Set(orderRowTopicIds)];

  const topics = useSelector(getTopicsByIds(uniqueTopicIds));

  const [selectedTopic, setSelectedTopic] = React.useState<
    { id?: string; isPrimary?: boolean } | undefined
  >({ id: topics.find(({ defaultTopic }) => defaultTopic)?.id });

  const targetRows = useSelector(
    getTargetRowIdsByOrderRowIds(orderRowIds, orderId)
  );

  const targetRowCount = targetRows.length;

  const allNotRemovable =
    orderRows.filter((row) => row.isRemovable === false).length > 0;

  const totalPricesSum = useSelector(
    getTotalPricesSumForOrderRowsWithIds(orderRowIds, orderId)
  );
  const dispatch = useDispatch();

  const sendRequest = () => {
    if (!selectedTopic?.id) {
      return;
    }

    if (selectedTopic.id === 'newTopic') {
      dispatch(
        action.moveOrderRowsToOrder({
          requestId,
          orderId: selectedOrder,
          orderRowIds,
        })
      );
    }

    dispatch(
      action.moveOrderRowsToTopic({
        requestId,
        topicId: selectedTopic.id,
        orderRowIds,
      })
    );
  };

  const [filter, setFilterText] = React.useState('');
  const placeHolder = useTxt('order.editMode.moveModal.filterPlaceHolder');

  const selectionInfo = useTxt(
    'order.editMode.moveModal.dropdown.selectionInfo'
  );

  const requestState = useSelector(getRequestState(requestId));

  React.useEffect(() => {
    if (requestState === 'Success') {
      onClose();
    }
  }, [requestState, onClose]);

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const isActionButtonDisabled =
    selectedTopic === undefined || requestState === 'Loading';

  const titleText = useTxt('order.editMode.moveModal.title', {
    amount: orderRowIds.length,
    totalPrice: big.priceFormatRounded(totalPricesSum),
  });

  const handleChange = useCallback((selection) => {
    setSelectedTopic(selection);
  }, []);

  return (
    <Modal onClose={onClose}>
      <Container>
        <Header>{titleText}</Header>
        <Content noMaxHeight>
          <StyledLabel htmlFor="filter">
            <Txt id="order.editMode.moveModal.filterLabel" />
            <FilterTextInput
              name="filter"
              id="filter"
              placeholder={placeHolder}
              onChange={(e) => setFilterText(e.target.value)}
              ref={inputRef}
            />
          </StyledLabel>
          {allNotRemovable ? (
            <Txt
              id="order.editMode.moveModal.noOtherOrders"
              component={ListTitle}
            />
          ) : null}
          <Txt
            id="order.editMode.moveModal.scrollListTitle"
            component={ListTitle}
          />
          <OrderList
            filter={filter}
            selected={selectedOrder}
            allNotRemovable={allNotRemovable}
            onSelect={setSelectedOrder}
          />
          <Txt
            id="order.editMode.moveModal.dropdownLabel"
            component={ListTitle}
          />
          <TopicDropDown
            orderId={selectedOrder}
            onChange={handleChange}
            currentTopics={topics}
            selectedTopic={selectedTopic}
          />
          <Txt
            id="order.editMode.moveModal.bottomInfo"
            component={BottomTitle}
            values={{ orderRowCount: orderRowIds.length, targetRowCount }}
          />
        </Content>
        <StyledFooter>
          {!selectedTopic?.isPrimary ? (
            <SelectionIndicator>{selectionInfo}</SelectionIndicator>
          ) : null}

          <ActionButtons>
            <CancelButton onClick={onClose}>
              <Txt id="common.cancel" />
            </CancelButton>
            <ActionButton
              onClick={sendRequest}
              disabled={isActionButtonDisabled}
            >
              <Txt id="order.editMode.moveModal.button.action" />
            </ActionButton>
          </ActionButtons>
        </StyledFooter>
      </Container>
    </Modal>
  );
};

export default MoveModal;

const SelectionIndicator = styled.label`
  color: ${(props) => props.theme.color.red};
`;

const StyledLabel = styled.label`
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;

  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
`;

const StyledFooter = styled(Footer)`
  display: grid;
  grid-template-columns: 1fr auto auto;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ListTitle = styled.h2`
  margin-bottom: 1rem;
  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
`;

const BottomTitle = styled.h2`
  margin: 1rem;

  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
`;

const CancelButton = styled(SecondaryButton)`
  margin-right: 4px;
`;

const ActionButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

export const ButtonGroup = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
`;
