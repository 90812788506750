import React from 'react';

import styled, { css } from 'styled-components';

import Txt from '../../../components/Txt';

export type ActionStatus =
  | 'changeOfCosts'
  | 'deletion'
  | 'orderChange'
  | 'topicChange'
  | 'statusChange'
  | 'newRow';

type ActionStatusPillProps = {
  isOrderRow: boolean;
  status: ActionStatus;
  moveChangeInfo?: string;
  quantityChangeInfo?: string;
  unitPriceChangeInfo?: string;
};

export const ActionStatusPill = ({
  isOrderRow,
  status,
  moveChangeInfo,
  quantityChangeInfo,
  unitPriceChangeInfo,
}: ActionStatusPillProps) => {
  switch (status) {
    case 'changeOfCosts': {
      if (quantityChangeInfo && unitPriceChangeInfo) {
        const id = `comments.modal.changeStatus.editedBoth${
          isOrderRow ? '' : '.target'
        }` as const;

        return (
          <StatusDiv isOrderRow={isOrderRow}>
            <Txt id={id} values={{ quantityChangeInfo, unitPriceChangeInfo }} />
          </StatusDiv>
        );
      }

      if (quantityChangeInfo) {
        const id = `comments.modal.changeStatus.editedQuantity${
          isOrderRow ? '' : '.target'
        }` as const;

        return (
          <StatusDiv isOrderRow={isOrderRow}>
            <Txt id={id} values={{ quantityChangeInfo }} />
          </StatusDiv>
        );
      }

      if (unitPriceChangeInfo) {
        const id = `comments.modal.changeStatus.editedUnitPrice${
          isOrderRow ? '' : '.target'
        }` as const;

        return (
          <StatusDiv isOrderRow={isOrderRow}>
            <Txt id={id} values={{ unitPriceChangeInfo }} />
          </StatusDiv>
        );
      }

      const id = `comments.modal.changeStatus.editedNoInfo${
        isOrderRow ? '' : '.target'
      }` as const;

      return (
        <StatusDiv isOrderRow={isOrderRow}>
          <Txt id={id} />
        </StatusDiv>
      );
    }
    case 'newRow': {
      const id = `comments.modal.changeStatus.newRow${
        isOrderRow ? '' : '.target'
      }` as const;

      return (
        <StatusDiv isOrderRow={isOrderRow}>
          <Txt id={id} />
        </StatusDiv>
      );
    }
    case 'deletion': {
      const id = `comments.modal.changeStatus.deletion${
        isOrderRow ? '' : '.target'
      }` as const;

      return (
        <StatusDiv isOrderRow={isOrderRow}>
          <Txt id={id} />
        </StatusDiv>
      );
    }
    case 'orderChange': {
      const id = `comments.modal.changeStatus.orderChangeWithValues${
        isOrderRow ? '' : '.target'
      }` as const;

      return (
        <StatusDiv isOrderRow={isOrderRow}>
          <Txt id={id} values={{ moveChangeInfo: moveChangeInfo ?? '' }} />
        </StatusDiv>
      );
    }
    case 'topicChange': {
      const id = `comments.modal.changeStatus.topicChange${
        isOrderRow ? '' : '.target'
      }` as const;

      return (
        <StatusDiv isOrderRow={isOrderRow}>
          <Txt id={id} />
        </StatusDiv>
      );
    }
    case 'statusChange':
      return (
        <StatusDiv isOrderRow={isOrderRow}>
          <Txt id="comments.modal.changeStatus.statusChange" />
        </StatusDiv>
      );
    default:
      return <div>{status}?</div>;
  }
};

type DivProps = {
  isOrderRow: boolean;
};

const StatusDiv = styled.div<DivProps>`
  height: ${({ theme }) => theme.margin[32]};
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSize.small};
  ${(props) =>
    !props.isOrderRow
      ? css`
          color: ${props.theme.color.purple};
        `
      : ''}
`;
