import React from 'react';

import Big from 'big.js';
import styled from 'styled-components';

import { InvoiceHeader } from '../../../../store/reducers/invoiceHeader';

import useTxt from '../../../../hooks/useTxt';

import { DeltaBigCostValue } from '../../../../components/BigValue';
import Cell from '../../../../components/Cell';

import * as big from '../../../../utils/big';

import { calculateExcessQuantity } from '../../ViewModeWrappers/Receive/receiveModeUtils';
import { Tr } from './ConversionRow';

import { ExistingOrderRow, NewOrderRow } from '.';

type TableFooterProps = {
  existingOrderRows: ExistingOrderRow[];
  newOrderRows: NewOrderRow[];
  invoiceHeader: InvoiceHeader;
};

const TableFooter = ({
  existingOrderRows,
  newOrderRows,
  invoiceHeader,
}: TableFooterProps) => {
  const totalShortHandText = useTxt(
    'order.invoiceLines.convertModal.table.footer.totalShortHand'
  );

  const totalText = useTxt(
    'order.invoiceLines.convertModal.table.footer.total'
  );

  const differenceToInvoiceText = useTxt(
    'order.invoiceLines.convertModal.table.footer.difference'
  );

  const invoiceLinesFromExisting = existingOrderRows
    .map((orderRow) => orderRow.invoiceLines)
    .flat();

  const invoiceLinesFromNew = newOrderRows.map(
    (orderRow) => orderRow.invoiceLine
  );

  const invoiceLinesTotalAmountFromExisting = invoiceLinesFromExisting.reduce(
    (previous, line) => {
      const newValue = previous.add(line.netTotal);

      return newValue;
    },
    new Big(0)
  );

  const invoiceLinesTotalAmountFromNew = invoiceLinesFromNew.reduce(
    (previous, line) => {
      const newValue = previous.add(line.netTotal);

      return newValue;
    },
    new Big(0)
  );

  const invoiceLinesTotalAmount = invoiceLinesTotalAmountFromExisting.add(
    invoiceLinesTotalAmountFromNew
  );

  const totalDiffFromExisting = existingOrderRows.reduce((previous, row) => {
    const rowLevelInvoiceLinesTotalAmount = row.invoiceLines.reduce(
      (prev, line) => {
        const newValue = prev.add(line.netTotal);

        return newValue;
      },
      new Big(0)
    );

    const newArrivalQuantity =
      row.orderRow.unitPrice && row.orderRow.unitPrice.toNumber() !== 0
        ? rowLevelInvoiceLinesTotalAmount.div(row.orderRow.unitPrice) ??
          new Big(0)
        : new Big(0);

    const excessQuantity = calculateExcessQuantity(
      row.orderRow,
      newArrivalQuantity
    );

    const difference = excessQuantity.mul(row.orderRow.unitPrice ?? new Big(0));

    return previous.add(difference);
  }, new Big(0));

  const totalDiff = totalDiffFromExisting.add(invoiceLinesTotalAmountFromNew);

  return (
    <>
      <Tr>
        <Cell colSpan={5} />
        <StyledCellWithBackgroundAndBorder
          align="right"
          colSpan={2}
        >{`${totalShortHandText} ${big.priceFormat(
          invoiceLinesTotalAmount
        )}`}</StyledCellWithBackgroundAndBorder>
        <StyledCellWithBorder align="right">
          <DeltaBigCostValue value={totalDiff} />
        </StyledCellWithBorder>
        <Cell />
      </Tr>
      <Tr>
        <Cell colSpan={5} />
        <StyledCellWithBackground align="right" colSpan={2}>
          <LineBreakDiv>
            <Span>{`${totalText} ${big.priceFormat(
              invoiceHeader.amount
            )}`}</Span>
            <Span>{`(${differenceToInvoiceText} ${big.priceFormat(
              invoiceHeader.amount.sub(invoiceLinesTotalAmount)
            )})`}</Span>
          </LineBreakDiv>
        </StyledCellWithBackground>
        <Cell colSpan={2} />
      </Tr>
    </>
  );
};

const Span = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontSize.tiny};
  color: ${(props) => props.theme.color.graphiteB57};
`;

const LineBreakDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCellWithBorder = styled(Cell)`
  border-top: 2px solid ${(props) => props.theme.color.graphiteB57};
  font-weight: bold;
`;

const StyledCellWithBackgroundAndBorder = styled(Cell)`
  border-top: 2px solid ${(props) => props.theme.color.graphiteB57};
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
  font-weight: bold;
`;

const StyledCellWithBackground = styled(Cell)`
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
`;

export default TableFooter;
