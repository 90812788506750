import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { RawAPIUpdatedEntities, APIUpdatedEntities } from '../../types/api';
import { mapRawUpdatedEntities } from '../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
  POST,
  downloadFile,
} from '../../utils/api';
import { bigString, dateString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import { isDefined } from '../../utils/general';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  getSnapshotsFetchRequest,
  Snapshot,
  getSnapshotCreateRequest,
  getDetailedSnapshotsCsvFetchRequest,
} from '../reducers/snapshot';

export type SnapshotAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getSnapshotsStarted')<{ projectId: string }>(),
  ...makeAction('getSnapshotsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getSnapshotsSuccess')<{
    projectId: string;
    snapshots: Snapshot[];
  }>(),
  ...makeAction('getDetailedSnapshotsCsvStarted')<{ projectId: string }>(),
  ...makeAction('getDetailedSnapshotsCsvFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getDetailedSnapshotsCsvSuccess')<{
    projectId: string;
  }>(),
  ...makeAction('postSnapshotRequested')<{ requestId: string }>(),
  ...makeAction('postSnapshotFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postSnapshotSuccess')<
    APIUpdatedEntities & { requestId: string }
  >(),
};

export const {
  getSnapshotsStarted,
  getSnapshotsFailure,
  getSnapshotsSuccess,
  postSnapshotRequested,
  postSnapshotFailure,
  postSnapshotSuccess,
  getDetailedSnapshotsCsvStarted,
  getDetailedSnapshotsCsvFailure,
  getDetailedSnapshotsCsvSuccess,
} = actionCreators;

const apiSnapshotType = t.exact(
  t.type({
    id: t.string,
    userId: t.string,
    userName: t.string,
    projectId: t.string,
    description: t.string,
    companyReportingPeriodDescription: t.string,
    predictionChangeBeforeLocking: bigString,
    targetChangeBeforeLocking: bigString,
    revenuePredictionChangeBeforeLocking: bigString,
    targetTotal: bigString,
    additionalTargetTotal: bigString,
    costPredictionTotal: bigString,
    contractTotal: bigString,
    changeOrdersTotal: bigString,
    reservesTotal: bigString,
    revenueTotal: bigString,
    isDeleted: t.boolean,
    statusId: t.string,
    createdAt: dateString,
    updatedAt: dateString,
    snapshotTypeComment: t.union([t.string, t.null]),
    snapshotTypeId: t.string,
  })
);

export type APISnapshot = t.TypeOf<typeof apiSnapshotType>;

export async function toSnapshot(u: unknown): Promise<Snapshot[]> {
  const apiSnapshots = await tPromise.decode(t.array(apiSnapshotType), u);

  return apiSnapshots.filter(isDefined);
}

const getSnapshots = async (projectId: string) => {
  const response = await GET(`v1/projects/${projectId}/snapshots`);

  return toSnapshot(response);
};

export const fetchSnapshots = (projectId: string) =>
  createAsyncThunk(getSnapshots, {
    args: [projectId],
    isPending: flow(getSnapshotsFetchRequest(projectId), remoteData.isLoading),
    initialAction: getSnapshotsStarted({ projectId }),
    successActionCreator: (response) =>
      getSnapshotsSuccess({
        projectId,
        snapshots: response,
      }),
    failureActionCreator: (error) =>
      getSnapshotsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

const getDetailedSnapshotsCsv = async (projectId: string) => {
  await downloadFile(`v1/projects/${projectId}/detailed-snapshots`);
};

export const fetchDetailedSnapshotsCsv = (projectId: string) =>
  createAsyncThunk(getDetailedSnapshotsCsv, {
    args: [projectId],
    isPending: flow(
      getDetailedSnapshotsCsvFetchRequest(projectId),
      remoteData.isLoading
    ),
    initialAction: getDetailedSnapshotsCsvStarted({ projectId }),
    successActionCreator: () =>
      getDetailedSnapshotsCsvSuccess({
        projectId,
      }),
    failureActionCreator: (error) =>
      getDetailedSnapshotsCsvFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

const postNewSnapshot = async ({
  projectId,
  description,
  snapshotTypeId,
  snapshotTypeComment,
}: {
  projectId: string;
  description: string;
  snapshotTypeId: string;
  snapshotTypeComment: string;
}): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>(
    `v1/projects/${projectId}/new-snapshot`,
    { description, snapshotTypeId, snapshotTypeComment }
  );

  return mapRawUpdatedEntities(response);
};

type PostSnapshotParams = {
  projectId: string;
  requestId: string;
  description: string;
  snapshotTypeId: string;
  snapshotTypeComment: string;
};

export const requestNewSnapshot = ({
  requestId,
  projectId,
  description,
  snapshotTypeId,
  snapshotTypeComment,
}: PostSnapshotParams): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(postNewSnapshot, {
      args: [{ projectId, description, snapshotTypeId, snapshotTypeComment }],
      isPending: flow(
        getSnapshotCreateRequest(requestId),
        remoteData.isLoading
      ),
      initialAction: postSnapshotRequested({ requestId }),
      successActionCreator: (updatedEntities) =>
        postSnapshotSuccess({ ...updatedEntities, requestId }),
      failureActionCreator: (error) =>
        postSnapshotFailure({
          requestId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};
