import React from 'react';

import * as t from 'io-ts';
import styled from 'styled-components';

import { decode } from '../utils/decoders';

import TableTextInput from './Input/TableTextInput';

type Alignment = 'left' | 'center' | 'right';

type ValidatedInputProps<Type, Output> = {
  align: Alignment;
  initialValue: string;
  codec: t.Type<Type, Output>;
  onChange: (value: Type) => void;
  toolTipMsg?: string;
  background?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

function ValidatedInput<Type, Output>({
  align,
  initialValue,
  codec,
  onChange,
  toolTipMsg,
  background,
  onClick,
  onKeyDown,
  onBlur,
}: ValidatedInputProps<Type, Output>) {
  const [prevValue, setPrevValue] = React.useState<string>(initialValue);
  const [value, setValue] = React.useState<string>(initialValue);

  const decodedValue = decode(codec, value);

  React.useEffect(() => {
    if (value === prevValue) {
      return;
    }

    if (decodedValue.kind === 'Invalid') {
      return;
    }

    setPrevValue(value);
    onChange(decodedValue.value);
  }, [decodedValue, onChange, value, prevValue, setPrevValue]);

  const invalid = decodedValue.kind === 'Invalid';
  const isToolTipVisible = toolTipMsg !== undefined && invalid;

  return (
    <>
      <TextInput
        invalid={invalid}
        value={value}
        align={align}
        onChange={(e) => setValue(e.target.value)}
        background={background}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
      {isToolTipVisible ? <ToolTip>{toolTipMsg}</ToolTip> : null}
    </>
  );
}

export default ValidatedInput;

const TextInput = styled(TableTextInput)`
  text-align: ${({ align }) => align};
`;

export const ToolTip = styled.div`
  position: absolute;
  top: ${(props) => props.theme.margin[24]};

  border: 1px solid ${(props) => props.theme.color.graphiteB76};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

  padding: ${(props) => props.theme.margin[8]};

  width: 15rem;

  display: flex;
  flex-flow: column nowrap;

  background: ${(props) => props.theme.color.white};

  text-align: left;
  color: ${(props) => props.theme.color.pitch};

  z-index: 10;
`;
