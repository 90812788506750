import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { getOrderSummary } from '../../../../store/reducers/order/order';
import { getIsOuterBarOpen } from '../../../../store/reducers/ui';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import {
  BigPriceValue,
  DeltaBigCostValue,
} from '../../../../components/BigValue';
import UnsettledCostsButton from '../../../../components/UnsettledCostsButton';

type SummaryProps = {
  viewMode?: string;
};

const Summary = (props: SummaryProps) => {
  const { viewMode } = props;
  const { orderId } = useRouteParams();
  const summary = useSelector(getOrderSummary(orderId));

  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const targetTotalText = useTxt('common.target');
  const predictionTotalText = useTxt('common.prediction');

  const predictionChangeFromLatestText = useTxt(
    'common.predictionChangeFromLatest'
  );

  const contractTotalText = useTxt('common.contract');
  const changeOrdersTotalText = useTxt('common.changeOrders');

  const reservesTotalText = useTxt('common.reserves');
  const receivedTotalText = useTxt('common.received');

  const orderedButNotReceivedText = useTxt('common.orderedButNotReceived');

  if (!summary) {
    return null;
  }

  return (
    <Container viewMode={viewMode}>
      {!outerBarOpen ? (
        <StyledSpan>
          {`${targetTotalText}: `}
          <b>
            <BigPriceValue value={summary.targetTotal} decimals={0} />
          </b>
        </StyledSpan>
      ) : null}
      <StyledSpan>
        {`${predictionTotalText}: `}
        <b>
          <BigPriceValue value={summary.predictionTotal} decimals={0} />
        </b>
      </StyledSpan>
      <StyledSpan>
        {`${predictionChangeFromLatestText}: `}
        <b>
          <DeltaBigCostValue
            value={summary.predictionChangeFromLatest}
            decimals={0}
          />
        </b>
      </StyledSpan>
      <StyledSpan>
        {`${contractTotalText}: `}
        <b>
          <BigPriceValue value={summary.contractTotal} decimals={0} />
        </b>
      </StyledSpan>
      <StyledSpan>
        {`${changeOrdersTotalText}: `}
        <b>
          <BigPriceValue value={summary.changeOrdersTotal} decimals={0} />
        </b>
      </StyledSpan>
      <StyledSpan>
        {`${reservesTotalText}: `}
        <b>
          <BigPriceValue value={summary.reservesTotal} decimals={0} />
        </b>
      </StyledSpan>
      <StyledSpan>
        {`${receivedTotalText}: `}
        <b>
          <BigPriceValue value={summary.receivedTotal} decimals={0} />
        </b>
      </StyledSpan>
      {!outerBarOpen ? (
        <StyledSpan>
          {`${orderedButNotReceivedText}: `}
          <b>
            <BigPriceValue value={summary.orderedButNotReceived} decimals={0} />
          </b>
        </StyledSpan>
      ) : null}
      <CostsButton>
        <UnsettledCostsButton
          orderId={orderId}
          documentType="invoice"
          tipId="InvoiceTooltipOpen"
        />
        <UnsettledCostsButton
          orderId={orderId}
          documentType="actualCost"
          tipId="ActualCostTooltipOpen"
        />
      </CostsButton>
    </Container>
  );
};

const StyledSpan = styled.span`
  margin: 0 ${({ theme }) => theme.margin[2]};
`;

const Container = styled.div<SummaryProps>`
  position: sticky;
  top: ${({ viewMode }) => (viewMode === 'edit' ? '6.55rem' : '4.3rem')};

  border-style: solid none solid none;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.rowBorder};

  padding: ${({ theme }) => `${theme.margin[4]} ${theme.margin[16]}`};

  height: 2.25rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) => theme.color.white};

  z-index: 2;
`;

const CostsButton = styled.span`
  display: flex;
`;

export default Summary;
