import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import { Form, Formik, FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppState } from '../../../../../../store/reducers';
import { ArrivalRow } from '../../../../../../store/reducers/arrivalRow';
import { InvoiceHeader } from '../../../../../../store/reducers/invoiceHeader';
import {
  Dimension,
  getDimensionIds,
  getDimensions,
} from '../../../../../../store/reducers/manualEntry';
import {
  getVatCodes,
  getAccounts,
} from '../../../../../../store/reducers/order/options';
import { selectHasUnassignedPresettledInvoiceRows } from '../../../../../../store/reducers/presettledInvoiceRows';

import {
  requestManualEntryDimensions,
  requestManualEntryDimensionsInitial,
  requestDimensionValues,
  createManualEntry,
  APIManualEntryBody,
} from '../../../../../../store/actions/manualEntry';
import { getDropDowns } from '../../../../../../store/actions/order/options';

import useRemoteData from '../../../../../../hooks/useRemoteData';
import useTxt from '../../../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../../../components/Buttons';
import {
  RowSelectLabel,
  RowSelect,
} from '../../../../../../components/Input/Select';
import TextInput from '../../../../../../components/Input/TextInput';
import { Spinner } from '../../../../../../components/Loading';
import Modal, {
  Content,
  Header,
  HeaderText,
} from '../../../../../../components/Modal/Modal';
import RemoteData from '../../../../../../components/RemoteData';
import Txt from '../../../../../../components/Txt';

import * as big from '../../../../../../utils/big';
import {
  isDefined,
  concatQueryParamsWithString,
} from '../../../../../../utils/general';

import DimensionLabelAndSelect from './DimensionLabelAndSelect';

type ManualEntryModalProps = {
  invoiceHeader: InvoiceHeader;
  onClose: () => void;
  arrivalRowsForInvoice: ArrivalRow[];
};

export type FormDimension = {
  id: string;
  valueId?: string;
};

type FormValues = {
  vatCodeId: string;
  accountCodeId: string;
  sum: string;
  formDimensions: FormDimension[];
};

const ManualEntryModal = ({
  invoiceHeader,
  onClose,
  arrivalRowsForInvoice,
}: React.PropsWithChildren<ManualEntryModalProps>) => {
  const [dimensionsLoaded, setDimensionsLoaded] = React.useState(false);

  const dimensions =
    useRemoteData(getDimensions(), requestManualEntryDimensionsInitial()) ?? [];
  const dispatch = useDispatch();

  const dimensionsLoadState = useSelector(getDimensions()).kind;

  const hasPresettledInvoiceRows = useSelector((state: AppState) =>
    invoiceHeader
      ? selectHasUnassignedPresettledInvoiceRows(invoiceHeader.id)(state)
      : false
  );

  React.useEffect(() => {
    if (dimensionsLoadState === 'Success') {
      setDimensionsLoaded(true);
    }
  }, [dimensionsLoadState]);

  const invoiceName = `${big.priceFormat(invoiceHeader.amount)}
  ${invoiceHeader.supplierName ? ` - ${invoiceHeader.supplierName}` : null}`;

  const amountLeft = invoiceHeader.amount.minus(
    arrivalRowsForInvoice.reduce((acc, curr) => {
      return acc.add(curr.quantity.mul(curr.unitPrice));
    }, new Big(0))
  );
  const descriptionText = useTxt('order.manualEntryModal.description');
  const cancelText = useTxt('common.cancel');
  const submitText = useTxt('order.manualEntryModal.submit');

  const chooseText = useTxt('common.choose');
  const sumText = useTxt('order.manualEntryModal.sum');
  const withoutAccountText = useTxt('order.manualEntryModal.withoutAccount');

  const requiredFieldError = useTxt('order.manualEntryModal.requiredField');
  const sumTooLargeError = useTxt('order.manualEntryModal.sumTooLarge');
  const hasToBeNumberError = useTxt('order.manualEntryModal.hasToBeNumber');

  const hasToBePositiveNumber = useTxt(
    'order.manualEntryModal.hasToBePositive'
  );

  const dimensionIds = useSelector(getDimensionIds());

  const initialSum = big.toInputString(amountLeft);

  const initialValuesForChosenDims: FormDimension[] = dimensionIds
    .filter(isDefined)
    .map((dim) => {
      return { id: dim, valueId: '' };
    });

  /* NOTE! This is an antipattern: using two different validation mechanisms

Had to divide the validation to two places, because Yup handles string and
object validation well, but we have our own good validations built for
localized decimals (floats with comma as decimal marker). */

  const validateAmount = ({ sum }: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    try {
      if (big.fromInputString(sum).gt(invoiceHeader.amount)) {
        errors.sum = sumTooLargeError;
      }

      if (big.fromInputString(sum).lt(new Big(0))) {
        errors.sum = hasToBePositiveNumber;
      }
    } catch (err) {
      errors.sum = hasToBeNumberError;
    }

    return errors;
  };

  const validationForOtherFields = Yup.object().shape({
    vatCodeId: !hasPresettledInvoiceRows
      ? Yup.string().required(requiredFieldError)
      : undefined,
    accountCodeId: !hasPresettledInvoiceRows
      ? Yup.string().required(requiredFieldError)
      : undefined,
    formDimensions: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(), // always present, comes from backend and user cannot modify
        valueId: Yup.string().ensure().required(requiredFieldError),
      })
    ),
  });

  const getDimByHtmlId = (htmlId: string, values: FormValues) => {
    /* Finding the dimension's id as a substring, rather than try to parse
        the id from htmlId */
    const newDim = values.formDimensions.find(
      ({ id }) => htmlId.indexOf(id) >= 0
    );

    return newDim;
  };

  const resetFieldValue = (
    htmlId: string,
    values: FormValues,
    setValues: any
  ) => {
    const dimToReset = getDimByHtmlId(htmlId, values);

    if (isDefined(dimToReset)) {
      const dimIndex = values.formDimensions.indexOf(dimToReset);
      // Reseting field value
      dimToReset.valueId = '';

      // Immutably copying...
      const copyFormDimensions = values.formDimensions.map((dim) => dim);
      // ...and mutably replacing the dimension
      copyFormDimensions.splice(dimIndex, 1, dimToReset);

      // Immutably defining new for dimensions
      const newFormDimensions = [...copyFormDimensions];

      const newValues: FormValues = {
        ...values,
        formDimensions: [...newFormDimensions],
      };

      setValues({ ...newValues });
    }
  };

  const customHandleChange = (
    e: string,
    dimension: Dimension,
    values: FormValues,
    setValues: any
  ) => {
    const newDim = values.formDimensions.filter(
      (row) => row.id === dimension.id
    )[0];

    if (isDefined(newDim)) {
      const newDimIndex = values.formDimensions.indexOf(newDim);
      // Mutably setting the value from select to dimension
      newDim.valueId = e;

      // Immutably copying...
      const copyFormDimensions = values.formDimensions.map((dim) => dim);
      // ...and mutably replacing the dimension
      copyFormDimensions.splice(newDimIndex, 1, newDim);

      // Immutably defining new for dimensions
      const newFormDimensions = [...copyFormDimensions];

      const newValues: FormValues = {
        ...values,
        formDimensions: [...newFormDimensions],
      };

      setValues({ ...newValues });

      const dimValueIds = values.formDimensions
        .map(({ valueId }) => valueId)
        .filter(isDefined)
        .filter((dim) => !isEmpty(dim));

      const queryParams = concatQueryParamsWithString(
        'selectedIds',
        dimValueIds
      );

      dispatch(requestManualEntryDimensions(queryParams));
    }
  };

  const accountOptions = useSelector(getAccounts);
  const vatCodeOptions = useSelector(getVatCodes);

  return dimensionsLoaded ? (
    <Modal onClose={onClose}>
      <FormContainer>
        <StyledHeader>
          <HeaderText>{invoiceName}</HeaderText>
        </StyledHeader>
        <StyledContent>
          <Description>{descriptionText}</Description>
          <Formik
            initialValues={{
              vatCodeId: '',
              accountCodeId: '',
              sum: initialSum,
              formDimensions: initialValuesForChosenDims,
            }}
            validationSchema={validationForOtherFields}
            validate={validateAmount}
            onSubmit={(values) => {
              const dimValueIds = values.formDimensions
                .map(({ valueId }) => valueId)
                .filter(isDefined)
                .filter((dim) => !isEmpty(dim));

              const manualEntryBody: APIManualEntryBody = {
                purchaseInvoiceHeaderId: invoiceHeader.id,
                amount: big.fromInputString(values.sum).toString(),
                description: '',
                vatCodeId:
                  values.vatCodeId && values.vatCodeId !== ''
                    ? values.vatCodeId
                    : null,
                accountId:
                  values.accountCodeId && values.accountCodeId !== ''
                    ? values.accountCodeId
                    : null,
                dimensionItemIds: dimValueIds,
              };

              dispatch(createManualEntry(manualEntryBody));

              onClose();
            }}
          >
            {({
              errors,
              values,
              setValues,
              isSubmitting,
              isValid,
              handleChange,
            }) => {
              return (
                <Form>
                  <Row>
                    <Column>
                      <b>{withoutAccountText}</b>
                      <p>{big.priceFormat(invoiceHeader.amount)}</p>
                    </Column>
                    <InputContainer>
                      <TextInput
                        required
                        label={sumText}
                        name="sum"
                        value={values.sum}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        errorMessage={errors.sum}
                      />
                    </InputContainer>
                  </Row>

                  {dimensions.map((dim) => (
                    <DimensionLabelAndSelect
                      chooseText={chooseText}
                      dimension={dim}
                      key={dim.id}
                      onChange={(e) => {
                        customHandleChange(e, dim, values, setValues);
                      }}
                      formDimensions={values.formDimensions}
                      disabled={isSubmitting}
                      htmlId={`dim-${dim.id}-select`}
                      fetchData={requestDimensionValues(dim.id)}
                      resetFieldValue={(htmlId) => {
                        resetFieldValue(htmlId, values, setValues);
                      }}
                    />
                  ))}

                  <RowSelectLabel htmlFor="vat-code-select">
                    <Txt
                      id="order.manualEntryModal.vatCode"
                      values={{ required: hasPresettledInvoiceRows ? '' : '*' }}
                      component="b"
                    />
                    <RemoteData data={vatCodeOptions} fetchData={getDropDowns}>
                      {(vatCodes) => (
                        <RowSelect
                          required={!hasPresettledInvoiceRows}
                          name="vatCodeId"
                          value={values.vatCodeId}
                          onChange={handleChange}
                          disabled={isSubmitting}
                          invalid={
                            !hasPresettledInvoiceRows && values.vatCodeId === ''
                          }
                          id="vat-code-select"
                        >
                          <option value="" disabled={!hasPresettledInvoiceRows}>
                            {chooseText}
                          </option>
                          {vatCodes.map((code) => (
                            <option key={code.id} value={code.id}>
                              {code.name}
                            </option>
                          ))}
                        </RowSelect>
                      )}
                    </RemoteData>
                  </RowSelectLabel>
                  <RowSelectLabel htmlFor="account-select">
                    <Txt
                      id="order.manualEntryModal.account"
                      values={{ required: hasPresettledInvoiceRows ? '' : '*' }}
                      component="b"
                    />
                    <RemoteData data={accountOptions} fetchData={getDropDowns}>
                      {(accounts) => (
                        <RowSelect
                          required={!hasPresettledInvoiceRows}
                          name="accountCodeId"
                          value={values.accountCodeId}
                          onChange={handleChange}
                          disabled={isSubmitting}
                          invalid={
                            !hasPresettledInvoiceRows &&
                            values.accountCodeId === ''
                          }
                          id="account-select"
                        >
                          <option value="" disabled={!hasPresettledInvoiceRows}>
                            {chooseText}
                          </option>
                          {accounts.map((acc) => (
                            <option key={acc.id} value={acc.id}>
                              {acc.name}
                            </option>
                          ))}
                        </RowSelect>
                      )}
                    </RemoteData>
                  </RowSelectLabel>
                  <CenteredButtonGroup>
                    <SecondaryButton type="button" onClick={onClose}>
                      {cancelText}
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner size="1rem" light />
                      ) : (
                        submitText
                      )}
                    </PrimaryButton>
                  </CenteredButtonGroup>
                </Form>
              );
            }}
          </Formik>
        </StyledContent>
      </FormContainer>
    </Modal>
  ) : null;
};

export default ManualEntryModal;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  width: 10rem;
`;

const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};
  height: 3rem;
`;

const StyledContent = styled(Content)`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => `${theme.margin[32]}`};
  min-height: 16rem;
  max-height: none;
`;

const CenteredButtonGroup = styled(ButtonGroup)`
  margin-top: ${({ theme }) => `${theme.margin[32]}`};
  justify-content: center;
`;

const Description = styled.span`
  padding-bottom: ${({ theme }) => `${theme.margin[32]}`};
`;
