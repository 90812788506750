import Big from 'big.js';
import { Reducer } from 'redux';

import { APIOrder } from '../../../types/api';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import { isDefined, isPresent } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as nullable from '../../../utils/nullable';
import { mergeWith } from '../../../utils/record';
import * as remoteData from '../../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from '../utils';

import { AppState } from '..';
import { ActionTypes } from '../../actionTypes';
import { sortItems } from '../helpers/sort';
import { getProcumentAreaById } from '../procurementArea';
import { getSortOrders } from './sortOrders';

export type OrderState = Partial<
  Record<
    string,
    remoteData.RemoteData<
      Partial<Record<string, APIOrder>>,
      BackendError | undefined
    >
  >
>;

const initialState: OrderState = {};

const orderReducer: Reducer<OrderState, ActionTypes> = (
  state = initialState,
  action
): OrderState => {
  switch (action.type) {
    case 'GET_ORDERS_STARTED': {
      const { projectId } = action.payload;

      return { ...state, [projectId]: remoteData.loading };
    }
    case 'GET_ORDERS_FAILURE': {
      const { projectId, error } = action.payload;

      return { ...state, [projectId]: remoteData.fail(error) };
    }
    case 'GET_ORDERS_SUCCESS': {
      const { projectId, orders } = action.payload;
      const normalizedOrders = normalizeBy('id', orders);

      return { ...state, [projectId]: remoteData.succeed(normalizedOrders) };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { orders: updatedOrders = [] } = action.payload;

    return updatedOrders.reduce((nextState, order) => {
      const { id, projectId, isDeleted } = order;
      const { [projectId]: remoteOrders = remoteData.notAsked } = nextState;

      return {
        ...nextState,
        [projectId]: remoteData.map(remoteOrders, ({ [id]: _, ...orders }) =>
          isDeleted ? orders : { [id]: order, ...orders }
        ),
      };
    }, state);
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default orderReducer;

export const selectProjectOrders = (projectId: string) => ({
  orders: {
    orderData: { [projectId]: remoteOrders = remoteData.notAsked },
  },
}: AppState) => remoteOrders;

export const getOrder: (ids: {
  orderId: string;
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<APIOrder | undefined> = ({
  projectId,
  orderId,
}) =>
  flow(selectProjectOrders(projectId), (remoteOrders) =>
    remoteData.map(remoteOrders, (orders) => orders[orderId])
  );

export const getOrderById = (orderId: string) => ({
  orders: { orderData },
}: AppState): APIOrder | undefined =>
  Object.values(orderData)
    .map((remoteOrders) =>
      remoteData.unwrap(remoteOrders ?? remoteData.notAsked, {
        unwrapper: ({ [orderId]: order }) => order,
        defaultValue: undefined,
      })
    )
    .find(isPresent);

export const getOrderNumber: (
  orderId: string
) => (appState: AppState) => string | undefined = (orderId) => (appState) => {
  const order = getOrderById(orderId)(appState);

  if (!order) {
    return undefined;
  }

  const procurementArea = getProcumentAreaById(order.procurementAreaId)(
    appState
  );

  if (!procurementArea) {
    return undefined;
  }

  return `${procurementArea.code}-${order.rowNumber}`;
};

export const getOrdersByProcurementAreaId = (procurementAreaId: string) => (
  appState: AppState
): APIOrder[] => {
  const procurementArea = getProcumentAreaById(procurementAreaId)(appState);
  const orderIds = procurementArea?.orderIds ?? [];
  const orders = orderIds.map((orderId) => getOrderById(orderId)(appState));
  const sortOrders = getSortOrders(appState);

  return sortItems(orders.filter(isDefined), sortOrders);
};

export const getProjectOrders = (projectId: string) => ({
  orders: {
    orderData: { [projectId]: projectOrders = remoteData.notAsked },
  },
}: AppState): remoteData.RemoteData<APIOrder[]> =>
  remoteData.map(projectOrders, (orders) =>
    Object.values(orders)
      .filter(isDefined)
      .sort((a, b) => (a.orderNumber < b.orderNumber ? -1 : 1))
  );

export type OrderSummary = typeof emptySummary;

const emptySummary = {
  targetTotal: new Big(0),
  additionalTargetTotal: new Big(0),
  predictionTotal: new Big(0),
  contractTotal: new Big(0),
  reservesTotal: new Big(0),
  receivedTotal: new Big(0),
  changeOrdersTotal: new Big(0),
  orderedButNotReceived: new Big(0),
  predictionChangeFromLatest: new Big(0),
  invoicesUnsettledTotal: new Big(0),
  invoicesUnsettledCount: new Big(0),
  invoiceComplaintsTotal: new Big(0),
  invoiceComplaintsCount: new Big(0),
  invoiceCorrectionsTotal: new Big(0),
  invoiceCorrectionsCount: new Big(0),
  actualcostsPendingTotal: new Big(0),
  actualcostsPendingCount: new Big(0),
};

const toSummary: (order: APIOrder) => OrderSummary = ({
  targetTotal,
  additionalTargetTotal,
  predictionTotal,
  contractTotal,
  reservesTotal,
  receivedTotal,
  changeOrdersTotal,
  predictionChangeFromLatest,
  invoicesUnsettledTotal,
  invoicesUnsettledCount,
  invoiceComplaintsTotal,
  invoiceComplaintsCount,
  invoiceCorrectionsTotal,
  invoiceCorrectionsCount,
  actualcostsPendingTotal,
  actualcostsPendingCount,
}) => ({
  targetTotal,
  additionalTargetTotal,
  predictionTotal,
  contractTotal,
  reservesTotal,
  receivedTotal,
  changeOrdersTotal,
  orderedButNotReceived: contractTotal
    .add(changeOrdersTotal)
    .sub(receivedTotal),
  predictionChangeFromLatest,
  invoicesUnsettledTotal,
  invoicesUnsettledCount,
  invoiceComplaintsTotal,
  invoiceComplaintsCount,
  invoiceCorrectionsTotal,
  invoiceCorrectionsCount,
  actualcostsPendingTotal,
  actualcostsPendingCount,
});

type SelectById<A> = (id: string) => (s: AppState) => nullable.Nullable<A>;

export const getOrderSummary: SelectById<OrderSummary> = (id) => (appState) => {
  const order = getOrderById(id)(appState);

  return nullable.map(order, toSummary);
};

export const toTotalSummary = (orders: APIOrder[]): OrderSummary =>
  orders
    .map(toSummary)
    .reduce(
      (total: OrderSummary, summary: OrderSummary) =>
        mergeWith(total, summary, (left, right) => left.add(right)),
      emptySummary
    );

export const getTotalSummary: (
  projectId: string
) => (appState: AppState) => remoteData.RemoteData<OrderSummary> = (
  projectId: string
) =>
  flow(getProjectOrders(projectId), (remoteOrders) =>
    remoteData.map(remoteOrders, toTotalSummary)
  );
