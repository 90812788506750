import React from 'react';

import Big from 'big.js';
import styled from 'styled-components';

import { APITopic, APIWorkPackage } from '../../../../types/api';

import ArrowIcon from '../../../../components/ArrowIcon';
import { DeltaBigCostValue } from '../../../../components/BigValue';
import Cell, { MoneyCell } from '../../../../components/Cell';
import { PrimaryRow } from '../../../../components/Table';

import { calculateExcessQuantity } from '../../ViewModeWrappers/Receive/receiveModeUtils';
import ConversionRow from './ConversionRow';

import { NewOrderRow, ExistingOrderRow, ExtendedExistingOrderRow } from '.';

type StyledPrimaryRowProps = {
  isActive: boolean;
};

const StyledPrimaryRow = styled(PrimaryRow)<StyledPrimaryRowProps>`
  border-bottom: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.color.rowBorderActive : theme.color.rowBorder};
`;

type CellTdProps = {
  paddingLeft?: number;
};

const StyledCell = styled(Cell)<CellTdProps>`
  padding-left: ${({ paddingLeft, theme }) =>
    paddingLeft !== undefined ? `${paddingLeft}rem;` : theme.margin[8]};
`;

const MoneyCellWithBackground = styled(MoneyCell)`
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
`;

const TextSpan = styled.span`
  padding-left: ${(props) => props.theme.margin[4]};
`;

type TopicProps = {
  topic: APITopic;
  workPackage?: APIWorkPackage;
  existingOrderRows: ExistingOrderRow[];
  newOrderRows: NewOrderRow[];
};

type TopicTotals = {
  netTotal: Big;
  difference: Big;
  remaining: Big;
};

export const Topic = ({
  topic,
  workPackage,
  existingOrderRows,
  newOrderRows,
}: TopicProps) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const toggleRowOpen = () => setIsOpen(!isOpen);

  const totals: TopicTotals = {
    netTotal: new Big(0),
    difference: new Big(0),
    remaining: new Big(0),
  };

  const extendedExistingOrderRows = existingOrderRows.map(
    (row: ExistingOrderRow): ExtendedExistingOrderRow => {
      const invoiceLinesTotalAmount = row.invoiceLines.reduce(
        (previous, line) => {
          const newValue = previous.add(line.netTotal);

          return newValue;
        },
        new Big(0)
      );
      totals.netTotal = totals.netTotal.add(invoiceLinesTotalAmount);

      const orderRowAmount =
        row.orderRow.quantity?.mul(row.orderRow.unitPrice ?? new Big(0)) ??
        new Big(0);

      const newArrivalQuantity =
        row.orderRow.unitPrice && row.orderRow.unitPrice.toNumber() !== 0
          ? invoiceLinesTotalAmount.div(row.orderRow.unitPrice) ?? new Big(0)
          : new Big(0);

      const excessQuantity = calculateExcessQuantity(
        row.orderRow,
        newArrivalQuantity
      );

      totals.difference = totals.difference.add(
        excessQuantity.mul(row.orderRow.unitPrice ?? new Big(0))
      );
      totals.remaining = totals.remaining.add(
        excessQuantity.toNumber() === 0
          ? orderRowAmount
              .sub(row.orderRow.arrivalTotal)
              .sub(invoiceLinesTotalAmount)
          : new Big(0)
      );

      return {
        ...row,
        orderRowAmount,
        invoiceLinesTotalAmount,
        newArrivalQuantity,
        excessQuantity,
      };
    }
  );

  newOrderRows.forEach((row) => {
    totals.netTotal = totals.netTotal.add(row.invoiceLine.netTotal);
    totals.difference = totals.difference.add(row.invoiceLine.netTotal);
    totals.remaining = totals.remaining.add(row.invoiceLine.netTotal);
  });

  return (
    <>
      <StyledPrimaryRow onClick={toggleRowOpen} isActive={isOpen}>
        <Cell>
          <ArrowIcon
            isOpen={isOpen}
            openAltTextKey="order.invoiceLines.convertModal.table.topic.open"
            closedAltTextKey="order.invoiceLines.convertModal.table.topic.closed"
          />
        </Cell>
        <StyledCell paddingLeft={0} colSpan={5}>
          {topic.name}
          {workPackage ? (
            <TextSpan>
              |
              <TextSpan>
                <i>{workPackage.name}</i>
              </TextSpan>
            </TextSpan>
          ) : null}
        </StyledCell>
        <MoneyCellWithBackground value={totals.netTotal} />
        <StyledCell align="right">
          <DeltaBigCostValue value={totals.difference} />
        </StyledCell>
        <MoneyCell value={totals.remaining} />
      </StyledPrimaryRow>

      {isOpen
        ? extendedExistingOrderRows.map((row) => {
            return (
              <ConversionRow
                key={`existing-order-row-${row.orderRow.id}`}
                row={row}
              />
            );
          })
        : null}
      {isOpen
        ? newOrderRows.map((row) => {
            return (
              <ConversionRow
                key={`new-order-row-from-${row.invoiceLine.id}`}
                row={row}
              />
            );
          })
        : null}
    </>
  );
};
