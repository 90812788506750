import React from 'react';

import styled from 'styled-components';

import { IconCircle } from '../assets/svg';

interface IDimentions {
  width: number;
  height: number;
}

export type IconProps = {
  notificationCount?: number;
  fill?: string;
  dimentions?: IDimentions;
};

const CircleIcon = ({
  dimentions = { width: 17, height: 17 },
  fill,
  notificationCount,
}: IconProps) => {
  return (
    <CounterContainer>
      <IconCircle
        width={dimentions?.width}
        height={dimentions?.height}
        fill={fill}
      />
      <Counter>{notificationCount}</Counter>
    </CounterContainer>
  );
};

const CounterContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
`;

const Counter = styled.div`
  position: absolute;
  font-size: ${(props) => props.theme.fontSize.tiny};
  color: ${(props) => props.theme.color.white};
`;

export default CircleIcon;
