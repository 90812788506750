import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { ID } from '../../types/general';

import { makeAction, ExtractActionTypes } from '../../utils/actionCreators';
import { GET, BackendError, apiErrorHandlingWithDecode } from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, combineThunks } from '../../utils/thunk';

import {
  selectProjectWorkPackageGroups,
  WorkPackageGroup,
} from '../reducers/workPackageGroup';

import { fetchWorkPackagesForProject } from '.';

export type WorkPackageGroupAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getWorkPackageGroupsStarted')<{ projectId: string }>(),
  ...makeAction('getWorkPackageGroupsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getWorkPackageGroupsSuccess')<{
    projectId: string;
    workPackageGroups: WorkPackageGroup[];
  }>(),
};
export const {
  getWorkPackageGroupsStarted,
  getWorkPackageGroupsFailure,
  getWorkPackageGroupsSuccess,
} = actionCreators;

const apiSnapshotType = t.exact(
  t.type({
    id: t.string,
    name: t.string,
    code: t.string,
    projectId: t.string,
    workPackageIds: t.array(t.string),
  })
);

export type APIWorkPackageGroup = t.TypeOf<typeof apiSnapshotType>;

export async function toWorkPackageGroup(
  u: unknown
): Promise<WorkPackageGroup[]> {
  const apiSnapshots = await tPromise.decode(t.array(apiSnapshotType), u);

  return apiSnapshots;
}

const getWorkPackageGroups = async (projectId: ID) => {
  const rawWorkPackageGroups = await GET(
    `v1/projects/${projectId}/work-package-groups`
  );

  return toWorkPackageGroup(rawWorkPackageGroups);
};

export const fetchWorkPackageGroupsForProject = (projectId: ID) =>
  createAsyncThunk(getWorkPackageGroups, {
    args: [projectId],
    isPending: flow(
      selectProjectWorkPackageGroups(projectId),
      remoteData.isLoading
    ),
    initialAction: getWorkPackageGroupsStarted({ projectId }),
    successActionCreator: (workPackageGroups) =>
      getWorkPackageGroupsSuccess({ projectId, workPackageGroups }),
    failureActionCreator: (error) =>
      getWorkPackageGroupsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export const fetchWorkPackageGroupsAndWorkPackages = (projectId: string) =>
  combineThunks(
    fetchWorkPackageGroupsForProject(projectId),
    fetchWorkPackagesForProject(projectId)
  );
