import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { OptionsType } from 'rc-select/lib/interface';

import { getOrderTopics } from '../../../store/reducers/topic';
import { selectProjectWorkPackages } from '../../../store/reducers/workPackage';

import * as Actions from '../../../store/actions';

import { APITopic } from '../../../types/api';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import DropDownSelect from '../../../components/DropDownSelect';

import { isNotNull, isDefined } from '../../../utils/general';
import * as remoteData from '../../../utils/remoteData';

type TopicOption = {
  id?: string;
  isPrimary?: boolean;
};

type TopicDropDownProps = {
  orderId: string;
  onChange: (selection: TopicOption) => void | undefined;
  currentTopics?: APITopic[];
  selectedTopic?: TopicOption;
};

const TopicDropDown = ({
  orderId,
  onChange,
  currentTopics,
  selectedTopic,
}: TopicDropDownProps) => {
  const topics =
    useRemoteData(
      getOrderTopics(orderId),
      Actions.fetchTopicsForOrder(orderId)
    ) ?? [];

  const { projectId } = useParams<{ projectId: string }>();

  const workPackages = remoteData.withDefault(
    useSelector(selectProjectWorkPackages(projectId)),
    {}
  );

  const currentWorkPackageIds = currentTopics
    ?.map((row) => row.workPackageId)
    .filter(isNotNull);

  const workPackageValues = Object.values(workPackages).filter(isDefined);

  const currentWorkPackages = workPackageValues.filter((row) =>
    currentWorkPackageIds?.includes(row.id)
  );

  const currentWorkPackageCodeNames = currentWorkPackages.map(
    (row) => `${row.code} ${row.name}`
  );

  const workSectionsTxt = currentWorkPackageCodeNames.join(', ');

  const newTopicText = useTxt('order.editMode.moveModal.dropdown.newTopic', {
    workSections: workSectionsTxt,
  });

  const multipleNewTopicsText = useTxt(
    'order.editMode.moveModal.dropdown.multipleNewTopics',
    { workSections: workSectionsTxt }
  );

  const options: OptionsType = topics.map(({ id, name, workPackageId }) => ({
    key: `topic-${id}`,
    value: `topic-${id}`,
    label: `${name} | ${workPackages[workPackageId ?? '']?.name} | ${
      workPackages[workPackageId ?? '']?.code
    }`,
    primary: currentWorkPackageIds?.includes(workPackageId ?? ''),
  }));

  const newTopicOption = {
    key: 'topic-newTopic',
    value: 'topic-newTopic',
    label: `+ ${
      currentTopics?.length && currentTopics?.length > 1
        ? multipleNewTopicsText
        : newTopicText
    }`,
    primary: true,
  };

  const concatenatedOptions = options.concat(newTopicOption);
  const primaryTopics = concatenatedOptions.filter(({ primary }) => primary);

  const defaultTopicId = primaryTopics[0].value.replace('topic-', '');

  useEffect(() => {
    if (defaultTopicId) {
      onChange({
        id: defaultTopicId,
        isPrimary: true,
      });
    }
  }, [onChange, defaultTopicId]);

  return (
    <DropDownSelect
      key={`topic-${selectedTopic?.id}`}
      options={concatenatedOptions}
      onChange={(id, isPrimary) =>
        onChange({ id: id.replace('topic-', ''), isPrimary })
      }
      defaultValue={`topic-${selectedTopic?.id}`}
    />
  );
};

export default TopicDropDown;
