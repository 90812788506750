import styled, { css } from 'styled-components';

import Cell from '../../../../../components/Cell';

type CompactCellProps = {
  invalid?: boolean;
};

export const CompactCell = styled(Cell)<CompactCellProps>`
  padding: 0 ${(props) => props.theme.margin[4]};
  height: ${(props) => props.theme.margin[32]};
  ${(props) =>
    props.invalid
      ? css`
          color: ${props.theme.color.red};
        `
      : ''}
`;

export const LeftPaddedCompactCell = styled(CompactCell)`
  padding-left: ${(props) => props.theme.margin[16]};
`;

export const RightPaddedCompactCell = styled(CompactCell)`
  padding-right: ${(props) => props.theme.margin[8]};
`;

export const UnitPriceCompactCell = styled(CompactCell)`
  padding-right: ${(props) => props.theme.margin[32]};
`;

type InvoiceCellProps = {
  selected?: boolean;
};

export const SelectableInvoiceCell = styled(
  LeftPaddedCompactCell
)<InvoiceCellProps>`
  ${(props) =>
    props.selected &&
    css`
      border-top: 1px solid ${props.theme.color.toolbarHighlight};
      border-bottom: 1px solid ${props.theme.color.toolbarHighlight};
    `}
`;
