import * as api from '../../utils/api';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from './utils';

import { ActionTypes as Action } from '../actionTypes';

import { AppState } from './index';

export type PaymentProgramRowGroup = {
  id: string;
  projectId: string;
  paymentBatchGroupId: string | null;
  code: string | null;
  description: string | null;
  isDeletable: boolean;
  isDeleted: boolean;
  updatedAt: Date;
  createdAt: Date;
  paymentProgramRowIds: string[];
};

type Mapping<A> = Partial<Record<string, A>>;

type Err = api.BackendError | undefined | string;
type RemoteData<A> = remoteData.RemoteData<A, Err>;

type ProjectPaymentProgramRowGroup = RemoteData<
  Mapping<PaymentProgramRowGroup>
>;
type PaymentProgramRowGroupState = {
  deleteRequests: Record<string, remoteData.RemoteData<undefined>>;
  updateRequests: Record<string, remoteData.RemoteData<undefined>>;
  postRequests: Record<string, remoteData.RemoteData<undefined>>;
  moveRowsRequests: Record<string, remoteData.RemoteData<undefined>>;
  data: Mapping<ProjectPaymentProgramRowGroup>;
};

const initialState: PaymentProgramRowGroupState = {
  deleteRequests: {},
  updateRequests: {},
  postRequests: {},
  moveRowsRequests: {},
  data: {},
};

function paymentProgramRowGroupReducer(
  state: PaymentProgramRowGroupState = initialState,
  action: Action
): PaymentProgramRowGroupState {
  switch (action.type) {
    case 'GET_PAYMENT_PROGRAM_ROW_GROUPS_STARTED': {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.projectId]: remoteData.loading,
        },
      };
    }
    case 'GET_PAYMENT_PROGRAM_ROW_GROUPS_FAILURE': {
      const { projectId, error } = action.payload;

      const failure =
        error instanceof api.ApiError ? error.getBackendError() : undefined;

      return {
        ...state,
        data: {
          ...state.data,
          [projectId]: remoteData.fail(failure),
        },
      };
    }
    case 'GET_PAYMENT_PROGRAM_ROW_GROUPS_SUCCESS': {
      const { projectId, paymentProgramRowGroups } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [projectId]: remoteData.succeed(
            normalizeBy('id', paymentProgramRowGroups)
          ),
        },
      };
    }
    case 'PUT_PAYMENT_PROGRAM_ROW_GROUP_FAILURE': {
      const { paymentProgramRowGroupId, error } = action.payload;

      return {
        ...state,
        updateRequests: {
          ...state.updateRequests,
          [paymentProgramRowGroupId]: remoteData.fail(error),
        },
      };
    }
    case 'DELETE_PAYMENT_PROGRAM_ROW_GROUP_FAILURE': {
      const { paymentProgramRowGroupId, error } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [paymentProgramRowGroupId]: remoteData.fail(error),
        },
      };
    }
    case 'POST_PAYMENT_PROGRAM_ROW_GROUP_FAILURE': {
      const { requestId, error } = action.payload;

      return {
        ...state,
        postRequests: {
          ...state.postRequests,
          [requestId]: remoteData.fail(error),
        },
      };
    }
    case 'PUT_PAYMENT_PROGRAM_ROW_GROUP_STARTED': {
      const { paymentProgramRowGroupId } = action.payload;

      return {
        ...state,
        updateRequests: {
          ...state.updateRequests,
          [paymentProgramRowGroupId]: remoteData.loading,
        },
      };
    }
    case 'DELETE_PAYMENT_PROGRAM_ROW_GROUP_STARTED': {
      const { paymentProgramRowGroupId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [paymentProgramRowGroupId]: remoteData.loading,
        },
      };
    }
    case 'POST_PAYMENT_PROGRAM_ROW_GROUP_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        postRequests: {
          ...state.postRequests,
          [requestId]: remoteData.loading,
        },
      };
    }
    case 'MOVE_PAYMENT_PROGRAM_ROWS_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        moveRowsRequests: {
          ...state.moveRowsRequests,
          [requestId]: remoteData.loading,
        },
      };
    }
    case 'MOVE_PAYMENT_PROGRAM_ROWS_FAILURE': {
      const { requestId, error } = action.payload;

      return {
        ...state,
        moveRowsRequests: {
          ...state.moveRowsRequests,
          [requestId]: remoteData.fail(error),
        },
      };
    }
    case 'POST_PAYMENT_PROGRAM_ROW_GROUP_SUCCESS': {
      const { paymentProgramRowGroups = [], requestId } = action.payload;

      const dataState = paymentProgramRowGroups.reduce(
        (newState, updatedPaymentProgramRowGroup) => {
          const { projectId, id, isDeleted } = updatedPaymentProgramRowGroup;

          const projectData = remoteData.map(
            newState.data[projectId] ?? remoteData.notAsked,
            ({ [id]: _, ...projectPaymentProgramRowGroups }) => {
              const newData = isDeleted
                ? projectPaymentProgramRowGroups
                : {
                    ...projectPaymentProgramRowGroups,
                    [id]: updatedPaymentProgramRowGroup,
                  };

              return newData;
            }
          );

          return {
            ...newState,
            data: {
              ...newState.data,
              [projectId]: projectData,
            },
          };
        },
        state
      );

      return {
        ...dataState,
        postRequests: {
          ...dataState.postRequests,
          [requestId]: remoteData.succeed(undefined),
        },
      };
    }
    case 'DELETE_PAYMENT_PROGRAM_ROW_GROUP_SUCCESS': {
      const {
        paymentProgramRowGroups = [],
        paymentProgramRowGroupId,
      } = action.payload;

      const dataState = paymentProgramRowGroups.reduce(
        (newState, updatedPaymentProgramRowGroup) => {
          const { projectId, id, isDeleted } = updatedPaymentProgramRowGroup;

          const projectData = remoteData.map(
            newState.data[projectId] ?? remoteData.notAsked,
            ({ [id]: _, ...projectPaymentProgramRowGroups }) => {
              const newData = isDeleted
                ? projectPaymentProgramRowGroups
                : {
                    ...projectPaymentProgramRowGroups,
                    [id]: updatedPaymentProgramRowGroup,
                  };

              return newData;
            }
          );

          return {
            ...newState,
            data: {
              ...newState.data,
              [projectId]: projectData,
            },
          };
        },
        state
      );

      return {
        ...dataState,
        postRequests: {
          ...dataState.postRequests,
          [paymentProgramRowGroupId]: remoteData.succeed(undefined),
        },
      };
    }
    case 'PUT_PAYMENT_PROGRAM_ROW_GROUP_SUCCESS': {
      const {
        paymentProgramRowGroups = [],
        paymentProgramRowGroupId,
      } = action.payload;

      const dataState = paymentProgramRowGroups.reduce(
        (newState, updatedPaymentProgramRowGroup) => {
          const { projectId, id, isDeleted } = updatedPaymentProgramRowGroup;

          const projectData = remoteData.map(
            newState.data[projectId] ?? remoteData.notAsked,
            ({ [id]: _, ...projectPaymentProgramRowGroups }) => {
              const newData = isDeleted
                ? projectPaymentProgramRowGroups
                : {
                    ...projectPaymentProgramRowGroups,
                    [id]: updatedPaymentProgramRowGroup,
                  };

              return newData;
            }
          );

          return {
            ...newState,
            data: {
              ...newState.data,
              [projectId]: projectData,
            },
          };
        },
        state
      );

      return {
        ...dataState,
        updateRequests: {
          ...dataState.updateRequests,
          [paymentProgramRowGroupId]: remoteData.succeed(undefined),
        },
      };
    }
    case 'MOVE_PAYMENT_PROGRAM_ROWS_SUCCESS': {
      const { paymentProgramRowGroups = [], requestId } = action.payload;

      const dataState = paymentProgramRowGroups.reduce(
        (newState, updatedPaymentProgramRowGroup) => {
          const { projectId, id, isDeleted } = updatedPaymentProgramRowGroup;

          const projectData = remoteData.map(
            newState.data[projectId] ?? remoteData.notAsked,
            ({ [id]: _, ...projectPaymentProgramRowGroups }) => {
              const newData = isDeleted
                ? projectPaymentProgramRowGroups
                : {
                    ...projectPaymentProgramRowGroups,
                    [id]: updatedPaymentProgramRowGroup,
                  };

              return newData;
            }
          );

          return {
            ...newState,
            data: {
              ...newState.data,
              [projectId]: projectData,
            },
          };
        },
        state
      );

      return {
        ...dataState,
        moveRowsRequests: {
          ...dataState.moveRowsRequests,
          [requestId]: remoteData.succeed(undefined),
        },
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const paymentProgramRowGroups =
      action.payload?.paymentProgramRowGroups ?? [];

    return paymentProgramRowGroups.reduce(
      (newState, updatedPaymentProgramRowGroup) => {
        const { projectId, id, isDeleted } = updatedPaymentProgramRowGroup;

        const projectData = remoteData.map(
          newState.data[projectId] ?? remoteData.notAsked,
          ({ [id]: _, ...projectPaymentProgramRowGroups }) => {
            const newData = isDeleted
              ? projectPaymentProgramRowGroups
              : {
                  ...projectPaymentProgramRowGroups,
                  [id]: updatedPaymentProgramRowGroup,
                };

            return newData;
          }
        );

        return {
          ...newState,
          data: {
            ...newState.data,
            [projectId]: projectData,
          },
        };
      },
      state
    );
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
}

export default paymentProgramRowGroupReducer;

export function getPaymentProgramRowGroups(
  projectId: string
): Selector<RemoteData<PaymentProgramRowGroup[]>> {
  return ({ paymentProgramRowGroups: { data } }: AppState) =>
    remoteData.map(
      data[projectId] ?? remoteData.notAsked,
      (paymentProgramRowGroupMapping) =>
        Object.values(paymentProgramRowGroupMapping).filter(isDefined)
    );
}

export function getPaymentProgramRowGroup({
  projectId,
  paymentProgramRowGroupId,
}: {
  projectId: string;
  paymentProgramRowGroupId: string;
}): Selector<PaymentProgramRowGroup | undefined> {
  return ({
    paymentProgramRowGroups: {
      data: {
        [projectId]: projectPaymentProgramRowGroups = remoteData.notAsked,
      },
    },
  }: AppState) => {
    return remoteData.unwrap(projectPaymentProgramRowGroups, {
      unwrapper: ({ [paymentProgramRowGroupId]: paymentProgramRowGroup }) =>
        paymentProgramRowGroup,
      defaultValue: undefined,
    });
  };
}

export function getPaymentProgramRowGroupDeleteRequest(
  groupId: string
): Selector<remoteData.RemoteAction> {
  return ({
    paymentProgramRowGroups: {
      deleteRequests: { [groupId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function getPaymentProgramRowGroupPostRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    paymentProgramRowGroups: {
      postRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function getPaymentProgramRowGroupUpdateRequest(
  groupId: string
): Selector<remoteData.RemoteAction> {
  return ({
    paymentProgramRowGroups: {
      updateRequests: { [groupId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function getMovePaymentProgramRowsRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    paymentProgramRowGroups: {
      moveRowsRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}
