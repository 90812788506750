import { APIUpdatedEntities, RawAPIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  POST,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { createAsyncThunk } from '../../../utils/thunk';

const actionCreators = {
  ...makeAction('postTargetRowAnalysisLinkStarted')<{
    projectId: string;
    requestId: string;
  }>(),
  ...makeAction('postTargetRowAnalysisLinkFailure')<{
    requestId: string;
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postTargetRowAnalysisLinkSuccess')<
    {
      projectId: string;
      requestId: string;
    } & APIUpdatedEntities
  >(),

  ...makeAction('removeTargetRowAnalysisLinkStarted')<{
    projectId: string;
    requestId: string;
  }>(),
  ...makeAction('removeTargetRowAnalysisLinkFailure')<{
    projectId: string;
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('removeTargetRowAnalysisLinkSuccess')<
    {
      projectId: string;
      requestId: string;
    } & APIUpdatedEntities
  >(),
};

export const {
  postTargetRowAnalysisLinkStarted,
  postTargetRowAnalysisLinkFailure,
  postTargetRowAnalysisLinkSuccess,
  removeTargetRowAnalysisLinkStarted,
  removeTargetRowAnalysisLinkFailure,
  removeTargetRowAnalysisLinkSuccess,
} = actionCreators;

export type TargetRowLinkageAction = ExtractActionTypes<typeof actionCreators>;

type TargetRowAnalysisLink = {
  entityId: string;
  analysisRowId: string;
};

type TargetRowLinkRequest = {
  projectId: string;
  requestId: string;
} & TargetRowAnalysisLink;

const postTargetRowAnalysisLink = (url: string) => async ({
  analysisRowId,
  entityId,
}: TargetRowAnalysisLink) => {
  const response = await POST<RawAPIUpdatedEntities>(url, {
    analysisListItemId: analysisRowId,
    targetRowId: entityId,
  });

  return mapRawUpdatedEntities(response);
};

const addTargetRowAnalysisLink = postTargetRowAnalysisLink(
  'v1/target-rows/analysis-list-item-linkage'
);

export const createTargetRowAnalysisLinkage = ({
  entityId: targetRowId,
  projectId,
  requestId,
  analysisRowId,
}: TargetRowLinkRequest) =>
  createAsyncThunk(addTargetRowAnalysisLink, {
    args: [{ entityId: targetRowId, analysisRowId }],
    initialAction: postTargetRowAnalysisLinkStarted({ projectId, requestId }),
    failureActionCreator: (error) =>
      postTargetRowAnalysisLinkFailure({
        projectId,
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      postTargetRowAnalysisLinkSuccess({
        ...updatedEntities,
        projectId,
        requestId,
      }),
  });

const deleteTargetRowAnalysisLink = postTargetRowAnalysisLink(
  'v1/target-rows/remove-analysis-list-item-linkage'
);

export const removeTargetRowAnalysisLinkage = ({
  entityId: targetRowId,
  projectId,
  requestId,
  analysisRowId,
}: TargetRowLinkRequest) =>
  createAsyncThunk(deleteTargetRowAnalysisLink, {
    args: [{ entityId: targetRowId, analysisRowId }],
    initialAction: removeTargetRowAnalysisLinkStarted({ projectId, requestId }),
    failureActionCreator: (error) =>
      removeTargetRowAnalysisLinkFailure({
        projectId,
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      removeTargetRowAnalysisLinkSuccess({
        ...updatedEntities,
        projectId,
        requestId,
      }),
  });
