import { GlobalState } from '../../../types/general';

import { Selector } from '../utils';

import { AppState } from '..';

type GlobalStateKey = {
  [K in keyof AppState]-?: AppState[K] extends GlobalState<unknown> ? K : never;
}[keyof AppState];

type InferGSType<Key extends GlobalStateKey> = {
  [K in Key]-?: AppState[K] extends GlobalState<infer U> ? U : never;
}[Key];

export type GetById<A> = (id: string) => Selector<A | undefined>;
export function getGlobalStateById<K extends GlobalStateKey>(
  key: K
): (
  id: string
) => (
  appState: { [k in K]: GlobalState<InferGSType<K>> }
) => InferGSType<K> | undefined {
  return (id) => (appState) => {
    const isInitialized = appState[key].meta.isInitialized[id];

    return isInitialized ? appState[key].data[id] : undefined;
  };
}
