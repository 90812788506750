import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Big from 'big.js';
import styled, { css } from 'styled-components';

import {
  TargetRow as APITargetRow,
  toggleMultipleTargetRowSelection,
} from '../../../../../store/actions/target';

import { APIOrderRow } from '../../../../../types/api';

import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import { IconButton } from '../../../../../components/Buttons';
import Cell, { MoneyCell } from '../../../../../components/Cell';
import { BaseRow } from '../../../../../components/Table';
import Tooltip from '../../../../../components/Tooltip';

import * as big from '../../../../../utils/big';

import { IconCloseBlack } from '../../../../../assets/svg';

type SplitTargetModalOrderRowProps = {
  orderRow: APIOrderRow;
  selectedAndRelatedTargetRows: APITargetRow[];
  orderRowSumToBeSplit: Big;
  setFormInvalid?: (id: string, e: boolean) => void;
  removeAllRows: () => void;
};

export const SplitTargetModalOrderRow = ({
  orderRow,
  selectedAndRelatedTargetRows,
  orderRowSumToBeSplit,
  setFormInvalid,
  removeAllRows,
}: SplitTargetModalOrderRowProps) => {
  const { orderId } = useRouteParams();
  const dispatch = useDispatch();

  const removeText = useTxt('order.inputs.Remove');

  const orderRowAmount = (orderRow.quantity ?? new Big(0)).mul(
    orderRow.unitPrice ?? 0
  );

  const orderRowTotalTip = useTxt(
    'order.targetSplitModal.tooltip.orderRowTotal',
    {
      orderRowAmount: big.priceFormat(orderRowAmount, 2),
    }
  );

  const tooMuchArrivalsTip = useTxt(
    'order.targetSplitModal.tooltip.tooMuchArrivals',
    {
      orderRowAmount: big.priceFormat(orderRowAmount, 2),
      arrivalAmount: big.priceFormat(orderRow.arrivalTotal ?? 0, 2),
      toBeSplitAmount: big.priceFormat(orderRowSumToBeSplit, 2),
      difference: big.priceFormat(
        orderRowAmount.minus(orderRow.arrivalTotal),
        2
      ),
    }
  );

  const diff = orderRowAmount.minus(orderRowSumToBeSplit);

  const tooMuchArrivals = orderRow.arrivalTotal.eq(0)
    ? false
    : diff.abs().lte(orderRow.arrivalTotal.abs()) ||
      diff.mul(orderRow.arrivalTotal).lt(0); // test if both are negative or positive

  const onDeleteOrderRow = (targetRowIds: string[]) => {
    removeAllRows();

    if (setFormInvalid) {
      setFormInvalid(orderRow.id, false);
    }

    dispatch(
      toggleMultipleTargetRowSelection({
        orderId,
        targetRowIds,
        chooseAll: false,
      })
    );
  };

  useEffect(() => {
    if (setFormInvalid) {
      setFormInvalid(orderRow.id, tooMuchArrivals);
    }
  }, [orderRow.id, setFormInvalid, tooMuchArrivals]);

  return (
    <BaseRow>
      <StyledCell colSpan={2}>{orderRow.description}</StyledCell>
      <StyledCell align="right">
        {big.amountFormat(orderRow.quantity || new Big(0), 4)}
      </StyledCell>
      <StyledCell align="left">{orderRow.unit}</StyledCell>
      <StyledMoneyCell value={orderRow.unitPrice || new Big(0)} decimals={4} />
      <StyledCell colSpan={3} />
      <StyledCell align="right" invalid={tooMuchArrivals}>
        <Tooltip tip={tooMuchArrivals ? tooMuchArrivalsTip : orderRowTotalTip}>
          {big.priceFormat(orderRowSumToBeSplit, 2)}
        </Tooltip>
      </StyledCell>
      <StyledCell align="center">
        <IconButton
          icon={IconCloseBlack}
          onClick={() =>
            onDeleteOrderRow(selectedAndRelatedTargetRows.map((row) => row.id))
          }
          aria-label={removeText}
        />
      </StyledCell>
    </BaseRow>
  );
};

type StyledCellProps = {
  invalid?: boolean;
};

const StyledCell = styled(Cell)<StyledCellProps>`
  border-bottom: 1px solid ${({ theme }) => theme.color.graySuit};
  ${(props) =>
    props.invalid
      ? css`
          color: ${props.theme.color.red};
          font-weight: bold;
        `
      : ''}
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;
