import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeApiActions, ExtractActionTypes } from '../../utils/actionCreators';
import { GET, apiErrorHandlingWithDecode } from '../../utils/api';
import { bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { APIProject, selectProjects } from '../reducers/project';

const actionCreators = makeApiActions('get', 'projects')<APIProject[]>();
export const {
  getProjectsStarted,
  getProjectsSuccess,
  getProjectsFailure,
} = actionCreators;
export type ProjectAction = ExtractActionTypes<typeof actionCreators>;

const apiProjectType = t.exact(
  t.type({
    id: t.string,
    companyId: t.string,
    name: t.string,
    code: t.string,
    status: t.string,
    isClosed: t.boolean,
    externalUrl: t.union([t.string, t.null]),
    procurementAreaIds: t.array(t.string),
    workPackageIds: t.array(t.string),

    receivedTotal: bigString,
    targetTotal: bigString,
    additionalTargetTotal: bigString,
    costPredictionTotal: bigString,
    contractTotal: bigString,
    changeOrdersTotal: bigString,
    reservesTotal: bigString,
    revenueTotal: bigString,

    latestSnapshotTargetTotal: bigString,
    latestSnapshotCostsTotal: bigString,
    latestSnapshotContractTotal: bigString,
    latestSnapshotChangeOrdersTotal: bigString,
    latestSnapshotReservesTotal: bigString,
    latestSnapshotRevenueTotal: bigString,

    targetChangeFromLatest: bigString,
    costPredictionChangeFromLatest: bigString,
    contractChangeFromLatest: bigString,
    changeOrdersChangeFromLatest: bigString,
    reservesChangeFromLatest: bigString,
    revenuePredictionChangeFromLatest: bigString,
  })
);

export async function toProjects(u: unknown): Promise<APIProject[]> {
  const apiProjects = await tPromise.decode(t.array(apiProjectType), u);

  return apiProjects;
}

const getProjects = async () => {
  const response = await GET<APIProject[]>('v1/projects');

  return toProjects(response);
};

export const fetchProjects = (): Thunk =>
  createAsyncThunk(getProjects, {
    args: [],
    isPending: flow(selectProjects(), remoteData.isLoading),
    initialAction: getProjectsStarted(),
    successActionCreator: (projects) => getProjectsSuccess(projects),
    failureActionCreator: (error) =>
      getProjectsFailure(apiErrorHandlingWithDecode(error)),
  });
