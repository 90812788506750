import { APIProcurementArea } from '../../types/api';

import { makeApiActions, ExtractActionTypes } from '../../utils/actionCreators';
import { GET, apiErrorHandlingWithDecode } from '../../utils/api';
import { Thunk } from '../../utils/thunk';

export type ProcurementAreaAction = ExtractActionTypes<typeof actionCreators>;
export const actionCreators = makeApiActions('get', 'procurementAreas')<
  APIProcurementArea[]
>();
export const {
  getProcurementAreasStarted,
  getProcurementAreasSuccess,
  getProcurementAreasFailure,
} = actionCreators;

const getProcurementAreas = (projectId: string) =>
  GET<APIProcurementArea[]>(`v1/projects/${projectId}/procurement-areas`);

export const fetchProcurementAreasForProject = (projectId: string): Thunk => (
  dispatch
) => {
  dispatch(getProcurementAreasStarted());

  getProcurementAreas(projectId).then(
    (response) => dispatch(getProcurementAreasSuccess(response)),
    (error) => {
      dispatch(getProcurementAreasFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};
