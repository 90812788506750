import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk } from '../../../utils/thunk';

import { getAnalysisGroupsForProject } from '../../reducers/analysis/group';

const actionCreators = {
  ...makeAction('getAnalysisGroupsStarted')<{ projectId: string }>(),
  ...makeAction('getAnalysisGroupsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getAnalysisGroupsSuccess')<{
    projectId: string;
    analysisGroups: AnalysisGroup[];
  }>(),
};
export const {
  getAnalysisGroupsStarted,
  getAnalysisGroupsFailure,
  getAnalysisGroupsSuccess,
} = actionCreators;

export type AnalysisGroupAction = ExtractActionTypes<typeof actionCreators>;

type ProjectId = {
  projectId: string;
};

const apiAnalysisGroup = t.exact(
  t.type({
    id: t.string,
    projectId: t.string,
    name: t.string,
    analysisTypeId: t.string,
    customFieldType: t.string,
    attributeIds: t.array(t.string),
    attributeNames: t.array(t.string),
    orderRowsAmountTotal: t.union([bigString, t.null]),
    targetRowsAmountTotal: t.union([bigString, t.null]),
    paymentProgramRowsAmountTotal: t.union([bigString, t.null]),
    arrivalsAmountTotal: t.union([bigString, t.null]),
    isOrderRowField: t.boolean,
    isTargetRowField: t.boolean,
    isPaymentProgramRowField: t.boolean,
    isDeleted: t.boolean,
    listItemIds: t.array(t.string),
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export type AnalysisGroup = t.TypeOf<typeof apiAnalysisGroup>;

export async function decodeAnalysisGroups(
  u: unknown
): Promise<AnalysisGroup[]> {
  return tPromise.decode(t.array(apiAnalysisGroup), u);
}

const fetchAnalysisGroups = async ({
  projectId,
}: ProjectId): Promise<AnalysisGroup[]> => {
  return decodeAnalysisGroups(
    await GET(`v1/projects/${projectId}/analysis-definitions`)
  );
};

export const requestAnalysisGroups = ({ projectId }: ProjectId) =>
  createAsyncThunk(fetchAnalysisGroups, {
    args: [{ projectId }],
    isPending: flow(
      getAnalysisGroupsForProject(projectId),
      remoteData.isLoading
    ),
    initialAction: getAnalysisGroupsStarted({ projectId }),
    failureActionCreator: (error) =>
      getAnalysisGroupsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (analysisGroups) =>
      getAnalysisGroupsSuccess({ projectId, analysisGroups }),
  });
