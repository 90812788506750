import React from 'react';

import styled from 'styled-components';

import { IconSearch } from '../../assets';
import { IconCloseBlack } from '../../assets/svg';

import { IconButton } from '../Buttons';

const SearchInput = React.forwardRef(
  (props: SearchInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { placeholder, handleClearButtonClick, value } = props;

    return (
      <InputContainer>
        <StyledSearchInput placeholder={placeholder} {...props} ref={ref} />
        {value ? (
          <CloseButton icon={IconCloseBlack} onClick={handleClearButtonClick} />
        ) : null}
      </InputContainer>
    );
  }
);

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearButtonClick: () => void;
  noMargin?: boolean;
  backgroundColor?: string;
}

const StyledSearchInput = styled.input<SearchInputProps>`
  margin-bottom: ${(props) => (props.noMargin ? 0 : props.theme.margin[8])};

  border: ${(props) => (props.value ? '2px' : '1px')} solid
    ${(props) =>
      props.value
        ? props.theme.color.sidebarBackground
        : props.theme.color.graphiteB86};
  border-radius: ${(props) => props.theme.margin[24]};

  padding: ${(props) => props.theme.margin[8]}
    ${(props) => props.theme.margin[40]};

  width: 100%;
  height: ${(props) => props.theme.margin[32]};

  /* Wow this is complicated, but it works :D At least on Chrome */
  background: ${(props) => {
      if (props.value) {
        return props.theme.color.toolbarHighlight;
      }

      if (props.backgroundColor) {
        return props.backgroundColor;
      }

      return props.theme.color.graphiteB96A;
    }}
    url(${IconSearch}) no-repeat scroll left
    ${(props) => props.theme.margin[16]} center;

  color: ${(props) => props.theme.color.pitch};
`;

const InputContainer = styled.div`
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0.25rem;
  top: -0.15rem;

  margin: ${(props) => props.theme.margin[10]};

  align-self: flex-end;

  /* stylelint-disable selector-max-type -- svgs inside the button are most easily */

  & svg {
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.color.graphiteB86};

    padding: 0.1rem;

    background-color: ${(props) => props.theme.color.graphiteB96A};

    fill: ${(props) => props.theme.color.white};
  }
`;

export default SearchInput;
