import styled from 'styled-components';

import { BaseButton } from '../Buttons';

export const ProjectMenuContainer = styled.div`
  padding: ${(props) => props.theme.margin[16]};
  height: 25rem;
  width: 100%;
  white-space: break-spaces;
`;

export const ProjectsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const ProjectButton = styled(BaseButton)`
  margin: ${(props) => props.theme.margin[8]} 0;
  color: ${(props) => props.theme.color.pitch};
`;
