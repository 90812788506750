import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { isPresent, isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';

import { AppState } from '..';
import { AnalysisGroup } from '../../actions/analysis';
import { ActionTypes } from '../../actionTypes';

export type AnalysisGroupState = Partial<
  Record<
    string,
    remoteData.RemoteData<
      Partial<Record<string, AnalysisGroup>>,
      BackendError | undefined
    >
  >
>;

const initialState: AnalysisGroupState = {};

export const groupsReducer: Reducer<AnalysisGroupState, ActionTypes> = (
  state = initialState,
  action
): AnalysisGroupState => {
  switch (action.type) {
    case 'GET_ANALYSIS_GROUPS_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.loading,
      };
    }
    case 'GET_ANALYSIS_GROUPS_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.fail(error),
      };
    }
    case 'POST_ANALYSIS_ROW_SUCCESS': {
      const { analysisGroups: updatedAnalysisGroups = [] } = action.payload;

      return updatedAnalysisGroups.reduce((nextState, analysisGroup) => {
        const { id, projectId } = analysisGroup;

        const {
          [projectId]: remoteAnalysisGroups = remoteData.notAsked,
        } = nextState;

        return {
          ...nextState,
          [projectId]: remoteData.map(
            remoteAnalysisGroups,
            ({ [id]: _, ...analysisGroups }) => {
              return { [id]: analysisGroup, ...analysisGroups };
            }
          ),
        };
      }, state);
    }
    case 'GET_ANALYSIS_GROUPS_SUCCESS': {
      const { projectId, analysisGroups } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.succeed(normalizeBy('id', analysisGroups)),
      };
    }
    default: {
      return state;
    }
  }
};

export default groupsReducer;

const toAnalysisGroups = (
  groups: Partial<Record<string, AnalysisGroup>>
): AnalysisGroup[] => {
  return Object.values(groups).filter(isDefined);
};

export const getAnalysisGroupsForProject = (projectId: string) => ({
  analysis: {
    groups: { [projectId]: analysisGroups = remoteData.notAsked },
  },
}: AppState) => {
  return remoteData.map(analysisGroups, (groups) => toAnalysisGroups(groups));
};

export const getAnalysisGroupById = (analysisGroupId: string) => ({
  analysis,
}: AppState): AnalysisGroup | undefined => {
  const analysisGroups = analysis.groups;

  return Object.values(analysisGroups)
    .map((remoteAnalysisGroups) =>
      remoteData.unwrap(remoteAnalysisGroups ?? remoteData.notAsked, {
        unwrapper: ({ [analysisGroupId]: analysisGroup }) => analysisGroup,
        defaultValue: undefined,
      })
    )
    .find(isPresent);
};
