import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk } from '../../../utils/thunk';

import { getPossibleAnalysisRowIdsforRevenue } from '../../reducers/analysis/possibleRevenueLinks';

const actionCreators = {
  ...makeAction('getPossibleAnalysisLinksForRevenueStarted')<{
    revenueId: string;
  }>(),
  ...makeAction('getPossibleAnalysisLinksForRevenueFailure')<{
    revenueId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getPossibleAnalysisLinksForRevenueSuccess')<{
    revenueId: string;
    analysisRowIds: string[];
  }>(),
};

export const {
  getPossibleAnalysisLinksForRevenueStarted,
  getPossibleAnalysisLinksForRevenueFailure,
  getPossibleAnalysisLinksForRevenueSuccess,
} = actionCreators;

export type PossibleRevenueLinkAction = ExtractActionTypes<
  typeof actionCreators
>;

const getPossibleAnalysisRowsForRevenue = async (revenueId: string) =>
  tPromise.decode(
    t.array(t.string),
    await GET(`v1/payment-program-rows/${revenueId}/valid-analysis-list-items`)
  );

export const requestPossibleAnalysisRowsForRevenue = (revenueId: string) =>
  createAsyncThunk(getPossibleAnalysisRowsForRevenue, {
    args: [revenueId],
    isPending: flow(
      getPossibleAnalysisRowIdsforRevenue(revenueId),
      remoteData.isLoading
    ),
    initialAction: getPossibleAnalysisLinksForRevenueStarted({ revenueId }),
    failureActionCreator: (error) =>
      getPossibleAnalysisLinksForRevenueFailure({
        revenueId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (analysisRowIds) =>
      getPossibleAnalysisLinksForRevenueSuccess({ revenueId, analysisRowIds }),
  });
