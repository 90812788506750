import { Reducer } from 'redux';

import { APIProjectUsers } from '../../types/api';

import { BackendError } from '../../utils/api';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

export type ProjectUsersState = Partial<
  Record<
    string,
    remoteData.RemoteData<APIProjectUsers[], BackendError | undefined>
  >
>;

const initialState: ProjectUsersState = {};

const projectUsersReducer: Reducer<ProjectUsersState, ActionTypes> = (
  state = initialState,
  action
): ProjectUsersState => {
  switch (action.type) {
    case 'GET_PROJECT_USERS_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.loading,
      };
    }
    case 'GET_PROJECT_USERS_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.fail(error),
      };
    }
    case 'GET_PROJECT_USERS_SUCCESS': {
      const { projectId, users } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.succeed(users),
      };
    }
    default: {
      return state;
    }
  }
};

export default projectUsersReducer;

export const selectProjectUsers = (projectId: string) => ({
  projectUsers: { [projectId]: projectUsers = remoteData.notAsked },
}: AppState) => projectUsers;
