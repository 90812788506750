import { APIUser } from '../../types/api';

import { makeApiActions, ExtractActionTypes } from '../../utils/actionCreators';
import { GET, PUT, apiErrorHandlingWithDecode } from '../../utils/api';
import { Thunk } from '../../utils/thunk';

import { fetchNotifications } from './notification';

export type UserAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeApiActions('get', 'user')<APIUser>(),
  ...makeApiActions('put', 'user')<APIUser>(),
};

export const {
  getUserStarted,
  getUserFailure,
  getUserSuccess,
  putUserStarted,
  putUserFailure,
  putUserSuccess,
} = actionCreators;

const getUser = () => GET<APIUser>('user');

const updateUser = (id: string, params: Partial<APIUser>) =>
  PUT<APIUser>(`v1/users/${id}/personal-settings`, params);

export const fetchUserState = (): Thunk => (dispatch, _) => {
  dispatch(getUserStarted());
  getUser().then(
    (response) => {
      dispatch(getUserSuccess(response));
      dispatch(fetchNotifications());
    },
    (error) => {
      dispatch(getUserFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};
export const updateUserData = (id: string, params: Partial<APIUser>): Thunk => (
  dispatch
) => {
  dispatch(putUserStarted());
  updateUser(id, params).then(
    (response) => {
      dispatch(putUserSuccess(response));
    },
    (error) => {
      dispatch(getUserFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};
