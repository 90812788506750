import Big from 'big.js';
import { sortBy, groupBy } from 'lodash';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import * as api from '../../utils/api';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  Selector,
  isUpdatedEntitiesActionType,
} from './utils';

import { ActionTypes } from '../actionTypes';
import { getArrivalsByOrderId, getArrivals } from './arrival';

import { AppState } from '.';

export type ArrivalRow = {
  id: ID;
  arrivalId: ID;
  orderRowId: ID | null;
  purchaseInvoiceHeaderId: ID | null;
  actualCostId: ID | null;
  quantity: Big;
  unitPrice: Big;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  vatCodeId: string | null;
  accountId: string | null;
  description: string | null;
};

type Err = api.BackendError | undefined;

export type ArrivalRowState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  deleteRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  analysisRowRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, ArrivalRow>>;
};

const initialState: ArrivalRowState = {
  requests: {},
  deleteRequests: {},
  analysisRowRequests: {},
  data: {},
};

const arrivalRowReducer: Reducer<ArrivalRowState, ActionTypes> = (
  state = initialState,
  action
): ArrivalRowState => {
  switch (action.type) {
    case 'DELETE_ARRIVAL_ROW_STARTED': {
      const { arrivalRowId } = action.payload;

      return {
        ...state,
        deleteRequests: { [arrivalRowId]: remoteData.loading },
      };
    }
    case 'DELETE_ARRIVAL_ROW_FAILURE': {
      const { arrivalRowId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          [arrivalRowId]: remoteData.fail(action.payload.error),
        },
      };
    }
    case 'GET_ARRIVAL_ROWS_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_ARRIVAL_ROWS_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_ARRIVAL_ROWS_SUCCESS': {
      const { orderId, arrivalRows } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', arrivalRows),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    case 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_STARTED': {
      const { analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.loading,
      };

      return {
        ...state,
        analysisRowRequests,
      };
    }
    case 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_FAILURE': {
      const { analysisRowId, error } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.fail(error),
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_SUCCESS': {
      const { analysisRowId, arrivalRows } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', arrivalRows),
      };

      return {
        ...state,
        analysisRowRequests,
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { arrivalRows: updatedArrivalRows = [] } = action.payload;

    if (updatedArrivalRows.length === 0) {
      return state;
    }

    const data = { ...state.data };

    updatedArrivalRows.forEach((arrivalRow) => {
      const { id, isDeleted } = arrivalRow;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = arrivalRow;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const selectOrderArrivalRowsRequests = (orderId: string) => ({
  arrivalRows: {
    requests: { [orderId]: requestState = remoteData.notAsked },
  },
}: AppState) => requestState;

export function getArrivalRowDeleteRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    arrivalRows: {
      deleteRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export const getArrivalRows = ({ arrivalRows: { data } }: AppState) =>
  Object.values(data).filter(isDefined);

export const getArrivalRowById = (id: string) => ({
  arrivalRows: {
    data: { [id]: arrivalRow },
  },
}: AppState) => arrivalRow;

export default arrivalRowReducer;

export const getArrivalRowsByOrderId = (orderId: string) => (
  appState: AppState
): ArrivalRow[] => {
  const orderArrivals = getArrivalsByOrderId(orderId)(appState);

  return orderArrivals.flatMap(({ arrivalRowIds = [] }) =>
    arrivalRowIds.map((id) => getArrivalRowById(id)(appState)).filter(isDefined)
  );
};

export const getArrivalRowsByOrderRowId = (orderRowId: string) => (
  appState: AppState
): ArrivalRow[] => {
  const arrivalRows = getArrivalRows(appState).filter(
    (row) => row.orderRowId === orderRowId
  );

  return arrivalRows;
};

export const getUnassignedArrivalRowsByOrderId = (orderId: string) => (
  appState: AppState
): ArrivalRow[] => {
  const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState);

  const unassignedOrderArrivalRows = orderArrivalRows.filter(
    (row) => row.purchaseInvoiceHeaderId === null && row.actualCostId === null
  );
  const allArrivals = getArrivals(appState);

  const sortedArrivalRows = sortBy(unassignedOrderArrivalRows, [
    ({ arrivalId }) => -allArrivals[arrivalId].rowNumber,
    ({ id }) => Number(id),
  ]);

  return sortedArrivalRows;
};

export const getInvoiceAssignedArrivalRowsByOrderId = (orderId: string) => (
  appState: AppState
): Record<string, ArrivalRow[]> => {
  const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState);

  return groupBy(
    orderArrivalRows.filter((row) => row.purchaseInvoiceHeaderId !== null),
    'purchaseInvoiceHeaderId'
  );
};

export const selectHasArrivalRowsForInvoiceId = (
  invoiceId: string
): Selector<boolean> => ({ arrivalRows: { data } }) =>
  Object.values(data).find(
    (row) => row !== undefined && row.purchaseInvoiceHeaderId === invoiceId
  ) !== undefined;

type InvoiceParams = {
  invoiceId: string;
  orderId: string;
};

export const getInvoiceAssignedArrivalRowsByInvoiceId = ({
  invoiceId,
  orderId,
}: InvoiceParams) => (appState: AppState): ArrivalRow[] => {
  const invoiceAssignedArrivalRows = getInvoiceAssignedArrivalRowsByOrderId(
    orderId
  )(appState);
  const allArrivals = getArrivals(appState);

  const arrivalRowsForThisInvoice = invoiceAssignedArrivalRows[invoiceId] ?? [];

  return sortBy(arrivalRowsForThisInvoice, [
    (arrivalRow) => -Number(allArrivals[arrivalRow.arrivalId].rowNumber),
    (arrivalRow) => Number(arrivalRow.id),
  ]);
};

export const getActualCostAssignedArrivalRowsByOrderId = (orderId: string) => (
  appState: AppState
): Record<string, ArrivalRow[]> => {
  const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState);

  const actualCostAssignedArrivalRows = groupBy(
    orderArrivalRows.filter((row) => row.actualCostId !== null),
    'actualCostId'
  );

  return actualCostAssignedArrivalRows;
};

type ActualCostParams = {
  actualCostId: string;
  orderId: string;
};

export const getActualCostAssignedArrivalRowsByActualCostId = ({
  actualCostId,
  orderId,
}: ActualCostParams) => (appState: AppState) => {
  const allArrivals = getArrivals(appState);

  const actualCostAssignedArrivalRows = getActualCostAssignedArrivalRowsByOrderId(
    orderId
  )(appState);

  const arrivalRowsForThisActualCost =
    actualCostAssignedArrivalRows[actualCostId] ?? [];

  return sortBy(arrivalRowsForThisActualCost, [
    (arrivalRow) => -Number(allArrivals[arrivalRow.arrivalId].rowNumber),
    (arrivalRow) => Number(arrivalRow.id),
  ]);
};
