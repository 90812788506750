import React from 'react';

import { TextId } from '../../localization';
import Txt from '../Txt';

export type InvoiceSettledNotificationProps = {
  textId: TextId;
};

const InvoiceSettledNotification: React.FC<InvoiceSettledNotificationProps> = ({
  textId,
}) => {
  return (
    <>
      <Txt id={textId} />
      <a href="?showAll=true">
        <Txt id={`${textId}.link` as TextId} />
      </a>
    </>
  );
};

export default InvoiceSettledNotification;
