import Big from 'big.js';
import { sortBy } from 'lodash';

import { ActualCost } from '../../../../../store/reducers/actualCost';
import { ArrivalRow } from '../../../../../store/reducers/arrivalRow';
import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';

import { APIArrival } from '../../../../../types/api';

import assertNever from '../../../../../utils/assertNever';
import { compactDateFormat, dateFormat } from '../../../../../utils/format';
import {
  ACTUAL_COST_STATUS_CANCELED,
  ACTUAL_COST_STATUS_NOT_HANDLED,
  INVOICE_HEADER_STATUS_BEING_CORRECTED,
  INVOICE_HEADER_STATUS_COMPLAINT_FILED,
  INVOICE_HEADER_STATUS_CORRECTED,
  INVOICE_HEADER_STATUS_DECLINED,
  INVOICE_HEADER_STATUS_NOT_HANDLED,
} from '../../../../../utils/general';

import {
  IconIndicatorGrey,
  IconIndicatorHollowGrey,
  IconIndicatorGreen,
  IconIndicatorHollowGreen,
  IconIndicatorBlack,
  IconIndicatorRed,
  IconIndicatorBlue,
  IconIndicatorLightBlue,
} from '../../../../../assets';

import { TextId } from '../../../../../localization';

// Sort based on rowNumber of arrival, then based on arrivalRow's id.
export const sortArrivalRows = (
  arrivalRows: ArrivalRow[],
  arrivals: Record<string, APIArrival>
) =>
  sortBy(arrivalRows, [
    (arrivalRow) => -Number(arrivals[arrivalRow.arrivalId].rowNumber),
    (arrivalRow) => Number(arrivalRow.id),
  ]);

// This treshold value defines the margin of error for combined arrival row totals vs. invoice/cost amount.
// handleable = invoice.amount > arrivalRowsTotal - treshold AND invoice.amount < arrivalRowsTotal + treshold
export const HANDLING_TRESHOLD_VALUE_EUR = new Big(0.5);

type InvoiceHeaderActualCostStatus = {
  icon: string;
  text: TextId;
  shortText: TextId;
  identifier: string;
  isHandleable: boolean;
  handleNetAmountPending?: Big;
  handleVatAmountPending?: Big;
  dateAndUser?: string;
  isBeingCorrected?: boolean;
};

// Get invoice headers status for display.
export const getInvoiceHeaderStatus = (
  invoiceHeader: InvoiceHeader
): InvoiceHeaderActualCostStatus => {
  if (INVOICE_HEADER_STATUS_NOT_HANDLED.includes(invoiceHeader.statusId)) {
    switch (invoiceHeader.handlingState) {
      case 'CanBeHandled':
        return {
          icon: IconIndicatorGreen,
          text: 'order.receiveMode.utils.invoiceIsProcessable',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: true,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'TooMuchReceived':
        return {
          icon: IconIndicatorHollowGreen,
          text: 'order.receiveMode.utils.invoiceTooManyArrivals',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          handleNetAmountPending: invoiceHeader.handleNetAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'TooLittleReceived':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.invoiceTooFewArrivals',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          handleNetAmountPending: invoiceHeader.handleNetAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'MissingVat':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.invoiceNoVatCode',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'MissingAccount':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.invoiceNoAccountCode',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'MissingAccountAndVat':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.invoiceNoCodes',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'VatAmountIncorrect':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.invoiceIncorrectVatAmount',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          handleVatAmountPending: invoiceHeader.handleVatAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'MissingCostType':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.invoiceNoCostType',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'NonSpecifiedWorkPackages':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.invoiceNoWorkSection',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'UndefinedHandlingError':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.undefinedHandlingError',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      default:
        throw assertNever(invoiceHeader.handlingState);
    }
  }

  if (INVOICE_HEADER_STATUS_DECLINED.includes(invoiceHeader.statusId)) {
    return {
      icon: IconIndicatorBlack,
      text: 'order.receiveMode.utils.invoiceIsDeclined',
      identifier: invoiceHeader.vendorInvoiceNo,
      isHandleable: false,
      shortText: 'order.receiveMode.invoiceStatus.declined',
      dateAndUser: invoiceHeader.latestStatusChangeAt
        ? `${compactDateFormat.format(invoiceHeader.latestStatusChangeAt)}, ${
            invoiceHeader.latestUpdater
          }`
        : '',
    };
  }

  if (INVOICE_HEADER_STATUS_COMPLAINT_FILED.includes(invoiceHeader.statusId)) {
    return {
      icon: IconIndicatorRed,
      text: 'order.receiveMode.utils.invoiceHasComplaintPending',
      identifier: invoiceHeader.vendorInvoiceNo,
      isHandleable: false,
      shortText: 'order.receiveMode.invoiceStatus.complaintPending',
      dateAndUser: invoiceHeader.latestStatusChangeAt
        ? `${compactDateFormat.format(invoiceHeader.latestStatusChangeAt)}, ${
            invoiceHeader.latestUpdater
          }`
        : '',
    };
  }

  if (INVOICE_HEADER_STATUS_BEING_CORRECTED.includes(invoiceHeader.statusId)) {
    switch (invoiceHeader.handlingState) {
      case 'CanBeHandled':
        return {
          icon: IconIndicatorHollowGreen,
          text: 'order.receiveMode.utils.invoiceIsProcessable',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: true,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'TooMuchReceived':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceTooManyArrivals',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          handleNetAmountPending: invoiceHeader.handleNetAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'TooLittleReceived':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceTooFewArrivals',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          handleNetAmountPending: invoiceHeader.handleNetAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'MissingVat':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceNoVatCode',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'MissingAccount':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceNoAccountCode',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'MissingAccountAndVat':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceNoCodes',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'VatAmountIncorrect':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceIncorrectVatAmount',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          handleVatAmountPending: invoiceHeader.handleVatAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'MissingCostType':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceNoCostType',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'NonSpecifiedWorkPackages':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.invoiceNoWorkSection',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      case 'UndefinedHandlingError':
        return {
          icon: IconIndicatorRed,
          text: 'order.receiveMode.utils.undefinedHandlingError',
          identifier: invoiceHeader.vendorInvoiceNo,
          isHandleable: false,
          shortText: 'order.receiveMode.invoiceStatus.beingCorrected',
          isBeingCorrected: true,
        };
      default:
        throw assertNever(invoiceHeader.handlingState);
    }
  }

  if (INVOICE_HEADER_STATUS_CORRECTED.includes(invoiceHeader.statusId)) {
    return {
      icon: IconIndicatorHollowGreen,
      text: 'order.receiveMode.utils.invoiceCorrected',
      identifier: invoiceHeader.vendorInvoiceNo,
      isHandleable: false,
      shortText: 'order.receiveMode.invoiceStatus.corrected',
      dateAndUser: invoiceHeader.latestStatusChangeAt
        ? `${compactDateFormat.format(invoiceHeader.latestStatusChangeAt)}, ${
            invoiceHeader.latestUpdater
          }`
        : '',
    };
  }

  if (invoiceHeader.paid) {
    return {
      icon: IconIndicatorBlue,
      text: 'order.receiveMode.utils.invoiceIsPaid',
      identifier: invoiceHeader.vendorInvoiceNo,
      isHandleable: false,
      shortText: 'order.receiveMode.invoiceStatus.paid',
      dateAndUser: invoiceHeader.paymentDate
        ? dateFormat.format(invoiceHeader.paymentDate)
        : '',
    };
  }

  if (invoiceHeader.approved) {
    return {
      icon: IconIndicatorLightBlue,
      text: 'order.receiveMode.utils.invoiceIsApproved',
      identifier: invoiceHeader.vendorInvoiceNo,
      isHandleable: false,
      shortText: 'order.receiveMode.invoiceStatus.approved',
      dateAndUser: invoiceHeader.approvalDate
        ? dateFormat.format(invoiceHeader.approvalDate)
        : '',
    };
  }

  return {
    icon: IconIndicatorGrey,
    text: 'order.receiveMode.utils.invoiceIsProcessed',
    identifier: invoiceHeader.vendorInvoiceNo,
    isHandleable: false,
    shortText: 'order.receiveMode.invoiceStatus.handled',
    dateAndUser: invoiceHeader.latestStatusChangeAt
      ? `${compactDateFormat.format(invoiceHeader.latestStatusChangeAt)}, ${
          invoiceHeader.latestUpdater
        }`
      : '',
  };
};

// Get actual costs status for display.
export const getActualCostStatus = (
  actualCost: ActualCost
): InvoiceHeaderActualCostStatus => {
  if (actualCost.statusId === ACTUAL_COST_STATUS_NOT_HANDLED) {
    switch (actualCost.handlingState) {
      case 'CanBeHandled':
        return {
          icon: IconIndicatorGreen,
          text: 'order.receiveMode.utils.actualCostIsProcessable',
          identifier: actualCost.documentNumber,
          isHandleable: true,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'TooMuchReceived':
        return {
          icon: IconIndicatorHollowGreen,
          text: 'order.receiveMode.utils.actualCostTooManyArrivals',
          identifier: actualCost.documentNumber,
          isHandleable: false,
          handleNetAmountPending: actualCost.handleAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      case 'TooLittleReceived':
        return {
          icon: IconIndicatorHollowGrey,
          text: 'order.receiveMode.utils.actualCostTooFewArrivals',
          identifier: actualCost.documentNumber,
          isHandleable: false,
          handleNetAmountPending: actualCost.handleAmountPending,
          shortText: 'order.receiveMode.invoiceStatus.notHandled',
        };
      // These states exist but are not yet(?) enabled on actual costs.
      // case 'MissingVat':
      // case 'MissingAccount':
      // case 'MissingAccountAndVat':
    }
  }

  if (actualCost.statusId === ACTUAL_COST_STATUS_CANCELED) {
    return {
      icon: IconIndicatorBlack,
      text: 'order.receiveMode.utils.actualCostIsCanceled',
      identifier: actualCost.documentNumber,
      isHandleable: false,
      shortText: 'order.receiveMode.invoiceStatus.canceled',
    };
  }

  return {
    icon: IconIndicatorGrey,
    text: 'order.receiveMode.utils.actualCostIsProcessed',
    identifier: actualCost.documentNumber,
    isHandleable: false,
    shortText: 'order.receiveMode.invoiceStatus.handled',
  };
};
