import React from 'react';

import styled from 'styled-components';

import Header from '../../components/Header';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';
import Summary from './components/Summary';
import TargetTable from './components/TargetTable';

import { routes, useParams } from '../../routes';

const ToolbarContainer = styled.div`
  height: ${(props) => props.theme.margin[32]};

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  line-height: ${(props) => props.theme.margin[32]};
`;

const TargetView = () => {
  const { projectId } = useParams(routes.TARGET);

  return (
    <Container>
      <Header>
        <Txt id="target.header" />
      </Header>
      <ProjectInfo projectId={projectId} />
      <ToolbarContainer />
      <Summary projectId={projectId} />
      <TargetTable projectId={projectId} />
    </Container>
  );
};

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]};
`;

export default TargetView;
