import React from 'react';

import styled, { css } from 'styled-components';

import { APIOrder } from '../../../../../../types/api';

type OrderListItemProps = {
  onSelect: (order: APIOrder) => void;
  order: APIOrder;
  isSelected?: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const OrderListItem = ({
  isSelected,
  order,
  onSelect,
}: OrderListItemProps) => {
  return (
    /* Note: className is used here only for testing purposes, not styling
     */
    <StyledRow
      data-testid={`row-invoice-item-${order.id}`}
      onClick={() => onSelect(order)}
      selected={isSelected}
      className={isSelected ? 'selected' : ''}
    >
      <StyledTd>{order.visibleCode}</StyledTd>
      <StyledTd>{order.name}</StyledTd>
      <StyledTd align="right">{order.contractor}</StyledTd>
    </StyledRow>
  );
};

type RowProps = {
  selected: boolean | undefined;
};

const StyledRow = styled.tr<RowProps>`
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      background: ${props.theme.color.toolbarHighlight};
    `}
`;

type TdProps = {
  align?: 'left' | 'right';
};

const StyledTd = styled.td<TdProps>`
  border-bottom: 1px solid ${({ theme }) => theme.color.rowBorder};
  padding: 10px;
  text-align: ${({ align }) => align || 'left'};
`;
