import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import Big from 'big.js';
import styled from 'styled-components';

import { getOrderRowsByOrderId } from '../../../../store/reducers/orderRow';
import { getTopicsByOrderId } from '../../../../store/reducers/topic';
import {
  getIsOuterBarOpen,
  getSavedReceiveAmountStateForOrder,
} from '../../../../store/reducers/ui';

import * as Actions from '../../../../store/actions';
import {
  clearReceiveAmountState,
  saveReceiveAmountState,
} from '../../../../store/actions/ui';

import {
  ReceiveViewModeOptions,
  ReceiveAmountState,
} from '../../../../types/general';

import useRouteParams from '../../../../hooks/useRouteParams';
import useChangeViewMode from '../../hooks/useChangeViewMode';

import {
  OrderReceiveViewContent,
  OrderReceiveViewFooter,
} from '../../../../components/Containers';
import { TableSeparateBorderCollapse } from '../../../../components/Table';
import { OrderTableHeader } from '../../components/OrderTableHeader';
import Summary from '../../components/Summary';
import Topics from '../../components/Topics';

import * as big from '../../../../utils/big';
import { isDefined } from '../../../../utils/general';

import ReceiveModeBottomControls from './ReceiveModeBottomControls';
import {
  getInitialReceiveAmountState,
  constructArrivalRequest,
  orderRowFullyReceived,
  calculateRemainingQuantity,
  emptyReceiveAmountStatePiece,
} from './receiveModeUtils';

const ReceiveModeWrapper = () => {
  const { projectId, orderId, showTargetRows } = useRouteParams();

  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const dispatch = useDispatch();
  const changeViewMode = useChangeViewMode();

  const savedReceiveAmountState = useSelector(
    getSavedReceiveAmountStateForOrder(orderId)
  );
  const topics = useSelector(getTopicsByOrderId(orderId));
  const orderRows = useSelector(getOrderRowsByOrderId(orderId));

  const [receiveAmount, setReceiveAmount] = useState<ReceiveAmountState>(
    getInitialReceiveAmountState(topics, {
      state: savedReceiveAmountState,
      orderRows,
    })
  );

  // Sync receiveAmount state to Redux when local receiveAmount changes. This
  // effect runs only on updates (e.g. not on the first render).
  useUpdateEffect(() => {
    const allFieldsEmpty = Object.values(receiveAmount).every(
      (r) => r.amount === ''
    );

    // Clear redux state if all fields are empty (which means we don't have any
    // values to lose)
    if (allFieldsEmpty) {
      dispatch(clearReceiveAmountState(orderId));
    } else {
      dispatch(saveReceiveAmountState({ orderId, state: receiveAmount }));
    }
  }, [receiveAmount]);

  const viewModeOptions: ReceiveViewModeOptions = {
    type: 'receive',
    state: receiveAmount,
    showTargetRows,
    updateAmount: (orderRowId, amount) => {
      setReceiveAmount((state) => ({
        ...state,
        [orderRowId]: { ...state[orderRowId], amount },
      }));
    },
    updateValidity: (orderRowId, valid) => {
      setReceiveAmount((state) => ({
        ...state,
        [orderRowId]: {
          // Invoice conversion will create an order row in the fly, which is
          // not in the state yet. This make sure we're not creating malformed
          // orderState rows here:
          ...(state[orderRowId] || emptyReceiveAmountStatePiece),
          valid,
        },
      }));
    },
    // Children will call this to toggle "receive all" mode for a single row
    toggleSelectAllForSingleRow: (orderRowId) => {
      setReceiveAmount((state) => {
        const currentState = state[orderRowId].allSelected;
        const orderRow = orderRows[orderRowId];

        const orderRowQuantity = orderRow?.quantity || new Big(0);
        const arrivalQuantity = orderRow?.arrivalQuantity || new Big(0);
        const difference = orderRowQuantity.sub(arrivalQuantity);
        const newAmount = currentState ? '' : big.toInputString(difference);

        return {
          ...state,
          [orderRowId]: {
            allSelected: !currentState,
            amount: newAmount,
            valid: true,
          },
        };
      });
    },
    // This will be called in TopicRowReceiveControls for a single topic. If all
    // rows under the topic are selected, they will be unselected. Otherwise all
    // will be selected.
    setSelectAllForEveryRowInTopic: (topicId, newAllSelectedValue) => {
      setReceiveAmount((state) => {
        const topicOrderRows =
          topics[topicId]?.orderRowIds
            .map((orderRowId) => orderRows[orderRowId])
            .filter(isDefined)
            // We want to ignore rows that have either unitPrice or quantity null
            // as they are not inputtable. We also want to ignore rows that are
            // fully received already, as their 100% button is disabled.
            .filter(
              (row) =>
                row.quantity !== null &&
                row.unitPrice !== null &&
                !orderRowFullyReceived(row)
            ) ?? [];

        const updatedRows = topicOrderRows.reduce<ReceiveAmountState>(
          (result, orderRow) => {
            const newAmount = !newAllSelectedValue
              ? ''
              : big.toInputString(calculateRemainingQuantity(orderRow));

            return {
              ...result,
              [orderRow.id]: {
                allSelected: newAllSelectedValue,
                amount: newAmount,
                valid: true,
              },
            };
          },
          {}
        );

        return {
          ...state,
          ...updatedRows,
        };
      });
    },
  };

  const onClickClear = () => {
    setReceiveAmount(getInitialReceiveAmountState(topics));
  };

  const onClickSubmit = () => {
    dispatch(
      Actions.createArrival(orderId, constructArrivalRequest(receiveAmount))
    );

    // Empty the fields after submitting the arrival
    setReceiveAmount(getInitialReceiveAmountState(topics));
  };

  return (
    <div>
      <OrderReceiveViewContent outerBarOpen={outerBarOpen}>
        <Summary />
        <TableSeparateBorderCollapse>
          <OrderTableHeader orderViewMode={viewModeOptions} orderId={orderId} />
          <Topics
            projectId={projectId}
            orderId={orderId}
            viewModeOptions={viewModeOptions}
          />
        </TableSeparateBorderCollapse>
      </OrderReceiveViewContent>
      <OrderReceiveViewFooter outerBarOpen={outerBarOpen}>
        <ReceiveModeBottomControls
          projectId={projectId}
          orderId={orderId}
          receiveAmount={receiveAmount}
          orderRows={orderRows}
          onClickClear={onClickClear}
          onClickSubmit={onClickSubmit}
          onClickExit={() => changeViewMode('normal')}
        />
      </OrderReceiveViewFooter>
    </div>
  );
};

export const NoOverflowSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default ReceiveModeWrapper;
