import React from 'react';

import { isEmpty } from 'lodash';

import {
  Dimension,
  getValidItemsForDimension,
} from '../../../../../../store/reducers/manualEntry';

import useRemoteData from '../../../../../../hooks/useRemoteData';

import DropDownSelect from '../../../../../../components/DropDownSelect';
import { RowSelectLabel } from '../../../../../../components/Input/Select';

import { isDefined } from '../../../../../../utils/general';
import { Thunk } from '../../../../../../utils/thunk';

import theme from '../../../../../../styles/theme';
import { FormDimension } from './ManualEntryModal';

type DimensionLabelAndSelectProps = {
  dimension: Dimension;
  chooseText: string;
  fetchData: Thunk;
  htmlId: string;
  onChange: (e: string) => void;
  disabled: boolean;
  formDimensions: FormDimension[];
  resetFieldValue: (htmlId: string) => void;
};

const DimensionLabelAndSelect = ({
  dimension,
  chooseText,
  fetchData,
  htmlId,
  onChange,
  disabled,
  formDimensions,
  resetFieldValue,
}: DimensionLabelAndSelectProps) => {
  const validItemsForDimension =
    useRemoteData(getValidItemsForDimension(dimension.id), fetchData) ?? [];

  const formDim = formDimensions.find(({ id }) => htmlId.indexOf(id) >= 0);

  if (!isDefined(formDim)) {
    return null;
  }

  const hasValue = formDim.valueId !== '';

  const amongValidItems = !isEmpty(
    validItemsForDimension.find(({ id }) => formDim.valueId === id)
  );

  const invalid = !hasValue || !amongValidItems;

  if (hasValue && !amongValidItems) {
    resetFieldValue(htmlId);
  }

  return (
    <div>
      <RowSelectLabel htmlFor={htmlId}>
        <b>
          {dimension.name}
          {dimension.mandatory ? ' *' : ''}
        </b>

        <DropDownSelect
          required={dimension.mandatory}
          invalid={invalid}
          additionalStyles={additionalStyling}
          additionalContainerStyles={additionalContainerStyling}
          onChange={onChange}
          disabled={disabled}
          defaultValue=""
          id={htmlId}
          options={[
            {
              key: '',
              value: '',
              label: chooseText,
            },
            ...validItemsForDimension.map((item) => ({
              key: item.id,
              value: item.id,
              label: `${item.code} ${item.name}`,
            })),
          ]}
        />
      </RowSelectLabel>
    </div>
  );
};

export default DimensionLabelAndSelect;

const additionalStyling: React.CSSProperties = {
  marginTop: theme.margin[8],
  marginBottom: theme.margin[8],
  borderRadius: theme.margin[4],
  background: theme.color.dropdownBg,
  fontSize: theme.fontSize.base,
  appearance: 'none',
  width: '12.5rem',
};

const additionalContainerStyling: React.CSSProperties = {
  padding: `${theme.margin[4]} ${theme.margin[4]} ${theme.margin[4]} ${theme.margin[16]}`,
  height: theme.margin[36],
  backgroundColor: 'transparent',
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[16]}`,
};
