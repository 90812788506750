import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';
import {
  Selector,
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from '../utils';

import { OrderChatMessage } from '../../actions/order/chatMessages';
import { ActionTypes } from '../../actionTypes';

type Err = BackendError | undefined;

type State = {
  getRequests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  createRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, OrderChatMessage>>;
};

const initialState: State = {
  getRequests: {},
  createRequests: {},
  data: {},
};

const orderChatMessageReducer: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'GET_ORDER_CHAT_MESSAGES_STARTED': {
      const { orderId } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.loading,
      };

      return { ...state, ...state.createRequests, getRequests };
    }
    case 'GET_ORDER_CHAT_MESSAGES_FAILURE': {
      const { orderId, error } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.fail(error),
      };

      const createRequests = {
        ...state.createRequests,
      };

      return { ...state, createRequests, getRequests };
    }
    case 'GET_ORDER_CHAT_MESSAGES_SUCCESS': {
      const { orderId, orderChatMessages } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.succeed(undefined),
      };

      const createRequests = {
        ...state.createRequests,
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', orderChatMessages),
      };

      return {
        getRequests,
        createRequests,
        data,
      };
    }
    case 'POST_ORDER_CHAT_MESSAGE_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        createRequests: {
          ...state.createRequests,
          [requestId]: remoteData.loading,
        },
      };
    }
    case 'POST_ORDER_CHAT_MESSAGE_FAILURE': {
      const { requestId } = action.payload;

      return {
        ...state,
        createRequests: {
          ...state.createRequests,
          [requestId]: remoteData.fail(action.payload.error),
        },
      };
    }
    case 'POST_ORDER_CHAT_MESSAGE_SUCCESS': {
      const { requestId, orderChatMessages = [] } = action.payload;

      const data = {
        ...state.data,
        ...normalizeBy('id', orderChatMessages),
      };

      return {
        ...state,
        data,
        createRequests: {
          ...state.createRequests,
          [requestId]: remoteData.succeed(undefined),
        },
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { orderChatMessages = [] } = action.payload;

    const data = {
      ...state.data,
      ...normalizeBy('id', orderChatMessages),
    };

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default orderChatMessageReducer;

export const getOrderChatFetchRequest = (
  orderId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    orders: {
      chatMessages: {
        getRequests: { [orderId]: request },
      },
    },
  }) => request ?? remoteData.notAsked;
};

export const getOrderChatCreateRequest = (
  requestId: string
): Selector<remoteData.RemoteData['kind']> => {
  return ({
    orders: {
      chatMessages: {
        createRequests: { [requestId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
};

export const getOrderChatMessages: (
  orderId: string
) => Selector<remoteData.RemoteData<OrderChatMessage[]>> = (orderId) => ({
  orders: {
    chatMessages: {
      getRequests: { [orderId]: requestState = remoteData.notAsked },
      data,
    },
  },
}) =>
  remoteData.map(requestState, (_) =>
    Object.values(data)
      .filter(isDefined)
      .filter((message) => message.orderId === orderId)
  );

// TODO: Is the above mapping necessary?
