import React from 'react';
import { useDispatch } from 'react-redux';

import { markNotificationRead } from '../../../store/actions/notification';

import { NotificationFeedItem } from '../../../types/general';

import { calculateElapsedTimeFrom } from '../../../utils/function';

import ChatMentionNotification from './ChatMentionNotification';
import ExternalChangeNotification from './ExternalChangeNotification';
import MultiOrderNotification from './MultiOrderNotification';
import OrderLevelNotification from './OrderLevelNotification';

interface NotificationProps {
  notification: NotificationFeedItem;
  onCloseSidebar: () => void;
}

const Notification = ({ notification, onCloseSidebar }: NotificationProps) => {
  const dispatch = useDispatch();
  const { notificationType } = notification;
  // 24 hours in milliseconds
  const oneDay = 86400000;
  const timeElapsed = calculateElapsedTimeFrom(notification.createdAt);
  const isNew = timeElapsed < oneDay && !notification.isRead;

  const markAsRead = (item: NotificationFeedItem): void => {
    dispatch(markNotificationRead(item));
  };

  switch (notificationType) {
    case '3':
    case '9':
      return (
        <MultiOrderNotification
          notification={notification}
          onCloseSidebar={onCloseSidebar}
          markAsRead={markAsRead}
          isNew={isNew}
        />
      );
    case '4':
      return (
        <ChatMentionNotification
          notification={notification}
          onCloseSidebar={onCloseSidebar}
          markAsRead={markAsRead}
          isNew={isNew}
        />
      );
    case '8':
      return (
        <ExternalChangeNotification
          notification={notification}
          onCloseSidebar={onCloseSidebar}
          markAsRead={markAsRead}
        />
      );
    default:
      return (
        <OrderLevelNotification
          notification={notification}
          onCloseSidebar={onCloseSidebar}
          markAsRead={markAsRead}
          isNew={isNew}
        />
      );
  }
};

export default Notification;
