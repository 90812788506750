import * as React from 'react';

import styled from 'styled-components';

import { ActualCost } from '../../../../store/reducers/actualCost';
import { ArrivalRow } from '../../../../store/reducers/arrivalRow';
import { InvoiceHeader } from '../../../../store/reducers/invoiceHeader';

import { APIOrder, APIOrderRow } from '../../../../types/api';

import useTxt from '../../../../hooks/useTxt';

import {
  StickyHeaderTh,
  Table,
  TableHeader,
} from '../../../../components/Table';

import { OrderTableRow } from './TableRow';

type AnalysisInspectionTableProps = {
  orders: APIOrder[];
  invoices: InvoiceHeader[];
  actualCosts: ActualCost[];
  arrivalRows: ArrivalRow[];
  orderRows: APIOrderRow[];
};

export const InspectionTable = ({
  orders,
  invoices,
  actualCosts,
  arrivalRows,
  orderRows,
}: AnalysisInspectionTableProps) => {
  const headerDescription = useTxt('analysis.modal.header.description');
  const headerIdentifier = useTxt('analysis.received.modal.header.identifier');
  const headerReceived = useTxt('common.received');

  return (
    <StyledDivTableContainer>
      <Table>
        <TableHeader>
          <tr>
            <StickyHeaderTh width="10rem">{headerIdentifier}</StickyHeaderTh>
            <StickyHeaderTh width="auto">{headerDescription}</StickyHeaderTh>
            <StickyHeaderTh width="16rem" />
            <StickyHeaderTh width="10rem" />
            <StickyHeaderTh width="10rem" textAlign="right">
              {headerReceived}
            </StickyHeaderTh>
          </tr>
        </TableHeader>
        <tbody>
          {orders.map((order) => {
            const filteredInvoices = invoices.filter(
              (invoice) => invoice.orderId === order.id
            );

            const filteredActualCosts = actualCosts.filter(
              (cost) => cost.orderId === order.id
            );

            const filteredOrderRows = orderRows.filter(
              (row) => row.orderId === order.id
            );

            return (
              <OrderTableRow
                key={`order-table-row-${order.id}`}
                order={order}
                arrivalRows={arrivalRows}
                invoices={filteredInvoices}
                actualCosts={filteredActualCosts}
                orderRows={filteredOrderRows}
              />
            );
          })}
        </tbody>
      </Table>
    </StyledDivTableContainer>
  );
};

const StyledDivTableContainer = styled.div`
  max-height: 70vh;

  display: flex;
  flex-direction: column;

  flex: 1;

  overflow-y: auto;
`;
