import React from 'react';

import Big from 'big.js';
import styled, { css } from 'styled-components';

import * as big from '../utils/big';

type SpanProps = {
  fontSize?: 'tiny' | 'small' | 'base';
  bold?: boolean;
};

const GreenSpan = styled.span<SpanProps>`
  ${(props) =>
    props.fontSize
      ? css`
          font-size: ${props.theme.fontSize[props.fontSize]};
        `
      : ''}
  ${(props) =>
    props.bold
      ? css`
          font-weight: bold;
        `
      : ''}
  color: ${(props) => props.theme.color.positiveGreen};
`;

const OrangeSpan = styled.span<SpanProps>`
  ${(props) =>
    props.fontSize
      ? css`
          font-size: ${props.theme.fontSize[props.fontSize]};
        `
      : ''}
  ${(props) =>
    props.bold
      ? css`
          font-weight: bold;
        `
      : ''}
  color: ${(props) => props.theme.color.darkOrange};
`;

const GreySpan = styled.span<SpanProps>`
  ${(props) =>
    props.fontSize
      ? css`
          font-size: ${props.theme.fontSize[props.fontSize]};
        `
      : ''}
  ${(props) =>
    props.bold
      ? css`
          font-weight: bold;
        `
      : ''}
  color: ${(props) => props.theme.color.graphiteB86};
`;

const DarkGreySpan = styled.span<SpanProps>`
  ${(props) =>
    props.fontSize
      ? css`
          font-size: ${props.theme.fontSize[props.fontSize]};
        `
      : ''}
  ${(props) =>
    props.bold
      ? css`
          font-weight: bold;
        `
      : ''}
  color: ${(props) => props.theme.color.graphite};
`;

const RedSpan = styled.span<SpanProps>`
  ${(props) =>
    props.fontSize
      ? css`
          font-size: ${props.theme.fontSize[props.fontSize]};
        `
      : ''}
  ${(props) =>
    props.bold
      ? css`
          font-weight: bold;
        `
      : ''}
  color: ${(props) => props.theme.color.negativeRed};
`;

type BigValueProps = {
  value: Big;
  formatValue?: Big;
  decimals?: number;
  bolded?: boolean;
};

/**
 * Print a span containing a value with decimal number formatting and colour
 * it red when positive and green when negative.
 */
export const DeltaBigValue = ({ value, decimals }: BigValueProps) =>
  value.lte(0) ? (
    <GreenSpan>{big.format(value, decimals)}</GreenSpan>
  ) : (
    <RedSpan>{big.format(value, decimals)}</RedSpan>
  );

export const DeltaBigCostValue = ({ value, decimals }: BigValueProps) =>
  value.lte(0) ? (
    <GreenSpan>{big.priceFormat(value, decimals)}</GreenSpan>
  ) : (
    <RedSpan>{big.priceFormat(value, decimals)}</RedSpan>
  );

export const AlternateBigValue = ({
  value,
  formatValue = new Big(0),
  decimals,
  bolded,
}: BigValueProps) => {
  if (formatValue.abs().lte(0.1)) {
    return (
      <DarkGreySpan fontSize="small" bold={bolded}>
        {big.format(value, decimals)}
      </DarkGreySpan>
    );
  }

  return formatValue.gte(0) ? (
    <GreenSpan fontSize="small" bold={bolded}>
      {big.format(value, decimals)}
    </GreenSpan>
  ) : (
    <OrangeSpan fontSize="small" bold={bolded}>
      {big.format(value, decimals)}
    </OrangeSpan>
  );
};

export const AlternateDeltaBigValue = ({ value, decimals }: BigValueProps) => {
  if (value.abs().lte(0.1)) {
    return (
      <GreySpan fontSize="tiny">{`+${big.format(value, decimals)}`}</GreySpan>
    );
  }

  return value.gte(0) ? (
    <GreenSpan fontSize="tiny">{`+${big.format(value, decimals)}`}</GreenSpan>
  ) : (
    <OrangeSpan fontSize="tiny">{big.format(value, decimals)}</OrangeSpan>
  );
};

export const DeltaBigProfitValue = ({ value, decimals }: BigValueProps) =>
  value.gte(0) ? (
    <GreenSpan>{big.priceFormat(value, decimals)}</GreenSpan>
  ) : (
    <RedSpan>{big.priceFormat(value, decimals)}</RedSpan>
  );

export const BigValue = ({ value, decimals }: BigValueProps) => (
  <span>{big.format(value, decimals)}</span>
);

export const BigPriceValue = ({ value, decimals }: BigValueProps) => (
  <span>{big.priceFormat(value, decimals)}</span>
);

export const BigPercentageValue = ({ value, decimals }: BigValueProps) => (
  <span>{big.percentageFormat(value, decimals)}</span>
);

export const DeltaBigPercentageValue = ({ value, decimals }: BigValueProps) => {
  if (value.abs().lte(0.1)) {
    return <span>{big.percentageFormat(value, decimals)}</span>;
  }

  return value.gte(0) ? (
    <GreenSpan>{big.percentageFormat(value, decimals)}</GreenSpan>
  ) : (
    <RedSpan>{big.percentageFormat(value, decimals)}</RedSpan>
  );
};
