import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { AppState } from '../../../../../store/reducers';
import {
  ActualCost,
  getActualCostUpdateRequest,
} from '../../../../../store/reducers/actualCost';
import { ArrivalRow } from '../../../../../store/reducers/arrivalRow';
import {
  getInvoiceUpdateRequest,
  InvoiceHeader,
} from '../../../../../store/reducers/invoiceHeader';
import { selectSpreadArrivalRowRequests } from '../../../../../store/reducers/presettledInvoiceRows';

import {
  finishInvoiceHeaderCorrection,
  updateActualCost,
  updateInvoiceHeader,
} from '../../../../../store/actions';
import {
  getPresettledInvoiceRowsByInvoiceId,
  spreadArrivalRowsByPresettledInvoiceRows,
} from '../../../../../store/actions/presettledInvoiceRows';
import { selectArrivalRows } from '../../../../../store/actions/ui';

import { ID } from '../../../../../types/general';

import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';
import useChangeViewMode from '../../../hooks/useChangeViewMode';

import { PrimaryButtonSmall } from '../../../../../components/Buttons';
import { Spinner } from '../../../../../components/Loading';

import * as big from '../../../../../utils/big';
import CAN, {
  CaslPaymentProgramRowRequestParams,
} from '../../../../../utils/caslUserPermissions';
import {
  ACTUAL_COST_STATUS_HANDLED,
  INVOICE_HEADER_STATUS_ACCEPTED,
} from '../../../../../utils/general';
import { isLoading } from '../../../../../utils/remoteData';
import { getActualCostStatus, getInvoiceHeaderStatus } from './utils';

import { IndicatorImg } from './Components';

type HandlableItemProps = {
  invoice?: InvoiceHeader;
  actualCost?: ActualCost;
  arrivalRows: ArrivalRow[];
};

const Wrapper = styled.div`
  padding: 0 ${(props) => props.theme.margin[8]} 0
    ${(props) => props.theme.margin[16]};

  height: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 1;

  background: ${({ theme }) => theme.color.toolbarHighlight};

  font-weight: bold;
`;

const ProcessingTool = ({
  invoice,
  actualCost,
  arrivalRows,
}: HandlableItemProps) => {
  const dispatch = useDispatch();

  const { showInfo } = useRouteParams();

  const processText = useTxt('order.receiveMode.processingTool.process');
  const correctionText = useTxt('order.receiveMode.invoice.finishCorrection');

  const spreadArrivalsText = useTxt(
    'order.receiveMode.invoice.spread.arrival.rows'
  );

  const showInfoText = useTxt('order.mainButtons.showInfo');

  const changeViewMode = useChangeViewMode();

  const toggleShowInfoSidebar = (visible?: boolean) => {
    const nextViewMode = showInfo === 'info' ? 'hideInfo' : 'showInfo';
    changeViewMode(visible === false ? 'hideInfo' : nextViewMode);
  };

  useEffect(() => {
    if (invoice) {
      dispatch(getPresettledInvoiceRowsByInvoiceId(invoice.id));
    }
  }, [dispatch, invoice]);

  const isPending = useSelector((state: AppState) => {
    if (invoice) {
      return (
        isLoading(getInvoiceUpdateRequest(invoice.id)(state)) ||
        isLoading(selectSpreadArrivalRowRequests(invoice.id)(state))
      );
    }

    if (actualCost) {
      return isLoading(getActualCostUpdateRequest(actualCost.id)(state));
    }

    return false;
  });

  const status =
    (invoice && getInvoiceHeaderStatus(invoice)) ||
    (actualCost && getActualCostStatus(actualCost));

  const onClickSpreadInvoice = (invoiceId: ID) => {
    dispatch(spreadArrivalRowsByPresettledInvoiceRows(invoiceId));
  };

  const onClickHandleInvoice = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    // Deselect handled arrival rows (this fixes the problem where user
    // can re-assign a handled arrival row).

    dispatch(
      selectArrivalRows(
        arrivalRows.reduce((map, { id }) => ({ ...map, [id]: false }), {})
      )
    );

    // Dispatch updates for invoices/costs.
    if (invoice) {
      if (status?.isBeingCorrected) {
        dispatch(finishInvoiceHeaderCorrection(invoice.id));
      } else {
        dispatch(
          updateInvoiceHeader(invoice.id, {
            statusId: INVOICE_HEADER_STATUS_ACCEPTED[0],
          })
        );
      }
    }

    if (actualCost) {
      dispatch(
        updateActualCost(actualCost.id, {
          statusId: ACTUAL_COST_STATUS_HANDLED,
        })
      );
    }
  };

  const statusText = useTxt(
    status?.text ?? 'meta.none',
    status?.identifier
      ? {
          identifier: status?.identifier,
          handleVatAmountPending: status?.handleVatAmountPending
            ? big.priceFormat(status.handleVatAmountPending)
            : '0',
          handleNetAmountPending: status?.handleNetAmountPending
            ? big.priceFormat(status.handleNetAmountPending)
            : '0',
        }
      : undefined
  );

  const noArrivalRowsText = useTxt(
    invoice
      ? 'order.receiveMode.utils.invoiceNoArrivalRows'
      : 'order.receiveMode.utils.actualCostNoArrivalRows',
    {
      identifier: status?.identifier ?? '',
    }
  );

  if (!status) {
    return null;
  }

  const projectId = invoice?.projectId || actualCost?.projectId;

  const ability = new CaslPaymentProgramRowRequestParams(projectId ?? '');
  const allowedUser = CAN('write', ability);

  return (
    <Wrapper>
      <HandlingText>
        <IndicatorImg src={status.icon} alt={statusText} />
        {!status.isHandleable && arrivalRows.length === 0
          ? noArrivalRowsText
          : statusText}
      </HandlingText>
      <StyledButtonsContainer>
        {invoice &&
        status.text === 'order.receiveMode.utils.invoiceNoCostType' ? (
          <SecondaryButton
            data-testid={`processing-tool-open-info-button-invoice${invoice.id}`}
            disabled={!allowedUser || isPending}
            onClick={() => toggleShowInfoSidebar()}
          >
            {isPending ? <Spinner size="1rem" light /> : ''}
            {!isPending ? showInfoText : ''}
          </SecondaryButton>
        ) : null}
        {invoice && invoice.isSpreadAvailable ? (
          <SecondaryButton
            data-testid={`processing-tool-spread-button-invoice${invoice.id}`}
            disabled={!allowedUser || isPending || !invoice.isSpreadAvailable}
            onClick={() => onClickSpreadInvoice(invoice.id)}
          >
            {isPending ? <Spinner size="1rem" light /> : ''}
            {!isPending ? spreadArrivalsText : ''}
          </SecondaryButton>
        ) : null}
        <HandleButton
          data-testid={`processing-tool-handle-button-${
            invoice
              ? `invoice-${invoice.id}`
              : `actualCost-${actualCost ? actualCost.id : ''}`
          }`}
          disabled={!status.isHandleable || !allowedUser || isPending}
          onClick={onClickHandleInvoice}
        >
          {isPending ? <Spinner size="1rem" light /> : ''}
          {!isPending && status.isBeingCorrected ? correctionText : ''}
          {!isPending && !status.isBeingCorrected ? processText : ''}
        </HandleButton>
      </StyledButtonsContainer>
    </Wrapper>
  );
};

const HandlingText = styled.span`
  background-repeat: no-repeat;
  background-position: 2px center;
`;

const HandleButton = styled(PrimaryButtonSmall)`
  padding: ${(props) => props.theme.margin[4]};

  height: auto;
  width: 12rem;

  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 500;
  color: ${(props) => props.theme.color.white};
`;

const SecondaryButton = styled(PrimaryButtonSmall)`
  margin: 0 1rem 0 0;

  padding: ${(props) => props.theme.margin[4]};

  height: auto;
  width: 12rem;

  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 500;
  color: ${(props) => props.theme.color.white};
`;

const StyledButtonsContainer = styled.div`
  white-space: nowrap;
`;

export default ProcessingTool;
