import Big from 'big.js';

import {
  APIOrderRow,
  APITopic,
  APIArrivalPostBody,
  APIOrderRowPutBody,
} from '../../../../types/api';
import {
  ReceiveAmountState,
  ReceiveAmountStatePiece,
} from '../../../../types/general';

import * as big from '../../../../utils/big';

// Calculates how much the given value exceeds the remaining amount of receivable
// quantity
export const calculateExcessQuantity = (orderRow: APIOrderRow, value: Big) => {
  // If quantity is null, everything is excess
  if (orderRow.quantity === null) {
    return value;
  }

  const bigValue = new Big(value);

  const bigExcessQuantity = orderRow.quantity
    .sub(orderRow.arrivalQuantity)
    .sub(bigValue);

  const excessQuantity = new Big(-bigExcessQuantity);

  // Negative quantity = let's limit excess to maximum of 0 (0 = there's no excess)
  // Positive => the same, but minimum of 0
  return orderRow.quantity < new Big(0)
    ? big.min(excessQuantity, new Big(0))
    : big.max(excessQuantity, new Big(0));
};

// Calculates how much quantity is remaining to be received for order row
export const calculateRemainingQuantity = (orderRow?: APIOrderRow): Big => {
  if (!orderRow?.quantity) {
    return new Big(0);
  }

  return orderRow.quantity.sub(orderRow.arrivalQuantity);
};

export const emptyReceiveAmountStatePiece: ReceiveAmountStatePiece = {
  amount: '',
  valid: true,
  allSelected: false,
};

// Creates a state piece for every order row we have in the order. If savedState
// is specified, tries to look up values from there first. Otherwise state piece
// will have the initial empty values.
export const getInitialReceiveAmountState = (
  topics: Partial<Record<string, APITopic>>,
  saved?: {
    state?: ReceiveAmountState;
    orderRows: Partial<Record<string, APIOrderRow>>;
  }
) => {
  const allOrderRowIds = Object.values(topics).reduce<string[]>(
    (result, topic) => [...result, ...(topic?.orderRowIds ?? [])],
    []
  );

  return allOrderRowIds.reduce<ReceiveAmountState>((result, id) => {
    // No state to load existing values from or order row ID has no saved state
    // => just return empty state piece
    if (!saved || !saved.state || !saved.state[id]) {
      return {
        ...result,
        [id]: emptyReceiveAmountStatePiece,
      };
    }

    const orderRow = saved.orderRows[id];
    const savedState = saved.state[id];

    // We want to disable allSelected for the saved value if quantity has changed
    // as the value no longer matches remaining quantity
    return {
      ...result,
      [id]:
        savedState.allSelected &&
        big.toInputString(calculateRemainingQuantity(orderRow)) !==
          savedState.amount
          ? { ...savedState, allSelected: false }
          : savedState,
    };
  }, {});
};

// Returns true if order row has everything received (=== arrivalQuantity
// equals quantity)
export const orderRowFullyReceived = (orderRow: APIOrderRow) =>
  (orderRow.quantity || new Big(0)).eq(orderRow.arrivalQuantity);

export const constructArrivalRequest = (
  state: ReceiveAmountState
): APIArrivalPostBody => ({
  arrivalRows: Object.keys(state)
    .map((orderRowId) => ({
      orderRowId,
      quantity: big.fromInputString(state[orderRowId].amount, 0).toString(),
    }))
    // Ignore empty rows in the request, or rows that have 0 as value
    .filter((arrivalRow) => !new Big(arrivalRow.quantity).eq(0)),
});

// Assumes that receiveAmount is a valid number.
export const constructQuantityIncreaseRequest = (
  orderRow: APIOrderRow,
  receiveAmount: string
): APIOrderRowPutBody => {
  const excessQuantity = calculateExcessQuantity(
    orderRow,
    big.fromInputString(receiveAmount, 0)
  );

  const newQuantity =
    orderRow.quantity === null
      ? excessQuantity
      : orderRow.quantity.add(excessQuantity);

  return {
    description: orderRow.description,
    quantity: newQuantity.toString(),
    statusId: orderRow.statusId,
    unit: orderRow.unit,
    unitPrice: orderRow.unitPrice ? orderRow.unitPrice.toString() : null,
    updatedAt: orderRow.updatedAt,
    rowNumber: orderRow.rowNumber,
  };
};
