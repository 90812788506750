import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ScheduledTask } from '../../actions/schedule/scheduledTasks';
import { ActionTypes as Action } from '../../actionTypes';

type Err = BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<string, ScheduledTask[]>;
};

const initialState: State = {
  requests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WORK_PACKAGE_SCHEDULED_TASKS_STARTED': {
      const { workPackageId } = action.payload;

      const requests = {
        ...state.requests,
        [workPackageId]: remoteData.loading,
      };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_WORK_PACKAGE_SCHEDULED_TASKS_FAILURE': {
      const { workPackageId, error } = action.payload;

      const requests = {
        ...state.requests,
        [workPackageId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }

    case 'GET_WORK_PACKAGE_SCHEDULED_TASKS_SUCCESS': {
      const { workPackageId, projectScheduledTasksEntries } = action.payload;

      const requests = {
        ...state.requests,
        [workPackageId]: remoteData.succeed(undefined),
      };

      return {
        requests,
        data: { ...state.data, [workPackageId]: projectScheduledTasksEntries },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  workPackageId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    schedule: {
      scheduledTasks: {
        requests: { [workPackageId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
}

export function isLoading(workPackageId: string): Selector<boolean> {
  return flow(
    getRequestState(workPackageId),
    (requestState) => requestState === 'Loading'
  );
}

export const getScheduledTasksEntriesForWorkPackageId: (
  workPackageId: string
) => Selector<remoteData.RemoteData<ScheduledTask[]>> = (workPackageId) => ({
  schedule: {
    scheduledTasks: {
      requests: { [workPackageId]: requestState = remoteData.notAsked },
      data,
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    const scheduledTasks = data[workPackageId];

    if (!scheduledTasks) {
      return [];
    }

    return scheduledTasks;
  });
