import { isDefined } from '../../utils/general';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes as Action } from '../actionTypes';

import { AppState } from '.';

export type BatchGroup = {
  updatedAt: string;
  createdAt: string;
  paymentBatchGroupCode: string;
  description: string;
  id: string;
};

type Mapping<A> = Partial<Record<string, A>>;
export type BatchGroups = Mapping<BatchGroup>;
export type State = remoteData.RemoteData<BatchGroups>;
const initalState = remoteData.notAsked;

export default function reducer(
  state: State = initalState,
  action: Action
): State {
  switch (action.type) {
    case 'GET_BATCH_GROUPS_STARTED': {
      return remoteData.loading;
    }
    case 'GET_BATCH_GROUPS_FAILURE': {
      return remoteData.fail(undefined);
    }
    case 'GET_BATCH_GROUPS_SUCCESS': {
      const { payload: batchGroups } = action;

      return remoteData.succeed(
        batchGroups.reduce(
          (acc: Partial<Record<string, BatchGroup>>, batchGroup) => ({
            ...acc,
            [batchGroup.id]: batchGroup,
          }),
          {}
        )
      );
    }
    default: {
      return state;
    }
  }
}

export function getBatchGroups({
  batchGroups,
}: Pick<AppState, 'batchGroups'>): remoteData.RemoteData<BatchGroup[]> {
  return remoteData.map(batchGroups, (value) => {
    const groups = Object.values(value).filter(isDefined);

    return groups;
  });
}
