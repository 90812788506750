import React from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getProject } from '../../../store/reducers/project';

import { fetchProjects } from '../../../store/actions/project';

import RemoteData from '../../../components/RemoteData';
import Txt from '../../../components/Txt';

import * as big from '../../../utils/big';
import * as RD from '../../../utils/remoteData';

const Container = styled.div`
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: ${({
    theme: {
      color: { rowBorder },
    },
  }) => rowBorder};

  padding-right: ${({ theme: { margin } }) => margin[16]};
  padding-left: ${({ theme: { margin } }) => margin[16]};

  height: ${({ theme: { margin } }) => margin[32]};

  display: flex;
  align-items: center;
`;

const Span = styled.span`
  margin-right: ${({ theme: { margin } }) => margin[32]};

  font-size: ${({
    theme: {
      fontSize: { small },
    },
  }) => small};
  line-height: 0.875rem;
  font-weight: 400;
  font-family: 'Graphik', sans-serif;
`;

type EurosProps = {
  euros: RD.RemoteData<Big | undefined>;
};

const Euros = ({ euros: data }: EurosProps) => (
  <RemoteData
    data={data}
    fetchData={fetchProjects()}
    loadingElement={<Txt id="meta.loading" component="b" />}
    failureElement={<Txt id="meta.networkError" component="b" />}
  >
    {(value) => {
      const euros = big.amountFormat(value ?? new Big(0), 0);

      return <Txt id="meta.euros" values={{ euros }} component="b" />;
    }}
  </RemoteData>
);

type SummaryProps = {
  projectId: string;
};

const Summary = ({ projectId }: SummaryProps) => {
  const totals = useSelector(getProject(projectId));

  const targetTotal = RD.map(totals, (project) => project?.targetTotal);

  const predictionTotal = RD.map(
    totals,
    (project) => project?.costPredictionTotal
  );

  const differenceToTarget = RD.map(totals, (project) =>
    project?.targetTotal.minus(project?.costPredictionTotal)
  );

  return (
    <Container>
      <Txt
        id="target.summary.target"
        values={{ amount: <Euros euros={targetTotal} /> }}
        component={Span}
      />
      <Txt
        id="target.summary.predictionTotal"
        values={{ amount: <Euros euros={predictionTotal} /> }}
        component={Span}
      />
      <Txt
        id="target.summary.differenceToTarget"
        values={{ amount: <Euros euros={differenceToTarget} /> }}
        component={Span}
      />
    </Container>
  );
};

export default Summary;
