import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../../types/general';

import { BackendError } from '../../../utils/api';
import { isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes } from '../../actionTypes';

export type OrderChangeLogEntry = {
  id: ID;
  userId: ID;
  userName: string;
  snapshotId: ID | null;
  timestamp: Date;
  orderId: ID;
  orderRowId: ID;
  orderRowNo: number;
  orderRowDescription: string;
  changeType:
    | 'changeOfCosts'
    | 'deletion'
    | 'orderChange'
    | 'topicChange'
    | 'statusChange'
    | 'newRow';
  differenceForOrderTotal: Big;
  moveChangeInfo?: string;
  quantityChangeInfo?: string;
  unitPriceChangeInfo?: string;
};

type Err = BackendError | undefined;

type State = {
  getRequests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Partial<Record<string, OrderChangeLogEntry>>;
};

const initialState: State = {
  getRequests: {},
  data: {},
};

const orderChangeLogEntryReducer: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'GET_ORDER_CHANGE_LOG_ENTRIES_STARTED': {
      const { orderId } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.loading,
      };

      return { ...state, getRequests };
    }
    case 'GET_ORDER_CHANGE_LOG_ENTRIES_FAILURE': {
      const { orderId, error } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.fail(error),
      };

      return { ...state, getRequests };
    }
    case 'GET_ORDER_CHANGE_LOG_ENTRIES_SUCCESS': {
      const { orderId, orderChangeLogEntries } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', orderChangeLogEntries),
      };

      return {
        getRequests,
        data,
      };
    }
    default:
      return state;
  }
};

export default orderChangeLogEntryReducer;

export const getOrderChangeLogEntriesFetchRequest = (
  orderId: string
): Selector<remoteData.RemoteData['kind']> => {
  return ({
    orders: {
      changeLogEntries: {
        getRequests: { [orderId]: request = remoteData.notAsked },
      },
    },
  }) => request.kind;
};

export const getOrderChangeLogEntries: (
  orderId: string
) => Selector<remoteData.RemoteData<OrderChangeLogEntry[]>> = (orderId) => ({
  orders: {
    changeLogEntries: {
      getRequests: { [orderId]: requestState = remoteData.notAsked },
      data,
    },
  },
}) =>
  remoteData.map(requestState, (_) =>
    Object.values(data)
      .filter(isDefined)
      .filter((entry) => entry.orderId === orderId)
  );

// TODO: Is the above mapping necessary?
