import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/schedule/projectTimeline';

const actionCreators = {
  ...makeAction('getProjectTimelinesStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getProjectTimelinesSuccess')<{
    projectId: string;
    projectTimelineEntries: ProjectTimelineEntry[];
  }>(),
  ...makeAction('getProjectTimelinesFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
};

export type ProjectTimelineAction = ExtractActionTypes<typeof actionCreators>;

const apiProjectTimelineEntryType = t.exact(
  t.type({
    projectId: t.string,
    date: dateString,
    percentageOfCompletion: bigString,
    snapshotTypeId: t.string,
    snapshotId: t.union([t.string, t.null]),
    currentPeriod: t.boolean,
    latestSnapshot: t.boolean,
    pastPeriod: t.boolean,
  })
);

export type ProjectTimelineEntry = t.TypeOf<typeof apiProjectTimelineEntryType>;

const {
  getProjectTimelinesStarted,
  getProjectTimelinesSuccess,
  getProjectTimelinesFailure,
} = actionCreators;

export async function toProjectTimelineEntries(
  u: unknown
): Promise<ProjectTimelineEntry[]> {
  const targetRows = await tPromise.decode(
    t.array(apiProjectTimelineEntryType),
    u
  );

  return targetRows;
}

const requestProjectTimelineForProject = async ({
  projectId,
}: {
  projectId: string;
}): Promise<ProjectTimelineEntry[]> => {
  const response = await GET<ProjectTimelineEntry[]>(
    `v1/projects/${projectId}/project-timelines`
  );

  return toProjectTimelineEntries(response);
};

export const getProjectTimelineForProject = ({
  projectId,
}: {
  projectId: string;
}) =>
  createAsyncThunk(requestProjectTimelineForProject, {
    args: [{ projectId }],
    initialAction: getProjectTimelinesStarted({ projectId }),
    isPending: isLoading(projectId),
    failureActionCreator: (error) =>
      getProjectTimelinesFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (projectTimelineEntries) =>
      getProjectTimelinesSuccess({
        projectId,
        projectTimelineEntries,
      }),
  });
