import { flow } from '../../../utils/function';

import { ActionTypes as Action } from '../../actionTypes';
import { ActualCost } from '../actualCost';
import { getSortOrderForKey, SortState } from '../helpers/sort';
import { AppState } from '../index';

// can be extended if needed,
// for now, users are able to sort invoices by dueDate
export type SortableKey = Extract<keyof ActualCost, 'date'>;

export type ActualCostSortState = SortState<SortableKey>;

const initialSortState: ActualCostSortState = [
  {
    key: 'date',
    sortOrder: 'Ascending',
  },
];

function sortActualCostReducer(
  state: ActualCostSortState = initialSortState,
  action: Action
): ActualCostSortState {
  switch (action.type) {
    case 'TOGGLE_ACTUALCOST_SORT': {
      const { sortableKey } = action.payload;
      const oldOrder = getSortOrderForKey(state, sortableKey);
      const newOrder = oldOrder === 'Ascending' ? 'Descending' : 'Ascending';

      const otherOrderings = state.filter(({ key }) => key !== sortableKey);

      return [{ key: sortableKey, sortOrder: newOrder }, ...otherOrderings];
    }
    default: {
      return state;
    }
  }
}

export function getSortOrders({
  actualCosts: { sortOrders },
}: AppState): ActualCostSortState {
  return sortOrders;
}

export const getActualCostSortOrderFor = (sortableKey: 'date') =>
  flow(getSortOrders, (sortOrders) =>
    getSortOrderForKey(sortOrders, sortableKey)
  );

export default sortActualCostReducer;
