import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  Redirect,
  useLocation,
  matchPath,
} from 'react-router-dom';

import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { AppState } from './store/reducers';
import { ProcurementAreaState } from './store/reducers/procurementArea';
import { ProjectState } from './store/reducers/project';
import {
  activeProjectLocalStorageKey,
  getOuterBarState,
} from './store/reducers/ui';
import { UserState } from './store/reducers/user';

import * as Actions from './store/actions';
import { setActiveProject, setOuterBarState } from './store/actions/ui';

import { APIProcurementArea } from './types/api';
import { GlobalState } from './types/general';

import GlobalNotificationHandler from './components/GlobalNotificationHandler';
import { GlobalLoadingSpinner } from './components/Loading';
import LeftSideNav from './components/Navigation/LeftSideNav';
import OuterBar from './components/OuterBar';
import Txt from './components/Txt';

import { getBaseApiUrl } from './utils/api';
import { ability, defineRulesFor } from './utils/caslUserPermissions';
import { allInitialized } from './utils/general';

import { AppContextProvider } from './context';
import { routes } from './routes';
import AnalysisView from './views/Analysis';
import HomePageView from './views/HomePageView/HomePageView';
import ActualCostLinesTable from './views/OrderView/components/ActualCostLines';
import InvoiceLinesTable from './views/OrderView/components/PurchaseInvoiceLines';
import OrderView from './views/OrderView/OrderView';
import PageNotFoundView from './views/PageNotFoundView/PageNotFoundView';
import ProjectView from './views/ProjectView/ProjectView';
import ReportingView from './views/ReportingView';
import RevenueView from './views/Revenue';
import TargetView from './views/TargetView';
import WorksectionView from './views/Worksection';
import WorkSectionExpanded from './views/WorkSectionExpanded';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
`;

const FlexRow = styled.div`
  height: 100%;
  display: flex;
`;

const Embed = styled.embed`
  height: 30vw;
  z-index: 20;
`;

const StyledH2 = styled.h2`
  position: absolute;
  top: 100px;

  width: 300px;

  font-weight: bold;
  color: white;
  text-align: center;

  z-index: 10;
`;

type ProjectRoutesProps = {
  projects: ProjectState;
  procurementAreas: GlobalState<APIProcurementArea>;
};

/* Commenting here until we have a file of its own for projectRoutes and other
 * component routes:
 * Now the <LeftSideNav> component is checking for active NavLinks based on the
 * url (not the params), so DO NOT use words from other urls/paths in new route's
 * url/path
 */

// Splitting this into it's own file could be wise, but at the same time it
// would be nice to keep all routes in the same file. So for now, I'll keep it
// here. -Valtteri
const ProjectRoutes = ({ projects, procurementAreas }: ProjectRoutesProps) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    const currentProject = localStorage.getItem(activeProjectLocalStorageKey);

    if (currentProject === projectId) {
      return;
    }

    localStorage.setItem(activeProjectLocalStorageKey, projectId);

    dispatch(setActiveProject(projectId));
  }, [projectId, dispatch]);

  // Fetch project-related data here every time project changes.
  useEffect(() => {
    dispatch(Actions.fetchProcurementAreasForProject(projectId));
    dispatch(Actions.fetchWorkPackagesForProject(projectId));
    dispatch(Actions.fetchOrdersForProject(projectId));
    dispatch(Actions.requestRevenues(projectId));
  }, [projectId, dispatch]);

  // Check that project and it's procurement areas have been fetched,
  // if not, display loading spinner.
  if (
    ['Loading', 'NotAsked'].includes(projects.kind) ||
    (projects.kind === 'Success' &&
      !allInitialized(
        procurementAreas,
        projects.value[projectId]?.procurementAreaIds ?? []
      ))
  ) {
    return <GlobalLoadingSpinner />;
  }

  // redirect to root if project fetch fails or project is not accessible by user
  // (is not included in backend response)
  if (
    projects.kind !== 'Success' ||
    (!isEmpty(projects.value) && !(projectId in projects.value))
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route
        path={`${match.path}/order/:orderId/:viewMode(edit|receive|comments)?/:subViewMode(invoices)?/:showTargetRows(showTarget)?/:showInfo(info)?`}
        exact
        component={OrderView}
      />
      <Route path={match.path} exact component={ProjectView} />
      <Route
        path={routes.WORKSECTION_EXPANDED}
        component={WorkSectionExpanded}
      />
      <Route path={routes.WORKSECTIONS} component={WorksectionView} />
      <Route path={routes.REVENUE} component={RevenueView} />
      <Route path={routes.ANALYSIS} component={AnalysisView} />
      <Route path={routes.REPORTING} component={ReportingView} />
      <Route path={routes.TARGET} component={TargetView} />
    </Switch>
  );
};

type AppProps = {
  projects: ProjectState;
  procurementAreas: ProcurementAreaState;
  user: UserState;
};

const getLoginUrl = () => {
  const loginReturnUrl = encodeURI(window.location.href);

  return `${process.env.REACT_APP_API_BASE_URL}login?returnTo=${loginReturnUrl}`;
};

function initiateLogin() {
  window.location.replace(getLoginUrl());
}

const App = ({ projects, procurementAreas, user }: AppProps) => {
  const dispatch = useDispatch();
  const apiBaseUrl = getBaseApiUrl();

  useEffect(() => {
    if (user.loggedIn === undefined) {
      dispatch(Actions.fetchUserState());
    } else if (!user.loggedIn) {
      initiateLogin();
    } else {
      const currentUser = user.current;
      ability.update(defineRulesFor(currentUser));

      dispatch(Actions.fetchProjects());
    }
  }, [dispatch, user]);

  const outerBarState = useSelector(getOuterBarState());

  const closeOuterBar = () => {
    dispatch(setOuterBarState(null));
  };

  const location = useLocation();

  useEffect(() => {
    // if navigation took to outside order view, remove selected invoice ID,
    // effectively setting invoice OuterBar as closed
    const pathMatchesOrderPaths = matchPath(location.pathname, {
      path: [
        routes.ORDER,
        routes.ORDER_WITH_OPEN_TOPIC,
        routes.ORDER_WITH_ORDER_ROW_FOCUSED,
      ],
      exact: true,
    });

    // matchPath returns a result object or null
    if (pathMatchesOrderPaths === null) {
      dispatch(setOuterBarState(null));
    }
  }, [location, dispatch]);

  return user.loggedIn ? (
    <FlexRow>
      <AppContextProvider>
        <LeftSideNav />
        <FlexColumn>
          <GlobalNotificationHandler />
          <Switch>
            <Route path={routes.PROJECT}>
              <ProjectRoutes
                projects={projects}
                procurementAreas={procurementAreas}
              />
            </Route>
            <Route path={routes.HOME} exact component={HomePageView} />
            <Route component={PageNotFoundView} />
          </Switch>
        </FlexColumn>
        {outerBarState !== null ? (
          <OuterBar
            onClose={() => closeOuterBar()}
            linesTable={
              outerBarState.type === 'invoice_header' ? (
                <InvoiceLinesTable invoiceHeaderId={outerBarState.contentId} />
              ) : (
                <ActualCostLinesTable actualCostId={outerBarState.contentId} />
              )
            }
          >
            <Embed
              key={outerBarState.contentId}
              src={
                outerBarState.type === 'invoice_header'
                  ? `${apiBaseUrl}v1/attachments/purchase-invoice-headers/${outerBarState.contentId}`
                  : `${apiBaseUrl}v1/attachments/actual-costs/${outerBarState.contentId}`
              }
            />
            <StyledH2>
              <Txt id="order.invoice.no.image" />
            </StyledH2>
          </OuterBar>
        ) : null}
      </AppContextProvider>
    </FlexRow>
  ) : (
    <GlobalLoadingSpinner />
  );
};

const mapStateToProps = (state: AppState): AppProps => ({
  projects: state.projects,
  procurementAreas: state.procurementAreas,
  user: state.user,
});

export default connect(mapStateToProps)(App);
