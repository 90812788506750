import React from 'react';
import { useDispatch } from 'react-redux';

import assertNever from '../utils/assertNever';
import * as RD from '../utils/remoteData';
import { Thunk } from '../utils/thunk';

import { Spinner } from './Loading';

type RemoteDataProps<Value, Err> = {
  data: RD.RemoteData<Value, Err>;
  fetchData?: Thunk;
  loadingElement?: React.ReactElement;
  failureElement?: React.ReactElement;
  children: (value: Value) => React.ReactElement | null;
};

function RemoteData<Val, Err>({
  data,
  fetchData,
  loadingElement,
  failureElement,
  children,
}: RemoteDataProps<Val, Err>): React.ReactElement | null {
  const dispatch = useDispatch();

  React.useEffect(() => {
    switch (data.kind) {
      case 'NotAsked':
        if (fetchData !== undefined) {
          dispatch(fetchData);
        }

        break;
      case 'Loading':
      case 'Failure':
      case 'Success':
        break;
      default:
        assertNever(data);
    }
  }, [data, fetchData, dispatch]);

  switch (data.kind) {
    case 'NotAsked':
    case 'Loading':
      return loadingElement ?? <Spinner size="4rem" />;
    case 'Failure':
      return failureElement ?? loadingElement ?? <Spinner size="4rem" />;
    case 'Success':
      return children(data.value);
    default:
      throw assertNever(data);
  }
}

export default RemoteData;
