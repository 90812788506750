import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Revenue } from '../../../../store/reducers/revenue/revenue';

import { createMultiplePaymentPrograms } from '../../../../store/actions';

import useTxt from '../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
  IconTextButton,
} from '../../../../components/Buttons';
import TextArea from '../../../../components/Input/TextArea';
import { Spinner } from '../../../../components/Loading';
import Modal, {
  Container,
  Header,
  Content,
  Footer,
} from '../../../../components/Modal/Modal';

import {
  parseRevenueRows,
  ParsedRevenueResultRow,
  everythingValid,
  constructPaymentProgramRowsRequest,
} from './utils';

import { IconInvalid } from '../../../../assets/svg';

import { Context } from '../../../../context/locale';
import {
  InputContainer,
  TableContainer,
  InfoContainer,
  ErrorText,
} from './Components';
import PreviewTable from './PreviewTable';

type Props = {
  paymentProgramRowGroupId: string;
  onClose: () => void;
  rows: Revenue[];
};

const ExcelImporter = ({ paymentProgramRowGroupId, onClose, rows }: Props) => {
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useDispatch();
  const localContext = useContext(Context);

  // Raw content of the textarea
  const [content, setContent] = useState('');

  // Parsed rows from the raw content
  const [parsedContent, setParsedContent] = useState<ParsedRevenueResultRow[]>(
    []
  );

  // Is everything valid in the input?
  const [valid, setValid] = useState(true);
  // Is submit request in progress?
  const [submitting, setSubmitting] = useState(false);

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  useEffect(() => {
    const newParsedContent = parseRevenueRows(content, localContext.lang, rows);
    setParsedContent(newParsedContent);
    setValid(everythingValid(newParsedContent));
  }, [content, rows, localContext]);

  const onSubmit = () => {
    setSubmitting(true);

    dispatch(
      createMultiplePaymentPrograms(
        constructPaymentProgramRowsRequest(
          parsedContent,
          paymentProgramRowGroupId,
          projectId,
          localContext.lang
        ),
        onClose
      )
    );

    setSubmitting(false);
  };

  const importRowsText = useTxt('order.excelImport.importRows');

  const instructionsText = useTxt('revenue.excelImport.instructions');
  const errorText = useTxt('order.excelImport.error');
  const cancelText = useTxt('common.cancel');
  const createText = useTxt('order.excelImport.createRows');

  const numberOfRowsText = useTxt('revenue.excelImport.numberOfRows', {
    numberOfNewRows: parsedContent.length,
  });

  const displayNumberOfRows = () => {
    if (parsedContent.length > 0) {
      return numberOfRowsText;
    }
  };

  return (
    <Modal onClose={onClose}>
      <StyledContainer>
        <Header>{importRowsText}</Header>
        <StyledContent>
          <InfoContainer>{instructionsText}</InfoContainer>
          <InputContainer>
            <TextArea value={content} onChange={onTextAreaChange} />
          </InputContainer>
          <TableContainer>
            <PreviewTable rows={parsedContent} />
          </TableContainer>
        </StyledContent>
        <Footer>
          <ErrorText valid={valid}>
            {!valid ? (
              <>
                <IconTextButton icon={IconInvalid}>{errorText}</IconTextButton>
              </>
            ) : (
              displayNumberOfRows()
            )}
          </ErrorText>
          <ButtonGroup>
            <SecondaryButton onClick={onClose}>{cancelText}</SecondaryButton>
            <StyledPrimaryButton
              disabled={parsedContent.length === 0 || !valid || submitting}
              onClick={onSubmit}
            >
              {submitting ? <Spinner size="1rem" light /> : createText}
            </StyledPrimaryButton>
          </ButtonGroup>
        </Footer>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled(Container)`
  width: 60vw;
`;

const StyledContent = styled(Content)`
  padding: 2.5rem;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: 7rem;
`;

export default ExcelImporter;
