/* eslint-disable max-classes-per-file */
import {
  createMongoAbility,
  MongoAbility,
  AbilityBuilder,
  AbilityClass,
  PureAbility,
  Subject,
  MongoQuery,
  InferSubjects,
} from '@casl/ability';

// "Actions" are the first argument to the Casl ability checker(which is exported from here below as default)
// they basically define what type of action is being performed.
type Actions = 'list' | 'read' | 'write';

// "Subjects" are the second argument to the checker.
// Casl gets object.constructor.modelName as subject type, and fallbacks
// to object.constructor.name if modelName is not specified.
// see example here ===> https://casl.js.org/v5/en/guide/subject-type-detection

type Subjects = InferSubjects<
  | CaslProjectRequestParams
  | CaslOrderRequestParams
  | CaslUserPersonalSettingsRequestParams
  | CaslUserRightsRequestParams
  | CaslWorkPackageRequestParams
  | CaslOrderRowRequestParams
  | CaslTopicRequestParams
  | CaslArrivalRowsRequestParams
  | CaslActualCostsRequestParams
  | CaslPurchaseInvoiceHeadersRequestParams
  | CaslPaymentProgramRowRequestParams
  | CaslAnalysisDefinitionRequestParams
  | CaslSnapshotsRequestParams
  | CaslTaskCreationAndReadRequestParams
  | CaslTaskUpdateRequestParams
  | CaslPaymentProgramRowGroupRequestParams
>;

type PossibleAbilities = [string, Subject];
type Conditions = MongoQuery;

export class CaslProjectRequestParams {
  static modelName = 'CaslProjectRequestParams';

  kind = 'CaslProjectRequestParams';

  projectId: string;

  constructor(id: string) {
    this.projectId = id;
  }
}

export class CaslUserPersonalSettingsRequestParams {
  static modelName = 'CaslUserPersonalSettingsRequestParams';

  kind = 'CaslUserPersonalSettingsRequestParams';

  userId: string;

  constructor(id: string) {
    this.userId = id;
  }
}

export class CaslUserRightsRequestParams {
  static modelName = 'CaslUserRightsRequestParams';

  kind = 'CaslUserRightsRequestParams';

  userId: string;

  constructor(id: string) {
    this.userId = id;
  }
}

export class CaslTaskUpdateRequestParams {
  static modelName = 'CaslTaskUpdateRequestParams';

  kind = 'CaslTaskUpdateRequestParams';

  taskId: string;

  constructor(id: string) {
    this.taskId = id;
  }
}

export class CaslOrderRequestParams extends CaslProjectRequestParams {}
export class CaslWorkPackageRequestParams extends CaslProjectRequestParams {}
export class CaslOrderRowRequestParams extends CaslProjectRequestParams {}
export class CaslTargetRowRequestParams extends CaslProjectRequestParams {}
export class CaslTopicRequestParams extends CaslProjectRequestParams {}
export class CaslArrivalRowsRequestParams extends CaslProjectRequestParams {}
export class CaslActualCostsRequestParams extends CaslProjectRequestParams {}
export class CaslPurchaseInvoiceHeadersRequestParams extends CaslProjectRequestParams {}
export class CaslPaymentProgramRowRequestParams extends CaslProjectRequestParams {}
export class CaslPaymentProgramRowGroupRequestParams extends CaslProjectRequestParams {}
export class CaslAnalysisDefinitionRequestParams extends CaslProjectRequestParams {}
export class CaslSnapshotsRequestParams extends CaslProjectRequestParams {}
export class CaslTaskCreationAndReadRequestParams extends CaslProjectRequestParams {}

export type AppAbility = MongoAbility<[Actions, Subjects]>;
const Ability = PureAbility as AbilityClass<AppAbility>;

export const ability = createMongoAbility<PossibleAbilities, Conditions>();

// a domain spacific language that casle uses to create abilities
// see example https://casl.js.org/v5/en/guide/define-rules#:~:text=too%20much%20code.-,defineAbility,-example

export const defineRulesFor = (user: any) => {
  const externalRules = user.rules;
  const { can, rules } = new AbilityBuilder(Ability);

  if (externalRules) {
    externalRules.forEach((rule: any) => {
      if (rule) {
        can(rule.action, rule.subject, rule.conditions);
      }
    });
  }

  return rules;
};

// can/cannot casl permission checker
// takes and "Action" and "Subject" of type defined above and returns a boolean
// true -> user has permission to perform the action and false -> vice versa
export default (action: Actions, subject: Subjects) => {
  return ability.can(action, subject);
};
