import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { getSelectableAnalysisRowsForRevenue } from '../../../../store/reducers/analysis';
import { getAnalysisRow } from '../../../../store/reducers/analysis/row';
import { getRevenue } from '../../../../store/reducers/revenue/revenue';

import { requestSelectableAnalysisRowsForRevenue } from '../../../../store/actions';
import {
  createRevenueAnalysisLinkage,
  removeRevenueAnalysisLinkage,
} from '../../../../store/actions/analysis/revenueLinkage';
import { requestAnalysisRows } from '../../../../store/actions/analysis/row';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useRemoteData from '../../../../hooks/useRemoteData';

import {
  Tag,
  ShowMoreTag,
  PlusTag,
  StyledAnalysisLinker,
  EDIT_TAG_AMOUNT,
  VIEW_TAG_AMOUNT,
} from '../../../../components/Analysis/AnalysisTags';

import { useParams, routes } from '../../../../routes';

type AnalysisTagsProps = {
  revenueId: string;
};

const AnalysisTags = ({ revenueId }: AnalysisTagsProps) => {
  const { viewMode, projectId } = useParams(routes.REVENUE);

  const [showAnalysisLinker, setShowAnalysisLinker] = React.useState(false);

  const analysisRowIds =
    useSelector(getRevenue({ projectId, revenueId }))?.analysisRowIds ?? [];

  const showAmount = viewMode === 'edit' ? EDIT_TAG_AMOUNT : VIEW_TAG_AMOUNT;

  const showMoreTag = analysisRowIds.length > showAmount;
  const visibleTags = analysisRowIds.slice(0, showAmount);

  const ref = useOnClickOutside(() => setShowAnalysisLinker(false));

  const selector = getSelectableAnalysisRowsForRevenue({
    revenueId,
    projectId,
  });

  const fetchData = requestSelectableAnalysisRowsForRevenue({
    revenueId,
    projectId,
  });

  return (
    <>
      {visibleTags.map((id) => (
        <AnalysisTag key={id} analysisRowId={id} />
      ))}
      {showMoreTag ? <ShowMoreTag /> : null}
      {viewMode !== 'edit' ? null : (
        <>
          <ButtonContainer ref={ref}>
            <PlusTag
              isPressed={showAnalysisLinker}
              onClick={() => setShowAnalysisLinker(!showAnalysisLinker)}
            />
            {showAnalysisLinker ? (
              <StyledAnalysisLinker
                linkedEntityId={revenueId}
                existingLinks={analysisRowIds}
                close={() => setShowAnalysisLinker(false)}
                selector={selector}
                fetchData={fetchData}
                linker={createRevenueAnalysisLinkage}
                unlinker={removeRevenueAnalysisLinkage}
              />
            ) : null}
          </ButtonContainer>
        </>
      )}
    </>
  );
};

export default AnalysisTags;

type TagProps = {
  analysisRowId: string;
};

export const AnalysisTag = ({ analysisRowId }: TagProps) => {
  const { projectId } = useParams(routes.REVENUE);

  const code =
    useRemoteData(
      getAnalysisRow({ projectId, analysisRowId }),
      requestAnalysisRows({ projectId })
    )?.code ?? '';

  return <Tag>{code}</Tag>;
};

const ButtonContainer = styled.div`
  position: relative;
`;
