import { flow } from '../../../utils/function';

import { AnalysisRow } from '../../actions';
import { ActionTypes as Action } from '../../actionTypes';
import { SortOrder } from '../helpers/sort';
import { AppState } from '../index';

export type SortableKey = Extract<
  keyof AnalysisRow,
  | 'orderNumber'
  | 'value'
  | 'code'
  | 'orderRowsAmount'
  | 'paymentProgramRowsAmount'
  | 'targetRowsAmount'
  | 'arrivalsAmount'
  | 'attributeValues'
>;

export const SortableKeys = {
  orderNumber: 'orderNumber',
  value: 'value',
  code: 'code',
  orderRowsAmount: 'orderRowsAmount',
  targetRowsAmount: 'targetRowsAmount',
  paymentProgramRowsAmount: 'paymentProgramRowsAmount',
  arrivalsAmount: 'arrivalsAmount',
  attributeValues: 'attributeValues',
} as const;

export type ExtendedSortOrder = SortOrder | 'NoSorting';

type Mapping<A> = Partial<Record<string, A>>;

type SortState = Mapping<{
  key: SortableKey;
  attributeId?: string;
  sortOrder: ExtendedSortOrder;
}>;

export const getSortOrderForKey = (
  sortOrders: SortState,
  sortableKey: SortableKey,
  analysisGroupId: string,
  attributeId?: string
): ExtendedSortOrder => {
  const analysisGroupSortOrders = sortOrders[analysisGroupId];

  if (!analysisGroupSortOrders) {
    return 'NoSorting';
  }

  if (analysisGroupSortOrders.key === 'attributeValues') {
    return analysisGroupSortOrders.attributeId &&
      attributeId &&
      analysisGroupSortOrders.attributeId === attributeId
      ? analysisGroupSortOrders.sortOrder
      : 'NoSorting';
  }

  return analysisGroupSortOrders.key === sortableKey
    ? analysisGroupSortOrders.sortOrder
    : 'NoSorting';
};

const initialSortByState: SortState = {};

function sortByReducer(
  state: SortState = initialSortByState,
  action: Action
): SortState {
  switch (action.type) {
    case 'ANALYSIS_ROW_SORT_TOGGLED': {
      const { sortableKey, analysisGroupId, attributeId } = action.payload;

      const oldOrder = getSortOrderForKey(
        state,
        sortableKey,
        analysisGroupId,
        attributeId
      );
      const newOrder = oldOrder === 'Ascending' ? 'Descending' : 'Ascending';

      return {
        ...state,
        [analysisGroupId]: {
          key: sortableKey,
          sortOrder: newOrder,
          attributeId,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export function getSortOrdersForAnalysis({
  analysis: { sortOrders },
}: AppState): SortState {
  return sortOrders;
}

// For table header use
export const getSortOrderForAnalysisGroup = (
  sortableKey: SortableKey,
  analysisGroupId: string,
  attributeId?: string
) =>
  flow(getSortOrdersForAnalysis, (sortOrders) =>
    getSortOrderForKey(sortOrders, sortableKey, analysisGroupId, attributeId)
  );

export default sortByReducer;
