import React from 'react';

import { orderBy } from 'lodash';
import styled from 'styled-components';

import { getProjectOrders } from '../../../store/reducers/order/order';

import * as Actions from '../../../store/actions';

import { APIOrder } from '../../../types/api';

import useRemoteData from '../../../hooks/useRemoteData';
import useRouteParams from '../../../hooks/useRouteParams';

import { useDebouncedValue } from '../../../utils/hooks';
import { searchFilter } from '../../../utils/search';

type OrderListProps = {
  filter: string;
  selected: string;
  allNotRemovable: boolean;
  onSelect: (id: string) => void | undefined;
};

const OrderList = ({
  filter,
  selected,
  allNotRemovable,
  onSelect,
}: OrderListProps) => {
  const { orderId, projectId } = useRouteParams();

  const orderRef = React.useRef<any>();

  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    if (isScrolled) {
      return;
    }

    if (orderRef.current) {
      orderRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
      setIsScrolled(true);
    }
  }, [orderRef, isScrolled]);

  const orders =
    useRemoteData(
      getProjectOrders(projectId),
      Actions.fetchOrdersForProject(projectId)
    ) ?? [];

  let filteredOrders: APIOrder[] = [];

  const textFilteredOrders = searchFilter(
    orders,
    useDebouncedValue(filter, 1000)
  );

  filteredOrders = orderBy(textFilteredOrders, ['visibleCode'], ['asc']);

  // If there is already arrival rows linked (orderRow.isRemovable = false) orderRow can't be
  // moved to another order, only within the order itself to another topic.

  if (allNotRemovable) {
    filteredOrders = textFilteredOrders.filter((order) => order.id === orderId);
  }

  return (
    <Container>
      {filteredOrders.map(({ id, name, visibleCode, contractor }) => (
        <Order
          {...(orderId === id
            ? {
                ref: orderRef,
              }
            : {})}
          key={id}
          onClick={() => onSelect(id)}
          isSelected={id === selected}
          data-testid={`move-modal-order-button-${id}`}
        >
          <VisibleCodeSpan>{visibleCode}</VisibleCodeSpan>
          {contractor ? (
            <>
              <ContractorSpan>{name}</ContractorSpan>
              <OrderNameSpan>{contractor}</OrderNameSpan>
            </>
          ) : (
            <OrderNameSpan>{name}</OrderNameSpan>
          )}
        </Order>
      ))}
    </Container>
  );
};

export default OrderList;

const VisibleCodeSpan = styled.span`
  width: 14%;
  text-align: left;
`;

const ContractorSpan = styled.span`
  width: 43%;
  text-align: left;
`;

const OrderNameSpan = styled.span`
  width: 43%;
  text-align: right;
`;

const Container = styled.div`
  margin-bottom: 2rem;

  height: 30vh;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
`;

const Order = styled.button<{ isSelected: boolean }>`
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${({ theme: { color } }) => color.dropdownBorder};

  padding: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ isSelected, theme: { color } }) =>
    isSelected ? color.toolbarHighlight : color.white};

  font-family: 'Graphik', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
`;
