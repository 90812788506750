import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';

import { getActualCostsForAnalysisRow } from '../../../../store/reducers/actualCost';
import { getAnalysisRow } from '../../../../store/reducers/analysis/row';
import { getArrivalRows } from '../../../../store/reducers/arrivalRow';
import { getInvoicesForAnalysisRow } from '../../../../store/reducers/invoiceHeader';
import { getProjectOrders } from '../../../../store/reducers/order/order';
import { getOrderRowsForAnalysisRow } from '../../../../store/reducers/orderRow';

import {
  fetchArrivalRowsForAnalysisRow,
  fetchInvoiceHeadersForAnalysisRow,
  fetchActualCostsForAnalysisRow,
  fetchOrdersForProject,
  fetchOrderRowsForAnalysisRow,
  requestAnalysisRows,
} from '../../../../store/actions';

import useRemoteData from '../../../../hooks/useRemoteData';
import useRouteParams from '../../../../hooks/useRouteParams';

import Modal from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import { priceFormat } from '../../../../utils/big';

import {
  Container,
  StyledContent,
  StyledHeader,
  SumRow,
} from '../ModalComponents';
import { InspectionTable } from './Table';

type ReceivedAnalysisModalProps = {
  analysisRowId: string;
  onClose: () => void;
};

export const ReceivedAnalysisModal = ({
  analysisRowId,
  onClose,
}: ReceivedAnalysisModalProps) => {
  const dispatch = useDispatch();

  const { projectId } = useRouteParams();

  const analysisRow = useRemoteData(
    getAnalysisRow({ projectId, analysisRowId }),
    requestAnalysisRows({ projectId })
  );

  // fetch also orderRow data for arrival row purposes
  const orderRows =
    useRemoteData(
      getOrderRowsForAnalysisRow(analysisRowId),
      fetchOrderRowsForAnalysisRow(analysisRowId)
    ) ?? [];

  const orders =
    useRemoteData(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const invoices =
    useRemoteData(
      getInvoicesForAnalysisRow({ projectId, analysisRowId }),
      fetchInvoiceHeadersForAnalysisRow(analysisRowId)
    ) ?? [];

  const actualCosts =
    useRemoteData(
      getActualCostsForAnalysisRow({ projectId, analysisRowId }),
      fetchActualCostsForAnalysisRow(analysisRowId)
    ) ?? [];

  React.useEffect(() => {
    dispatch(fetchArrivalRowsForAnalysisRow(analysisRowId));
  }, [analysisRowId, dispatch]);

  const arrivalRows = useSelector(getArrivalRows);

  const orderRowIds = orderRows.map((row) => row.id);

  const invoiceHeaderOrderIds = invoices.map((invoice) => invoice.orderId);

  const actualCostOrderIds = actualCosts.map((cost) => cost.orderId);

  const filteredArrivalRows = arrivalRows.filter((row) =>
    orderRowIds.includes(row.orderRowId ?? '')
  );

  const distinctOrderIds = [
    ...new Set([...invoiceHeaderOrderIds, ...actualCostOrderIds]),
  ];

  const filteredOrders = orders.filter((order) =>
    distinctOrderIds.includes(order.id)
  );

  const sortedOrders = filteredOrders.sort((a, b) =>
    a.visibleCode.localeCompare(b.visibleCode)
  );

  const sum = priceFormat(
    filteredArrivalRows.reduce(
      (previous, current) =>
        previous.add(current.unitPrice.mul(current.quantity)),
      new Big(0)
    )
  );

  return (
    <Modal onClose={onClose}>
      <Container>
        <StyledHeader>
          <Txt
            id="analysis.received.modal.title"
            values={{
              analysisRowCode: analysisRow?.code ?? '',
              analysisRowName: analysisRow?.value,
            }}
          />
        </StyledHeader>
        <StyledContent>
          <InspectionTable
            invoices={invoices}
            actualCosts={actualCosts}
            orders={sortedOrders}
            arrivalRows={filteredArrivalRows}
            orderRows={orderRows}
          />
          <SumRow>
            <Txt
              id="analysis.modal.sum"
              values={{ sum: sum.toString() }}
              component="b"
            />
          </SumRow>
        </StyledContent>
      </Container>
    </Modal>
  );
};
