import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { getTargetViewFilterSearchWord } from '../../../../store/reducers/ui';

import { TargetRow, TargetRowHierarchyEntry } from '../../../../store/actions';
import {
  uiStateTargetRowHierarchyEntryShowLevel,
  uiStateTargetViewSearch,
} from '../../../../store/actions/ui';

import { APIOrder, APITopic, APIWorkPackage } from '../../../../types/api';

import useTxt from '../../../../hooks/useTxt';

import { IconButton } from '../../../../components/Buttons';
import SearchInput from '../../../../components/Input/SearchInput';
import { StickyTableHeader } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import getWidths from '../../../../utils/headerWidth';
import { useDebouncedValue } from '../../../../utils/hooks';

import {
  IconDoubleRight,
  IconDoubleDown,
  IconDown,
  IconUp,
} from '../../../../assets/svg';

export const tableColumns = {
  referenceNo: { align: 'left', relativeWidth: 2 },
  description: { align: 'left', relativeWidth: 2 },
  searchInput: { align: 'center', relativeWidth: 3 },
  quantity: { align: 'right', relativeWidth: 1 },
  unitPrice: { align: 'right', relativeWidth: 2 },
  target: { align: 'right', relativeWidth: 2 },
  order: { align: 'left', relativeWidth: 3 },
  workSection: { align: 'left', relativeWidth: 3 },
} as const;

const getColumnPercent = getWidths(tableColumns);

type ThProps = {
  name: keyof typeof tableColumns;
  paddingDisabled?: boolean;
  children?: React.ReactNode;
};

const Th = styled.th<ThProps>`
  padding: ${({ paddingDisabled, theme: { margin } }) =>
    paddingDisabled ? '0rem' : `${margin[8]} ${margin[16]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  align-items: center;
  text-align: ${({ name }) => tableColumns[name].align};
`;

const textIdHead = 'target.table.header.';

const TxtTh = ({ name, children }: ThProps) => (
  <Th name={name}>
    {children}
    <Txt id={`${textIdHead}${name}` as const} />
  </Th>
);

const SearchDiv = styled.div`
  margin: ${(props) => props.theme.margin[4]};
`;

type SearchInputThProps = {
  targetRowHierarchyEntries: TargetRowHierarchyEntry[];
  topics: APITopic[];
  orders: APIOrder[];
  workPackages: APIWorkPackage[];
  targetRows: TargetRow[];
} & ThProps;

type InputState = { value: string };

const SearchInputTh = ({
  name,
  targetRowHierarchyEntries,
  topics,
  orders,
  workPackages,
  targetRows,
}: SearchInputThProps) => {
  const dispatch = useDispatch();

  const searchWord = useSelector(getTargetViewFilterSearchWord());

  const [filterValue, setFilterValue] = React.useState<InputState>({
    value: '',
  });

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue({ value: e.target.value });
  };

  const debouncedValue = useDebouncedValue(filterValue.value, 1000);

  useEffect(() => {
    if (debouncedValue === searchWord) {
      return;
    }

    dispatch(
      uiStateTargetViewSearch({
        searchString: debouncedValue,
        topics,
        orders,
        workPackages,
        targetRows,
        targetRowHierarchyEntries,
      })
    );
  }, [
    debouncedValue,
    searchWord,
    dispatch,
    topics,
    orders,
    workPackages,
    targetRows,
    targetRowHierarchyEntries,
  ]);

  return (
    <Th name={name} paddingDisabled>
      <SearchDiv>
        <SearchInput
          onChange={onSearchInputChange}
          value={filterValue.value}
          handleClearButtonClick={() =>
            setFilterValue({
              value: '',
            })
          }
          noMargin
        />
      </SearchDiv>
    </Th>
  );
};

type TableHeaderProps = {
  targetRowHierarchyEntries: TargetRowHierarchyEntry[];
  topics: APITopic[];
  orders: APIOrder[];
  workPackages: APIWorkPackage[];
  targetRows: TargetRow[];
  openDepth: number;
  maxDepth: number;
};

const TableHeader = ({
  targetRowHierarchyEntries,
  topics,
  orders,
  workPackages,
  targetRows,
  openDepth,
  maxDepth,
}: TableHeaderProps) => {
  const dispatch = useDispatch();

  const allOpenStatus = openDepth === maxDepth;

  const toggleAllOpen = () => {
    if (allOpenStatus) {
      dispatch(
        uiStateTargetRowHierarchyEntryShowLevel({
          showLevel: 0,
          targetRowHierarchyEntries,
        })
      );
    } else {
      dispatch(
        uiStateTargetRowHierarchyEntryShowLevel({
          showLevel: maxDepth,
          targetRowHierarchyEntries,
        })
      );
    }
  };

  const toggleLevels = (increment: number) => {
    const nextDepth = openDepth + increment;
    dispatch(
      uiStateTargetRowHierarchyEntryShowLevel({
        showLevel: nextDepth,
        targetRowHierarchyEntries,
      })
    );
  };

  const allOpenText = useTxt('target.table.hierarchies.open');
  const allClosedText = useTxt('target.table.hierarchies.closed');
  const openLevelText = useTxt('target.table.hierarchies.openLevel');
  const closeLevelText = useTxt('target.table.hierarchies.closeLevel');

  return (
    <StickyTableHeader>
      <tr>
        <Th name="referenceNo">
          <StyledToggleLevelDiv>
            <StyledIconButton
              icon={allOpenStatus ? IconDoubleDown : IconDoubleRight}
              onClick={toggleAllOpen}
              aria-label={allOpenStatus ? allOpenText : allClosedText}
            />
            <StyledIconButton
              icon={IconDown}
              onClick={() => toggleLevels(1)}
              disabled={allOpenStatus && openDepth !== 0}
              aria-label={openLevelText}
            />
            <StyledIconButton
              icon={IconUp}
              onClick={() => toggleLevels(-1)}
              disabled={!allOpenStatus && openDepth === 0}
              aria-label={closeLevelText}
            />
            <Txt id={`${textIdHead}referenceNo` as const} />
          </StyledToggleLevelDiv>
        </Th>
        <TxtTh name="description" />
        <SearchInputTh
          name="searchInput"
          targetRowHierarchyEntries={targetRowHierarchyEntries}
          topics={topics}
          orders={orders}
          workPackages={workPackages}
          targetRows={targetRows}
        />
        <TxtTh name="quantity" />
        <TxtTh name="unitPrice" />
        <TxtTh name="target" />
        <TxtTh name="order" />
        <TxtTh name="workSection" />
      </tr>
    </StickyTableHeader>
  );
};

const StyledIconButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[4]};
`;

const StyledToggleLevelDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default TableHeader;
