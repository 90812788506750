import React from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';

type SpinnerProps = {
  size: string; // CSS size
  light?: boolean;
  className?: string;
};

const SpinnerStyle = styled.div<SpinnerProps>`
  position: relative;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  display: inline-block;

  @keyframes ring-anim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerDiv = styled.div<SpinnerProps>`
  position: absolute;

  border-color: ${(props) =>
      props.light ? props.theme.color.white : props.theme.color.pitch}
    transparent transparent transparent;
  border-radius: 50%;
  border-width: calc(${(props) => props.size} / 8);
  border-style: solid;

  box-sizing: border-box;
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  display: block;

  animation: ring-anim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`;

const FirstDiv = styled(SpinnerDiv)`
  animation-delay: -0.45s;
`;

const SecondDiv = styled(SpinnerDiv)`
  animation-delay: -0.3s;
`;

const ThirdDiv = styled(SpinnerDiv)`
  animation-delay: -0.15s;
`;

export const Spinner = ({ size, light, className }: SpinnerProps) => (
  <SpinnerStyle size={size} light={light} className={className}>
    <FirstDiv size={size} light={light} />
    <SecondDiv size={size} light={light} />
    <ThirdDiv size={size} light={light} />
    <SpinnerDiv size={size} light={light} />
  </SpinnerStyle>
);

const GlobalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  z-index: 150;
`;

// eslint-disable-next-line import/prefer-default-export
export const GlobalLoadingSpinner = () =>
  ReactDOM.createPortal(
    <GlobalContainer>
      <Spinner size="4rem" />
    </GlobalContainer>,
    document.body
  );
