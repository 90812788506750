/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

import { TextId } from '../../localization';
import Txt from '../Txt';

type InvoiceLineMovedNotificationProps = {
  textId: TextId;
  onClick: () => void;
  topicName?: string;
};

const InvoiceLineMovedNotification: React.FC<InvoiceLineMovedNotificationProps> = ({
  textId,
  onClick,
  topicName,
}) => {
  return (
    <>
      <Txt id={textId} /> &quot;
      <Link
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        to=""
      >
        {topicName ?? <Txt id="order.invoiceLines.group.unassigned" />}
      </Link>
      &quot; <Txt id={`${textId}.after` as TextId} />
    </>
  );
};

export default InvoiceLineMovedNotification;
