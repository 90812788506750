import React, { useReducer } from 'react';
import { connect, useDispatch } from 'react-redux';

import { orderBy } from 'lodash';
import styled from 'styled-components';

import { AppState } from '../../../../../../store/reducers';
import { ActualCost } from '../../../../../../store/reducers/actualCost';
import { InvoiceHeader } from '../../../../../../store/reducers/invoiceHeader';
import { ProcurementAreaState } from '../../../../../../store/reducers/procurementArea';

import {
  moveInvoiceHeader,
  requestMoveActualCost,
} from '../../../../../../store/actions';

import { APIOrder } from '../../../../../../types/api';

import useTxt from '../../../../../../hooks/useTxt';

import {
  SecondaryButton,
  PrimaryButton,
} from '../../../../../../components/Buttons';
import ErrorModal from '../../../../../../components/ErrorModal/ErrorModal';
import Modal, {
  Container,
  FilterTextInput,
  Header,
  Content,
  Footer,
} from '../../../../../../components/Modal/Modal';
import { OrderList } from './components';

import * as big from '../../../../../../utils/big';
import { isDefined, isInvoiceHeader } from '../../../../../../utils/general';
import { searchFilter } from '../../../../../../utils/search';

import { OrderListItem } from './OrderListItem';

type MoveCostModalStateProps = {
  procurementAreas: ProcurementAreaState;
};

type MoveCostModalProps = MoveCostModalStateProps & {
  orders: Partial<Record<string, APIOrder>>;
  cost: InvoiceHeader | ActualCost;
  onClose: () => void;
};

export type MoveCostModalReducerProps = {
  filterText?: string;
  selectedOrder?: APIOrder | undefined;
  sortOrder?: 'desc' | 'asc';
  toggleSort?: () => void;
};

const MoveCostModal = ({
  onClose,
  cost,
  orders,
  procurementAreas,
}: MoveCostModalProps) => {
  const dispatch = useDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const isInvoice = isInvoiceHeader(cost);

  const modalReducer = (
    modalState: MoveCostModalReducerProps,
    action: { type: string } & Partial<MoveCostModalReducerProps>
  ): MoveCostModalReducerProps => {
    switch (action.type) {
      case 'setFilterText':
        return { ...modalState, filterText: action.filterText };
      case 'setSelectedOrder':
        return { ...modalState, selectedOrder: action.selectedOrder };
      case 'setSortOrder':
        return { ...modalState, sortOrder: action.sortOrder };
      default:
        throw new Error();
    }
  };

  const [modalState, dispatchState] = useReducer(modalReducer, {});

  const { filterText = '', selectedOrder, sortOrder = 'asc' } = modalState;

  // Solve orders belonging to the `same project.

  const errorModalText = useTxt('order.receiveMode.invoice.add');
  const titleText = useTxt('order.receiveMode.moveCostModal.title');
  const amountText = useTxt('order.receiveMode.moveCostModal.amount');

  const filterPlaceHolderText = useTxt(
    'order.receiveMode.moveCostModal.filterPlaceholder'
  );
  const cancelText = useTxt('common.cancel');
  const moveToText = useTxt('order.receiveMode.moveCostModal.moveToOrder');

  const invoiceOrder = cost.orderId && orders[cost.orderId];

  const procurementArea =
    invoiceOrder && procurementAreas.data[invoiceOrder.procurementAreaId];

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  if (!invoiceOrder || !procurementArea) {
    return <ErrorModal onClose={onClose}>{errorModalText}</ErrorModal>;
  }

  const procurementAreaIds = Object.values(procurementAreas.data)
    .filter((area) => area.projectId === procurementArea.projectId)
    .map((area) => area.id);

  const projectOrders = orderBy(
    Object.values(orders)
      .filter(isDefined)
      .filter(
        (order) =>
          procurementAreaIds.includes(order.procurementAreaId) &&
          cost.orderId !== order.id
      ),
    ['visibleCode'],
    [sortOrder]
  );

  // Filter orders with the filter text.
  const normalizedFilterText = filterText.toLowerCase();

  const filteredOrders = searchFilter(projectOrders, normalizedFilterText, [
    'visibleCode',
    'name',
    'contractor',
    'procurementReferenceNumber',
  ]);

  // Select callback.
  const selectOrder = (order: APIOrder) => {
    if (!selectedOrder || selectedOrder.id !== order.id) {
      dispatchState({ type: 'setSelectedOrder', selectedOrder: order });
    } else {
      dispatchState({ type: 'setSelectedOrder', selectedOrder: undefined });
    }
  };

  // Move button callback.
  const onMoveInvoice = () => {
    if (selectedOrder) {
      if (isInvoice) {
        dispatch(moveInvoiceHeader(cost.id, selectedOrder.id));
      } else {
        dispatch(
          requestMoveActualCost({
            actualCostId: cost.id,
            orderId: selectedOrder.id,
          })
        );
      }
    }

    onClose();
  };

  // toggles order list sort state

  const toggleSort = () => {
    dispatchState({
      type: 'setSortOrder',
      sortOrder: sortOrder === 'desc' ? 'asc' : 'desc',
    });
  };

  const costIdentifier = isInvoiceHeader(cost)
    ? cost.vendorInvoiceNo
    : cost.documentNumber;

  return (
    <Modal onClose={onClose}>
      <Container>
        <Header>
          {titleText} | {costIdentifier} {amountText}{' '}
          {big.priceFormat(cost.amount)}
        </Header>
        <Content>
          <FilterTextInput
            placeholder={filterPlaceHolderText}
            name="name"
            value={filterText}
            onChange={(e: React.ChangeEvent) =>
              dispatchState({
                type: 'setFilterText',
                filterText: (e.target as HTMLInputElement).value,
              })
            }
            ref={inputRef}
          />
          <OrderList toggleSort={toggleSort} sortOrder={sortOrder}>
            {filteredOrders.map((order) => (
              <OrderListItem
                key={`move-invoice-item-${order.id}`}
                isSelected={selectedOrder && order.id === selectedOrder.id}
                onSelect={selectOrder}
                order={order}
              />
            ))}
          </OrderList>
        </Content>
        <Footer>
          <CancelButton onClick={onClose}>{cancelText}</CancelButton>
          <MoveButton disabled={!selectedOrder} onClick={onMoveInvoice}>
            {moveToText}
          </MoveButton>
        </Footer>
      </Container>
    </Modal>
  );
};

const CancelButton = styled(SecondaryButton)`
  margin-right: 4px;
`;

const MoveButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

const mapStateToProps = (state: AppState): MoveCostModalStateProps => ({
  procurementAreas: state.procurementAreas,
});

export default connect(mapStateToProps)(MoveCostModal);
