import React from 'react';

import styled from 'styled-components';

import Txt from '../../../components/Txt';

import { IconDot } from '../../../assets/svg';

import globalTheme from '../../../styles/theme';

export type ChangeLogRowType = 'orderChangeLogEntry' | 'targetChangeLogEntry';

type RowTypeIndicatorProps = {
  changeLowRowType: ChangeLogRowType;
};

export const RowTypeIndicator = ({
  changeLowRowType,
}: RowTypeIndicatorProps) => {
  switch (changeLowRowType) {
    case 'orderChangeLogEntry': {
      return (
        <StatusDiv>
          <IconDot
            width={globalTheme.margin[10]}
            height={globalTheme.margin[10]}
            color={globalTheme.color.lightBlue}
            data-testid="orderChangeLogEntryIcon"
          />
          <TextDiv>
            <Txt id="common.orderRow" />
          </TextDiv>
        </StatusDiv>
      );
    }
    default:
      return (
        <StatusDiv>
          <IconDot
            width={globalTheme.margin[10]}
            height={globalTheme.margin[10]}
            color={globalTheme.color.purple}
            data-testid="targetChangeLogEntryIcon"
          />
          <TextDiv>
            <Txt id="common.targetRow" />
          </TextDiv>
        </StatusDiv>
      );
  }
};

const StatusDiv = styled.div`
  height: ${({ theme }) => theme.margin[32]};
  width: 100%;

  display: flex;
  justify-content: left;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSize.small};
`;

const TextDiv = styled.div`
  margin-left: ${({ theme }) => theme.margin[4]};
`;
