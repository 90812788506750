import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProcurementAreasByProjectId } from '../../../store/reducers/procurementArea';

import { updateProjectColumns } from '../../../store/actions/ui';

import { Table } from '../../../components/Table';

import ProcurementArea from './ProcurementArea';
import ProcurementAreasTableHeader from './ProcurementAreasTableHeader';

type Props = {
  projectId: string;
};

const ProcurementAreasTable = ({ projectId }: Props) => {
  const dispatch = useDispatch();

  const onUpdateColumn = (column: any) => {
    dispatch(updateProjectColumns(column));
  };

  const procurementAreas = useSelector(
    getProcurementAreasByProjectId(projectId)
  );

  const [orderStatusFilterValues, setOrderStatusFilterValue] = React.useState<
    string[]
  >([]);

  const toggleOrderStatusFilter = (value: string) => {
    if (orderStatusFilterValues.includes(value)) {
      return setOrderStatusFilterValue(
        orderStatusFilterValues.filter((row) => row !== value)
      );
    }

    return setOrderStatusFilterValue(orderStatusFilterValues.concat(value));
  };

  return (
    <Table>
      <ProcurementAreasTableHeader
        projectId={projectId}
        onChangeColumn={onUpdateColumn}
        toggleOrderStatusFilter={toggleOrderStatusFilter}
        orderStatusFilterValues={orderStatusFilterValues}
      />
      {procurementAreas.map((procurementArea) => (
        <ProcurementArea
          key={`procurement-area-row-${procurementArea.id}`}
          procurementAreaId={procurementArea.id}
          orderStatusFilterValues={orderStatusFilterValues}
        />
      ))}
    </Table>
  );
};
export default ProcurementAreasTable;
