import { generatePath } from 'react-router-dom';
import * as reactRouterDom from 'react-router-dom';

export enum routes {
  HOME = '/',
  ANALYSIS = '/project/:projectId/analysis/:viewMode(edit)?',
  ORDER = '/project/:projectId/order/:orderId/:viewMode(edit|receive|comments)?/:subViewMode(invoices)?/:showTargetRows(showTarget)?/:showInfo(info)?',
  ORDER_WITH_ORDER_ROW_FOCUSED = '/project/:projectId/order/:orderId/:viewMode(edit|receive|comments)?/:subViewMode(invoices)?/:showTargetRows(showTarget)?/:showInfo(info)?#orderRowId-:orderRowId',
  ORDER_WITH_INVOICE_FOCUSED = '/project/:projectId/order/:orderId/:viewMode(receive)/:subViewMode(invoices)/:showTargetRows(showTarget)?/:showInfo(info)?#invoiceHeaderId-:invoiceHeaderId',
  ORDER_WITH_OPEN_TOPIC = '/project/:projectId/order/:orderId/:viewMode(edit|receive|comments)?/:subViewMode(invoices)?/:showTargetRows(showTarget)?/:showInfo(info)?#topic-:topicId',
  PROJECT = '/project/:projectId',
  REVENUE = '/project/:projectId/revenue/:viewMode(edit)?',
  REPORTING = '/project/:projectId/reporting',
  TARGET = '/project/:projectId/target',
  WORKSECTIONS = '/project/:projectId/worksection',
  WORKSECTION_EXPANDED = '/project/:projectId/worksection/:workPackageId/details/',
  WORKSECTION_EXPANDED_WITH_TOPIC_OPEN = '/project/:projectId/worksection/:workPackageId/details/#topic-:topicId',
}

interface BaseParams {
  projectId: string;
}
interface HomeParams {
  route: routes.HOME;
}

interface AnalysisParams extends BaseParams {
  route: routes.ANALYSIS;
  viewMode?: 'edit';
}

interface OrderParams extends BaseParams {
  route: routes.ORDER;
  orderId: string;
  viewMode?: 'edit' | 'receive' | 'comments';
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
}

interface OrderWithOrderRowFocusedParams extends BaseParams {
  route: routes.ORDER_WITH_ORDER_ROW_FOCUSED;
  orderId: string;
  viewMode?: 'edit' | 'receive' | 'comments';
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
  orderRowId: string;
}

interface OrderWithInvoiceFocusedParams extends BaseParams {
  route: routes.ORDER_WITH_INVOICE_FOCUSED;
  orderId: string;
  viewMode: 'receive';
  subViewMode: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
  invoiceHeaderId: string;
}

interface ProjectParams extends BaseParams {
  route: routes.PROJECT;
}

interface OrderWithTopicParams extends BaseParams {
  route: routes.ORDER_WITH_OPEN_TOPIC;
  orderId: string;
  viewMode?: 'edit' | 'receive' | 'comments';
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
  topicId: string;
}

interface RevenueParams extends BaseParams {
  route: routes.REVENUE;
  viewMode?: 'edit';
}

interface ReportingParams extends BaseParams {
  route: routes.REPORTING;
}

interface TargetParams extends BaseParams {
  route: routes.TARGET;
}

interface WorksectionsParams extends BaseParams {
  route: routes.WORKSECTIONS;
}

interface WorksectionsExpandedParams extends BaseParams {
  route: routes.WORKSECTION_EXPANDED;
  workPackageId: string;
}

interface WorksectionExpandedWithOrderParams {
  route: routes.WORKSECTION_EXPANDED_WITH_TOPIC_OPEN;
  projectId: string;
  workPackageId: string;
  topicId: string;
}

type UrlParams =
  | HomeParams
  | AnalysisParams
  | OrderParams
  | OrderWithOrderRowFocusedParams
  | OrderWithTopicParams
  | ProjectParams
  | RevenueParams
  | ReportingParams
  | TargetParams
  | WorksectionsParams
  | WorksectionsExpandedParams
  | WorksectionExpandedWithOrderParams
  | OrderWithInvoiceFocusedParams;

export const generateUrl = (urlParams: UrlParams): string => {
  const urlPattern = urlParams.route;
  const path = generatePath(urlPattern, urlParams);

  return path;
};

type GetRouteParams<Route extends UrlParams['route']> = Omit<
  Extract<UrlParams, { route: Route }>,
  'route'
>;

export function useParams<Route extends UrlParams['route']>(
  _: Route
): GetRouteParams<Route> {
  return reactRouterDom.useParams<any>();
}
