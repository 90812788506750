import styled, { DefaultTheme } from 'styled-components';

import { CircleIconButtonContainer } from '../../../components/Navigation/Components';

export type ThemeColor = keyof DefaultTheme['color'];

type UserInitialsBadgeProps = {
  backgroundColor?: ThemeColor;
};

export const UserInitialsBadge = styled(
  CircleIconButtonContainer
)<UserInitialsBadgeProps>`
  margin: 0;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.color.amethyst};
  color: ${({ theme }) => theme.color.white};
`;
