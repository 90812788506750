import React from 'react';

import styled from 'styled-components';

import { SelectOption } from '@customtypes/ui';

import { getStatuses } from '../../../store/reducers/order/options';

import { getDropDowns } from '../../../store/actions';

import useRemoteData from '../../../hooks/useRemoteData';

import { Spinner } from '../../../components/Loading';
import FilterSettings from '../../../components/MultiSelect/MultiSelect';

import { TextId } from '../../../localization';

type FilterDropDownProps = {
  setFilterValue: (value: string) => void;
  filterValue: string[];
};

export const FilterDropdown = ({
  setFilterValue,
  filterValue,
}: FilterDropDownProps) => {
  const statusOptions = useRemoteData(getStatuses, getDropDowns);

  const onChange = (change: SelectOption) => {
    const { id } = change;

    setFilterValue(id);
  };

  const textStart = 'common.orderStatus.';

  return (
    <FilterDiv
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <FlexColumn>
        {statusOptions ? (
          <FilterSettings
            toggleShow={() => {}}
            listItems={{
              listItemsArray:
                statusOptions.map((d) => ({
                  id: d.id,
                  name: `${textStart}${d.id}` as TextId,
                  selected: filterValue.includes(d.id),
                })) ?? [],
            }}
            onChange={onChange}
            container={MultiSelectContainer}
            noListBorder
          />
        ) : (
          <Spinner size="1rem" />
        )}
      </FlexColumn>
    </FilterDiv>
  );
};

const FilterDiv = styled.div`
  position: absolute;
  top: ${(props) => props.theme.margin[24]};
  right: 20%;

  border: 1px solid lightgrey;
  border-radius: 5px 0 5px 2px;
  box-shadow: 5px 7px 11px -12px ${(props) => props.theme.color.black};

  background: ${(props) => props.theme.color.graphiteB99};

  z-index: 10;
`;

const FlexColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MultiSelectContainer = styled.div`
  min-width: 10rem;
`;
