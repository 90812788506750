import React from 'react';

import styled, { DefaultTheme } from 'styled-components';

import useTxt from '../../hooks/useTxt';

type Color = keyof DefaultTheme['color'];

export interface FillerSectionProps {
  active?: boolean;
  colorName?: Color;
  children?: React.ReactChild;
  percentage?: string | number;
}

interface IProgressPillProps extends FillerSectionProps {
  onClick?: () => void;
  isDisabled?: boolean;
}

const StatusProgressPill = (props: IProgressPillProps) => {
  const { percentage, onClick, isDisabled, active } = props;
  const progressPillText = useTxt('orderRow.inputs.ProgressPill');

  return (
    <ProgressPill
      aria-label={progressPillText}
      onClick={onClick}
      colorName={active ? 'pitch' : 'buttonPrimaryDisabled'}
      disabled={isDisabled}
    >
      <FillerDiv colorName="black" percentage={percentage} active={active}>
        <Span>{!active ? percentage : 100}%</Span>
      </FillerDiv>
    </ProgressPill>
  );
};
export default StatusProgressPill;

const ProgressPill = styled.button<IProgressPillProps>`
  position: relative;

  border-radius: ${({ theme }) => theme.margin[8]};
  border: 1px solid ${({ theme }) => theme.color.graphiteB86};

  padding: 0;

  height: ${({ theme }) => theme.margin[16]};
  width: 100%;
  max-width: ${(props) => props.theme.margin[48]};

  background: ${({ colorName, theme: { color } }) =>
    color[colorName || 'aqua']};

  font-size: 0.5rem;
  text-align: center;
  line-height: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.white};

  cursor: pointer;
  overflow: hidden;

  :disabled {
    background: ${({ theme }) => theme.color.buttonPrimaryDisabled};
    cursor: initial;
  }
`;

const FillerDiv = styled.div<FillerSectionProps>`
  border-radius: ${({ theme, percentage, active }) => {
    return percentage && percentage < 80 && !active
      ? `${theme.margin[8]} 0 0 ${theme.margin[8]} `
      : theme.margin[8];
  }};

  height: 100%;
  width: ${({ percentage, active }) => (!active ? percentage : 100)}%;

  display: flex;
  align-items: center;

  background: ${({ colorName, theme: { color } }) =>
    color[colorName || 'cyan']};
`;

const Span = styled.span`
  position: absolute;
  width: 100%;
`;
