import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/schedule/workPackageGroupTimeline';

const actionCreators = {
  ...makeAction('getWorkPackageGroupTimelinesStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getWorkPackageGroupTimelinesSuccess')<{
    projectId: string;
    workPackageGroupTimelineEntries: WorkPackageGroupTimelineEntry[];
  }>(),
  ...makeAction('getWorkPackageGroupTimelinesFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
};

export type WorkPackageGroupTimelineAction = ExtractActionTypes<
  typeof actionCreators
>;

const apiWorkPackageGroupTimelineEntryType = t.exact(
  t.type({
    workPackageGroupId: t.string,
    projectId: t.string,
    date: dateString,
    percentageOfCompletion: bigString,
    snapshotTypeId: t.string,
    snapshotId: t.union([t.string, t.null]),
    currentPeriod: t.boolean,
    latestSnapshot: t.boolean,
    pastPeriod: t.boolean,
  })
);

export type WorkPackageGroupTimelineEntry = t.TypeOf<
  typeof apiWorkPackageGroupTimelineEntryType
>;

const {
  getWorkPackageGroupTimelinesStarted,
  getWorkPackageGroupTimelinesSuccess,
  getWorkPackageGroupTimelinesFailure,
} = actionCreators;

export async function toWorkPackageGroupTimelineEntries(
  u: unknown
): Promise<WorkPackageGroupTimelineEntry[]> {
  const targetRows = await tPromise.decode(
    t.array(apiWorkPackageGroupTimelineEntryType),
    u
  );

  return targetRows;
}

const requestWorkPackageGroupTimelineForProject = async ({
  projectId,
}: {
  projectId: string;
}): Promise<WorkPackageGroupTimelineEntry[]> => {
  const response = await GET<WorkPackageGroupTimelineEntry[]>(
    `v1/projects/${projectId}/work-package-group-timelines`
  );

  return toWorkPackageGroupTimelineEntries(response);
};

export const getWorkPackageGroupTimelineForProject = ({
  projectId,
}: {
  projectId: string;
}) =>
  createAsyncThunk(requestWorkPackageGroupTimelineForProject, {
    args: [{ projectId }],
    initialAction: getWorkPackageGroupTimelinesStarted({ projectId }),
    isPending: isLoading(projectId),
    failureActionCreator: (error) =>
      getWorkPackageGroupTimelinesFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (workPackageGroupTimelineEntries) =>
      getWorkPackageGroupTimelinesSuccess({
        projectId,
        workPackageGroupTimelineEntries,
      }),
  });
