import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { getSortOrderFor } from '../../../../store/reducers/revenue/sortRevenue';

import { revenuesSortOrderToggled } from '../../../../store/actions/revenue';

import { BaseButton } from '../../../../components/Buttons';
import { StickyTableHeader } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import getWidths from '../../../../utils/headerWidth';

import { IconUp, IconDown } from '../../../../assets/svg';

import { routes, useParams } from '../../../../routes';

export const tableColumns = {
  select: { align: 'center', relativeWidth: 1 },
  batchCode: { align: 'left', relativeWidth: 2 },
  description: { align: 'left', relativeWidth: 5 },
  unitPrice: { align: 'right', relativeWidth: 2 },
  quantity: { align: 'center', relativeWidth: 1 },
  unit: { align: 'center', relativeWidth: 1 },
  netPrice: { align: 'right', relativeWidth: 2 },
  vat: { align: 'center', relativeWidth: 1 },
  grossPrice: { align: 'right', relativeWidth: 2 },
  billingDate: { align: 'center', relativeWidth: 3 },
  actualizedBilling: {
    align: 'right',
    relativeWidth: 3,
  },
  analysis: { align: 'right', relativeWidth: 3 },
  status: { align: 'center', relativeWidth: 2 },
  attachment: { align: 'center', relativeWidth: 1 },
  delete: { align: 'center', relativeWidth: 1 },
} as const;

const getColumnPercent = getWidths(tableColumns);

type ThProps = {
  name: keyof typeof tableColumns;
  paddingDisabled?: boolean;
};

const Th = styled.th<ThProps>`
  padding: ${({ paddingDisabled, theme: { margin } }) =>
    paddingDisabled ? '0rem' : `${margin[8]} ${margin[16]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  align-items: center;
  text-align: ${({ name }) => tableColumns[name].align};
`;

const textIdHead = 'revenue.table.header.';

const TxtTh = ({ name }: ThProps) => (
  <Th name={name}>
    <Txt id={`${textIdHead}${name}` as const} />
  </Th>
);

type SortButtonProps = {
  align: 'center' | 'left' | 'right';
};

const aligns = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

const SortButton = styled(BaseButton)<SortButtonProps>`
  padding: ${({ theme: { margin } }) => `${margin[8]} ${margin[16]}`};

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ align }) => aligns[align]};
`;

type SortableThProps = {
  name: 'billingDate' | 'batchCode';
} & SortButtonProps;

const SortableTh = ({ name, align }: SortableThProps) => {
  const dispatch = useDispatch();

  const sortOrder = useSelector(getSortOrderFor(name));

  const onClick = () =>
    dispatch(revenuesSortOrderToggled({ sortableKey: name }));

  return (
    <Th name={name} paddingDisabled>
      <SortButton onClick={onClick} data-testid={`sort-${name}`} align={align}>
        <Txt id={`${textIdHead}${name}` as const} component="b" />
        {sortOrder === 'Ascending' ? <StyledIconDown /> : <StyledIconUp />}
      </SortButton>
    </Th>
  );
};

const TableHeader = () => {
  const isInEditMode = useParams(routes.REVENUE)?.viewMode === 'edit';

  return (
    <StyledStickyTHead editMode={isInEditMode}>
      <tr>
        {isInEditMode ? <TxtTh name="select" /> : null}
        <SortableTh name="batchCode" align="left" />
        <TxtTh name="description" />
        <TxtTh name="unitPrice" />
        <TxtTh name="quantity" />
        <TxtTh name="unit" />
        <TxtTh name="netPrice" />
        <TxtTh name="vat" />
        <TxtTh name="grossPrice" />
        <SortableTh name="billingDate" align="center" />
        <TxtTh name="actualizedBilling" />
        <TxtTh name="analysis" />
        <TxtTh name="status" />
        <TxtTh name="attachment" />
        <TxtTh name="delete" />
      </tr>
    </StyledStickyTHead>
  );
};
export default TableHeader;

const StyledIconDown = styled(IconDown)`
  margin-left: ${(props) => props.theme.margin[8]};
`;

const StyledIconUp = styled(IconUp)`
  margin-left: ${(props) => props.theme.margin[8]};
`;

type StyledStickyTHeadProps = {
  editMode: boolean;
};

const StyledStickyTHead = styled(StickyTableHeader)<StyledStickyTHeadProps>`
  top: ${(props) => (props.editMode ? '105px' : '69px')};
`;
