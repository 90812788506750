import React from 'react';

import styled from 'styled-components';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useTxt from '../../../hooks/useTxt';

import Txt from '../../../components/Txt';

import getWidths from '../../../utils/headerWidth';

import { IconFunnel, IconFunnelBlack } from '../../../assets';

import { FilterDropdown } from './FilterDropDown';

export const tableColumns = {
  user: { align: 'left', relativeWidth: 4 },
  rowType: { align: 'left', relativeWidth: 2 },
  blank: { align: 'left', relativeWidth: 0.7 },
  description: { align: 'left', relativeWidth: 5 },
  action: { align: 'center', relativeWidth: 5 },
  sumChange: { align: 'right', relativeWidth: 2 },
} as const;

const getColumnPercent = getWidths(tableColumns);

const textIdHead = 'comments.modal.changes.';

type ThProps = {
  name: keyof typeof tableColumns;
  paddingDisabled?: boolean;
  invisible?: boolean;
};

const Th = styled.th<ThProps>`
  padding: ${({ paddingDisabled, theme: { margin } }) =>
    paddingDisabled ? '0rem' : `${margin[8]} ${margin[8]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  ${({ invisible }) => (invisible ? 'height: 0;' : null)}
  align-items: center;
  text-align: ${({ name }) => tableColumns[name].align};
`;

const TxtTh = ({ name, paddingDisabled }: ThProps) => (
  <Th name={name} paddingDisabled={paddingDisabled}>
    <Txt id={`${textIdHead}${name}` as const} component="b" />
  </Th>
);

type FilterThProps = {
  name: keyof typeof tableColumns;
  filterOpen: boolean;
  filterValue: { order: string[]; target: string[] };
  toggleFilter: () => void;
  setFilterValue: ({
    key,
    value,
  }: {
    key: 'order' | 'target';
    value: string | null;
  }) => void;
};

const FilterTh = ({
  name,
  filterOpen,
  filterValue,
  toggleFilter,
  setFilterValue,
}: FilterThProps) => {
  const alt = useTxt('comments.modal.changes.filter');

  const src =
    filterValue.order.length + filterValue.target.length > 0
      ? IconFunnelBlack
      : IconFunnel;

  const ref = useOnClickOutside(() => {
    if (filterOpen) {
      toggleFilter();
    }
  });

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    toggleFilter();
  };

  return (
    <Th name={name} paddingDisabled>
      <ThFilterDiv onClick={(e) => onClick(e)} ref={ref}>
        <Txt id={`${textIdHead}${name}` as const} component="b" />
        <StyledImg src={src} alt={alt} />
        {filterOpen ? (
          <FilterDropdown
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            toggleFilter={toggleFilter}
          />
        ) : null}
      </ThFilterDiv>
    </Th>
  );
};

type HeaderProps = {
  filterOpen: boolean;
  filterValue: { order: string[]; target: string[] };
  toggleFilter: () => void;
  setFilterValue: ({
    key,
    value,
  }: {
    key: 'order' | 'target';
    value: string | null;
  }) => void;
};

export const TableHeader = ({
  filterOpen,
  filterValue,
  toggleFilter,
  setFilterValue,
}: HeaderProps) => (
  <thead>
    <tr>
      <TxtTh name="user" />
      <TxtTh name="rowType" />
      <TxtTh name="description" />
      <FilterTh
        name="action"
        filterOpen={filterOpen}
        filterValue={filterValue}
        toggleFilter={toggleFilter}
        setFilterValue={setFilterValue}
      />
      <TxtTh name="blank" />
      <TxtTh name="sumChange" />
    </tr>
  </thead>
);

export const InvisibleTableHeader = () => (
  <thead>
    <tr>
      <Th name="user" invisible />
      <Th name="rowType" paddingDisabled invisible />
      <Th name="description" invisible />
      <Th name="action" invisible />
      <Th name="blank" invisible />
      <Th name="sumChange" invisible />
    </tr>
  </thead>
);

const ThFilterDiv = styled.div`
  position: relative;

  padding: ${({ theme: { margin } }) => `${margin[8]} ${margin[16]}`};

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const StyledImg = styled.img`
  margin-left: ${({ theme: { margin } }) => margin[8]};
  height: 1rem;
`;
