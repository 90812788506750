import React from 'react';

import { getBatchGroups } from '../../../../store/reducers/batchGroup';
import { getPaymentProgramRowGroups } from '../../../../store/reducers/paymentProgramRowGroup';
import { getRevenueRows } from '../../../../store/reducers/revenue/revenue';

import { requestBatchGroups } from '../../../../store/actions/batchGroups';
import { requestPaymentProgramRowGroups } from '../../../../store/actions/paymentProgramRowGroup';
import { requestRevenues } from '../../../../store/actions/revenue';

import useRemoteData from '../../../../hooks/useRemoteData';

import { Table } from '../../../../components/Table';

import PaymentProgramRowGroup from './PaymentProgramRowGroup';
import TableHeader from './TableHeader';

type RevenueTableProps = {
  projectId: string;
};

const RevenueTable = ({ projectId }: RevenueTableProps) => {
  const paymentProgramRowGroups =
    useRemoteData(
      getPaymentProgramRowGroups(projectId),
      requestPaymentProgramRowGroups(projectId)
    ) ?? [];

  const revenueRows =
    useRemoteData(getRevenueRows, requestRevenues(projectId)) ?? [];

  const batchGroups = useRemoteData(getBatchGroups, requestBatchGroups) ?? [];

  return (
    <Table>
      <TableHeader />
      <tbody>
        {paymentProgramRowGroups.map((group) => {
          const revenueRowsForGroup = revenueRows.filter(
            (row) => row.paymentProgramRowGroupId === group.id
          );

          return (
            <PaymentProgramRowGroup
              key={`payment-program-row-group-${group.id}`}
              group={group}
              rows={revenueRowsForGroup}
              batchGroups={batchGroups}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default RevenueTable;
