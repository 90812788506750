import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getHandledActualCostsByOrderId,
  getUnhandledActualCostsByOrderId,
} from '../../../../../store/reducers/actualCost';

import { ID } from '../../../../../types/general';

import useTxt from '../../../../../hooks/useTxt';

import { Table } from '../../../../../components/Table';

import { useDebouncedValue } from '../../../../../utils/hooks';
import { searchFilter } from '../../../../../utils/search';

import { IconActualCosts } from '../../../../../assets';

import ActualCost from './ActualCost';
import ArrivalRowTableHeader from './ArrivalRowTableHeader';
import { SectionSpacer, SectionTitleBar } from './Components';

type Props = {
  orderId: string;
  showHandledRows: boolean;
  selectedId?: ID;
  setSelectedId: (id: ID, onlyOuterBar?: boolean) => void;
  searchText?: {
    text: string;
    idFilter?: string;
  };
  setSearchText?: (searchText: { text: string; idFilter?: string }) => void;
};

const ActualCostsTable = ({
  orderId,
  showHandledRows,
  selectedId,
  setSelectedId,
  searchText,
  setSearchText,
}: Props) => {
  const history = useHistory();
  const [isAllRendered, setAllRendered] = React.useState(false);

  const { hash } = history.location;

  const hashActualCostId = hash.replace('#actualCostId-', '');
  React.useEffect(() => {
    setAllRendered(true);
  }, []);

  const debouncedValue = useDebouncedValue(searchText?.text, 1000);

  const unhandledActualCosts = useSelector(
    getUnhandledActualCostsByOrderId(orderId)
  );

  const handledActualCosts = useSelector(
    getHandledActualCostsByOrderId(orderId)
  );

  const unhandledAC = searchFilter(
    unhandledActualCosts,
    searchText?.idFilter ?? debouncedValue,
    searchText?.idFilter ? ['id'] : undefined
  );

  const handledAC = searchFilter(
    handledActualCosts,
    searchText?.idFilter ?? debouncedValue,
    searchText?.idFilter ? ['id'] : undefined
  );

  const memoizedInvoiceHeaderVendorId = useMemo(() => {
    return unhandledActualCosts
      .concat(handledActualCosts)
      .find((row) => row.id === hashActualCostId)?.entryNo;
  }, [hashActualCostId, unhandledActualCosts, handledActualCosts]);

  // when history changes(hashActualCostId), set the search text to the invoice vendor id

  useEffect(() => {
    if (memoizedInvoiceHeaderVendorId && setSearchText) {
      setSearchText({
        text: memoizedInvoiceHeaderVendorId,
        idFilter: hashActualCostId,
      });
    }
  }, [memoizedInvoiceHeaderVendorId, hashActualCostId, setSearchText]);

  const sectionTitleText = useTxt('order.receiveMode.actualCost.sectionTitle');

  const showSectionTitlebar =
    unhandledAC.length !== 0 || handledAC.length !== 0;

  const showArrivalRowTableHeader =
    unhandledAC.length !== 0 || (showHandledRows && handledAC.length !== 0);

  const selectedActualCost = unhandledActualCosts
    .concat(handledActualCosts)
    .find((actualCost) => selectedId === actualCost.id);

  return (
    <SectionSpacer>
      {showSectionTitlebar ? (
        <SectionTitleBar icon={IconActualCosts}>
          <div>{sectionTitleText}</div>
        </SectionTitleBar>
      ) : null}
      <Table>
        {showArrivalRowTableHeader ? (
          <ArrivalRowTableHeader listType="actualCost" />
        ) : null}
        {unhandledAC.map((cost) => (
          <ActualCost
            key={`actual-cost-${cost.id}`}
            actualCost={cost}
            isSelected={
              selectedActualCost?.id === cost.id || cost.id === hashActualCostId
            }
            onSelect={() => setSelectedId(cost.id)}
            isAllRendered={isAllRendered}
          />
        ))}
        {showHandledRows
          ? handledAC.map((cost) => (
              <ActualCost
                key={`actual-cost-${cost.id}`}
                actualCost={cost}
                isSelected={
                  selectedActualCost?.id === cost.id ||
                  cost.id === hashActualCostId
                }
                onSelect={() => setSelectedId(cost.id, true)}
                isAllRendered={isAllRendered}
                isHandled
              />
            ))
          : null}
      </Table>
    </SectionSpacer>
  );
};

export default ActualCostsTable;
