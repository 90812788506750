import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { ExtractActionTypes, makeAction } from '../../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
} from '../../../utils/api';
import { bigString, dateString } from '../../../utils/decoders';
import { flow } from '../../../utils/function';
import { isDefined } from '../../../utils/general';
import invertObject from '../../../utils/invertObject';
import { createAsyncThunk } from '../../../utils/thunk';

import {
  getTargetChangeLogEntriesFetchRequest,
  TargetChangeLogEntry,
} from '../../reducers/target/changeLogEntries';

export type TargetChangeLogEntryAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('getTargetChangeLogEntriesStarted')<{ orderId: string }>(),
  ...makeAction('getTargetChangeLogEntriesFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getTargetChangeLogEntriesSuccess')<{
    orderId: string;
    targetChangeLogEntries: TargetChangeLogEntry[];
  }>(),
};

export const {
  getTargetChangeLogEntriesStarted,
  getTargetChangeLogEntriesFailure,
  getTargetChangeLogEntriesSuccess,
} = actionCreators;

export const targetLogStatusToId = {
  changeOfCosts: '1',
  deletion: '2',
  orderChange: '3',
  topicChange: '4',
  newRow: '5',
} as const;

export type TargetLogEntryAction = keyof typeof targetLogStatusToId;

const idToStatus = invertObject(targetLogStatusToId);

const requiredProperties = t.exact(
  t.type({
    id: t.string,
    userId: t.string,
    userName: t.string,
    snapshotId: t.union([t.string, t.null]),
    timestamp: dateString,
    orderId: t.string,
    targetRowId: t.string,
    referenceNo: t.union([t.string, t.null]),
    targetRowDescription: t.string,
    targetRowChangeTypeId: t.keyof(idToStatus),
    differenceForOrderTotal: bigString,
  })
);

const optionalProperties = t.partial({
  moveChangeInfo: t.string,
  quantityChangeInfo: t.string,
  unitPriceChangeInfo: t.string,
});

const apiTargetChangeLogEntryType = t.intersection([
  requiredProperties,
  optionalProperties,
]);

export type APITargetChangeLogEntry = t.TypeOf<
  typeof apiTargetChangeLogEntryType
>;

export async function toTargetChangeLogEntry(
  u: unknown
): Promise<TargetChangeLogEntry[]> {
  const apiChangeLogEntries = await tPromise.decode(
    t.array(apiTargetChangeLogEntryType),
    u
  );

  return apiChangeLogEntries
    .map(({ targetRowChangeTypeId, ...rest }) => {
      const changeType = idToStatus[targetRowChangeTypeId];

      return {
        changeType,
        ...rest,
      };
    })
    .filter(isDefined);
}

const getTargetChangeLogEntries = async (orderId: string) => {
  const response = await GET(`v1/orders/${orderId}/target-change-log`);

  return toTargetChangeLogEntry(response);
};

export const fetchTargetChangeLogEntries = (orderId: string) =>
  createAsyncThunk(getTargetChangeLogEntries, {
    args: [orderId],
    isPending: flow(
      getTargetChangeLogEntriesFetchRequest(orderId),
      (requestState) => requestState === 'Loading'
    ),
    initialAction: getTargetChangeLogEntriesStarted({ orderId }),
    successActionCreator: (response) =>
      getTargetChangeLogEntriesSuccess({
        orderId,
        targetChangeLogEntries: response,
      }),
    failureActionCreator: (error) =>
      getTargetChangeLogEntriesFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
