import React from 'react';

import styled from 'styled-components';

import { InvoiceHeader } from '../../../../store/reducers/invoiceHeader';

import { APIWorkPackage } from '../../../../types/api';

import useTxt from '../../../../hooks/useTxt';

import Cell from '../../../../components/Cell';
import { BaseRow, Table } from '../../../../components/Table';

import { Topic as TopicType } from '../OrderRowDropdown';
import TableFooter from './TableFooter';
import { TableHeader } from './TableHeader';
import { Topic } from './Topic';

import { ExistingOrderRow, NewOrderRow } from '.';

type TableProps = {
  topicsToDisplay: TopicType[];
  existingOrderRows: ExistingOrderRow[];
  newOrderRows: NewOrderRow[];
  workPackages: APIWorkPackage[];
  invoiceHeader: InvoiceHeader;
};

const StyledCell = styled(Cell)`
  height: ${({ theme }) => theme.margin[64]};
  font-weight: bold;
`;

export const InvoiceLinesConvertTable = ({
  topicsToDisplay,
  newOrderRows,
  existingOrderRows,
  workPackages,
  invoiceHeader,
}: TableProps) => {
  const oldTopics = topicsToDisplay.filter((topic) => !topic.createNewTopic);

  const newTopics = topicsToDisplay.filter((topic) => topic.createNewTopic);

  const newTopicsText = useTxt('order.invoiceLines.convertModal.newTopics');

  return (
    <StyledDivTableContainer>
      <Table>
        <TableHeader />
        <tbody>
          {oldTopics.map((topic) => {
            const filteredNewOrderRows = newOrderRows.filter(
              (row) => row.topicId === topic.id
            );

            const filteredWorkPackage = workPackages.find(
              (row) => row.id === topic.workPackageId
            );

            const filteredExistingOrderRows = existingOrderRows.filter(
              (row) => row.orderRow.topicId === topic.id
            );

            return (
              <Topic
                key={`convert-modal-topic-${topic.id}`}
                topic={topic}
                workPackage={filteredWorkPackage}
                existingOrderRows={filteredExistingOrderRows}
                newOrderRows={filteredNewOrderRows}
              />
            );
          })}
          {newTopics.length > 0 ? (
            <BaseRow>
              <StyledCell colSpan={9}>{newTopicsText}</StyledCell>
            </BaseRow>
          ) : null}
          {newTopics.map((topic) => {
            const filteredNewOrderRows = newOrderRows.filter(
              (row) => row.topicId === topic.id
            );

            const filteredWorkPackage = workPackages.find(
              (row) => row.id === topic.workPackageId
            );

            const filteredExistingOrderRows = existingOrderRows.filter(
              (row) => row.orderRow.topicId === topic.id
            );

            return (
              <Topic
                key={`convert-modal-topic-${topic.id}`}
                topic={topic}
                workPackage={filteredWorkPackage}
                existingOrderRows={filteredExistingOrderRows}
                newOrderRows={filteredNewOrderRows}
              />
            );
          })}
          <TableFooter
            existingOrderRows={existingOrderRows}
            newOrderRows={newOrderRows}
            invoiceHeader={invoiceHeader}
          />
        </tbody>
      </Table>
    </StyledDivTableContainer>
  );
};

const StyledDivTableContainer = styled.div`
  max-height: 70vh;

  display: flex;
  flex-direction: column;

  flex: 1;

  overflow-y: scroll;
`;
