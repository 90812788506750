import React from 'react';

import Big from 'big.js';
import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';

import { DeltaBigCostValue } from '../../../../components/BigValue';
import Cell, { MoneyCell } from '../../../../components/Cell';
import { BaseRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';

import { IconArrowLongRight } from '../../../../assets';

import { ExtendedExistingOrderRow, NewOrderRow } from '.';

type ConversionRowProps = {
  row: ExtendedExistingOrderRow | NewOrderRow;
};

const StyledImg = styled.img`
  padding-left: ${({ theme }) => theme.margin[8]};
  vertical-align: middle;
`;

export function isNewOrderRow(
  row: ExtendedExistingOrderRow | NewOrderRow
): row is NewOrderRow {
  return (row as NewOrderRow).topicId !== undefined;
}

const ConversionRow = ({ row }: ConversionRowProps) => {
  const iconAltText = useTxt('order.invoiceLines.convertModal.table.arrow');

  if (isNewOrderRow(row)) {
    return (
      <Tr>
        <StyledCell align="left">+</StyledCell>
        <StyledCell align="left">{row.invoiceLine.productName}</StyledCell>
        <StyledCell />
        <StyledCell />
        <StyledCell align="center">
          <StyledImg src={IconArrowLongRight} alt={iconAltText} />
        </StyledCell>
        <StyledCellWithBackground align="right">{`${big.amountFormat(
          row.invoiceLine.quantity
        )} ${row.invoiceLine.unit}`}</StyledCellWithBackground>
        <StyledMoneyCellWithBackground
          value={row.invoiceLine.netTotal ?? new Big(0)}
        />
        <StyledCell align="right">
          <DeltaBigCostValue value={row.invoiceLine.netTotal ?? new Big(0)} />
        </StyledCell>
        <StyledMoneyCell value={row.invoiceLine.netTotal ?? new Big(0)} />
      </Tr>
    );
  }

  return (
    <Tr>
      <StyledCell align="left">{row.orderRow.rowNumber}</StyledCell>
      <StyledCell align="left">{row.orderRow.description}</StyledCell>
      <StyledCell align="right">{`${big.amountFormat(
        row.orderRow.quantity ?? new Big(0)
      )} ${row.orderRow.unit}`}</StyledCell>
      <StyledMoneyCell value={row.orderRowAmount} />
      <StyledCell align="center">
        <StyledImg src={IconArrowLongRight} alt={iconAltText} />
      </StyledCell>
      <StyledCellWithBackground align="right">{`${
        row.orderRow.unitPrice && row.orderRow.unitPrice.toNumber() !== 0
          ? big.amountFormat(row.newArrivalQuantity)
          : 'NaN'
      } ${row.orderRow.unit}`}</StyledCellWithBackground>
      <StyledMoneyCellWithBackground value={row.invoiceLinesTotalAmount} />
      <StyledCell align="right">
        <DeltaBigCostValue
          value={row.excessQuantity.mul(row.orderRow.unitPrice ?? new Big(0))}
        />
      </StyledCell>
      <StyledMoneyCell
        value={
          row.excessQuantity.toNumber() === 0
            ? row.orderRowAmount
                .sub(row.orderRow.arrivalTotal)
                .sub(row.invoiceLinesTotalAmount)
            : new Big(0)
        }
      />
    </Tr>
  );
};

export const Tr = styled(BaseRow)`
  background: ${({
    theme: {
      color: { white },
    },
  }) => white};
`;

export const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${(props) => props.theme.color.mercury};
`;

export const StyledCellWithBackground = styled(StyledCell)`
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${(props) => props.theme.color.mercury};
`;

const StyledMoneyCellWithBackground = styled(StyledMoneyCell)`
  background: ${({
    theme: {
      color: { primaryRowBackground },
    },
  }) => primaryRowBackground};
`;

export default ConversionRow;
