import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import * as uuid from 'uuid';

import { Revenue } from '../../../../store/reducers/revenue/revenue';
import { getActiveProjectId } from '../../../../store/reducers/ui';

import { requestNewRevenue } from '../../../../store/actions/revenue';

import { IconTextButton } from '../../../../components/Buttons';
import Cell from '../../../../components/Cell';
import { SecondaryRow } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import { IconPlus } from '../../../../assets/svg';

import ExcelImporter from '../ExcelImporter';
import { tableColumns } from './TableHeader';

type ControlRowProps = {
  paymentProgramRowGroupId: string;
  rows: Revenue[];
};

const columnTotal = Object.keys(tableColumns).length;

const ControlRow = (props: ControlRowProps) => {
  const { paymentProgramRowGroupId, rows } = props;

  const [excelImporterVisible, setExcelImporterVisible] = React.useState(false);
  const [requestId] = React.useState(uuid.v4());
  const projectId = useSelector(getActiveProjectId) ?? '';
  const dispatch = useDispatch();

  const onAddRowClicked = () => {
    dispatch(
      requestNewRevenue({ requestId, projectId, paymentProgramRowGroupId })
    );
  };

  const toggleViewExcelImporter = () => {
    setExcelImporterVisible(!excelImporterVisible);
  };

  return (
    <>
      <SecondaryRow>
        <Cell colSpan={columnTotal}>
          <StyledControlBtns>
            <IconTextButton icon={IconPlus} onClick={onAddRowClicked}>
              <Txt id="revenue.table.rows.addRow" />
            </IconTextButton>
            <IconTextButton icon={IconPlus} onClick={toggleViewExcelImporter}>
              <Txt id="common.importRowsFromTable" />
            </IconTextButton>
          </StyledControlBtns>
        </Cell>
      </SecondaryRow>
      {excelImporterVisible ? (
        <ExcelImporter
          onClose={toggleViewExcelImporter}
          paymentProgramRowGroupId={paymentProgramRowGroupId}
          rows={rows}
        />
      ) : null}
    </>
  );
};

const StyledControlBtns = styled.div`
  display: flex;
  gap: 1rem;
`;

export default ControlRow;
