/*
 * Performs left-to-right function composition. The first argument may have any
 * arity, the second argument must be unary.
 *
 * example:
 * const len = (s: string): number => s.length
 * const double = (n: number): number => n * 2
 * const f = flow(len, double)
 * f('aaa') === 6
 */
export function flow<A extends readonly unknown[], B, C>(
  ab: (...args: A) => B,
  bc: (b: B) => C
): (...args: A) => C {
  return (...args: A) => bc(ab(...args));
}

// calculates elapsed time from a given date to
// Date.now()

export function calculateElapsedTimeFrom(date: Date): number {
  return Math.floor(Date.now() - date.getTime());
}

// formats a given milliseconds into hour, min, sec
export function formatElapsedTime(elapsedTime: number) {
  // only diff with in 24 hour is considered
  const hours = Math.floor((elapsedTime / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
  const seconds = Math.floor((elapsedTime / 1000) % 60);

  return { hours, minutes, seconds };
}
