import styled from 'styled-components';

import { HeaderTh } from '../../../components/Table';

type ThProps = {
  textAlign?: 'left' | 'center' | 'right';
};

export const Th = styled(HeaderTh)<ThProps>`
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
`;

export const WhiteTh = styled(Th)`
  background-color: white;
`;
