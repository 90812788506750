import React from 'react';
import { useHistory } from 'react-router-dom';

import { NotificationFeedItem } from '../../../types/general';

import { useElapsedTime } from '../../../hooks/ui';
import useTxt from '../../../hooks/useTxt';

import * as big from '../../../utils/big';
import { dateFormat } from '../../../utils/format';
import groupBy from '../../../utils/groupBy';
import { keys } from '../../../utils/record';

import { generateUrl, routes } from '../../../routes';
import Tooltip from '../../Tooltip';
import {
  NotificationContainer,
  StyledNotificationContent,
  StyledHeader,
  StyledLinkArea,
  NotificationTimeStamp,
  StyledRouterLink,
  StyledLinkDiv,
} from './Components';

type NotificationProps = {
  notification: NotificationFeedItem;
  onCloseSidebar: () => void;
  markAsRead: (item: NotificationFeedItem) => void;
  isNew?: boolean;
};

const MultiOrderNotification = ({
  notification,
  onCloseSidebar,
  markAsRead,
  isNew,
}: NotificationProps) => {
  const history = useHistory();
  const invoice = useTxt('navigation.notifications.invoice');
  const invoiceDate = useTxt('order.receiveMode.arrivalRowTH.invoiceDate');
  const NewTxt = useTxt('navigation.notifications.new');
  const elapsedTimeText = useElapsedTime(notification.createdAt);

  const defaultDescription = useTxt(
    'navigation.notifications.defaultDescription'
  );

  const reclaimedDescription = useTxt(
    'navigation.notifications.description.reclaimedInvoices'
  );

  const beingCorrectedDescription = useTxt(
    'navigation.notifications.description.invoicesBeingCorrected'
  );

  const groupedInvoices = groupBy(
    'orderId',
    notification.purchaseInvoiceHeaders
  );

  const orderIds = keys(groupedInvoices);

  const handleOrderClick = () => {
    if (!notification.isRead) {
      markAsRead(notification);
    }

    onCloseSidebar();
  };

  const handleInvoiceClick = (invoiceId: string, orderId: string): void => {
    if (!notification.project?.id) {
      return;
    }

    const url = generateUrl({
      route: routes.ORDER_WITH_INVOICE_FOCUSED,
      projectId: notification.project.id,
      orderId,
      viewMode: 'receive',
      subViewMode: 'invoices',
      invoiceHeaderId: invoiceId,
    });

    if (!notification.isRead) {
      markAsRead(notification);
    }

    history.push(url);

    onCloseSidebar();
  };

  const selectDescription = (notificationType: string) => {
    switch (notificationType) {
      case '3':
        return reclaimedDescription;
      case '9':
        return beingCorrectedDescription;
      default:
        return defaultDescription;
    }
  };

  return (
    <NotificationContainer>
      <StyledNotificationContent>
        <StyledHeader>
          {`${isNew ? NewTxt : ''}${selectDescription(
            notification.notificationType
          )}`}
        </StyledHeader>
        {/* new indicator */}
        <NotificationTimeStamp>
          {isNew
            ? elapsedTimeText
            : notification.createdAt.toLocaleDateString()}
        </NotificationTimeStamp>

        <StyledLinkArea>
          {/* project info */}
          {notification.project ? notification.project.label : null}

          {/* notifications */}
          {notification.project && notification.order ? (
            <StyledRouterLink
              key={`order-link-${notification.order.id}`}
              to={generateUrl({
                route: routes.ORDER,
                projectId: notification.project.id,
                orderId: notification.order.id,
                viewMode: 'receive',
                subViewMode: 'invoices',
              })}
            >
              {notification.order.label}
            </StyledRouterLink>
          ) : null}
        </StyledLinkArea>

        <StyledLinkArea onClick={handleOrderClick}>
          {orderIds.map((id) => {
            if (!notification.project) {
              return null;
            }

            return (
              <React.Fragment key={`orders-and-invoices-${id}`}>
                <StyledRouterLink
                  key={`invoices-grouped-per-order-notification-${id}`}
                  to={generateUrl({
                    route: routes.ORDER,
                    projectId: notification.project.id,
                    orderId: id,
                    viewMode: 'receive',
                    subViewMode: 'invoices',
                  })}
                >
                  {groupedInvoices[id][0].orderName}
                </StyledRouterLink>
                {groupedInvoices[id].map((row) => (
                  <StyledLinkDiv
                    key={`invoice-${row.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInvoiceClick(row.id, row.orderId);
                    }}
                  >
                    <Tooltip tip={row.comment ?? ''}>
                      {invoice} {row.vendorInvoiceNumber} | {invoiceDate}{' '}
                      {dateFormat.format(row.dueDate)} |{' '}
                      {big.amountFormat(row.amount)} &euro;
                    </Tooltip>
                  </StyledLinkDiv>
                ))}
              </React.Fragment>
            );
          })}
        </StyledLinkArea>
      </StyledNotificationContent>
    </NotificationContainer>
  );
};

export default MultiOrderNotification;
