import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { APITask } from '@customtypes/api';

import { updateTaskStatus } from '../../../../../../store/actions';

import useTxt from '../../../../../../hooks/useTxt';

import {
  BaseButton,
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../../../components/Buttons';
import TextInput from '../../../../../../components/Input/TextArea';
import Modal, {
  Content,
  Header,
} from '../../../../../../components/Modal/Modal';
import Tooltip from '../../../../../../components/Tooltip';

import { isDefined } from '../../../../../../utils/general';

import { IconInfo } from '../../../../../../assets';

interface AssigneeViewProps {
  assignedTask: APITask;
  onShowOuterBar: (e: React.SyntheticEvent) => void;
}

const AssignerView = ({ assignedTask, onShowOuterBar }: AssigneeViewProps) => {
  const dispatch = useDispatch();

  const [showDenyReasonModal, setDenyReasonModal] = useState<boolean>();

  const [denyReason, setDenyReason] = useState<string>('');

  const OpenInvoice = useTxt('order.receiveMode.invoice.delegate.OpenInvoice');

  const isInvoiceOkayText = useTxt(
    'order.receiveMode.invoice.delegate.isInvoiceOkayText'
  );

  // modal content if invoice is not okay
  const headerText = useTxt(
    'order.receiveMode.invoice.delegate.denyModalHeader'
  );

  const cancelText = useTxt('common.cancel');
  const saveText = useTxt('common.send');

  const denyReasonInputText = useTxt(
    'order.receiveMode.invoice.delegate.denyReason'
  );

  const delegationApprovalDictionary: Record<string, string> = {
    '2': useTxt('common.status.yes'),
    '3': useTxt('common.status.no'),
    '4': useTxt('common.status.ignore'),
  };

  const handleStatusChange = (statusId: string) => {
    if (statusId === '3') {
      setDenyReasonModal(true);
    } else {
      saveStatusChange(statusId);
    }
  };

  const saveStatusChange = (statusId: string) => {
    dispatch(
      updateTaskStatus(assignedTask.id, {
        statusId,
        invoiceHeaderId: assignedTask.purchaseInvoiceHeaderId,
        denyReason,
      })
    );

    setDenyReasonModal(false);
  };

  return (
    <>
      {showDenyReasonModal ? (
        <ReasonModal onClose={() => setDenyReasonModal(false)}>
          <Header>{headerText}</Header>
          <Content>
            <Label>{denyReasonInputText}</Label>
            <TextInput onChange={(e) => setDenyReason(e.target.value)} />
            <CenteredButtonGroup>
              <SecondaryButton
                type="button"
                onClick={() => setDenyReasonModal(false)}
              >
                {cancelText}
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={!denyReason.length}
                onClick={() => saveStatusChange('3')}
              >
                {saveText}
              </PrimaryButton>
            </CenteredButtonGroup>
          </Content>
        </ReasonModal>
      ) : null}
      <BtnDelegation onClick={onShowOuterBar}>{OpenInvoice}</BtnDelegation>
      <TaskDescription>{assignedTask?.description} </TaskDescription>
      <TaskDescription>{isInvoiceOkayText}</TaskDescription>
      <ApprovalBtns>
        {Object.keys(delegationApprovalDictionary).map((key) => (
          <BtnDelegation
            disabled={
              isDefined(assignedTask.statusId) && assignedTask.statusId !== '1'
            }
            selected={key === assignedTask?.statusId}
            key={key}
            onClick={() => handleStatusChange(key)}
            className="actions-btns"
          >
            {delegationApprovalDictionary[key]}
          </BtnDelegation>
        ))}
      </ApprovalBtns>
      <DenyReasonInfo>
        <Tooltip tip={assignedTask.denyReason ?? ''}>
          {assignedTask.statusId === '3' ? <Icon src={IconInfo} /> : null}
        </Tooltip>
      </DenyReasonInfo>
    </>
  );
};

const ReasonModal = styled(Modal)``;

const DenyReasonInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  padding-bottom: 0.5rem;
  font-weight: 700;
`;

const TaskDescription = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

const BtnDelegation = styled(BaseButton)<{ selected?: boolean }>`
  border: 1px solid
    ${(props) =>
      props.selected ? props.theme.color.black : props.theme.color.white};
  border-radius: 13.5rem;

  padding: 0.25rem 1.25rem;

  max-width: 7.25rem;

  background: rgba(255, 255, 255, 0.47);

  font-size: 0.75rem;
  text-align: center;
  white-space: nowrap;

  :disabled {
    display: ${(props) => (props.selected ? 'auto' : 'none')};
    background: ${(props) => props.theme.color.buttonPrimaryDisabled};
    color: ${(props) => props.theme.color.black};
  }
`;

const ApprovalBtns = styled.div`
  display: flex;
  gap: 0.5rem;

  /* stylelint-disable selector-max-type -- TODO Should be refactored */
  button {
    background: ${(props) => props.theme.color.pitch};
    color: white;
  }
`;

export const StyledHeader = styled(Header)`
  padding: ${(props) => `${props.theme.margin[16]} ${props.theme.margin[16]}`};
  height: 48px;
`;

export const StyledContent = styled(Content)`
  padding: ${(props) => `${props.theme.margin[32]}`};
`;

const CenteredButtonGroup = styled(ButtonGroup)`
  position: absolute;
  bottom: 1.25rem;
  left: 0;

  width: 100%;

  justify-content: center;
`;

export const Description = styled.span`
  padding-bottom: ${(props) => `${props.theme.margin[32]}`};
  font-weight: bold;
`;

const Icon = styled.img`
  border-radius: 50%;
  width: ${({ theme }) => theme.margin[18]};
  height: ${({ theme }) => theme.margin[18]};
  background-color: ${({ theme }) => theme.color.orange};
`;

export default AssignerView;
