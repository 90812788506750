// Inspired by redux-toolkit's createAsyncthunk() and redux-loop's cmd.run().
//
// https://redux-toolkit.js.org/api/createAsyncThunk
// https://redux-loop.js.org/docs/api-docs/cmds.html#cmdrunfunc-options

import { ThunkAction } from 'redux-thunk';

import { AppState } from '../store/reducers';

import { ActionTypes } from '../store/actionTypes';

export type Thunk = ThunkAction<
  void | undefined,
  AppState,
  unknown,
  ActionTypes
>;

export function createAsyncThunk<Args extends readonly unknown[], Value, Error>(
  asyncFn: (...params: Args) => Promise<Value>,
  {
    args,
    isPending,
    initialAction,
    successActionCreator,
    failureActionCreator,
  }: {
    args: Args;
    isPending?: (s: AppState) => boolean;
    initialAction: ActionTypes;
    successActionCreator: (value: Value) => ActionTypes;
    failureActionCreator: (error: Error) => ActionTypes;
  }
): Thunk {
  return (dispatch, getState) => {
    if (isPending && isPending(getState())) {
      return;
    }

    dispatch(initialAction);
    asyncFn(...args).then(
      (value: Value) => {
        const successAction = successActionCreator(value);
        dispatch(successAction);
      },
      (error: Error) => {
        const failureAction = failureActionCreator(error);
        dispatch(failureAction);
      }
    );
  };
}

export function combineThunks(left: Thunk, right: Thunk): Thunk {
  return (dispatch) => {
    dispatch(left);
    dispatch(right);
  };
}
