import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsOuterBarOpen } from '../../../../../store/reducers/ui';

import {
  createOrderRow,
  createMultipleOrderRows,
} from '../../../../../store/actions';

import { APITopic, APIOrderRowPostBody } from '../../../../../types/api';

import { IconTextButton, ButtonGroup } from '../../../../../components/Buttons';
import Cell from '../../../../../components/Cell';
import { SecondaryRow } from '../../../../../components/Table';
import Txt from '../../../../../components/Txt';

import { emptyOrderRow } from '../../../../../utils/general';

import { IconPlus } from '../../../../../assets/svg';

import ExcelImporter from './ExcelImporter/ExcelImporter';

type Props = {
  topic: APITopic;
};

const TopicControlRow = ({ topic }: Props) => {
  const [excelImporterVisible, setExcelImporterVisible] = useState(false);
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const dispatch = useDispatch();

  const onCreateOrderRow = () => {
    dispatch(createOrderRow(emptyOrderRow(topic.orderId, topic.id)));
  };

  const onCreateTenRows = () => {
    dispatch(
      createMultipleOrderRows({
        data: Array<APIOrderRowPostBody>(10).fill(
          emptyOrderRow(topic.orderId, topic.id)
        ),
      })
    );
  };

  return (
    <>
      {excelImporterVisible && (
        <ExcelImporter
          topic={topic}
          onClose={() => setExcelImporterVisible(false)}
        />
      )}
      <SecondaryRow data-testid={`topic-control-row-${topic.id}`}>
        <Cell />
        <Cell colSpan={outerBarOpen ? 9 : 12}>
          <ButtonGroup>
            <IconTextButton icon={IconPlus} onClick={onCreateOrderRow}>
              <Txt id="common.addRow" />
            </IconTextButton>
            <IconTextButton icon={IconPlus} onClick={onCreateTenRows}>
              10
            </IconTextButton>
            <IconTextButton
              icon={IconPlus}
              onClick={() => setExcelImporterVisible(true)}
            >
              <Txt id="common.importRowsFromTable" />
            </IconTextButton>
          </ButtonGroup>
        </Cell>
      </SecondaryRow>
    </>
  );
};

export default TopicControlRow;
