import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { getProjectWorkPackageGroups } from '../../../store/reducers/workPackageGroup';

import { fetchWorkPackageGroupsAndWorkPackages } from '../../../store/actions';

import RemoteData from '../../../components/RemoteData';
import { Table } from '../../../components/Table';

import WorksectionGroup from './WorkPackageGroup';
import WorkSectionTableHeader from './WorksectionTableHeader';

type WorksectionTableProps = {
  projectId: string;
};

const WorksectionTable = ({ projectId }: WorksectionTableProps) => {
  const remoteWorkPackageGroups = useSelector(
    getProjectWorkPackageGroups(projectId)
  );

  return (
    <Table>
      <WorkSectionTableHeader projectId={projectId} />
      <RemoteData
        data={remoteWorkPackageGroups}
        fetchData={fetchWorkPackageGroupsAndWorkPackages(projectId)}
        loadingElement={<></>}
      >
        {(workPackageGroups) => (
          <>
            {workPackageGroups.map((group) => (
              <WorksectionGroup key={group.id} workPackageGroupId={group.id} />
            ))}
          </>
        )}
      </RemoteData>
    </Table>
  );
};

export default WorksectionTable;
