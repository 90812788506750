import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { getAreAllWorkSectionsOpen } from '../../store/reducers/ui';

import { uiStateToggleOpenAllWorkSections } from '../../store/actions/ui';

import useTxt from '../../hooks/useTxt';

import Txt from '../../components/Txt';

import getWidths from '../../utils/headerWidth';

import { IconDoubleRight, IconDoubleDown } from '../../assets/svg';

import { ToggleButton } from '../../context/toggleContext';

const tableColumns = {
  arrows: { align: 'center', relativeWidth: 0.2 },
  orderNumber: { align: 'left', relativeWidth: 0.5 },
  order: { align: 'left', relativeWidth: 2 },
  target: { align: 'right', relativeWidth: 1 },
  additionalTarget: { align: 'right', relativeWidth: 1 },
  predictionTotal: { align: 'right', relativeWidth: 1 },
  predictionChangeFromLatest: { align: 'right', relativeWidth: 1 },
  contractTotal: { align: 'right', relativeWidth: 1 },
  changeOrdersTotal: { align: 'right', relativeWidth: 1 },
  reserved: { align: 'right', relativeWidth: 1 },
  received: { align: 'right', relativeWidth: 1 },
  status: { align: 'center', relativeWidth: 1 },
} as const;

type ThProps = {
  name: keyof typeof tableColumns;
};

const getColumnPercent = getWidths(tableColumns);

const txtIdPrefix = 'worksectionDetails.orderTable.';

type TableHeaderRowProps = {
  hasOpenAllButton?: boolean;
};

const TableHeaderRow = ({ hasOpenAllButton }: TableHeaderRowProps) => {
  const namedColumns = [
    'order',
    'target',
    'additionalTarget',
    'predictionTotal',
    'predictionChangeFromLatest',
    'contractTotal',
    'changeOrdersTotal',
    'reserved',
    'received',
  ] as const;

  const dispatch = useDispatch();
  const allOpenText = useTxt('worksection.workPackage.groups.open');
  const allClosedText = useTxt('worksection.workPackage.groups.closed');

  const allSectionsOpen = useSelector(getAreAllWorkSectionsOpen());

  const toggleAllOpenStatus = () => {
    dispatch(uiStateToggleOpenAllWorkSections(''));
  };

  return (
    <tr>
      <ArrowsTh name="arrows">
        {hasOpenAllButton ? (
          <ToggleButton
            label={allSectionsOpen ? allOpenText : allClosedText}
            icon={allSectionsOpen ? IconDoubleDown : IconDoubleRight}
            onClick={toggleAllOpenStatus}
          />
        ) : null}
      </ArrowsTh>
      <Th name="orderNumber" />
      {namedColumns.map((name) => (
        <Th key={name} name={name}>
          <CellDiv align={tableColumns[name].align}>
            <span>
              <Txt id={`${txtIdPrefix}${name}` as const} component="b" />
            </span>
          </CellDiv>
        </Th>
      ))}
      <RightPaddedTh name="status">
        <CellDiv align={tableColumns.status.align}>
          <span>
            <Txt id={`${txtIdPrefix}status` as const} component="b" />
          </span>
        </CellDiv>
      </RightPaddedTh>
    </tr>
  );
};

type CellDivProps = {
  align?: 'center' | 'right' | 'left';
  className?: string;
};

const aligns = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

const CellDiv = styled.div<CellDivProps>`
  /* Needed for children and grandchildren, not this component itself */
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${({ align }) => (align ? `justify-content: ${aligns[align]};` : '')}
`;

export const Th = styled.th<ThProps>`
  border-top: 1px solid ${(props) => props.theme.color.rowBorder};
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};

  padding: ${(props) =>
    `${props.theme.sizes.smallPadding} ${props.theme.margin[24]} ${props.theme.sizes.smallPadding} ${props.theme.margin[8]}`};

  width: ${({ name }) => `${getColumnPercent(name)}%`};

  align-items: center;

  text-align: ${({ name }) => tableColumns[name].align};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const RightPaddedTh = styled(Th)`
  padding-right: ${({ theme }) => theme.margin[64]};
`;

const ArrowsTh = styled(Th)`
  width: ${({ theme }) => theme.margin[64]};
`;

export default TableHeaderRow;
