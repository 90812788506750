import React from 'react';
import { useSelector } from 'react-redux';

import { getTopic } from '../../../../../store/reducers/topic';

import { APITopicPutBody } from '../../../../../types/api';

import useTxt from '../../../../../hooks/useTxt';

import Cell, { CellProps } from '../../../../../components/Cell';
import TableTextInput from '../../../../../components/Input/TableTextInput';
import { TableErrorNotification } from '../../../components/TableErrorNotification';
import { MAX_TOPIC_LENGTH } from '../../../components/Topics/TopicRow';

type EditableTopicNameCellProps = {
  topicId: string;
  setTopic: <K extends keyof APITopicPutBody>(
    key: K
  ) => (value: string | null) => void;
  name: string;
  isValid: boolean;
  onBlur?: () => void;
};

export default ({
  topicId,
  setTopic,
  name,
  isValid,
  onBlur,
  ...cellProps
}: EditableTopicNameCellProps & CellProps) => {
  const topic = useSelector(getTopic(topicId));

  const errorMessageText = useTxt('common.maxCharacters', {
    value: MAX_TOPIC_LENGTH,
  });

  const validationError = !isValid ? errorMessageText : null;

  const onNameChange = () => ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setTopic('name')(value);
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  if (!topic) {
    return null;
  }

  return (
    <Cell {...cellProps}>
      <TableTextInput
        aria-label="Nimi"
        value={name}
        onChange={onNameChange()}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
      />
      {!isValid ? (
        <TableErrorNotification>{validationError}</TableErrorNotification>
      ) : null}
    </Cell>
  );
};
