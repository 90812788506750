import { useSelector } from 'react-redux';

import { Reducer } from 'redux';

import { ID } from '@customtypes/general';

import { APIPurchaseInvoiceAttachment } from '../../types/api';

import { getBaseApiUrl } from '../../utils/api';
import { updateRecord } from '../../utils/general';

import { ActionTypes } from '../actionTypes';
import { getInvoiceHeaderById } from './invoiceHeader';

import { AppState } from '.';

export type InvoiceAttachmentState = Record<ID, APIPurchaseInvoiceAttachment>;

const initialState: InvoiceAttachmentState = {};

const invoiceHeaderReducer: Reducer<InvoiceAttachmentState, ActionTypes> = (
  state = initialState,
  action
): InvoiceAttachmentState => {
  switch (action.type) {
    case 'GET_INVOICE_ATTACHMENTS_SUCCESS':
      return updateRecord(state, action.payload);
    default:
      return state;
  }
};

export const useInvoiceHeaderAttachmentUrls = (purchaseInvoiceHeaderId: ID) => {
  return useSelector((state: AppState) => {
    const invoiceHeader = getInvoiceHeaderById(purchaseInvoiceHeaderId)(state);

    if (!invoiceHeader) {
      return [];
    }

    return [
      ...(invoiceHeader.invoiceLink ? [invoiceHeader.invoiceLink] : []),
      ...Object.values(state.invoiceAttachments)
        .filter((row) =>
          invoiceHeader.purchaseInvoiceAttachmentIds.includes(row.id)
        )
        .map((row) => row.attachmentLink),
      ...Object.values(state.invoiceAttachmentFiles)
        .filter((row) => row.purchaseInvoiceHeaderId === invoiceHeader.id)
        .map(
          (row) =>
            `${getBaseApiUrl()}v1/attachments/purchase-invoice-headers/${
              row.purchaseInvoiceHeaderId
            }/files/${row.id}`
        ),
    ];
  });
};

export default invoiceHeaderReducer;
