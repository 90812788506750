import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Big from 'big.js';
import { v4 } from 'uuid';

import { getOrderById } from '../../../../store/reducers/order/order';

import { removeTargetRowAnalysisLinkage } from '../../../../store/actions/analysis/targetRowLinkage';
import { TargetRow } from '../../../../store/actions/target/targetRow';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { SecondaryRow } from '../../../../components/Table';
import UnlinkIconButton from '../../../../components/UnlinkIconButton';

import { priceFormat } from '../../../../utils/big';
import CAN, {
  CaslAnalysisDefinitionRequestParams,
} from '../../../../utils/caslUserPermissions';

import { IconToLinkedEntity } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';
import { RightPaddedCell, StyledCell } from '../ModalComponents';

type TableRowProps = {
  targetRow: TargetRow;
  analysisRowId: string;
};

export const TableRow = ({
  analysisRowId,
  targetRow: {
    id,
    orderId,
    topicId,
    referenceNumber,
    description,
    unitPrice,
    quantity,
  },
}: TableRowProps) => {
  const dispatch = useDispatch();

  const { visibleCode, name } = useSelector(getOrderById(orderId)) ?? {
    visibleCode: '',
    name: '',
  };

  const { projectId } = useRouteParams();
  const requestId = v4();

  const linkedEntityAltText = useTxt('analysis.modal.linkedEntity.altText');

  const unlinkTargetRow = () =>
    dispatch(
      removeTargetRowAnalysisLinkage({
        entityId: id,
        projectId,
        requestId,
        analysisRowId,
      })
    );

  const ability = new CaslAnalysisDefinitionRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <SecondaryRow
      key={id}
      data-testid={`analysis-target-row-modal-target-row-${id}`}
    >
      <StyledCell align="right">{referenceNumber}</StyledCell>
      <StyledCell>{description}</StyledCell>
      <StyledCell>{`${visibleCode} ${name}`}</StyledCell>
      <StyledCell>
        <UnlinkIconButton
          onClick={unlinkTargetRow}
          testId={`unlink-target-row-${id}-from-analysis-row-${analysisRowId}`}
          disabled={!allowedUser}
        />
      </StyledCell>
      <StyledCell>
        {allowedUser && topicId ? (
          <Link
            data-testid={`navigate-to-target-row-${id}-topic-${topicId}`}
            to={generateUrl({
              route: routes.ORDER_WITH_OPEN_TOPIC,
              projectId,
              orderId,
              topicId,
              showTargetRows: 'showTarget',
            })}
          >
            <img src={IconToLinkedEntity} alt={linkedEntityAltText} />
          </Link>
        ) : null}
      </StyledCell>
      <RightPaddedCell align="right">
        {priceFormat((unitPrice ?? new Big(0)).mul(quantity ?? new Big(0)))}
      </RightPaddedCell>
    </SecondaryRow>
  );
};
