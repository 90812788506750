import React from 'react';

import styled from 'styled-components';

import { APIProject } from '../../../../../store/reducers/project';
import {
  getProjectPoCMonths,
  getProjectCurrentMonth,
  getProjectTimelineSnapshotIds,
} from '../../../../../store/reducers/schedule/projectTimeline';
import { getProjectWorkPackageGroups } from '../../../../../store/reducers/workPackageGroup';

import { getProjectTimelineForProject } from '../../../../../store/actions/schedule/projectTimeline';
import { fetchWorkPackageGroupsAndWorkPackages } from '../../../../../store/actions/workPackageGroup';

import useRemoteData from '../../../../../hooks/useRemoteData';

import { Table } from '../../../../../components/Table';

import ProjectRow from './ProjectRow';
import TableHeader from './TableHeader';
import WorkPackageGroupRow from './WorkPackageGroupRow';

type TimelineViewProps = {
  project: APIProject | undefined;
  selectedSnapshotTypeId: '1' | '2' | '3';
};

const TimelineView = ({
  project,
  selectedSnapshotTypeId,
}: TimelineViewProps) => {
  const projectId = project ? project.id : '';

  const months = useRemoteData(
    getProjectPoCMonths({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const snapshotIds =
    useRemoteData(
      getProjectTimelineSnapshotIds({
        projectId,
      }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const currentPeriod = useRemoteData(
    getProjectCurrentMonth({ projectId }),
    getProjectTimelineForProject({ projectId })
  );

  const workPackageGroups =
    useRemoteData(
      getProjectWorkPackageGroups(projectId),
      fetchWorkPackageGroupsAndWorkPackages(projectId)
    ) ?? [];

  const sortedWorkPackageGroups = workPackageGroups.sort((a, b) =>
    a.code.localeCompare(b.code)
  );

  if (!project || !months || !currentPeriod) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledTable>
        <TableHeader
          projectId={projectId}
          snapshotIds={snapshotIds}
          months={months}
          currentPeriod={currentPeriod}
        />
        <tbody>
          <ProjectRow
            months={months}
            snapshotIds={snapshotIds}
            currentPeriod={currentPeriod}
            projectId={projectId}
            selectedSnapshotTypeId={selectedSnapshotTypeId}
          />

          {sortedWorkPackageGroups.map((group) => {
            return (
              <WorkPackageGroupRow
                workPackageGroup={group}
                projectId={projectId}
                months={months}
                snapshotIds={snapshotIds}
                currentPeriod={currentPeriod}
                key={`workPackageGroupRow-${group.id}`}
                selectedSnapshotTypeId={selectedSnapshotTypeId}
              />
            );
          })}
        </tbody>
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  max-height: 70vh;
  white-space: nowrap;
  overflow: auto;
`;

export const StyledTable = styled(Table)`
  border-collapse: separate;
`;

export default TimelineView;
