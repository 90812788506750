import React from 'react';
import { Link } from 'react-router-dom';

import { APITopic } from '../../types/api';

import { generateUrl, routes } from '../../routes';
import Txt from '../Txt';

type TopicDeletedNotificationProps = {
  orderId: string;
  projectId: string;
  deletedTopicName: string;
  defaultTopic: APITopic | undefined;
};

const TopicDeletedNotification: React.FC<TopicDeletedNotificationProps> = ({
  orderId,
  deletedTopicName,
  defaultTopic,
  projectId,
}) => {
  return (
    <>
      <Txt
        id="order.editMode.delete.topic.success.notification"
        values={{ deletedTopicName }}
      />{' '}
      &quot;
      <Link
        to={
          defaultTopic
            ? generateUrl({
                route: routes.ORDER_WITH_OPEN_TOPIC,
                viewMode: 'edit',
                projectId,
                orderId,
                topicId: defaultTopic.id,
              })
            : ''
        }
      >
        {defaultTopic?.name ?? <Txt id="common.description.missing" />}
      </Link>
      &quot; <Txt id="order.editMode.delete.topic.success.notification.after" />
    </>
  );
};

export default TopicDeletedNotification;
