import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import {
  ExtendedSortOrder,
  getSortOrderForAnalysisGroup,
  SortableKeys,
} from '../../../store/reducers/analysis/sortOrders';

import { analysisRowSortToggled } from '../../../store/actions/analysis/row';

import { TextAndIconButton } from '../../../components/Buttons';
import Txt from '../../../components/Txt';

import getWidths from '../../../utils/headerWidth';

import { IconSortDown, IconSortUp, IconDown } from '../../../assets/svg';

const tableColumns = {
  empty: { align: 'left', relativeWidth: 2 },
  code: { align: 'left', relativeWidth: 4 },
  value: { align: 'left', relativeWidth: 20 },
  paymentProgramRowsAmount: { align: 'right', relativeWidth: 7 },
  orderRowsAmount: { align: 'right', relativeWidth: 7 },
  targetRowsAmount: { align: 'right', relativeWidth: 7 },
  arrivalsAmount: { align: 'right', relativeWidth: 7 },
  attributeValues: { align: 'right', relativeWidth: 50 },
  delete: { align: 'left', relativeWidth: 3 },
};

export const Thead = styled.thead`
  height: ${(props) => props.theme.margin[32]};
`;

type ThProps = {
  name: keyof typeof tableColumns;
  widthDivider?: number;
  noText?: boolean;
};

const textIdHead = 'analysis.table.header.';

export const TxtTh = ({ name, widthDivider = 1, noText = false }: ThProps) => (
  <Th name={name} widthDivider={widthDivider}>
    {noText ? '' : <Txt id={`${textIdHead}${name}` as const} />}
  </Th>
);

const getColumnPercent = getWidths(tableColumns);

const Th = styled.th<ThProps>`
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};

  padding: ${({ theme: { margin } }) => `${margin[8]}`};

  width: ${({ name, widthDivider = 1 }) =>
    `${getColumnPercent(name) / widthDivider}%`};

  line-height: ${({ theme }) => theme.margin[32]};
  text-align: ${({ name }) => tableColumns[name].align};
  font-style: italic;
`;

type ColumnName = keyof typeof tableColumns;

type AcceptedColumn = Extract<
  ColumnName,
  | 'code'
  | 'value'
  | 'paymentProgramRowsAmount'
  | 'orderRowsAmount'
  | 'targetRowsAmount'
  | 'arrivalsAmount'
  | 'attributeValues'
>;

interface SortableThProps {
  name: AcceptedColumn;
  justifyContent: 'flex-end' | 'flex-start';
  analysisGroupId: string;
  noText?: boolean;
  attributeId?: string;
  attributeName?: string;
  widthDivider?: number;
}

const isSortableKey = (column: ColumnName): column is AcceptedColumn => {
  return column in SortableKeys;
};

const getSortIcon = (sortOrder: ExtendedSortOrder) => {
  switch (sortOrder) {
    case 'Ascending':
      return IconSortUp;
    case 'Descending':
      return IconSortDown;
    default:
      return IconDown;
  }
};

export const SortableTh = (props: SortableThProps) => {
  const {
    name,
    justifyContent,
    analysisGroupId,
    noText,
    attributeId,
    attributeName,
    widthDivider,
  } = props;

  const order = useSelector(
    getSortOrderForAnalysisGroup(name, analysisGroupId, attributeId)
  );

  const dispatch = useDispatch();

  const onRequestSort = () => {
    if (isSortableKey(name)) {
      dispatch(
        analysisRowSortToggled({
          analysisGroupId,
          sortableKey: name,
          attributeId,
        })
      );
    }
  };

  return (
    <Th name={name} widthDivider={widthDivider} onClick={onRequestSort}>
      {noText ? (
        ''
      ) : (
        <ButtonDiv justifyContent={justifyContent}>
          <TextAndIconButton icon={getSortIcon(order)}>
            {attributeName ? (
              <b>{attributeName}</b>
            ) : (
              <Txt id={`${textIdHead}${name}` as const} component="b" />
            )}
          </TextAndIconButton>
        </ButtonDiv>
      )}
    </Th>
  );
};

type ButtonDivProps = {
  justifyContent: 'flex-end' | 'flex-start';
};

const ButtonDiv = styled.div<ButtonDivProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
`;

export default SortableTh;
