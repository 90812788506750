import React from 'react';

import styled from 'styled-components';

import { StickyHeaderTh } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import getWidths from '../../../../utils/headerWidth';

export const tableColumns = {
  toggleButton: { align: 'left', relativeWidth: 2 },
  timeStamp: { align: 'left', relativeWidth: 4 },
  reportingPeriod: { align: 'left', relativeWidth: 4 },
  description: { align: 'left', relativeWidth: 4 },
  userName: { align: 'left', relativeWidth: 4 },
  percentageOfCompletion: { align: 'right', relativeWidth: 3 },
  targetChangeBeforeLocking: { align: 'right', relativeWidth: 3 },
  targetTotal: { align: 'right', relativeWidth: 3 },
  additionalTargetTotal: { align: 'right', relativeWidth: 3 },
  costPredictionTotal: { align: 'right', relativeWidth: 3 },
  predictionChangeBeforeLocking: { align: 'right', relativeWidth: 3 },
  contractTotal: { align: 'right', relativeWidth: 3 },
  changeOrdersTotal: { align: 'right', relativeWidth: 3 },
  reservesTotal: { align: 'right', relativeWidth: 3 },
  revenueTotal: { align: 'right', relativeWidth: 3 },
  profit: { align: 'right', relativeWidth: 3 },
  profitChange: { align: 'right', relativeWidth: 3 },
} as const;

const getColumnPercent = getWidths(tableColumns);

type ThProps = {
  name: keyof typeof tableColumns;
  paddingDisabled?: boolean;
};

const Th = styled(StickyHeaderTh)<ThProps>`
  padding: ${({ paddingDisabled, theme: { margin } }) =>
    paddingDisabled ? '0rem' : `${margin[8]} ${margin[8]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  align-items: center;
  text-align: ${({ name }) => tableColumns[name].align};
`;

const textIdHead = 'reporting.table.header.';

const TxtTh = ({ name, paddingDisabled }: ThProps) => (
  <Th name={name} paddingDisabled={paddingDisabled}>
    <Txt id={`${textIdHead}${name}` as const} component="b" />
  </Th>
);

const TableHeader = () => (
  <thead>
    <tr>
      <TxtTh name="toggleButton" />
      <TxtTh name="timeStamp" />
      <TxtTh name="reportingPeriod" />
      <TxtTh name="description" />
      <TxtTh name="userName" />
      <TxtTh name="percentageOfCompletion" />
      <TxtTh name="targetTotal" />
      <TxtTh name="additionalTargetTotal" />
      <TxtTh name="targetChangeBeforeLocking" />
      <TxtTh name="costPredictionTotal" />
      <TxtTh name="predictionChangeBeforeLocking" />
      <TxtTh name="contractTotal" />
      <TxtTh name="changeOrdersTotal" />
      <TxtTh name="reservesTotal" />
      <TxtTh name="revenueTotal" />
      <TxtTh name="profit" />
      <TxtTh name="profitChange" />
    </tr>
  </thead>
);
export default TableHeader;
