import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';

import { startInvoiceHeaderCorrection } from '../../../../../store/actions';

import useTxt from '../../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../../components/Buttons';
import TextInput from '../../../../../components/Input/TextInput';
import Modal, { Content, Header } from '../../../../../components/Modal/Modal';

export const FormContainer = styled.form`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  width: 30vw;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  padding: ${(props) => `${props.theme.margin[16]} ${props.theme.margin[16]}`};
  height: 48px;
`;

const StyledContent = styled(Content)`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};
  padding: ${(props) => `${props.theme.margin[32]}`};
  max-height: 16rem;
  min-height: 16rem;
`;

const CenteredButtonGroup = styled(ButtonGroup)`
  justify-content: center;
`;

const Description = styled.span`
  padding-bottom: ${(props) => `${props.theme.margin[32]}`};
`;

type InvoiceCorrectionModalProps = {
  invoiceHeader: InvoiceHeader;
  onClose: () => void;
};

const InvoiceCorrectionModal = ({
  invoiceHeader,
  onClose,
}: React.PropsWithChildren<InvoiceCorrectionModalProps>) => {
  const [reason, setReason] = useState('');
  const dispatch = useDispatch();

  const confirmationText = useTxt(
    'order.receiveMode.invoiceCorrection.confirmation'
  );

  const informationText = useTxt(
    'order.receiveMode.invoiceCorrection.information'
  );
  const reasonText = useTxt('order.receiveMode.invoiceCorrection.reason');
  const cancelText = useTxt('common.cancel');
  const saveText = useTxt('common.save');

  const onCorrectionReasonChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReason(e.target.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(startInvoiceHeaderCorrection(invoiceHeader.id, reason ?? ''));

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer onSubmit={onSubmit}>
        <StyledHeader>
          <h2>{confirmationText}</h2>
        </StyledHeader>
        <StyledContent>
          <Description>{informationText}</Description>
          <TextInput label={reasonText} onChange={onCorrectionReasonChanged} />
          <CenteredButtonGroup>
            <SecondaryButton type="button" onClick={onClose}>
              {cancelText}
            </SecondaryButton>
            <PrimaryButton type="submit">{saveText}</PrimaryButton>
          </CenteredButtonGroup>
        </StyledContent>
      </FormContainer>
    </Modal>
  );
};

export default InvoiceCorrectionModal;
