type RecordKey = string | symbol | number;

type Entries<Obj extends Record<RecordKey, RecordKey>> = {
  [Key in keyof Obj]: { key: Key; value: Obj[Key] };
}[keyof Obj];

type InvertedObject<Obj extends Record<RecordKey, RecordKey>> = {
  [Key in Entries<Obj>['value']]: Extract<Entries<Obj>, { value: Key }>['key'];
};

export default function invertObject<Obj extends Record<RecordKey, RecordKey>>(
  object: Obj
): InvertedObject<Obj> {
  const invertedObject: any = Object.keys(object).reduce(
    (acc, key) => ({ ...acc, [object[key]]: key }),
    {}
  );

  return invertedObject;
}
