import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { WorkPackageReceivedTimelineEntry } from '../../actions/schedule/workPackageReceivedTimeline';
import { ActionTypes as Action } from '../../actionTypes';

type Err = BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<string, WorkPackageReceivedTimelineEntry[]>;
};

const initialState: State = {
  requests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WORK_PACKAGE_RECEIVED_TIMELINES_STARTED': {
      const { workPackageId } = action.payload;

      const requests = {
        ...state.requests,
        [workPackageId]: remoteData.loading,
      };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_WORK_PACKAGE_RECEIVED_TIMELINES_FAILURE': {
      const { workPackageId, error } = action.payload;

      const requests = {
        ...state.requests,
        [workPackageId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }

    case 'GET_WORK_PACKAGE_RECEIVED_TIMELINES_SUCCESS': {
      const { workPackageId, workPackageReceivedTimelines } = action.payload;

      const requests = {
        ...state.requests,
        [workPackageId]: remoteData.succeed(undefined),
      };

      return {
        requests,
        data: { ...state.data, [workPackageId]: workPackageReceivedTimelines },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  workPackageId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    schedule: {
      workPackageReceivedTimelines: {
        requests: { [workPackageId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
}

export function isLoading(workPackageId: string): Selector<boolean> {
  return flow(
    getRequestState(workPackageId),
    (requestState) => requestState === 'Loading'
  );
}

export const getWorkPackageSnapshotReceivedTimelineEntries: (
  workPackageId: string
) => Selector<remoteData.RemoteData<WorkPackageReceivedTimelineEntry[]>> = (
  workPackageId
) => ({
  schedule: {
    workPackageReceivedTimelines: {
      requests: { [workPackageId]: requestState = remoteData.notAsked },
      data,
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    const timelineData = data[workPackageId];

    if (!timelineData) {
      return [];
    }

    const filteredTimelineData = timelineData.filter(
      (row) => row.snapshotId !== null && row.workPackageId === workPackageId
    );

    return filteredTimelineData;
  });

export const getWorkPackageCurrentPeriodReceivedTimelineEntries: (
  workPackageId: string
) => Selector<remoteData.RemoteData<WorkPackageReceivedTimelineEntry[]>> = (
  workPackageId
) => ({
  schedule: {
    workPackageReceivedTimelines: {
      requests: { [workPackageId]: requestState = remoteData.notAsked },
      data,
    },
  },
}) =>
  remoteData.map(requestState, (_) => {
    const timelineData = data[workPackageId];

    if (!timelineData) {
      return [];
    }

    const filteredTimelineData = timelineData.filter(
      (row) => row.snapshotId === null && row.workPackageId === workPackageId
    );

    return filteredTimelineData;
  });
