export type FormValues = {
  name: string;
  vatCodeId: string;
  supplierId: string;
  costTypeId: string;
  accountCodeId: string;
  visibleCode: string;
};

type NameErrorTextId =
  | 'order.options.formik.error.name.length'
  | 'order.options.formik.error.name.mandatory';

type VisibleCodeErrorTextId =
  | 'order.options.formik.error.code.procurementAreaMatch'
  | 'order.options.formik.error.code.length'
  | 'order.options.formik.error.code.unique'
  | 'order.options.formik.error.code.mandatory'
  | 'order.options.formik.error.code.onlyLettersAndNumbersAndDashes';

export function isNameErrorTextId(
  value: string | undefined
): value is NameErrorTextId {
  if (!value) {
    return false;
  }

  return [
    'order.options.formik.error.name.length',
    'order.options.formik.error.name.mandatory',
  ].includes(value);
}

export function isVisibleCodeErrorTextId(
  value: string | undefined
): value is VisibleCodeErrorTextId {
  if (!value) {
    return false;
  }

  return [
    'order.options.formik.error.code.procurementAreaMatch',
    'order.options.formik.error.code.length',
    'order.options.formik.error.code.unique',
    'order.options.formik.error.code.mandatory',
    'order.options.formik.error.code.onlyLettersAndNumbersAndDashes',
  ].includes(value);
}
