import styled, { css } from 'styled-components';

import Cell from '../../../../../components/Cell';
import { NumberIndicator } from '../../../../../components/Navigation/Components';

type SectionTitleBarProps = {
  icon?: string;
};

export const SectionTitleBar = styled.div<SectionTitleBarProps>`
  padding: ${(props) => props.theme.margin[24]}
    ${(props) => props.theme.margin[8]};

  height: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: bold;

  ${(props) =>
    props.icon
      ? css`
          padding-left: 2.5rem;
          background-repeat: no-repeat;
          background-position: 1rem center;
          background-image: url(${props.icon});
        `
      : ''}
`;

export const SectionSpacer = styled.div`
  margin-bottom: ${(props) => props.theme.margin[40]};
  width: 100%;
`;

export const ToggleBar = styled.div`
  position: relative;
  margin: 1rem 0;
  display: flex;
  align-items: flex-end;
`;

export const AssignmentBoxContainer = styled.div`
  position: sticky;
  top: 4.25rem;

  padding: ${(props) => `${props.theme.margin[8]} ${props.theme.margin[24]}`};

  height: 2rem;

  display: flex;
  align-items: center;

  background: ${({ theme }) => theme.color.toolbarHighlight};

  z-index: 30;
`;

export const AttachmentCount = styled(NumberIndicator)`
  left: 10px;
  bottom: -0.4rem;
  top: inherit;
  background-color: ${(props) => props.theme.color.indicatorBackground};
`;

export const IndicatorImg = styled.img`
  margin-right: ${({ theme }) => theme.margin[20]};
`;

/**
 * DropDownList is a styled component, that creates a floating container for list items.
 * DropDownList is a flex column, whose children are aligned to left.
 *
 * It is recommended to use DropDownList inside a <Cell> component
 * so that the DropDownList has a close containing block
 * (https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_Block), and that it
 * doesn't get positioned to some random place in the page (closest containing block might be very far).
 *
 * For the future:
 * Currently the top and right proporties are taken from the theme, because this component
 * is used in very few places, and these numbers suit the usage there (the containing block are
 * colspan 1, centered components that contain only one icon).
 * If the right and top properties need to be adjusted, when/if this component is used in more
 * places, probably best idea is to give them as props, so that there's no need to create a new component.
 */

export const DropDownList = styled.div`
  position: absolute;
  right: ${(props) => props.theme.margin[8]};
  top: ${(props) => props.theme.margin[24]};

  border: 1px solid ${({ theme }) => theme.color.graphiteB76};
  border-radius: ${(props) => props.theme.margin[2]};
  box-shadow: 0px 4px 40px -10px rgba(0, 0, 0, 0.25);

  padding: ${({ theme }) => `${theme.margin[32]} 0 `};

  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.color.white};

  cursor: initial;

  z-index: 30;
`;

type DropDownItemProps = {
  disabled?: boolean;
};

const IconSize = '1.125rem';

export const DropDownItem = styled.span<DropDownItemProps>`
  padding: ${(props) => `${props.theme.margin[8]} ${props.theme.margin[24]}`};

  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  color: ${(props) =>
    props.disabled ? props.theme.color.graphiteB76 : props.theme.color.pitch};

  /* stylelint-disable selector-max-type -- svgs have to be styled with type */
  svg {
    margin-right: ${(props) => props.theme.margin[24]};
    width: ${IconSize};
    height: ${IconSize};
  }
  svg path {
    stroke: ${(props) => (props.disabled ? props.theme.color.graphiteB76 : ``)};
  }

  :hover,
  :focus {
    ${({ disabled, theme }) =>
      disabled
        ? css`
            user-select: none;
            cursor: not-allowed;
          `
        : css`
            background-color: ${theme.color.primaryRowBackground};
            cursor: pointer;
          `}
  }
`;

type InvoiceActiveProp = {
  isActive?: boolean;
};

export const TableBodyGap = styled.tr<InvoiceActiveProp>`
  border-left: 0.16rem solid ${(props) => props.theme.color.white};
  border-right: 0.12rem solid ${(props) => props.theme.color.white};
  height: 0.5rem;

  ${(props) =>
    props.isActive &&
    css`
      border-top: 0.05rem solid ${props.theme.color.buttonPrimaryDisabled};
    `}
`;

export const InvoiceTableBody = styled.tbody<InvoiceActiveProp>`
  border-right: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  border-left: 1px solid ${(props) => props.theme.color.toolbarHighlight};

  ${(props) =>
    props.isActive &&
    css`
      border-left: 0.15rem solid ${props.theme.color.darkpurple};
    `}
`;

export const ProcessingCell = styled(Cell)<InvoiceActiveProp>`
  border-left: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  border-right: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  border-top: 1px solid white;

  padding: 0;

  height: 2rem;
`;
