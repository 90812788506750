import { Reducer } from 'redux';

import { flow } from '../../../utils/function';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from '../utils';

import { ActionTypes as Action } from '../../actionTypes';

type State = Partial<Record<string, string[]>>;

const reducer: Reducer<State, Action> = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_TARGET_ROW_SELECTION': {
      const { orderId, targetRowId } = action.payload;

      const prevSelection = state[orderId] ?? [];

      const isSelected = prevSelection.includes(targetRowId);

      const nextSelection = isSelected
        ? prevSelection.filter((x) => x !== targetRowId)
        : [...prevSelection, targetRowId];

      return {
        ...state,
        [orderId]: nextSelection,
      };
    }
    case 'TOGGLE_MULTIPLE_TARGET_ROW_SELECTION': {
      const { orderId, targetRowIds, chooseAll } = action.payload;

      const prevSelection = state[orderId] ?? [];

      const withoutIds = prevSelection.filter(
        (value) => !targetRowIds.includes(value)
      );

      const withIds = [...new Set([...prevSelection, ...targetRowIds])];

      const newOrderState = chooseAll ? withIds : withoutIds;

      return { ...state, [orderId]: newOrderState };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { targetRows: updatedTargetRows = [] } = action.payload;

    return updatedTargetRows.length === 0 ? state : {};
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default reducer;

export function getSelectedTargetRowsForOrder(
  orderId: string
): Selector<string[]> {
  return ({
    target: {
      selection: { [orderId]: orderSelectedTargetRows = [] },
    },
  }) => orderSelectedTargetRows;
}

export type SelectionLocation = {
  orderId: string;
  targetRowId: string;
};

export const isTargetRowSelected = ({
  orderId,
  targetRowId,
}: SelectionLocation) =>
  flow(getSelectedTargetRowsForOrder(orderId), (orderSelectedTargetRows) =>
    orderSelectedTargetRows.includes(targetRowId)
  );
