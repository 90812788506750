import React from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';

import { getArrivalsByOrderId } from '../../../../store/reducers/arrival';
import { getOrderById } from '../../../../store/reducers/order/order';
import { getUserName } from '../../../../store/reducers/user';

import { APIOrderRow } from '../../../../types/api';
import { ReceiveAmountState } from '../../../../types/general';

import useTxt from '../../../../hooks/useTxt';

import {
  IconTextButton,
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../components/Buttons';
import Toolbar from '../../../../components/Toolbar';

import * as big from '../../../../utils/big';
import CAN, {
  CaslPaymentProgramRowRequestParams,
} from '../../../../utils/caslUserPermissions';
import { dateTimeFormat } from '../../../../utils/format';

import { IconArrowLeft } from '../../../../assets/svg';

import ArrivalAssignmentLaari from './ArrivalAssignmentLaari';

type Props = {
  receiveAmount: ReceiveAmountState;
  orderId: string;
  projectId: string;
  orderRows: Partial<Record<string, APIOrderRow>>;
  onClickClear: () => void;
  onClickSubmit: () => void;
  onClickExit: () => void;
};

const ReceiveModeBottomControls = ({
  receiveAmount,
  orderId,
  orderRows,
  onClickClear,
  onClickSubmit,
  onClickExit,
  projectId,
}: Props) => {
  const orderArrivals = useSelector(getArrivalsByOrderId(orderId));
  const username = useSelector(getUserName);
  const order = useSelector(getOrderById(orderId));
  const ability = new CaslPaymentProgramRowRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const exitText = useTxt('order.receiveMode.exit');
  const arrivalText = useTxt('order.receiveMode.arrival');
  const amountText = useTxt('common.amount').toLowerCase();
  const clearText = useTxt('common.clear');
  const receiveText = useTxt('common.receive');

  if (!order) {
    return null;
  }

  const allValid = Object.values(receiveAmount).every((r) => r.valid);

  const allEmpty = Object.values(receiveAmount).every(
    (r) => r.amount === '' || big.fromInputString(r.amount, 0).eq(0)
  );

  const totalSum = big.sum(
    ...Object.keys(receiveAmount).map((orderRowId) =>
      big
        .fromInputString(receiveAmount[orderRowId].amount, 0)
        .mul(orderRows[orderRowId]?.unitPrice || new Big(0))
    )
  );

  const newArrivalNumber =
    orderArrivals.reduce(
      (result, current) => Math.max(result, current.rowNumber),
      0
    ) + 1;

  const showArrivalText = !allEmpty && allValid;

  return (
    <>
      <Toolbar>
        <IconTextButton icon={IconArrowLeft} onClick={onClickExit}>
          {exitText}
        </IconTextButton>
        {showArrivalText ? (
          <div>
            {newArrivalNumber}. {arrivalText}, {username},{' '}
            {dateTimeFormat.format(new Date())}, {amountText}{' '}
            {big.priceFormat(totalSum)}
          </div>
        ) : null}
        <ButtonGroup>
          <SecondaryButton onClick={onClickClear}>{clearText}</SecondaryButton>
          <PrimaryButton
            onClick={onClickSubmit}
            disabled={!allValid || allEmpty || !allowedUser}
          >
            {receiveText}
          </PrimaryButton>
        </ButtonGroup>
      </Toolbar>
      <ArrivalAssignmentLaari />
    </>
  );
};

export default ReceiveModeBottomControls;
