import Big from 'big.js';
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { APIUpdatedEntities, RawAPIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  POST,
  BackendError,
  apiErrorHandlingWithDecode,
  DELETE,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../../utils/thunk';

import {
  getTargetRowsForAnalysisRow,
  isPending,
  isTargetRowsForProjectPending,
} from '../../reducers/target/targetRows';
import {
  DraftTargetRow,
  SplitDraftTargetRow,
  getTargetRowUpdateRequest,
} from '../../reducers/ui';

const apiTargetRowType = t.exact(
  t.type({
    id: t.string,

    orderId: t.string,
    topicId: t.union([t.string, t.null]),
    orderRowId: t.union([t.string, t.null]),

    referenceNumber: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),

    quantity: t.union([bigString, t.null]),
    unitPrice: t.union([bigString, t.null]),
    unit: t.union([t.string, t.null]),

    isDeleted: t.boolean,
    isOriginal: t.boolean,
    isDisabled: t.boolean,
    isSplitFrom: t.union([t.string, t.null]),
    isDeletable: t.boolean,

    createdAt: dateString,
    updatedAt: dateString,

    targetRowHierarchyEntryId: t.union([t.string, t.null]),
    analysisListItemIds: t.array(t.string),
    projectId: t.string,
  })
);

export type TargetRow = t.TypeOf<typeof apiTargetRowType> & { totalPrice: Big };

const actionCreators = {
  ...makeAction('getTargetRowsStarted')<{ orderId: string }>(),
  ...makeAction('getTargetRowsSuccess')<{
    orderId: string;
    targetRows: TargetRow[];
  }>(),
  ...makeAction('getTargetRowsFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getTargetRowsForProjectStarted')<{ projectId: string }>(),
  ...makeAction('getTargetRowsForProjectSuccess')<{
    projectId: string;
    targetRows: TargetRow[];
  }>(),
  ...makeAction('getTargetRowsForProjectFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getTargetRowsForAnalysisRowStarted')<{
    analysisRowId: string;
  }>(),
  ...makeAction('getTargetRowsForAnalysisRowFailure')<{
    analysisRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getTargetRowsForAnalysisRowSuccess')<{
    analysisRowId: string;
    targetRows: TargetRow[];
  }>(),
  ...makeAction('postTargetRowsStarted')<{ requestId: string }>(),
  ...makeAction('postTargetRowsSuccess')<
    APIUpdatedEntities & { requestId: string }
  >(),
  ...makeAction('postTargetRowsFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deleteTargetRowStarted')<{ targetRowId: string }>(),
  ...makeAction('deleteTargetRowSuccess')<
    APIUpdatedEntities & { targetRowId: string }
  >(),
  ...makeAction('deleteTargetRowFailure')<{
    targetRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('splitTargetRowsStarted')<{ requestId: string }>(),
  ...makeAction('splitTargetRowsSuccess')<
    APIUpdatedEntities & { requestId: string }
  >(),
  ...makeAction('splitTargetRowsFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
};

export const {
  getTargetRowsStarted,
  getTargetRowsFailure,
  getTargetRowsSuccess,
  getTargetRowsForProjectStarted,
  getTargetRowsForProjectFailure,
  getTargetRowsForProjectSuccess,
  getTargetRowsForAnalysisRowStarted,
  getTargetRowsForAnalysisRowFailure,
  getTargetRowsForAnalysisRowSuccess,
  postTargetRowsStarted,
  postTargetRowsSuccess,
  postTargetRowsFailure,
  deleteTargetRowStarted,
  deleteTargetRowSuccess,
  deleteTargetRowFailure,
  splitTargetRowsStarted,
  splitTargetRowsSuccess,
  splitTargetRowsFailure,
} = actionCreators;

export type TargetRowAction = ExtractActionTypes<typeof actionCreators>;

type OrderId = {
  orderId: string;
};

type ProjectId = {
  projectId: string;
};

export async function decodeTargetRows(u: unknown): Promise<TargetRow[]> {
  const targetRows = await tPromise.decode(t.array(apiTargetRowType), u);

  return targetRows.map(({ unitPrice, quantity, ...rest }) => ({
    unitPrice,
    quantity,
    totalPrice: (unitPrice ?? new Big(0)).mul(quantity ?? new Big(0)),
    ...rest,
  }));
}

const fetchTargetRows = async ({ orderId }: OrderId): Promise<TargetRow[]> => {
  return decodeTargetRows(await GET(`v1/orders/${orderId}/target-rows`));
};

const fetchTargetRowsForProject = async ({
  projectId,
}: ProjectId): Promise<TargetRow[]> => {
  return decodeTargetRows(await GET(`v1/projects/${projectId}/target-rows`));
};

export const requestTargetRows = ({ orderId }: OrderId) =>
  createAsyncThunk(fetchTargetRows, {
    args: [{ orderId }],
    isPending: isPending({ orderId }),
    initialAction: getTargetRowsStarted({ orderId }),
    failureActionCreator: (error) =>
      getTargetRowsFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (targetRows) =>
      getTargetRowsSuccess({ orderId, targetRows }),
  });

export const requestTargetRowsForProject = ({ projectId }: ProjectId) =>
  createAsyncThunk(fetchTargetRowsForProject, {
    args: [{ projectId }],
    isPending: isTargetRowsForProjectPending({ projectId }),
    initialAction: getTargetRowsForProjectStarted({ projectId }),
    failureActionCreator: (error) =>
      getTargetRowsForProjectFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (targetRows) =>
      getTargetRowsForProjectSuccess({ projectId, targetRows }),
  });

const postNewTargetRows = async (
  rows: DraftTargetRow[]
): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>(
    'v1/target-rows/multiple',
    {
      data: rows.map(
        ({
          description,
          unit,
          quantity,
          unitPrice,
          analysisId,
          createAlsoOrder,
          orderId,
          topicId,
        }) => ({
          description,
          unit,
          quantity: quantity || null,
          unitPrice: unitPrice || null,
          analysisListItemIds: analysisId ? [analysisId] : [],
          createOrderRow: createAlsoOrder,
          orderId,
          topicId,
        })
      ),
    }
  );

  return mapRawUpdatedEntities(response);
};

type NewTargetRowsRequest = {
  requestId: string;
  data: DraftTargetRow[];
};

export const requestNewTargetRows = ({
  requestId,
  data,
}: NewTargetRowsRequest): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(postNewTargetRows, {
      args: [data],
      isPending: flow(
        getTargetRowUpdateRequest(requestId),
        remoteData.isLoading
      ),
      initialAction: postTargetRowsStarted({ requestId }),
      successActionCreator: (updatedEntities) =>
        postTargetRowsSuccess({ ...updatedEntities, requestId }),
      failureActionCreator: (error) =>
        postTargetRowsFailure({
          requestId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

const deleteTargetRow = async (
  targetRowId: string
): Promise<APIUpdatedEntities> => {
  const response = await DELETE<RawAPIUpdatedEntities>(
    `v1/target-rows/${targetRowId}`
  );

  return mapRawUpdatedEntities(response);
};

type DeleteTargetRowRequest = {
  targetRowId: string;
};

export const requestDeleteTargetRow = ({
  targetRowId,
}: DeleteTargetRowRequest): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(deleteTargetRow, {
      args: [targetRowId],
      isPending: flow(
        getTargetRowUpdateRequest(targetRowId),
        remoteData.isLoading
      ),
      initialAction: deleteTargetRowStarted({ targetRowId }),
      successActionCreator: (updatedEntities) =>
        deleteTargetRowSuccess({ ...updatedEntities, targetRowId }),
      failureActionCreator: (error) =>
        deleteTargetRowFailure({
          targetRowId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

const splitTargetRows = async (
  rows: SplitDraftTargetRow[]
): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>('v1/target-rows/split', {
    data: rows.map(
      ({
        description,
        unit,
        quantity,
        unitPrice,
        analysisId,
        orderId,
        topicId,
        splitFromTargetRowId,
      }) => ({
        description,
        unit,
        quantity: quantity || null,
        unitPrice: unitPrice || null,
        analysisListItemIds: analysisId ? [analysisId] : [],
        orderId,
        topicId,
        splitFromTargetRowId,
      })
    ),
  });

  return mapRawUpdatedEntities(response);
};

type SplitTargetRowsRequest = {
  requestId: string;
  data: SplitDraftTargetRow[];
};

export const requestSplitTargetRows = ({
  requestId,
  data,
}: SplitTargetRowsRequest): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(splitTargetRows, {
      args: [data],
      isPending: flow(
        getTargetRowUpdateRequest(requestId),
        remoteData.isLoading
      ),
      initialAction: splitTargetRowsStarted({ requestId }),
      successActionCreator: (updatedEntities) =>
        splitTargetRowsSuccess({ ...updatedEntities, requestId }),
      failureActionCreator: (error) =>
        splitTargetRowsFailure({
          requestId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

const fetchTargetRowsForAnalysisRow = async ({
  analysisRowId,
}: {
  analysisRowId: string;
}): Promise<TargetRow[]> => {
  return decodeTargetRows(
    await GET(`v1/custom-fields/list-items/${analysisRowId}/target-rows`)
  );
};

export const requestTargetRowsForAnalysisRow = (analysisRowId: string): Thunk =>
  createAsyncThunk(fetchTargetRowsForAnalysisRow, {
    args: [{ analysisRowId }],
    isPending: flow(
      getTargetRowsForAnalysisRow(analysisRowId),
      remoteData.isLoading
    ),
    initialAction: getTargetRowsForAnalysisRowStarted({ analysisRowId }),
    failureActionCreator: (error) =>
      getTargetRowsForAnalysisRowFailure({
        analysisRowId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (targetRows) =>
      getTargetRowsForAnalysisRowSuccess({ analysisRowId, targetRows }),
  });
