import { makeApiActions, ExtractActionTypes } from '../../utils/actionCreators';
import { GET } from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk } from '../../utils/thunk';

import { getBatchGroups, BatchGroup } from '../reducers/batchGroup';

export type BatchGroupAction = ExtractActionTypes<typeof actionCreators>;
const actionCreators = makeApiActions('get', 'batchGroups')<BatchGroup[]>();

export const {
  getBatchGroupsStarted,
  getBatchGroupsFailure,
  getBatchGroupsSuccess,
} = actionCreators;

async function fetchBatchGroups(): Promise<BatchGroup[]> {
  return GET<BatchGroup[]>('v1/payment-batch-groups');
}

export const requestBatchGroups = createAsyncThunk(fetchBatchGroups, {
  args: [],
  isPending: flow(getBatchGroups, remoteData.isLoading),
  initialAction: getBatchGroupsStarted(),
  successActionCreator: getBatchGroupsSuccess,
  failureActionCreator: () => getBatchGroupsFailure(),
});
