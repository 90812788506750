import {
  APITaskUpdatePutBody,
  APITask,
  APITaskPostBody,
  // APITaskPutBody,
  APIUpdatedEntities,
  RawAPIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import {
  makeAction,
  makeApiActions,
  ExtractActionTypes,
} from '../../utils/actionCreators';
import { ApiError, BackendError, GET, POST, PUT } from '../../utils/api';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { isFetchingByOrderId } from '../reducers/tasks';

export type TaskAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeApiActions('post', 'task')<APIUpdatedEntities>(),
  ...makeAction('getTasksByOrderIdStarted')<{
    orderId: string;
  }>(),
  ...makeAction('getTasksByOrderIdFailure')<{
    error: BackendError | undefined;
    orderId: string;
  }>(),
  ...makeAction('getTasksByOrderIdSuccess')<{
    orderId: string;
    tasks: APITask[];
  }>(),
  ...makeApiActions('put', 'delegationStatus')<APIUpdatedEntities>(),
};
export const {
  putDelegationStatusStarted,
  putDelegationStatusFailure,
  putDelegationStatusSuccess,
  postTaskStarted,
  postTaskSuccess,
  postTaskFailure,
  getTasksByOrderIdStarted,
  getTasksByOrderIdFailure,
  getTasksByOrderIdSuccess,
} = actionCreators;

const postTask = (body: APITaskPostBody) =>
  POST<RawAPIUpdatedEntities>('v1/tasks', body).then(mapRawUpdatedEntities);

// create delegation task
export const createTask = (
  body: APITaskPostBody,
  successCallback?: (updated: APIUpdatedEntities) => void
): Thunk => (dispatch, _) => {
  dispatch(postTaskStarted());

  postTask(body).then(
    (updatedEntities) => {
      dispatch(postTaskSuccess(updatedEntities));

      if (successCallback) {
        successCallback(updatedEntities);
      }
    },
    (error) => {
      dispatch(
        postTaskFailure(
          error instanceof ApiError ? error.getBackendError() : undefined
        )
      );
    }
  );
};

/**  request by orderId
 @param orderId
*/
const getTasksByOrderId = (orderId: ID) =>
  GET<APITask[]>(`v1/orders/${orderId}/tasks`);

export const fetchTasksForOrder = (orderId: ID) =>
  createAsyncThunk(getTasksByOrderId, {
    args: [orderId],
    isPending: isFetchingByOrderId(orderId),
    initialAction: getTasksByOrderIdStarted({ orderId }),
    failureActionCreator: (error) =>
      getTasksByOrderIdFailure({
        orderId,
        error: error instanceof ApiError ? error.getBackendError() : undefined,
      }),
    successActionCreator: (tasks) =>
      getTasksByOrderIdSuccess({ orderId, tasks }),
  });

/**  update a task
 @param taskId
 @param APITaskUpdatePutBody
 */
export const updateTaskStatus = (
  taskId: ID,
  body: APITaskUpdatePutBody
): Thunk => (dispatch) => {
  dispatch(putDelegationStatusStarted());

  PUT<RawAPIUpdatedEntities>(`v1/tasks/${taskId}`, body)
    .then(mapRawUpdatedEntities)
    .then(
      (updatedEntities) => {
        dispatch(putDelegationStatusSuccess(updatedEntities));
      },
      (error) => {
        dispatch(
          putDelegationStatusFailure(
            error instanceof ApiError ? error.getBackendError() : undefined
          )
        );
      }
    );
};
