import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes as Action } from '../../actionTypes';

type RevenueDeletes = Partial<Record<string, remoteData.RemoteAction>>;

function revenueDeleteReducer(state: RevenueDeletes = {}, action: Action) {
  switch (action.type) {
    case 'DELETE_REVENUE_STARTED': {
      return { ...state, [action.payload.requestId]: remoteData.loading };
    }
    case 'DELETE_REVENUE_FAILURE': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.fail(action.payload.error),
      };
    }
    case 'DELETE_REVENUE_SUCCESS': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.succeed(undefined),
      };
    }
    default:
      return state;
  }
}

export function getRevenueDeleteRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    revenues: {
      deleteRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export default revenueDeleteReducer;
