import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';

import { IconCloseWhite } from '../../assets/svg';

import { AppContext } from '../../context';
import { IconButton } from '../Buttons';

const Content = styled.div`
  margin-left: ${(props) => props.theme.margin[4]};

  border-left: ${(props) => props.theme.color.black} 3px solid;

  padding-left: ${(props) => props.theme.margin[4]};

  width: 40vw;
  height: 60%;

  display: flex;
  flex-direction: column;

  flex-grow: 1;

  background: ${(props) => props.theme.color.embedBackground};
`;

const TableContainer = styled.div`
  margin-left: ${(props) => props.theme.margin[4]};

  border-top: 3px solid ${({ theme }) => theme.color.rowBorder};
  border-left: ${(props) => props.theme.color.black} 3px solid;

  padding-left: ${(props) => props.theme.margin[4]};

  width: 40vw;
  height: 40%;

  display: flex;
  flex-direction: column;

  flex-grow: 1;

  overflow-y: scroll;
`;

const PaddedIconButton = styled(IconButton)`
  margin: ${(props) => props.theme.margin[8]};

  border-radius: ${(props) => props.theme.margin[4]};

  width: ${(props) => props.theme.margin[40]};
  height: ${(props) => props.theme.margin[40]};

  display: flex;
  justify-content: center;

  background-color: ${(props) => props.theme.color.black};
`;

type OuterBarProps = PropsWithChildren<{
  linesTable?: React.ReactNode;
  onClose: () => void;
}>;

const OuterBar = ({ children, onClose, linesTable }: OuterBarProps) => (
  <AppContext.Consumer>
    {(context) => {
      return (
        context.outerBarContainer &&
        ReactDOM.createPortal(
          <>
            <Content>
              <PaddedIconButton onClick={onClose} icon={IconCloseWhite} />
              {children}
            </Content>
            <TableContainer>{linesTable}</TableContainer>
          </>,
          context.outerBarContainer
        )
      );
    }}
  </AppContext.Consumer>
);

export default OuterBar;
