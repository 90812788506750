import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import { BackendError } from '../../utils/api';
import { flow } from '../../utils/function';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

export type APIProject = {
  id: ID;
  companyId: ID;
  name: string;
  code: string;
  status: string;
  isClosed: boolean;
  externalUrl?: string | null;
  procurementAreaIds: ID[];
  workPackageIds: ID[];

  receivedTotal: Big;
  targetTotal: Big;
  additionalTargetTotal: Big;
  costPredictionTotal: Big;
  contractTotal: Big;
  changeOrdersTotal: Big;
  reservesTotal: Big;
  revenueTotal: Big;

  latestSnapshotTargetTotal: Big;
  latestSnapshotCostsTotal: Big;
  latestSnapshotContractTotal: Big;
  latestSnapshotChangeOrdersTotal: Big;
  latestSnapshotReservesTotal: Big;
  latestSnapshotRevenueTotal: Big;

  targetChangeFromLatest: Big;
  costPredictionChangeFromLatest: Big;
  contractChangeFromLatest: Big;
  changeOrdersChangeFromLatest: Big;
  reservesChangeFromLatest: Big;
  revenuePredictionChangeFromLatest: Big;
};

export type ProjectState = remoteData.RemoteData<
  Partial<Record<string, APIProject>>,
  BackendError | undefined
>;

const initialState: ProjectState = remoteData.notAsked;

const projectReducer: Reducer<ProjectState, ActionTypes> = (
  state = initialState,
  action
): ProjectState => {
  switch (action.type) {
    case 'GET_PROJECTS_STARTED': {
      return remoteData.loading;
    }
    case 'GET_PROJECTS_FAILURE': {
      return remoteData.fail(undefined);
    }
    case 'GET_PROJECTS_SUCCESS': {
      const projects = action.payload;
      const normalizedProjects = normalizeBy('id', projects);

      return remoteData.succeed(normalizedProjects);
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { projects: updatedProjects = [] } = action.payload;

    if (state.kind !== 'Success' || updatedProjects.length < 1) {
      return state;
    }

    const previousValues = state.value;

    const updatedState = updatedProjects.reduce((nextState, project) => {
      const { id } = project;

      return {
        ...nextState,
        [id]: project,
      };
    }, previousValues);

    return { ...state, value: updatedState };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default projectReducer;

export const getProjectById = (projectId: string) => ({
  projects,
}: AppState): APIProject | undefined => {
  if (projects.kind !== 'Success') {
    return undefined;
  }

  const project = projects.value[projectId];

  return project;
};

export const selectProjects = () => ({
  projects: remoteProjects = remoteData.notAsked,
}: AppState) => remoteProjects;

export const getProject: (
  projectId: string
) => (appState: AppState) => remoteData.RemoteData<APIProject | undefined> = (
  projectId
) =>
  flow(selectProjects(), (remoteProjects) =>
    remoteData.map(remoteProjects, (projects) => projects[projectId])
  );

export const getProjects = (state: AppState) => state.projects;
