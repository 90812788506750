import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/schedule/projectTimeline';

const actionCreators = {
  ...makeAction('getWorkPackageReceivedTimelinesStarted')<{
    workPackageId: string;
  }>(),
  ...makeAction('getWorkPackageReceivedTimelinesSuccess')<{
    workPackageId: string;
    workPackageReceivedTimelines: WorkPackageReceivedTimelineEntry[];
  }>(),
  ...makeAction('getWorkPackageReceivedTimelinesFailure')<{
    workPackageId: string;
    error: BackendError | undefined;
  }>(),
};

export type WorkPackageReceivedTimelineAction = ExtractActionTypes<
  typeof actionCreators
>;

const apiWorkPackageReceivedTimelineEntryType = t.exact(
  t.type({
    projectId: t.string,
    snapshotId: t.union([t.string, t.null]),
    workPackageId: t.union([t.string, t.null]),
    date: dateString,
    percentageOfCompletion: bigString,
  })
);

export type WorkPackageReceivedTimelineEntry = t.TypeOf<
  typeof apiWorkPackageReceivedTimelineEntryType
>;

const {
  getWorkPackageReceivedTimelinesStarted,
  getWorkPackageReceivedTimelinesSuccess,
  getWorkPackageReceivedTimelinesFailure,
} = actionCreators;

export async function toWorkPackageReceivedTimelineEntries(
  u: unknown
): Promise<WorkPackageReceivedTimelineEntry[]> {
  const targetRows = await tPromise.decode(
    t.array(apiWorkPackageReceivedTimelineEntryType),
    u
  );

  return targetRows;
}

const requestWorkPackageReceivedTimelineEntries = async ({
  workPackageId,
}: {
  workPackageId: string;
}): Promise<WorkPackageReceivedTimelineEntry[]> => {
  const response = await GET<WorkPackageReceivedTimelineEntry[]>(
    `v1/work-packages/${workPackageId}/received-timelines`
  );

  return toWorkPackageReceivedTimelineEntries(response);
};

export const getWorkPackageReceivedTimelineEntries = ({
  workPackageId,
}: {
  workPackageId: string;
}) =>
  createAsyncThunk(requestWorkPackageReceivedTimelineEntries, {
    args: [{ workPackageId }],
    initialAction: getWorkPackageReceivedTimelinesStarted({ workPackageId }),
    isPending: isLoading(workPackageId),
    failureActionCreator: (error) =>
      getWorkPackageReceivedTimelinesFailure({
        workPackageId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (workPackageReceivedTimelines) =>
      getWorkPackageReceivedTimelinesSuccess({
        workPackageId,
        workPackageReceivedTimelines,
      }),
  });
