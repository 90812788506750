import { ID } from '../../../types/general';

import { ExtractActionTypes, makeAction } from '../../../utils/actionCreators';
import {
  BackendError,
  GET,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { Thunk, createAsyncThunk } from '../../../utils/thunk';

import { selectProcurementReferenceNumberRequests } from '../../reducers/order/procurementReferenceNumber';

export type ProcurementReferenceNumberAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('getProcurementReferenceNumberStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getProcurementReferenceNumberSuccess')<{
    projectId: string;
    procurementReferenceNumber: string;
  }>(),
  ...makeAction('getProcurementReferenceNumberFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('clearProcurementReferenceNumber')<{
    projectId: string;
  }>(),
};

export const {
  getProcurementReferenceNumberStarted,
  getProcurementReferenceNumberSuccess,
  getProcurementReferenceNumberFailure,
  clearProcurementReferenceNumber,
} = actionCreators;

const getProcurementReferenceNumber = (projectId: string) =>
  GET<string>(`v1/projects/${projectId}/new-procurement-reference-number`);

export const fetchProcurementReferenceNumberForProject = (
  projectId: ID
): Thunk =>
  createAsyncThunk(getProcurementReferenceNumber, {
    args: [projectId],
    isPending: flow(
      selectProcurementReferenceNumberRequests(projectId),
      remoteData.isLoading
    ),
    initialAction: getProcurementReferenceNumberStarted({ projectId }),
    successActionCreator: (procurementReferenceNumber) =>
      getProcurementReferenceNumberSuccess({
        projectId,
        procurementReferenceNumber,
      }),
    failureActionCreator: (error) =>
      getProcurementReferenceNumberFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
