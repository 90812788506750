import React from 'react';

import styled from 'styled-components';

import ArrowIcon from '../../../components/ArrowIcon';
import Cell from '../../../components/Cell';
import { PrimaryRow } from '../../../components/Table';

import { routes, useParams } from '../../../routes';
import { Analysis } from './Analysis';
import { ControlRow } from './ControlRow';

type StyledPrimaryRowProps = {
  isActive: boolean;
};

const StyledPrimaryRow = styled(PrimaryRow)<StyledPrimaryRowProps>`
  border-bottom: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.color.rowBorderActive : theme.color.rowBorder};
`;

type CellTdProps = {
  paddingLeft?: number;
};

const StyledCell = styled(Cell)<CellTdProps>`
  padding-left: ${({ paddingLeft, theme }) =>
    paddingLeft !== undefined ? `${paddingLeft}rem;` : theme.margin[8]};
  background-color: ${({ theme }) => theme.color.white};
`;

type AnalysisGroupProps = {
  id: string;
  name: string;
  dynamicColumnHeaderNames: string[];
  dynamicColumnAttributeIds: string[];
  isOrderRowField: boolean;
  isPaymentProgramRowField: boolean;
  isTargetRowField: boolean;
};

export const AnalysisGroup = ({
  id,
  name,
  dynamicColumnHeaderNames,
  dynamicColumnAttributeIds,
  isOrderRowField,
  isPaymentProgramRowField,
  isTargetRowField,
}: AnalysisGroupProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { viewMode = 'view' } = useParams(routes.ANALYSIS);
  const toggleRowOpen = () => setIsOpen(!isOpen);

  // NB: placeholders: These should be reported by the backend
  const revenuesIfSelectedForAnalysisItems = '';
  const expensesIfSelectedForAnalysisItems = '';
  const targetIfSelectedForAnalysisItems = '';
  const receivedIfSelectedForAnalysisItems = '';

  return (
    <>
      <StyledPrimaryRow
        onClick={toggleRowOpen}
        isActive={isOpen}
        data-testid={`analysis-group-row-${id}`}
      >
        <Cell />
        <Cell>
          <ArrowIcon
            isOpen={isOpen}
            openAltTextKey="analysis.table.mainRow.open"
            closedAltTextKey="analysis.table.mainRow.closed"
          />
        </Cell>
        <Cell>{name}</Cell>
        <Cell align="right">{revenuesIfSelectedForAnalysisItems}</Cell>
        <Cell align="right">{expensesIfSelectedForAnalysisItems}</Cell>
        <Cell align="right">{targetIfSelectedForAnalysisItems}</Cell>
        <Cell align="right">{receivedIfSelectedForAnalysisItems}</Cell>
        <Cell />
        <Cell />
      </StyledPrimaryRow>

      {isOpen ? (
        <>
          <PrimaryRow>
            <StyledCell paddingLeft={0} colSpan={9}>
              <Analysis
                analysisGroupId={id}
                dynamicColumnHeaderNames={dynamicColumnHeaderNames}
                dynamicColumnAttributeIds={dynamicColumnAttributeIds}
                isOrderRowField={isOrderRowField}
                isPaymentProgramRowField={isPaymentProgramRowField}
                isTargetRowField={isTargetRowField}
              />
            </StyledCell>
          </PrimaryRow>
          {viewMode === 'edit' ? <ControlRow analysisGroupId={id} /> : null}
        </>
      ) : null}
    </>
  );
};
