import { combineReducers } from 'redux';

import changeLogEntries from './changeLogEntries';
import hierarchies from './hierarchy';
import moveTargetRows from './moveTargetRows';
import selection from './selection';
import targetRows from './targetRows';

export default combineReducers({
  targetRows,
  selection,
  moveTargetRows,
  hierarchies,
  changeLogEntries,
});
