import React from 'react';

import { v1 as uuid } from 'uuid';

import Cell from '../../../../components/Cell';
import {
  Table,
  TableHeader,
  SecondaryRow,
  StickyHeaderTh,
} from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import * as big from '../../../../utils/big';
import { containsPercentage } from '../../../../utils/general';
import { ParsedRevenueResultRow } from './utils';

import { ErrorText, InvalidityIndicator } from './Components';

type Props = {
  rows: ParsedRevenueResultRow[];
};

const PreviewTable = ({ rows }: Props) => (
  <Table>
    <TableHeader>
      <tr>
        <StickyHeaderTh width="3.5rem">
          <Txt id="common.code" />
        </StickyHeaderTh>
        <StickyHeaderTh width="auto">
          <Txt id="common.description" />
        </StickyHeaderTh>
        <StickyHeaderTh width="auto" textAlign="right">
          <Txt id="common.quantity" />
        </StickyHeaderTh>
        <StickyHeaderTh width="auto">
          <Txt id="common.unit.short" />
        </StickyHeaderTh>
        <StickyHeaderTh width="auto" textAlign="right">
          <Txt id="common.unitPrice" />
        </StickyHeaderTh>
        <StickyHeaderTh width="auto" textAlign="right">
          <Txt id="common.amount" />
        </StickyHeaderTh>
        <StickyHeaderTh width="auto" textAlign="right">
          <Txt id="revenue.table.header.vat" />
        </StickyHeaderTh>
        <StickyHeaderTh width="auto" textAlign="right">
          <Txt id="revenue.table.header.billingDate" />
        </StickyHeaderTh>
      </tr>
    </TableHeader>
    <tbody>
      {rows.map((row, rowNumber) => {
        /*
           The rows don't have proper ID's yet and using index as a key would
           cause issues. Let's generate key with UUID as we don't expect the
           input to be edited manually in the text area. It should not
           affect performance too much.
        */

        const rowKey = `preview-row-${uuid()}`;

        if (row === null) {
          return (
            <SecondaryRow key={rowKey} data-testid={`row-${rowNumber}`}>
              <Cell>{rowNumber + 1}</Cell>
              <Cell colSpan={5}>
                <ErrorText>
                  <Txt id="order.excelImport.previewTable.error" />
                </ErrorText>
              </Cell>
            </SecondaryRow>
          );
        }

        const quantityAsBig = big.fromInputString(row.quantity.value, 0);
        const unitPriceAsBig = big.fromInputString(row.unitPrice.value, 0);

        const displayQuantity =
          row.quantity.value !== '' && row.quantity.validationError === null;

        const displayUnitPrice =
          row.unitPrice.value !== '' && row.unitPrice.validationError === null;

        let vatAsBig = big.fromInputString(row.vat.value, 0);

        if (vatAsBig.lt(1) && !containsPercentage(row.vat.value)) {
          vatAsBig = vatAsBig.mul(100);
        }

        return (
          <SecondaryRow key={rowKey} data-testid={`row-${rowNumber}`}>
            <Cell>
              {row.code.value}
              <InvalidityIndicator field={row.code} />
            </Cell>
            <Cell>
              {row.description.value}
              <InvalidityIndicator field={row.description} />
            </Cell>
            <Cell align="right">
              {displayQuantity ? big.amountFormat(quantityAsBig) : '-'}
              <InvalidityIndicator field={row.quantity} showValue />
            </Cell>

            <Cell>
              {row.unit.value}
              <InvalidityIndicator field={row.unit} />
            </Cell>

            <Cell align="right">
              {displayUnitPrice ? big.priceFormat(unitPriceAsBig, 4) : '- €'}
              <InvalidityIndicator field={row.unitPrice} showValue />
            </Cell>
            <Cell align="right">
              {displayQuantity && displayUnitPrice
                ? big.priceFormat(quantityAsBig.mul(unitPriceAsBig))
                : '- €'}
            </Cell>
            <Cell align="right">
              {vatAsBig.lt(1)
                ? big.percentageFormat(vatAsBig, 2)
                : big.percentageFormat(vatAsBig, 0)}
              <InvalidityIndicator field={row.vat} />
            </Cell>
            <Cell align="right">
              {row.billingDate.value}
              <InvalidityIndicator field={row.billingDate} />
            </Cell>
          </SecondaryRow>
        );
      })}
    </tbody>
  </Table>
);

export default PreviewTable;
