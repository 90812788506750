import React from 'react';

import styled from 'styled-components';

import { SelectOption } from '@customtypes/ui';

import {
  statusToId,
  LogEntryAction,
} from '../../../store/actions/order/changeLogEntries';
import {
  targetLogStatusToId,
  TargetLogEntryAction,
} from '../../../store/actions/target/changeLogEntries';

import useTxt from '../../../hooks/useTxt';

import { IconButton, IconTextButton } from '../../../components/Buttons';
import FilterSettings from '../../../components/MultiSelect/MultiSelect';

import { IconCloseBlack, IconDelete } from '../../../assets/svg';

import { TextId } from '../../../localization';

type FilterDropDownProps = {
  setFilterValue: ({
    key,
    value,
  }: {
    key: 'order' | 'target';
    value: string | null;
  }) => void;
  filterValue: { order: string[]; target: string[] };
  toggleFilter: () => void;
};

export const FilterDropdown = ({
  setFilterValue,
  filterValue,
  toggleFilter,
}: FilterDropDownProps) => {
  const orderOptions = Object.keys(statusToId) as LogEntryAction[];

  const targetOptions = Object.keys(
    targetLogStatusToId
  ) as TargetLogEntryAction[];

  const onChangeWithKey = ({
    change,
    key,
  }: {
    change: SelectOption;
    key: 'order' | 'target';
  }) => {
    const { name } = change;

    const lastPartWithoutShort = name.replace('.short', '');

    const lastPart = lastPartWithoutShort.substring(
      lastPartWithoutShort.lastIndexOf('.') + 1
    );

    setFilterValue({ key, value: lastPart });
  };

  const onChangeOrderColumn = (change: SelectOption) => {
    onChangeWithKey({ change, key: 'order' });
  };

  const onChangeTargetColumn = (change: SelectOption) => {
    onChangeWithKey({ change, key: 'target' });
  };

  const onRemoveSelections = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterValue({ key: 'order', value: null });
  };

  const textStart = 'comments.modal.changeStatus.';

  const title = useTxt('comments.modal.changes.filter.multiselect.header');
  const deleteText = useTxt('common.removeSelections');
  const orderRowHeader = useTxt('header.orderRow');
  const targetHeader = useTxt('common.target');

  return (
    <FilterDiv
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <FlexColumn>
        <HeaderFlexRow>
          <Header>{title}</Header>
          <RemoveFilterDiv>
            <IconTextButton
              icon={IconDelete}
              onClick={(e) => {
                onRemoveSelections(e);
              }}
            >
              {deleteText}
            </IconTextButton>
            <StyledIconButton icon={IconCloseBlack} onClick={toggleFilter} />
          </RemoveFilterDiv>
        </HeaderFlexRow>
        <FlexRow>
          <FlexColumn>
            <FilterHeaderDiv>{orderRowHeader}</FilterHeaderDiv>
            <FilterSettings
              toggleShow={() => {}}
              listItems={{
                listItemsArray:
                  orderOptions.map((d) => ({
                    id: d,
                    name: `${textStart}${d}.short` as TextId,
                    selected: filterValue.order.includes(d),
                  })) ?? [],
              }}
              onChange={onChangeOrderColumn}
              container={MultiSelectContainer}
              noListBorder
            />
          </FlexColumn>
          <FlexColumn>
            <FilterHeaderDiv>{targetHeader}</FilterHeaderDiv>
            <FilterSettings
              toggleShow={() => {}}
              listItems={{
                listItemsArray:
                  targetOptions.map((d) => ({
                    id: d,
                    name: `${textStart}${d}.short` as TextId,
                    selected: filterValue.target.includes(d),
                  })) ?? [],
              }}
              onChange={onChangeTargetColumn}
              container={MultiSelectContainer}
              noListBorder
            />
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </FilterDiv>
  );
};

const StyledIconButton = styled(IconButton)`
  margin-left: ${({ theme: { margin } }) => margin[16]};
`;

const FilterDiv = styled.div`
  position: absolute;
  top: ${(props) => props.theme.margin[24]};
  right: 20%;

  border: 1px solid lightgrey;
  border-radius: 5px 0 5px 2px;
  box-shadow: 5px 7px 11px -12px ${(props) => props.theme.color.black};

  background: ${(props) => props.theme.color.graphiteB99};

  z-index: 10;
`;

const Header = styled.div`
  width: 40%;
  font-weight: bold;
`;

const RemoveFilterDiv = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
`;

const HeaderFlexRow = styled.div`
  margin: ${(props) => props.theme.margin[16]};

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
`;

const FlexColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MultiSelectContainer = styled.div`
  min-width: 16rem;
`;

const FilterHeaderDiv = styled.div`
  margin-left: ${(props) => props.theme.margin[16]};
  text-align: left;
`;
