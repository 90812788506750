import React, {
  useContext,
  // useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';

import { IconButton, SvgComponent } from '../components/Buttons';

const ToggleContext = React.createContext({
  on: false,
  toggle: () => {},
});

export const useToggleContext = () => {
  const context = useContext(ToggleContext);

  if (!context) {
    throw new Error(`Toggle context not available`);
  }

  return context;
};

type ToggleProps = {
  children: React.ReactNode;
};

export const Toggle = ({ children }: ToggleProps) => {
  const [on, setOn] = useState(false);
  const toggle = useCallback(() => setOn((oldOn) => !oldOn), []);

  const value = useMemo(() => ({ on, toggle }), [on, toggle]);

  return (
    <ToggleContext.Provider value={value}>{children}</ToggleContext.Provider>
  );
};

type ToggleButtonProps = {
  icon: SvgComponent;
  label?: string;
  onClick: (e: React.SyntheticEvent) => void;
};

export const ToggleButton = ({ label, icon, onClick }: ToggleButtonProps) => {
  const { toggle } = useToggleContext();

  const handleToggleBtnClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onClick(e);
    toggle();
  };

  return (
    <IconButton icon={icon} onClick={handleToggleBtnClick} aria-label={label} />
  );
};
