import React from 'react';
import * as RI from 'react-intl';

import * as localization from '../localization';

export type TxtProps = {
  id: localization.TextId;
  values?: React.ComponentProps<typeof RI.FormattedMessage>['values'];
  component?: React.ComponentType | keyof React.ReactHTML;
  props?: React.Attributes;
};

/**
 * Component for translations. This component exists to ensure
 * typechecking of localization keys.
 * Component prop is meant to be used for styling.
 * <Txt id="someId" component="b" />
 */
const Txt = ({ id, values, component = React.Fragment, props }: TxtProps) => (
  <RI.FormattedMessage id={id} values={values}>
    {(text) => React.createElement(component, props, text)}
  </RI.FormattedMessage>
);

export default Txt;
