export const en = {
  'form.inputError.isRequired': 'Required field',
  'form.inputError.maxLength': 'The maximum length is 200 characters',
  'form.inputError.startDateError':
    'Start date can not be after end date below',
  'form.inputError.endDateError': 'End date can not be before start date above',
  'form.inputError.codeAlreadyExists':
    'Code is unavailable, please use a different code',

  'analysis.header': 'Analysis',

  'navigation.project': 'Project',
  'navigation.worksection': 'Work sections',
  'navigation.revenue': 'Revenue',
  'navigation.analysis': 'Analysis',
  'navigation.reporting': 'Reporting',
  'navigation.target': 'Target',
  'navigation.notifications': 'Notifications',
  'navigation.notifications.all': 'All notifications',
  'navigation.notifications.unread': 'Unread notifications',
  'navigation.notifications.noNotifications': 'No notifications',
  'navigation.notifications.new': 'New! ',
  'navigation.notifications.defaultDescription': 'Notification',
  'navigation.notifications.description.unhandledInvoice': 'Unhandled invoice',
  'navigation.notifications.description.unhandledActualCost':
    'Unhandled other expense',
  'navigation.notifications.description.reclaimedInvoices': 'Claimed invoices',
  'navigation.notifications.description.newMessage': 'New message to package',
  'navigation.notifications.description.declinedInvoice': 'Declined invoices',
  'navigation.notifications.description.canceledActualCost':
    'Canceled other expense',
  'navigation.notifications.description.invoiceNeedingRehandling':
    'Invoice needs rehandling',
  'navigation.notifications.description.delegationRequest':
    'Delegation request',
  'navigation.notifications.description.delegationResponse':
    'Delegation response',
  'navigation.notifications.description.invoicesBeingCorrected':
    'Invoices being corrected',
  'navigation.notifications.eventsForHandledInvoices':
    'Events for handled invoices',
  'navigation.notifications.unprocessedInvoices':
    'Unprocessed invoices or other expenses',
  'navigation.notifications.eventsForHandledInvoices.toolTip':
    'Show invoices that have complaint pending, that have been rejected by external system after processing or need to be rehandled or are currently being corrected within this system',
  'navigation.notifications.unprocessedInvoices.toolTip':
    'Shows invoices ordered by due date (oldest first)',
  'navigation.notifications.delegation.toolTip':
    'Shows invoices delegated to you or responses to your delegations',
  'navigation.notifications.messages': 'Messages and mentions',
  'navigation.notifications.justNow': 'Just now',
  'navigation.notifications.ago': 'ago',
  'navigation.notifications.minutes': 'mins',
  'navigation.notifications.hours': 'hrs',
  'navigation.notifications.invoice': 'Invoice',
  'navigation.notifications.expense': 'Expence',
  'navigation.notifications.registrationDate': 'Registration Date',
  'navigation.notifications.delegationRequest': 'Delegation request',
  'navigation.notifications.delegationResponse': 'Delegation response',
  'navigation.notifications.delegated': 'Delegated',
  'navigation.notifications.delegationResponse.reject': 'Invoice invalid',
  'navigation.notifications.delegationResponse.accept': 'Invoice ok',
  'navigation.notifications.delegationResponse.noInformation': 'No information',
  'navigation.notifications.externalChangeNotifications':
    'Changes from external systems',
  'navigation.notifications.externalChangeNotifications.targetRows':
    'External target change',
  'navigation.notifications.externalChangeNotifications.newOrders':
    'New orders',
  'navigation.notifications.externalChangeNotifications.removedOrders':
    'Removed orders',
  'navigation.notifications.externalChangeNotifications.editedOrders':
    'Edited orders',
  'navigation.notifications.externalChangeNotifications.checkOlderChanges':
    'Check older changes from reporting',

  'meta.none': ' ',
  'meta.empty': ' ',
  'meta.euros': '{euros} €',
  'meta.loading': 'Loading...',
  'meta.networkError': 'Network error',
  'meta.failure': 'Oops. Something went wrong',
  'meta.pleaseContact': 'Please contact sitedrivepay@sitedrive.com',
  'meta.noProjects': 'No Projects',
  'meta.pageNotFound': 'Page not found',

  'header.UOM': 'UOM',
  'header.orderRow': 'Order row',

  'analysis.button.edit': 'Edit',
  'analysis.button.new': 'New Analysis',
  'analysis.button.remove': 'Delete row',
  'analysis.table.header.empty': ' ',
  'analysis.table.header.code': 'Code',
  'analysis.table.header.delete': ' ',
  'analysis.table.header.none': ' ',
  'analysis.table.header.description': 'Description',
  'analysis.table.header.value': 'Row description',
  'analysis.table.header.paymentProgramRowsAmount': 'Revenue',
  'analysis.table.header.orderRowsAmount': 'Expenses',
  'analysis.table.header.targetRowsAmount': 'Target',
  'analysis.table.header.arrivalsAmount': 'Received',
  'analysis.table.header.attributeValues': '(Dynamic columns)',
  'analysis.table.header.status': 'Status',
  'analysis.table.mainRow.closed': 'Closed',
  'analysis.table.mainRow.open': 'Open',

  'analysis.orderRow.modal.title':
    '{analysisRowCode} - {analysisRowName} - Linked order rows',
  'analysis.targetRow.modal.title':
    '{analysisRowCode} - {analysisRowName} - Linked target rows',
  'analysis.received.modal.title':
    '{analysisRowCode} - {analysisRowName} - Invoices and Actual Costs',
  'analysis.received.modal.unassignedArrivals': 'Unassigned',
  'analysis.modal.header.description': 'Description',
  'analysis.received.modal.header.identifier': 'Code',
  'analysis.modal.header.order': 'Order',
  'analysis.modal.sum': 'Sum: {sum}',
  'analysis.modal.linkedEntity.altText':
    'Link to linked order row in Order view',
  'analysis.modal.unlink': 'Unlink',

  'common.cancel': 'Cancel',
  'common.save': 'Save',
  'common.total.short': 'Total',
  'common.send': 'Send',
  'common.total': 'Total',
  'common.description': 'Description',
  'common.quantity': 'Quantity',
  'common.unit.short': 'Unit',
  'common.unitPrice': 'Unit price',
  'common.amount': 'Amount',
  'common.received': 'Received',
  'common.analysis': 'Analysis',
  'common.toBeReceived': 'To be received',
  'common.row': 'Row',
  'common.clear': 'Clear',
  'common.receive': 'Receive',
  'common.dismiss': 'Dismiss',
  'common.reason': 'Reason',
  'common.showHide': 'Show/hide columns',
  'common.description.missing': 'Description missing',

  'common.code': 'Code',
  'common.remainingAmount': 'Remaining',
  'common.remainingAmountLong': 'Cost plan remaining',
  'common.status': 'Status',
  'common.unsettled': 'Unsettled',
  'common.status.Planned': 'Planned',
  'common.status.Accepted': 'Accepted',
  'common.status.Closed': 'Ready',
  'common.addRow': 'Add row',
  'common.importRowsFromTable': 'Import rows',
  'common.maxCharacters': 'Maximum characters allowed {value}',
  'common.increase': 'Increase',

  'common.openOrClose': 'Open / close',
  'common.removeSelections': 'Remove selections',

  'common.target': 'Target',
  'common.prediction': 'Cost plan',
  'common.reported': 'Reported',
  'common.currentAccepted': 'Current accepted',
  'common.planned': 'Planned',
  'common.target.difference': 'Difference to target',
  'common.predictionChangeFromLatest': 'Difference to previous',
  'common.contract': 'Contract',
  'common.changeOrders': 'Change orders',
  'common.reserves': 'Reserves',
  'common.orderedButNotReceived': 'Ordered but not received',
  'common.invoicesUnsettledCount': 'invoices unsettled',
  'common.invoiceCorrectionsCount': 'invoices being corrected',
  'common.invoiceComplaintsCount': 'invoices with complaints pending',
  'common.actualcostsPendingCount': 'other expenses pending',
  'common.workPackage': 'Work package',
  'common.name': 'Name',
  'common.workPackage.notFound': 'No matching work packages found',
  'common.paymentBatchGroup.notFound': 'No matching payment batch groups found',
  'common.paymentBatchGroup': 'Payment batch group',
  'common.noSelection': 'No selection',
  'common.choose': 'Select',

  'common.maxRowsExceeded':
    'The maximum number of rows that can be added at one time is {maxRows}',

  'common.orderRow': 'Order row',
  'common.targetRow': 'Target row',
  'common.newTopic': 'New topic',
  'common.moreWorkSections': 'Add new work sections',

  'common.status.ignore': 'Ignore',
  'common.status.yes': 'Yes',
  'common.status.no': 'No',
  'common.status.ok': 'Ok',
  'common.otherSections': 'Other sections',

  'common.orderStatus.1': 'Planned',
  'common.orderStatus.2': 'Contract',
  'common.orderStatus.3': 'Finalized',

  'common.workSection.icon.alt': 'Link to work section',

  'comments.modal.title.order': '{visibleCode}, {orderName}',
  'comments.modal.comments.submit': 'Submit',
  'comments.modal.comments.header': 'Comments',
  'comments.modal.comments.noComments': 'No comments',
  'comments.modal.comments.placeHolder':
    'Write a message. You can mention people using @.',
  'comments.modal.changes.header': 'Changes',
  'comments.modal.changes.predictionChangeBeforeLocking': 'Cost plan change: ',
  'comments.modal.changes.targetChangeBeforeLocking': 'Target change: ',
  'comments.modal.changes.arrivalsChangeBeforeLocking': 'Received: ',
  'comments.modal.changes.currentPeriod': 'Current',
  'comments.modal.changes.blank': ' ',
  'comments.modal.changes.orderRowNo': 'Row no',
  'comments.modal.changes.rowType': 'Type',
  'comments.modal.changes.user': 'User',
  'comments.modal.changes.description': 'Description',
  'comments.modal.changes.action': 'Action',
  'comments.modal.changes.filter': 'Filter',
  'comments.modal.changes.sumChange': 'Total difference',
  'comments.modal.changes.filter.multiselect.header': 'Filter Change Log',
  'comments.modal.changeStatus.editedBoth':
    'Order row edited (quantity: {quantityChangeInfo}, unit price: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedQuantity':
    'Order row edited (quantity: {quantityChangeInfo})',
  'comments.modal.changeStatus.editedUnitPrice':
    'Order row edited (unit price: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedNoInfo': 'Order row edited',
  'comments.modal.changeStatus.changeOfCosts': 'Order row edited',
  'comments.modal.changeStatus.rejected': 'Invoice rejected',
  'comments.modal.changeStatus.processed': 'Invoice processed',
  'comments.modal.changeStatus.newRow': 'Order row added',
  'comments.modal.changeStatus.deletion': 'Order row deleted',
  'comments.modal.changeStatus.orderChangeWithValues':
    'Order row moved {moveChangeInfo}',
  'comments.modal.changeStatus.orderChange': 'Order row moved between orders',
  'comments.modal.changeStatus.topicChange': 'Order row moved between topics',
  'comments.modal.changeStatus.movedToTopicWithValues':
    'Order row moved to topic {moveChangeInfo}',
  'comments.modal.changeStatus.statusChange': 'Order row status changed',

  'comments.modal.changeStatus.editedBoth.target':
    'Target row edited (quantity: {quantityChangeInfo}, unit price: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedQuantity.target':
    'Target row edited (quantity: {quantityChangeInfo})',
  'comments.modal.changeStatus.editedUnitPrice.target':
    'Target row edited (unit price: {unitPriceChangeInfo})',
  'comments.modal.changeStatus.editedNoInfo.target': 'Target row edited',
  'comments.modal.changeStatus.changeOfCosts.target': 'Target row edited',
  'comments.modal.changeStatus.newRow.target': 'Target row added',
  'comments.modal.changeStatus.deletion.target': 'Target row deleted',
  'comments.modal.changeStatus.orderChangeWithValues.target':
    'Target row moved {moveChangeInfo}',
  'comments.modal.changeStatus.orderChange.target':
    'Target row moved between orders',
  'comments.modal.changeStatus.topicChange.target':
    'Target row moved between topics',
  'comments.modal.changeStatus.movedToTopicWithValues.target':
    'Target row moved to topic {moveChangeInfo}',

  'comments.modal.changeStatus.changeOfCosts.short': 'Modified',
  'comments.modal.changeStatus.deletion.short': 'Deleted',
  'comments.modal.changeStatus.orderChange.short':
    'Moved between procurement packages',
  'comments.modal.changeStatus.topicChange.short': 'Moved between topics',
  'comments.modal.changeStatus.statusChange.short': 'Status changed',
  'comments.modal.changeStatus.newRow.short': 'Added',

  'component.AnalysisLiker.filter.placeholder':
    'Search by description or code...',

  'datePicker.months.Jan': 'Jan',
  'datePicker.months.Feb': 'Feb',
  'datePicker.months.Mar': 'Mar',
  'datePicker.months.Apr': 'Apr',
  'datePicker.months.May': 'May',
  'datePicker.months.Jun': 'Jun',
  'datePicker.months.Jul': 'Jul',
  'datePicker.months.Aug': 'Aug',
  'datePicker.months.Sep': 'Sep',
  'datePicker.months.Oct': 'Oct',
  'datePicker.months.Nov': 'Nov',
  'datePicker.months.Dec': 'Dec',

  'months.short.0': 'Jan',
  'months.short.1': 'Feb',
  'months.short.2': 'Mar',
  'months.short.3': 'Apr',
  'months.short.4': 'May',
  'months.short.5': 'Jun',
  'months.short.6': 'Jul',
  'months.short.7': 'Aug',
  'months.short.8': 'Sep',
  'months.short.9': 'Oct',
  'months.short.10': 'Nov',
  'months.short.11': 'Dec',

  'datePicker.labels.previousMonth': 'Previous month',
  'datePicker.labels.nextMonth': 'Next month',

  'datePicker.weekDaysShort.Mon': 'Mon',
  'datePicker.weekDaysShort.Tue': 'Tue',
  'datePicker.weekDaysShort.Wed': 'Wed',
  'datePicker.weekDaysShort.Thu': 'Thu',
  'datePicker.weekDaysShort.Fri': 'Fri',
  'datePicker.weekDaysShort.Sat': 'Sat',
  'datePicker.weekDaysShort.Sun': 'Sun',

  'datePicker.weekDaysLong.Mon': 'Monday',
  'datePicker.weekDaysLong.Tue': 'Tueday',
  'datePicker.weekDaysLong.Wed': 'Wedday',
  'datePicker.weekDaysLong.Thu': 'Thuday',
  'datePicker.weekDaysLong.Fri': 'Friday',
  'datePicker.weekDaysLong.Sat': 'Saturday',
  'datePicker.weekDaysLong.Sun': 'Sunday',

  'order.mainButtons.edit': 'Edit',
  'order.mainButtons.showComments': 'Comment',
  'order.mainButtons.showTarget': 'Show target',
  'order.mainButtons.showInfo': 'Show package info',
  'order.mainButtons.continueReception': 'Continue reception',
  'order.mainButtons.startReception': 'Start reception',

  'order.analysisTags.showMore': 'Show more',
  'order.analysisTags.add': 'Create',

  'order.editMode.saveAndExit': 'Save and exit',
  'order.editMode.toolBelt.remove': 'Delete order rows',
  'order.editMode.toolBelt.move': 'Move {count} selected order rows',
  'order.editMode.toolBelt.move.one': 'Move {count} selected order row',
  'order.editMode.toolBelt.add': 'Add topic',

  'order.editMode.moveModal.title':
    'Move selected order line ({amount} pc, total. {totalPrice})',
  'order.editMode.moveModal.filterLabel': 'Search content:',
  'order.editMode.moveModal.filterPlaceHolder':
    'Search by procurement package number, description or supplier...',

  'order.editMode.moveModal.scrollListTitle': 'Select procurement package',

  'order.editMode.moveModal.dropdownLabel': 'Move to topic',
  'order.editMode.moveModal.dropdownPlaceholder': 'Choose topic',
  'order.editMode.moveModal.dropdown.newTopic':
    'Create new topic with related work section ({workSections})',
  'order.editMode.moveModal.dropdown.multipleNewTopics':
    'Create new topics with related work sections ({workSections})',

  'order.editMode.moveModal.dropdown.selectionInfo':
    'You have selected a topic which relates to different work section',

  'order.editMode.delete.topic.success.notification':
    'Topic ({deletedTopicName}) was deleted. Target and order rows were moved automatically to ',

  'order.editMode.delete.topic.success.notification.after': ' ',

  'order.editMode.moveModal.button.action': 'Move to topic',
  'order.editMode.moveModal.noOtherOrders':
    'There are receipts for the selected order lines, only intra-order transfers are allowed',
  'order.editMode.moveModal.bottomInfo':
    'Chosen {orderRowCount} pcs of order lines for transfer, also linked target lines ({targetRowCount} pcs) will be moved',

  'order.excelImport.importRows': 'Import rows',
  'order.excelImport.instructions':
    'Insert copied rows from Excel in the input field below. Each copied row should have description, quantity, unit, unit price (in the same order)',
  'order.excelImport.error': 'Data entered has errors.',
  'order.excelImport.createRows': 'Import',
  'order.excelImport.previewTable.error':
    'Invalid row. Read the instructions above.',

  'order.receiveMode.showHandled': 'Show processed costs',
  'order.receiveMode.hideHandled': 'Hide processed costs',
  'order.receiveMode.exit': 'Exit receive mode',
  'order.receiveMode.arrival': 'arrival',
  'order.receiveMode.unitPriceMissing': 'Unit price is missing.',

  'order.receiveMode.invoice.add': 'Add to procurement package and receive',
  'order.receiveMode.cost.move': 'Transfer to another procurement package',
  'order.receiveMode.invoice.manualEntry': 'Create a manual accounting line',
  'order.receiveMode.invoice.decline': 'Discard invoice',
  'order.receiveMode.invoice.image.inNewTab': 'Open invoice image in new tab',
  'order.receiveMode.invoice.attachment.upload': 'Add invoice attachment',
  'order.receiveMode.invoice.attachment.upload.success':
    'Attachment upload was successful!',
  'order.receiveMode.invoice.attachment.upload.dragdrop':
    'Drag and drop files here or',
  'order.receiveMode.invoice.attachment.upload.button': 'Choose files',
  'order.receiveMode.invoice.attachment.upload.format':
    'File format PDF, maximum size 10 Mb.',
  'order.receiveMode.invoice.spread.arrival.rows': 'Split by codes',

  'order.receiveMode.invoice.delegate': 'Delegate invoice',
  'order.receiveMode.invoice.delegate.OpenInvoice': 'Open',
  'order.receiveMode.invoice.delegate.inProgress': 'in progress',
  'order.receiveMode.invoice.delegate.accepted': 'accepted',
  'order.receiveMode.invoice.delegate.rejected': 'rejected',
  'order.receiveMode.invoice.delegate.ignored': 'ignored',
  'order.receiveMode.invoice.delegate.reviewRequestToMe':
    'Review request from ({assignerName})',
  'order.receiveMode.invoice.delegate.modal.title': 'Review request',
  'order.receiveMode.invoice.delegate.reviewRequestFromMe': 'Request',
  'order.receiveMode.invoice.delegate.modal.description': 'Description',
  'order.receiveMode.invoice.delegate.modal.placeholder':
    'Write message to reviewer',
  'order.receiveMode.invoice.delegate.denyReason':
    'Explanation why the invoice is not ok?',
  'order.receiveMode.invoice.delegate.denyModalHeader': 'Invoice verification',
  'order.receiveMode.invoice.delegate.isInvoiceOkayText':
    'Is this invoice correct?',

  'order.receiveMode.invoice.delegate.modal.selectReviewers':
    'Select reviewers',

  'order.receiveMode.invoice.fileComplaint': 'Set complaint',
  'order.receiveMode.invoice.cancelComplaint': 'Cancel complaint',
  'order.receiveMode.invoice.startCorrection':
    'Make correction to invoice settling',
  'order.receiveMode.invoice.finishCorrection': 'Finish correction',
  'order.receiveMode.invoice.isOpen': 'Invoice opened',
  'order.receiveMode.invoice.isClosed': 'Invoice closed',
  'order.receiveMode.invoice.amount': 'Invoice amount:',
  'order.receiveMode.invoice.amountInclVAT': 'Amount incl. VAT:',
  'order.receiveMode.invoice.noArrivalRows':
    'No row have yet been assigned to invoice.',

  'order.receiveMode.invoiceTable.sectionTitle': 'Invoices',

  'order.receiveMode.invoiceTable.previous': 'Prev',
  'order.receiveMode.invoiceTable.next': 'Next',

  'order.receiveMode.moveInvoiceModal.errorModal':
    'The order or purchase package is not available, please try again.',
  'order.receiveMode.moveCostModal.title':
    'Transfer invoice to another procurement package',
  'order.receiveMode.moveCostModal.amount': 'Amount:',
  'order.receiveMode.moveCostModal.filterPlaceholder':
    'Search by procurement package number, contractor name, or keywords...',
  'order.receiveMode.moveCostModal.moveToOrder': 'Move',
  'order.receiveMode.moveCostModalTH.name': 'Name',
  'order.receiveMode.moveCostModalTH.visibleCode': 'Code',
  'order.receiveMode.moveCostModalTH.contractor': 'Contractor',

  'order.receiveMode.actualCost.add': 'Add to procurement package and receive',
  'order.receiveMode.actualCost.isOpen': 'Expense is open',
  'order.receiveMode.actualCost.isClosed': 'Expense is closed',
  'order.receiveMode.actualCost.amount': 'Expense amount:',
  'order.receiveMode.actualCost.noArrivalRows':
    'No rows have yet been allocated to the expense.',
  'order.receiveMode.actualCost.sectionTitle': 'Other expenses',
  'order.receiveMode.actualCost.image.inNewTab':
    'Open expense image in new tab',

  'order.receiveMode.arrivalRow.missingOrderRow': 'Missing order line',
  'order.receiveMode.arrivalRow.checkBoxLabel': 'Selected orders',
  'order.receiveMode.arrivalRow.notSelectedLabel': 'Orders',
  'order.receiveMode.arrivalRow.deleteButtonLabel': 'Delete receipt line',

  'order.receiveMode.arrivalRowTH.invoiceDate': 'Due date',
  'order.receiveMode.arrivalRowTH.actualCostDate': 'Posting date',
  'order.receiveMode.arrivalRowTH.chooseRow': 'Select line',
  'order.receiveMode.arrivalRowTH.chooseAll': 'Select all',
  'order.receiveMode.arrivalRowTH.arrivalAbbreviation': 'Rcpt',
  'order.receiveMode.arrivalRowTH.rowNo': 'Row',
  'order.receiveMode.arrivalRowTH.status': 'Status',
  'order.receiveMode.arrivalRowTH.statusShort': 'Status',
  'order.receiveMode.arrivalRowTH.supplier': 'Supplier',
  'order.receiveMode.arrivalRowTH.invoiceAmount': 'Invoice amount',
  'order.receiveMode.arrivalRowTH.quantity': 'Quantity',
  'order.receiveMode.arrivalRowTH.workSection': 'Work section',
  'order.receiveMode.arrivalRowTH.workSection.short': 'Section',
  'order.receiveMode.arrivalRowTH.unit': 'UOM',
  'order.receiveMode.arrivalRowTH.unitPrice': 'Unit price',
  'order.receiveMode.arrivalRowTH.amount': 'Received amount',
  'order.receiveMode.arrivalRowTH.date': 'Date',
  'order.receiveMode.arrivalRowTH.vatCode': 'Tax code',
  'order.receiveMode.arrivalRowTH.accountCode': 'Account code',
  'order.receiveMode.arrivalRowTH.options': 'Options',

  'order.receiveMode.assignmentTool.assign': 'Assign',
  'order.receiveMode.assignmentTool.rows': 'Rows,',
  'order.receiveMode.assignmentTool.amount': 'Quantity',
  'order.receiveMode.assignmentTool.unAssigned': 'Unassigned',
  'order.receiveMode.assignmentTool.toActualCost': 'Expense',
  'order.receiveMode.assignmentTool.toInvoice': 'Invoice',

  'order.receiveMode.complaintModal.confirmation':
    'Do you want to set the status of the invoice as “complaint pending”?',
  'order.receiveMode.complaintModal.information': `The invoice can be processed normally even with “complaint pending” -status`,
  'order.receiveMode.complaintModal.reason': 'Complaint reason :',

  'order.receiveMode.declineInvoiceModal.confirmation':
    'Confirm invoice decline',
  'order.receiveMode.declineInvoiceModal.information':
    'Declined invoice is sent to the finance team',
  'order.receiveMode.declineInvoiceModal.reason': 'Reason for rejection:',

  'order.receiveMode.invoiceCorrection.confirmation':
    'Confirm correction to invoice settling',
  'order.receiveMode.invoiceCorrection.information':
    'Corrected invoice settling information is sent to the finance team',
  'order.receiveMode.invoiceCorrection.reason': 'Reason for correction:',

  'order.receiveMode.invoiceStatus.notHandled': 'Unhandled',
  'order.receiveMode.invoiceStatus.handled': 'Processed {dateAndUser}',
  'order.receiveMode.invoiceStatus.canceled': 'Canceled {dateAndUser}',
  'order.receiveMode.invoiceStatus.paid': 'Paid {dateAndUser}',
  'order.receiveMode.invoiceStatus.approved': 'Approved {dateAndUser}',
  'order.receiveMode.invoiceStatus.complaintPending': 'Complaint {dateAndUser}',
  'order.receiveMode.invoiceStatus.beingCorrected':
    'Being corrected {dateAndUser}',
  'order.receiveMode.invoiceStatus.corrected': 'Corrected {dateAndUser}',
  'order.receiveMode.invoiceStatus.declined': 'Declined {dateAndUser}',

  'order.receiveMode.processingTool.process': 'Process',
  'order.receiveMode.invoice.success.notification':
    'Invoice settled. You can see the settled invoices by ',
  'order.receiveMode.invoice.success.notification.link':
    'opening processed orders',
  'order.receiveMode.actualCost.success.notification':
    'Actual cost settled. You can see the settled invoices by ',
  'order.receiveMode.actualCost.success.notification.link':
    'opening processed orders',

  'order.receiveMode.unassignedArrivalRows.searchPlaceholder':
    'Search invoices...',

  'order.receiveMode.unassignedArrivalRows.sectionTitle':
    'Unassigned receipts ― ',

  'order.receiveMode.utils.invoiceIsProcessable':
    'Invoice {identifier} is processable',
  'order.receiveMode.utils.invoiceTooManyArrivals':
    'Invoice {identifier} has too many receipts ({handleNetAmountPending})',
  'order.receiveMode.utils.invoiceTooFewArrivals':
    'Invoice {identifier} can not be processed, too few receipts ({handleNetAmountPending})',
  'order.receiveMode.utils.invoiceNoVatCode':
    'Invoice {identifier} can not be processed. Tax code is missing',
  'order.receiveMode.utils.invoiceNoAccountCode':
    'Invoice {identifier} can not be processed. Account is missing',
  'order.receiveMode.utils.invoiceNoWorkSection':
    'Invoice {identifier} can not be processed. Work section is not specified',
  'order.receiveMode.utils.invoiceNoCostType':
    'Invoice {identifier} can not be processed. Cost type is missing',
  'order.receiveMode.utils.undefinedHandlingError':
    'Invoice {identifier} can not be processed. Contact administrator',
  'order.receiveMode.utils.invoiceNoCodes':
    'Invoice {identifier} can not be processed. Both Tax code and account are missing',
  'order.receiveMode.utils.invoiceIncorrectVatAmount':
    'Invoice {identifier} is not processable. Sum of VAT of receipt lines differ from VAT of the invoice {handleVatAmountPending}',
  'order.receiveMode.utils.invoiceIsDeclined':
    'Invoice {identifier} is declined',
  'order.receiveMode.utils.invoiceHasComplaintPending':
    'A complaint is pending for the invoice {identifier} ',
  'order.receiveMode.utils.invoiceBeingCorrected':
    'Settling of invoice {identifier} is being corrected',
  'order.receiveMode.utils.invoiceCorrected':
    'Invoice {identifier} settling has been corrected afterwards by user',
  'order.receiveMode.utils.invoiceIsProcessed':
    'Invoice {identifier} has been processed',
  'order.receiveMode.utils.invoiceIsPaid': 'Invoice {identifier} is paid',
  'order.receiveMode.utils.invoiceIsApproved':
    'Invoice {identifier} is approved',
  'order.receiveMode.utils.actualCostIsProcessable':
    'Expense {identifier} is available',
  'order.receiveMode.utils.actualCostTooManyArrivals':
    'Expense {identifier} has too many receipts ({handleNetAmountPending})',
  'order.receiveMode.utils.actualCostTooFewArrivals':
    'Expense {identifier} is can not be processed. Too few receipts have been assigned for ({handleNetAmountPending})',
  'order.receiveMode.utils.actualCostIsProcessed':
    'Expense {identifier} has been processed',
  'order.receiveMode.utils.actualCostIsCanceled':
    'Expense {identifier} has been canceled by accounting team',

  'order.receiveMode.utils.invoiceNoArrivalRows':
    'Invoice {identifier} can not be processed. No receipts have been assigned.',
  'order.receiveMode.utils.actualCostNoArrivalRows':
    'Expense {identifier} can not be processed. No receipts have been assigned.',

  'order.targetMode.toolBelt.addTargetRows': 'Add target rows',
  'order.targetMode.toolBelt.splitTargetRows': 'Split target rows',
  'order.targetMode.toolBelt.addOrderRows': 'Add order rows from target',
  'order.targetMode.toolBelt.move': 'Move {count} selected target rows',
  'order.targetMode.toolBelt.move.one': 'Move {count} selected target row',
  'order.targetMode.toolBelt.divide': 'Share target',

  'order.targetMode.splitIcon.tooltip.disabled':
    'This row is split and only shown as extra information',
  'order.targetMode.splitIcon.tooltip.splitFrom':
    'This row is created as a result from splitting another row',

  'order.addTargetRowsModal.requiredField': 'Required field',
  'order.addTargetRowsModal.button.addNewRow': 'Add a new row',
  'order.addTargetRowsModal.button.submit': 'Create rows',
  'order.addTargetRowsModal.placeholder.description': 'Add a description',
  'order.addTargetRowsModal.placeholder.quantity': 'Quantity',
  'order.addTargetRowsModal.placeholder.unit': 'UOM',
  'order.addTargetRowsModal.placeholder.unitPrice': 'Unit price',
  'order.addTargetRowsModal.placeholder.order': 'Select order...',
  'order.addTargetRowsModal.placeholder.topic': 'Select topic...',
  'order.addTargetRowsModal.placeholder.analysis': 'Select analysis...',
  'order.addTargetRowsModal.tableHeader.none': ' ',
  'order.addTargetRowsModal.tableHeader.description': 'Description',
  'order.addTargetRowsModal.tableHeader.quantity': 'Quantity',
  'order.addTargetRowsModal.tableHeader.unit': 'UOM',
  'order.addTargetRowsModal.tableHeader.unitPrice': 'Unit price',
  'order.addTargetRowsModal.tableHeader.selectOrder':
    'Select procurement package: *',
  'order.addTargetRowsModal.tableHeader.selectTopic': 'Select topic: *',
  'order.addTargetRowsModal.tableHeader.selectAnalysis': 'Select analysis:',
  'order.addTargetRowsModal.tableHeader.alsoCreateOrderRows':
    'Also add order lines',
  'order.addTargetRowsModal.title': 'Create new target',
  'order.addTargetRowsModal.targetRowsSum': 'Total new target rows',
  'order.addTargetRowsModal.orderRowsSum': 'Total new order rows',

  'order.targetSplitModal.title': 'Split selected target and order rows',
  'order.targetSplitModal.title.remark':
    '(Created rows cannot be deleted afterwards)',
  'order.targetSplitModal.withoutOrderRowsTable.title':
    'Selected target rows with no order row related',
  'order.targetSplitModal.withOrderRowsTable.title':
    'Selected target rows with related order rows',
  'order.targetSplitModal.targetRowsSum': 'Total target rows',
  'order.targetSplitModal.orderRowsSum': 'Total order rows',
  'order.targetSplitModal.remainingAmount':
    'All selected target rows have not been split yet (Remaining {remainingAmount})',
  'order.targetSplitModal.remainingAmount.row': 'Remaining {remainingAmount}',
  'order.targetSplitModal.tooMuchArrivals':
    'Order rows have too much arrivals linked to them which cannot be split. Increase the order row amount or delete arrivals.',

  'order.targetSplitModal.tableHeader.none': ' ',
  'order.targetSplitModal.tableHeader.description': 'Description',
  'order.targetSplitModal.tableHeader.description2': ' ',
  'order.targetSplitModal.tableHeader.quantity': 'Quantity',
  'order.targetSplitModal.tableHeader.unit': 'UOM',
  'order.targetSplitModal.tableHeader.unitPrice': 'Unit price',
  'order.targetSplitModal.tableHeader.selectOrder':
    'Select procurement package: *',
  'order.targetSplitModal.tableHeader.selectTopic': 'Select topic: *',
  'order.targetSplitModal.tableHeader.selectAnalysis': 'Select analysis:',
  'order.targetSplitModal.tableHeader.total': 'Total price',

  'order.targetSplitModal.tooltip.orderRowTotal':
    'Total amount of this order row to be split: {orderRowAmount}',
  'order.targetSplitModal.tooltip.tooMuchArrivals':
    'Amount to be split from order row {toBeSplitAmount} should not exceed the difference ({difference}) of order row ({orderRowAmount}) and arrivals linked to it ({arrivalAmount}).',

  'order.targetSplitModal.tooltip.newOrderRow.total':
    'Amount of order row to be created: {orderRowAmount}',
  'order.targetSplitModal.button.addRest': 'Add remaining amount',

  'order.targetSplitModal.button.submit': 'Split rows',

  'order.targetMode.moveModal.title':
    'Move selected target rows ({amount} pc, total {totalPrice})',
  'order.targetMode.moveModal.filterLabel': 'Search content:',
  'order.targetMode.moveModal.filterPlaceHolder':
    'Search by order number, description or supplier...',

  'order.targetMode.moveModal.scrollListTitle': 'Select order',

  'order.targetMode.moveModal.dropdownLabel': 'Move to topic:',
  'order.targetMode.moveModal.dropdownPlaceholder': 'Select topic...',

  'order.targetMode.moveModal.button.action': 'Move to topic',

  'order.targetMode.moveModal.bottomInfo':
    'Note that only target rows will be moved to the selected order. You may want to add new order rows separately.',

  'order.manualEntryModal.description':
    'Create new invoice line for this invoice',
  'order.manualEntryModal.withoutAccount': 'Invoice without accounting:',
  'order.manualEntryModal.sum': 'Net accounting amount:',
  'order.manualEntryModal.submit': 'Create row',
  'order.manualEntryModal.vatCode': 'Tax code {required}',
  'order.manualEntryModal.account': 'Account code {required}',
  'order.manualEntryModal.requiredField': 'Required field',
  'order.manualEntryModal.sumTooLarge':
    'The amount entered must be less than the unaccounted invoice',
  'order.manualEntryModal.hasToBeNumber':
    'Enter number in the field, separated by a comma',
  'order.manualEntryModal.hasToBePositive': 'Entered amount must be positive',

  'order.invoicesPerOrderRowModal.title':
    '{orderRowNo} {orderRowDescription}, received total: {arrivalTotal}',
  'order.invoicesPerOrderRowModal.button.close': 'Close',

  'order.invoicesPerOrderRowModal.table.header.timestamp': 'Timestamp',
  'order.invoicesPerOrderRowModal.table.header.user': 'User',
  'order.invoicesPerOrderRowModal.table.header.amount': 'Amount',
  'order.invoicesPerOrderRowModal.table.header.invoice': 'Invoice',
  'order.invoicesPerOrderRowModal.table.header.link': 'Link to invoice',

  'order.invoicesPerOrderRowModal.table.linkIcon.alt':
    'Link to invoice or other expense',

  'order.options.formik.error.name.mandatory': 'Mandatory field',
  'order.options.formik.error.name.length': 'Maximum length is 200 characters',
  'order.options.formik.error.code.procurementAreaMatch':
    'Code has to start with same characters as Procurement Area selected',
  'order.options.formik.error.code.length': 'Maximum length is 10 characters',
  'order.options.formik.error.code.unique':
    'Code has to be unique within Project',
  'order.options.formik.error.code.mandatory': 'Mandatory field',
  'order.options.formik.error.code.onlyLettersAndNumbersAndDashes':
    'Only letters, numbers and dashes (-) allowed',

  'order.options.header.new': 'Create new procurement package',
  'order.options.header.tooltip':
    'Order reference is created automatically. Use it while ordering and ask the supplier to add it to the invoice as a reference. This way we can automatically assign the invoice to this purchase.',
  'order.options.header.edit': 'Basic procurement package information',
  'order.options.secondHeader.edit': 'Order reference',
  'order.options.secondHeader.tooltip':
    'Use the reference when ordering and ask the supplier to include it to the invoice as a reference. This way we can automatically assign the invoice to this purchase.',
  'order.options.header.edit.open': 'Basic order information is open',
  'order.options.header.edit.closed': 'Basic order information is closed',
  'order.options.visibleCode': 'Code',
  'order.options.name': 'Name of procurement package',
  'order.options.supplier': 'Supplier',
  'order.options.costType': 'Cost type',
  'order.options.vatCode': 'VAT code',
  'order.options.account': 'Account',
  'order.options.status': 'Procurement status',
  'order.options.header.delete': 'Procurement package deletion',
  'order.options.header.delete.open': 'Delete header is open',
  'order.options.header.delete.closed': 'Delete header is closed',
  'order.options.deleteButton': 'Delete order',
  'order.options.cannotBeDeleted':
    'Order cannot be deleted. Move / delete order lines, move target lines and report project before deleting',

  'order.invoiceLines.addButton': 'Add as order lines',
  'order.invoiceLines.headerTitle': 'E-invoice lines',
  'order.invoiceLines.chooseAll': 'Select all',
  'order.invoiceLines.header.chooseRow': 'Select row',
  'order.invoiceLines.header.rowNo': '#',
  'order.invoiceLines.header.productCode': 'Product code',
  'order.invoiceLines.header.description': 'Description',
  'order.invoiceLines.header.quantity': 'Quantity',
  'order.invoiceLines.header.unit': 'UOM',
  'order.invoiceLines.header.netPrice': 'Net price',
  'order.invoiceLines.header.netTotal': 'Net total',
  'order.invoiceLines.header.VAT': 'VAT',
  'order.invoiceLines.header.orderRow': 'Selected order line',
  'order.invoiceLines.row.selectRow': 'Select row',
  'order.invoiceLines.row.notSelectedLabel': 'Not selected order lines',
  'order.invoiceLines.row.newOrderRow': 'New order line',
  'order.invoiceLines.row.newOrderRows':
    'Create new order line for each invoice line',
  'order.invoiceLines.row.newOrderRowShortLabel': 'New',
  'order.invoiceLines.row.tooltip.received':
    'Line is already received ({orderRowDescription})',
  'order.invoiceLines.group.unassigned': 'No assignment',
  'order.invoiceLines.group.assigned': 'Invoice row regrouped under',
  'order.invoiceLines.group.assigned.after': ' ',
  'order.invoiceLines.selected': '{count} rows selected, {sum} in total',

  'order.actualCostLines.addButton': 'Add as order lines',
  'order.actualCostLines.headerTitle': 'Actual cost detail lines',
  'order.actualCostLines.chooseAll': 'Select all',
  'order.actualCostLines.header.chooseRow': 'Select row',
  'order.actualCostLines.header.rowNo': '#',
  'order.actualCostLines.header.description': 'Description',
  'order.actualCostLines.header.quantity': 'Quantity',
  'order.actualCostLines.header.unit': 'UOM',
  'order.actualCostLines.header.unitPrice': 'Unit price',
  'order.actualCostLines.header.netTotal': 'Net total',
  'order.actualCostLines.header.orderRow': 'Selected order line',
  'order.actualCostLines.row.selectRow': 'Select row',
  'order.actualCostLines.row.notSelectedLabel': 'Not selected order lines',
  'order.actualCostLines.row.newOrderRow': 'New order line',
  'order.actualCostLines.row.newOrderRows':
    'Create new order line for each invoice line',
  'order.actualCostLines.row.newOrderRowShortLabel': 'New',
  'order.actualCostLines.row.tooltip.received':
    'Line is already received ({orderRowDescription})',
  'order.actualCostLines.group.unassigned': 'No assignment',
  'order.actualCostLines.group.assigned':
    'Actual cost detail row regrouped under',
  'order.actualCostLines.group.assigned.after': ' ',

  'order.invoice.no.image': 'Invoice image is not available',

  'order.invoiceLines.convertModal.title':
    'Laskun {vendorInvoiceNo} ({amount}) electronic line receipt summary',
  'order.invoiceLines.convertModal.table.header.orderRow.rowNo': ' ',
  'order.invoiceLines.convertModal.table.header.orderRow.description':
    'Description',
  'order.invoiceLines.convertModal.table.header.orderRow.quantity': 'Quantity',
  'order.invoiceLines.convertModal.table.header.orderRow.amount': 'Amount',
  'order.invoiceLines.convertModal.table.header.arrow': ' ',
  'order.invoiceLines.convertModal.table.header.arrivalRow.quantity':
    'Quantity',
  'order.invoiceLines.convertModal.table.header.arrivalRow.amount': 'Amount',
  'order.invoiceLines.convertModal.table.header.orderRow.change': 'Difference',
  'order.invoiceLines.convertModal.table.header.orderRow.amountLeft':
    'Amount remaining',
  'order.invoiceLines.convertModal.table.uppertierheader.orderRows':
    'Order rows',
  'order.invoiceLines.convertModal.table.uppertierheader.arrivalRows':
    'Receipt rows',
  'order.invoiceLines.convertModal.table.topic.open': 'Topic is open',
  'order.invoiceLines.convertModal.table.topic.closed': 'Topic is closed',
  'order.invoiceLines.convertModal.table.arrow': 'Arrow',
  'order.invoiceLines.convertModal.table.footer.totalShortHand': 'Total',
  'order.invoiceLines.convertModal.table.footer.total': 'Invoice total',
  'order.invoiceLines.convertModal.table.footer.difference': 'difference',
  'order.invoiceLines.convertModal.newTopics':
    'New topics to be created for new work sections',
  'order.invoiceLines.convertModal.button.action': 'Mark received',
  'order.invoiceLines.convertModal.button.invalidPrice':
    'Order price can not be 0€',
  'order.invoiceLines.convertModal.invalidData': 'Invalid data, contact admin',
  'order.invoiceLines.convertModal.button.noRows': 'No rows selected',
  'order.invoiceLines.convertModal.button.invalidInvoiceStatus':
    'Invalid invoice status',
  'orderRow.status.Contract': 'Contract',
  'orderRow.status.Reserves': 'Reserves',
  'orderRow.status.ChangeOrder': 'CO',
  'orderRow.inputs.SelectRow': 'Select row',
  'orderRow.inputs.rowNumber': 'Row number',
  'orderRow.inputs.Title': 'Description',
  'orderRow.inputs.Amount': 'Quantity',
  'orderRow.inputs.Unit': 'UOM',
  'orderRow.inputs.UnitPrice': 'Unit price',
  'orderRow.inputs.ProgressPill': 'Receive remaining quantity',
  'order.inputs.Remove': 'Remove rows',

  'order.actualCostLines.convertModal.title':
    'Cost {entryNo} ({amount}) electronic line receipt summary',
  'order.actualCostLines.convertModal.button.action': 'Mark received',
  'order.actualCostLines.convertModal.table.uppertierheader.orderRows':
    'Order rows',
  'order.actualCostLines.convertModal.table.uppertierheader.arrivalRows':
    'Receipt rows',

  'order.topicRow.icon.alt': 'Link to topic of work step',
  'order.topicRow.Remove': 'Remove rows',

  'procurement.header': 'Procurement',

  'project.info.profit': 'Profit:',
  'project.info.profitChange': 'Profit margin change from previous:',

  'project.tooltip.PoC':
    'Current actual percentage of completion. The value is calculated from work amount and completion percentage of linked tasks in Sitedrive schedule',
  'project.tooltip.target': 'Sum of all target rows incl. additional target',
  'project.tooltip.additionalTarget':
    'Sum of target rows that were created in this system in addition to the original target',
  'project.tooltip.differenceToTarget':
    'Cost Plan subtracted from Target, variance',
  'project.tooltip.costPlan':
    'Total of order rows, forecast of eventual total cost',
  'project.tooltip.differenceToReported':
    'Change of Cost Plan from previous reported Cost Plan',
  'project.tooltip.contract': 'Total of order rows with contract status',
  'project.tooltip.changeOrders':
    'Total of order rows with Change order status',
  'project.tooltip.reserves': 'Total of order rows with Reserve status',
  'project.tooltip.accepted': 'Accepted total',
  'project.tooltip.remaining': 'Accepted total subtracted from Cost Plan',
  'project.tooltip.unsettled': 'Unsettled invoices or other expenses',
  'project.tooltip.status': 'Status of the order',

  'project.procurementTable.secondaryHeader.text': 'Filtered values',

  'project.procurementTable.order.status': 'Order status',
  'project.procurementTable.areas.open': 'All procurement entities are open',
  'project.procurementTable.areas.closed':
    'All procurement entities are closed',
  'project.procurementTable.header.functions': 'Options',

  'project.procurementTable.orders.asc': 'Orders ordered by ascending code',
  'project.procurementTable.orders.desc': 'Orders ordered by descending code',

  'project.procurementTable.order.number': 'Procurement code',
  'project.procurementTable.order.title': 'Description',
  'project.procurementTable.order.target': 'Target',
  'project.procurementTable.order.additionalTarget': 'Additional target',
  'project.procurementTable.order.differenceToTarget': 'Difference to target',
  'project.procurementTable.order.prediction': 'Cost Plan',
  'project.procurementTable.order.difference': 'Difference',
  'project.procurementTable.order.contractTotal': 'Contract',
  'project.procurementTable.order.changeOrdersTotal': 'Additional work',
  'project.procurementTable.order.reserves': 'Reserves',
  'project.procurementTable.order.receivedTotal': 'Recieved total',
  'project.procurementTable.order.unsettled': 'Unsettled',

  'project.procurementTable.procurementAreaRow': 'Add package',
  'project.procurementTable.procurementAreaRow.open':
    'Procurement area row open',
  'project.procurementTable.procurementAreaRow.closed':
    'Procurement area row closed',

  'target.table.header.empty': ' ',
  'target.table.header.spacer': ' ',
  'target.table.header.description': 'Description',
  'target.table.header.quantity': 'Quantity',
  'target.table.header.unit': 'UOM',
  'target.table.header.unitPrice': 'Unit price',
  'target.table.header.target': 'Target amount',
  'target.table.header.prediction': 'Cost Plan amount',
  'target.table.header.difference': 'Difference to target',
  'target.table.alt.suffix': 'Related order line',

  'reporting.header': 'Reporting',
  'reporting.button.addNewSnapshot': 'New report',
  'reporting.button.csvExport': 'Export data (csv)',
  'reporting.table.header.toggleButton': ' ',
  'reporting.table.header.timeStamp': 'Timestamp',
  'reporting.table.header.description': 'Description',
  'reporting.table.header.reportingPeriod': 'Reporting period',
  'reporting.table.header.userName': 'User',
  'reporting.table.header.percentageOfCompletion': 'Completion',
  'reporting.table.header.targetChangeBeforeLocking':
    'Diff to previous (Target)',
  'reporting.table.header.targetTotal': 'Target',
  'reporting.table.header.additionalTargetTotal': 'Additional target',
  'reporting.table.header.costPredictionTotal': 'Cost Plan',
  'reporting.table.header.predictionChangeBeforeLocking':
    'Diff to previous (Cost Plan)',
  'reporting.table.header.contractTotal': 'Contract',
  'reporting.table.header.changeOrdersTotal': 'Change orders',
  'reporting.table.header.reservesTotal': 'Reserves',
  'reporting.table.header.revenueTotal': 'Revenue',
  'reporting.table.header.profit': 'Profit',
  'reporting.table.header.profitChange': 'Difference in profit',

  'reporting.table.snapshot.open.alt': 'Snapshot open',
  'reporting.table.snapshot.closed.alt': 'Snapshot closed',
  'reporting.table.snapshot.order.link.alt': 'Link to order',

  'reporting.table.snapshot.tooltip.planned':
    '* Based on planned value for {timelineDate} (reported in {snapshotCreationDate})',
  'reporting.table.snapshot.tooltip.previouslyPlanned':
    '* Based on planned value of previous snapshot for {timelineDate} (reported in {snapshotCreationDate})',

  'reporting.addNewSnapshot.modal.header': 'New report: {projectCodeName}',

  'reporting.addNewSnapshot.modal.table.header.sectionName': ' ',
  'reporting.addNewSnapshot.modal.table.header.current': 'Current',
  'reporting.addNewSnapshot.modal.table.header.previous': 'Previous',
  'reporting.addNewSnapshot.modal.table.header.difference': 'Difference',
  'reporting.addNewSnapshot.modal.table.header.icon': ' ',

  'reporting.addNewSnapshot.modal.description': 'Description',
  'reporting.addNewSnapshot.modal.reportingPeriod': 'Reporting period',
  'reporting.addNewSnapshot.modal.target': 'Target',
  'reporting.addNewSnapshot.modal.costPrediction': 'Cost Plan',
  'reporting.addNewSnapshot.modal.costs': 'Costs',
  'reporting.addNewSnapshot.modal.contract': 'Contract',
  'reporting.addNewSnapshot.modal.changeOrders': 'Change orders',
  'reporting.addNewSnapshot.modal.reserves': 'Reserves',
  'reporting.addNewSnapshot.modal.revenue': 'Revenue',
  'reporting.addNewSnapshot.modal.profit': 'Profit',
  'reporting.addNewSnapshot.modal.percentageOfCompletion':
    'Percentage of completion',
  'reporting.addNewSnapshot.modal.poc.actual': 'Actual',
  'reporting.addNewSnapshot.modal.poc.planned': 'Planned',
  'reporting.addNewSnapshot.modal.poc.previouslyPlanned': 'Previously planned',

  'reporting.addNewSnapshot.modal.linkToPoCBasicGraph': '% of Completion Graph',
  'reporting.addNewSnapshot.modal.linkToPoCWorkSectionGraph':
    '% of Completion per Worksection',

  'reporting.addNewSnapshot.modal.linkToPoCTable': 'Snapshot Info Table',

  'reporting.addNewSnapshot.modal.differenceTooltip':
    'Difference between previously reported snapshot and current state',

  'reporting.addNewSnapshot.modal.pocRowTooltip':
    '% of completion to be reported depends on the selection below. Previous % depicts previously reported forecast for this current month.',

  'reporting.addNewSnapshot.modal.poc.actualTooltip':
    '% of completion is calculated based on reported work completed per scheduled task.',
  'reporting.addNewSnapshot.modal.poc.plannedTooltip':
    '% of completion is calculated based on planned work completed per scheduled task. Updated schedule is used.',
  'reporting.addNewSnapshot.modal.poc.previouslyPlannedTooltip':
    '% of completion is calculated based on the planned work completed that was reported in last snapshot. % of completion forecast should remain same, if no new unscheduled costs have occured',

  'reporting.addNewSnapshot.modal.poc.exceptionNote':
    'This should be an exception, please give a comment in the input field.',

  'reporting.addNewSnapshot.modal.submitButton': 'Submit',

  'reporting.currentPeriod.orderSnapshots.sectionTitle': 'Current (unreported)',

  'revenue.header': 'Revenue',
  'revenue.button.edit': 'Edit',
  'revenue.toolbelt.button.addGroup': 'Add group',
  'revenue.toolbelt.button.moveRows': 'Move rows',
  'revenue.summary.total': 'Total bill: {amount}',
  'revenue.summary.billed': 'Billed: {amount}',
  'revenue.summary.notBilled': 'Remaining bill: {amount}',

  'revenue.addGroup.form.header': 'Create new group',

  'revenue.addGroup.formik.paymentBatchGroup': 'Classification',

  'revenue.addGroup.formik.error.mandatory': 'Mandatory field',
  'revenue.addGroup.formik.error.description.length': 'Too long description',
  'revenue.addGroup.formik.error.code.length': 'Too long code',

  'revenue.moveRows.modal.title':
    'Move selected rows ({amount} pc, total. {totalPrice})',

  'revenue.moveRows.modal.dropdownLabel': 'Move to group:',
  'revenue.moveRows.modal.submitButton': 'Move to group',

  'revenue.table.header.select': ' ',
  'revenue.table.header.batchCode': 'Code',
  'revenue.table.header.description': 'Description',
  'revenue.table.header.unitPrice': 'Unit price',
  'revenue.table.header.quantity': 'Quantity',
  'revenue.table.header.unit': 'UOM',
  'revenue.table.header.vat': 'VAT-%',
  'revenue.table.header.netPrice': 'Net amount',
  'revenue.table.header.grossPrice': 'Gross amount',
  'revenue.table.header.billingDate': 'Billing date',
  'revenue.table.header.actualizedBilling': 'Actual billing amount',
  'revenue.table.header.analysis': 'Breakdown',
  'revenue.table.header.status': 'Status',
  'revenue.table.header.attachment': 'Attachment',
  'revenue.table.header.delete': ' ',

  'revenue.table.header.sort.ascending.alt': 'Ascending order',
  'revenue.table.header.sort.descending.alt': 'Descending order',

  'revenue.table.group.remove': 'Remove group',

  'revenue.table.rows.addRow': 'Add row',
  'revenue.table.rows.remove': 'Remove rows',

  'revenue.table.mainRow.closed': 'Topic is closed',
  'revenue.table.mainRow.open': 'Topic is open',

  'revenue.table.input.percentage.toolTip':
    'Enter a percentage between 0 and 100%.',
  'revenue.status.Preliminary': 'Preliminary',
  'revenue.status.Planned': 'Planned',
  'revenue.status.Accepted': 'Accepted',
  'revenue.status.Invoiced': 'Invoiced',
  'revenue.status.Paid': 'Paid',
  'revenue.excelImport.numberOfRows': 'Importing {numberOfNewRows} row/s',
  'revenue.excelImport.instructions':
    'Insert copied rows from Excel in the input field below. No restriction on number of rows but each copied row should have columns code,description, quantity, unit, unit price, VAT, invoice date (mm.dd.yyyy) (in the same order)',

  'validation.number.toolTip':
    'Enter a numbers separated by a comma. The maximum digits is four decimal places.',
  'validation.string.length.toolTip':
    'String length exceeds maximum length {length} characters.',
  'validation.unique.string.toolTip': 'The code you entered is already in use.',

  'validation.hasMaxNWholeNumbersPositive': 'Number is too large',
  'validation.hasMaxNWholeNumbersNegative': 'Numebr is too small',
  'validation.hasMaxNDecimals': 'Maximum decimals allowed is 4',
  'validation.number.error': 'Enter a number, decimals separated with a comma',
  'validation.integer.error': 'Enter an integer, no decimals',

  'validation.quantity.orderedLessThanReceived':
    'Ordered quantity cannot be less than received',
  'validation.quantity.orderedMoreThanReceived':
    'Ordered quantity cannot be more than received',

  'validation.unknownField': 'Unknown field',
  'validation.invalidValue': 'Invalid value',
  'validation.duplicateValue': 'Duplicate value',
  'validation.billingDate.error': 'Only Date value is allowed',
  'validation.vat.error': 'Number should be between 0 and 100',

  'validation.excessQuantity': `Quantity remaining {quantityAndUnit}.
  Would you like to increase amount ordered by {excessQuantityAndUnit} ({changeAmountEur})?`,
  'validation.emptyAmount': 'Amount is empty.',

  'validation.maximumChange':
    'Maximum change allowed ({arrivalQuantityAndUnit}).',

  'scheduledTasks.workLoad': 'Workload',

  'worksection.graph.title': 'Progress | Planned | Accepted',
  'worksection.workpackage.create': 'Create Work Package',
  'worksection.workpackage.edit': 'Basic Work Package information',
  'worksection.workpackage.editModal.header':
    'Edit work section: {code} - {name}',
  'worksection.workpackage.delete': 'Delete Work Package',
  'worksection.workpackage.name': 'Name',
  'worksection.workpackage.code': 'Code',
  'worksection.workpackage.category': 'Work section category',
  'worksection.workpackage.description': 'Description',
  'worksection.workpackage.startDate': 'Start Date',
  'worksection.workpackage.endDate': 'End Date',
  'worksection.workpackage.disabledDatesInfo':
    'Dates from schedule tasks are used',
  'worksection.workpackage.wpDatesTooltipInfo':
    'Dates from schedule tasks are used for calculations, instead of manually entered dates',
  'worksection.workpackage.connectedtasks.title': 'Connected scheduled tasks',
  'worksection.workpackage.connectedtasks.linkToSchedule':
    'Edit tasks in Schedule',
  'worksection.workpackage.connectedtasks.noConnectedTaskInfo':
    'No task is selected for timing of work section, please select tasks for this work section in schedule. Alternatively, set fixed date range for timing the work section.',

  'worksection.header': 'Work sections',
  'worksection.workPackage.groups.open': 'All work package groups are open',
  'worksection.workPackage.groups.closed': 'All work package groups are closed',
  'worksection.workPackage.groups.sortAsc':
    'Sort Work packages by ascending order',
  'worksection.workPackage.groups.sortDesc':
    'Sort Work packages by descending order',
  'worksection.table.header.name': 'Description',
  'worksection.table.header.personResponsible': 'Person responsible',
  'worksection.table.header.targetTotal': 'Target',
  'worksection.table.header.additionalTargetTotal': 'Additional target',
  'worksection.table.header.predictionTotal': 'Cost Plan',
  'worksection.table.header.differenceToTarget': 'Difference to target',
  'worksection.table.header.predictionChangeFromLatest': 'Difference',
  'worksection.table.header.contractTotal': 'Contract',
  'worksection.table.header.remaining': 'Remaining',
  'worksection.table.header.changeOrdersTotal': 'Additional work',
  'worksection.table.header.reservesTotal': 'Total reservations',
  'worksection.table.header.receivedTotal': 'Received',
  'worksection.table.header.none': ' ',
  'worksection.table.header.code': 'Code',
  'worksection.table.header.percentageOfCompletion': 'Completion',
  'worksection.table.header.differenceToPreviousPoC': '+/-',
  'worksection.table.mainRow.closed': 'Work phase is closed',
  'worksection.table.mainRow.open': 'Work phase is open',
  'worksection.orderRow.header.status': 'Row status',

  'worksection.table.workPackageGroup.open': 'Workpackage group row open',
  'worksection.table.workPackageGroup.closed': 'Workpackage group row closed',

  'worksectionDetails.back': 'Back:',
  'worksectionDetails.actionButton': 'Add orders and topics',
  'worksection.actionButton.addWorkPackage': 'Add',
  'worksection.table.header.createWorkPackage': 'Create a new workpackage',
  'worksectionDetails.actionButton.info': 'Show Connected Tasks',
  'worksectionDetails.header': '{code} - {name}',

  'worksectionDetails.orderTable.order': 'Procurement package',
  'worksectionDetails.orderTable.target': 'Target',
  'worksectionDetails.orderTable.additionalTarget': 'Additional Target',
  'worksectionDetails.orderTable.predictionTotal': 'Cost Plan',
  'worksectionDetails.orderTable.predictionChangeFromLatest': 'Difference',
  'worksectionDetails.orderTable.contractTotal': 'Contract',
  'worksectionDetails.orderTable.changeOrdersTotal': 'Change orders',
  'worksectionDetails.orderTable.reserved': 'Reserves',
  'worksectionDetails.orderTable.received': 'Accepted',
  'worksectionDetails.orderTable.status': 'Status',
  'worksectionDetails.orderTable.linkToEntityIcon.alt': 'Link to order',

  'worksectionDetails.orderTable.topicRow.closed': 'Topic is closed',
  'worksectionDetails.orderTable.topicRow.open': 'Topic is open',

  'users.editMode.editUserSettings': 'User Settings',
  'users.editMode.language': 'Language',

  'target.header': 'Target',
  'target.summary.target': 'Target: {amount}',
  'target.summary.predictionTotal': 'Cost Plan: {amount}',
  'target.summary.differenceToTarget': 'Difference to target: {amount}',

  'target.table.header.referenceNo': 'Reference No',
  'target.table.header.order': 'Order',
  'target.table.header.workSection': 'Work section',
  'target.table.header.searchInput': 'Search',

  'target.table.hierarchies.open': 'All hierarchy entries are open',
  'target.table.hierarchies.closed': 'All hierarchy entries are closed',
  'target.table.hierarchies.openLevel': 'Open one level',
  'target.table.hierarchies.closeLevel': 'Close one level',

  'target.noHierarchies.sectionTitle': 'Target rows without hierarchy',
};
